






























import {Component, Vue} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs,} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceItem from "@/components/Personal/MyService/ServiceItem.vue";

import MyServiceItem from "./MyServiceItem.vue";
import ServiceTrashCanItem from "./ServiceTrashCanItem.vue";
// import OpenService from "./OpenService.vue";
import {getCancelServiceList, getServiceOrdersStatus, getServiceStatus, refundReasons,} from "@/services/api";
import {Mutation, State} from "vuex-class";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        PersonalHeader,
        ServiceItem,
        MyServiceItem,
        ServiceTrashCanItem,
    },
})
export default class extends Vue {
    @Mutation SET_REFUNDREASONS;
    @Mutation SET_ALLORDERSTATUS;
    @Mutation SET_CANCELRESON;

    @Mutation SET_ALLSERVICESTATUS;
    @State allOrderStatus;
    @State allServiceStaus;
    @State refundReasons;
    tabTitle = ["全部服务", "待付款", "已付款", "已完成", "已取消", "待评价"];
    // 服务订单回收站
    searchItem = "";
    cancelVisible = false;
    timer = null;
    hackReset = true;
    defaultValue = "";
    OrdersStatus = {};
    serviceStatus = {};
    allRefundReasons = {};
    acKEY: any = 0;

    //搜索框搜索
    onSearch(value) {
        if (this.acKEY == 7) this.acKEY = 0;
        this.emptyTextBySearch = true;
        this.searchItem = value;
    }

    emptyTextBySearch = false;

    created() {
        this.getCancelServiceList();
        this.refundReasonsEvent();
        this.getServiceOrdersStatus();
        this.getServiceStatus();
        const acKEY = sessionStorage.getItem("acKEY");
        if (acKEY != null) {
            this.acKEY = Number(acKEY);
        } else {
            this.change(-1);
        }
    }

    mounted() {
        const that = this
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            // this.$router.push("/personal/myService");
        } else {
            localStorage.setItem("loginBeforePath", "/personal/myService");
            this.$router.push("/login");
        }
        localStorage.setItem("laterPath", "/personal/myService");

        this.$nextTick().then(() => {
            let m: any = document.querySelectorAll(".ant-tabs-tab");
            m[6].style.borderLeft = "1px solid #E4E9F2";
            m[6].style.height = "0.2rem";
            m[6].style.paddingTop = "0";
            m[5].style.paddingRight = "0.2rem";
            m[6].style.paddingLeft = "0.3rem";
            m[6].style.marginLeft = "0.1rem";
            m.forEach((element) => {
                element.onclick = () => {
                    const el = (this.$refs["serviceItem" + this.acKEY] as any)[0];
                    this.emptyTextBySearch = true;
                    el.getList();
                };
            });
            const acKEY = sessionStorage.getItem("acKEY");
            if (acKEY != null) {
                // 创建一个鼠标事件
                var e = document.createEvent('MouseEvent');
                // 事件初始化绑定一个点击
                e.initEvent('click', false, false);
                // 获取到第二个待付款元素
                const el = m[Number(acKEY)];
                // 分发事件
                el.dispatchEvent(e)
                sessionStorage.removeItem("acKEY");
            }
        });


    }

    change(activeKey) {
        let a: any = document.querySelectorAll(".ant-tabs-ink-bar");
        if (activeKey === 7) {
            a[0].style.background = 'none';
        } else if (a.length != 0) {
            a[0].style.backgroundColor = '#39BEB8';
        }
        this.defaultValue = "";
        if (activeKey < 0)
            this.$nextTick().then(res => {
                (this.$refs[`serviceItem0`] as any)[0].getList();
            });
    }

    getCancelServiceList() {
        getCancelServiceList({typeId: "89"}).then(({code, data}: any) => {
            if (code == 0) {
                this.SET_CANCELRESON(data);
            }
        });
    }

    //退款原因
    refundReasonsEvent() {
        if (this.refundReasons) {
            refundReasons({type: 1}).then((res) => {
                let that: any = this;
                res.data.forEach((el) => {
                    that.allRefundReasons[el.dictionaryId] = el.dictionaryName;
                });
                this.SET_REFUNDREASONS(this.allRefundReasons);
            });
        }
    }

    getServiceOrdersStatus() {
        getServiceOrdersStatus({}).then((res) => {
            let that: any = this;
            (res as any).data.forEach((el) => {
                that.OrdersStatus[el.dictionaryId] = el.dictionaryName;
            });
            this.SET_ALLORDERSTATUS(this.OrdersStatus);
        });
    }

    getServiceStatus() {
        getServiceStatus({}).then((res) => {
            let that: any = this;
            (res as any).data.forEach((el) => {
                that.serviceStatus[el.dictionaryId] = el.dictionaryName;
            });
            this.SET_ALLSERVICESTATUS(this.serviceStatus);
        });
    }
}

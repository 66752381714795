<template>
  <div>
    <Header/>
    <Nav/>
    <div class="doctor-banner-pensionService">
      <div
          :style="{ backgroundImage: `url(${content.serviceDetailPhoto})` }"
          class="banner-image"
      ></div>
      <div class="banner-description">
        <div class="title">
          <span class="content">{{ content.classificationName }}</span>
        </div>
        <div class="text">
          <div class="content">{{ content.detailDescription }}</div>
        </div>
      </div>
    </div>
    <div class="doctor-breadcrumb">
      <div class="container">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link to="/home" class="breadcrumbO">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link to="/pensionService" class="breadcrumbO"
            >养老服务
            </router-link
            >
          </a-breadcrumb-item>
          <a-breadcrumb-item :style="{ color: '#60c7c6' }">
            {{ content.classificationName }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="food-body">
      <div class="title">服务特色</div>
      <FoodDesc v-if="content.features"
                :content="content.features.slice(0,6)"
                :serviceFeaturePhoto="content.serviceFeaturePhoto"
      />


      <div class="title" style="margin-bottom: 0.2rem">
        <!-- <span>服务预约</span> -->
        <span>服务项目</span>

        <div class="myServiceLinput">
          <a-input-search
              class="goods-search"
              enterButton="搜索"
              placeholder="请输入服务名称"
              @search="onSearch"
          >
          </a-input-search>
        </div>
      </div>
      <div class="order" v-for="item in orderList" :key="item.id">
        <OrderItem
            :all="item"
            :orderArea="item.addressStreet"
            :orderDesc="item.serviceDesc"
            :orderImage="item.serviceImage"
            :orderLocation="item.addressProvince+item.addressCity+item.addressArea+item.addressStreet+item.detailAddress"
            :orderPrice="item.servicePrice"
            :orderRate="item.serviceOveralScore"
            :orderTitle="item.serviceName"
            :saleCount="item.totalSale"
            :serviceId="item.serviceId"
            :telemedicineReservation="item.telemedicineReservation"
        />
      </div>
      <div class="pagination" v-if="pagination.total>10" style="padding: 0.31rem 0 0.61rem;">
        <a-pagination
            :defaultCurrent="1"
            :item-render="$store.state.itemRender"
            :showTotal="(total) => `共 ${pagination.total} 条`"
            :total="pagination.total"
            showQuickJumper
            @change="onchange"
        />
      </div>
      <div class="title" hidden>其他服务商</div>
      <PicSlider v-if="false" :orgList="orgList" class="pic-slider"/>
      <div class="title" style="margin-top: 0.5rem">服务流程</div>
      <Process :ProcessType="2" class="process-pic"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import FoodDesc from "@/components/FoodDesc";
    import PicSlider from "@/components/PicSlider";
    import OrderItem from "@/components/OrderItem";
    import Process from "@/components/Process";
    import {Breadcrumb, Input} from "ant-design-vue";
    import {fetchDetailByThirdId, otherOrgs, serviceTypeInfo,} from "@/services/api";

    Vue.use(Breadcrumb);
Vue.use(Input);

export default {
  name: "PensionFood",
  components: {
    Header,
    Nav,
    Footer,
    FoodDesc,
    PicSlider,
    OrderItem,
    Process,
  },
  data() {
    return {
      content: {},
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        keywords: undefined,
      },
      serviceName: "",
      orgList: [],
      orderList: [],
    };
  },
  methods: {
    onSearch(value) {
      this.pagination = {
        ...this.pagination,
        keywords: value,
      };
      const params = {
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.pageNum,
        searchInput: this.pagination.keywords,
        thirdServicesId: this.content.serviceClassificationId,
        cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
      };

      this.orderList.length = 0;
      this.getServiceList(params);
    },
    onchange(page, pageSize) {
      window.scrollTo({top: 0});

      const temp = {
        ...this.pagination,
        pageNum: page,
        pageSize,
      };
      this.pagination = temp;

      const params = {
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.pageNum,
        searchInput: this.pagination.keywords,
        thirdServicesId: this.content.serviceClassificationId,
        cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
      };
      this.getServiceList(params);
    },
    getServiceList(params) {
      fetchDetailByThirdId(params).then((res) => {
        if (res.code === 0) {
          this.orderList = [];
          if (res.data.serviceList) {
            res.data.serviceList.forEach((item) => this.orderList.push(item));
            this.pagination = {
              ...this.pagination,
              total: res.data.totalCount,
            };
          }
        }
      });
    },
  },
  mounted: function () {
    let serviceClassificationId = this.$route.query.serviceClassificationId;
    const params = {
      pageSize: 10,
      pageIndex: 1,
      searchInput: this.pagination.keywords,
      thirdServicesId: serviceClassificationId,
      cityId: parseInt(localStorage.getItem("provinceId") || "2", 10),
    };
    this.getServiceList(params);

    // this.content = this.$route.query.data;
    serviceTypeInfo({serviceClassificationId: serviceClassificationId}).then((res) => {
          if (res && res.code == 0) {
            this.content = res.data;
          }
        }
    ).then(_ => {
      const otherParams = {
        pageSize: 9999,
        pageIndex: 1,
        cityId: parseInt(localStorage.getItem("provinceId") || "2", 10),
        secondCategoryId: "12",
        areaId: null,
      };
      otherOrgs({...otherParams}).then((res) => {
        if (res && res.code == 0) {
          this.orgList = res.data.orgList;
        }
      });
    });

  },
};
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.banner-pensionService {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 4rem;

  .banner-image {
    height: 100%;
    width: 100%;
    background-image: url("./../assets/images/banner_zhucan.png");
    background-size: cover;
    background-position: center center;
  }

  .banner-description {
    position: absolute;
    left: 4.2rem;
    top: 0.4rem;
    color: #fff;

    .title {
      .content {
        font-size: 0.46rem;
        border-bottom: medium solid #f6ab1a;
      }
    }

    .text {
      width: 7.21rem;

      .content {
        font-size: 0.18rem;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.18rem 0 0.18rem;
  background-color: #fff;

  .container {
    width: 13.66rem;
    margin: 0 auto;
  }
}

.food-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #fff;

  .title {
    font-size: 0.4rem;
    color: #1f6bb1;
    position: relative;

    .myServiceLinput {
      position: absolute;
      left: 3.5rem;
      top: 0.5rem;
      /*bottom: 0.0rem;*/
      transform: translateY(-50%);
      /*border: 1px solid red;*/

      .ant-input {
        width: 3.39rem;
        font-size: 0.12rem;
        line-height: 0.84rem;
        height: 0.38rem;
      }

      // 输入框placeholder字号
      .goods-search input::-webkit-input-placeholder {
        font-size: 0.12rem;
      }

      button.ant-btn.ant-btn-primary.ant-input-search-button {
        width: 0.61rem;
        height: 0.38rem;
        background-color: #60c7c6;
        border-color: #60c7c6;
        font-size: 0.12rem;
        border-radius: 0 0.5rem 0.5rem 0;
      }

      input.ant-input:focus {
        border-color: #d9d9d9;
        -webkit-box-shadow: 0 0 0 0;
        box-shadow: 0 0 0 0;
        border-radius: 0.5rem 0 0 0.5rem;
      }

      input.ant-input:hover {
        border-color: #d9d9d9;
      }

      input.ant-input {
        border-radius: 0.5rem 0 0 0.5rem;

      }
    }
  }

  .order {
    /*margin-bottom: 0.1rem;*/
    /*width: 13.66rem;*/
    /*height: 2.4rem;*/
    /*border: 1px solid red;*/
  }

  .pic-slider {
    margin: 0.46rem 0 0.63rem;
  }


  .process-pic {
    margin: 0.3rem 0 0.6rem;
  }
}
</style>

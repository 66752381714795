
































import {Component, Vue} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceItem from "@/components/Personal/MyService/ServiceItem.vue";
import MyGoodsModule from "./MyGoodsModule.vue";
import GoosdCanItem from "./GoosdCanItem.vue";
import {getCancelServiceList, refundReasons,} from "@/services/api";
import {Mutation, State} from "vuex-class";
import {baseUrl, request} from "@/services/http";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);
@Component({
    components: {
        PersonalHeader,
        ServiceItem,
        MyGoodsModule,
        GoosdCanItem
    }
})
export default class extends Vue {
    @Mutation SET_REFUNDREASONS;
    @Mutation SET_ALLORDERSTATUS;
    @Mutation SET_CANCELRESON;

    @Mutation SET_ALLSERVICESTATUS;
    @State allOrderStatus;
    @State allServiceStaus;
    @State refundReasons;
    tabTitle = ["全部商品", "待付款", "待发货", "待收货", "已完成", "待评价"];
    searchItem = "";
    cancelVisible = false;
    timer = null;
    hackReset = true;
    defaultValue = "";
    OrdersStatus = {};
    serviceStatus = {};
    allRefundReasons = {};
    acKEY:any = 0;

    onSearch(value) {
        if (this.acKEY == 7) this.acKEY = 0;
        this.emptyTextBySearch = true;
        this.searchItem = value;
    }

    emptyTextBySearch = false;
    allServieStyleList = [];

    getAllServieStyleList() {
        request(`${baseUrl}/api/v1/dicList/service-style`).then((res: any) => {
            if (res["code"] == 0) {
                this.allServieStyleList = res["data"];
            }
        });
    }

    created() {
        this.getCancelServiceList();
        this.refundReasonsEvent();
        this.getServiceOrdersStatus();
        this.getAllServieStyleList();
        const acKEY = sessionStorage.getItem("acKEY");
        if(acKEY!=null){
            this.acKEY = Number(acKEY);
        }else{
            this.change(-1);
        }
    }

    mounted() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            this.$router.push("/personal/MyGoods");
        } else {
            this.$router.push("/login");
        }

        this.$nextTick().then(() => {
            let m: any = document.querySelectorAll(".ant-tabs-tab");
            // m[6].style.display = "none";
            m[6].style.borderLeft="1px solid #E4E9F2";
            m[6].style.height="0.2rem";
            m[6].style.paddingTop="0";
            m[5].style.paddingRight="0.2rem";
            m[6].style.paddingLeft="0.3rem";
            m[6].style.marginLeft="0.1rem";
            m.forEach(element => {
                element.onclick = () => {
                    const el = (this.$refs["serviceItem" + this.acKEY] as any)[0];
                    this.emptyTextBySearch = true;
                    el.getList();
                };
            });
            const acKEY = sessionStorage.getItem("acKEY");
            if(acKEY!=null){
                // 创建一个鼠标事件
                var e = document.createEvent('MouseEvent');
                // 事件初始化绑定一个点击
                e.initEvent('click', false, false);
                // 获取到第二个待付款元素
                const el = m[ Number(acKEY)];
                // 分发事件
                el.dispatchEvent(e)
                sessionStorage.removeItem("acKEY");
            }
        });
    }

    //商品订单消原因
    getCancelServiceList() {
        getCancelServiceList({typeId: "89"}).then(({code, data}: any) => {
            if (code == 0) {
                this.SET_CANCELRESON(data);
            }
        });
        // }
    }

    //提交（修改）退款申请-退款原因
    refundReasonsEvent() {
        if (this.refundReasons) {
            refundReasons({}).then(res => {
                let that: any = this;
                res.data.forEach(el => {
                    that.allRefundReasons[el.dictionaryId] = el.dictionaryName;
                });
                this.SET_REFUNDREASONS(this.allRefundReasons);
            });
        }
    }

    //商品订单下拉状态
    getServiceOrdersStatus() {
        const data = {1: "等待买家付款", 2: "买家已付款", 3: "卖家已发货", 4: "交易成功", 8: "交易关闭", 6: "交易取消", 9: "退款中的订单"}
        let that: any = this;
        that.OrdersStatus = data;
        this.SET_ALLORDERSTATUS(this.OrdersStatus);
    }

    //切换获取状态下对应的信息
    change(activeKey) {
        let a: any = document.querySelectorAll(".ant-tabs-ink-bar");
        if(activeKey===7){
            a[0].style.background='none';
        } else if(a.length!=0){
            a[0].style.backgroundColor='#39BEB8';
        }
        this.defaultValue = "";
        if (activeKey < 0)
            this.$nextTick().then(res => {
                (this.$refs[`serviceItem0`] as any)[0].getList();
            });
    }

    setCancelModal(flag: boolean) {
        this.cancelVisible = flag;
    }
}

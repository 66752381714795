






















import {Component, Prop, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";

Vue.use(Rate);

@Component({
    props: {
        orderImage: String,
        orderTitle: String,
        orderRate: Number,
        orderLocation: String,
        orderPrice: Number,
        orderArea: String,
        saleCount: Number,
        orderDesc: String,
        serviceId: String,
        all: Object,
        telemedicineReservation: Boolean,
    },
})
export default class OrderItem extends Vue {
    @Prop() private all!: Object;
    // @Provide:{

    // }
    jumpjump() {
        const id = this.all["orgId"];
        // this.all["ifParentOrg"] == 1
        //   ? this.all["orgId"]
        //   : this.all["parentOrgId"];
        if (this.all["ifParentOrg"]) this.$router.push("/orgDetail/" + id);
        else
            this.$router.push(
                `/servieItemDetail?title=${this.all["categoryName"]}&orgId=${id}&categoryId=${this.all["serviceCategoryId"]}`
            );
    }

    handleViewDetail(serviceId: string) {
        sessionStorage.setItem("serviceId", serviceId);
        this.$router.push(`/cookInHome/${serviceId}`);
    }

    handleBuy() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            this.$router.push({
                path: "/serviceOrder",
                query: {
                    serviceId: this.$props.serviceId,
                    amount: "1",
                },
            });
        } else {
            const {href} = this.$router.resolve({
                path: "/serviceOrder",
                query: {
                    serviceId: this.$props.serviceId,
                    amount: "1",
                },
            });
            sessionStorage.setItem("pay", href);
            this.$router.push("/login");
        }
    }
}

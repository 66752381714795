var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pensionNews-item-body",on:{"click":_vm.handleViewDetail}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.img,"alt":""}})]),_c('div',{staticClass:"content"},[_c('a',{staticClass:"title",attrs:{"title":_vm.title}},[_vm._v(_vm._s(_vm.title))]),_c('p',{attrs:{"id":"descrabe123461"}},[_vm._v(_vm._s(_vm.contentF))]),_c('div',{staticClass:"foot"},[_c('div',{staticClass:"tag-row"},[(_vm.newsType)?_c('div',{staticClass:"tag-icon"}):_vm._e(),_c('span',{style:({
            color: '#60c6c7',
            marginLeft: '0.1rem',
            marginRight: '0.5rem'
          })},[_vm._v(_vm._s(_vm.newsType))]),_c('span',[_vm._v(_vm._s(_vm.from))])]),_c('div',{staticClass:"tag-row",style:({ justifyContent: 'flex-end' })},[_c('div',{staticClass:"tag-icon",style:({
            backgroundImage: ("url(" + (require('./../assets/images/shijian_icon_zxxq.png')) + ")"),
            width: '0.17rem',
            height: '0.16rem',
            marginRight: '0.05rem'
          })}),_c('span',[_vm._v(_vm._s(_vm.publishTime))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
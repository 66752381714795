







































    import {Component, Vue} from "vue-property-decorator";

    @Component({
  props: {
    institutionName: String,
    institutionLocation: String,
    institutionPhone: String,
    subOrgId: String,
    allcontent: Object
  }
})
export default class InstitutionInfo extends Vue {
  // @Prop() private msg!: string
  jumpToOrgDetail() {
    // this.$router.push(`/orgDetail/${this.$props.orgId}`);
    const {href} = this.$router.resolve(`/subordinateBody/${this.$props.allcontent.subOrgId}`);
    window.open(href, "_blank");
  }

  /**
   * TODO 有使用但没有定义，不知道是计算的还是prop
   */
  itemPrice: Number = 0;

  mounted() {
    const allcontent = this.$props.allcontent;
  }

  get _address() {
    const allcontent = this.$props.allcontent;
    const addressProvince = allcontent["addressProvince"] || "";
    const addressCity = allcontent["addressCity"] || "";
    const addressArea = allcontent["addressArea"] || "";
    const detailAddress = allcontent["detailAddress"] || "";
    const addressStreet = allcontent["addressStreet"] || "";

    return (
      addressProvince +
      addressCity +
      addressArea +
      addressStreet +
      detailAddress
    );
  }
  // lookDetail() {

  // }
}
































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message, Modal} from "ant-design-vue";
    import {deleteShippingAddress, fetchProvinceName, updateDefaultShippingAddress,} from "@/services/api";

    @Component
export default class addressLocationItem extends Vue {
  @Prop() private type!: boolean;
  @Prop() private data!: any;

  visible: Boolean = false; // 编辑服务地址对话框

  allAreaData = [];
  public Province = "";
  public City = "";
  public Area = "";
  shopAddressPlaceholder = "";
  created() {
    this.GettingCities();
  }
  //根据省市街道id获取对应名字
  GettingCities() {
    fetchProvinceName({
      province: this.data.receiverProvince,
      city: this.data.receiverCity,
      area: this.data.receiverArea,
      street: this.data.receiverStreet,
    }).then((res) => {
      if (res.code == 0) {
        const add = res.info.addr;
        this.shopAddressPlaceholder = `${add.province}${add.city}${add.area}${
          add.street ? `${add.street}` : ""
        }`;
      } else {
        message.error(res.msg);
      }
    });
  }
  // 点击设为默认
  handleSelectDefault() {
    const that = this;
    updateDefaultShippingAddress({
      id: that.data.addressId,
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据
        message.success("默认地址更换成功");
        // 调用父组件方法，更新数据
        that.$emit("getLocationList");
      } else {
        message.error(`设置默认失败，错误原因：${res.msg}`);
      }
    });
  }
  // 点击编辑
  handleEdit() {
    this.$emit("editShippingAddress", this.data);
  }

  handleDelete() {
    Modal.confirm({
      title: "您确定要删除该收货地址吗？",
      width:'5rem',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleDelete2(),
    });
  }

  // 点击删除
  handleDelete2() {
    const that = this;
    deleteShippingAddress({
      addressIdList: [that.data.addressId],
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据

        message.success("删除成功");
        // 调用父组件方法，更新数据
        that.$emit("getLocationList");
      } else {
        message.error(`删除失败，错误原因：${res.msg}`);
      }
    });
  }
}











import {Component, Vue} from "vue-property-decorator";
import {baseUrl, mallUrl} from "../../../services/http";

@Component({
  props: {
    price: Number,
    picture: String,
    commodityId: String
  }
})
export default class CommodityCollectionScrollItem extends Vue {
  baseUrl = baseUrl;

  handleGoCommodity(data) {
    open(mallUrl + '/#/mall/detail/' + data);
  }
}

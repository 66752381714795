import { render, staticRenderFns } from "./PensionNewsItem.vue?vue&type=template&id=e3d5bfee&scoped=true&"
import script from "./PensionNewsItem.vue?vue&type=script&lang=ts&"
export * from "./PensionNewsItem.vue?vue&type=script&lang=ts&"
import style0 from "./PensionNewsItem.vue?vue&type=style&index=0&id=e3d5bfee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3d5bfee",
  null
  
)

export default component.exports
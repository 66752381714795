










































    import {Component, Vue} from "vue-property-decorator";
    import {Input, message, Modal, Table, Tabs} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ApplyAeturnExchange from "@/components/Personal/ApplyAeturnExchange.vue";
    import returnExchangeRecords from "@/components/Personal/returnExchangeRecords.vue";
    import {baseUrl} from "../../../services/http";
    import {ApplyExchangeList, changingRefundingList} from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Modal);

@Component({
  components: {
    PersonalHeader,
    ApplyAeturnExchange,
    returnExchangeRecords
  }
})
export default class extends Vue {
  baseUrl = baseUrl
  // 取消订单Modal显隐
  cancelVisible = false;
  curType: any = 0;
  applyReturnValue: any = ""; // 申请退换货输入框value
  returnRecordValue: any = ""; // 退款记录输入框value
  data: Array<any> = [];
  data2: Array<any> = [];
  topLis = [

    {
      title: "订单详情",
      width: "6.5rem"

    },
    // {
    //     title: "订单状态",
    //     width: "2.5rem"
    // },
    {
      title: "下单时间",
      width: "3.5rem"
    },
    {
      title: "操作",
      width: "3.5rem"
    }
  ];
  topLis2 = [
    {
      title: "商品详情",
      width: "3.2rem"

    },
    {
      title: "退款金额",
      width: "1.6rem"
    },
    {
      title: "申请时间",
      width: "1.5rem"
    },
    {
      title: "退款类型",
      width: "1.5rem"
    },
    {
      title: "退款状态",
      width: "1.5rem"
    },
    {
      title: "操作",
      width: "2.31rem"
    }
  ];
  emptyTextBySearch = false
  pagination = {
    total: 0,
    pageSize: 10,
    pageIndex: 1,
    queryStatus: 2
  };
  pagination2 = {
    total: 0,
    pageSize: 10,
    pageIndex: 1,
    queryStatus: 2
  };
  private msg: string = "MyGoods页面！";

  async created() {

    await this.getApplyReturn();
    await this.getReturnRecord();
  }

  //根据商品状态选择
  typeClick(type: string) {
    this.curType = type;
    this.getReturnRecord();
  }


  // 查询可申请退换货商品列表
  getApplyReturn() {
    ApplyExchangeList({
      ...this.pagination,
      searchInput: this.applyReturnValue,
    }).then((res: any) => {
      if (res && res.code === 0) {
        this.pagination.total = res.data.total;
        this.pagination.pageSize = res.data.size;
        this.data = [];
        res.data.records.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.orderSeriesNum = item.orderSeriesNum;
          obj.merchantName = item.merchantName;
          obj.commodityId = item.commodityId;
          obj.goods = {
            commodityName: item.commodityName,
            pic: item.pic
          };
          obj.orderStatus = item.orderStatus;
          obj.createTime = item.createTime;
          obj.other = item;
          this.data.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }

  // 获取退换货记录
  getReturnRecord() {
    changingRefundingList({
      ...this.pagination2,
      searchInput: this.returnRecordValue,
      returnType: this.curType
    }).then((res: any) => {
      if (res && res.code === 0) {
        // 处理数据
        this.pagination2.total = res.data.total;
        this.pagination2.pageSize = res.data.size;
        this.data2 = [];
        res.data.records.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.returnId = item.returnId;
          obj.refundSeries = item.refundSeries;
          obj.commodityName = item.commodityName;
          obj.commodityId = item.commodityId;
          obj.merchantName = item.merchantName;
          obj.commodityImage = item.commodityImage;
          obj.merchantInfoId = item.merchantInfoId;
          obj.creatTime = item.creatTime;
          obj.statusDic = item.statusDic;
          obj.status = item.status;
          obj.returnType = item.returnType;
          obj.serviceStyle = item.serviceStyle;

          obj.refundDetail = item.refundDetail;
          obj.orderSeriesNum = item.orderSeriesNum;
          obj.other = item;
          this.data2.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }

  // 点击进入申请售后、申请退款
  handleApplyCusService(value: any) {
    const data = {
      commodityPic: value.other.pic,
      commodityName: value.other.commodityName,
      skuInfo: value.other.brand,
      orderCommodityId: value.other.commodityRelationId,
    };
    if (value.other.orderStatusDic === 262) {
      const {href} = this.$router.resolve({
        path: "/personal/ReturnGoodsOne",
        query: {
          commodityRelationId: value.other.commodityRelationId
        }
      });
      window.open(href, "_blank");
    } else {
      const {href} = this.$router.resolve({
        path: "/personal/AskBuyAfter",
        query: data
      });
      window.open(href, "_blank");
    }

  }


  // 点击查看退款记录
  handleRecordCheck(value: any) {
    //0是退款，1是退换货
    if (value.returnType === 0) {

      if (value.statusDic === 269 || value.statusDic === 725) {
        const {href}:any= this.$router.resolve({
          path: "/personal/ReturnGoodsTwo",
          query: {
            commodityRelationId: value.other.commodityRelationId,
            returnId: value.other.returnId,
          }
        });
        window.open(href, "_blank");
      } else if (value.statusDic === 270 || value.statusDic === 271) {
        const {href}:any = this.$router.resolve({
          path: "/personal/ReturnGoodsThree",
          query: {
            params: value,
            commodityRelationId: value.other.commodityRelationId,
            returnId: value.other.returnId
          }
        });
        window.open(href, "_blank");
      }

    } else {
      if (value.statusDic === 269 || value.statusDic === 725 || value.statusDic === 728) {
        const {href}:any= this.$router.resolve({
          path: "/personal/ReturnGoods2",
          query: {
            commodityRelationId: value.other.commodityRelationId,
            returnId: value.other.returnId
          }
        });
        window.open(href, "_blank");
      } else if (value.statusDic === 726 || value.statusDic === 727) {
        const {href}:any= this.$router.resolve({
          path: "/personal/ReturnGoods3",
          query: {
            commodityRelationId: value.other.commodityRelationId,
            returnId: value.other.returnId
          }
        });
        window.open(href, "_blank");
      } else if (value.statusDic === 270 || value.statusDic === 271) {
        const {href}:any = this.$router.resolve({
          path: "/personal/ReturnGoods4",
          query: {
            commodityRelationId: value.other.commodityRelationId,
            returnId: value.other.returnId
          }
        });
        window.open(href, "_blank");
      }
    }


  }

  // 点击搜索查询申请服务退款
  onSearchApplyReturn(value: any) {
    this.getApplyReturn();
    this.emptyTextBySearch = true
  }

  // 点击搜索查询退换记录
  onSearchReturnRecord(value: any) {
    this.getReturnRecord();
    this.emptyTextBySearch = true
  }


}

































  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {Steps} from "ant-design-vue";
  import moment from "moment";

  Vue.use(Steps);

@Component({
  props: {
    stepData: Array
    // status: Number
  }
})
export default class MyStepBar extends Vue {
  @Prop() private status!: Number;
  currentStep = 0;
  @Watch("status", { immediate: true, deep: true })
  getStatus(newval, oldnew) {
    this.currentStep = newval + 1;
  }
  stepConfirmTime: String[] = [];

  mounted() {
    // 让组件适应任何数量的步骤
    this.stepConfirmTime = this.$props.stepData.map((item: any) => item.time);
    this.currentStep = this.$props.status + 1;
  }

  nextStep() {
    // 要求在完成每一步后在desc的位置显示完成步骤的时间
    if (this.stepConfirmTime.length) {
      this.stepConfirmTime[this.currentStep] = moment().format(
        "YYYY-MM-DD  HH:MM:SS"
      );
    }

    // 完成所有步骤后不能再进入下一步
    if (this.currentStep < this.$props.stepData.length) {
      this.currentStep += 1;
    }
  }
}



















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import serviceOrdeItem from "@/components/Personal/serviceOrderForm/serviceOrdeItem.vue";
import {serviceOrderList} from "@/services/api";
import aSpin from "@/components/aSpin.vue";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        serviceOrdeItem,
        aSpin
    }
})
export default class extends Vue {
    @Prop() public emptyTextBySearch: any;
    allOrderStatus: any = {
        762: "已订购",
        763: "等待服务",
        764: "进行中",
        765: "已完成",
        766: "已取消",
    };
    cancelVisible = false;
    goodsState = false;
    statusKey = 0;
    data: any = [];
    OrdersStatus: any = [];
    loading = true;
    searchInput: '';
    pagination = {
        total: 0,
        limit: 10,
        page: 1
    };
    //input框数据
    queryName: "";
    //切换数据
    orderStatus: "";
    @Prop()
    public status!: number;
    loaded = false;

    mounted() {
        this.loaded = true;
    }

    @Watch("allOrderStatus", {immediate: true, deep: true})
    getOrderStatus(newVal) {
        newVal[0] = "订购单状态";
        this.OrdersStatus = newVal;
    }

    @Prop() public searchItem!: null;

    @Watch("searchItem", {immediate: true, deep: true})
    getSearchItem(newVal, oldVal) {
        if (newVal != undefined || this.loaded) {
            this.pagination.page = 1;
            this.getSearch(newVal);
        }
    }

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.page = page;
        this.getList();
    }

    //———查询服务订购单———
    getList() {
        serviceOrderList({
            ...this.pagination,
            orderStatus: this.status === 0 ? null : (this.status === 1 ? null : this.status),
            queryName: this.searchInput,
            ifEvaluate: this.status === 1 ? 0 : null,
            user: 1,

        }).then(res => {
            this.data = [];
            let data = (res as any).page;
            this.pagination.total = data.totalCount;
            data.list.forEach(item => {
                item.tableData = [{
                    serviceName: item.serviceName,
                    servicePic: item.servicePic,
                    serviceCount: item.serviceCount,
                    serviceStyleName: item.serviceStyleName,
                    servicePrice: item.servicePrice,
                    orderStatusName: item.orderStatusName,
                    serviceId: item.serviceId,
                    ifEvaluation: item.ifEvaluation,
                    skuInfo: item.skuInfo,
                }]
                this.data.push(item);
            })
            this.pagination.page = data.currPage;
        });
        this.loading = false;
    }

    //商品名称/商品订单号搜索查询
    getSearch(searchInput: any) {
        this.searchInput = searchInput
        this.getList()
    }

    //商品订单状态下拉查询
    getOrderFormList(status: any) {
        serviceOrderList({
            ...this.pagination,
            orderStatus: status === 0 ? null : (status === 1 ? null : status),
            queryName: this.searchInput,
            ifEvaluate:status === 1 ? status : null,
            user: 1,

        }).then(res => {
            this.data = [];
            let data = (res as any).page;
            this.pagination.total = data.totalCount;

            data.list.forEach(item => {
                item.tableData = [{
                    serviceName: item.serviceName,
                    servicePic: item.servicePic,
                    serviceCount: item.serviceCount,
                    serviceStyleName: item.serviceStyleName,
                    servicePrice: item.servicePrice,
                    orderStatusName: item.orderStatusName,
                }]
                this.data.push(item)
            })
            this.pagination.page = data.currPage;
        });
        this.loading = false;
    }


    //获取商品订单状态
    dhabjshsacj = "预购单状态";

    //获取订单状态
    visibleChange(key: string) {
        this.goodsState = false;
        this.$emit('emptyTextBySearch',false);
        this.$emit("changeKey");
        this.dhabjshsacj = key.split("$")[1];
        this.pagination.page = 1;

        if (key === "0$预购单状态") {
            this.getOrderFormList(0);
        } else {
            this.getOrderFormList(key.split("$")[0]);
        }

    }

    //子组件回调数据
    againGetList() {
        this.pagination.page = 1;
        this.getList();
    }

}

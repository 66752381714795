
<script>
export default {
  name: "emoji-selector",
  props: {
    emojiList: {
      type: Array,
      default: function (){return []}
    },
    val: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'val',
    event: 'change'
  },
  data() {
    return {
      visible: this.val
    }
  },
  watch: {
    visible() {
      this.$emit('change', this.visible)
    },
    val() {
      this.visible = this.val
    }
  },
  methods: {
    commit(emoji) {
      this.$emit('commit', emoji)
      this.visible = false;
    }
  }
}
</script>

<template>
  <div :class="visible ? 'emoji-selector visible' : 'emoji-selector hide'">
    <div v-for="item in emojiList" class="emoji" v-if="visible">
      <span @click="commit(item.emoji)">{{ item.emoji }}</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .visible {
    z-index: 100;
    padding: 5px;
    width: 392px; //30*n + 2 + 30
    height: 242px; //30*n + 2
    border: 1px solid #E4E9F2;
    border-radius: 8px;
    bottom: 129px;
    left: -1px;
  }
  .hide {
    width: 0px; //30*n + 2 + 30
    height: 0px; //30*n + 2
    bottom: 105px;
    left: 15px;
  }

  .emoji-selector {
    overflow-y: scroll;
    background-color: #ffffff;
    position: absolute;
    transition: all .15s;
    .emoji {
      float: left;
      width: 30px;
      height: 30px;
      text-align: center;
      vertical-align: center;
      span {
        font-size: 20px;
      }
    }
  }
</style>

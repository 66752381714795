















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import MyGoodsItem from "@/components/Personal/myGoods/MyGoodsItem.vue";
import {Mutation, State} from "vuex-class";
import {getServiceList, orderFormList} from "@/services/api";
import aSpin from "@/components/aSpin.vue";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        PersonalHeader,
        MyGoodsItem,
        aSpin
    }
})
export default class extends Vue {
    @Prop() public emptyTextBySearch: any;
    @Prop() public all: any;

    @Mutation SET_ALLORDERSTATUS;
    @Mutation SET_ALLSERVICESTATUS;
    // @State allOrderStatus;
    allOrderStatus: any = {
        1: "待付款",
        2: "待发货",
        3: "待收货",
        4: "已完成",
        6: "已取消",
        8: "已关闭",
        9: "退款中的订单",
    };
    @State allServiceStaus;
    cancelVisible = false;
    goodsState = false;
    currentIndex = 0;
    statusKey = 0;
    searchContent = "";
    serviceStatus = {};
    data = [];
    OrdersStatus: any = [];
    serviceCurStatus: 0;
    loading = true;
    pagination = {
        total: 0,
        pageSize: 5,
        pageIndex: 1
    };
    @Prop({
        type: Number,
        required: true,
        default: null
    })
    @Prop()
    // public allServieStyleList: any;
    @Prop()
    public status!: number;
    // @Watch("status", { immediate: true, deep: true })
    // getStatus() {
    //   this.getList();
    // }
    loaded = false;

    mounted() {
        this.loaded = true;
    }

    @Watch("allOrderStatus", {immediate: true, deep: true})

    getOrderStatus(newVal, oldVal) {
        newVal[0] = "订单状态";
        this.OrdersStatus = newVal;
    }

    @Watch("allServiceStaus", {immediate: true, deep: true})
    getServiceStatus(newVal, oldVal) {
        this.serviceStatus = newVal;
    }

    @Prop({
        type: String,
        required: true,
        default: null
    })
    public searchItem!: null;

    @Watch("searchItem", {immediate: true, deep: true})
    //
    getSearchItem(newVal, oldVal) {
        if (newVal != "" || this.loaded) {
            this.pagination.pageIndex = 1;
            this.getSearch(newVal);
            this.currentIndex = 3;
        }
    }

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        switch (this.currentIndex) {
            case 0:
                this.getList();
                break;
            case 1:
                this.getOrderFormList(this.statusKey);
                break;
            case 2:
                this.getServiceList(this.serviceCurStatus);
                break;
            case 3:
                this.getSearch(this.searchItem);
        }
    }

    //———查询商品订单列表———
    getList() {
        orderFormList({
            ...this.pagination,
            status: this.status === 0 ? null : this.status
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.pagination.pageSize = data.pageSize;
            this.loading=false;
        });

    }

    //商品名称/商品订单号搜索查询
    getSearch(searchInput: any) {
        orderFormList({
            ...this.pagination,
            queryKey: searchInput
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.pagination.pageSize = data.pageSize;
        });
    }

    //商品订单状态下拉查询
    getOrderFormList(status: any) {
        if (status === null) status = "";
        orderFormList({
            ...this.pagination,
            status: status
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.pagination.pageSize = data.pageSize;
        });
    }

    getServiceListCondition = {serviceStyle: null, serviceStatus: null};

    getServiceList(status: any, type = "state") {
        const obj: any = {
            ...this.pagination,
            queryStatus: this.status,
            ...this.getServiceListCondition
        };
        if (!obj.serviceStyle) delete obj.serviceStyle;
        if (!obj.serviceStatus) delete obj.serviceStatus;
        getServiceList(obj).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.pagination.pageSize = data.pageSize;
            let that: any = this;
            that.data = [];
            data.returnInfos.forEach((el, i) => {
                let newObj: Object = [
                    {
                        contactPerson: el.ifShop
                            ? el.contactPersonDetail.shopName
                            : el.contactPersonDetail.receiverName,
                        ifRedund: el.ifRedund,
                        price: el.price,
                        quantity: el.quantity,
                        refundId: el.refundId,
                        refundStatus: el.refundStatus,
                        refundStatusDic: el.refundStatusDic,
                        relationId: el.servicRelationId,
                        serviceNum: el.serviceNum,
                        serviceFee: el.serviceFee,
                        serviceId: el.serviceId,
                        serviceName: el.serviceName,
                        servicePic: el.servicePicture,
                        serviceStatus: el.serviceStatus,
                        serviceStyle: el.serviceStyle,
                        serviceType: el.serviceType,
                        servicesStatusDic: el.servicesStatusDic
                    }
                ];
                Vue.set(el, "services", newObj);
                Vue.set(el, "orderCode", el.serviceOrderSeriesNum);
                Vue.set(el, "totalAmount", el.price);
                Vue.set(el, "serviceOrderInfoId", el.servicOrderId);
                // Vue.set(el, "relationId", el.servicRelationId);

                (this as any).data.push(el);
            });
        });
    }

    //获取商品订单状态
    dhabjshsacj = "订单状态";

    //获取订单状态
    visibleChange(key: string) {
        this.goodsState = false;
        this.emptyTextBySearch = false;
        this.$emit("changeKey");
        this.currentIndex = 1;
        this.statusKey = +key.split("$")[0];
        this.dhabjshsacj = key.split("$")[1];
        this.pagination.pageIndex = 1;
        if(key==="0$订单状态"){
            this.getOrderFormList(0);
        }else{
            this.getOrderFormList(key.split("$")[0]);
        }

    }

    setCancelModal(flag: boolean) {
        this.cancelVisible = flag;
    }


    againGetList() {
        this.pagination.pageIndex = 1;
        this.getList();
    }

    //跳转到商城页
    jumpToMall(e) {
        open("/mall")
    }
}

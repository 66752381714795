










































    import {Component, Prop, Vue} from "vue-property-decorator";
    import PicItem from "./PicItem.vue";

    @Component({
  components: {
    PicItem
  }
})
export default class YlmsContent extends Vue {
  @Prop() private orgList!: Array<[]>;

  clickNextCount: number = 0;
  canPre: boolean = false;
  canNext: boolean = true;

  leftOffset: number = 0;
  itemCount: number = 0;
  maxPage = 0;
  mounted() {
    setTimeout(() => {
      this.itemCount = this.orgList.length;
      this.maxPage = Math.floor(this.itemCount / 4) - 1;
    }, 1000);
  }

  handleNext() {
    if (this.clickNextCount < this.maxPage) {
      this.leftOffset -= 12.9;
      this.clickNextCount++;
      this.canPre = true;
      this.canNext = true;
    }
    if (this.clickNextCount == this.maxPage) {
      this.canNext = false;
    }
  }
  toPage(item) {
    if (item.fatherId != "") {
      this.$router.push(`/orgDetail/${item.fatherId}`);
    } else {
      this.$router.push(`/orgDetail/${item.orgId}`);
    }
  }
  handlePre() {
    if (this.clickNextCount > 0) {
      this.leftOffset += 12.9;
      this.clickNextCount--;
      this.canPre = true;
      this.canNext = true;
    }
    if (this.clickNextCount == 0) {
      this.canPre = false;
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('Nav'),_c('div',{staticClass:"serviceJoinStep3"},[_c('div',{staticStyle:{"width":"10rem","margin":"0 auto","margintop":"-0.4rem"}},[_c('ReturnStep',{attrs:{"curStep":3,"stepList":_vm.stepList}})],1),_c('div',{staticClass:"form-box"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"服务商账号"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'orgAccount',
              {
                trigger: 'blur',
                rules: [
                  {
                    required: true,
                    validator: this.checkExist.bind(this),
                  } ],
                initialValue: _vm.formData.shopInfo.orgAccount,
              } ]),expression:"[\n              'orgAccount',\n              {\n                trigger: 'blur',\n                rules: [\n                  {\n                    required: true,\n                    validator: this.checkExist.bind(this),\n                  },\n                ],\n                initialValue: formData.shopInfo.orgAccount,\n              },\n            ]"}],staticStyle:{"width":"4.2rem"},attrs:{"placeholder":"服务商账号不能为空"}}),_c('p',{staticStyle:{"color":"#a9a9a9","font-size":"0.15rem","position":"absolute","left":"4.4rem","width":"7rem","top":"-0.15rem"}},[_vm._v("此帐号为日后登录并管理服务商管理中心时使用，注册后不可修改，请牢记。默认密码123456")])],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'orgName',
              {
                trigger: 'blur',
                rules: [
                  {
                    required: true,
                    validator: this.checkExistName.bind(this),
                  } ],
                initialValue: _vm.formData.shopInfo.orgName,
              } ]),expression:"[\n              'orgName',\n              {\n                trigger: 'blur',\n                rules: [\n                  {\n                    required: true,\n                    validator: this.checkExistName.bind(this),\n                  },\n                ],\n                initialValue: formData.shopInfo.orgName,\n              },\n            ]"}],staticStyle:{"width":"4.2rem"},attrs:{"placeholder":"请输入服务商名称"}}),_c('p',{staticStyle:{"color":"#a9a9a9","font-size":"0.15rem","position":"absolute","left":"4.4rem","width":"7rem","top":"-0.15rem"}},[_vm._v("服务商名称注册后不可修改，请认真填写")])],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商养老模式"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'orgForm',
              {
                rules: [
                  {
                    required: true,
                    message: '服务商养老模式不能为空',
                  } ],
                initialValue: _vm.initialValue.orgForm,
              } ]),expression:"[\n              'orgForm',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '服务商养老模式不能为空',\n                  },\n                ],\n                initialValue: initialValue.orgForm,\n              },\n            ]"}],staticStyle:{"width":"4.2rem","font-size":"0.16rem"},attrs:{"mode":"multiple","placeholder":"请选择服务商养老模式"}},_vm._l((_vm.serviceType),function(item){return _c('a-select-option',{key:item.key,staticStyle:{"font-size":"0.16rem"},attrs:{"value":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商类别"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ndewi',
              {
                rules: [
                  {
                    required: true,
                    message: '服务商类别不能为空',
                  } ],
                initialValue: _vm.initialValue.ndewi,
              } ]),expression:"[\n              'ndewi',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '服务商类别不能为空',\n                  },\n                ],\n                initialValue: initialValue.ndewi,\n              },\n            ]"}],staticStyle:{"width":"3rem","font-size":"0.16rem"},attrs:{"cleanable":"","placeholder":"请选择服务商类别"},on:{"change":_vm.ndewichange}},_vm._l((_vm.orgType),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"主体性质"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'fnre',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择服务商主体性质',
                  } ],
                initialValue: _vm.initialValue.fnre,
              } ]),expression:"[\n              'fnre',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择服务商主体性质',\n                  },\n                ],\n                initialValue: initialValue.fnre,\n              },\n            ]"}]},_vm._l((_vm.fnreType),function(item){return _c('a-radio',{key:item.key,attrs:{"value":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"收住对象"}},'a-form-item',_vm.formItemLayout,false),[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'refcev',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择服务商收住对象',
                  } ],
                initialValue: _vm.initialValue.refcev,
              } ]),expression:"[\n              'refcev',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择服务商收住对象',\n                  },\n                ],\n                initialValue: initialValue.refcev,\n              },\n            ]"}],attrs:{"options":_vm.plainOptions}})],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商所在地"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'freoij',
              {
                rules: [
                  {
                    required: true,
                    validator: this.addrcheck.bind(this),
                  } ],
                // initialValue: formData.expansionInfo.orgIntro
              } ]),expression:"[\n              'freoij',\n              {\n                rules: [\n                  {\n                    required: true,\n                    validator: this.addrcheck.bind(this),\n                  },\n                ],\n                // initialValue: formData.expansionInfo.orgIntro\n              },\n            ]"}],staticStyle:{"width":"4.2rem","font-size":"0.16rem"},attrs:{"options":_vm.options,"loadData":_vm.loadData,"placeholder":_vm.addrPlaceHolder},on:{"change":_vm.selectAddress}})],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'addressDetail',
              {
                rules: [
                  {
                    required: true,
                    message: '服务商详细地址不能为空',
                  } ],
                initialValue: _vm.formData.shopInfo.addressDetail,
              } ]),expression:"[\n              'addressDetail',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '服务商详细地址不能为空',\n                  },\n                ],\n                initialValue: formData.shopInfo.addressDetail,\n              },\n            ]"}],staticStyle:{"width":"4.2rem"},attrs:{"placeholder":"请输入服务商详细地址"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"床位数"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'bedNum',
              {
                rules: [
                  {
                    required: true,
                    message: '服务商床位数不能为空',
                  } ],
                initialValue: _vm.formData.shopInfo.bedNum,
              } ]),expression:"[\n              'bedNum',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '服务商床位数不能为空',\n                  },\n                ],\n                initialValue: formData.shopInfo.bedNum,\n              },\n            ]"}],staticStyle:{"width":"3rem","font-size":"0.16rem"},attrs:{"type":"number","min":"0","placeholder":"请输入服务商床位数"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"最低价格（元）"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'lowestPrice',
              {
                trigger: 'blur',
                rules: [
                  {
                    required: true,
                    validator: this.formater.bind(this),
                  } ],
                initialValue: _vm.formData.shopInfo.lowestPrice,
              } ]),expression:"[\n              'lowestPrice',\n              {\n                trigger: 'blur',\n                rules: [\n                  {\n                    required: true,\n                    validator: this.formater.bind(this),\n                  },\n                ],\n                initialValue: formData.shopInfo.lowestPrice,\n              },\n            ]"}],staticStyle:{"width":"3rem","font-size":"0.16rem"},attrs:{"placeholder":"请输入服务商最低价格","addon-before":"￥"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"最高价格（元）"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'highestPrice',
              {
                trigger: 'blur',
                rules: [
                  {
                    required: true,
                    validator: this.maxThanMin.bind(this),
                  } ],
                initialValue: _vm.formData.shopInfo.highestPrice,
              } ]),expression:"[\n              'highestPrice',\n              {\n                trigger: 'blur',\n                rules: [\n                  {\n                    required: true,\n                    validator: this.maxThanMin.bind(this),\n                  },\n                ],\n                initialValue: formData.shopInfo.highestPrice,\n              },\n            ]"}],staticStyle:{"width":"3rem","font-size":"0.16rem"},attrs:{"addon-before":"￥","placeholder":"请输入服务商最高价格"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"服务商电话"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'orgTel',
              {
                rules: [
                  {
                    required: true,
                    validator: this.phoneCheck.bind(this),
                  } ],
                initialValue: _vm.formData.shopInfo.orgTel,
              } ]),expression:"[\n              'orgTel',\n              {\n                rules: [\n                  {\n                    required: true,\n                    validator: this.phoneCheck.bind(this),\n                  },\n                ],\n                initialValue: formData.shopInfo.orgTel,\n              },\n            ]"}],staticStyle:{"width":"4.2rem","font-size":"0.16rem"},attrs:{"placeholder":"请输入服务商电话,如010-12345678,13812345678"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"营业时间"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'businessHours',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择服务商营业时间！',
                  },
                  {
                    validator: this.checkBusinessTime.bind(this),
                  } ],
                initialValue: _vm.needSetBusinessTime,
              } ]),expression:"[\n              'businessHours',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择服务商营业时间！',\n                  },\n                  {\n                    validator: this.checkBusinessTime.bind(this),\n                  },\n                ],\n                initialValue: needSetBusinessTime,\n              },\n            ]"}],on:{"change":_vm.onBusinessHoursChange}},[_c('a-radio',{attrs:{"value":false}},[_vm._v("全天")]),_c('a-radio',{attrs:{"value":true}},[_vm._v("自定义")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.needSetBusinessTime),expression:"needSetBusinessTime"}],staticStyle:{"margin-top":"0.1rem"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'opentime',
                  {
                    initialValue: _vm.formData.shopInfo.openTime
                      ? _vm.moment(_vm.formData.shopInfo.openTime, 'HH:mm:ss')
                      : _vm.moment('00:00:00', 'HH:mm:ss'),
                  } ]),expression:"[\n                  'opentime',\n                  {\n                    initialValue: formData.shopInfo.openTime\n                      ? moment(formData.shopInfo.openTime, 'HH:mm:ss')\n                      : moment('00:00:00', 'HH:mm:ss'),\n                  },\n                ]"}],attrs:{"default-open-value":_vm.moment('00:00', 'HH:mm'),"placeholder":"开始时间","format":"HH:mm"}}),_vm._v("- "),_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'closetime',
                  {
                    initialValue: _vm.formData.shopInfo.closeTime
                      ? _vm.moment(_vm.formData.shopInfo.closeTime, 'HH:mm:ss')
                      : _vm.moment('00:00:00', 'HH:mm:ss'),
                  } ]),expression:"[\n                  'closetime',\n                  {\n                    initialValue: formData.shopInfo.closeTime\n                      ? moment(formData.shopInfo.closeTime, 'HH:mm:ss')\n                      : moment('00:00:00', 'HH:mm:ss'),\n                  },\n                ]"}],attrs:{"default-open-value":_vm.moment('00:00', 'HH:mm'),"placeholder":"结束时间","format":"HH:mm"}})],1)],1)],1),_c('a-form-item',_vm._b({attrs:{"label":"服务类目"}},'a-form-item',_vm.formItemLayout,false),[_vm._l((_vm.list),function(item){return _c('div',{key:item},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("refr" + item),
                {
                  rules: [
                    {
                      required: true,
                      validator: _vm.onChange,
                    } ],
                  // initialValue: formData.expansionInfo.orgIntro
                } ]),expression:"[\n                `refr${item}`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      validator: onChange,\n                    },\n                  ],\n                  // initialValue: formData.expansionInfo.orgIntro\n                },\n              ]"}],staticStyle:{"width":"4.2rem","font-size":"0.16rem"},attrs:{"options":_vm.serviceTypes,"placeholder":"请选择服务类目"}}),(item > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",staticStyle:{"marginleft":"5px"},attrs:{"type":"minus-circle-o"},on:{"click":function () { return _vm.remove(item); }}}):_vm._e()],1)}),(_vm.showRepeat)?_c('span',{staticStyle:{"color":"#f00"}},[_vm._v(" 所选服务类目已重复，请重新选择 "),_c('br')]):_vm._e(),_c('span',{staticStyle:{"color":"#60c7c6","fontsize":"0.14rem","cursor":"pointer"},on:{"click":_vm.add}},[_c('a-icon',{staticStyle:{"marginright":"5px"},attrs:{"type":"plus-circle"}}),_vm._v("新增服务类目 ")],1)],2),_c('a-form-item',_vm._b({attrs:{"label":"服务商特色","id":"djaksdniodljnak"}},'a-form-item',_vm.formItemLayout,false),[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'fwts',
              {
                rules: [],
                initialValue: _vm.initialValue.fwts,
              } ]),expression:"[\n              'fwts',\n              {\n                rules: [],\n                initialValue: initialValue.fwts,\n              },\n            ]"}]},_vm._l((_vm.serviceList),function(item){return _c('div',{key:item.key},[_c('a-checkbox',{attrs:{"value":item.key}},[_c('div',{staticClass:"tdajosnBox",style:({
                    borderColor: _vm.tdajosnBoxClick(item.key) ? '#60c7c6' : '',
                  })},[_c('h2',[_vm._v(_vm._s(item.value))]),_c('div',[_vm._v(_vm._s(item.desc))])])])],1)}),0)],1),_c('a-form-item',_vm._b({staticStyle:{"margin":"1rem 0"}},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem","marginright":"1rem","background":"#fff","color":"#60c7c6"},attrs:{"type":"primary"},on:{"click":_vm.back}},[_vm._v("上一步")]),(_vm.buttonSet.enableClick)?_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem"},attrs:{"loading":_vm.buttonSet.loading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提交审核")]):_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem"},attrs:{"loading":_vm.buttonSet.loading,"type":"primary","disabled":""}},[_vm._v("提交审核")]),_c('a-modal',{attrs:{"footer":null,"centered":"","closable":false,"afterClose":_vm.handleClickConfirm},on:{"ok":_vm.handleClickConfirm},model:{value:(_vm.visibleConfirm),callback:function ($$v) {_vm.visibleConfirm=$$v},expression:"visibleConfirm"}},[_c('Confirm'),_c('div',{staticStyle:{"text-align":"center"}},[_c('a-button',{staticStyle:{"width":"50%"},attrs:{"type":"primary"},on:{"click":_vm.handleClickConfirm}},[_vm._v("确认")])],1)],1)],1)],1)],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }





















































































  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
  import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
  import {baseUrl} from "../../services/http"
  import aSpin from "@/components/aSpin.vue";

  @Component({
  components: {
    PersonalHeader,
    OpenServiceItem,
    aSpin
  },
})
export default class extends Vue {
  @Prop() topLis: any;
  @Prop() data: any;
  @Prop() pagination: any;
  currentRelationId = "";
  currentServiceType = 0;
  currentTab = 1;
  serviceStatus = 0;
  baseUrl = baseUrl;
  loading:any=true;
  @Watch("data", {immediate: true, deep: true})
  getData(newVal) {
    this.loading=false
  }

  mounted() {
    // this.getServiceList();
  }

  // 点击查看退款记录
  handleRecordCheck(id) {
    this.$emit("handleRecordCheck", id);
  }

  // 点击删除退款记录
  handleRecordDelete(id) {
    this.$emit("handleRecordDelete", id);
  }

  defaultValue = "";

  // 退款状态字典
  formatStatusDic(value: any) {

    // 203,请等待服务商处理
    // 204,退款成功
    // 205,退款失败
    // 309,退款关闭
    // 435,服务商拒绝退款
    // 503,等待平台处理
    // 715,退款待处理

    let descrption = "";
    switch (value) {
      case 203: // 请等待服务商处理
        descrption = "退款待处理";
        break;
      case 204: // 退款成功
        descrption = "退款成功";
        break;
      case 205: // 退款失败
        descrption = "退款失败";
        break;
      case 309: // 退款关闭
        descrption = "退款关闭";
        break;
      case 358: //
        descrption = "已完成";
        break;
      case 435: // 服务商拒绝退款
        descrption = "已拒绝退款";
        break;
      default:
        descrption = "待退款";
        break;
    }
    return descrption;
  }

  emptyTextBySearch = false;

  onChangePageSize(page, pageSize) {
    window.scrollTo({top: 0});
    this.pagination.pageIndex = page;
    this.$emit("getReturnRecord");
  }


}
















































import {Component, Vue} from "vue-property-decorator";
import {postCancelCollect, topside} from "../../../services/api";
import {baseUrl} from "../../../services/http";
import {message} from "ant-design-vue";

@Component({
    props: {
        data: Object
    }
})
export default class Collection extends Vue {
    baseUrl = baseUrl;
    serveCancel = false;

    // 点击进入服务商
    handleGoServiceSotre() {
        const {orgId} = this.$props.data;
        this.$router.push({
            path: "/orgDetail/" + orgId
        });
    }

    handleGoService() {
        const {serviceId} = this.$props.data;
        this.$router.push({
            path: "/cookInHome/" + serviceId
        });
    }


    //置顶、取消置顶
    cancelTheTop(value) {

        topside({
            collectionId: value,
        }).then(res => {
            if (res && res.code === 0) {
                // 处理数据
                this.$emit("updateServiceCollectList");
            } else {
                message.error(res.msg);
            }
        });
    }

    // 点击取消收藏
    handleCancelCollect() {
        this.serveCancel = true

    }
    //执行取消收藏
    enshrine(){
        const that = this;
        postCancelCollect({
            serviceCollectId: this.$props.data.serviceCollectId,
            contenId: 237
        }).then(res => {
            if (res && res.code === 0) {
                // 处理数据
                that.$emit("updateServiceCollectList");
                this.serveCancel = false
            } else {
                message.error(res.msg);
            }
        });

    }

}

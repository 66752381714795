export default {
  imToolInit: imToolInit,
  loadConversationList: loadConversationList,
  fillUserInfoToConversationList: fillUserInfoToConversationList,
  loadHistory: loadHistory,
  getTempConversation: getTempConversation,
  destoryConversation: destoryConversation,
  sendMessage: sendMessage,
  uploadImg: uploadImg,
  sendServiceOrder: sendServiceOrder,
  sendService: sendService,
  sendCommodityOrder: sendCommodityOrder,
  sendCommodity: sendCommodity,
  mergeConversation: mergeConversation,
  getEmojiList: getEmojiList,
}
/**
 * 加载依赖js
 */

//把RongIMLib挂载到window对象上
const RongIMLib = require('@rongcloud/imlib-v4');
window.RongIMLib = RongIMLib;
//把RongIMEmoji挂载到RongIMLib对象上
RongIMLib.RongIMEmoji = require('./RongEmoji-2.2.9.min.js')
//send-data.js配置window.uploadProcess
require('./send-data.js')
//upload.js配置window.UploadFile
require('./upload.js')
//获取UploadClient
const UploadClient = require('./init.js')

/**
 * 变量定义
 */
let ajaxMethod = undefined;
let im = {};
let token = undefined;
let defaultCallbackSetting = {
  // 监听会话列表变更事件
  conversation(event) {
    // 假定存在 getExistedConversationList 方法，以获取当前已存在的会话列表数据
    // const conversationList = getExistedConversationList()
    const conversationList = []
    // 发生变更的会话列表
    const updatedConversationList = event.updatedConversationList;
    // 通过 im.Conversation.merge 计算最新的会话列表
    // const latestConversationList = im.Conversation.merge({conversationList, updatedConversationList})
  },
  // 监听消息通知
  message(event) {
    // 新接收到的消息内容
    const message = event.message;
  },
  // 监听 IM 连接状态变化
  status(event) {
  },
  // 监听聊天室 KV 数据变更
  chatroom(event) {
    /**
     * 聊天室 KV 存储数据更新
     * @example
     * [
     *  {
     *    "key": "name",
     *    "value": "我是小融融",
     *    "timestamp": 1597591258338,
     *    "chatroomId": "z002",
     *    "type": 1 // 1: 更新（ 含:修改和新增 ）、2: 删除
     *  },
     * ]
     */
    const updatedEntries = event.updatedEntries
  },
  expansion(event) {
    /**
     * 更新的消息拓展数据
     * @example {
     *    expansion: { key: 'value' },      // 设置或更新的扩展值
     *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
     * }
     */
    const updatedExpansion = event.updatedExpansion;
    /**
     * 删除的消息拓展数据
     * @example {
     *    deletedKeys: ['key1', 'key2'],    // 设置或更新的扩展值
     *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
     * }
     */
    const deletedExpansion = event.deletedExpansion;
  },
};
let APP_KEY = '8luwapkv862jl';
let EMOJI_LIST = RongIMLib.RongIMEmoji.list;

/**
 * 初始化IM
 * @param callbackSetting 各种情况的回调函数设置，参考defaultCallbackSetting
 * @returns {Promise<void>}
 */
async function imToolInit({localAjaxMethod, callbackSetting}) {
  if(!localAjaxMethod) {
    console.error('localAjaxMethod不能为空，解构参数：{url, method, params, data, onSuccess, onError}')
  }
  ajaxMethod = localAjaxMethod
  console.log("应用初始化以获取 RongIMLib 实例对象，请务必保证此过程只被执行一次")
  im = await RongIMLib.init({ appkey: APP_KEY });
  console.log("应用初始化完成")
  //注册自定义消息
  im.registerMessageType('FYTL:Service', true, true);
  im.registerMessageType('FYTL:ServiceOrder', true, true);
  im.registerMessageType('FYTL:Commodity', true, true);
  im.registerMessageType('FYTL:CommodityOrder', true, true);
  //配置callback
  setCallback(callbackSetting);
  let userInfo = await loginUser();
  console.log("im ok!")
  RongIMLib.RongIMEmoji.init();
  console.log("RongIMEmoji ok!",RongIMLib.RongIMEmoji.list)
  return userInfo;
}

/**
 * 获取会话列表（IM默认保存6个月）
 * @param resultList   会话列表（请求结束后，若未定义onSuccess方法将会把记录填充到这里）
 * @param onSuccess    可选参数-获取成功的回调函数（若未定义，则默认把记录填充回resultList）
 * @param onFail       可选参数-获取失败的回调函数（若未定义，则默认打印在控制台）
 */
async function loadConversationList({resultList, onSuccess, onFail, callback}) {
  // 获取会话列表
  await im.Conversation.getList().then(async conversationList => {
    if(onSuccess) {
      onSuccess(conversationList)
    } else {
      console.log('获取会话列表成功，开始请求会话对象信息');
      resultList.splice(0, resultList.length);
      await fillUserInfoToConversationList(conversationList);
      Object.assign(resultList, conversationList)
      console.log("resultList",resultList)
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('获取会话列表失败: ', error.code, error.msg);
    }
  });
  if(callback) {
    callback()
  }
}

/**
 * 向传入的conversationList添加用户的平台信息（服务商、店铺id、头像、昵称）
 */
async function fillUserInfoToConversationList(conversationList) {
  let ids = [];
  conversationList.forEach((item) => {
    ids.push(item.targetId);
  })
  let userInfoMap = {}
  await ajaxMethod({
    url: '/im/users',
    method: 'post',
    data: {
      userIdList: ids
    },
    onSuccess: (data) => {
      if (data && data.code === 0) {
        userInfoMap = data.data
      } else {
        console.error(data.msg)
        return
      }
    }
  })
  conversationList.forEach((item) => {
    Object.assign(item, userInfoMap[item.targetId])
  })
}

/**
 * 通过chat界面的入口数据entryParams像服务器请求一个伪造的临时session用来显示会话（若对方im未注册也会同时注册一个新用户）
 * @param entryParams
 * @returns {Promise<void>}
 */
async function getTempConversation(entryParams) {
  let tempSession;
  await ajaxMethod({
    url: '/im/session',
    method: 'post',
    data: entryParams,
    onSuccess: async (res) => {
      console.log(res)
      tempSession = res.data
    }
  })
  return tempSession;
}

/**
 * 获取指定对象会话历史
 * @param targetId        指定会话对象id
 * @param resultList      聊天记录列表（若未设置onSuccess则默认将返回值填充到这里）
 * @param onSuccess
 * @param onFail
 * @returns               返回是否还有更多聊天记录
 */
async function loadHistory({targetId, resultList, timestamp, onSuccess, onFail, callback}) {
  let conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  const option = {
    // 获取历史消息的时间戳，默认为 0，表示从当前时间获取
    timestamp: timestamp ? timestamp : +new Date(),
    // 获取条数，有效值 1-20，默认为 20
    count: 20,
  };
  await conversation.getMessages(option).then(result => {
    if(onSuccess) {
      onSuccess(result)
    } else {
      result.list.forEach((item) => {
        resultList.push(item)
      })
      console.log('获取历史消息成功', result.list, result.hasMore);
      return result.hasMore; // 是否还有历史消息可获取
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送文字消息失败', error.code, error.msg);
    }
  });
  if(callback) {
    callback()
  }
}

/**
 * 销毁指定会话
 * @param targetId
 * @returns {Promise<void>}
 */
async function destoryConversation({targetId, callback}) {
  // conversation 会话实例
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  await conversation.destory().then(() => {
    console.log('删除会话成功')
    callback()
  });
}

/**
 * 发送消息
 * @param targetId    对方id
 * @param content     消息内容
 * @param onSuccess   成功回调
 * @param onFail      失败回调
 */
function sendMessage({targetId, content, onSuccess, onFail}) {
  // 获取指定会话的抽象实例，对于会话的操作基于此实例完成
  const conversation = im.Conversation.get({
    targetId: targetId,
    // 会话类型：RongIMLib.CONVERSATION_TYPE.PRIVATE | RongIMLib.CONVERSATION_TYPE.GROUP
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  // 向会话内发消息
  conversation.send({
    // 消息类型，其中 RongIMLib.MESSAGE_TYPE 为 IMLib 内部的内置消息类型常量定义
    messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
    // 消息内容
    content: {
      content: content // 文本内容
    }
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    } else {
      console.log('发送文字消息成功', message);
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送文字消息失败', error.code, error.msg);
    }
  });
}

/**
 * 上传图片
 * @param file
 * @param autoSend          上传成功后是否自动发送（false则返回图片url）
 * @param targetId          autoSend为true时必填
 * @param onSuccess         消息发送成功的回调
 * @param uploadCallbacks
 */
async function uploadImg({file, autoSend, targetId, onSuccess, uploadCallbacks}) {
  console.log('------获取数据123132231------');
  console.log(file)
  // console.log(autoSend)
  // console.log(onSuccess)
  // // console.log(uploadCallbacks)
  console.log('------获取数据123132231结束------');
  if(autoSend && !targetId) {
    console.error('autoSend为true时，targetId不能为空')
    return;
  }
  let fileType = RongIMLib.FILE_TYPE.IMAGE;
  // console.log(fileType)  //1
  let config = {
    domain: 'upload.qiniu.com',
    fileType: fileType,
    getToken: async function(callback) {
      await im.getFileToken(fileType).then(function(data) {
        console.log('获取上传token成功', data)
        callback(data.token, data);
      }).catch(function(error) {
        console.log('获取上传 token 失败', error);
      });
    }
  };
  await UploadClient.initImage(config, async function(uploadFile) {
    console.log('gggggg-----======');
    console.log(file);
    console.log(uploadCallbacks)
    await uploadFile.upload(file, uploadCallbacks);
  });

  uploadCallbacks = uploadCallbacks ? uploadCallbacks : {
    onProgress: function(loaded, total) {
      let percent = Math.floor(loaded / total * 100);
      console.log('已上传: ', percent);
    },
    onCompleted: async function(data) {
      // 上传完成, 调用 getFileUrl 获取文件下载 url
      console.log('上传成功: ', data);
      let filename = data.filename; // 通过 uploadCallbacks 的 onCompleted 中返回的 data 获取
      let oriname = data.name; // 通过 uploadCallbacks 的 onCompleted 中返回的 data 获取
      let base64 = data.thumbnail
      await im.getFileUrl(fileType, filename, oriname, data, 1).then(async function(data) {

        console.log('文件 url 为: ', data.downloadUrl);
        if(autoSend) {
          console.log("-----rrrrrrrrrrrrrrrrrrr-----");
         // console.log(base64);
          console.log( data.downloadUrl)
          console.log(targetId)
          //console.log(onSuccess)

          await sendImg({
            base64: base64,
            url: data.downloadUrl,
            targetId: targetId,
            onSuccess: onSuccess
          });
        } else {
          return data.downloadUrl;
        }
      }).catch(function(error) {
        console.log('获取文件 url 失败', error);
      });
    },
    onError: function(error) {
      console.error('上传失败', error);
    }
  };

}

/**
 * 发送图片消息
 * @param base64
 * @param url
 * @param targetId
 * @param onSuccess
 */
function sendImg({base64, url, targetId, onSuccess}) {
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  conversation.send({
    messageType: RongIMLib.MESSAGE_TYPE.IMAGE, // 'RC:ImgMsg'
    content: {
      content: base64, // // 压缩后的 base64 略缩图, 用来快速展示图片
      imageUri: url // 上传到服务器的 url. 用来展示高清图片
    }
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    }
    console.log('发送图片消息成功', message);
  });
}

/**
 * 发送消息(服务）
 * @param targetId    对方id
 * @param content     消息内容 {serviceId,relationId}
 * @param onSuccess   成功回调
 * @param onFail      失败回调
 */
function sendService({targetId, content, onSuccess, onFail}) {
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  conversation.send({
    messageType: 'FYTL:Service', // 填写开发者定义的 messageType
    content: content
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    } else {
      console.log('发送服务消息成功', message);
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送服务消息失败', error.code, error.msg);
    }
  });
}

/**
 * 发送消息(服务订单）
 * @param targetId    对方id
 * @param content     消息内容 {serviceId,relationId}
 * @param onSuccess   成功回调
 * @param onFail      失败回调
 */
function sendServiceOrder({targetId, content, onSuccess, onFail}) {
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  conversation.send({
    messageType: 'FYTL:ServiceOrder', // 填写开发者定义的 messageType
    content: content
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    } else {
      console.log('发送服务订单消息成功', message);
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送服务订单消息失败', error.code, error.msg);
    }
  });
}



/**
 * 发送消息(商品）
 * @param targetId    对方id
 * @param content     消息内容
 * @param onSuccess   成功回调
 * @param onFail      失败回调
 */
function sendCommodity({targetId, content, onSuccess, onFail}) {
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  conversation.send({
    messageType: 'FYTL:Commodity', // 填写开发者定义的 messageType
    content: content
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    } else {
      console.log('发送商品消息成功', message);
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送商品消息失败', error.code, error.msg);
    }
  });
}

/**
 * 发送消息(商品订单）
 * @param targetId    对方id
 * @param content     消息内容 {serviceId,relationId}
 * @param onSuccess   成功回调
 * @param onFail      失败回调
 */
function sendCommodityOrder({targetId, content, onSuccess, onFail}) {
  const conversation = im.Conversation.get({
    targetId: targetId,
    type: RongIMLib.CONVERSATION_TYPE.PRIVATE
  });
  conversation.send({
    messageType: 'FYTL:CommodityOrder', // 填写开发者定义的 messageType
    content: content
  }).then(function(message){
    if(onSuccess) {
      onSuccess(message)
    } else {
      console.log('发送商品订单消息成功', message);
    }
  }).catch(error => {
    if(onFail) {
      onFail(error)
    } else {
      console.log('发送商品订单消息失败', error.code, error.msg);
    }
  });
}



/**
 * 合并会话列表
 * @param data
 * @returns {any[]}
 */
function mergeConversation(data) {
  return im.Conversation.merge(data);
}

function getEmojiList() {
  if(EMOJI_LIST.length == 0) {
    RongIMLib.RongIMEmoji.init();
    console.log('RongIMEmoji ok!')
  }
  return EMOJI_LIST;
}

/***********************************************
 *          ！！！！！！注意！！！！！！            *
 * 以下的所有函数都是封装好的代码无需修改且无需关注。   *
 *                                             *
 *                                             *
 ***********************************************/
function setCallback(callbackSetting) {
  console.log("开始设置回调")
  im.watch(callbackSetting ? callbackSetting : defaultCallbackSetting);
  console.log(callbackSetting ? "callback set ok!" : "未检测到callback配置，使用默认设置")
}
async function loginUser() {
  /**
   * 请求token并登陆
   */
  let userInfo = undefined;
  console.log('请求token并登陆');
  await ajaxMethod({
    url: '/im/token',
    method: 'get',
    onSuccess: async (data) => {
      if (data && data.code === 0) {
        if (data.token != undefined) {
          token = data.token
          console.log('token请求成功' + data.token);
          await im.connect({
            token: token
          }).then((user) => {
            console.log('链接成功, 链接用户 id 为: ', user.id);
          }).catch((error) => {
            console.log('链接失败: ', error.code, error.msg);
          });
          userInfo = data.data;//当前登录用户信息
        } else {
          console.error(data.msg)
        }
      } else {
        console.error(data.msg)
      }
    }
  })
  return userInfo;
}

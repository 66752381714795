




































































    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({
  components: {}
})
export default class extends Vue {
  private rightShow: boolean = false;
  private leftShow: boolean = false;
  private index: Number = 0;
  @Prop({
    type: Array,
    required: true
  })
  public subServiceList!: Array<[]>;
  mounted() {
    this.currentMenu(0);
  }
  public currentMenu(curindex: number) {
    let length = this.subServiceList.length;
    this.rightShow = length <= (curindex + 1) * 4 ? false : true;
    this.leftShow = length > curindex * 4 && curindex > 0 ? true : false;
    return this.subServiceList.slice(curindex * 4, (curindex + 1) * 4);
  }
  toDetail(subOrgId) {
    this.$router.push({
      path: "/servieItemDetailAll",
      query: {
        orgId: subOrgId
      }
    });
  }
}












































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Table } from "ant-design-vue";

Vue.use(Table);

@Component({
  components: {}
})
export default class extends Vue {
  columns = [
    {
      title: "序号",
      dataIndex: "xh",
      align: "center"
    },
    {
      title: "家庭成员",
      dataIndex: "cy",
      align: "center"
    },
    {
      title: "关系",
      dataIndex: "gx",
      align: "center"
    },
    {
      title: "家庭圈身份",
      dataIndex: "sf",
      align: "center"
    },
    {
      title: "联系电话",
      dataIndex: "phone",
      align: "center"
    },
    {
      title: "身份证编号",
      dataIndex: "bh",
      align: "center"
    },
    {
      title: "代币",
      dataIndex: "db",
      align: "center"
    },
    {
      title: "邀请日期",
      dataIndex: "date",
      align: "center"
    }
  ];
  data = [
    // {
    //   xh: "1",
    //   cy: "张三",
    //   date: "2019.11.9",
    //   db: "100",
    //   bh: "1037789738927197",
    //   phone: "1757787676",
    //   sf: "普通",
    //   gx: "父子"
    // }
  ];
}

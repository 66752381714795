<template>
  <div>
    <Header />
<!--    <Nav />-->
    <div class="joinRead">
      <div class="stepTop">
        <img src="../../assets/images/homeImg/组 11@2x.png" height="240" width="3840"/>
        <div class="rz-type">商家入驻</div>
      </div>
      <div class="progressBar">
        <MyStepBar :stepData="stepData" :status="1"  />
      </div>
      <div class="container">
        <div v-html="getStr" style="text-align:left;cursor:default;color: red;"></div>
      </div>
      <a-checkbox
        @change="onChange"
        style="marginBottom: 0.4rem;marginTop: -0.4rem;"
        >我已阅读并同意以上协议</a-checkbox
      >
      <div class="present">
        <a-button class="button2" type="primary" v-if="checked"  @click="next">下一步</a-button>
        <a-button type="primary" v-else disabled style="background: #f5f5f5; border-color: #d9d9d9; color:rgba(0,0,0,.25);display: block;" @click="next">下一步
        </a-button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import ReturnStep from "@/components/ReturnStep.vue";
    import {Button, Checkbox, message} from "ant-design-vue";
    import {str} from "../../../public/html/商家入驻协议-线上.ts";
    import MyStepBar from "@/components/enterMyStepBar.vue";

    Vue.use(Checkbox);
Vue.use(Button);

export default {
  components: {
    Nav,
    Footer,
    Header,
    // CooperationAgreement,
    ReturnStep,
    MyStepBar
  },
  computed: {
    getStr() {
      return str;
    }
  },
  data() {
    return {
      checked: false,
      stepData:[
        {
          name: "入驻协议",
          time: "",
          img:require('@/assets/images/homeImg/enter-1.png'),
        },
        {
          name: "主体信息",
          time: "",
          img:require('@/assets/images/homeImg/enter-2.png'),
        },
        {
          name: "店铺信息",
          time: "",
          img:require('@/assets/images/homeImg/enter-3.png'),
        },
      ],
    };
  },
  beforeCreate() {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      this.$router.push("/login");
    }
  },

  methods: {
    onChange(e) {
      this.checked = e.target.checked;
    },
    next() {
      if (this.checked) {
        this.$router.push("/businessJoinStep1");
        localStorage.setItem("checkedOn", "checkedOk");
      } else {
        message.info("请阅读并同意以上协议");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.joinRead {
  text-align: center;
  margin-top: -0.01rem;
  .stepTop{
    width: 100%;
    display: flex;
    margin-bottom: 0.6rem;
    position: relative;
    img{
      width: 100%;
      height: 1.2rem;
    }
    .rz-type {
      position: absolute;
      left: 4.4rem;
      top: 0.39rem;
      font-size: 0.24rem;
      color: #fff;
      font-weight: bold;
      opacity: 1;
    }
  }
  .progressBar {
    width:4.5rem;
    height: 1rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 13.66rem;
    text-align: center;
    margin: 0 auto;
    padding: 0.4rem 1.16rem 0;
  }
  .present {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 1.46rem;
      height: 0.4rem;
      opacity: 1;
      border-radius: 0.2rem;
      font-size: 0.14rem;
      margin-bottom: 0.6rem;
      img {
        width: 7.75px;
        height: 13.38px;
        margin-left: 0.07rem;
        transform:rotate(180deg);
      }
    }

    .button2{
      color: #FFFFFF;
      border: 1px solid #60c7c6;
      background-color: #60c7c6;
      img{
        margin-left: 0.07rem;
        transform:rotate(180deg);
      }
    }
  }
}
</style>

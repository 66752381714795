

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: String,
    type: String,
    time: String,
    id: String
  }
})
export default class RelatedNewsItem extends Vue {
  // @Prop() private msg!: string
  jump() {
    this.$router.push("/artical/" + this.$props.id);
  }
}























import {Component, Prop, Vue} from "vue-property-decorator";
import {http} from "@/services/http";

@Component({
    props: {
    }
})
export default class shopHeader extends Vue {
    @Prop() shopId: number;

    created() {
        this.getShopInfo();
        this.getShopFollowState();
    }
    commentScore = 0;
    ifFollowed = false;
    data:any={}

    getShopInfo() {
        http.get(`/api/v1/merchant/detail/info/${this.shopId}`).then((res:any) => {
            let code=res.code
            let data=res.data
                if (code == 0) {
                    Object.assign(this, data);
                    this.data=data
                    this.$emit("setBannerList", data);
                }
            });
    }

    getShopFollowState() {
        const userInfoString = localStorage.getItem("userInfo");
        if (userInfoString!=null) {
            http.get(`/api/v1/token`, {}, 'baseUrl2').then((res: any) => {
                if (res.code != 401) {
                    http.get(`/api/v1/collections/shop-commodity/status`, {
                        params: {
                            id: this.shopId,
                            type: 236,
                        },
                    }).then((res:any) => {
                        let code=res.code
                        let data=res.data
                        if (code == 0) this.ifFollowed = data;
                    });
                }else{
                    localStorage.removeItem("userInfo");
                }
            })
        }

    }

    follow() {
        const userInfoString = localStorage.getItem("userInfo");
        if (!userInfoString) {
            open("/#/login?shopId=" + this.shopId, "_self");
            return;
        }
        http.post(`/api/v1/collections/shop-commodity`, {
                id: this.shopId,
                type: 236,
            })
            .then((res:any) => {
                let code=res.code
                if (code == 0) this.ifFollowed = !this.ifFollowed;
            });
    }
}


































































import {Component, Prop, Vue} from "vue-property-decorator";
import {baseUrl} from "../services/http";

@Component({})
export default class RightOrderDetail extends Vue {
    baseUrl = baseUrl
    @Prop() status!: boolean;
    @Prop() serviceItem!: Object;
    @Prop() detailsRefund!: any;

}



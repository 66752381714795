













































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";

Vue.use(Rate);

@Component({
    props: {
        orgId: String,
        itemImage: String,
        itemTitle: String,
        itemLocation: String,
        itemRate: Number,
        itemTags: Array,
        itemPrice: Number,
        address: String,
        orgType: String,
        faceTo: String,
        bedNum: Number,
        children: Array
    },
    components: {
        InstitutionInfo
    }
})
export default class userItemBody extends Vue {
    showArr = [];


    handleJumpToOrgDetail() {
        this.$router.push(`/creditInvestigation/memberInformation`);
    }
}

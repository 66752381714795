<script>
    import {orderFormList} from '@/services/api'

    export default {
  name: "commodity-order",
  props: {
    merchantId: {
      type: String,
      default: undefined
    },
    commodityOrderId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      pageIndex: 1,
      commodityOrderList: []
    }
  },
  watch: {
    merchantId: {
      immediate: true,
      handler() {
        this.reload();
      }
    },
    commodityOrderId: {
      immediate: true,
      handler() {
        this.reload();
      }
    },
  },
  methods: {
    reload() {
      if(this.merchantId != undefined && this.commodityOrderId != undefined) {
        console.error('merchant-id和commodity-order-id只能提供一个，同时提供将按commodity-order-id显示。')
      }
      if(this.commodityOrderId) {
        this.loadCommodityOrderInfo();
      } else if (this.merchantId) {
        this.loadCommodityOrderList(true);
      }
    },
    loadCommodityOrderInfo() {
      orderFormList({
        pageIndex: this.pageIndex,
        commodityOrderId: this.commodityOrderId
      }).then(res => {
        this.commodityOrderList = res.data.list
      })
    },
    loadCommodityOrderList(replace) {
      orderFormList({
        pageIndex: this.pageIndex,
        merchantId: this.merchantId
      }).then(res => {
        if(replace) {
          this.commodityOrderList = res.data.list
        } else {
          res.data.list.forEach(item => {
            this.commodityOrderList.push(item)
          })
        }
      })
    },
    sendCommodityOrder(item) {
      this.$emit('send-commodity-order', {
        commodityOrderId: item.orderInfoId,
        totalAmount:item.totalAmount,
        status: item.status,
        commodityName: item.commodityInfos[0].commodityName,
        commodityPic: item.commodityInfos[0].commodityPic
      })
    },
    sendCommodity(commodityInfo) {
      this.$emit('send-commodity', {
        commodityId: commodityInfo.commodityId,
        commodityPic: commodityInfo.commodityPic,
        commodityName: commodityInfo.commodityName,
        price: commodityInfo.price,
      })
    }
  }
}
</script>

<template>
  <ul class="order-list">
    <li class="order" v-for="item in commodityOrderList">
      <div class="head">
        <span class="text code" :title="item.seriesNum">订单：{{ item.seriesNum }}</span>
        <span class="text date">{{ item.createdTime }}</span>
      </div>
      <div class="commodity" v-for="commodity in item.commodityInfos">
        <img :src="commodity.commodityPic">
        <span class="text">{{ commodity.commodityName }}</span>
        <button @click="sendCommodity(commodity)">发送商品</button>
      </div>
      <div class="foot">
        <p class="text status">{{ item.status }}</p>
        <p class="tag">
          <span class="text">订单金额：</span>
          <span class="price">{{ item.totalAmount }}</span>
        </p>
        <button @click="sendCommodityOrder(item)">发送订单</button>
      </div>
    </li>
  </ul>
</template>

<style lang="less" scoped>

.text {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  height: 19px;
  color: #454F63;
}

.order-list {
  padding-left: 0px;
  margin: 0px;
  height: 100%;
  overflow-y: scroll;
  .order {
    list-style-type: none;
    border-bottom: 1px solid #E4E9F2;
    padding: 20px 10px;
    transition: all .15s;
    &:hover {
      background: #F8F8F8;
    }
    .head {
      height: 20px;
      .code {
        color: #2A2E43;
        display: inline-block;
        width: 126px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        float: left;
      }
      .date {
        color: #2A2E43;
        float: right;
      }
    }

    .commodity {
      position: relative;
      &:hover {
        background: #F8F8F8;
      }
      background: #ffffff;
      margin: 10px 10px 0px 10px;
      height: 100px;
      border: 1px solid #E4E9F2;
      border-radius: 4px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        margin: 10px;
      }
      span {
        position: absolute;
        left: 100px;
        top: 15px;
        width: calc(100% - 110px);
        height: 40px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      button {
        position: absolute;
        right: -1px;
        bottom: 14px;
      }
    }

    .foot {
      position: relative;
      .status {
        margin-top: 40px;
      }
      .tag {
        margin-top: 10px;
        margin-bottom: 0px;
      }
      .price {
        line-height: 26px;
        height: 26px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #FF1010;
      }
      .price:before {
        content: '￥'
      }
      button {
        margin: 0px;
        position: absolute;
        bottom: 0px;
        right: 20px;
      }
    }
  }
  button {
    width: 86px;
    height: 30px;
    background-color: #ffffff;
    border: 1px solid #78849E;
    border-radius: 15px;
    margin-right: 10px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    color: #78849E;
    transition: all .15s;

    &:hover {
      background: #EEF3FA;
    }

    &:active {
      background: #D7F2F9;
    }
  }
}
</style>















































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Input, message, Radio, Select} from "ant-design-vue";
import {dicListType, fetchAddrCascader, reservationAdd} from "@/services/api";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {State} from "vuex-class";

Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Button);

@Component({
    components: {
        Nav,
        Footer,
        Header
    }
})
export default class pensionOrderForm extends Vue {
    @State orgFormJson;
    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 5}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 15}
        }
    };

    options = [];
    demandRemark = "";
    physicalConditionList = {};
    priceRangeList = {};
    demandPeriodList = {};
    allowSubmit = true;
    contentOption = [
        {label: "男", value: "0"},
        {label: "女", value: "1"}
    ];

    submitMyOrder() {
        this.$router.push("/pensionOrderSuccess");
    }

    form: any;

    beforeCreate() {
        this.form = this.$form.createForm(this);
    }

    mounted() {
        this.init();
        this.dicListType(78).then(v => {
            this.physicalConditionList = v;
        });
        this.dicListType(79).then(v => {
            this.priceRangeList = v;
        });
        this.dicListType(80).then(v => {
            this.demandPeriodList = v;
        });
    }

    handleSubmit(e) {
        if (this.allowSubmit) {
            e.preventDefault();
            this.allowSubmit = false
            this.form.validateFields((err, values) => {
                function getdictoryid(currentObject, name) {
                    let currentId = "";
                    Object.entries(currentObject).forEach(el => {
                        if (currentObject[el[0]] === name) {
                            currentId = el[0];
                        }
                    });
                    return currentId;
                }

                if (!err) {
                    let param = {
                        addressProvince: values.address[0].toString(),
                        addressCity: values.address[1].toString(),
                        addressArea: values.address[2].toString(),
                        addressStreet: values.address[3].toString(),
                        orgId: this.$route.params.orgId,
                        contactName: values.contactName,
                        contactTel: values.contactTel,
                        detailAdd: values.detailAdd,
                        elderAge: values.elderAge,
                        elderGender: values.elderGender,
                        physicalCondition: getdictoryid(
                            this.physicalConditionList,
                            values.physicalCondition
                        ),
                        priceRange: getdictoryid(this.priceRangeList, values.priceRange),
                        demandPeriod: getdictoryid(
                            this.demandPeriodList,
                            values.demandPeriod
                        ),
                        demandRemark: this.demandRemark,
                        // orgForm: values.orgForm.toString()
                    };
                    reservationAdd({...param}).then(res => {
                        if (res.code === 0) {
                            // message.success("添加成功");
                            this.$emit("reserveSuccess", "123");
                            this.form.resetFields();
                            this.demandRemark = "";
                            this.allowSubmit = true
                            // this.$router.push("/pensionOrderSuccess");
                        } else {
                            message.error("预约失败，请联系网站管理员！");
                        }
                    });
                } else {
                    // 提交失败恢复可点击的状态
                    this.allowSubmit = true
                }
            });
        }

    }

    async dicListType(typeId) {
        let data = {};
        await dicListType({
            typeId: typeId
        }).then(res => {
            res.data.dictionaryList.forEach(el => {
                data[el.dictionaryId] = el.dictionaryName;
            });
        });
        return data;
    }

    init() {
        fetchAddrCascader({
            areaType: 0,
            areaId: ""
        }).then(data => {
            if (data && data.code == 0) {
                const list = data.data.list;
                let children: any = [];
                if (list) {
                    list.forEach(i => {
                        children.push({
                            label: i.name,
                            value: i.id,
                            id: i.id,
                            level: 1,
                            isLeaf: false
                        });
                    });
                }
                this.options = children;
            } else {
                message.error(data.msg);
            }
        });
    }
    //年龄验证
    timeOfLife(rule, value, callback){
        if (!value) {
            callback("老人年龄不能为空");
        }
        const reg = /^[1-9]\d*$/ ;
        if (!reg.test(value)) {
            callback("格式不正确，请输入正确的老人年龄");
        } else {
            callback();
        }
        // pattern: new RegExp(/^[1-9]\d*$/, 'g'),
        //     message: '老人年龄不能为空'
    }
    //联系电话不能为空
    phoneCheck(rule, value, callback) {
        if (!value) {
            callback("联系人电话不能为空");
        }
        const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
        const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (!reg.test(value) && !reg2.test(value)) {
            callback("格式不正确，请输入正确的手机号");
        } else {
            callback();
        }
    }

    loadData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        const {id} = targetOption;
        const {level} = targetOption;
        targetOption.loading = true;
        fetchAddrCascader({
            areaType: level,
            areaId: id
        }).then(data => {
            targetOption.loading = false;
            if (data && data.code == 0) {
                const list = data.data.list;
                let children: any = [];
                if (list) {
                    list.forEach(i => {
                        children.push({
                            label: i.name,
                            value: i.id,
                            id: i.id,
                            isLeaf: i.childNum == 0,
                            level: level + 1
                        });
                    });
                }
                targetOption.loading = false;
                targetOption.children = children;
                this.options = [...this.options];
            } else {
                message.error(data.msg);
            }
        });
    }
}














import { Component, Prop, Vue } from "vue-property-decorator";
import YlmsItem from "./YlmsItem.vue";

@Component({
  props: {
    itemPic: String,
    itemName: String,
    itemLocation: String
  }
})
export default class YlmsContent extends Vue {
  // @Prop() private msg!: string
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"related-news-item-body"},[_c('div',{staticClass:"title",on:{"click":function($event){return _vm.jump()}}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"tag-row"},[_c('div',{staticClass:"tag-icon"}),_c('span',{style:({
        color: '#60c6c7',
        marginLeft: '0.1rem',
        marginRight: '0.37rem'
      })},[_vm._v(_vm._s(_vm.type))]),_c('div',{staticClass:"tag-icon",style:({
        backgroundImage: ("url(" + (require('./../assets/images/shijian_icon_zxxq.png')) + ")"),
        width: '0.17rem',
        height: '0.16rem'
      })}),_c('span',[_vm._v(_vm._s(_vm.time))])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="Detail">
        <div v-if="commodityInfoDto.onStatus === 344&&merchantInfo.merchantStatus===1">
            <div class="position" style="display: flex">
                <div style="width: 60%;"> {{commodityInfoDto.commodityType ?
                    commodityInfoDto.commodityType.replace(/\//g, ">") : ""}}>
                    <span class="dd">{{ commodityInfoDto.commodityName }}</span>
                </div>

                <div class="storeInformation">
                    <div class="rate">
                        <span @click.prevent="$router.push('/mall/shop/'+ merchantInfo.merchantId)">{{this.merchantInfo.merchantName}}</span>
                        <a-rate v-model="this.merchantInfo.overallScore"
                                style="margin-left: 0.2rem;margin-top: -0.03rem;" :disabled="true"/>
                    </div>
                    <div class="enshrine" @click="follow">
                        <span>{{ ifFollowed ? "取消关注" : "关注店铺" }}</span>
                        <img :src="ifFollowed ?require('@/assets/images/mallHomeImg/路径 2292@2x.png'):require('@/assets/images/mallHomeImg/路径 1948@2x.png')">
                    </div>
                </div>
            </div>

            <mallDetailHeader
                    :commodityInfoDto="commodityInfoDto"
                    :merchantInfo="merchantInfo"
                    :skuInfoList="skuInfoList"
                    :skuSettingVos="skuSettingVos"
                    :detailPics="detailPics"
                    @buyNow="buyNow"
            />
            <!-- <detailLike /> -->
            <div id="tabHeader">
                <span @click="tab = 1" :class="{ select: tab == 1 }">商品介绍</span>
                <span @click="tab = 2" :class="{ select: tab == 2 }">累计评价({{ $store.state.productCommontTotalCount || 0 }})</span>
            </div>
            <productDesciption
                    v-show="tab == 1"
                    ref="productDesciption"
                    :commodityIntroductionDto="commodityIntroductionDto"
            />
            <evaluation v-show="tab == 2" :id="id" :overallScore="overallScore||5"/>
            <detailFooter style="margin-bottom: 0.6rem"/>
        </div>
        <div class="underneath" v-else>
            <div class="model">
                <img src="../../assets/images/mallHomeImg/7@2xa.png"/>
                <div class="matter">
                    <div class="title">{{beNullList.title}}</div>
                    <p v-for="(item,index) of beNullList.list" :key="index">{{item.data}}</p>
                    <div class="button">
                        <div class="button1" @click="goPlatform('/#/home')">平台首页</div>
                        <div class="button2"  @click="goPlatform(beNullList.path)">{{beNullList.pathName}}</div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal class="aModel" footer width="6.5rem" v-model="batchInto" @click="() => (this.batchInto = false)">
            <div style="padding-left:0.3rem;height: 0.9rem;margin-top: 0.2rem">
                <div style="display: flex">
                    <div><img style="width: 30px;height: 30px" src="../../assets/images/duihao_icon_shrz.png"></div>
                    <div style="margin-left: 0.1rem;font-size: 0.16rem;color: #333;display: inline-block;margin-top: 0.01rem">
                        {{ ifFollowed ? "收藏店铺成功" : "取消收藏成功" }}
                    </div>
                </div>
                <p v-if="ifFollowed" style="margin-top: 0.1rem;margin-left: 0.4rem">您可以<span
                        style="color: #35a;cursor: pointer" @click="enshrine">去我的收藏</span>查看收藏的店铺</p>
                <p v-else style="margin-top: 0.1rem;margin-left: 0.4rem">您已经成功取消收藏店铺</p>
            </div>
        </a-modal>
    </div>
</template>
<script lang="js">
    import Vue from "vue";
    import {http} from "@/services/http";
    import {
        detailFooter,
        detailLike,
        evaluation,
        mallDetailHeader,
        productDesciption,
    } from "@/components/mallComponents";

    export default Vue.extend({
        name: "Detail",
        data() {
            return {
                tab: 1,
                commodityInfoDto: {},
                merchantInfo: {},
                skuInfoList: {},
                beNullList: {},
                detailPics: [{}],
                commodityIntroductionDto: {},
                id: "0",
                overallScore: 0,
                addressList: [],
                allReviewNum: 0,
                addressId: "",
                skuSettingVos: [],
                ifFollowed: false,
                batchInto: false
            };
        },
        components: {
            mallDetailHeader,
            detailLike,
            productDesciption,
            evaluation,
            detailFooter,
        },
        created() {
            const id = this.$router.currentRoute.params.id;
            this.id = id;
            http.get(`/api/v1/commodities/info`, {
                params: {commodityId: id},
            })
                .then((res) => {
                    this.commodityInfoDto = res["data"].commodityInfoDto;
                    this.merchantInfo = res["data"].merchantInfo;
                    this.skuInfoList = res["data"].skuInfoList;
                    this.skuSettingVos = res["data"].skuSettingVos;
                    this.detailPics = res["data"].detailPics;
                    this.commodityIntroductionDto = res["data"].commodityIntroductionDto;
                    this.allReviewNum = this.commodityInfoDto.commentCount;
                    const userInfoString = localStorage.getItem("userInfo");
                    if (userInfoString != null) {
                        this.getShopFollowState();
                    }
                    const t = setTimeout(() => {
                        this.$refs.productDesciption.setCommodityIntroductionDto(
                            this.commodityIntroductionDto
                        );
                        clearTimeout(t);
                    }, 1000);
                });

            http.get(`/api/v1/comments/count`, {
                params: {commodityId: id},
            }).then((res) => {
                // this.overallScore = res["data"].overallScore;
            });

            this.getAddressList();
           this.beNullList= {
                title: '很抱歉，您查看的商品，可能已下架或者被转移。',
                list: [{data: "您可以:"}, {data: "很抱歉，您查看的商品不存在，可能已下架或者被转移。"}, {data: "2.在顶部搜索框重新输入关键词搜索"}, {data: "3.去其他的地方逛逛"}],
                path: '/#/personal/myCollection?type=2',
                pathName: '收藏的商品'
            }

        },
        mounted() {
            const userInfoString = localStorage.getItem("userInfo");
            if (userInfoString != null) {
                this.browsingHistoryList(this.$route.params.id);
            }
        },
        methods: {
            //查看店铺是否被关注
            getShopFollowState() {
                http.get(`/api/v1/collections/shop-commodity/status`, {
                    params: {
                        id: this.merchantInfo.merchantId,
                        type: 236,
                    },
                }).then((res) => {
                        if (res.code == 0) this.ifFollowed = res.data;
                    });
            },

            //去平台
            goPlatform(val) {
                window.open(val, "_blank");
            },
            //关注店铺
            follow() {
                const userInfoString = localStorage.getItem("userInfo");
                if (!userInfoString) {
                    open("/#/login?shopId=" + this.shopId, "_self");
                    return;
                }
                http.post(`/api/v1/collections/shop-commodity`, {
                    id: this.merchantInfo.merchantId,
                    type: 236,
                })
                    .then((res) => {
                        if (res.code == 0) {
                            this.batchInto = true;
                            this.ifFollowed = !this.ifFollowed;
                        }
                    });
            },
            //查看收藏店铺
            enshrine() {
                open('/#/personal/myCollection?type=4')
            },
            getAddressList() {
                if (!localStorage.getItem("userInfo")) {
                    return;
                }
                http.get("/api/v1/addresses/list").then((res) => {
                    if (res["code"] == 0) {
                        this.addressList = res["data"];
                        this.addressId = this.addressList[0].addressId;
                    }
                });
            },
            buyNow(parm) {
                if (!localStorage.getItem("userInfo")) {
                    open("/#/login");
                    return;
                }

                const dateString = new Date()
                    .toLocaleString("chinese", {hour12: false})
                    .replace(/\//g, "-");
                const commoditiesInCarts = [
                    {
                        isLimit: this.commodityInfoDto.isLimit,
                        brand: this.commodityInfoDto.brand,
                        buyPermission: this.commodityInfoDto.buyPermission,
                        // cartId: "2e16d27a-1d38-4698-9b21-b0034b2288c4",
                        commodityId: this.id,
                        commodityName: this.commodityInfoDto.commodityName,
                        commodityPic: this.detailPics[0]
                            ? this.detailPics[0].pictureAddress
                            : "",
                        createTime: dateString,
                        freight: this.commodityInfoDto.shippingPrice,
                        isInvoice: this.commodityInfoDto.isInvoice,
                        linePrice: this.commodityInfoDto.linePrice,
                        memberClass: this.commodityInfoDto.memberClass,
                        originalPrice: parm.suggestPrice,
                        price: parm.suggestPrice,
                        purchaseLimit: this.commodityInfoDto.purchaseLimit,
                        quantity: parm.quantity,
                        sku: parm.siInfo,
                        skuId: parm.siId,
                        startingQuantity: this.commodityInfoDto.startingQuantity,
                        totalPrice: (parm.quantity * parm.suggestPrice).toFixed(2),
                        updateTime: dateString,
                    },
                ];

                let data = [
                    {
                        ...this.merchantInfo,
                        freightTotal: this.commodityInfoDto.shippingPrice,
                        commoditiesInCarts,
                    },
                ];
                const dataList =  JSON.stringify({data, totalAmount: data[0].commoditiesInCarts[0].totalPrice + data[0].freightTotal, addressId: this.addressId, cart: false})
                this.$router.push({
                    path: "/mall/OrderInfo",
                    query: {
                        dataList
                    },
                })
            },
            //保存浏览记录
            browsingHistoryList(value) {
                http.post(`/api/v1/addBrowseRecord`, {ifCommodity: 1, contentId: value,}, {}, 'baseUrl2');
            }
        },
    });
</script>
<style lang="less" scoped>
    #Detail {
        width: 13.85rem;
        margin: auto;
        margin-bottom: 0.3rem;
        /*border: 1px solid red;*/

        .position {
            height: 0.45rem;
            font-size: 0.14rem;
            color: #2A2E43;
            opacity: 1;
            line-height: 0.45rem;

            .dd {
                color: #39BEB8;
                font-size: 0.14rem;
                opacity: 1;
            }

            .storeInformation {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                /*border: 1px solid red;*/

                .rate {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #454F63;
                    opacity: 1;

                    span {
                        cursor: pointer;
                    }

                    span:hover {
                        color: #39BEB8;
                    }

                    /deep/ .ant-rate {
                        color: #FFBF26;
                        font-size: 0.199rem;

                        svg {
                            color: #FFBF26;
                            width: 0.85em;
                            height: 0.85em;
                            margin: -0.05rem -0.03rem -0.03rem -0.03rem;
                        }
                    }
                }

                .enshrine {
                    height: 100%;
                    margin-left: 0.5rem;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 0.14rem;
                        color: #454F63;
                        opacity: 1;
                        margin-right: 0.08rem;
                        cursor: pointer;
                    }

                    img {
                        margin-top: 0.03rem;
                        width: 0.14rem;
                        height: 0.11rem;
                        cursor: pointer;
                    }

                }
            }
        }


        #tabHeader {
            width: 100%;
            font-size: 0.16rem;
            height: 0.48rem;
            background: #F8F8F8;
            /*border: 1px solid red;*/

            span {
                display: inline-block;
                float: left;
                height: 0.48rem;
                line-height: 0.48rem;
                padding: 0 0.2rem;
                user-select: none;
                cursor: pointer;


                font-size: 0.16rem;
                font-weight: bold;
                color: #2A2E43;
                opacity: 1;
            }

            span:first-child {
                width: 1.55rem;
                font-size: 0.16rem;
                text-align-last: center;
            }

            span.select {
                color: #70CECA;
                border-bottom: 3px solid #70CECA;
            }
        }

        .aModel {
            .ant-modal-content {
                margin-top: 2rem;
            }
        }
        .underneath {
            height: 4rem;
            width: 14rem;
            margin: 0 auto;
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            justify-content: center;

            .model {
                display: flex;
                align-items: center;

                img {
                    width: 1.79rem;
                    height: 1.2rem;
                    /*border: 1px solid red;*/
                }

                .matter {
                    margin-left: 0.3rem;

                    .title {
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #2A2E43;
                        opacity: 1;
                        margin-bottom: 0.1rem;
                    }

                    p {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin: 0;
                        line-height: 0.25rem;
                        /*display: inline-block;*/
                    }

                    .button {
                        /*border: 1px solid red;*/
                        display: flex;
                        min-width: 1.8rem;
                        max-width: 2rem;
                        margin-top: 0.1rem;
                        justify-content: space-between;

                        .button1, .button2 {
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #5FC6C5;
                            opacity: 1;
                            cursor: pointer;
                        }
                    }
                }
            }

        }
    }

</style>

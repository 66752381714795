










































































































































































































import {Component, Vue} from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import castAbout from "../../creditInvestigation/components/castAbout.vue";
import homeScore from "../../creditInvestigation/particulars/home-score.vue";
import {getMerchantDetailInfo} from "@/services/api";
import fa from "element-ui/src/locale/lang/fa";


@Component({
  components: {
    castAbout, homeScore, Footer
  },
})
export default class extends Vue {
  consumptionDetailData: any = []; // 商品消费明细列表
  value = 3;
  previewVisible = false;
  previewImage = '';
  columns1 = [
    {
      title: "商品订单号",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "商品详情",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "评价内容",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "评价人",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "商品评分",
      dataIndex: "",
      align: "center"
    },
    {
      title: "店铺评分",
      dataIndex: "",
      align: "center"
    }
  ];
  columns = [
    {
      title: "商品详情",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "商品价格",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "销售笔数",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "商品评分",
      dataIndex: "createTime",
      align: "center"
    },
  ];

  mounted() {
    this.consumptionDetail()
  }

  merchantDetailInfo = {
    agencyId: '',
    agencyName: '',
    agencyImage: '',
    onStatus: 1,
    merchantAddress: '',
    merchantTel: '',
    joinDate: '',
    totalScore: 5,
    industryInvolved: '',
    merchantIntro: '',
    categoryList: null,
    adminName: '',
    adminTel: '',
    storeRemark: null,
    companyName: '',
    orgCode: '',
    lpName: '',
    companyContactNumber: '',
    companyAddress: '',
    lpIdPicturePositive: '',
    lpIdPictureNegative: '',
    businessLicensePicture: '',
    merOveralScore: 5,
    commodityCompliance: 5,
    merchantAttitude: 5,
    deliverySpeed: 5,
    deliveryAttitude: 5
  }
  getSuccess = true

  consumptionDetail() {
    const that = this
    let agencyId = that.$route.query['agencyId'];
    that.getSuccess = false
    if (agencyId) {
      getMerchantDetailInfo(agencyId).then(res => {
        if (res && res.code == 0) {
          that.merchantDetailInfo = res.info
          that.getSuccess = true;
        }
      })
    }
  }
  handlePicturePreview(val){
    this.previewVisible=true
    this.previewImage=val

  }
  handleCancelOK() {
    this.previewVisible = false;
  }

}

export const str = `<head profile="http://dublincore.org/documents/dcmi-terms/">
    <meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8"/>
    <title xml:lang="en-US">zhouzhen</title>
    <meta name="DCTERMS.title" content="zhouzhen" xml:lang="en-US"/>
    <meta name="DCTERMS.language" content="en-US" scheme="DCTERMS.RFC4646"/>
    <meta name="DCTERMS.source" content="http://xml.openoffice.org/odf2xhtml"/>
    <meta name="DCTERMS.creator" content="张宁"/>
    <meta name="DCTERMS.issued" content="2017-02-13T01:54:00" scheme="DCTERMS.W3CDTF"/>
    <meta name="DCTERMS.contributor" content="WPS_1613985209"/>
    <meta name="DCTERMS.modified" content="2021-07-06T11:35:50" scheme="DCTERMS.W3CDTF"/>
    <meta name="DCTERMS.provenance" content="" xml:lang="en-US"/>
    <meta name="DCTERMS.subject" content="," xml:lang="en-US"/>
    <link rel="schema.DC" href="http://purl.org/dc/elements/1.1/" hreflang="en"/>
    <link rel="schema.DCTERMS" href="http://purl.org/dc/terms/" hreflang="en"/>
    <link rel="schema.DCTYPE" href="http://purl.org/dc/dcmitype/" hreflang="en"/>
    <link rel="schema.DCAM" href="http://purl.org/dc/dcam/" hreflang="en"/>
    <style type="text/css">
        @page {
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            empty-cells: show
        }
    p{
         cursor: default !important;
        }
        a{
          cursor: default !important;
        }
        td, th {
            vertical-align: top;
            font-size: 12pt;
        }

        h1, h2, h3, h4, h5, h6 {
            clear: both;
        }

        ol, ul {
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        /* "li span.odfLiEnd" - IE 7 issue*/
        li span. {
            clear: both;
            line-height: 0;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
        }

        span.footnodeNumber {
            padding-right: 1em;
        }

        span.annotation_style_by_filter {
            font-size: 95%;
            font-family: Arial;
            background-color: #fff000;
            margin: 0;
            border: 0;
            padding: 0;
        }

        span.heading_numbering {
            margin-right: 0.8rem;
        }

        * {
            margin: 0;
        }

        .P1_borderStart {
            border-left-style: none;
            border-right-style: none;
            border-top-style: none;
            font-size: 9pt;
            padding-left: 0in;
            padding-right: 0in;
            padding-top: 0in;
            text-align: center ! important;
            font-family: Times New Roman;
            writing-mode: horizontal-tb;
            direction: ltr;
            border-bottom-style: none;
        }

        .P1 {
            border-left-style: none;
            border-right-style: none;
            font-size: 9pt;
            padding-left: 0in;
            padding-right: 0in;
            text-align: center ! important;
            font-family: Times New Roman;
            writing-mode: horizontal-tb;
            direction: ltr;
            border-top-style: none;
            border-bottom-style: none;
        }

        .P1_borderEnd {
            border-bottom-width: 0.0261cm;
            border-bottom-style: solid;
            border-bottom-color: #000000;
            border-left-style: none;
            border-right-style: none;
            font-size: 9pt;
            padding-bottom: 0.0138in;
            padding-left: 0in;
            padding-right: 0in;
            text-align: center ! important;
            font-family: Times New Roman;
            writing-mode: horizontal-tb;
            direction: ltr;
            border-top-style: none;
        }

        .P10 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            line-height: 0.4028in;
            text-align: center ! important;
        }

        .P11 {
            font-size: 14pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            line-height: 0.4028in;
            text-align: center ! important;
            color: #000000;
            font-weight: bold;
        }

        .P12 {
            font-size: 18pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-left: 0in;
            margin-right: 0in;
            line-height: 0.4028in;
            text-align: center ! important;
            text-indent: 0.5in;
            color: #000000;
            font-weight: bold;
        }

        .P13 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P14 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            text-align: justify ! important;
        }

        .P15 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P16 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P17 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P18 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P19 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P2 {
            font-size: 9pt;
            text-align: center ! important;
            font-family: Times New Roman;
            writing-mode: horizontal-tb;
            direction: ltr;
        }

        .P20 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
        }

        .P21 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P22 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P23 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            text-align: justify ! important;
            color: #000000;
        }

        .P25 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P26 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P27 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P28 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P29 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P3 {
            font-size: 9pt;
            text-align: left ! important;
            font-family: Times New Roman;
            writing-mode: horizontal-tb;
            direction: ltr;
        }

        .P30 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P31 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P32 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P34_borderStart {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            line-height: 125%;
            color: #000000;

            padding-bottom: 0.0835in;
            border-bottom-style: none;
        }

        .P34 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            line-height: 125%;
            color: #000000;
            padding-top: 0in;
            padding-bottom: 0.0835in;
            border-top-style: none;
            border-bottom-style: none;
        }

        .P34_borderEnd {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
            padding-top: 0in;
            border-top-style: none;
        }

        .P35 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
        }

        .P36 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            color: #000000;
        }

        .P37 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-left: 0in;
            margin-right: 0in;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            text-indent: 0.3335in;
        }

        .P38 {
            font-size: 12pt;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-left: 0in;
            margin-right: 0in;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            text-indent: 0.3346in;
            color: #000000;
            text-decoration: underline;
            font-weight: bold;
        }

        .P39 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-left: 0.2917in;
            margin-right: 0in;
            text-indent: -0.2917in;
        }

        .P40 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-left: 0.4925in;
            margin-right: 0in;
            margin-top: 0in;
            margin-bottom: 0.0835in;
            line-height: 125%;
            text-indent: -0.4925in;
        }

        .P41 {
            font-size: 10.5pt;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            margin-top: 0in;
            margin-bottom: 0.0346in;
            line-height: 125%;
        }

        .P42_borderStart {
            font-size: 12pt;
            margin-left: 0in;
            margin-right: 0in;
            margin-top: 0in;
            text-align: left ! important;
            text-indent: 0in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-bottom: 0in;
            border-bottom-style: none;
        }

        .P42 {
            font-size: 12pt;
            margin-left: 0in;
            margin-right: 0in;
            text-align: left ! important;
            text-indent: 0in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-bottom: 0in;
            padding-top: 0in;
            border-top-style: none;
            border-bottom-style: none;
        }

        .P42_borderEnd {
            font-size: 12pt;
            margin-bottom: 0in;
            margin-left: 0in;
            margin-right: 0in;
            text-align: left ! important;
            text-indent: 0in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-top: 0in;
            border-top-style: none;
        }

        .P43_borderStart {
            font-size: 12pt;
            margin-left: 0in;
            margin-right: 0in;
            margin-top: 0in;
            text-align: left ! important;
            text-indent: 0.3346in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-bottom: 0in;
            border-bottom-style: none;
        }

        .P43 {
            font-size: 12pt;
            margin-left: 0in;
            margin-right: 0in;
            text-align: left ! important;
            text-indent: 0.3346in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-bottom: 0in;
            padding-top: 0in;
            border-top-style: none;
            border-bottom-style: none;
        }

        .P43_borderEnd {
            font-size: 12pt;
            margin-bottom: 0in;
            margin-left: 0in;
            margin-right: 0in;
            text-align: left ! important;
            text-indent: 0.3346in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
            background-color: #ffffff;
            padding-top: 0in;
            border-top-style: none;
        }

        .P7 {
            font-size: 22pt;
            font-weight: bold;
            line-height: 125%;
            margin-bottom: 0.0835in;
            margin-top: 0in;
            font-family: Calibri;
            writing-mode: horizontal-tb;
            direction: ltr;
        }

        .P8 {
            font-size: 12pt;
            font-weight: bold;
            line-height: 125%;
            margin-bottom: 0.0835in;
            margin-top: 0in;
            font-family: Arial;
            writing-mode: horizontal-tb;
            direction: ltr;
        }

        .P9 {
            font-size: 12pt;
            font-weight: bold;
            line-height: 125%;
            margin-bottom: 0.0835in;
            margin-top: 0in;
            font-family: 宋体;
            writing-mode: horizontal-tb;
            direction: ltr;
        }

        .T1 {
            color: #000000;
            font-family: Arial;
            font-size: 18pt;
            font-weight: bold;
        }

        .T10 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T11 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T13 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T15 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T16 {
            color: #000000;
            font-family: Arial;
        }

        .T18 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T20 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T25 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T26 {
            color: #000000;
            font-family: Arial;
            font-style: normal;
            text-decoration: none ! important;
            font-weight: bold;
        }

        .T27 {
            color: #000000;
            font-family: Arial;
        }

        .T29 {
            color: #000000;
            font-family: Arial;
        }

        .T3 {
            color: #000000;
            font-family: Arial;
            font-size: 18pt;
            font-weight: bold;
        }

        .T30 {
            color: #000000;
            font-family: Arial;
            text-decoration: underline;
            font-weight: bold;
        }

        .T31 {
            color: #000000;
            font-family: Arial;
            text-decoration: underline;
            font-weight: bold;
        }

        .T32 {
            color: #000000;
            font-family: Arial;
            text-decoration: underline;
        }

        .T34 {
            color: #000000;
            font-family: Arial;
        }

        .T35 {
            color: #000000;
            font-family: Arial;
        }

        .T36 {
            color: #000000;
            font-family: 宋体;
            font-style: normal;
            text-decoration: none ! important;
            font-weight: bold;
        }

        .T37 {
            color: #000000;
            font-family: 宋体;
            font-size: 12pt;
        }

        .T39 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T42 {
            font-family: Arial;
            font-weight: bold;
        }

        .T43 {
            font-family: Arial;
            font-weight: bold;
        }

        .T44 {
            font-family: Arial;
            font-size: 12pt;
        }

        .T45 {
            font-family: Arial;
            font-size: 12pt;
        }

        .T47 {
            font-family: Arial;
            font-size: 12pt;
        }

        .T48 {
            font-family: Arial;
            font-size: 12pt;
        }

        .T49 {
            font-family: Arial;
            font-size: 12pt;
        }

        .T5 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T50 {
            font-family: Arial;
        }

        .T51 {
            font-size: 12pt;
        }

        .T53 {
            font-size: 12pt;
        }

        .T54 {
            font-size: 12pt;
        }

        .T61 {
            color: #00b050;
            font-family: Arial;
            font-size: 12pt;
        }

        .T62 {
            color: #00b050;
            font-family: Arial;
            font-size: 12pt;
            font-weight: bold;
        }

        .T8 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .T9 {
            color: #000000;
            font-family: Arial;
            font-size: 12pt;
        }

        .WW8Num1z0 {
            font-family: Wingdings;
        }

        .WW8Num2z0 {
            font-family: Wingdings;
        }

        .WW8Num4z0 {
            font-family: Wingdings;
        }

        .WW8Num5z0 {
            font-family: Wingdings;
        }

        .WW8Num6z0 {
            font-family: Wingdings;
        }

        .WW8Num7z0 {
            font-family: Wingdings;
        }

        .WW8Num8z0 {
            font-family: Wingdings;
        }

        /* ODF styles with no properties representable as CSS */
        .T60 .WW8Num3z0 .WW8Num3z1 .WW8Num3z2 .WW8Num3z3 .WW8Num3z4 .WW8Num3z5 .WW8Num3z6 .WW8Num3z7 .WW8Num3z8 {
        }
    </style>
</head>
<body dir="ltr">
<p class="P10"><span class="T1">芳园天伦养老平台</span><span class="T3">养老机构</span><span class="T1">在线入驻协议</span></p>
<p class="P11">前言</p>
<p class="P37"><span class="T18">《</span><span class="T20">养老机构</span><span class="T18">入驻芳园天伦智慧养老平台</span><span
        class="T18">合作协议》（以下简称“本协议”）是由</span><span class="T18">深圳芳园天伦有限公司</span><a id="_Hlk75704730"/><span class="T18">(以下简称“甲方”)</span><span
        class="T18"> 与以独立经营者身份</span><span class="T18">入驻芳园天伦智慧养老平台</span><span class="T18">(以下简称“</span><span
        class="T18">平台</span><span class="T18">”)</span><span class="T18">【经营】</span><span
        class="T18">实体(以独立</span><span class="T18">经营</span><span class="T20">养老机构</span><span class="T18">在</span><span
        class="T18">平台</span><span class="T18">填写</span><span class="T18">、提交的</span><span class="T18">入驻人</span><span
        class="T18">有关的材料</span><span class="T18">为准，以下简称“乙方”或“</span><span class="T20">养老机构</span><span class="T18">”)关于提供和使用</span><span
        class="T18">的各项权利义务所达成的一致意思表示，甲方乙方共同信守。</span></p>
<p class="P43"><span class="T36">“</span><span class="T26">签约须知”</span><span class="T30">：</span><span
        class="T31">养老机构</span><span class="T30">通过“</span><span class="T31">养老机构</span><span
        class="T30">在线入驻系统”办理</span><span class="T31">养老机构</span><span class="T30">入驻过程中，一经勾选“我己仔细阅读并同意协议”并点击“提交入驻申请”按键，即意味着</span><span
        class="T31">养老机构</span><span class="T30">同意与【甲方】签订本协议并自愿受本协议约束，但</span><span class="T31">养老机构</span><span
        class="T30">在线同意本协议并不导致本协议立即生效。</span><span class="T31">养老机构</span><span
        class="T30">的入驻申请、资料提交等经过短信等以及系统通知形式向</span><span class="T31">养老机构</span><span
        class="T30">发送审核通过时，本协议对</span><span class="T31">养老机构</span><span class="T30">及【甲方】具有法律效力;如【甲方】审核后需要</span><span
        class="T31">养老机构</span><span class="T30">与【甲方】另行签署协议的，则</span><span class="T31">养老机构</span><span class="T30">还应遵守另行签署的协议。</span>
</p>
<p class="P22">此“签约须知”为本协议正文的组成部分。</p>
<p class="P11">协议正文</p>
<p class="P42_borderStart"><span class="T42" style="color: black">第一条</span><span class="T43"> </span><span class="T42"  style="color: black">协议内容概述</span><span
        class="T50"><br/></span><span class="T16">1</span><span class="T16">、</span><span class="T16">协议内容。本协议包括协议正文、附件及所有甲方已经发布的或将来可能发布的各类规则、规范、规则解读、实施细则、通知、公告等（以下合称“平台规</span><span
        class="T27">则”）。所有附件及平台规则均为本协议不可分割的一部分，与协议正文具有同等法律效力。协议正文、附件与平台规则冲突的，以发布在后的文件为准执行。</span></p>
<p class="P42"><span class="T27">2、</span><span
        class="T27">乙方首次申请入驻平台时，在签署或在线接受本协议后，本协议项下的平台服务并不立即开通。乙方在须履行上传《营业执照》及相关</span><span class="T27">许可</span><span
        class="T27">资质</span><span class="T27">（或具有同等法律效力的资质文件如许可证等，统称“经营资质证书”）</span><span class="T27">等义务，经甲方审核通过后，向乙方发出服务开通通知（通知方式包括但不限于邮箱、手机短信等）时，本协议项下的平台服务正式开通。</span>
</p>
<p class="P42"><span class="T27">3、</span><span class="T27">协议变更及生效。甲方有权变更（包括但不限于制定、修订、废止）本协议正文、附件及/或平台规则</span><span
        class="T27">当中的相关内容</span><span class="T27">，将至少提前 5日在甲方网站公示，</span><span class="T16">并载明生效日期，乙方应实时关注公示内容。如乙方不接受变更，应当在公示期间内申请退出平台，则乙方将进入“冻结”状态，本协议将终止。乙方清楚知晓、同意并确认前述“冻结”状态下的机构管理功能限制，甲方对于该等限制、协议终止</span><span
        class="T16">不负有任何违约责任或其他责任。如乙方未按前述约定申请退出平台，即视为乙方接受前述变更事项。</span></p>
<p class="P42"><span class="T27">第二条</span><span class="T34"> </span><span class="T27">相关定义及解释</span></p>
<p class="P42"><span class="T27">1</span><span class="T27">、平台：即本协议中甲方运营的电子商务平台。网站名称为：</span><span class="T32">__芳园天伦智慧养老平台___</span><span
        class="T27">，域名为_</span><span class="T32"> </span><span class="T32">www.fytlun.com</span><span
        class="T27">  </span><span class="T27">是为平台用户提供信息发布、交流，为入驻平台</span><span class="T29">养老机构</span><span
        class="T27">开设（虚拟）店铺（简称“</span><span class="T29">养老机构</span><span
        class="T27">店铺”），以及相应的管理及其他技术服务的电子商务交易服务平台。</span></p>
<p class="P42"><span class="T27">2、乙方注册及入驻：注册指</span><span class="T35">按照</span><span class="T27">平</span><span
        class="T35">台</span><span class="T27">的注册流程和要求，完成注册成为平台会员。入驻指完成注册后，欲成为平台</span><span
        class="T29">养老机构</span><span class="T27">（本文简称“</span><span class="T29">养老机构</span><span class="T27">”），乙方的经营、服务符合当年度的平台入驻标准且满足本协议第四条等项</span><span
        class="T29">养老机构</span><span class="T27">开通条件，依据平台入驻流程和要求完成在线信息提交，经甲方审核同意后，可以使用通过审核的登陆名和自行设定的密码登陆</span><span
        class="T29">养老机构</span><span class="T27">中心，依法依规开展经营。本协议中的</span><span class="T29">养老机构</span><span class="T27">指本协议缔约方中的“乙方”。</span>
</p>
<p class="P42_borderEnd"><span class="T27">3、</span><span class="T5">甲方是向乙方及甲方其他会员进行网上交易活动提供交易平台的平台服务提供商，不对乙方及参与交易的甲方其他客户的任何口头、书面陈述或承诺，不对乙方发布的信息及交易行为的真实性、合法性、准确性、及时性、有效性等作任何明示或暗示的保证，亦不承担任何法律责任。若因乙方与参与交易的甲方其他客户之间的交易行为引起的任何法律纠纷，包括但不限于投诉、起诉、举报及税赋等，均由参与交易的双方解决，与甲方无关。但，乙方怠于履行义务时，甲方有权介入乙方与甲方其他客户间的争议，依据一般人的认知程度对该争议进行判断和处置，乙方应当予以执行。</span>
</p>
<p class="P13"><span class="T5">4、平台会员：指与平台签订《平台注册协议》《隐私政策》并完成注册流程的具有完全民事行为能力的法人、自然人及其他组织</span><span class="T18">等接受平台各项</span><span
        class="T5">服务的使用者。除非另有说明，否则“会员”均指此含义。</span></p>
<p class="P13"><span class="T5">5、会员名：是指平台会员申请入驻时提交的，通过平台审核的会员名。会员名与自设密码共同使用以登陆甲方的</span><span
        class="T9">养老机构</span><span class="T5">中心，每一个</span><span class="T5">会员</span><span
        class="T5">可以拥有多个下属</span><span class="T9">养老机构</span><span class="T5">。</span><span class="T9">养老机构</span><span
        class="T5">应妥善保管</span><span class="T9">养老机构</span><span class="T5">会员名及密码信息，不得擅自转让或授权他人使用。随意转让会员账号的将被关闭，且转让方还须承担该账号下所有行为的法律责任。</span>
</p>
<p class="P13"><span class="T5">6、</span><span class="T9">养老机构</span><span class="T5">店铺：</span><span
        class="T9">养老机构</span><span class="T5">在完成</span><span class="T9">养老机构</span><span class="T5">注册及</span><span
        class="T9">养老机构</span><span class="T5">入驻流程后，为进行合法经营，依据协议约定和平台规则由乙方申请，平台审核通过的具有独立且唯一性</span><span
        class="T5">ID</span><span class="T5">（英文</span><span class="T13"> </span><span class="T5">IDentity</span><span
        class="T5"> 的缩写，意为“身份标识号码”）、特定名称（可依据相关平台</span><span class="T9">养老机构</span><span class="T5">店铺命名和域名管理规则进行定义）的网络化虚拟店铺，乙方可依据经</span><span
        class="T18">营需要申请开设一个或多个店铺。乙方通过店铺会员名可以实现对该店铺的经营和管理，包括但不仅限于服务信息上传和修改、退款处理，交易跟踪、订单取消等。</span></p>
<p class="P13"><span class="T5">7、平台规则：指公示在平台之上的</span><span class="T39">，</span><span class="T5">任何规范性文件，包括但不限于规则与规范、标准、实施细则、协议等。</span>
</p>
<p class="P13"><span class="T5">8、保证金：</span><span class="T9">养老机构</span><span class="T5">向平台缴纳的用以保证本协议履行及对服务和服务质量进行担保的钱款，平台可以依照本协议的相关约定对其进行处置。</span>
</p>
<p class="P13"><span class="T5">9、技术服务费：</span><span class="T9">养老机构</span><span class="T5">使用平台各项服务时按照本协议约定缴纳的固定技术服务费用。具体缴纳标准见本协议附件三。</span>
</p>
<p class="P13"><span class="T5">10、销售佣金：</span><span class="T9">养老机构</span><span class="T5">根据经营品类在达成每一单交易时按比例向平台缴纳的佣金费用，佣金比例因品类不同收取比例不同，具体缴纳标准及方式见本协议附件三。</span>
</p>
<p class="P13"><span class="T11">11、服务期：指甲方为乙方提供平台入驻服务的期限，从甲方为乙方开通</span><span class="T10">养老机构</span><span class="T11">中心操作权限之日开始直至双方约定的期限为止；乙方如为续约</span><span
        class="T10">养老机构</span><span class="T11">，续约服务期则从前一次服务期终止日后一日开始直至双方约定的期限为止；乙方如为续约</span><span
        class="T10">养老机构</span><span class="T11">，因乙方原因导致未能正常续约而甲方关闭其操作权限时，服务期则从甲方重新开通</span><span
        class="T10">养老机构</span><span class="T11">中心操作</span><span class="T18">权限之日开始直至双方约定的期限为止。如无其他特殊约定，本协议所指服务期以一个完整的自然年度为周期计</span><span
        class="T11">算。本协议约定的合同有效期与服务期不一致时，合同期限届满日以服务期届满日为准。</span></p>
<p class="P31"> </p>
<h1 class="P7"><a id="a__第三条_服务内容及乙方养老机构店铺经营方式"><span/></a><span class="T45">第三条</span><span class="T48"> </span><span
        class="T45">服务内容及乙方</span><span class="T47">养老机构</span><span class="T45">店铺经营方式</span></h1>
<p class="P14"><span class="T5">1</span><span class="T5">、甲方依照本协议第四条约定为乙方可使用</span><span class="T15">平台</span><span
        class="T5">发布</span><span class="T15">机构</span><span class="T5">信息，与愿意</span><span class="T15">预约</span><span
        class="T5">乙方</span><span class="T15">入住服机构</span><span class="T5">的客户进行在线交流</span><span
        class="T15">和</span><span class="T5">在线</span><span class="T15">预约</span><span class="T5">，并向通过</span><span
        class="T9">平台</span><span class="T15">完成预约服务。或者</span><span class="T5">乙方开通</span><span
        class="T15">养老机构</span><span class="T5">店铺服务后，乙方可使用</span><span class="T15">平台</span><span
        class="T5">发布</span><span class="T9">服务</span><span class="T5">信息，与愿意购买乙方</span><span class="T9">服务</span><span
        class="T5">的甲方其他客户进行在线交流，进行在线交易，并向通过</span><span class="T9">平台</span><span class="T5">购买其</span><span
        class="T9">服务</span><span class="T5">的客户提供售后服务，同时可参与</span><span class="T9">平台</span><span
        class="T5">相关活动及使用</span><span class="T15">平台</span><span class="T5">同意提供的其他有偿服务。</span></p>
<p class="P13"><span class="T5">2、乙方店铺经营方式：乙方店铺</span><span class="T18">内的服务，</span><span class="T5">乙方店铺内</span><span
        class="T15">提供预约入住机构的</span><span class="T9">服务</span><span class="T15">以及延伸的服务项目</span><span class="T5">，均以乙方自身名义进行</span><span
        class="T15">养老机构信息上传、</span><span class="T18">展示、咨询答复、服务销售、发票开具、上门服务、到店服务、开启服务及售后服务提供等；乙方销售及服务出现争议、纠纷、国家机关机构调查时，由乙方以销售者、服务者的身份独立处理。平台不参与乙方店铺的经营，除依据本协议相关约定外，也不直接介入乙方与其他人间的争议和纠纷。此与前述“甲方与乙方关系声明”一致。</span>
</p>
<p class="P21"> </p>
<h1 class="P7"><a id="a__第三条_入驻条件及证明文件提交"><span/></a><span class="T51">第三条</span><span class="T54"> </span><span
        class="T51">入驻条件及证明文件提交</span></h1>
<p class="P13"><span class="T5">1</span><span class="T5">、入驻条件</span></p>
<p class="P13"><span class="T18">乙方申请成为平台入驻</span><span class="T20">养老机构</span><span
        class="T18">，在平台开展经营活动，须满足以下条件：</span></p>
<p class="P21">（1）乙方已依照中华人民共和国法律取得合法有效的“经营资质证书”；</p>
<p class="P21">（2）乙方申请经营的服务项目与“经营资质证书”相符；</p>
<p class="P21">（3）乙方遵守宪法、法律、行政法规及社会公序良俗，并同意本协议及平台相关规则。</p>
<p class="P21">2、证明文件提交</p>
<p class="P21">（1）乙方须根据平台相关规则及要求向甲方提交“经营资质证书”及授权委托书等文件【如一般企业法人，包括但不限于营业执照、税务登记证、资质证明（特殊行业）、经营许可证（特殊行业）等】。</p>
<p class="P23">
    （2）乙方保证向甲方提供的上述证明文件或其他相关证明真实、合法、准确、有效，并保证上述证明文件或其他相关证明发生任何变更或更新时，及时通知甲方，若上述文件变更或更新导致乙方不符合本协议所规定入驻条件的，甲方有权单方全部或部分限制乙方经营，直至终止本协议。</p>
<p class="P13"><span class="T5">（3）乙方对其提交的证明文件或其他相应证明的真实性、合法性、准确性、有效性承担全部法律责任，若因乙方提交虚假、过期文件或未及时更新或通知证明文件导致纠纷或被相关国家机关处罚的，由乙方独立承担全部法律责任，如因此造</span><span
        class="T18">成平台（包括平台合作方、代理人或职员）损失的，乙方应予以赔偿。对此，按照前述“甲方与乙方关系声明”当中的相关约定执行。</span></p>
<p class="P21"> </p>
<h1 class="P7"><a id="a__第四条_养老机构店铺服务开通及停止"><span/></a><span class="T51">第四条</span><span class="T54"> </span><span
        class="T53">养老机构</span><span class="T51">店铺服务开通及停止</span></h1>
<p class="P13"><span class="T5">1</span><span class="T5">、对于乙方拟开展经营的店铺，甲方在乙方提出开店申请并满足以下条件后</span><span
        class="T13"> </span><span class="T5">7个工作日内完成入驻材料的审核，乙方15天内完成技术服务费和保证金的缴纳操作，甲方在确认收款后，在3个工作日内为</span><span
        class="T9">养老机构</span><span class="T5">开通</span><span class="T9">养老机构</span><span class="T5">中心操作权限。</span></p>
<ol>
    <li><p class="P15" style="margin-left:0cm;"><span class="WW8Num3z0"
                                                      style="display:block;float:left;min-width:0.7409cm;">（1）</span><span
            class="T5">会员是其账户通过平台实名认证的</span><span class="T18">乙方</span><span class="T5">账号。</span><span
            class="odfLiEnd"/> </p></li>
    <li><p class="P40" style="margin-left:0cm;"><span class="WW8Num3z0"
                                                      style="display:block;float:left;min-width:0.7409cm;">（2）</span><span
            class="T18">乙方</span><span class="T5">已按照本协议及平台相关规则提交证明文件或其他相关证明并已通过平台审核。</span><span class="odfLiEnd"/> 
    </p></li>
</ol>
<p class="P13"><span class="T5">2</span><span class="T5">、甲方为乙方开通服务后，乙方可利用平台</span><span class="T9">养老机构</span><span
        class="T5">会员名及自设密码登陆</span><span class="T9">养老机构</span><span class="T5">后台，根据平台相关规则及流程向乙方特定店铺上传、发布服务信息，与客户交流达成交易，使用本协议约定的其他有偿服务等。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、乙方店铺服务的停止：</span></p>
<p class="P22">
    （1）乙方需要停止店铺服务的，应至少提前30个工作日向甲方提出申请，经甲方审核同意后由甲方停止该店铺的服务功能；为弥补甲方已投入的人力、物力和技术支持，乙方同意甲方不退还该店铺未到期部分对应的技术服务费；</p>
<p class="P22">（2）出现以下任一情形时，甲方有权随时停止乙方相关店铺的服务；</p>
<ul>
    <li><p class="P25" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方不满足入驻条件的；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P25" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方提供虚假资质文件的；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P16" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">乙方产品价格、规格等信息标示错误，导致行政处罚、</span><span class="T18">争议和诉讼的；</span><span class="odfLiEnd"/> </p></li>
    <li><p class="P25" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方服务质量、资质不合格的；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P16" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T37">乙</span><span class="T5">方在服务期内连续</span><span class="T13"> </span><span
            class="T5">30（三十）日或累计</span><span class="T13"> </span><span class="T5">45（四十五）日未正常经营店铺的；</span><span
            class="odfLiEnd"/> </p></li>
    <li><p class="P16" style="margin-left:0cm;"><span class="WW8Num1z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">其他违反本协议约定或平台规则的，或者其他甲方认为侵犯平台或</span><span class="T18">平台会员</span><span
            class="T5">权益的；</span><span class="odfLiEnd"/> </p></li>
</ul>
<ul>
    <li><p class="P17" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">乙方店铺因违规行为被扣分达24分的（具体</span><span class="T18">参照甲</span><span class="T5">方规则规范体系）；</span><span
            class="odfLiEnd"/> </p></li>
</ul>
<ul>
    <li><p class="P26" style="margin-left:0cm;"><span class="WW8Num6z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>发生甲方有权扣除乙方保证金情形时，乙方未能在约定或者规定的期限内补足保证金的。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P22">
    （3）据本条约定乙方店铺服务停止时，乙方可以经甲方同意重新开通店铺，但双方需另行签订新的入驻协议，入驻期限以新的协议为准，甲方因履行原入驻协议时，有权不再退还或者已经作为违约金的技术服务费，乙方不得要求返还，乙方需按照新的入驻协议重新缴纳相关费用。</p>
<p class="P13"><span class="T5">4</span><span class="T5">、乙方未经甲方同意，擅自停止店铺经营，或者乙方因为出现上述第四条第三款</span><span
        class="T5">第二项</span><span class="T5">情形被甲方停止店铺服务的，乙方已缴纳的相关店铺的技术服务费未到期部分，作为乙方向甲方支付的违约金，该金额不足以冲抵乙方应付违约金和赔偿金时，乙方应另行支付差额。</span>
</p>
<p class="P31"> </p>
<h1 class="P7"><a id="a__第五条_双方权利及义务"><span/></a><span class="T45">第五条</span><span class="T48"> </span><span
        class="T45">双方权利及义务</span></h1>
<p class="P13"><span class="T5">1</span><span class="T5">、甲方根据本协议向乙方提供平台及相应技术支持服务，维护平台的正常稳定运行，努力提升和改进技术，对平台功能及服务进行更新、升级，不断提升平台性能和交易效率。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、甲方对乙方在使用平台过程中遇到的问题及提出的建议和意见积极予以回复</span><span
        class="T8">，</span><span class="T5">可依乙方需求对其使用平台提供合理的指导和培训。</span></p>
<p class="P13"><span class="T5">3</span><span class="T5">、乙方同意</span><span class="T9">，</span><span
        class="T5">甲方根据乙方</span><span class="T18">“经营资质证书的经营范围及资质</span><span
        class="T5">核实及调整乙方在平台经营的具体服务种类、数量和品类范围。</span></p>
<p class="P13"><span class="T5">4</span><span
        class="T5">、乙方同意并自愿遵守甲方根据国家相关法律法规、政策及平台运营情况，对公示于平台的规则、流程、收费标准等进行更新、调整。</span></p>
<p class="P13"><span class="T5">5</span><span class="T5">、乙方同意甲方对乙方提供的相关资质文件及各类信息资料进行审核。甲方审核为形式审核，审核通过并不代表甲方对审核内容的真实性、合法性、准确性、及时性的确认，乙方仍须对其提交的资料的真实性、合法性、准确性、及时性等承担相应的法律责任。</span>
</p>
<p class="P13"><span class="T5">6</span><span class="T5">、甲方有权对乙方的注册信息、上传的相关数据信息、在平台发布的其他信息及交易行为进行监督检查，对发现的违法违规信息及其相关内容，乙方同意甲方不经通知立即删除，对发现的其他问题或疑问甲方有权向乙方发出询问及要求改正的通知，乙方应在接到通知后立即做出说明或改正。对乙方前述不当行为，甲方有权追究其违约、侵权责任并解除本协议。</span>
</p>
<p class="P13"><span class="T5">7</span><span class="T5">、甲方有权将经国家生效法律文书或行政文书确定的乙方违法违规事件，或乙方违反本协议相关约定的事项，在平台上予以公示；乙方多次违规或者有严重违约、违规情形的，甲方有权对其采取暂停店铺运营、扣除保证金直至终止本协议等措施，上述措施不足以补偿甲方损失的，甲方有权继续向乙方追偿。</span>
</p>
<p class="P13"><span class="T5">8</span><span
        class="T5">、如乙方的运营情况不能满足平台要求，经限期整改调整后，仍无法满足平台运营条件的，甲方有权解除本协议，停止向乙方提供服务。</span></p>
<p class="P13"><span class="T5">9</span><span class="T5">、甲方有权根据市场情况及平台入驻</span><span class="T9">养老机构</span><span
        class="T5">经营情况组织相应的促销活动，乙方应根据自身经营情况及服务情况积极予以支持。</span></p>
<p class="P13"><span class="T5">10</span><span class="T5">、甲</span><span class="T18">方有权要求乙方提供与乙方服务、售后服务等相关的信息，以便于平台客户直接向平台客服代表进行问询或投诉时予以回复，对于甲方无法回答或属乙方掌握的情况，甲方有权要求乙方在指定的时限内予以回复或提供给出相应方案，对乙方未及时解决的客户问询及投诉，甲方有权根据掌握的情况予以答复并对乙方采取相应的处理措施。</span>
</p>
<p class="P41"><span class="T5">11</span><span class="T5">、如因乙方服务、发布的信息或提供的售后服务问题而引发客户对平台的诉讼，甲方有权披露乙方为实际服务提供商，乙方应承担因客户诉讼而产生的全部法律责任，如因</span><span
        class="T18">此而给甲方造成损失的，甲方有权要求乙方赔偿甲方的全部损失。对此，亦按照前述“甲方与乙方关系声明”当中的相关约定执行。</span></p>
<p class="P34"/>
<h1 class="P7"><a id="a__第六条_乙方声明及保证"><span/></a><span class="T45">第六条</span><span class="T48"> </span><span
        class="T45">乙方声明及保证</span></h1>
<p class="P13"><span class="T5">1</span><span class="T5">、保证在平台提交的注册资料真实、准确、合法、有效，对获得的与自设密码共同使用的平台</span><span
        class="T9">养老机构</span><span class="T5">会员</span><span class="T5">名（登录账号）妥善保管，不得擅自转让或授权他人使用，并对利用该会员名和密码所进行的一切活动负全部责任。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、保证符合本协议约定的入驻条件，保证向甲方提交的证明文件及其他相关证明真实、准确、合法、有效，并保证在上述资料发生变更时及时通知甲方，并在平台上予以更新。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、保证有权利订立本协议，</span><span class="T18">保证代理</span><span
        class="T5">人已获得充分授权，并对代理人的行为承担法律责任，同时保证对其雇员、本协议约定的联系人及其他委派的履行本协议的人员的行为承担法律责任。</span></p>
<p class="P13"><span class="T5">4</span><span class="T5">、保证遵守本协议及附件的相关约定和平台相关规则及流程，在使用平台相关服务时严格按照上述约定及规则和流程使用，不从事任何有损甲方利益的行为。</span>
</p>
<p class="P13"><span class="T5">5</span><span class="T5">、保证对在平台经营的服务拥有合法销售权，服务质量合格，符合国家相关质量标准，不侵犯任何第三方的合法权利，并对其服务质量及服务合法性独立承担全部法律责任。</span><span
        class="T18">对此，同意前述“甲方与乙方关系声明”当中的相关约定。</span></p>
<p class="P13"><span class="T5">6</span><span class="T5">、保证在平台发布的服务信息真实、准确，符合平台规则及国家相关法律法规的规定，与实际出售的服务一致，不含任何夸大或虚假内容，并对服务信息承担独立的完全的法律责任。若发布的上述信息变更的，乙方应及时在平台予以更新。</span><span
        class="T18">若因信息未及时变更引起法律后果的，乙方独立承担全部责任。对此，同意前述“甲方与乙方关系声明”当中的相关约定。</span></p>
<p class="P31"> </p>
<p class="P13"><span class="T5">7</span><span class="T5">、保证按照平台审核通过的服务品类和品牌经营，未经甲方确认，不得擅自增加、变更服务品类和品牌。保证按照平台规则及国家相关规定设置服务价格、售卖限制、服务说明及其他服务信息，并对上述设置独立承担全部法律责任。乙方同时保证在经营过程中，按照平台相关规则使用平台即时通讯工具，保证在9:00</span><span
        class="T37">—</span><span class="T5">22:00时间段内有专职客服5分钟内回复甲方客户的咨询，并保证在甲方客户完成支付后48小时内提供服务。</span></p>
<p class="P13"><span class="T5">8</span><span class="T5">、保证向购买其服务的甲方客户开具合法合规发票，并保证按照国家相关规定自行承担相应税费。如因乙方发票开具错误、未开具发票或者其他发票问题引起的纠纷，均由乙方独立承担相应的法律责任，如造成平台损失的，乙方应予以赔偿。</span><span
        class="T18">对此，同意前述“甲方与乙方关系声明”当中的相关约定。</span></p>
<p class="P13"><span class="T5">9</span><span class="T5">、保证按照国家相关规定、自身售后服务承诺及附件自主售后的相关要求，提供相应的售后服务，同时保证按照本协议附件的相关规定履行客户权益保障及相关售后服务义务。针对甲方客户提出的“退款”申请，乙方应在120小时之内给予核定，超时视为同意甲方客户的申请。</span>
</p>
<p class="P13"><span class="T5">10</span><span class="T5">、保证在使用平台进行交易的过程中遵守诚实信用原则，不在交易中采取欺诈及虚假宣传、混淆等不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。</span>
</p>
<p class="P13"><span class="T5">11</span><span class="T5">、保证不将从平台获取的任何数据用于本协议约定以外的用途，同时保证未经甲方许可不得擅自获取、使用、传播平台的任何资料，包括但不限于甲方标识及</span><span
        class="T18">信息、交易数据、客户信息、支付信息、甲方其他客户展示于平台的信息等。对此，同意前述“甲方与乙方关系声明”当中的相关约定。</span></p>
<p class="P13"><span class="T5">12</span><span class="T5">、同意授予平台全球通用（在法律许可范围内）的、免费的许可使用权利</span><span
        class="T5">(</span><span class="T5">并有权对该权利进行再授权</span><span class="T5">)</span><span
        class="T5">，使平台有权</span><span class="T5">(</span><span class="T5">全部或部份地</span><span class="T5">) </span><span
        class="T5">使用、复制、修订、改写、发布、翻译、分发、执行和展示乙方公示于平台网络店铺的各类信息或制作其派生作品，或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。</span></p>
<p class="P13"><span class="T5">13</span><span class="T5">、乙方不得在平台发布任何吸引甲方客户到其他平台或乙方自身网络销售平台或渠道进行交易的信息，也不得在</span><span
        class="T15">服务</span><span class="T5">中夹带此类吸引甲方客户的信息。</span></p>
<p class="P13"><span class="T5">14</span><span class="T5">、乙方不得隐瞒任何可能对平台产生风险的事项，包括但不限于产品出现</span><span
        class="T15">服务</span><span class="T5">缺陷、质量</span><span class="T15">问题</span><span class="T5">、权利纠纷、重大违约、上传资料包含病毒木马等，若乙方发生此类影响平台商誉、正常经营、安全的事项，甲方有权解除本协议并追究乙方违约、侵权</span><span
        class="T18">责任。对此，同意前述“甲方与乙方关系声明”当中的相关约定。</span></p>
<p class="P13"><span class="T5">15</span><span class="T5">、未经甲方另行书面许可，乙方不得将本协议项下权利义务全部或部分转移给第三方；乙方聘请第三方代运营公司代表乙方运营店铺的，第三方代运营公司的一切行为均视同为乙方亲自实施，由乙方承担全部法律后果；乙方与第三方代运营公司间的权利义务关系由乙方与第三方代运营公司单独解决，不得影响到甲方及甲方其他客户的权利。</span><span
        class="T13"> </span></p>
<p class="P22"> </p>
<h1 class="P7"><a id="a__第七条_费用及结算"><span/></a><span class="T45">第七条</span><span class="T48"> </span><span class="T45">费用及结算</span>
</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、乙方应按照本协议附件《乙方信息确认表》、《</span><span class="T9">养老机构</span><span
        class="T5">店铺信息表》或补充协议（若有）中确定的标准及支付方式向平台支付下述费用：</span></p>
<p class="P22">（1）技术服务费</p>
<ul>
    <li><p class="P29" style="margin-left:0cm;"><span class="WW8Num2z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方应按照附件或补充协议（若有）约定的费用标准和时间向平台指定账户支付技术服务费；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P19" style="margin-left:0cm;"><span class="WW8Num2z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">技术服务费按照乙方在平台开设的店铺数量收取，即每开通一个拥有独立店铺</span><span class="T5">ID</span><span class="T5">的</span><span
            class="T9">养老机构</span><span class="T5">，则应在开通前按照附件或补充协议（若有）中确定的标准缴纳技术服务费；</span><span class="odfLiEnd"/> 
    </p></li>
    <li><p class="P35" style="margin-left:0cm;"><span class="WW8Num2z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T11">乙方申请经营的服务品类及对应的技术服务费见《</span><span class="T10">养老机构</span><span
            class="T11">店铺信息表》及相关补充协议（若有）；</span><span class="odfLiEnd"/> </p></li>
    <li><p class="P29" style="margin-left:0cm;"><span class="WW8Num2z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方店铺续约的，乙方应在续约期开始日前缴纳续展期间技术服务费。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P22">（2）保证金</p>
<ul>
    <li><p class="P20" style="margin-left:0cm;"><span class="WW8Num5z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">乙方应按照附件或补充协议（若有）约定的费用标准，</span><span class="T5">在</span><span class="T5">入驻</span><span
            class="T5">时</span><span class="T5">一次性向平台指定账户足额支付保证金；</span><span class="odfLiEnd"/> </p></li>
    <li><p class="P30" style="margin-left:0cm;"><span class="WW8Num5z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方申请经营的服务品类、店铺性质及商标状态及对应的保证金见《店铺信息表》及相关补充协议（若有）；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P20" style="margin-left:0cm;"><span class="WW8Num5z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">保证金主要用于保证乙方按照甲方的规范进行经营，并且在乙方有违规行为时根据相关规则规定扣除；乙方</span><span class="T5">终止与甲方合同时，</span><span
            class="T5">剩余保证金退还乙方</span><span class="T5">。</span><span class="odfLiEnd"/> </p></li>
    <li><p class="P30" style="margin-left:0cm;"><span class="WW8Num5z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>若发生保证金扣除，乙方应遵照甲方的相关规定补齐保证金；逾期不补齐的，甲方有权按照相关规定对乙方进行处罚。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P31">（3）销售佣金</p>
<ul>
    <li><p class="P32" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方通过平台完成的每一个成功交易应按照双方签订的入驻协议的约定向平台支付销售佣金。<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P32" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>乙方在平台的交易额以平台记录的数据为准，乙方经营的品类以本协议或其他补充协议（若有）约定为准，乙方经营的服务品牌以平台审核通过的为准；<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P32" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>销售佣金由平台在客户确认收货后，直接从客户支付的货款中实时扣除。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P39"><span class="T5">（4）</span><span class="T11">积分</span></p>
<ul>
    <li><p class="P32" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>购物积分：会员在甲方平台上购物时获得平台奖励的积分，赠送购物积分成本由乙方负担，从乙方支付的货款中实时扣除，将购物积分金额划转至甲方账户中，甲方向乙方开具发票。<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P32" style="margin-left:0cm;"><span class="WW8Num4z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>抵扣积分：客户在购买服务时，可使用已获得购物积分抵扣部分货款，从客户支付的货款中实时扣除，将抵扣积分金额划转至乙方账户中，乙方向甲方开具发票。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P22">（5）其他费用</p>
<ul>
    <li><p class="P27" style="margin-left:0cm;"><span class="WW8Num8z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>本协议附件或甲乙双方补充协议（若有）约定的乙方应向平台支付的其他费用，按照附件或补充协议（若有）的约定收取。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P13"><span class="T5">2</span><span class="T5">、甲乙双方按照下述约定对乙方在平台完成在线交易的货款进行结算：</span></p>
<p class="P22">（1）客户通过平台与乙方进行在线交易的，货款须支付至与平台合作的平台指定的第三方支付公司账户中或甲方指定银行账户中。</p>
<p class="P13"><span class="T5">（2）甲乙双方的结算以客户订单</span><span class="T44">的确认</span><span class="T49">服务完成</span><span
        class="T44">后</span><span
        class="T5">当天或其他约定的日期计算，甲方将委托第三方支付平台或甲方指定的银行扣除相关费用，包含但不限于佣金、银行手续费、罚款等，将余款支付到乙方的支付账户。</span></p>
<p class="P13"><span class="T5">（3）如甲乙双方的结算模式为月结，以每个自然月为结算期，每个月</span><span class="T5">1</span><span class="T5">日为上月结算日。平台平台系统在每个结算日自动生成结算单，供乙方进行对账。确认账单后，甲方或甲方通知相关付款方将货款支付到乙方账户中。</span>
</p>
<p class="P22">以上结算日遇休息日或法定节假日的，顺延至休息日或法定节假日结束后的第一个工作日。</p>
<p class="P22">
    （4）乙方须向平台提供其在平台指定的第三方支付公司开设的结算账户或银行账户以便平台完成货款结算，若乙方账户信息变更，应提前5个工作日以书面形式通知平台，否则，因乙方变更账户造成货款支付失败、迟延或错误，损失由乙方自行承担。</p>
<p class="P13"><span class="T5">3</span><span class="T5">、甲乙双方根据本协议开展的业务以人民币作为结算货币。</span></p>
<p class="P22">4、站内促销合作</p>
<p class="P22">站内促销包含积分促销、免服务费促销、礼券促销、折扣促销等多种形式，具体以甲方平台发布的促销活动信息和规则为准。</p>
<ul>
    <li><p class="P18" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T9">养老机构</span><span class="T5">促销活动，指乙方自发策划并组织的店铺内促销活动，甲方不承担任何促销成本。</span><span class="odfLiEnd"/> 
    </p></li>
    <li><p class="P18" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span><span
            class="T5">平台促销活动，指平台进行策划并统一组织</span><span class="T9">养老机构</span><span class="T5">报名参与的促销活动，对于其中礼券、秒杀、降价竞购等活动，甲方按约定承担一定的促销成本。</span><span
            class="odfLiEnd"/> </p></li>
    <li><p class="P28" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>成本结算规则：甲乙双方对在结算周期内发生的礼券、秒杀、竞购等促销活动进行核算，按约定的补贴金额进行结算。<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P28" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>成本结算时间：促销补贴应在当次促销活动结束后10日内，由甲方向乙方出具站内促销销售清单，乙方在收到清单后3个工作日内予以确认，并向甲方开具发票，甲方自收到发票之日起20个工作日内向乙方支付款项。乙方如对销售清单存有异议，双方可按上述约定对无异议部分先行结算，异议部分待核实确认后随下一结算周期双方再行办理结算。<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P28" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>发票要求：增值税专用发票，发票内容为“服务费”。<span
            class="odfLiEnd"/> </p></li>
    <li><p class="P28" style="margin-left:0cm;"><span class="WW8Num7z0"
                                                      style="display:block;float:left;min-width:0.7409cm;"></span>其他说明：甲方有权对乙方的交易行为进行查阅，发现涉及乙方的交易行为中存在任何问题或怀疑，均有权向乙方发出询问的通知，并不予以相应的活动补贴，并直接作出扣减乙方活动补贴，或者直接从乙方的销售货款中扣减活动补贴对应的金额，以及对乙方店铺采取限制性处理等措施。<span
            class="odfLiEnd"/> </p></li>
</ul>
<p class="P22"> </p>
<h1 class="P7"><a id="a__第八条_保密"><span/></a><span class="T45">第八条</span><span class="T48"> </span><span
        class="T45">保密</span></h1>
<p class="P13"><span class="T5">1</span><span class="T5">、甲乙双方对于本协议的签订、内容及在履行本协议期间所获知的另一方的商业秘密负有保密义务。非经对方书面同意，任何一方不得向第三方（关联公司除外）泄露、给予或转让该等保密信息。（根据法律、法规、证券交易所规则向政府、证券交易所或其他监管机构提供、双方的法律、会计、商业及其他顾问、雇员除外）。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、如对方提出要求，任何一方均应将载有对方保密信息的任何文件、资料或软件等，在本协议终止后按对方要求归还对方，或予以销毁并提供相关销毁证明文件，不得继续使用。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、在本协议终止之后，各方在本条款项下的义务并不随之终止，各方仍需遵守本协议之保密条款，履行其所承诺的保密义务，直到其他方同意其解除此项义务，或事实上不会因违反本协议的保密条款而给其他方造成任何形式的损害时为止。</span>
</p>
<p class="P13"><span class="T5">4</span><span class="T5">、任何一方均应告知并督促其因履行本协议之目的而必须获知本协议内容及因合作而获知对方商业秘密的雇员、代理人、顾问等遵守保密条款，并对其雇员、代理人、顾问等的行为承担责任。</span>
</p>
<p class="P22"> </p>
<h1 class="P7"><a id="a__第九条_违约责任"><span/></a><span class="T45">第九条</span><span class="T48"> </span><span class="T45">违约责任</span>
</h1>
<p class="P22">1、乙方签订本合同之后，未能实际办理平台入驻手续，不再履行入驻经营时，乙方应当向甲方支付一年的技术服务费作为违约金，甲方如有其他损失时，还可另行主张损失。</p>
<p class="P22">
    2、乙方向甲方提供虚假、失效的证明文件或其他相关证明，在平台发布错误、虚假、违法及不良信息或进行其他违反本协议约定的行为，给甲方及平台造成任何损失的（损失包括但不限于诉讼费、律师费、赔偿、补偿、行政机关处罚、差旅费等），乙方同意甲方自乙方缴纳的保证金及未结算货款中直接予以扣除，该金额不足以充抵乙方应付款时，乙方应另行支付差额，本协议另有约定的除外。</p>
<p class="P13"><span class="T5">3、乙方不论采取何种方式</span><span class="T61">，</span><span class="T5">将</span><span class="T18">平台客户吸引到平台以外的平</span><span
        class="T5">台或场所进行交易或绕开甲方指定付款方式进行交易的，以及非法获取平台系统数据、利用平台谋取不正当利益或从事非法活动的，甲方有权扣除乙方全部保证金作为违约金，并保留向乙方继续追究违约责任的权利。</span>
</p>
<p class="P22">
    4、乙方发生违反本协议及平台规则的情形时，甲方除有权按照本条约定要求乙方承担违约责任外，还有权按照平台相关管理规则采取服务立即下架、暂停向乙方提供服务、暂时关闭乙方后台管理账户、暂缓支付未结算款项、终止合作等措施。</p>
<p class="P22">5、双方确定续订本协议之后，乙方单方终止店铺经营，不再经营时，乙方应当向甲方支付一年的技术服务费作为违约金，甲方如有其他损失时，还可另行主张损失。</p>
<p class="P22"> </p>
<h1 class="P8"><a id="a__第十条_有限责任及免责"><span/></a>第十条<span class="T60"> </span>有限责任及免责</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、</span><span class="T18">因以下例外情况，甲方均不对由于电力、网络、电脑、通讯或其他系统的故障、罢工（含内部罢工或劳工骚乱）、群体劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、地震、洪水、风暴、爆炸、战争、政府行为等不可抗力，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、本协议项下</span><span class="T18">的服务将按“现状”和按“可得到”的状态提供，甲方在此明确声明：对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、使用平台服务下载或者获取任何资料的行为均出于乙方的独立判断，并由其自行承担因此而可能产生的风险和责任。</span></p>
<p class="P13"><span class="T5">4</span><span class="T5">、法律地位声明：</span><span class="T18">同前述“甲方与乙方关系声明”。</span></p>
<p class="P13"><span class="T5">5</span><span class="T5">、不可抗力处理：如本协议履行期间，甲乙双方任何一方遭受不可抗力，均应在遭受不可抗力后尽快通知对方，并于通知之日起</span><span
        class="T5">15</span><span class="T5">日内提供相关证明文件，不可抗力持续达到30日的，任一方有权经通知对方提前终止本协议。因不可抗力原因而导致本协议中止、终止的，双方均不须向对方承担违约责任。</span>
</p>
<p class="P22"> </p>
<h1 class="P8"><a id="a__第十一条_协议有效期"><span/></a>第十一条<span class="T60"> </span>协议有效期</h1>
<p class="P13"><span class="T5">1</span><span
        class="T5">、本协议有效期为一年，有效期内本协议持续对缔约双方有效。除非发生本协议第九条、第十条第五款、第十三条所述的终止或解除事项。</span></p>
<p class="P13"><span class="T5">2</span><span class="T5">、本协议附件《</span><span class="T9">养老机构</span><span class="T5">店铺信息表》或相关补充协议（若有）中约定的服务期届满前60天，如乙方愿意在服务期届满后继续使用甲方提供的服务经营对应店铺的，应向甲方提出相应续展服务期的申请并按约定缴纳下一年度的技术服务费，且经甲方审核通过后，甲乙双方签订次年合作协议。否则，服务期届满后甲方将停止向乙方提供相应店铺的服务，在甲方停止提供服务后</span><span
        class="T5">1</span><span class="T5">个月内，若乙方仍未完成新的服务期申请程序，则甲方不再保留乙方原有店铺及相关数据。</span></p>
<p class="P22"> </p>
<h1 class="P9"><a id="a__第十二条_协议的变更"><span/></a>第十二条 协议的变更</h1>
<p class="P13"><span class="T8">1、</span><span class="T5">本协议附件《乙方信息确认表》中约定的缔约双方基本信息变更的，变更方应于发生该等变更事项后15日内向另一方发出变更的通知及证明文件，未及时通知另一方的，应对变更事项所造成的后果承担全部法律责任。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、本协议附件《乙方信息确认表》、《</span><span class="T9">养老机构</span><span
        class="T5">店铺信息表》或相关补充协议（若有）中约定的费用，甲方有权根据平台运营情况、乙方经营情况及市场情况提前15日通知乙方进行变更，双方签署补充协议予以确认。若乙方不同意变更的，应于甲方发出变更通知后15日内向甲方发出终止协议的书面通知，本协议自甲方收到乙方发出的书面终止通知之日起终止。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、本协议其他条款变更或增加新的条款的，均须经缔约双方协商同意并签署书面补充协议，补充协议一经签署，即构成本协议的组成部分，与本协议具有同等法律效力。</span>
</p>
<p class="P22"> </p>
<h1 class="P9"><a id="a__第十三条_协议的终止及解除"><span/></a>第十三条 协议的终止及解除</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、本协议在下述情形下自然终止：</span></p>
<p class="P13"><span class="T5">（1）本协议附件《</span><span class="T9">养老机构</span><span class="T5">店铺信息表》或相关增开店铺补充协议（若有）中确认的服务期届满，而甲乙双方未在服务期满日前完成次年合作协议的签订，且乙方所有店铺的服务期限均已届满的；</span>
</p>
<p class="P13"><span class="T5">（2）双方签署新协议替代本协议的</span><span class="T62">。</span></p>
<p class="P13"><span class="T5">2</span><span class="T5">、除本协议另有约定外，缔约双方中任一方在本协议有效期内提前终止本协议的，须提前15天以书面形式通知对方，经对方同意后双方签署书面补充协议终止本协议。</span>
</p>
<p class="P13"><span class="T5">本协议签署后，为使乙方顺利开展经营，甲方应为乙方提供人力、物力、技术支持和服务。如因前述原</span><span class="T18">因【</span><span
        class="T25">第十三条</span><span class="T18">】导致本协议</span><span
        class="T5">提前终止的，乙方同意甲方不退还已缴纳的技术服务费，未缴纳的，乙方同意足额缴纳。</span></p>
<p class="P13"><span class="T5">3</span><span class="T5">、乙方有下述情形时，甲方可单方解除本协议：</span></p>
<p class="P13"><span class="T5">（1）乙方任一店铺未正常经营，在服务期内累计达到</span><span class="T5">45</span><span
        class="T5">（四十五）日或者连续达到</span><span class="T5">30</span><span class="T5">（三十）日的；</span></p>
<p class="P22">（2）乙方违反本协议约定或者平台规则规定的相关义务，经甲方通知后拒不改正的；</p>
<p class="P22">（3）本协议约定的其他甲方可单方解除本协议的。</p>
<p class="P13"><span class="T5">4</span><span class="T5">、协议终止后后续事项的处理</span></p>
<p class="P22">（1）双方合作终止后，自终止合作之日起，甲方将关闭乙方店铺会员名账户权限，并对乙方服务全部下架，乙方将无法再通过该账户进行任何形式的操作且平台前端网站不再显示任何乙方服务信息。</p>
<p class="P22">
    （2）本协议终止后，甲方有权保留乙方的注册信息及交易行为记录等数据，但甲方没有为乙方保留这些数据的义务，亦不承担在协议终止后向乙方或第三方转发任何未阅读或未发送的信息的义务，也不就协议终止向乙方或任何第三方承担责任。法律另有规定的除外。</p>
<p class="P22">（3）自终止合作之日起三十日内，甲乙双方进行退场清算，包括但不限于财务对账及在途货物的处理。在途货物指双方合作终止前，客户已购买但尚未交付的服务，对于此类服务，乙方仍应按照合作终止前的流程交付并结算。</p>
<p class="P22">
    （4）双方合作终止，并不免除乙方依据本协议应向客户承担的售后服务及产品保证责任，乙方仍应按照本协议附件关于售后服务的相关约定履行售后服务义务及产品质量保证责任；如在甲乙双方合作终止后，因乙方服务质量问题或售后服务问题而导致甲方或其他第三方人身或财产损失的，乙方仍应独立承担全部责任。</p>
<p class="P22"/>
<h1 class="P9"><a id="a__第十四条_通知及送达"><span/></a>第十四条 通知及送达</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、</span><span class="T13"> </span><span class="T5">一方发给另一方的与本协议有关的通知应以书面形式送达，或以传真、电报、电传、电子邮件等发送。以传真、电报、电传或电子邮件发送的，发送当日为送达日，以邮资预付的挂号信件，特快专递寄送的，签收日为送达日。</span>
</p>
<p class="P13"><span class="T5">2</span><span class="T5">、除非一方以书面形式通知另一方更改联系方式，否则本协议项下的所有通知均应按本协议附件《乙方信息确认表》中列明的联系方式送出，若一方变更联系方式而未通知另一方的，因通知延误或不能送达而造成的损失由联系方式变更一方承担。</span>
</p>
<p class="P22"> </p>
<h1 class="P9"><a id="a__第十五条_争议解决"><span/></a>第十五条 争议解决</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、履行本协议过程中产生的任何争议，双方应协商解决，协商不成的，可向本协议签署地人民法院提起诉讼。</span></p>
<p class="P13"><span class="T5">2</span><span class="T5">、本协议的签订、解释、变更、履行及争议的解决等均适用中华人民共和国大陆地区现行有效的法律。</span></p>
<p class="P22"> </p>
<h1 class="P9"><a id="a__第十六条_其他约定"><span/></a>第十六条 其他约定</h1>
<p class="P13"><span class="T5">1</span><span class="T5">、本协议的任何一方未能及时行使本协议项下的权利不应被视为放弃该权利，也不影响该方在将来行使该权利。</span></p>
<p class="P13"><span class="T5">2</span><span class="T5">、如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除。但本协议的其余条款仍应有效并且有约束力。</span>
</p>
<p class="P13"><span class="T5">3</span><span class="T5">、本协议是缔约双方之间关于本协议中提及合作事项的完整的、唯一的协议，本协议取代了任何先前的关于该合作事项的协议和沟通（包括书面形式和口头形式）。</span>
</p>
<p class="P13"><span class="T5">4</span><span class="T5">、本协议文本以中华人民共和国通用简体汉字版本为准。</span></p>
</body>`

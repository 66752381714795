var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"desc-body"},[_c('div',{staticClass:"dec-body"},[_c('div',{staticClass:"dec",on:{"mouseover":function($event){return _vm.handleMouseOver(0)},"mouseout":_vm.handleMouseOut}},[_vm._m(0),_c('div',{staticClass:"dec-content"},[_vm._v(" 根据国际先进的经验，针对睡眠质量专门设计的评测问卷。 ")])]),_c('div',{staticClass:"dec",on:{"mouseover":function($event){return _vm.handleMouseOver(1)},"mouseout":_vm.handleMouseOut}},[_vm._m(1),_c('div',{staticClass:"dec-content"},[_vm._v(" 社区幸福食堂每日有专人打扫清洁，保证就餐环境干净整洁 ")])])]),_c('div',{class:['food-img', _vm.currentImage === 0 && 'active'],style:({
      backgroundImage: ("url(" + (require('./../assets/images/kexuepinggu_jpg_smzlpg.png')) + ")")
    })}),_c('div',{class:['food-img', _vm.currentImage === 1 && 'active'],style:({
      backgroundImage: ("url(" + (require('./../assets/images/pingcebianjie_jpg_smzlpg.png')) + ")")
    })}),_c('div',{class:['food-img', _vm.currentImage === 2 && 'active'],style:({
      backgroundImage: ("url(" + (require('./../assets/images/zhenduixingjianyi_jpg_smzlpg.png')) + ")")
    })}),_c('div',{class:['food-img', _vm.currentImage === 3 && 'active'],style:({
      backgroundImage: ("url(" + (require('./../assets/images/baocunpingcejieguoi_jpg_smzlpg.png')) + ")")
    })}),_c('div',{staticClass:"dec-body"},[_c('div',{staticClass:"dec",on:{"mouseover":function($event){return _vm.handleMouseOver(2)},"mouseout":_vm.handleMouseOut}},[_vm._m(2),_c('div',{staticClass:"dec-content"},[_vm._v("根据评估报告，给与针对性的健康干预建议")])]),_c('div',{staticClass:"dec",on:{"mouseover":function($event){return _vm.handleMouseOver(3)},"mouseout":_vm.handleMouseOut}},[_vm._m(3),_c('div',{staticClass:"dec-content"},[_vm._v("保存每次评测结果，方便对照。")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dec-title"},[_c('div',{staticClass:"badge"}),_c('span',{staticClass:"title"},[_vm._v("科学的评估")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dec-title"},[_c('div',{staticClass:"badge"}),_c('span',{staticClass:"title"},[_vm._v("评测便捷")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dec-title"},[_c('div',{staticClass:"badge"}),_c('span',{staticClass:"title"},[_vm._v("针对性建议")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dec-title"},[_c('div',{staticClass:"badge"}),_c('span',{staticClass:"title"},[_vm._v("保存评测结果 ")])])}]

export { render, staticRenderFns }
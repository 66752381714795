
































































































































import {Component, Vue} from "vue-property-decorator";
import {Input, message} from "ant-design-vue";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import RelatedNewsItem from "@/components/RelatedNewsItem.vue";
import ServiceComment2 from "@/components/ServiceComment2.vue";
import {ferchNewsDetail, fetchAddNewsComment, fetchNewsCommentsList, fetchRelateNewsList,} from "@/services/api";
import {baseUrl, request} from "@/services/http";

Vue.use(Input);

@Component({
  components: {
    Nav,
    Footer,
    Header,
    RelatedNewsItem,
    ServiceComment2,
  },
})
export default class extends Vue {
  get lables() {
    const lable = this.newsDetail.labelsList;
    return lable ? lable.split(",") : [];
  }

  relatedArticals = [
    {
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场",
    },
    {
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场",
    },
    {
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场",
    },
    {
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场",
    },
    {
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场",
    },
  ];
  comments = [
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
    {
      nickName: "user1",
      comment: "服务不错，和描述一致.",
    },
  ];

  pagination = {
    pageNum: 1,
    pageSize: 10,
    total: 0,
  };

  newsDetail: any = {};
  relateNewsList: any[] = [];
  myComment: string = "";
  commentsList: any[] = [];

  maxContextLength: number = 2000

  changeArticle(id) {
    if (id) this.$router.push(id);
  }
  articleObject: any = {};

  updateSee(newsId) {
    if (!localStorage.getItem("userInfo")) return;
    request(`${baseUrl}/api/v1/comments-news/browse`, {
      method: "POST",
      data: {
        userId: JSON.parse(localStorage.getItem("userInfo") as string)
          .userInfoId,
        newsId,
      },
    }).then(({ code }: any) => {
    });
  }

  mounted() {
    const newsId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1,
      window.location.href.length
    );
    ferchNewsDetail({
      newsId,
    }).then((res) => {
      if (res.code === 0) {
        this.updateSee(newsId);
        this.newsDetail = res.data;
        this.newsDetail.content = Base64.decode(this.newsDetail.content);

        this.articleObject = {
          pre: this.newsDetail.pre,
          next: this.newsDetail.next,
        };
      }
    });

    // 相关文章只取前五条
    fetchRelateNewsList({
      newsId,
      pageSize: 5,
      pageIndex: 1,
    }).then((res) => {
      if (res.code === 0) {
        res.data.list.forEach((item: any) => this.relateNewsList.push(item));
      }
    });

    this.getNewsCommentsList({
      pageIndex: 1,
      pageSize: 10,
      newsId,
    });
  }

  getNewsCommentsList(params: any) {
    fetchNewsCommentsList(params).then((res) => {
      if (res.code === 0) {
        this.commentsList = [];
        res.data.list.forEach((item: any) => this.commentsList.push(item));
        this.pagination = {
          ...this.pagination,
          total: res.data.totalCount,
        };
      }
    });
  }

  submitComment() {
    const newsId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1,
      window.location.href.length
    );
    if (!localStorage.getItem("userInfo")) {
      this.$router.push("/login");
      return;
    }
    const params = {
      messageId: newsId,
      commentContent: this.myComment,
      isAnonymity: true,
    };
    if (this.myComment) {
      fetchAddNewsComment(params).then((res) => {
        if (res.code === 0) {
          this.myComment = "";
          message.success("评论发表成功！");
          this.getNewsCommentsList({
            pageIndex: 1,
            pageSize: 10,
            newsId,
          });
        }
      });
    } else {
      message.warning("请填写评论！");
    }
  }

  onchange(page: any, pageSize: any) {
    window.scrollTo({ top: 0 });

    const newsId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1,
      window.location.href.length
    );
    this.commentsList.length = 0;

    const temp = {
      ...this.pagination,
      pageNum: page,
      pageSize,
    };
    this.pagination = temp;

    const params = {
      pageSize: this.pagination.pageSize,
      pageIndex: this.pagination.pageNum,
      newsId,
    };
    this.getNewsCommentsList(params);
  }
}

<template>
    <div>
        <Header/>
        <!--    <Nav />-->
        <div class="OrganizationJoinStep1">
            <div class="stepTop">
                <img src="../../assets/images/homeImg/组 11@2x.png" height="240" width="3840"/>
                <div class="rz-type">养老机构入驻</div>
            </div>
            <div class="progressBar">
                <MyStepBar :stepData="stepData" :status="currentStatus"/>
            </div>
            <div class="form-box">
                <a-form :form="form" @submit="handleSubmit">
                    <a-form-item v-bind="formItemLayout" label="公司信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司名称">
                        <a-input
                                placeholder="请输入公司名称"
                                v-decorator="[
                'companyName',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.companyNamecheck.bind(this)
                    }
                  ],
                  initialValue: formData.organizationInfo.companyName
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="统一社会信用代码">
                        <a-input
                                placeholder="请输入统一社会信用代码"
                                v-decorator="[
                'orgCode',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.companyCodecheck.bind(this)
                    }
                  ],
                  initialValue: formData.organizationInfo.orgCode
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司所在地">
                        <a-cascader
                                :options="options"
                                style="width: 3.53rem;"
                                placeholder="请选择公司所在地"
                                :loadData="loadData"
                                v-decorator="[
                'companyaddress',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      message: '公司所在地不能为空'
                    }
                  ],
                  initialValue: formData.organizationInfo.companyAddress
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司详细地址">
                        <a-input
                                placeholder="请输入公司详细地址"
                                v-decorator="[
                'companyAddressDetail',
                {
                  rules: [
                    {
                      required: true,
                      message: '公司详细地址不能为空'
                    }
                  ],
                  initialValue: formData.organizationInfo.companyAddressDetail
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司电话">
                        <a-input
                                placeholder="请输入公司电话,如：010-12345678,13812345678,0044-1111111"
                                v-decorator="[
                'companyContactNumber',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.phoneCheck.bind(this)
                    }
                  ],
                  initialValue: formData.organizationInfo.companyContactNumber
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="联系人及账户信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人姓名">
                        <a-input
                                placeholder="请输入法定代表人姓名"
                                v-decorator="[
                'farenName',
                {
                  rules: [
                    {
                      required: true,
                      message: '法定代表人姓名不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.lpName
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人身份证号">
                        <a-input
                                placeholder="请输入法定代表人身份证号"
                                v-decorator="[
                'farenInfoCard',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.infoCardCheck.bind(this)
                    }
                  ],
                  initialValue: formData.accountInfo.lpIdNum
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人联系方式">
                        <a-input
                                placeholder="请输入法定代表人联系方式"
                                v-decorator="[
                'farenphone',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this)
                    },
                    {}
                  ],
                  initialValue: formData.accountInfo.lpTel
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="银行开户名">
                        <a-input
                                placeholder="请输入银行开户名"
                                v-decorator="[
                'bankKaiName',
                {
                  rules: [
                    {
                      required: true,
                      message: '银行开户名不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.accountName
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="银行开户账号">
                        <a-input
                                placeholder="请输入银行开户账号"
                                v-decorator="[
                'bankZhanghao',
                {
                  rules: [
                    {
                      required: true,
                      message: '银行开户账号不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.accountNumber
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行名称">
                        <a-input
                                placeholder="请输入开户银行名称"
                                v-decorator="[
                'bankName',
                {
                  rules: [
                    {
                      required: true,
                      message: '开户银行名称不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.bankName
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行所在地">
                        <a-cascader
                                :options="options"
                                style="width: 3.53rem;"
                                placeholder="请选择开户银行所在地"
                                :loadData="loadData"
                                v-decorator="[
                'kaihuAddress',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      message: '开户银行所在地不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.accountAddress
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行详细地址">
                        <a-input
                                placeholder="请输入开户银行详细地址"
                                v-decorator="[
                'kaihuDeatilAddress',
                {
                  rules: [
                    {
                      required: true,
                      message: '开户银行详细地址不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.accountDetailAdd
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司紧急联系人">
                        <a-input
                                placeholder="请输入公司紧急联系人"
                                v-decorator="[
                'harryName',
                {
                  rules: [
                    {
                      required: true,
                      message: '公司紧急联系人不能为空'
                    }
                  ],
                  initialValue: formData.accountInfo.emergencyContactName
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司紧急联系人手机">
                        <a-input
                                placeholder="请输入公司紧急联系人手机"
                                v-decorator="[
                'harryTel',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this)
                    }
                  ],
                  initialValue:
                    formData.accountInfo.emergencyContactPhone
                }
              ]"
                        />
                    </a-form-item>
                    <div class="imageUpload">
                        <a-form-item v-bind="formItemLayout" label="法人身份证照片（正面）">
                            <a-upload
                                    name="file"
                                    :customRequest="lpIdPicturePositive"
                                    :remove="lpIdPicturePositiveRemove"
                                    @preview="handlePicturePreview"
                                    :file-list="lpIdPicturePositiveFileList"
                                    list-type="picture-card"
                                    v-decorator="[
                'zhengmianupload',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.lpIdPicturePositiveFileCheck.bind(this)
                    }
                  ]
                }
              ]"
                            >
                                <div v-if="lpIdPicturePositiveFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <span class="fileTipBox202005061333">文件大小在3M以内，支持png，jpeg，gif格式</span>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="法人身份证照片（反面）" >
                            <a-upload
                                    name="file"
                                    :customRequest="lpIdPictureNegative"
                                    :remove="lpIdPictureNegativeRemove"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="lpIdPictureNegativeFileList"
                                    v-decorator="[
                'fanmianupload',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.lpIdPictureNegativeFileCheck.bind(this)
                    }
                  ]
                }
              ]"
                            >
                                <div v-if="lpIdPictureNegativeFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox202005061333">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="营业执照照片">
                            <a-upload
                                    name="file"
                                    :customRequest="businessLicensePicture"
                                    :remove="businessLicensePictureRemove"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="businessLicensePictureFileList"
                                    v-decorator="[
                'yinyephone',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.businessLicensePictureFileCheck.bind(this)
                    }
                  ]
                }
              ]"
                            >
                                <div v-if="businessLicensePictureFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox202005061333">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="开户许可证" >
                            <a-upload
                                    name="file"
                                    :customRequest="accountOpeningPermit"
                                    :remove="accountOpeningPermitRemove"
                                    @preview="handlePicturePreview"
                                    :file-list="accountOpeningPermitList"
                                    list-type="picture-card"
                                    v-decorator="[
                'openAccount',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.accountOpeningPermitFileCheck.bind(this),
                    },
                  ],
                },
              ]"

                            >
                                <div v-if="accountOpeningPermitList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox202005061333">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="经营许可证" >
                            <a-upload
                                    name="file"
                                    :customRequest="uploadAtlasAll"
                                    :remove="uploadAtlasRemove"
                                    :multiple="true"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="uploadAtlasList"
                                    v-decorator="[
                'imageUrl',
                {
                  rules: [
                    {
                      required: false,
                      validator: this.uploadCoverFileCheckOne.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div v-if="uploadAtlasList.length < 3">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>

                            </a-upload>

                            <div class="fileTipBox202005061333">
                                文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="产品资质证书" >
                            <a-upload
                                    name="file"
                                    :customRequest="uploadAtlasAllTwo"
                                    :remove="uploadAtlasRemoveTwo"
                                    :multiple="true"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="ProductQualificationList"
                                    v-decorator="[
                'imageUrlTwo',
                {
                  rules: [
                    {
                      required: false,
                      validator: this.uploadCoverFileCheckTwo.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div v-if="ProductQualificationList.length < 3">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>

                            </a-upload>
                            <div class="fileTipBox202005061333">
                                文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张
                            </div>
                        </a-form-item>
                    </div>
                    <a-form-item v-bind="tailFormItemLayout" class="present">
                        <a-button class="button1" @click="previous"><img
                                src="../../assets/images/homeImg/路径 2330@2x.png"/>返回上一步
                        </a-button>
                        <a-button type="primary" html-type="submit" class="button2">下一步</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <Footer/>

        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="'30vw'">
            <div style="margin-top: 0.2rem;">
                <img alt="example" style="width: 100%;max-height: 5rem;margin-bottom: 0.3rem" :src="previewImage"/>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import MyStepBar from "@/components/enterMyStepBar";
    import {Button, Cascader, Checkbox, Form, Input, message, Select, Upload} from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import {fetchAddrCascader, uploadMerchantFile, uploadServiceFile} from "@/services/api";

    import {baseUrl} from "@/services/http";

    Vue.use(Checkbox);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Cascader);
    Vue.use(Upload);

    export default {
        components: {
            Nav,
            Footer,
            Header,
            ReturnStep,
            MyStepBar
        },
        data() {
            return {
                checked: false,
                formItemLayout: {
                    labelCol: {
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 10,
                        },
                    },
                    wrapperCol: {
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 14,
                        },
                    },
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 24,
                            offset: 0
                        },
                        sm: {
                            span: 13,
                            offset: 10
                        }
                    }
                },
                isSendingFisrt: true,
                currentStatus: 2,
                stepData: [
                    {
                        name: "入驻协议",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-1.png'),
                    },
                    {
                        name: "主体信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-2.png'),
                    },
                    {
                        name: "养老机构信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-3.png'),
                    },
                ],
                options: [],
                lpIdPicturePositiveFileList: [],
                lpIdPictureNegativeFileList: [],
                businessLicensePictureFileList: [],
                accountOpeningPermitList: [],

                uploadAtlasList: [],
                ifImageCanUploadTwo: true,
                ProductQualificationList: [],
                ProductQualificationImagesList: [], //经营许可证存储当前图片的路径
                ProductQualificationPathList: [], // 产品资质证书存储当前图片的路径,
                maxProductQualificationSize: 3, // 存储当前图片的路径
                ProductQualificationUpload: true,

                ifImageCanUpload: true,
                formData: {
                    organizationInfo: {
                        // 公司信息
                        companyName: "", // 公司名称
                        orgCode: "", // 公司组织机构代码
                        companyAddressDetail: "", // 公司详细地址
                        companyContactNumber: "", // 公司电话
                        companyProvince: "", // 公司地址（省、直辖市、自治区等）
                        companyCity: "", // 公司地址（市）
                        companyArea: "", // 公司地址（区、县）
                        companyStreet: "", // 公司地址（街道、乡）
                        detailDescription: "",
                    },
                    accountInfo: {
                        // 联系人及账户信息
                        lpName: "", // 法人名称
                        lpIdNum: "", // 法人身份证编号
                        lpTel: "", // 法人联系方式
                        accountName: "", // 银行开户名
                        accountNumber: "", // 银行开户账号
                        bankName: "", // 开户银行名称
                        accountDetailAdd: "", // 开户详细地址
                        businessLicensePicture: "", // 营业执照照片
                        accountProvince: "",  //开会所在省
                        accountCity: "",  //开户所在市
                        accountArea: "", //开户所在县区
                        accountStreet: "", //开户所在街道
                        lpIdPicturePositive: "", // 法人身份证照片正面
                        lpIdPictureNegative: "", // 法人身份证照片反面
                        emergencyContactName: "", // 紧急联系人姓名
                        emergencyContactPhone: "", // 紧急联系人-联系方式
                        accountOpeningPermit: "",
                        operatingLicense: "",
                        productQualificationCertificate: "",
                    },
                    administratorInfo: {
                        // 管理员信息
                        adminName: "", // 管理员姓名
                        adminIdNum: "", // 管理员身份证编号
                        adminTel: "" // 管理员手机号
                    },
                    expansionInfo: {
                        coverImage: "",
                        createTime: "",
                        briefIntro: "",
                        orgRemark: "",
                        areaSize: 0,
                        orgIntro: "",
                    },
                    shopInfo: {
                        orgAccount: JSON.parse(localStorage.getItem("userInfo"))["userName"], // 商家账号
                        orgName: "",
                        orgForm: [],
                        servicesCategoryList: [],
                        orgImagesList: [],
                        orgType: "",
                        orgCharacter: "",
                        serviceOdject: "",
                        addressProvince: "",
                        needServiceForOrg: false,
                        addressCity: "",
                        addressArea: "",
                        addressStreet: "",
                        addressDetail: "",
                        bedNum: "",
                        lowestPrice: "",
                        highestPrice: "",
                        orgTel: "",
                        openTime: "",
                        closeTime: "",
                        shopAddress: [],
                    },
                    label: {
                        specialService: [],
                    },
                },
                vcodes: "", // 验证码相关数据设置
                isSending: false, // 是否发送
                remainTime: 60,
                interval: null,


                previewVisible: false, // 预览框展示
                previewImage: ""
            };
        },
        beforeCreate() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.form = this.$form.createForm(this);
            } else {
                this.$router.push("/login");
            }
        },
        async mounted() {
            await this.init();
            let item = localStorage.getItem("organizationJoinForm");
            //回填地址
            if (item != "undefined") {
                if (item) {
                    let data = JSON.parse(item);
                    this.formData = data;
                    this.testImages();
                    var companyProvince = Number(data.organizationInfo.companyProvince);
                    var companyCity = Number(data.organizationInfo.companyCity);
                    var companyArea = Number(data.organizationInfo.companyArea);
                    var companyStreet = Number(data.organizationInfo.companyStreet);
                    this.formData.organizationInfo.companyAddress = [companyProvince, companyCity, companyArea, companyStreet];

                    var addressProvinceTwo = Number(data.accountInfo.accountProvince);
                    var addressCityTwo = Number(data.accountInfo.accountCity);
                    var addressAreaTwo = Number(data.accountInfo.accountArea);
                    var addressStreetTwo = Number(data.accountInfo.accountStreet);
                    this.formData.accountInfo.accountAddress = [addressProvinceTwo, addressCityTwo, addressAreaTwo, addressStreetTwo];
                    let m = JSON.parse(JSON.stringify(this.formData.organizationInfo.companyAddress));
                    let n = JSON.parse(JSON.stringify(this.formData.accountInfo.accountAddress));
                    m.pop();
                    n.pop();
                    this.initAllArea(m);
                    this.initAllArea(n);
                }
            }


        },
        methods: {
            testImages() {
                //法人身份证照片（正面）
                if (this.formData.accountInfo.lpIdPicturePositive) {
                    this.lpIdPicturePositiveFileList.push({
                        uid: "lpidpicturepositivefilelist",
                        name: "lpIdPicturePositiveFile.png",
                        status: "done",
                        url: this.formData.accountInfo.lpIdPicturePositive.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.accountInfo.lpIdPicturePositive}` : this.formData.accountInfo.lpIdPicturePositive
                    });
                }
                //法人身份证照片（反面）
                if (this.formData.accountInfo.lpIdPictureNegative) {
                    this.lpIdPictureNegativeFileList.push({
                        uid: "lpidpicturenegativefilelist",
                        name: "lpIdPictureNegativeFile.png",
                        status: "done",
                        url: this.formData.accountInfo.lpIdPictureNegative.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.accountInfo.lpIdPictureNegative}` : this.formData.accountInfo.lpIdPictureNegative
                    });
                }
                //营业执照
                if (this.formData.accountInfo.businessLicensePicture) {
                    this.businessLicensePictureFileList.push({
                        uid: "businesslicensepicturefilelist",
                        name: "businessLicensePictureFile.png",
                        status: "done",
                        url: this.formData.accountInfo.businessLicensePicture.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.accountInfo.businessLicensePicture}` : this.formData.accountInfo.businessLicensePicture
                    });
                }
                //开户许可证
                if (this.formData.accountInfo.accountOpeningPermit) {
                    this.accountOpeningPermitList.push({
                        uid: "accountOpeningPermitList",
                        name: "accountOpeningPermitList.png",
                        status: "done",
                        url: this.formData.accountInfo.accountOpeningPermit.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.accountInfo.accountOpeningPermit}` : this.formData.accountInfo.accountOpeningPermit,
                    });
                }
                //经营许可证多图片
                if (this.formData.accountInfo.operatingLicense) {
                    let operatingLicense = this.formData.accountInfo.operatingLicense.split(",");
                    operatingLicense.forEach((item, index) => {
                        if (this.formData.accountInfo.operatingLicense) {
                            //图片数据赋值出来
                            this.ProductQualificationImagesList.push({
                                "uid": "imageUrl" + index,
                                "path": item
                            })
                            this.uploadAtlasList.push({
                                uid: "imageUrl" + index,
                                name: "imageUrl" + index + ".png",
                                status: "done",
                                url: item.indexOf("/fileupload") == 0 ? `${baseUrl}${item}` : item,
                            });

                        }
                    })

                }
                //产品资质证书
                if (this.formData.accountInfo.productQualificationCertificate) {
                    let operatingLicense = this.formData.accountInfo.productQualificationCertificate.split(",");
                    operatingLicense.forEach((item, index) => {
                        if (this.formData.accountInfo.productQualificationCertificate) {
                            //图片数据赋值出来
                            this.ProductQualificationPathList.push({
                                "uid": "imageUrl" + index,
                                "path": item
                            })
                            //图片数据回填
                            this.ProductQualificationList.push({
                                uid: "imageUrl" + index,
                                name: "imageUrl" + index + ".png",
                                status: "done",
                                url: item.indexOf("/fileupload") == 0 ? `${baseUrl}${item}` : item,
                            });
                        }
                    })

                }
            },
            async initAllArea([a, b, c]) {
                let opton = this.options;
                for (let level of [a, b, c]) {
                    let item = opton.find(item => item.value == level);
                    await this.loadData([item]);
                    opton = item.children;
                }
            },
            previous() {
                this.saveJoinForm();

                this.$router.push("/OrganizationJoinRead")
                // this.$router.back();
            },

            /*获取表单数据*/
            getFormValue(name) {
                return this.form.getFieldValue(name);
            },
            /* 上传图片统一处理*/
            uploadFile(options, typeId, callback) {
                if (!this.ifImageCanUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", typeId);
                let p = {
                    percent: 0
                };
                uploadMerchantFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({
                            percent: 99.8
                        });
                    }
                })
                    .then(data => {
                        // this.loading = false;
                        if (data && data.code == 0) {
                            options.onSuccess();
                            callback(data.filePath);
                        } else {
                            message.error(data.msg);
                            callback(false);
                        }
                    })
                    .catch(error => {
                        message.error("上传失败");
                        callback(false);
                    });
            },

            // 校验营业执照图片
            businessLicensePictureFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.businessLicensePicture) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传营业执照电子版！");
                    return;
                }

                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({yinyephone: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },

            /*图片预览 */
            async handlePicturePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await this.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            /*将文件转成Base64*/
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            /*预览框关闭*/
            handleCancel() {
                this.previewVisible = false;
            },
            /* 校验公司名称*/
            companyNamecheck(rule, value, callback) {
                if (!value) {
                    callback("公司名称不能为空");
                    return;
                }
                callback();
            },


            /* 校验社会统一信用代码*/
            companyCodecheck(rule, value, callback) {
                if (!value) {
                    callback("统一社会信用代码不能为空");
                    return;
                }
                const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的统一社会信用代码");
                    return;
                }
                callback();
            },
            /* 校验电话号码*/
            phoneCheck(rule, value, callback) {
                if (!value) {
                    callback("公司电话不能为空");
                    return;
                }
                const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
                const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value) && !reg2.test(value)) {
                    callback(
                        "格式不正确，请输入正确格式的公司电话,如：010-12345678，13812345678,0044-1111111"
                    );
                    return;
                }
                callback();
            },
            /*校验身份证号*/
            infoCardCheck(rule, value, callback) {
                const field = rule.field;
                const fieldsObject = {
                    farenInfoCard: "法定代表人",
                    admininfoCard: "管理员"
                };
                if (!value) {
                    callback(`${fieldsObject[field]}身份证号不能为空`);
                    return;
                }

                // ^\d{15}|\d{18}$
                const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的身份证号");
                    return;
                }
                callback();
            },
            /*校验手机号*/
            telCheck(rule, value, callback) {
                const field = rule.field;
                const fieldsObject = {
                    farenphone: "法定代表人联系方式",
                    harryTel: "公司紧急联系人手机",
                    adminTel: "管理员联系手机"
                };
                if (!value) {
                    callback(`${fieldsObject[field]}不能为空`);
                    return;
                }

                const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的手机号");
                    return;
                }
                callback();
            },
            /*法人名字校验*/
            farenName(rule, value, callback) {
                // ^[\u4e00-\u9fa5]{0,}$
                const reg = /^[\u4e00-\u9fa5a-zA-Z ]{0,20}$/;
                if (!reg.test(value)) {
                    callback("只能输入汉字或字母");
                    return;
                } else if (value && value.length < 2) {
                    callback("请填写正确格式的姓名");
                    return;
                }
                callback();
            },
            /* 获取地址信息*/
            loadData(selectedOptions) {
                const targetOption = selectedOptions[selectedOptions.length - 1];
                const {value} = targetOption;
                const {level} = targetOption;
                targetOption.loading = true;
                return new Promise(resolve => {
                    fetchAddrCascader({
                        areaType: level,
                        areaId: value
                    }).then(data => {
                        resolve();
                        targetOption.loading = false;
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach(i => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        isLeaf: i.childNum == 0,
                                        level: level + 1
                                    });
                                });
                            }
                            targetOption.loading = false;
                            targetOption.children = children;
                            this.options = [...this.options];
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },
            /* 初始化地址*/
            init(place) {
                return new Promise(resolve => {
                    fetchAddrCascader({
                        areaType: 0,
                        areaId: ""
                    }).then(data => {
                        resolve();
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach(i => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        level: 1,
                                        isLeaf: false
                                    });
                                });
                            }
                            this.options = children;
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },
            /* 上传法人身份证照片（正面）*/
            lpIdPicturePositive(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 4, url => {
                        if (url){
                            this.formData.accountInfo.lpIdPicturePositive = url;
                            this.lpIdPicturePositiveFileList.push({
                                uid: "lpidpicturepositivefilelist",
                                name: "lpIdPicturePositiveFile.png",
                                status: "done",
                                url: this.formData.accountInfo.lpIdPicturePositive.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` :url
                            });
                        } else {
                            this.lpIdPicturePositiveFileList.pop();
                        }
                    });
                }, 1000);
            },
            /*移除法人身份证照片（正面）*/
            lpIdPicturePositiveRemove(file) {
                this.lpIdPicturePositiveFileList = [];
                this.formData.accountInfo.lpIdPicturePositive = "";
            },
            lpIdPicturePositiveFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.lpIdPicturePositive) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    this.ifImageCanUpload = false;
                    callback("请上传法人身份证电子版（正面）");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({zhengmianupload: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },

            /* 上传法人身份证照片（反面）*/
            lpIdPictureNegative(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 5, url => {
                        if (url){
                            this.formData.accountInfo.lpIdPictureNegative = url;
                            this.lpIdPictureNegativeFileList.push({
                                uid: "lpidpicturenegativefilelist",
                                name: "lpIdPictureNegativeFile.png",
                                status: "done",
                                url: this.formData.accountInfo.lpIdPictureNegative.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url
                            });
                        } else {
                            this.lpIdPictureNegativeFileList.pop();
                        }
                    });
                }, 800);
            },
            /* 移除法人身份证照片（反面）*/
            lpIdPictureNegativeRemove(file) {
                this.lpIdPictureNegativeFileList = [];
                this.formData.accountInfo.lpIdPictureNegative = "";
            },
            // 校验法人身份证照片（反面）
            lpIdPictureNegativeFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.lpIdPictureNegative) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传法人身份证电子版（反面）");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({fanmianupload: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();
                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            /* 上传营业执照图片*/
            businessLicensePicture(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 6, url => {
                        if (url){
                            this.formData.accountInfo.businessLicensePicture = url;
                            this.businessLicensePictureFileList.push({
                                uid: "businesslicensepicturefilelist",
                                name: "businessLicensePictureFile.png",
                                status: "done",
                                url: this.formData.accountInfo.businessLicensePicture.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url
                            });
                        } else {
                            this.businessLicensePictureFileList.pop();
                        }
                    });
                }, 800);
            },
            /* 移除营业执照图片*/
            businessLicensePictureRemove(file) {
                this.businessLicensePictureFileList = [];
                this.formData.accountInfo.businessLicensePicture = "";
            },
            //上传开户许可证
            accountOpeningPermit(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 2, (url) => {
                        if (url){
                            this.formData.accountInfo.accountOpeningPermit = url;
                            this.accountOpeningPermitList.push({
                                uid: "accountOpeningPermitList",
                                name: "accountOpeningPermitList.png",
                                status: "done",
                                url: this.formData.accountInfo.accountOpeningPermit.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url,
                            });
                        } else {
                            this.accountOpeningPermitList.pop();
                        }
                    });
                }, 800);
            },
            // 移除开户许可证
            accountOpeningPermitRemove(file) {
                this.accountOpeningPermitList = [];
                this.formData.accountInfo.accountOpeningPermit = "";
            },
            //校验开户许可证
            accountOpeningPermitFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.accountOpeningPermit) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传开户许可证！");
                    return;
                }

                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({openAccount: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },

            //上传经营许可证
            uploadAtlasAll(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadAtlas(options);
                }, 1000);
            },
            //上传经营许可证
            uploadAtlas(options) {
                if (!this.ifImageCanUploadTwo) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                }).then(data => {

                    if (data && data.code == 0) {
                        options.onSuccess();

                        const file = options.file;
                        if (this.ProductQualificationImagesList.length > this.maxProductQualificationSize) {
                            return;
                        }
                        //获取上传数据
                        this.ProductQualificationImagesList.push({
                            "uid": file.uid,
                            "path": data.filePath,
                        })
                        //上传显示大图而不是缩略图
                        this.uploadAtlasList.push({
                            "uid": file.uid,
                            "name": "imageUrl" + file.uid + ".png",
                            "status": "done",
                            "url": `${baseUrl}${data.filePath}`,
                        })
                    } else {
                        message.error(data.msg);
                        this.uploadAtlasList.pop();
                    }
                }).catch(error => {
                    message.error("上传失败");
                    this.uploadAtlasList.pop();
                });
            },
            //移除经营许可证
            uploadAtlasRemove(file) {
                let removeId = file.uid;
                //储存数据移除
                this.ProductQualificationImagesList = this.ProductQualificationImagesList.filter(i => i.uid !== removeId);
                //展示图片移除
                this.uploadAtlasList = this.uploadAtlasList.filter(i => i.uid !== removeId)
            },
            //校验经营许可证上传
            uploadCoverFileCheckOne(rule, value, callback) {
                if (value!=undefined) {
                    if (value.file.size > 3000 * 1000 * 1) {
                        this.ifImageCanUpload = false;
                        value.fileList.length = 0;
                        this.form.setFieldsValue({imageUrlTwo: ""});
                        message.error("上传失败，请控制图片在3M之内");
                        callback();
                        return;
                    } else {
                        this.ifImageCanUpload = true;
                        callback();
                    }
                }else{
                    callback();
                    return;
                }
            },


            //上传产品资质证书
            uploadAtlasAllTwo(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadAtlasTwo(options);
                }, 1000);
            },
            //上传产品资质证书
            uploadAtlasTwo(options) {
                if (!this.ProductQualificationUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                }).then(data => {
                    if (data && data.code == 0) {
                        options.onSuccess();
                        const file = options.file;
                        if (this.ProductQualificationPathList.length > this.maxProductQualificationSize) {
                            return;
                        }
                        //获取上传数据
                        this.ProductQualificationPathList.push({
                            "uid": file.uid,
                            "path": data.filePath,
                        })
                        //上传显示大图而不是缩略图
                        this.ProductQualificationList.push({
                            "uid": file.uid,
                            "name": "imageUrl" + file.uid + ".png",
                            "status": "done",
                            "url": `${baseUrl}${data.filePath}`,
                        })
                    } else {
                        message.error(data.msg);
                        this.ProductQualificationList.pop();
                    }
                }).catch(error => {
                    message.error("上传失败");
                    this.ProductQualificationList.pop();
                });
            },
            //移除产品资质证书
            uploadAtlasRemoveTwo(file) {
                let removeId = file.uid;
                //储存数据移除
                this.ProductQualificationPathList = this.ProductQualificationPathList.filter(i => i.uid !== removeId);
                //展示图片移除
                this.ProductQualificationList = this.ProductQualificationList.filter(i => i.uid !== removeId)
            },
            //校验产品资质证书上传
            uploadCoverFileCheckTwo(rule, value, callback) {
                if (value!=undefined) {
                    if (value.file.size > 3000 * 1000 * 1) {
                        this.ifImageCanUpload = false;
                        value.fileList.length = 0;
                        this.form.setFieldsValue({imageUrlTwo: ""});
                        message.error("上传失败，请控制图片在3M之内");
                        callback();
                        return;
                    } else {
                        this.ifImageCanUpload = true;
                        callback();
                    }
                }else{
                    callback();
                    return;
                }
            },


            //数据存储
            saveJoinForm() {
                const getFormValue = this.getFormValue;
                let companyAddress = getFormValue("companyaddress") || [];

                this.formData.organizationInfo.companyAddress = companyAddress;
                let accountAddress = getFormValue("kaihuAddress") || [];
                this.formData.accountInfo.accountAddress = accountAddress;

                //公司信息
                this.formData.organizationInfo.companyName = getFormValue("companyName");
                this.formData.organizationInfo.orgCode = getFormValue("orgCode");
                this.formData.organizationInfo.companyAddressDetail = getFormValue("companyAddressDetail");
                this.formData.organizationInfo.companyContactNumber = getFormValue("companyContactNumber");
                this.formData.organizationInfo.companyProvince = companyAddress[0];
                this.formData.organizationInfo.companyCity = companyAddress[1];
                this.formData.organizationInfo.companyArea = companyAddress[2];
                this.formData.organizationInfo.companyStreet = companyAddress[3] || "";

                // 联系人及账户信息
                this.formData.accountInfo.lpName = getFormValue("farenName"); // 法人名称
                this.formData.accountInfo.lpIdNum = getFormValue("farenInfoCard"); // 法人身份证编号
                this.formData.accountInfo.lpTel = getFormValue("farenphone"); // 法人联系方式
                this.formData.accountInfo.accountName = getFormValue("bankKaiName"); // 银行开户名
                this.formData.accountInfo.accountNumber = getFormValue("bankZhanghao"); // 银行开户账号
                this.formData.accountInfo.bankName = getFormValue("bankName"); // 开户银行名称
                this.formData.accountInfo.accountDetailAdd = getFormValue("kaihuDeatilAddress"); // 开户详细地址
                this.formData.accountInfo.accountProvince = accountAddress[0];
                this.formData.accountInfo.accountCity = accountAddress[1];
                this.formData.accountInfo.accountArea = accountAddress[2];
                this.formData.accountInfo.accountStreet = accountAddress[3] || "";
                this.formData.accountInfo.emergencyContactName = getFormValue("harryName"); // 紧急联系人姓名
                this.formData.accountInfo.emergencyContactPhone = getFormValue("harryTel"); // 紧急联系人-联系方式
                this.formData.accountInfo.operatingLicense = this.ProductQualificationImagesList.map(i => i.path).join(',') //经营许可证
                this.formData.accountInfo.productQualificationCertificate = this.ProductQualificationPathList.map(i => i.path).join(',') //产品资质证书
                // 存储
                localStorage.setItem("organizationJoinForm", JSON.stringify(this.formData));
            },
            // 提交数据下一步
            handleSubmit(e) {
                const getFormValue = this.getFormValue;
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        // 封装信息
                        // 校验地址 公司地址和开户地址
                        this.saveJoinForm();
                        this.$router.push("/OrganizationJoinStep2");
                    }
                });
            },


        }
    };
</script>

<style lang="less">
    .OrganizationJoinStep1 {
        margin-top: -0.01rem;

        .stepTop {
            width: 100%;
            display: flex;
            margin-bottom: 0.6rem;
            position: relative;

            img {
                width: 100%;
                height: 1.2rem;
            }

            .rz-type {
                position: absolute;
                left: 4.4rem;
                top: 0.39rem;
                font-size: 0.24rem;
                color: #fff;
                font-weight: bold;
                opacity: 1;
            }
        }

        .progressBar {
            width: 4.5rem;
            height: 1rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            /*border: 1px solid red;*/
        }

        .form-box {
            padding: 0.1rem 0;
            margin-bottom: 0.7rem;

            .tip {
                margin-top: 0.2rem;

                .ant-form-item-label label {
                    font-size: 0.14rem;
                    font-weight: bold;
                    color: #454F63;
                    opacity: 1;
                }

                .ant-form-item-label > label::after {
                    content: '';
                }
            }

            .ant-input {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
                width: 3.53rem;
                height: 0.4rem;

                &::placeholder {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #8F9BA8;
                    opacity: 1;
                }

            }

            .ant-form-item-label label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-cascader-picker-label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-form-item-control-wrapper {
                .ant-form-explain {
                    font-size: 0.14rem;
                    color: #FF4021;
                    opacity: 1;
                    margin-left: 3.7rem;
                    margin-top: -0.33rem;
                }
            }

            .present {
                margin-top: 0.8rem;

                button {
                    width: 1.46rem;
                    height: 0.4rem;
                    opacity: 1;
                    border-radius: 0.2rem;
                    font-size: 0.14rem;

                    img {
                        width: 7.75px;
                        height: 13.38px;
                    }
                }

                .button1 {
                    border: 1px solid #78849E;
                    margin-right: 0.5rem;
                    color: #454F63;

                    img {
                        margin-right: 0.07rem;
                    }
                }

                .button2 {
                    color: #FFFFFF;

                    img {
                        margin-left: 0.07rem;
                        transform: rotate(180deg);
                    }
                }
            }

            .ant-form-item {
                margin-bottom: 0.2rem;
            }

            .ant-input:hover {
                border-color: #60c7c6;
            }

            .ant-select-selection:hover {
                border-color: #60c7c6;
            }

            .ant-btn-primary {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:focus {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }
        }

        .imageUpload {
            .ant-form-item{
                min-height: 1.3rem;
            }
            .ant-form-item-control-wrapper {
                .ant-form-explain {
                    font-size: 0.14rem;
                    color: #FF4021;
                    opacity: 1;
                    margin-left: 3.7rem;
                    margin-top: -0.8rem;
                }
            }

            .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                width: 100%;
                /*object-fit: contain;*/
                object-fit: fill;
                border-radius: 4px;
            }
            .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
                padding: 0;
            }
        }

        .fileTipBox202005061333 {
            position: absolute;
            font-size: 0.14rem;
            font-weight: 400;
            color: #a9a9a9;
            opacity: 1;
            top: 0.17rem;
            display: flex;
            align-items: center;
            height: 0.2rem;

        }
    }
</style>

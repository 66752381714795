

    import {Component, Vue} from "vue-property-decorator";
    import {http} from "@/services/http";

    @Component({})
export default class detailFooter extends Vue {
  details = [];
  created() {
    http.get(`/api/v1/commodities/service-detail`).then((res) => {
      this.details = (res as any)["data"].details;
    });
  }
}





























































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {collectionsOrgService, fetchCollection} from "@/services/api";
    import pensionOrderForm from "../views/PensionOrderForm.vue";
    import PensionOrderSuccess from "../views/PensionOrderSuccess.vue";
    import magnifier from "../components/magnifier.vue";
    import {baseUrl, request} from "@/services/http";

    @Component({
  props: {
    title: String,
    // orgId: String,
    location: String,
    lowPrice: Number,
    highPrice: Number,
    orgType: String,
    orgDistribut: String,
    buildYear: String,
    size: String,
    bedNum: Number,
    orgImages: Array,
    coverPicture: String,
    orgId: String,
    checkStatus: String,
    serviceNum: Number,
    serviceTypeNum: Number,
  },
  components: {
    pensionOrderForm,
    PensionOrderSuccess,
    magnifier,
  },
})
export default class OrgDetailHeader extends Vue {
  currentImageIndex;
  collectionString = "收藏";
  visible: boolean = false;
  visible2 = false;
  showImage = false;
  currImg = "";
  @Prop() private orgId!: string;

  get _imgUrl() {
    const that = this
    if (that.currentImageIndex > 0) {
      return that.$props.orgImages.length > 0
          ? that.$props.orgImages[that.currentImageIndex].orgImage
          : that.$props.coverPicture;
    } else {
      return that.$props.coverPicture
    }
  }

  reserveSuccessEvent(e) {
    this.visible = false;
    this.visible2 = true;
  }

  revivification(value) {
    this.$emit("revivification", value);
  }

  toDetail() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.visible = true;
    } else {
      this.$router.push("/login");
    }
  }

  created() {
    this.isCollectionsOrgService();
  }

  isCollectionsOrgService() {
    let userInfo = localStorage.getItem("userInfo");
    if(userInfo){
      request(`${baseUrl}/api/v1/token`).then((res:any) => {
        if (res.code === 401) {
          localStorage.removeItem("userInfo");
        } else {
          collectionsOrgService({
            itemId: this.$props.orgId,
            itemType: 238,
          }).then((res) => {
            this.collectionString = res.data.isCollected === 0 ? "收藏" : "已收藏";
          });
        }
      })
    }else{
      this.collectionString ="收藏"
    }
  }

  fetchCollection(operation) {
    const params = {
      itemId: this.$props.orgId,
      itemType: 238,
    };
    return new Promise((resolve, reject) => {
      fetchCollection({...params, operation: operation}).then((res) => {
        resolve(res);
      });
    });
  }

  async collection() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      let operation = 0;
      if (this.collectionString === "已收藏") {
        operation = 1;
      } else {
        operation = 0;
      }
      await this.fetchCollection(operation);
      await this.isCollectionsOrgService();
    } else {
      this.$router.push("/login?collectionOrgid=" + this.$props.orgId);
    }
  }

  /**
   * 点击箭头选择前一张图片
   */
  handlePreImg() {
    if (this.currentImageIndex > 0) {
      if (this.currentImageIndex % 4 == 0) {
        const el = document.getElementById("imgsInnerBox");
        (el as any).style.marginLeft =
            -(this.currentImageIndex - 4) * 1.53 + "rem";
      }
      this.currentImageIndex -= 1;
    }
  }

  /**
   * 选中图片
   * @param index
   */
  handleSelectImg(index) {
    console.log(index, '选中index')
    const that = this
    const orgImages = that.$props.orgImages
    if (index < orgImages.length) {
      that.currentImageIndex = index
      that.currImg = orgImages[index]['orgImage']
    }
  }

  /**
   * 点击箭头选择后一张图片
   */
  handleNextImg() {
    if (this.currentImageIndex < this.$props.orgImages.length - 1) {
      this.currentImageIndex += 1;
      if (this.currentImageIndex % 4 == 0) {
        const el = document.getElementById("imgsInnerBox");
        (el as any).style.marginLeft = -this.currentImageIndex * 1.53 + "rem";
      }
    }
  }
}
















































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {
  Steps,
  Table,
  Form,
  Radio,
  Input,
  Select,
  Button
} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceProcessSteps from "./classServicesteps.vue";
Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);

@Component({
  components: {
    PersonalHeader,
    ServiceProcessSteps
  }
})
export default class extends Vue {
  status = 0;
  plainOptions = ["已婚", "未婚"];
  value1 = "未婚";
  stepList = [
    {
      name: "预约体检日期"
    },
    {
      name: "到达体检中心体检"
    },
    {
      name: "获取体检报告"
    }
  ];
  leftForm: any;
  rightForm: any;
  marrige = [
    { label: "已婚", value: "1" },
    { label: "未婚", value: "2" }
  ];

  pagination = {
    total: 3,
    showTotal: (total: number) => `共${total}条记录`,
    itemRender: (current: any, type: string, originalElement: any): any => {
      if (type === "prev") {
        return "< 上一页";
      } else if (type === "next") {
        return "下一页 >";
      }
      return originalElement;
    }
  };

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 }
    }
  };

  beforeMounted() {
    this.leftForm = this.$form.createForm(this);
    this.rightForm = this.$form.createForm(this);
  }

  handleTypeChange() {}
  handleLeftSubmit() {}
  handleRightSubmit() {}
}




























    /* eslint-disable */
    import {Component, Emit, Prop, Vue} from "vue-property-decorator";
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import {quillEditor} from "vue-quill-editor"
    import {uploadServiceFile} from "@/services/api";
    import {message} from 'ant-design-vue';
    //加入以下引用
    import Quill from "quill";
    import ImageResize from "quill-image-resize-module"; // 引用
    Quill.register("modules/imageResize", ImageResize); // 注册
    // 拖拽不能用……
    // import { ImageDrop } from "quill-image-drop-module";
    // Quill.register("modules/imageDrop", ImageDrop);

    @Component({
        components: {
            quillEditor
        }
    })
    export default class extends Vue {

        @Prop() private initPlaceholder: string;

        @Emit()
        contentCallback(contentValue: string) {
        };

        // 屏幕高度系数
        heightCoefficient: number = window.innerHeight / 1080;
        // 1.2: 4.45
        // 1: 3.54
        // 0.83333333: 2.5
        // 0.66666667: 1.66
        // F(x) = (2.01817) * Math.pow(x,0) + (-3.38153) *  Math.pow(x,1) + (0.954726) * Math.pow(x,2) + (4.64712) * Math.pow(x,3) +(2.83879) * Math.pow(x,4) + (-3.53728) * Math.pow(x,5)
        editorHeightFun = (2.01817) * Math.pow(this.heightCoefficient, 0)
            + (-3.38153) * Math.pow(this.heightCoefficient, 1)
            + (0.954726) * Math.pow(this.heightCoefficient, 2)
            + (4.64712) * Math.pow(this.heightCoefficient, 3)
            + (2.83879) * Math.pow(this.heightCoefficient, 4)
            + (-3.53728) * Math.pow(this.heightCoefficient, 5)
        // 唯一id
        uniqueId = "uniqueFileInputId";
        // 富文本编辑器默认内容
        content = '';
        // 富文本编辑器配置
        editorOption = {
            // 富文本编辑器配置
            modules: {
                // 工具栏定义的
                toolbar: [
                    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
                    ["blockquote"], // 引用  代码块-----['blockquote', 'code-block']
                    // [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
                    [{list: "ordered"}, {list: "bullet"}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
                    [{script: "sub"}, {script: "super"}], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
                    [{indent: "-1"}, {indent: "+1"}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
                    // [{direction: "rtl"}], // 文本方向-----[{'direction': 'rtl'}]
                    [{size: ["small", false, "large", "huge"]}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
                    [{header: [1, 2, 3, 4, 5, 6, false]}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
                    // [{font: []}], // 字体种类-----[{ font: [] }]
                    ["image"], // 链接、图片、视频-----['link', 'image', 'video']
                    [{align: []}], // 对齐方式-----[{ align: [] }]
                    // ["clean"], // 清除文本格式-----['clean']
                    [{color: []}, {background: []}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
                ],
                // 图片拖拽
                // imageDrop: true,
                // 图片缩放
                imageResize: {
                    displayStyles: {
                        backgroundColor: "black",
                        border: "none",
                        color: "white"
                    },
                    modules: ["Resize", "DisplaySize", "Toolbar"]
                },
            },
            // 主题
            theme: "snow",
            placeholder: this.initPlaceholder || '请输入正文'
        };

        get editor() {
            let quill = (this.$refs["content-quill-editor"] as any)['quill'];
            return quill;
        }

        mounted() {
            let that = this;
            let imgHandler = async function (image) {
                if (image) {
                    let fileInput = document.getElementById(that.uniqueId); // 隐藏的file文本ID
                    if (fileInput)
                        fileInput.click(); // 加一个触发事件
                }
            };
            that.editor.getModule("toolbar").addHandler("image", imgHandler);


            //自定义粘贴图片功能
            let quill2 = (this.$refs["content-quill-editor"] as any)['quill'];
            this.$forceUpdate();
            quill2.root.addEventListener(
                "paste",
                (evt) => {
                    if (
                        evt.clipboardData &&
                        evt.clipboardData.files &&
                        evt.clipboardData.files.length
                    ) {
                        evt.preventDefault();
                        [].forEach.call(evt.clipboardData.files, (file: any) => {
                            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                                return;
                            }
                            const formData = new FormData();
                            formData.append("file", file);
                            formData.append("typeId", '8'); // 详细信息里面的图片文件
                            uploadServiceFile(formData, _ => {
                            }).then((data) => {
                                let quill = (this.$refs["content-quill-editor"] as any)['quill'];
                                if (data && data.code == 0) {

                                    let Range = that.editor.getSelection();
                                    let url = data.fullPath;
                                    url = url.indexOf("http") != -1 ? url : "http:" + url;
                                    // 上传文件成功之后在富文本中回显(显示)
                                    that.editor.insertEmbed(
                                        Range != null ? quill.getSelection().index : 0,
                                        "image",
                                        url
                                    );
                                    // 调整光标到最后
                                    quill.setSelection(quill.getSelection().index + 1); //光标后移一位
                                }
                            })
                        });
                    }
                },
                false
            );
        }

        /**
         * 初始化
         */
        init(content) {
            this.content = content || ''
        }

        /**
         * <a href="https://blog.csdn.net/liuqiao0327/article/details/107126784/"></a>
         * @param quill
         */
        //失去焦点事件
        onEditorBlur(quill) {
            this.contentCallback(this.content)
        }

        //获得焦点事件
        onEditorFocus(quill) {
        }

        // 准备富文本编辑器
        onEditorReady(quill) {
        }

        //内容改变事件
        onEditorChange({quill, html, text}) {
            this.content = html
            this.contentCallback(this.content)
        }

// 自定义上传文件
        async uploadImg() {
            const that = this
            let formData = new FormData();
            let uniqueFileInput = document.getElementById(that.uniqueId)
            let file: any;
            if (uniqueFileInput && (file = uniqueFileInput['files'][0])) {
                formData.append("file", file);
                formData.append("typeId", '8'); // 详细信息里面的图片文件
                let p = {
                    percent: 0,
                };
                uploadServiceFile(formData, _ => {
                }).then((data) => {
                    if (data && data.code == 0) {
                        //返回上传文件的地址
                        let Range = that.editor.getSelection();
                        let url = data.fullPath;
                        url = url.indexOf("http") != -1 ? url : "http:" + url;
                        // 上传文件成功之后在富文本中回显(显示)
                        that.editor.insertEmbed(
                            Range != null ? Range.index : 0,
                            "image",
                            url
                        );
                    } else {
                        message.error(data.msg);
                    }
                }).catch(e => message.error("图片上传失败"))
                    .then(_ => {
                        if (uniqueFileInput) uniqueFileInput['value'] = ""
                    });
            }

        }
    }


















































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import DetailInfo from "@/components/Personal/myGoods/DetailInfo.vue";
import DetailPriceCard from "@/components/Personal/DetailPriceCard.vue";
import {Mutation} from "vuex-class";
import {dictionariesWithDesc, physicalDistribution, TheOrderDetails} from "@/services/api";
import {baseUrl} from "../../../../services/http";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";

Vue.use(Table);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        MyStepBar,
        DetailInfo,
        DetailPriceCard,
        Breadcrumb,
    }
})
export default class extends Vue {
    baseUrl = baseUrl
    // 5是取消 6 交易关闭
    currentStatus: any = ''; // 根据状态动态调整currentStatus的值
    status: any = ''; //商品订单状态
    ifEvaluation: boolean = false; //商品订单是否评论
    @Mutation SET_CURDETAILSTATUS;
    orderInfoId = "";
    statusList: any = [];
    commodityData = [];
    tableData: Array<any> = [];
    logisticsList = [];
    companyList: Array<any> = [];
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的商品",
            path: "/personal/MyGoods",
            significance: 0
        },
        {
            name: "订单详情",
            path: "/personal/serviceDetail",
            significance: 1
        },
    ]

    mounted() {
        const str1: any = localStorage.getItem("CURDETAILSTATUS");
        this.orderInfoId = JSON.parse(str1)

        //获取物流数据
        dictionariesWithDesc(176).then(res => {
            res.values.forEach(item => {
                this.companyList[item.key] = item.value;

            })

        }).then(_ => {
            this.lookDetail();
        })
    }

    lookDetail() {
        TheOrderDetails({orderInfoId: this.orderInfoId}).then(res => {
            if (res) {
                let data: any = (res as any).data || {};
                this.commodityData = data
                this.status = data.status;
                this.ifEvaluation = data.ifEvaluation;
                this.statusList = data.statusList;
                data.commodityInfos.forEach((item: any, index: any) => {
                    let obj: any = {};
                    obj.service = {
                        commodityName: item.commodityName,
                        commodityPic: item.commodityPic
                    };
                    obj.commoditySuggestPrice = item.commoditySuggestPrice;
                    obj.aggregate  =  obj.commoditySuggestPrice*item.purchaseQuantity;
                    obj.purchaseQuantity = item.purchaseQuantity;
                    obj.orderStatus = item.orderStatus;
                    this.tableData.push(obj);
                });


                this.changeStatus();
                if (data.status === 263 || data.status == 265) {
                    this.materialFlow()
                }
            }
        });
    }

    //已发货和已完成状态下获取物流信息
    materialFlow() {
        physicalDistribution({orderInfoId: this.orderInfoId}).then(res => {
            if (res) {
                let data: any = (res as any).data || {};
                this.logisticsList = data;
                this.changeStatus();
            }
        });
    }

    //判断顶部状态和显示时间
    changeStatus() {
        switch (this.status) {
            case 261: //代付款
                this.currentStatus = 1;
                this.stepData[0].time = this.statusList[0].time
                break;
            case 262: //待发货
                this.currentStatus = 2;
                this.stepData[0].time = this.statusList[0].time
                this.stepData[1].time = this.statusList[1].time;
                break;
            case 263: //已发货
                this.currentStatus = 3;
                this.stepData[0].time = this.statusList[0].time
                this.stepData[1].time = this.statusList[1].time;
                this.stepData[2].time = this.statusList[2].time;
                break;
            case 265:   //已完成
                this.currentStatus = 4;
                this.stepData[0].time = this.statusList[0].time
                this.stepData[1].time = this.statusList[1].time;
                this.stepData[2].time = this.statusList[2].time;
                this.stepData[3].time = this.statusList[3].time;
                this.stepData[4].time = this.statusList[4].time;
                // 处理评论
                if (this.ifEvaluation && this.statusList.length > 4) {
                    this.currentStatus = 5;
                  this.stepData[4].time = this.statusList[4].time;
                }
                break;
            case 266: //已取消
                this.currentStatus = 6;
                break;
            case 267: //退货中
                break;
            case 268: //已关闭
                this.currentStatus = 7;
                break;
        }



    }


    stepData = [
        {
            name: "拍下商品",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "买家付款",
            time: "",
            img: require('@/assets/images/homeImg/flow-2.png'),
        },
        {
            name: "卖家发货",
            time: "",
            img: require('@/assets/images/homeImg/flow-3.png'),
        },
        {
            name: "确认收货",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        },
        {
            name: "评价",
            title: "",
            img: require('@/assets/images/homeImg/flow-5.png'),
        }
    ];

    columns = [
        {
            title: "商品",
            dataIndex: "service",
            key: "service",
            align: "center",
            width: "3.3rem",
            scopedSlots: {customRender: "service"}
        },
        {
            title: "单价",
            dataIndex: "commoditySuggestPrice",
            key: "commoditySuggestPrice",
            align: "center"
        },
        {
            title: "数量",
            dataIndex: "purchaseQuantity",
            key: "purchaseQuantity",
            align: "center"
        },
        {
            title: "优惠金额",
            align: "center",
            width: "1.9rem",
            scopedSlots: {customRender: "discounts"}
        },
        {
            title: "小计",
            dataIndex: "aggregate",
            key: "aggregate",
            align: "center"
        },
        {
            title: "操作",
            dataIndex: "orderStatus",
            align: "center",
            customRender: (text) => {
                switch (text) {
                    case 261:
                        return '待付款'
                    case 262:
                        return '待发货'
                    case 263:
                        return '已发货'
                    case 265:
                        return '已完成'
                    case 266:
                        return '已取消'
                    case 267:
                        return '退货中'
                    case 268:
                        return '已关闭'
                }
            }
        }
    ];
}

<template>
  <div>
    <Header />
    <Nav />
    <div class="serviceJoinStep3">
      <div style="width: 10rem; margin: 0 auto; margintop: -0.4rem">
        <ReturnStep :curStep="3" :stepList="stepList" />
      </div>
      <div class="form-box">
        <a-form :form="form">
          <a-form-item v-bind="formItemLayout" label="服务商账号">
            <a-input
              style="width: 4.2rem"
              placeholder="服务商账号不能为空"
              v-decorator="[
                'orgAccount',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkExist.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgAccount,
                },
              ]"
            />
            <p
              style="
                color: #a9a9a9;
                font-size: 0.15rem;
                position: absolute;
                left: 4.4rem;
                width: 7rem;
                top: -0.15rem;
              "
            >此帐号为日后登录并管理服务商管理中心时使用，注册后不可修改，请牢记。默认密码123456</p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="服务商名称">
            <a-input
              style="width: 4.2rem"
              placeholder="请输入服务商名称"
              v-decorator="[
                'orgName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkExistName.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgName,
                },
              ]"
            />
            <p
              style="
                color: #a9a9a9;
                font-size: 0.15rem;
                position: absolute;
                left: 4.4rem;
                width: 7rem;
                top: -0.15rem;
              "
            >服务商名称注册后不可修改，请认真填写</p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="服务商养老模式">
            <a-select
              style="width: 4.2rem; font-size: 0.16rem"
              mode="multiple"
              placeholder="请选择服务商养老模式"
              v-decorator="[
                'orgForm',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商养老模式不能为空',
                    },
                  ],
                  initialValue: initialValue.orgForm,
                },
              ]"
            >
              <a-select-option
                style="font-size: 0.16rem"
                v-for="item in serviceType"
                :value="item.key"
                :key="item.key"
              >{{ item.value }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="服务商类别">
            <a-select
              style="width: 3rem; font-size: 0.16rem"
              cleanable
              placeholder="请选择服务商类别"
              @change="ndewichange"
              v-decorator="[
                'ndewi',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商类别不能为空',
                    },
                  ],
                  initialValue: initialValue.ndewi,
                },
              ]"
            >
              <a-select-option
                v-for="item in orgType"
                :value="item.key"
                :key="item.key"
              >{{ item.value }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="主体性质">
            <a-radio-group
              v-decorator="[
                'fnre',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择服务商主体性质',
                    },
                  ],
                  initialValue: initialValue.fnre,
                },
              ]"
            >
              <a-radio v-for="item in fnreType" :value="item.key" :key="item.key">{{ item.value }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="收住对象">
            <a-checkbox-group
              :options="plainOptions"
              v-decorator="[
                'refcev',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择服务商收住对象',
                    },
                  ],
                  initialValue: initialValue.refcev,
                },
              ]"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="服务商所在地">
            <a-cascader
              :options="options"
              :loadData="loadData"
              style="width: 4.2rem; font-size: 0.16rem"
              :placeholder="addrPlaceHolder"
              @change="selectAddress"
              v-decorator="[
                'freoij',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.addrcheck.bind(this),
                    },
                  ],
                  // initialValue: formData.expansionInfo.orgIntro
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="服务商详细地址">
            <a-input
              style="width: 4.2rem"
              placeholder="请输入服务商详细地址"
              v-decorator="[
                'addressDetail',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商详细地址不能为空',
                    },
                  ],
                  initialValue: formData.shopInfo.addressDetail,
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="床位数">
            <a-input-number
              type="number"
              style="width: 3rem; font-size: 0.16rem"
              min="0"
              placeholder="请输入服务商床位数"
              v-decorator="[
                'bedNum',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商床位数不能为空',
                    },
                  ],
                  initialValue: formData.shopInfo.bedNum,
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="最低价格（元）">
            <a-input
              style="width: 3rem; font-size: 0.16rem"
              placeholder="请输入服务商最低价格"
              addon-before="￥"
              v-decorator="[
                'lowestPrice',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.formater.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.lowestPrice,
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="最高价格（元）">
            <a-input
              style="width: 3rem; font-size: 0.16rem"
              addon-before="￥"
              placeholder="请输入服务商最高价格"
              v-decorator="[
                'highestPrice',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.maxThanMin.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.highestPrice,
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="服务商电话">
            <a-input
              style="width: 4.2rem; font-size: 0.16rem"
              placeholder="请输入服务商电话,如010-12345678,13812345678"
              v-decorator="[
                'orgTel',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.phoneCheck.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgTel,
                },
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="营业时间">
            <a-radio-group
              @change="onBusinessHoursChange"
              v-decorator="[
                'businessHours',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择服务商营业时间！',
                    },
                    {
                      validator: this.checkBusinessTime.bind(this),
                    },
                  ],
                  initialValue: needSetBusinessTime,
                },
              ]"
            >
              <a-radio :value="false">全天</a-radio>
              <a-radio :value="true">自定义</a-radio>
              <div v-show="needSetBusinessTime" style="margin-top: 0.1rem">
                <a-time-picker
                  :default-open-value="moment('00:00', 'HH:mm')"
                  placeholder="开始时间"
                  format="HH:mm"
                  v-decorator="[
                    'opentime',
                    {
                      initialValue: formData.shopInfo.openTime
                        ? moment(formData.shopInfo.openTime, 'HH:mm:ss')
                        : moment('00:00:00', 'HH:mm:ss'),
                    },
                  ]"
                />-
                <a-time-picker
                  :default-open-value="moment('00:00', 'HH:mm')"
                  placeholder="结束时间"
                  format="HH:mm"
                  v-decorator="[
                    'closetime',
                    {
                      initialValue: formData.shopInfo.closeTime
                        ? moment(formData.shopInfo.closeTime, 'HH:mm:ss')
                        : moment('00:00:00', 'HH:mm:ss'),
                    },
                  ]"
                />
              </div>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="服务类目">
            <div v-for="item in list" :key="item">
              <!-- @change="(value) => onChange(value, `refr${item}`)" -->

              <!-- message: '服务类目不能为空', -->
              <a-cascader
                :options="serviceTypes"
                style="width: 4.2rem; font-size: 0.16rem"
                placeholder="请选择服务类目"
                v-decorator="[
                  `refr${item}`,
                  {
                    rules: [
                      {
                        required: true,
                        validator: onChange,
                      },
                    ],
                    // initialValue: formData.expansionInfo.orgIntro
                  },
                ]"
              />
              <a-icon
                v-if="item > 1"
                class="dynamic-delete-button"
                style="marginleft: 5px"
                type="minus-circle-o"
                @click="() => remove(item)"
              />
            </div>
            <span v-if="showRepeat" style="color: #f00">
              所选服务类目已重复，请重新选择
              <br />
            </span>
            <span style="color: #60c7c6; fontsize: 0.14rem; cursor: pointer" @click="add">
              <a-icon type="plus-circle" style="marginright: 5px" />新增服务类目
            </span>
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="服务商特色" id="djaksdniodljnak">
            <a-checkbox-group
              v-decorator="[
                'fwts',
                {
                  rules: [],
                  initialValue: initialValue.fwts,
                },
              ]"
            >
              <div v-for="item in serviceList" :key="item.key">
                <!-- <a-col :span="6"> -->
                <a-checkbox :value="item.key">
                  <div
                    class="tdajosnBox"
                    :style="{
                      borderColor: tdajosnBoxClick(item.key) ? '#60c7c6' : '',
                    }"
                  >
                    <h2>{{ item.value }}</h2>
                    <div>{{ item.desc }}</div>
                  </div>
                </a-checkbox>
                <!-- </a-col> -->
                <!-- <a-col :span="18">{{item.desc}}</a-col> -->
              </div>
            </a-checkbox-group>
          </a-form-item>

          <a-form-item v-bind="tailFormItemLayout" style="margin: 1rem 0">
            <a-button
              type="primary"
              style="
                width: 2rem;
                height: 0.44rem;
                marginright: 1rem;
                background: #fff;
                color: #60c7c6;
              "
              @click="back"
            >上一步</a-button>
            <a-button
              v-if="buttonSet.enableClick"
              :loading="buttonSet.loading"
              type="primary"
              style="width: 2rem; height: 0.44rem"
              @click="handleSubmit"
            >提交审核</a-button>
            <a-button
              v-else
              :loading="buttonSet.loading"
              type="primary"
              disabled
              style="width: 2rem; height: 0.44rem"
            >提交审核</a-button>
            <a-modal
              :footer="null"
              centered
              v-model="visibleConfirm"
              :closable="false"
              @ok="handleClickConfirm"
              :afterClose="handleClickConfirm"
            >
              <Confirm />
              <div style="text-align: center">
                <a-button type="primary" style="width: 50%" @click="handleClickConfirm">确认</a-button>
              </div>
            </a-modal>

            <!--            <a-button-->
            <!--              type="primary"-->
            <!--              style="width: 2rem;height:0.44rem;marginRight:1rem; background: #fff;color: #60c7c6"-->
            <!--              @click="$router.back()"-->
            <!--            >-->
            <!--              上一步-->
            <!--            </a-button>-->
            <!--            <a-button-->
            <!--              type="primary"-->
            <!--              style="width: 2rem;height:0.44rem;"-->
            <!--              @click="handleSubmit"-->
            <!--            >-->
            <!--              下一步-->
            <!--            </a-button>-->
          </a-form-item>
        </a-form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import moment from "moment";
    import {
        Button,
        Cascader,
        Checkbox,
        DatePicker,
        Form,
        Icon,
        Input,
        message,
        Modal,
        Radio,
        Select,
        Upload,
    } from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import {
        checkAccountNameExist,
        checkUserNameExist,
        dictionaries,
        dictionariesWithDesc,
        fetchAddrCascader,
        fetchOrganizationsAdd,
        fetchProvinceName,
        fetchServiceTypes,
    } from "@/services/api";

    import Confirm from "./ServiceJoinStep4";

    Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(DatePicker);
Vue.use(Cascader);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Modal);

export default {
  components: {
    Nav,
    Footer,
    Header,
    ReturnStep,
    Confirm,
  },
  data() {
    return {
      showRepeat: false,
      imageUrl: "",
      formItemLayout: {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 9,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 15,
          },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 15,
            offset: 9,
          },
        },
      },
      stepList: [
        {
          name: "入驻协议",
        },
        {
          name: "基本信息",
        },
        {
          name: "服务商信息",
        },
        {
          name: "扩展信息",
        },
      ],
      list: [1],
      serviceType: [], // 服务形式
      orgType: [], // 机构类别
      fnreType: [], // 主体性质
      plainOptions: [], // 机构收住对象
      options: [], // 地址选择器
      serviceTypes: [], // 服务商类目
      serviceTypesPathMap: [], // 服务商类目
      serviceList: [],
      formData: {
        organizationInfo: {
          companyName: "",
          orgCode: "",
          companyProvince: "",
          companyCity: "",
          companyArea: "",
          companyStreet: "",
          companyAddressDetail: "",
          companyContactNumber: "",
        },
        accountInfo: {
          lpName: "",
          lpIdNum: "",
          lpTel: "",
          accountName: "",
          accountNumber: "",
          bankName: "",
          accountProvince: "",
          accountCity: "",
          accountArea: "",
          accountStreet: "",
          accountDetailAdd: "",
          lpIdPicturePositive: "",
          lpIdPictureNegative: "",
          businessLicensePicture: "",
          emergencyContactName: "",
          emergencyContactPhone: "",
        },
        administratorInfo: {
          adminName: "",
          adminIdNum: "",
          adminTel: "",
        },
        expansionInfo: {
          coverImage: "",
          createTime: "",
          briefIntro: "",
          orgRemark: "",
          areaSize: 0,
          orgIntro: "",
        },
        shopInfo: {
          orgAccount: JSON.parse(localStorage.getItem("userInfo"))[
            "userName"
          ],
          orgName: "",
          orgForm: [],
          servicesCategoryList: [],
          orgType: "",
          orgCharacter: "",
          serviceOdject: "",
          addressProvince: "",
          addressCity: "",
          addressArea: "",
          addressStreet: "",
          addressDetail: "",
          bedNum: "",
          lowestPrice: "",
          highestPrice: "",
          orgTel: "",
          openTime: "",
          closeTime: "",
        },
        label: {
          specialService: [],
        },
        paymentInfo: {
          // 后端存储，前端不存储
          alipayId: "",
          alipayNickname: "",
          alipayRealName: "",
          wechatId: "",
        },
        uploads: {
          // 上传图片由于某些数据的原因需要暂存
          businessLicensePictureUrl: "", // 营业执照暂存
          lpIdPicturePositiveUrl: "", // 身份证正面照片暂存
          lpIdPictureNegativeUrl: "", // 身份证反面照片暂存
          coverImage: "", // 封面照暂存
        },
      },
      needSetBusinessTime: false,

      buttonSet: {
        loading: false,
        enableClick: false,
      },

      addrPlaceHolder: "请选择服务商所在地",
      initialValue: {
        // 需要进行初始化处理的数据
        orgForm: [], // 养老形式
        ndewi: [], // 服务商类别
        fnre: "", // 主体性质
        refcev: [], // 收住对象
        serviceTypes: [], // 服务商类目
        fwts: [], // 服务特色
      },

      visibleConfirm: false,
    };
  },
  beforeCreate() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.form = this.$form.createForm(this);
    } else {
      this.$router.push("/login");
    }
  },
  mounted() {
    let initRefr = {};
    this.visibleConfirm = false;
    dictionaries([65, 67, 69, 74]).then((res) => {
        if (res && res.code == 0) {
          this.serviceType = res.values[74];
          this.orgType = res.values[65];
          this.fnreType = res.values[67];
          this.plainOptions = res.values[69].map((i) => {
            return {
              label: i.value,
              value: i.key.toString(),
            };
          });
        } else {
          message.error("获取机构类别失败");
        }
      }).then(() => {
        dictionariesWithDesc(70).then((res) => {
          if (res && res.code == 0) {
            this.serviceList = res.values;
          } else {
            message.error(res.msg);
          }
        });
      }).then(() => {
        // 获取服务类目
        fetchServiceTypes().then((res) => {
            if (res && res.code == 0) {
              this.serviceTypes = res.data.list;
              this.serviceTypesPathMap = res.data.pathMap;
            } else {
              message.error(res.msg);
            }
          })
          .then((_) => {
            // 返回上一步的时候数据给他填回去
            let item = localStorage.getItem("serviceJoinForm");
            if (item) {
              let data = JSON.parse(item);

              this.formData = data;

              // 养老形式
              if (this.formData.shopInfo.orgForm) {
                if (typeof data.shopInfo.orgForm == "string") {
                  this.formData.shopInfo.orgForm = data.shopInfo.orgForm.split(
                    ","
                  );
                  this.formData.shopInfo.orgForm.forEach((i) =>
                    this.initialValue.orgForm.push(parseInt(i))
                  );
                } else {
                  this.formData.shopInfo.orgForm = data.shopInfo.orgForm;
                  this.formData.shopInfo.orgForm.forEach((i) =>
                    this.initialValue.orgForm.push(parseInt(i))
                  );
                }
              }

              // 服务商类别
              if (this.formData.shopInfo.orgType) {
                let orgInt;
                if ((orgInt = parseInt(this.formData.shopInfo.orgType)))
                  this.initialValue.ndewi = parseInt(
                    this.formData.shopInfo.orgType
                  );
              }

              // 主体性质
              if (this.formData.shopInfo.orgCharacter) {
                this.initialValue.fnre = parseInt(
                  this.formData.shopInfo.orgCharacter
                );
              }

              // 收住对象
              if (this.formData.shopInfo.serviceOdject) {
                if (typeof data.shopInfo.serviceOdject == "string") {
                  this.formData.shopInfo.serviceOdject = data.shopInfo.serviceOdject.split(
                    ","
                  );
                  this.initialValue.refcev = this.formData.shopInfo.serviceOdject;
                } else {
                  this.formData.shopInfo.serviceOdject =
                    data.shopInfo.serviceOdject;
                  this.initialValue.refcev = this.formData.shopInfo.serviceOdject;
                }
              }

              // 服务商特色
              if (this.formData.label.specialService) {
                if (typeof data.label.specialService == "string") {
                  this.formData.label.specialService = data.label.specialService.split(
                    ","
                  );
                  this.formData.label.specialService.forEach((i) =>
                    this.initialValue.fwts.push(parseInt(i))
                  );
                } else {
                  this.formData.label.specialService =
                    data.label.specialService;
                  this.formData.label.specialService.forEach((i) =>
                    this.initialValue.fwts.push(parseInt(i))
                  );
                }
              }

              // 服务商类目
              if (this.formData.shopInfo.servicesCategoryList) {
                let index = 1;
                this.formData.shopInfo.servicesCategoryList.forEach((i) => {
                  if (this.serviceTypesPathMap[i]) {
                    // 存在一些老旧数据，比如入驻申请之前能显示，但是再次申请的时候就不显示了
                    let pathMap = this.serviceTypesPathMap[i].split(",");
                    this.initialValue.serviceTypes.push(pathMap);
                    initRefr[`refr${index}`] = pathMap;
                    if (index > 1) this.add();
                    index++;
                  }
                });
              }

              // 时间
              if (
                this.formData.shopInfo.openTime &&
                this.formData.shopInfo.closeTime
              ) {
                if (
                  this.formData.shopInfo.openTime == "00:00:00" &&
                  this.formData.shopInfo.closeTime == "00:00:00"
                ) {
                  this.needSetBusinessTime = false;
                } else {
                  this.needSetBusinessTime = true;
                }
                initRefr["businessHours"] = this.needSetBusinessTime;
              }
            } else {
              // message.error("前置信息获取失败，请重新填写");
              // this.$router.back();
            }

            this.init();
            this.buttonSet.loading = false;
            this.buttonSet.enableClick = true;
          })
          .then((_) => {
            if (this.formData.shopInfo.addressArea) {
              // 请求公司地址
              fetchProvinceName({
                province: this.formData.shopInfo.addressProvince,
                city: this.formData.shopInfo.addressCity,
                area: this.formData.shopInfo.addressArea,
                street: this.formData.shopInfo.addressStreet,
              }).then((res) => {
                if (res.code == 0) {
                  const add = res.info.addr;
                  this.addrPlaceHolder = `${add.province}/${add.city}/${
                    add.area
                  }${add.street ? `/${add.street}` : ""}`;
                } else {
                  message.error(res.msg);
                }
              });
            }
          })
          .then((_) => {
            this.form.setFieldsValue(initRefr);
          });
      });
  },
  methods: {
    phoneCheck(rule, value, callback) {
      if (!value) {
        callback("服务商电话不能为空");
      }
      const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
      const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!reg.test(value) && !reg2.test(value)) {
        callback(
          "格式不正确，请输入正确格式的公司电话,如：021-87888822,13812345678"
        );
      } else {
        callback();
      }
    },
    back() {
      this.saveJoinForm();
      this.$router.back();
    },
    moment,
    getFormValue(name) {
      return this.form.getFieldValue(name);
    },
    // 类目中的重复问题
    onChange(rule, value, callback) {
      const key = rule.field;
      let flg = false;
      this.list.forEach((i) => {
        let name = `refr${i}`;
        if (name != key) {
          let services = this.getFormValue(name);
          if (value[value.length - 1] == services[services.length - 1]) {
            this.form.setFields({
              [key]: { errors: [new Error("此类目已选")] },
            });
            // callback();
            this.showRepeat = true;
            flg = true;
            callback("此类目已选");
            return;
          }
        }
      });

      if (!flg) {
        this.showRepeat = false;
        callback();
      }
    },
    // 添加类目
    add() {
      this.list.push(this.list[this.list.length - 1] + 1);
    },
    // 移除类目
    remove(k) {
      const index = this.list.findIndex((item) => item === k);
      this.list.splice(index, 1);
    },
    tdajosnBoxClick(id) {
      const fwts = this.getFormValue("fwts");
      return fwts.includes(id);
    },
    // 提交
    handleSubmit(e) {
      // e.preventDefault();
      if (this.buttonSet.enableClick) {
        this.buttonSet.loading = true;
        this.buttonSet.enableClick = false;
        this.form.validateFieldsAndScroll(
          null,
          {
            first: false,
            scroll: {
              offsetTop: 120,
            },
          },
          (err, values) => {
            if (!err) {
              this.saveJoinForm();
              fetchOrganizationsAdd(this.formData).then((res) => {
                this.buttonSet.loading = false;
                this.buttonSet.enableClick = true;

                if (res && res.code == 0) {
                  message.success("提交成功，等待审核");

                  this.buttonSet.loading = false;
                  this.buttonSet.enableClick = true;

                  this.visibleConfirm = true;
                } else {
                  this.buttonSet.loading = false;
                  this.buttonSet.enableClick = true;
                  message.error(res.msg);
                }
              });

              // this.$router.push("/serviceJoinStep4");
            } else {
              this.buttonSet.loading = false;
              this.buttonSet.enableClick = true;
            }
          }
        );
      }
    },
    // 获取地址信息
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      const { value } = targetOption;
      const { level } = targetOption;
      targetOption.loading = true;
      fetchAddrCascader({
        areaType: level,
        areaId: value,
      }).then((data) => {
        targetOption.loading = false;
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach((i) => {
              children.push({
                label: i.name,
                value: i.id,
                isLeaf: i.childNum == 0,
                level: level + 1,
              });
            });
          }
          targetOption.loading = false;
          targetOption.children = children;
          this.options = [...this.options];
        } else {
          message.error(data.msg);
        }
      });
    },
    // 初始化地址
    init(place) {
      fetchAddrCascader({
        areaType: 0,
        areaId: "",
      }).then((data) => {
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach((i) => {
              children.push({
                label: i.name,
                value: i.id,
                level: 1,
                isLeaf: false,
              });
            });
          }
          this.options = children;
        } else {
          message.error(data.msg);
        }
      });
    },
    // 校验最高价格比最低价格要多
    maxThanMin(rule, value, callback) {
      if (!value) {
        callback("服务商最高价格不能为空");
        return;
      }
      if (+value <= 0) {
        callback("金额格式不正确,价格不能为0和负数");
        return;
      }
      let lowestPrice = parseInt(this.getFormValue("lowestPrice"));
      if (lowestPrice >= 0) {
        if (parseInt(value) < lowestPrice) {
          callback("最高价格不能比最低价格低");
          return;
        }
      }

      this.form.setFieldsValue({ highestPrice: (+value).toFixed(2) });
      callback();
    },
    formater(rule, value, callback) {
      if (!value) {
        callback("服务商最低价格不能为空");
        return;
      }
      if (+value <= 0) {
        callback("金额格式不正确,价格不能为0和负数");
        return;
      }
      this.form.setFieldsValue({ lowestPrice: (+value).toFixed(2) });

      callback();
    },
    // 检查商家账号是否已存在
    checkExist(rule, value, callback) {
      // 校验服务商的名称是否被占用
      if (value) {
        // 不能全是数字或者以数字开头
        if (/^[0-9]*$/.test(value)) {
          callback(new Error("用户名不能为纯数字，请重新输入"));
          return;
        }

        checkUserNameExist(value)
          .then((res) => {
            if (res && res.code == 0) {
              callback();
            } else {
              callback(res.msg);
            }
          })
          .catch((e) => {
            callback(e);
          });
      } else {
        callback(new Error("请填写服务商账号！"));
      }
    },
    // 检查商家名称是否已存在
    checkExistName(rule, value, callback) {
      // 校验服务商的名称是否被占用
      if (value) {
        checkAccountNameExist(value)
          .then((res) => {
            if (res && res.code == 0) {
              callback();
            } else {
              callback(res.msg);
            }
          })
          .catch((e) => {
            callback(e);
          });
      } else {
        callback(new Error("服务商名称不能为空"));
      }
    },
    // 校验营业时间
    checkBusinessTime(rule, value, callback) {
      const that = this;
      if (value) {
        // 是否是自定义
        let openTime = that.getFormValue("opentime");
        let closeTime = that.getFormValue("closetime");
        if (!openTime) {
          callback(new Error("请设置开始营业时间"));
        }
        if (!closeTime) {
          callback(new Error("请设置营业结束时间"));
        }
      }
      callback();
    },
    // 选择经营时间
    onBusinessHoursChange(e) {
      this.needSetBusinessTime = e.target.value;
    },
    /**
     *  选择服务商地址
     * @param value
     * @param selectedOptions
     */
    selectAddress(value, selectedOptions) {
      if (value.length >= 3 && value.length <= 4) {
        this.formData.shopInfo.addressProvince = value[0];
        this.formData.shopInfo.addressCity = value[1];
        this.formData.shopInfo.addressArea = value[2];
        this.formData.shopInfo.addressStreet = value[3] || "";
      }
    },
    /**
     * 校验服务商地址
     * @param rule
     * @param value
     * @param callback
     */
    addrcheck(rule, value, callback) {
      if (!this.formData.shopInfo.addressArea) {
        callback(new Error("服务商所在地不能为空"));
      } else {
        callback();
      }
    },
    ndewichange(value) {
    },
    /**
     * 确认
     */
    handleClickConfirm(e) {
      this.$router.push("/serviceJoin");
    },
    // 存储数
    saveJoinForm() {
      // 服务类目

      this.formData.shopInfo.servicesCategoryList = [];
      this.list.forEach((i) => {
        let services = this.getFormValue(`refr${i}`);
        if (services) {
          if (services.length > 0) {
            let service = services[services.length - 1];
            if (
              this.formData.shopInfo.servicesCategoryList.indexOf(services) ==
              -1
            ) {
              this.formData.shopInfo.servicesCategoryList.push(service);
            }
          }
        }
      });

      this.formData.shopInfo.orgAccount = this.getFormValue("orgAccount");
      this.formData.shopInfo.orgName = this.getFormValue("orgName");
      this.formData.shopInfo.orgForm = this.getFormValue("orgForm");
      let orgType = this.getFormValue("ndewi");
      if (orgType) {
        this.formData.shopInfo.orgType = orgType;
      } else {
        this.formData.shopInfo.orgType = "";
      }
      this.formData.shopInfo.orgCharacter = this.getFormValue("fnre");
      this.formData.shopInfo.serviceOdject = this.getFormValue("refcev");
      this.formData.shopInfo.addressDetail = this.getFormValue("addressDetail");
      this.formData.shopInfo.bedNum = this.getFormValue("bedNum");
      this.formData.shopInfo.lowestPrice = this.getFormValue("lowestPrice");
      this.formData.shopInfo.highestPrice = this.getFormValue("highestPrice");
      this.formData.shopInfo.orgTel = this.getFormValue("orgTel");
      let businessTimeSet = this.getFormValue("businessHours");
      if (businessTimeSet) {
        this.formData.shopInfo.openTime = this.getFormValue("opentime").format(
          "HH:mm:ss"
        );
        this.formData.shopInfo.closeTime = this.getFormValue(
          "closetime"
        ).format("HH:mm:ss");
      } else {
        this.formData.shopInfo.openTime = "00:00:00";
        this.formData.shopInfo.closeTime = "00:00:00";
      }

      let fwts = this.getFormValue("fwts");
      if (fwts) {
        if (fwts.length > 0) {
          this.formData.label.specialService = fwts.join();
        } else {
          // 这个可以没有
          this.formData.label.specialService = "";
        }
      }

      if (this.formData.shopInfo.orgForm) {
        if (
          Array.isArray(this.formData.shopInfo.orgForm) &&
          this.formData.shopInfo.orgForm.length > 0
        ) {
          this.formData.shopInfo.orgForm = this.formData.shopInfo.orgForm.join();
        }
      }

      if (this.formData.shopInfo.serviceOdject) {
        if (
          Array.isArray(this.formData.shopInfo.serviceOdject) &&
          this.formData.shopInfo.serviceOdject.length > 0
        ) {
          this.formData.shopInfo.serviceOdject = this.formData.shopInfo.serviceOdject.join();
        }
      }

      localStorage.setItem("serviceJoinForm", JSON.stringify(this.formData));
    },
  },
};
</script>

<style lang="less">
.serviceJoinStep3 {
  .form-box {
    padding: 0.3rem 0;

    .form-title {
      border-top: 1px solid #f2f2f2;
      padding-top: 0.26rem;
      padding-bottom: 0.2rem;
      width: 10.66rem;
      margin: 0 auto;
    }

    .ant-form-item {
      margin-bottom: 0.2rem;
    }

    .ant-input:hover {
      border-color: #60c7c6;
    }

    .ant-select-selection:hover {
      border-color: #60c7c6;
    }

    .ant-form-item-label label {
      color: #737373;
      font-size: 0.16rem;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #60c7c6;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }

    .ant-radio-inner::after {
      background-color: #60c7c6;
    }

    .ant-btn-primary {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
  }

  .ant-radio-wrapper {
    font-size: 0.16rem;
  }

  .ant-checkbox-wrapper {
    font-size: 0.16rem;
  }

  .ant-form-item-children {
    font-size: 0.16rem;
  }

  #fwts {
    max-width: 7rem;

    .tdajosnBox {
      width: 3rem;
      height: 1rem;

      border: 1px solid #e0e0e0;
      overflow: hidden;
      margin-top: -0.2rem;
      color: rgba(0, 0, 0, 0.65);
      box-sizing: border-box;

      h2 {
        font-size: 0.14rem;
        line-height: 0.32rem;
        margin: 0;
        font-weight: bold;
        padding: 0.07rem 0.14rem 0;
        color: rgba(0, 0, 0, 0.8);
      }

      div {
        font-size: 0.14rem;
        color: rgba(0, 0, 0, 0.5);
        padding: 0 0.14rem;
      }
    }

    .ant-checkbox {
      position: absolute;
      right: 1px;
      top: calc(1rem - 14px);
      height: 0.16rem;
      bottom: 0;
      border: none;

      input {
        border: none;
      }

      .ant-checkbox-inner {
        //   display: none;
        border: none;
        bottom: 0;
      }
    }

    .ant-checkbox-checked::after {
      border: none;
    }
  }

  #fwts > div {
    margin: 0;
    float: left;
    height: 1.1rem;
    margin-right: 0.2rem;
    overflow: hidden;

    .ant-checkbox-wrapper {
      height: 1rem;
      position: relative;
      width: 100%;
    }
  }
}
</style>

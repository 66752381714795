




































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Icon, message} from "ant-design-vue";
import GoodsDetail from "@/components/Personal/myGoods/GoodsDetail.vue";
import {baseUrl} from "../../../services/http";
import {
  aftersalesApplicationStatus,
  cancellationOfOrder,
  commodityRecyclingStation,
  confirmReceipt,
  fetchProvinceName,
  invoicesInfo,
  invoicingCommodityOrders,
  PushOrderReason,
  saveTheInvoice,
  unpaidInformation
} from "@/services/api";
import {mallUrl} from "@/services/http"

import {Mutation, State} from "vuex-class";

Vue.use(Icon);
    @Component({
        components: {
            GoodsDetail
        },
        props: {
            location: String,
            allcontent: Object
        }
    })
    export default class MyGoodsItem extends Vue {
        baseUrl = baseUrl;
        serveComplete = false;
        @Prop() private status!: String;
        @Prop() private statusDic!: Number;
        @Prop() private allcontent!: Object;
        @Prop() private serviceOrderInfoId!: String;
        @Prop() private itemIndex: Number;
        @Prop() public services!: any;
        @Mutation SET_CANCELRESON;
        @State cancelReason;
        selectChangetsuahValue: any = null;
        cancelServiceList = {};
        serveCancel = false;
        recycleBin = false;
        @Mutation SET_CURDETAILSTATUS;
        informationData: any = {};
        resultOrderInfos: any = {}
        shopAddressPlaceholder = "";
        province: any = '';
        city: any = '';
        area: any = '';
        street: any = '';
        allowSubmit: any = true;

        //发票数据
        orderId: any = '';
        makeOutCheque: Boolean = false;
        invoiceType: any = 0;
        invoiceContent = 0;
        invoiceTitleType = 0;
        taxpayerId: any = "";
        receiverEmail: any = "";
        personage: any = "";
        invoiceId: any = '';
        checkTheInvoice: Boolean = false;
        disabled: Boolean = true;

        //订单详情
        lookDetail(orderInfoId: any) {
            window.open("#/personal/OrderDetail");
            this.SET_CURDETAILSTATUS(orderInfoId);
        }

        mounted() {
            this.selectChangetsuahValue = undefined;
        }

        //取消订单
        getCancelServiceList() {
            PushOrderReason({}).then(({code, data}: any) => {
                if (code == 0) {
                    this.cancelServiceList = data;
                }
            });
        }

        //删除订单提示
        deleteEvent(value: String) {
            this.recycleBin = true
        }

        //删除订单
        deleteOrder(value) {
            if (this.allcontent["orderStatus"] == 265 || this.allcontent["orderStatus"] == 266) {
                commodityRecyclingStation({
                    orderInfoId: value,
                }).then((res) => {
                    if ((res as any).code === 0) {
                        message.success("删除成功,已移到商品订单回收站");
                        this.$emit("againGetList");
                    } else {
                        message.error((res as any).msg);
                    }
                    this.recycleBin = false
                });
            } else {
                message.error("只有已完成和已取消的订单能够放入回收站");
            }

        }

        //获取取消订单中内容
        cancelServiceEnvet(key: boolean) {
            this.getCancelServiceList();
            this.serveCancel = key;
        }

        //取消订单
        selectChange(value) {
            if (this.allowSubmit) {
                this.allowSubmit = false;
                if (this.selectChangetsuahValue === undefined) {
                    message.error("请选择取消订单理由");
                    return
                } else {
                    cancellationOfOrder({
                        orderInfoId: value,
                        cancelReason: this.selectChangetsuahValue
                    }).then(res => {
                        if (res.code === 0) {
                            this.allowSubmit = false
                            message.success("取消订单成功");
                            this.$emit("againGetList");
                            this.serveCancel = false
                        } else {
                            message.error(res.msg);
                            this.allowSubmit = true
                        }
                    });
                    this.serveComplete = false;
                }
            }

        }

        //提示确认收货
        confirmReceipt() {
            this.serveComplete = true;
        }

        //确认收货
        serviceOrderConfirm(orderInfoId) {
            confirmReceipt({
                orderInfoId: orderInfoId
            }).then(res => {
                if (res.code === 0) {
                    message.success("确认成功");
                    this.$emit("againGetList");
                } else {
                    message.error(res.msg);
                }
            });
            this.serveComplete = false;
        }

        //提醒商家发货
        remindBusinessman(value) {
            alert("该功能暂未开放");
            // businessman({
            //   orderInfoId: value
            // }).then(res => {
            //   // if (res.code === 0) {
            //   //     message.success("确认成功");
            //   // } else {
            //   //     message.error("确认失败");
            //   // }
            // });
            // this.serveComplete = false;
        }

        //跳转店铺
        store(value) {
            open(mallUrl + '/#/mall/shop/' + value.merchantId);
        }

        //进行评价
        evaluate(value) {
            this.$router.push({
                path: "/personal/goodsJudgeService",
                query: {
                    orderId: value
                }
            });
        }

        //付款
        paymentOne(value) {
            unpaidInformation({
                orderId: value,
            }).then((res) => {
                if (res.code === 0) {
                    this.informationData = res.data;
                    //根据地址id获取对应详情
                    fetchProvinceName({
                        province: this.informationData.receiveInfo.addrProvince,
                        city: this.informationData.receiveInfo.addrCity,
                        area: this.informationData.receiveInfo.addrArea,
                        street: this.informationData.receiveInfo.addrStreet
                    }).then(res => {
                        if (res.code == 0) {
                            const add = res.info.addr;
                            this.province = add.province;
                            this.city = add.city;
                            this.area = add.area;
                            this.street = add.street;

                            const allcontent = this.informationData;
                            const obj = {
                                commodityAddressInfo: {
                                    province: this.province,
                                    city: this.city,
                                    area: this.area,
                                    street: this.street,
                                    addressDetail: allcontent.receiveInfo.detailAddress,
                                    phoneNumber: allcontent.receiveInfo.receivePhoneNum,
                                    receiverName: allcontent.receiveInfo.receiveName,
                                },
                                mainCommodityOrderCode: allcontent.mainCommodityOrderCode,
                                payTotal: allcontent.totalPayAmount,
                                resultOrderInfos: this.informationData.commodity,
                                mainCommodityType: "我的商品"
                            };
                            window.location.href = mallUrl + `/mall/checkstand?orderInfo=${JSON.stringify(obj)}`
                            // open(mallUrl + `/mall/checkstand?orderInfo=${JSON.stringify(obj)}`);

                        } else {
                            message.error(res.msg);
                        }
                    });


                } else {
                    message.error(res.msg);
                }
            });

        }

        //再次购买
        buyAgain(value) {
            open('/#/mall/detail/' +value.commodityInfos[0].commodityId)
        }

        //申请开票
        invoice(value) {
            alert("该功能暂未开发");
        }

        //申请开票
        applyForInvoice(orderId) {
            this.orderId=orderId
            this.makeOutCheque=true;
        }
        //查看发票
        checkTheInvoiceData(invoiceId) {
            this.invoiceId = invoiceId;
            this.checkTheInvoice = true;
            this.invoiceApply()
        }
        //发票类型
        InvoiceTypeCli(value) {
            this.invoiceType = value
        }
        //发票内容信息
        invoiceContentClick(value) {
            this.invoiceContent = value
        }
        //发票抬头切换
        radioChange(e) {
            this.invoiceTitleType = e.target.value;
        }
        //保存发票
        saveInvoice() {
            let invoiceInfo: any = {
                invoiceType: this.invoiceType === 0 ? false : true,
                invoiceContent: this.invoiceContent === 0 ? false : true,
                titleType: this.invoiceTitleType === 0 ? false : true,
                invoiceTitle: this.invoiceTitleType === 0 ? this.personage : this.receiverEmail,
            };
            if (this.invoiceTitleType === 1) {
                (invoiceInfo["taxpayerId"] = this.taxpayerId)
            }
            saveTheInvoice(invoiceInfo).then(res => {
                if (res && res.code == 0) {
                    invoicingCommodityOrders({
                        orderId: this.orderId,
                        invoiceInfoId: res.invoiceId,
                    }).then(res => {
                        if (res && res.code == 0) {
                            message.success('保存成功');
                            this.makeOutCheque = false;
                            this.$emit("againGetList");
                        } else {
                            message.error(res.code)
                        }
                    })
                }
            })
        };
        //发票详情
        invoiceApply() {
            invoicesInfo({
                invoiceId: this.invoiceId
            }).then(res => {
                this.checkTheInvoice = true
                this.invoiceType = res.data.invoiceTypeId;
                this.invoiceContent = res.data.invoiceContentId;
                this.invoiceTitleType = res.data.titleTypeId;
                this.receiverEmail = res.data.invoiceTitle;
                this.taxpayerId = res.data.taxpayerIdentificationNumber;
                this.personage = res.data.invoiceTitle;
            })
        }


        //查看退款
        seeRefund(value) {
            aftersalesApplicationStatus({returnId: value.refundId}).then(res => {
                if (value.refundTypeClaim == 0) {
                    if (res.data.statusDic === 269) {
                        this.$router.push({
                            path: "/personal/ReturnGoodsTwo",
                            query: {
                                commodityRelationId: value.orderCommodiyId,
                                returnId: value.refundId
                            }
                        });
                    } else if (res.data.statusDic === 270 || res.data.statusDic === 271 || res.data.statusDic === 272) {
                        this.$router.push({
                            path: "/personal/ReturnGoodsThree",
                            query: {
                                params: value,
                                commodityRelationId: value.orderCommodiyId,
                                returnId: value.refundId
                            }
                        });
                    }
                } else if (value.refundTypeClaim == 1) {
                    if (value.statusDic === 269) {
                        if (res.data.disposeStatus != 254) {
                            this.$router.push({
                                path: "/personal/ReturnGoods2",
                                query: {
                                    commodityRelationId: value.orderCommodiyId,
                                    returnId: value.refundId,
                                }
                            });
                        } else {
                            this.$router.push({
                                path: "/personal/ReturnGoods3",
                                query: {
                                    commodityRelationId: value.orderCommodiyId,
                                    returnId: value.refundId
                                }
                            });
                        }
                    } else if (res.data.statusDic === 270 || res.data.statusDic === 271 || res.data.statusDic === 272) {
                        this.$router.push({
                            path: "/personal/ReturnGoods4",
                            query: {
                                commodityRelationId: value.orderCommodiyId,
                                returnId: value.refundId
                            }
                        });
                    }
                }
            });

        }

        toChat(query) {
            let url = '/#/chat?'
            for (let key in query) {
                url += key + '=' + query[key] + '&'
            }
            url.substr(0, url.length - 1)
            open(url)
        }
    }

<template>
    <div class="Breadcrumb-list">
        <a-breadcrumb>
            <a-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index">
                <router-link v-if="item.significance!=1" :to="item.path"  class="breadcrumbZero">{{item.name}}</router-link>
                <span v-else class="breadcrumbOne">{{item.name}}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>

<script>
    export default {
        name: "Breadcrumb",
        props:{
            breadcrumb:Array
        },
        mounted() {
        }


    }
</script>

<style lang="less" scoped>
.Breadcrumb-list{
    .breadcrumbZero{
        font-size: 0.14rem;
        color: #2A2E43;
        opacity: 1;
    }
    .breadcrumbZero:hover{
        opacity: 0.8;
    }
    .breadcrumbOne{
        font-size: 0.14rem;
        color: #39BEB8;
        opacity: 1;
    }

}
</style>











































































  import creditReportingNav from "./creditReportingNav.vue";
  import serviceProvider from "./components/serviceProvider.vue";
  import highQuality_organization from "./components/highQuality_organization.vue";
  import highQuality_merchant from "./components/highQuality_merchant.vue";
  import merchant from "./components/merchant.vue";
  import organization from "./components/organization.vue";
  import Footer from "@/components/Footer.vue";
  import {Component, Vue} from "vue-property-decorator";
  import {getMainIndexInfo} from "@/services/api";
  import {message} from "ant-design-vue";


  @Component({
  components: {
    creditReportingNav,
    serviceProvider,
    highQuality_organization,
    highQuality_merchant,
    Footer,
    merchant,
    organization,
  },
})
export default class extends Vue {
  tabIndex = "1";
  tabIndexTwo = "1";
  scoreHidden = false;

  // 存储列表
  dataInfo = {
    serviceProviders: [{
      agencyId: "",
      agencyName: "",
      agencyImage: ""
    }],
    organizations: [],
    merchants: [],
    services: [{
      serviceId: "",
      serviceName: "",
      serviceImage: "",
      agencyName: "",
      servicePrice: ""
    }],
    commodities: [{
      commodityId: "",
      commodityName: "",
      commodityImage: "",
      agencyName: "",
      commodityPrice: ""
    }]
  }
  dataInfo2 = {
    serviceProviders: [{
      agencyId: "",
      agencyName: "",
      agencyImage: ""
    }],
    organizations: [],
    merchants: [],
    services: [{
      serviceId: "",
      serviceName: "",
      serviceImage: "",
      agencyName: "",
      servicePrice: ""
    }],
    commodities: [{
      commodityId: "",
      commodityName: "",
      commodityImage: "",
      agencyName: "",
      commodityPrice: ""
    }]
  }

  beforeCreate() {
    if (!localStorage.getItem("userInfo")) {
      message.warning("请先登录");
      open("/#/login", "_self");
      return;
    }
  }

  mounted() {
    const that = this;
    //正常优质服务数据
    getMainIndexInfo({creditStatus: 397}).then(res => {
      if (res && res.code == 0) {
        that.dataInfo = res.data
      } else {
        message.error(res.msg)
      }
    }).then(_ => {
      //黑名单数据
      getMainIndexInfo({creditStatus: 396}).then(res => {
        if (res && res.code == 0) {
          that.dataInfo2 = res.data
        } else {
          message.error(res.msg)
        }
      })
    }).then(_ => {
      // 保险
      if (!that.dataInfo) {
        getMainIndexInfo({creditStatus: 397}).then(res => {
          if (res && res.code == 0) {
            that.dataInfo = res.data
          } else {
            message.error(res.msg)
          }
        })
      }
    })
  }

  viewMoreRecommonds(val) {
    let data;
    switch (val) {
      case '1':
        data = 2
        break;
      case'2':
        data = 1
        break;
      case'3':
        data = 3
        break;
      case'4':
        data = 4
        break;
      case'5':
        data = 5
        break;
    }
    this.$router.push({path: '/creditInvestigation/creditReportingServicePage'});
    let data2 = {
      "value": '',
      "choice": 395
    }
    sessionStorage.setItem("creditSearch", JSON.stringify(data2));
    sessionStorage.setItem("acKEY", JSON.stringify(data));
  }

}

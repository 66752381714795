

















import {Component, Prop, Vue} from "vue-property-decorator";
import {Input, message, Divider} from "ant-design-vue";

Vue.use(Input);
Vue.use(Divider);

@Component({

})
export default class extends Vue {
  @Prop() private serviceProvider!: [];
  @Prop() private scoreHidden:Boolean;
  mounted() {
    const that = this
  }


  handleLogin() {

  }
}













































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {collectionsOrgService, fetchCollection} from "@/services/api";
import reservationListForm from "../views/reservationListForm.vue";
import PensionOrderSuccess from "../views/PensionOrderSuccess.vue";

@Component({
    props: {
        image: String,
        title: String,
        price: Number,
        count: Number,
        markPrice: Number,
        allInfo: Object,
        orgImages: Array,
    },
    components: {
        PensionOrderSuccess,
        reservationListForm
    },
})
export default class CookInHomeHeader extends Vue {
    @Prop() private orgId!: string;

    visible = false;
    serviceId:any='';
    skuInfoId:any = "";
    skuInfo = "";
    amount = 1;
    collectionString = "收藏";
    currentImageIndex = 0;
    visible2 = false;
    showImage: any = false;
    serviceSpecificationType = false;
    skuList: any = [];
    skuSettingMap: any = {};
    select: any = {};
    flag = -1;
    servicePrice: any = 0;

    //获取规格信息
    @Watch("allInfo", {immediate: true, deep: true})
    getallInfo(newVal) {
        this.skuList = newVal.skuList
        this.skuSettingMap = newVal.skuSettingMap;
        // //默认选中第几个
        this.skuSettingMap.forEach((element) => {
            if (element.skuValue.length === 1) {
                element.select = 0;
                Object.assign(this.select, {[element.skuName]: element.skuValue[0]});
            }

        });

        // //字符串转数组
        this.skuSettingMap.forEach(el => {
            const mapItem = el.skuValue
            let cc = typeof (mapItem);
            if (cc === 'string') {
                let str = mapItem.replaceAll('[', '').replaceAll(']', '');
                let itemArr = str.split(', ');
                el.skuValue = itemArr;
            }

        })
        if (this.skuSettingMap.length) {
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.trim().includes(val));
            });
            // this.skuChange(arr[0]);
        } else {
            this.$nextTick().then((r) => this.skuChange(this.skuList[0]));
        }
    }

    //服务内容
    typeClick2(item, i, val) {
        if (i != this.flag) {
            //点击选中
            this.flag = i;
            val.select = i;
            Object.assign(this.select, {[val.skuName]: item.trim()});
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.split(' ').includes(val))
            });
            this.skuChange1(arr[0]);
            if (arr.length === 1) {
                this.skuChange(arr[0]);
            }
        } else {
            //点击取消选中
            this.flag = -1;
            val.select = -1;
            Object.assign(this.select, {[val.skuName]: item.trim()});
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.split(' ').includes(val))
            });
            this.skuInfoId = ''
            this.$forceUpdate()
        }
    }


    skuChange(item) {
        if (item) {
            this.servicePrice = item.costPrice
            this.skuInfoId = item.skuInfoId;
            this.skuInfo = item.skuInfo;
        }
        this.$forceUpdate()
        if (this.skuInfoId != '') {
            this.serviceSpecificationType = false;
        }

    }

    //选中一个
    skuChange1(item) {
        if (item) {
            this.skuInfoId = '';
        }
        this.$forceUpdate()
    }

    mounted() {
        this.serviceId = this.$route.params.serviceId;
        this.isCollectionsOrgService();
    }
    add() {
        this.amount += 1;
    }

    minus() {
        if (this.amount > 1) {
            this.amount -= 1;
        }
    }

    //我要预约
    goPersonalMyGoods() {
        const userInfo = localStorage.getItem("userInfo");
        if (this.skuList.length === 0) {
            this.skuInfoId = null
        }
        if (userInfo) {
            if (this.skuInfoId === '') {
                this.serviceSpecificationType = true;
                return;

            } else {
                this.serviceSpecificationType = false;
                this.visible = true;
            }


        } else {
            this.$router.push("/login");
        }
    }

    reserveSuccessEvent(e) {
        this.visible = false;
        this.visible2 = true;
    }

    get _imgUrl() {
        return this.$props.orgImages.length > 0 ? this.$props.orgImages[this.currentImageIndex].orgImage : this.$props.image;
    }

    handlePreImg() {
        if (this.currentImageIndex > 0) {
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft =
                    -(this.currentImageIndex - 4) * 1.53 + "rem";
            }
            this.currentImageIndex -= 1;
        }
    }

    handleNextImg() {
        if (this.currentImageIndex < this.$props.orgImages.length - 1) {
            this.currentImageIndex += 1;
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft = -this.currentImageIndex * 1.53 + "rem";
            }
        }
    }

    deleteOrder() {
        this.visible2 = false
    }

    isCollectionsOrgService() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem("userInfo")) {
                resolve("");
                return;
            }
            collectionsOrgService({
                itemId: this.serviceId,
                itemType: 237,
            }).then((res) => {
                if (res.data) {
                    this.collectionString =
                        res.data.isCollected === 0 ? "收藏" : "已收藏";
                    resolve(res);
                }
            });
        });
    }

    fetchCollection(operation) {
        const params = {
            itemId: this.serviceId,
            itemType: 237,
        };
        return new Promise((resolve, reject) => {
            fetchCollection({...params, operation: operation}).then((res) => {
                resolve(res);
            });
        });
    }

    async collection() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            let operation = 0;
            if (this.collectionString === "已收藏") {
                operation = 1;
            } else {
                operation = 0;
            }
            await this.fetchCollection(operation);
            await this.isCollectionsOrgService();
        } else {
            this.$router.push("/login");
        }
    }

}


























import {Component, Prop, Vue} from "vue-property-decorator";
import YlmsItem from "@/components/YlmsItem.vue";

@Component({
    props: {
        headerImage: String,
        headerTitle: String,
        headerContent: String,
        headerMoreVisible: Boolean,
        imgPoistion: String,
        formDicId: Number
    },
    components: {
        YlmsItem
    }
})
export default class YlmsHeader extends Vue {
    // @Prop() private msg!: string
    b = false;

    djabdjs() {
        if (!this.b) this.gotopage(this.$props.formDicId);
    }

    mounted() {
        if (location.href.includes("pensionInstitution")) {
            this.b = true;
        }
    }

    gotopage(formDicId: Number) {
        this.$router.push(`/pensionInstitution/${formDicId}`);
    }
}

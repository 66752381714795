































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
import aSpin from "@/components/aSpin.vue";

@Component({
    components: {
        PersonalHeader,
        OpenServiceItem,
        aSpin
    },
})
export default class extends Vue {
    @Prop() topLis: any;
    @Prop() data: any;
    @Prop() pagination: any;
    @Prop() emptyTextBySearch: any;
    currentRelationId = "";
    currentServiceType = 0;
    currentTab = 1;
    serviceStatus = 0;
    loading = true;

    @Watch("data", {immediate: true, deep: true})
    getData(newVal) {
        this.loading = false
    }

    // 点击查看退款记录
    checkDetail(id) {
        this.$emit("checkDetail", id);
    }

    handleDelServiceComment(id) {
        this.$emit("handleDelServiceComment", id);
    }

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        this.$emit("getServiceComment");
    }



}
































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message, Modal} from "ant-design-vue";
    import {deleteServiceAddress, fetchProvinceName, updateDefaultAddress} from "@/services/api";

    @Component
export default class BankCard extends Vue {
  @Prop() private type!: boolean;
  @Prop() private data!: any;

  visible: Boolean = false; // 编辑服务地址对话框
  formatPhone = (p: any) => {
    if (p) {
      return p.substring(0, 3) + "*****" + p.substring(p.length - 2);
    }
  };

  allAreaData = [];
  // public address = "";
  // public City = "";
  // public Area = "";
  address = [];

  created() {
    fetchProvinceName({
      province: this.data.receiverProvince,
      city: this.data.receiverCity,
      area:this.data.receiverArea,
      street:this.data.receiverStreet
    }).then(({ info, code }) => {
      if (code == 0) {
        this.address = Object.values(info.addr);
      }
    });
  }

  // 点击设为默认
  handleSelectDefault() {
    const that = this;
    updateDefaultAddress({
      id: that.data.addressId,
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据
        message.success("默认地址更换成功");
        // 调用父组件方法，更新数据
        that.$emit("getServiceAddress");
      } else {
        message.error(`设置默认失败，错误原因：${res.msg}`);
      }
    });
  }
  // 点击编辑
  handleEdit() {
    this.$emit("editServiceAddress", this.data);
  }
  // 点击删除

  handleDelete() {
    Modal.confirm({
      title: "您确定要删除该服务信息吗？",
      width:'5rem',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleDelete2(),
    });
  }

  handleDelete2() {
    const that = this;
    deleteServiceAddress({
      addressIdList: [that.data.addressId],
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据

        message.success("删除成功");
        // 调用父组件方法，更新数据
        that.$emit("getServiceAddress");
      } else {
        message.error(`删除失败，错误原因：${res.msg}`);
      }
    });
  }
}

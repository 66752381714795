

















































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Header from "@/components/Header.vue";
    import serviceItemAllOrder from "./serviceItemAllOrder.vue";
    import Cansole from "./cansole.vue";
    import servieItemHeader from "./servieItemHeader.vue";
    import InfoCard from "../../components/InfoCard.vue";
    import {briefInfo, thirdCategoryList} from "../../services/api";

    @Component({
  components: {
    Nav,
    Footer,
    Header,
    serviceItemAllOrder,
    Cansole,
    servieItemHeader,
    InfoCard
  }
})
export default class extends Vue {
  pagination = {
    pageIndex: 1,
    pageSize: 3,
    total: 0
  };
  lookMoreShow = false;
  pageContent = [];
  content = {};
  allService = [];
  orgId = "";
  title = "";
  data() {
    const pageContent: Array<any> = [];
    return {
      pageContent: pageContent
    };
  }

  get __orgChildNodesBool() {
    if (this.content["orgChildNodes"])
      return this.content["orgChildNodes"].length > 0;
    else return false;
  }

  secondId = "";
  categoryId = "";
  @Watch("$route")
  routechange(to: any, from: any) {
    this.orgId = to.query.orgId;
    this.briefInfo(this.orgId);
    this.serviceList(this.orgId);
  }
  mounted() {
    this.orgId = this.$route.query.orgId;
    this.title = this.$route.query.title;
    this.categoryId = this.$route.query.categoryId;
    this.briefInfo(this.orgId);
    this.serviceList(this.orgId);
    this.thirdCategoryList({
      pageSize: 999,
      pageIndex: 1,
      orgId: this.orgId,
      thirdCategoryId: this.categoryId
    });
  }
  lookAllService() {
    this.$router.push({
      path: "/servieItemDetailAll",
      query: {
        orgId: this.orgId
      }
    });
  }
  thirdCategoryList(params) {
    thirdCategoryList(params).then(res => {
      this.allService = res.data.serviceList;
      if (res.data.totalCount > 3) {
        this.lookMoreShow = !this.lookMoreShow;
      }
    });
  }
  lookMore() {
    this.thirdCategoryList({
      pageSize: 9999,
      pageIndex: 1,
      orgId: this.orgId,
      thirdCategoryId: this.categoryId
    });
  }
  briefInfo(orgId) {
    briefInfo({
      orgId: orgId
    }).then(res => {
      this.content = res.data;
    });
  }
  onchange(page, pageSize) {
    this.pagination.pageIndex = page;
    this.orgId = this.$route.query.orgId;
    this.serviceList(this.orgId);
  }
  serviceList(orgId) {
    // serviceList({
    //   orgId: orgId,
    //   ...this.pagination
    // }).then(res => {
    //   this.allService = res.data.thirdCategoryList;
    //   this.pagination.total = res.data.totalCount;
    // });
  }
}

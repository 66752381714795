




































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    props: {
        // serviceOrder: Object,
        // amount:any
    },
})
export default class settlementList extends Vue {
    @Prop() amount: any;
    @Prop() serviceOrder: any;
    serviceRemarkData:any='';
    addAmount() {
        this.$emit("addAmount", this.amount);
    };

    minusAmount() {
        this.$emit("minusAmount", this.amount);
    }
    serviceRemarkCli(){
        this.$emit("serviceRemarkCli", this.serviceRemarkData);
    }
    //计算服务商合计金额
    get totalAmount() {
        return (
            this.serviceOrder.servicePrice * +this.amount +
            +this.amount * this.serviceOrder.serviceStyleList[0].serviceFee
        );
    }

    unclickable = true

    created() {
        if (this.$route.name === 'purchaseOrder') {
            this.unclickable =false
        }
    }

}



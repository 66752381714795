



















  import {message} from "ant-design-vue";
  import {Component, Prop, Vue} from "vue-property-decorator";

  @Component({
  props: {
    img: String,
    title: String,
    content: String,
    phone: String,
    location: String,
  },
})
export default class SubOrgItem extends Vue {
  @Prop() private subOrgId!: string;
  @Prop() private checkStatus: string;
  @Prop() private datatype: string;

  lookDetail() {
    if(!this.datatype){
      if (this.checkStatus != "41") {
        message.warn("该服务商已下架或服务商重审核中");
        return;
      }
      this.$router.push({
        path: "/servieItemDetailAll",
        query: {
          orgId: this.subOrgId,
        },
      });
    }else{
      if (this.checkStatus != "41") {
        message.warn("该服务商已下架或服务商重审核中");
        return;
      }
      this.$router.push({
        path: "/organDetailAll",
        query: {
          orgId: this.subOrgId,
        },
      });
    }

  }
}








import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";

Vue.use(Rate);
@Component({
    props: {
        loading: Boolean,
    },
})
export default class extends Vue {

}

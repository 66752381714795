




























































































































































































    import Vue from "vue";
    import {http} from "@/services/http";

    export default Vue.extend({
  name: "CouponCenter",
  mounted() {
    this.getdata();
  },
  data() {
    return {
      condition: { pageSize: 12, pageIndex: 1 },
      total: 200,
      list: [],
    };
  },
  methods: {
    itemRender(current, type, originalElement) {
      const a = document.createElement("a");

      if (type === "prev") {
        a.innerHTML = "上一页";
        return a;
      } else if (type === "next") {
        a.innerHTML = "下一页";
        return a;
      }
      return originalElement;
    },
    onChange(e: number) {
      this.condition.pageIndex = e;
      this.getdata();
    },
    getdata() {
      http
        .get("/api/v1/coupons/center", { params: this.condition })
        .then((res:any) => {
          this.list = res["data"].coupons;
          this.total = res["data"].totalCount;
        });
    },
  },
});























































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {diagnosisTreatCancel, dictionariesWithDesc, medicalReport} from "@/services/api";
    import {message} from "ant-design-vue";

    @Component({
  props: {
    infoData: Object,
    status: Number
  }
})
export default class ReservationDetailInfo extends Vue {
  @Prop() private content!: Object;
  cancelVisible = false;
  cancelReason:any = [];
  cancelId = "";
  selectChange(key) {
    let obj = this.cancelReason.filter(i=>i.key == key)[0].value
    this.cancelId = obj;
  }
  created() {
    //取消预约单原因
    dictionariesWithDesc(183).then((res: any) => {
      this.cancelReason = res["values"];
    });
  }
  cancelTheItem() {
    this.cancelVisible = true;
  }

  reservationCancel(reservationId) {
    if (!this.cancelId) {
      message.warning("请选择取消原因");
      return;
    }
    diagnosisTreatCancel({
      reservationId: reservationId,
      cancelReason: this.cancelId
    }).then(res => {
      if (res.code === 0) {
        message.success("提交成功");
        this.cancelId = "";
        this.$router.push("/personal/reservationList");
      } else {
        message.error("提交失败");
      }
      this.cancelVisible = false;
    });
  }
  //查看报告
  report(value){
    medicalReport({
      reservationId: value
    }).then(res => {
      if (res.code === 0) {
        //文件下载
        let link = document.createElement('a')  // 创建a标签
        link.style.display = 'none'
        // 这里是将url转成blob地址，
        fetch(res.path).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob) // 设置下载地址
          link.download = ''
          document.body.appendChild(link)
          link.click();
          message.success("下载成功,请前往所选下载路径进行查看。");
        })
      } else {
        message.error(res.msg);
      }
    });

  }
}

<template>
    <div class="judge-body" v-if="shoasjoas">
        <PersonalHeader :title="'评价服务订单'"/>
        <div class="judge-header">
            <div class="header-icon">
                <img :src="decideWorkpoints.agencyLogo" style="width: 1rem; height: 1rem; border-radius: 50%"/>
            </div>
            <div class="middle_box" style="width: 2.6rem">
                <span class="header-title" @click="jumpService">{{decideWorkpoints.agencyName}}</span>
                <div class="header-rate">
                    <div class="item">
                        <span :style="{ fontSize: '0.14rem', color: '#737373' }">综合</span>
                        <span :style="{ fontSize: '0.14rem', color: '#e24848' }">
              {{ decideWorkpoints.overallScore || 0 }}
            </span>
                    </div>
                    <div class="item">
                        <span :style="{ fontSize: '0.14rem', color: '#737373' }">服务</span>
                        <span :style="{ fontSize: '0.14rem', color: '#e24848' }">
              {{ decideWorkpoints.serviceScore || 0 }}
            </span>
                    </div>
                    <div class="item">
                        <span :style="{ fontSize: '0.14rem', color: '#737373' }">态度</span>
                        <span :style="{ fontSize: '0.14rem', color: '#e24848' }">
              {{ decideWorkpoints.attitudeScore || 0 }}
            </span>
                    </div>
                    <div class="item">
                        <span :style="{ fontSize: '0.14rem', color: '#737373' }">效率</span>
                        <span :style="{ fontSize: '0.14rem', color: '#e24848' }">
              {{ decideWorkpoints.efficiencyScore || 0 }}
            </span>
                    </div>
                </div>
            </div>

            <div class="rate-item-costom-box">
                <div class="rate-item-costom">
                    <span>服务符合度</span>
                    <a-rate
                            :disabled="commentId"
                            v-model="ServiceInfo.serviceCompliance"
                    />
                </div>
                <div class="rate-item-costom">
                    <span>服务商态度</span>
                    <a-rate
                            :disabled="commentId"
                            v-model="ServiceInfo.organizationAttitude"
                    />
                </div>
                <div class="rate-item-costom">
                    <span>服务商效率</span>
                    <a-rate
                            :disabled="commentId"
                            v-model="ServiceInfo.organizationSpeed"
                    />
                </div>
            </div>
        </div>
        <div class="judge-content" style="margin-bottom: 0.2rem; height: auto">
            <div class="content-header">
        <span :style="{ marginLeft: '0.17rem' }"
        >服务订单号: {{ orgInfo.seriesNum }}</span
        >
                <span :style="{ marginLeft: '0.37rem' }">
          <span v-if="commentId">服务评价时间:</span>
          {{ orgInfo.createTime }}
        </span>
            </div>
            <div class="content-body" style="height: auto">
                <div class="rate-body">
                    <div class="good-img">
                        <img :src="orgInfo.serviceLogo" style="width: 100%; height: 100%"/>
                    </div>
                    <span class="good-name">{{ orgInfo.serviceName || "--" }}</span>
                </div>
                <div class="judge-form">
                    <div class="rate-item" :style="{ alignItems: 'center', marginBottom: '0.1rem' }">
                        <span :style="{ fontSize: '0.14rem', width: '1.2rem' }">服务评分</span>
                        <a-rate :style="{ marginLeft: '0.2rem' }" :defaultValue="0" :disabled="commentId" v-model="ServiceInfo.commentStar"/>
                    </div>
                    <div class="rate-item" :style="{ marginBottom: '0.2rem' }">
                        <span :style="{ fontSize: '0.14rem', width: '1.2rem' }">服务评价</span>
                        <a-textarea  v-if="!commentId" placeholder="快把您的感受分享给大家吧" v-model="ServiceInfo.commentContent" :style="{marginLeft: '0.2rem',width: '4.5rem',fontSize: '0.16rem',resize: 'none', height: '1.1rem',}"></a-textarea>
                        <div v-else :style="{ marginLeft: '0.2rem', width: '4.5rem', fontSize: '0.16rem',resize: 'none', height: '1.1rem',display: 'inline-block',}">
                            {{ ServiceInfo.commentContent }}
                        </div>
                    </div>
                    <div class="Tiptip">{{ commentContentTip }}</div>

                    <div
                            class="rate-item"
                            v-if="!commentId"
                            :style="{ marginBottom: '0.3rem' }"
                    >
                        <a-checkbox
                                :style="{ fontSize: '0.14rem' }"
                                v-model="ServiceInfo.isAnonymous"
                        >匿名
                        </a-checkbox
                        >
                    </div>
                    <div class="button" v-if="!commentId">
                        <span class="btn" @click="handleSubmitJudge">发表</span>
                    </div>
                </div>
            </div>
        </div>
        <a-modal width="500px" style="top: 150px" v-model="judgeModal">
            <template slot="footer">
                <div class="judge-modal-footer">
                    <a-button key="back" @click="closeModal">返回我的服务</a-button>
                    <a-button
                            key="submit"
                            type="primary"
                            @click="() => $router.push('/personal/myService')"
                    >继续评价
                    </a-button
                    >
                </div>
            </template>
            <div class="judge-modal-body">
                <div class="confirm-img"></div>
                <div class="confirm-tip">
                    <span>评价已完成，以下服务未评价快去评价吧！</span>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import {Button, Checkbox, Icon, Input, message, Modal, Rate} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {baseUrl, request} from "@/services/http";
    import {
        appointmentOrderEvaluation,
        diagnosisAndTreatData,
        diagnosisTreatmentDetails,
        telemedicineDetails
    } from "../../../../services/api";

    Vue.use(Icon);
    Vue.use(Rate);
    Vue.use(Input);
    Vue.use(Checkbox);
    Vue.use(Modal);
    Vue.use(Button);

    @Component({
        components: {
            PersonalHeader
        }
    })
    export default class extends Vue {
        reservationOrderId = "";
        ifEvaluation = "";
        ServiceInfo = {
            isAnonymous: false,
            commentStar:0,
            commentContent:'',
            serviceCompliance:0,
            organizationAttitude:0,
            organizationSpeed:0,
        };
        commentContentTip='';
        content = {}
        shoasjoas = true;
        orgInfo = {};
        decideWorkpoints = {};
        commentId = false;
        judgeModal = false;
        mounted() {
            this.reservationOrderId = this.$route.query.reservationOrderId;
            this.ifEvaluation = this.$route.query.ifEvaluation;

            //根据诊疗单ID查看诊疗详情
            diagnosisTreatmentDetails({
                reservationId: this.reservationOrderId
            }).then(res => {
                let data = res.info;
                this.content = data;

            })
            //查看服务商图片和服务商评分信息
            diagnosisAndTreatData({
                reservationId: this.reservationOrderId
            }).then(res => {
                let data = res.info;
                this.orgInfo = data
                // 查看服务商评分信息
                if (data.orgId) {
                    request(`${baseUrl}/api/v1/service-order/evaluation/orgInfo`, {
                        method: "GET",
                        params: {orgId: data.orgId}
                    }).then(({code, data}) => {
                        if (code == 0) {
                            this.decideWorkpoints = data
                        }
                    });
                }
            })


            const serviceCommentId = this.$route.query.serviceCommentId;

            if (serviceCommentId) {
              this.commentId = true;
                telemedicineDetails({
                    evaluationId: serviceCommentId
                }).then(res => {
                    let data = res.info;
                    const serviceInfo = {
                        isAnonymous: false,
                        commentStar:data.commentStar,
                        commentContent:data.commentContent,
                        serviceCompliance:data.serviceCompliance,
                        organizationAttitude:data.organizationAttitude,
                        organizationSpeed:data.organizationSpeed,
                    };
                    this.ServiceInfo = serviceInfo

                })
            }
        }

        //查看服务商
        jumpService() {

        }
        //返回服务
        closeModal(){
            this.judgeModal = false;
            this.$router.push("/personal/myService");
        }

        //发表评价
        handleSubmitJudge() {
            //只判断了服务评价
            if(this.ServiceInfo.commentContent===''){
                this.commentContentTip='请填写服务评价信息'
                return
            }
            const data = {
                ...this.ServiceInfo,
                telemedicineReservationId: this.reservationOrderId,
                isAnonymous: this.ServiceInfo.isAnonymous ? 1 : 0,
            };
            appointmentOrderEvaluation(data).then(res => {
                    if (res.code === 0) {
                        message.success("评论成功！");
                        this.judgeModal = true;
                        this.$router.push("/personal/reservationList");
                    } else {
                        message.error(res.msg);
                    }
                });
        }

    }
</script>

<style lang="less" scoped>
    .Tiptip {
        padding-left: 25%;
        color: #f00;
        margin-top: -0.1rem;
        margin-bottom: 0.1rem;
        font-size: 0.14rem;
    }

    .judge-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0.3rem;
        height: 100%;
        width: 11.44rem;
        .judge-header {
            display: flex;
            flex: row;
            justify-content: flex-start;
            align-items: center;
            height: 1.3rem;
            width: 100%;
            .rate-item-costom-box {
                .rate-item-costom {
                    margin-right: 0.26rem;
                    display: inline-block;
                    font-size: 0.14rem;
                    .ant-rate-star:not(:last-child) {
                        margin-right: 2px;
                    }
                    span {
                        margin-right: 0.02rem;
                    }
                    i {
                        font-size: 0.22rem;
                        transform: scale(1);
                    }
                }
            }

            .header-icon {
                width: 0.74rem;
                height: 0.74rem;
                border-radius: 0.37rem;
                background-color: #dddddd;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.45rem;
                color: #fff;
                margin-left: 0.35rem;
            }
            .header-title {
                font-size: 0.16rem;
                color: #171717;
                font-weight: bold;
                margin-left: 0.2rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .header-rate {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 0.4rem;
                width: 1.62rem;
                margin-left: 0.26rem;
                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    height: 0.4rem;
                }
            }
        }
        .judge-content {
            width: 100%;
            // height: 5.95rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            border: 1px solid #cdcdcd;
            .content-header {
                width: 100%;
                height: 0.35rem;
                background-color: #f8f8f8;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #adadad;
                font-size: 0.14rem;
            }
            .content-body {
                // height: 5.6rem;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0.8rem 0 0.6rem;
                .rate-body {
                    height: 100%;
                    width: 3.75rem;
                    border-right: 1px solid #dcdcdc;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 0.2rem;
                    .good-img {
                        width: 2.35rem;
                        height: 1.33rem;
                        background-color: #dcdcdc;
                    }
                    .good-name {
                        font-size: 0.17rem;
                        font-weight: bold;
                        color: #171717;
                        margin-top: 0.18rem;
                    }
                    .good-price {
                        font-size: 0.17rem;
                        font-weight: bold;
                        color: #d84848;
                        margin-top: 0.1rem;
                    }
                    .rate-item {
                        width: 90%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 0.1rem;
                        ul.ant-rate {
                            padding-bottom: 3px;
                            font-size: 0.22rem;
                        }
                    }
                }
                .judge-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    height: 100%;
                    width: 7.3rem;
                    padding: 0 0.92rem;
                    .rate-item {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        ul.ant-rate {
                            padding-bottom: 3px;
                            font-size: 0.22rem;
                        }
                    }
                    .button {
                        width: 100%;
                        height: 0.45rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .btn {
                            width: 2rem;
                            height: 0.45rem;
                            border-radius: 5px;
                            background-color: #60c8c6;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>

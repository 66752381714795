
































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button, Input } from "ant-design-vue";

Vue.use(Button);
Vue.use(Input);

@Component({
  components: {}
})
export default class extends Vue {}

























































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button, Divider, Radio, Modal } from "ant-design-vue";

Vue.use(Button);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(Modal);

@Component({
  components: {}
})
export default class extends Vue {
  visible: boolean = false;

  showModal() {
    this.visible = true;
  }

  handleOk() {
    this.visible = false;
  }
}






    import {Component, Vue} from "vue-property-decorator";
    import {baseUrl, request} from "@/services/http";

    @Component({})
export default class classOpenServiceOne extends Vue {
  relationId = "";
  created() {
    this.relationId = this.$route.query.relationId;
    let form = new FormData();
    form.append("relationId", this.relationId);
    request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service`, {
      method: "post",
      data: form
    }).then(res => {
    });
  }
}

import { render, staticRenderFns } from "./organizationDetailHeader.vue?vue&type=template&id=adc67568&scoped=true&"
import script from "./organizationDetailHeader.vue?vue&type=script&lang=ts&"
export * from "./organizationDetailHeader.vue?vue&type=script&lang=ts&"
import style0 from "./organizationDetailHeader.vue?vue&type=style&index=0&id=adc67568&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adc67568",
  null
  
)

export default component.exports
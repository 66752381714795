















































































































































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Button, Form, Input, message, Radio, Select} from "ant-design-vue";
    import {dicListType, fetchAddrCascader, telemedicineAdd} from "@/services/api";
    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Header from "@/components/Header.vue";
    import {State} from "vuex-class";

    Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Button);

@Component({
  props: {
    skuInfoId: String,
  },
  components: {
    Nav,
    Footer,
    Header
  }
})
export default class pensionOrderForm extends Vue {
  @State orgFormJson;
  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 5}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 15}
    }
  };


  options = [];
  demandRemark = "";
  skuInfoIdStr=''
  physicalConditionList = {};
  contentOption = [
    { label: "男", value: "621" },
    { label: "女", value: "622" }
  ];
  @Watch("skuInfoId", {immediate: true, deep: true})
  getSkuInfoId(newVal, oldVal) {
    this.skuInfoIdStr =newVal
    // this.isCollectionsOrgService(newVal);
  }
  submitMyOrder() {
    this.$router.push("/pensionOrderSuccess");
  }
  form: any;
  beforeCreate() {
    this.form = this.$form.createForm(this);
  }
  mounted() {
    this.init();
    this.dicListType(96).then(v => {
      this.physicalConditionList = v;

    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      function getdictoryid(currentObject, name) {
        let currentId = "";
        Object.entries(currentObject).forEach(el => {
          if (currentObject[el[0]] === name) {
            currentId = el[0];
          }
        });
        return currentId;
      }
      if (!err) {
        let param = {
          contactProvince: values.address[0].toString(),
          contactCity: values.address[1].toString(),
          contactArea: values.address[2].toString(),
          contactStreet: values.address[3].toString(),
          serviceId: this.$route.params.serviceId,
          contactName: values.contactName,
          contactTel: values.contactTel,
          contactDetailAddr: values.detailAdd,
          patientAge: values.elderAge,
          patientName: values.patientName,
          patientGender: values.elderGender,
          //等着预约单接口接收传的skuInfoId
          skuInfoId:this.skuInfoIdStr,
          patientMaritalStatus: getdictoryid(
            this.physicalConditionList,
            values.physicalCondition
          ),
          requireRemark: this.demandRemark,
        };
        telemedicineAdd({ ...param }).then(res => {
          if (res.code === 0) {
            this.$emit("reserveSuccess", "123");
            this.form.resetFields();
            this.demandRemark = "";
          } else {
            message.error("预约失败，请联系网站管理员！");
          }
        });
      }
    });
  }
  async dicListType(typeId) {
    let data = {};
    await dicListType({
      typeId: typeId
    }).then(res => {
      res.data.dictionaryList.forEach(el => {
        data[el.dictionaryId] = el.dictionaryName;
      });
    });
    return data;
  }

  init() {
    fetchAddrCascader({
      areaType: 0,
      areaId: ""
    }).then(data => {
      if (data && data.code == 0) {
        const list = data.data.list;
        let children: any = [];
        if (list) {
          list.forEach(i => {
            children.push({
              label: i.name,
              value: i.id,
              id: i.id,
              level: 1,
              isLeaf: false
            });
          });
        }
        this.options = children;
      } else {
        message.error(data.msg);
      }
    });
  }

  phoneCheck(rule, value, callback) {
    if (!value) {
      callback("联系人电话不能为空");
    }
    const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
    const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (!reg.test(value) && !reg2.test(value)) {
      callback("格式不正确，请输入正确的手机号");
    } else {
      callback();
    }
  }

  loadData(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    const { id } = targetOption;
    const { level } = targetOption;
    targetOption.loading = true;
    fetchAddrCascader({
      areaType: level,
      areaId: id
    }).then(data => {
      targetOption.loading = false;
      if (data && data.code == 0) {
        const list = data.data.list;
        let children: any = [];
        if (list) {
          list.forEach(i => {
            children.push({
              label: i.name,
              value: i.id,
              id: i.id,
              isLeaf: i.childNum == 0,
              level: level + 1
            });
          });
        }
        targetOption.loading = false;
        targetOption.children = children;
        this.options = [...this.options];
      } else {
        message.error(data.msg);
      }
    });
  }
}

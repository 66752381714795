





























































































































    import {Component, Vue} from "vue-property-decorator";
    import {Col, Dropdown, Icon, message, Row} from "ant-design-vue";
    import {fetchCityList, firstLetterList, userLogout,} from "@/services/api";

    import {facilitatorUrl, MerchantUrl, serviceProviderUrl} from "@/services/http";
    import AMapLoader from "@amap/amap-jsapi-loader";

    declare var AMap: any;

Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Dropdown);

@Component
export default class Header extends Vue {
  // @Prop() private msg!: string
  currentCity: string = "正在定位...";
  isLogin: Boolean = false;
  homeStyle: Boolean = false;
        userInfo: any = null;
        menuCenterInnerBox = false;
        webNavigation = false;

        orgAdminUrl = serviceProviderUrl;
        facilitatoAdminUrl = facilitatorUrl;
        meradminUrl = MerchantUrl;

        saveCurrentCityId(adcode: string) {
            fetchCityList(0).then((res) => {
                if (res.code === 0) {
                    const city = res.data.provinceList.filter(
                        (item: any) => item.provinceCode === adcode
                    );
                    if (city) {
                        localStorage.setItem("provinceId", city[0].provinceId);
                        // localStorage.setItem("provinceId", "2");
                    } else {
                        message.error(`未找到匹配的城市ID！`);
                    }
                } else {
                    message.error(`获取城市列表错误，错误原因：${res.msg}`);
                }
            });
        }

        showCityByn: boolean = false;

        updated() {
            this.showCityByn = !location.href.includes("pickCity");
        }

        initAMap() {
            AMapLoader.load({
                version: "1.4.15",
                plugins: ["AMap.Geolocation", "AMap.Geocoder", "AMap.CitySearch"],
                key: "c4ac5dbaa55b2994a57c6b369f28af53",
            })
                .then((AMap: any) => {
                    this.getLocation(AMap);
                })
        }
        //获取定位信息
        getLocation(AMap) {
            if (localStorage.getItem("setCity")) {
                const str: any = localStorage.getItem("setCity");
                const obj = JSON.parse(str);
                this.currentCity = obj.cityName;
                localStorage.setItem("provinceId", obj.cityId);
                return;
            }
            const location = new AMap.Geolocation({
                enableHighAccuracy: true,
                extensions: "all",
            });
            location.getCurrentPosition((status, result) => {
                if (result.type == "complete" && result.info == "SUCCESS") {
                    const geolocation = new AMap.Geolocation();
                    geolocation.getCityInfo((state, result) => {
                        if (result.type == "complete" && result.info == "SUCCESS") {
                            this.currentCity = result.city;
                            this.saveCurrentCityId(result.adcode);
                        }
                    })
                } else {
                    const citySearch = new AMap.CitySearch();
                    citySearch.getLocalCity((status, result) => {
                        if (result.type == "complete" && result.info == "OK") {
                            this.currentCity = result.city || result.province;
                            this.saveCurrentCityId(result.adcode);
                        }
                    });
                }

            })
        }

        mounted() {
            this.initAMap();
            if (this.$route.path === '/home') {
                this.homeStyle = true
            }
            setTimeout(() => {
                // 判断用户是否登录
                const userInfo = localStorage.getItem("userInfo");
                if (userInfo) {
                    this.userInfo = JSON.parse(userInfo);
                    this.isLogin = true;
                } else {
                    this.isLogin = false;
                    this.userInfo = null;
                }
            }, 1000);
        }

        destroyed() {
            const userInfo = localStorage.getItem("userInfo");
        }

        //跳转养老商城
        jumpToMall(e) {
            (location.href.includes("test") || location.href.includes("develop")) ? open("/mall") : alert("暂未开放");
        }

        //跳转对应服务商、商户、机构
        viewMoreRecommonds(value: Number) {
            const {href} = this.$router.resolve({path: "/servicePage?moreType=" + value,});
            window.open(href, "_blank");
        }

        // 点击退出
        handleLogout() {
            userLogout({}).then((res) => {
                if (res && res.code === 0) {
                    this.isLogin = false;
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("merchantJoinForm");
                    localStorage.removeItem("serviceJoinForm");
                    localStorage.removeItem("laterPath");
                    sessionStorage.removeItem("pay");
                    this.$router.push("/home");
                } else {
                    if (res.msg == "token失效，请重新登录") {
                        localStorage.removeItem("userInfo");
                        this.isLogin = false;
                        this.userInfo = null;
                    }
                    message.error(res.msg);
                }
            });
        }

        // 点击个人中心
        goPersonalMyGoods() {
            // const userInfo = localStorage.getItem("userInfo");
            // if (userInfo) {
            if (this.$route.path == "/personal/myHomePage") {
                window.location.reload();
            } else this.$router.push("/personal/myHomePage");

            // } else {
            //   this.$router.push("/login");
            // }
        }

        jumpToSelectCity() {
            if (!localStorage.getItem("citypicK"))
                firstLetterList({}).then((res) => {
                    localStorage.setItem("citypicK", JSON.stringify(res));
                    this.$router.push("/pickCity");
                });
            else this.$router.push("/pickCity");
        }

        CityBoxBool: boolean = false;
        hotCitys = [
            {
                cityId: "0",
                cityName: "全国",
            },
            {
                cityId: "1",
                cityName: "北京",
            },
            {
                cityId: "73",
                cityName: "上海",
            },
            {
                cityId: "200",
                cityName: "广州",
            },
            {
                cityId: "202",
                cityName: "深圳",
            },
            {
                cityId: "2",
                cityName: "天津",
            },
            {
                cityId: "309",
                cityName: "西安",
            },
            {
                cityId: "255",
                cityName: "重庆",
            },
            {
                cityId: "87",
                cityName: "杭州",
            },
            {
                cityId: "74",
                cityName: "南京",
            },
            // {
            //   cityId: "169",
            //   cityName: "武汉",
            // },
        ];

        selectTheCity(cityId, cityName) {
            const obj = {
                cityId,
                cityName,
            };
            localStorage.setItem("setCity", JSON.stringify(obj));
            localStorage.setItem("provinceId", cityId);
            this.$router.push("/Home");
            window.location.reload();
        }
    }

<template>
  <div class="judge-body-institution">
    <div class="judge-detail-top">
      <Breadcrumb :breadcrumb="breadcrumb"/>
    </div>
    <div class="comment-top">
      <div class="top-bg">
        <div class="bg-left">
          <img :src="agencyPic" @click="gotoOrgDetail"/>
        </div>
        <div class="bg-right">
          <span class="bg-right-header-title" @click="gotoOrgDetail">{{ agencyName }}</span>
          <div class="bg-right-header-rate">
            <div>综合</div>
            <div>服务</div>
            <div>态度</div>
            <div>效率</div>
          </div>
          <div class="bg-right-header-item">
            <div>{{ parseFloat(ScoreInfo.overallScore).toFixed(2) || 0 }}</div>
            <div>{{ parseFloat(ScoreInfo.serviceScore).toFixed(2) || 0 }}</div>
            <div>{{ parseFloat(ScoreInfo.attitudeScore).toFixed(2) || 0 }}</div>
            <div>{{ parseFloat(ScoreInfo.efficiencyScore).toFixed(2) || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="comment-merchant">
        <span>养老机构服务</span>
        <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem" v-model="Obj.serviceCompliance"/>
        <span>{{ Obj.serviceCompliance ? parseInt(Obj.serviceCompliance).toFixed(1) : 0 }}分</span>
      </div>
      <div class="comment-merchant">
        <span>养老机构态度</span>
        <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem"
                v-model="Obj.organizationAttitude"/>
        <span>{{ Obj.organizationAttitude > 0 ? parseInt(Obj.organizationAttitude).toFixed(1) : 0 }}分</span>
      </div>
      <div class="comment-merchant">
        <span>养老机构效率</span>
        <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem" v-model="Obj.organizationSpeed"/>
        <span>{{ Obj.organizationSpeed ? parseInt(Obj.organizationSpeed).toFixed(1) : 0 }}分</span>
      </div>
    </div>
    <div class="comment-center">
      <div class="comment-center-left">
        <div class="rate-body-comment">
          <div class="good-img-comment">
            <!--            <img :src="item.servicePic" />-->
          </div>
          <!--          <span class="good-name-comment">{{ item.serviceName || "&#45;&#45;" }}</span>-->
          <!--          <span class="good-price-comment">￥{{ item.servicePrice || "&#45;&#45;" | formatMoney }}</span>-->
          <!--          <span class="good-specification">四菜一汤 上门服务</span>-->
        </div>
      </div>
      <div class="comment-center-right">
        <div class="judge-form">
          <div class="rate-item" style="margin-top: 0.5rem" v-if="commentTime">
            <span class="pin">评价时间</span>
            <div class="exhibition">{{ commentTime }}</div>
          </div>
        </div>
        <div class="judge-form">

          <div class="rate-item" :style="{marginTop:commentTime?'0':'0.5rem'}">
            <span class="pin">养老机构评价</span>
            <a-textarea
                v-if="!commentId"
                placeholder="养老环境如何，服务内容如何，服务态度如何，快把您的感受分享给大家吧。"
                v-model="Obj.commentContent"
                :style="{
                width: '6rem',
                fontSize: '0.14rem',
                resize: 'none',
                height: '1.4rem',
              }"
            ></a-textarea>
            <div class="exhibition" v-else>{{ Obj.commentContent }}</div>
          </div>

          <div class="button" v-if="!commentId">
            <div class="btn" @click="handleSubmitJudge">
              <img src="../../../../assets/images/homeImg/路径 2314@2x.png"/>
              发表
            </div>
            <div>
              <a-checkbox :style="{ fontSize: '0.14rem' }" v-model="Obj.ifAnonymous">匿名</a-checkbox>
            </div>
          </div>
        </div>

      </div>
    </div>
    <a-modal width="6.3rem" :visible="judgeModal" @cancel="() => (this.judgeModal = false)">
      <template slot="title">
        <div class="modal-header">
          <span>评价成功</span>
        </div>
      </template>
      <div class="modal-center">
        <p class="confirm-single">评价已完成，以下养老机构未评价快去评价吧！</p>
      </div>
      <template slot="footer">
        <div class="modal-footer">
          <div class="ok-button" @click="() => $router.push('/personal/myReservation')">
            <span><img src="../../../../assets/images/homeImg/yes.png"/>继续评价</span>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import {Button, Checkbox, Icon, Input, message, Modal, Rate} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {reservationCommentAdd} from "@/services/api";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import {baseUrl, request} from "@/services/http";

    Vue.use(Icon);
Vue.use(Rate);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Modal);
Vue.use(Button);

@Component({
  components: {
    PersonalHeader,
    Breadcrumb
  }
})
export default class extends Vue {
  reservationOrderId = "";
  agencyName = "";
  postIntentionTime = "";
  seriesNum = "";
  agencyPic = "";
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "我的预约单",
      path: "/personal/myReservation",
      significance: 0
    },
    {
      name: "养老机构评价",
      path: "/personal/ReservationCommnet",
      significance: 1
    },
  ]
  Obj = {
    commentContent: "",
    ifAnonymous: false,
    organizationAttitude: 0,
    organizationSpeed: 0,
    serviceCompliance: 0,
  };
  commentTime = '';
  judgeModal = false;
  commentId = false;
  ScoreInfo = {};
  serOrOrg='';

  mounted() {
    // this.reservationOrderId = this.$route.query.reservationOrderId;
    this.Obj.reservationId = this.$route.query.reservationOrderId;
    this.Obj.orgId = this.$route.query.orgId;
    const commentId = this.$route.query.commentId;

    //评论时查看机构详情
    if (this.Obj.orgId) {
      request(`${baseUrl}/api/v1/service-order/evaluation/orgInfo`, {
        method: "GET",
        params: {orgId: this.Obj.orgId}
      }).then(({code, data}) => {
        if (code == 0) {
          this.ScoreInfo = data;
          this.agencyPic = data.agencyLogo;
          this.agencyName = data.agencyName;
        }
      });
    }


    if (commentId) {
      this.commentId = true;
      request(`${baseUrl}/api/v1/services/comments/reservation/detail`, {
        method: "GET",
        params: {commentId}
      }).then(({code, data}) => {
        if (code == 0) {
          this.Obj.commentContent = data.content;
          this.Obj.overallScore = data.score;
          this.Obj.orgId = data.orgId;
          this.Obj.organizationAttitude = data.organizationAttitude;
          this.Obj.organizationSpeed = data.organizationSpeed;
          this.Obj.serviceCompliance = data.serviceCompliance;
          this.commentTime = data.commentTime;
          this.Obj.content = data.content;
          //头部机构信息
          request(`${baseUrl}/api/v1/service-order/evaluation/orgInfo`, {
            method: "GET",
            params: {orgId: this.Obj.orgId}
          }).then(({code, data}) => {
            if (code == 0) {
              this.ScoreInfo = data;
              this.serOrOrg=data.serOrOrg
              this.agencyPic = data.agencyLogo;
              this.agencyName = data.agencyName;
            }
          });
        }
      });
    }
  }


  //预约单评价
  handleSubmitJudge() {
    if (this.Obj.commentContent == "") {
      message.error("请填写相关评价内容！");
    } else {
      this.Obj.ifAnonymous = this.Obj.ifAnonymous ? 1 : 0;
      reservationCommentAdd({...this.Obj}).then(res => {
        if (res.code === 0) {
          message.success("评论成功！");
          this.judgeModal = true
        } else {
          message.success("评论失败");
        }
      });
    }
  }

  onChange(e) {
    this.Obj.ifAnonymous = e.target.checked;
  }

  /**
   * 机构详情页
   * @param id
   */
  gotoOrgDetail() {
    if (this.Obj.orgId){
      if(!this.serOrOrg){
        this.$router.push({
          path: `/orgDetail/${this.Obj.orgId}`,
        });
      }else{
        this.$router.push({
          path: `/organizationDetail/${this.Obj.orgId}`,
        });
      }
    }

  }

}
</script>

<style lang="less" scoped>
.Tiptip {
  padding-left: 14.7%;
  color: #f00;
  margin-top: -0.1rem;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
}

.judge-body-institution {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;;

  .judge-detail-top {
    margin: 0.2rem 0.3rem;
    display: flex;
    align-items: center;
  }

  .comment-top {
    height: 1.16rem;
    width: 100%;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 0.12rem;
    display: flex;

    .top-bg {
      width: 3.11rem;
      height: 100%;
      background-image: url("./../../../../assets/images/homeImg/组 1188@2x.png");
      background-size: cover;
      background-position: center center;
      display: flex;

      .bg-left {
        cursor: pointer;
        width: 0.68rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 0.47rem;
          height: 0.48rem;
          border-radius: 0.05rem;
        }
      }

      .bg-right {
        width: 2.43rem;
        height: 100%;
        text-align: center;

        .bg-right-header-title {
          height: 0.25rem;
          cursor: pointer;
          font-size: 0.16rem;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 1;
          display: block;
          margin-top: 0.2rem;
          margin-bottom: 0.1rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          padding-left: 0.1rem;
          padding-right: 0.1rem;
        }

        .bg-right-header-rate {
          width: 1.8rem;
          height: 0.19rem;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            font-size: 0.14rem;
            color: #FFFFFF;
            opacity: 1;
          }
        }

        .bg-right-header-item {
          width: 2.02rem;
          height: 0.25rem;
          margin: 0 auto;
          margin-top: 0.06rem;
          border-radius: 0.12rem;
          background: #FFFFFF;
          opacity: 1;
          display: flex;
          align-items: center;

          div {
            min-width: 0.3rem;
            font-size: 0.12rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FF7700;
            opacity: 1;
            margin: 0.14rem 0.1rem;
          }
        }
      }
    }

    .comment-merchant {
      padding-left: 0.3rem;
      display: flex;
      align-items: center;
      /*border: 1px solid red;*/

      span {
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #363C51;
        opacity: 1;
        margin-right: 0.2rem;
      }
    }
  }

  .comment-center {
    width: 100%;
    height: 5.69rem;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 0.12rem;
    margin-top: 0.3rem;
    display: flex;

    .comment-center-left {
      width: 2.75rem;
      height: 100%;

      .rate-body-comment {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .good-img-comment {
          background-color: #dcdcdc;
          margin-top: 0.6rem;
          border-radius: 0.1rem;

          img {
            width: 205px;
            height: 180px;
            border-radius: 0.1rem;
          }
        }

        .good-name-comment {
          width: 1.88rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          font-size: 0.16rem;
          font-weight: 400;
          color: #454F63;
          opacity: 1;
          margin-top: 0.1rem;
          text-align: center;

        }

        .good-price-comment {
          font-size: 0.2rem;
          color: #FF1010;
          opacity: 1;
          margin-top: 0.1rem;
        }

        .good-specification {
          font-size: 0.12rem;
          font-weight: 400;
          color: #78849E;
          opacity: 1;
          margin-top: 0.1rem;
        }
      }
    }

    .comment-center-right {
      width: 9.4rem;
      height: 100%;

      .evaluationTime {
        width: 100%;
        font-size: 0.14rem;
        font-weight: 400;
        color: #78849E;
        opacity: 1;
        /*text-align: center;*/
        margin-top: 0.3rem;
        margin-bottom: 0.33rem;
        padding-left: 1rem;
        border: 1px solid red;
      }

      .judge-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 0.93rem;

        .rate-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 0.21rem;
          /*border: 1px solid red;*/

          .exhibition {
            font-size: 0.14rem;
            width: 6rem;
            resize: none;
            display: inline-block;
          }

          .pin {
            font-size: 0.14rem;
            font-weight: 400;
            color: #363C51;
            opacity: 1;
            display: inline;
            width: 0.9rem;
            margin-right: 0.2rem;
            text-align: right;
          }

          .num {
            font-size: 0.14rem;
            font-weight: 400;
            color: #363C51;
            opacity: 1;
            margin-left: 0.1rem;
          }
        }

        .button {
          width: 100%;
          height: 0.45rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.4rem;

          .btn {
            width: 1.03rem;
            height: 0.4rem;
            background: #39BEB8;
            opacity: 1;
            border-radius: 0.2rem;
            font-size: 0.14rem;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 0.3rem;

            img {
              width: 13.69px;
              height: 13.69px;
              margin-right: 0.05rem;
            }
          }

          .btn:hover {
            opacity: 0.8;
          }


        }
      }
    }
  }
}
</style>



























































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Checkbox, Icon} from "ant-design-vue";
    import GoodsDetail from "@/components/Personal/myGoods/GoodsDetail.vue";

    Vue.use(Icon);
Vue.use(Checkbox);

@Component({
  components: {
    GoodsDetail
  },
  props: {
    status: Number
        }
    })
    export default class ReservationTrashItem extends Vue {
        @Prop() private content!: Object;
        deleteModel: Boolean = false;

        onChange(e, id, ischeck, reservationId) {
            // id, ischeck, serviceOrderInfoId
            if (!ischeck) {
                this.$emit("getOrderInfoList", {
                    id: id,
                    ischeck: ischeck,
                    reservationId: reservationId
                });
            } else {
                this.$emit("deleteOrderInfoList", {
                    id: id,
                    ischeck: ischeck,
                    reservationId: reservationId
                });
            }
        }

        singleDelete() {
            this.deleteModel = true;
        }

        comfrimDelete(reservationId) {
            this.$emit("singleDelete", reservationId);
        }

        singleHuanYuan(reservationId) {
            this.$emit("singleHuanYuan", reservationId);
        }
    }

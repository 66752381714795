
































    import {Component, Vue} from "vue-property-decorator";
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    import {navComponent} from "@/components/mallComponents";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import moment from "moment";
    import "moment/locale/zh-cn";

    moment.locale("zh-cn");
@Component({
  components: {
    Header,
    navComponent,
    Footer
  },
})
export default class App extends Vue {
  locale = zh_CN;
  ifNotCart = true;
  hidePathArr = [
    "/mall/cart",
    "/mall/OrderInfo",
    "/mall/checkstand",
    "/mall/serviceOrderPaySuccess",
  ];
  created() {
    let a = this.hidePathArr.find((item) => location.href.includes(item));
    this.ifNotCart = a ? false : true;
  }
  updated() {
    // let a = this.hidePathArr.find((item) => location.href.includes(item));
    // this.ifNotCart = a ? false : true;
  }
  mounted() {
    this.$router.beforeEach((to:any, from, next) => {
      const newPageRouter = ["Detail"];
      if (newPageRouter.includes(to.name)) {
        open("/mall" + to.fullPath);
        return;
      }
      next();
    });
  }
}




































import {Component, Vue} from "vue-property-decorator";
import {Button, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import ReservationDetailInfo from "@/components/Personal/MyReservation/ReservationDetailInfo.vue";
// getReservationDetail
import {getReservationDetail} from "@/services/api";

Vue.use(Table);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        MyStepBar,
        ReservationDetailInfo,
        Breadcrumb
    }
})
export default class extends Vue {
    // 4是取消
    currentStatus = 0; // 根据状态动态调整currentStatus的值
    content = {};

    stepData = [
        {
            name: "提交预约单",
            time: "",
            img:require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "养老机构接受预约单",
            time: "",
            img:require('@/assets/images/homeImg/flow-6.png'),
        },
        {
            name: "预约单完成",
            time: "",
            img:require('@/assets/images/homeImg/flow-4.png'),
        },
        {
            name: "评价",
            time: "",
            img:require('@/assets/images/homeImg/flow-5.png'),
        }
    ];
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "机构预约单",
            path: "/personal/myReservation",
            significance: 0
        },
        {
            name: "机构预约单详情",
            path: "/personal/reservationDetail",
            significance: 1
        },
    ]

    columns = [
        {
            title: "养老机构名称",
            dataIndex: "agencyName",
            key: "agencyName",
            align: "center",
        },
        {
            title: "养老机构地址",
            dataIndex: "agencyAddress",
            key: "agencyAddress",
            align: "center",
        },
        {
            title: "床位信息",
            dataIndex: "bedNum",
            key: "bedNum",
            scopedSlots: {customRender: "bedNum"},
            align: "center",
        },
        {
            title: "收住对象",
            dataIndex: "serviceOdject",
            key: "serviceOdject",
            align: "center",
        },
        {
            title: "价格",
            dataIndex: "agencyPrice",
            key: "agencyPrice",
            scopedSlots: {customRender: "agencyPrice"},
            align: "center",
        }
    ];

    tableData = [];

    created() {
        let reservationOrderId = this.$route.query.reservationOrderId;
        this.getReservationDetailOne(reservationOrderId);
    }

    getReservationDetailOne(reservationOrderId) {
        getReservationDetail({
            reservationOrderId: reservationOrderId
        }).then(res => {
            let data = res.data;
            this.content = data;
            (this as any).tableData.push(data.agencyInfo);
            switch (data.statusDic) {
                case 239:
                    this.currentStatus = 1;
                    break;
                case 240:
                    this.currentStatus = 2;
                    break;
                case 241:
                    this.currentStatus = 3;
                    if (data.ifEvaluation) this.currentStatus = 4;
                    break;
                case 243:
                    this.currentStatus = 5;
                    break;
                //  239-提交预约单 240-服务商与用户线下已沟通 241-服务结束 243-已取消
            }
            this.stepData[0].time = data.postIntentionTime || "";
            this.stepData[1].time = data.offlineCommunicationTime || "";
            this.stepData[2].time = data.finishTime || "";
            this.stepData[3].time = data.postCommentTime || "";
        });
    }
}

import { render, staticRenderFns } from "./fwsItem.vue?vue&type=template&id=d35fcb4e&scoped=true&"
import script from "./fwsItem.vue?vue&type=script&lang=ts&"
export * from "./fwsItem.vue?vue&type=script&lang=ts&"
import style0 from "./fwsItem.vue?vue&type=style&index=0&id=d35fcb4e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d35fcb4e",
  null
  
)

export default component.exports








































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DoctorDesc extends Vue {
  @Prop() private content!: Array<[]>;
  @Prop() private serviceFeaturePhoto!: String;
  currentImage: Number = 0;

  handleMouseOver(index: Number) {
    this.currentImage = index;
  }
  handleMouseOut() {
    this.currentImage = 0;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receipt-body"},[_c('PersonalHeader',{attrs:{"title":'申请发票'}}),_c('div',{staticClass:"apply-form"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"发票类型"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiptType',
            {
              rules: [
                {
                  required: true,
                  message: '请选择发票类型'
                }
              ]
            }
          ]),expression:"[\n            'receiptType',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择发票类型'\n                }\n              ]\n            }\n          ]"}],attrs:{"options":_vm.typeOption},on:{"change":_vm.handleTypeChange}})],1),_c('a-form-item',_vm._b({attrs:{"label":"发票抬头"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiptName',
            { rules: [{ required: true, message: '请填发票抬头！' }] }
          ]),expression:"[\n            'receiptName',\n            { rules: [{ required: true, message: '请填发票抬头！' }] }\n          ]"}],staticStyle:{"width":"2rem"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"纳税人识别号"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiptName',
            { rules: [{ required: true, message: '请填写纳税人识别号！' }] }
          ]),expression:"[\n            'receiptName',\n            { rules: [{ required: true, message: '请填写纳税人识别号！' }] }\n          ]"}],staticStyle:{"width":"2rem"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"发票内容"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiptContent',
            {
              rules: [
                {
                  required: true,
                  message: '请选择发票内容'
                }
              ]
            }
          ]),expression:"[\n            'receiptContent',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择发票内容'\n                }\n              ]\n            }\n          ]"}],attrs:{"options":_vm.contentOption},on:{"change":_vm.handleTypeChange}})],1),_c('a-form-item',_vm._b({attrs:{"label":"收票人手机"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phone',
            { rules: [{ required: true, message: '请填写收票人手机号！' }] }
          ]),expression:"[\n            'phone',\n            { rules: [{ required: true, message: '请填写收票人手机号！' }] }\n          ]"}],staticStyle:{"width":"2rem"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"收票人邮箱"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mail']),expression:"['mail']"}],staticStyle:{"width":"2rem"}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v("申请")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }


















































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SleepDesc extends Vue {
  // @Prop() private msg!: string
  currentImage: Number = 0;

  handleMouseOver(index: Number) {
    this.currentImage = index;
  }
  handleMouseOut() {
    this.currentImage = 0;
  }
}

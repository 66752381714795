

























































































    import {Component, Vue} from "vue-property-decorator";
    import {Input, message, Modal, Rate, Select, Table, Tabs,} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {
        deleteReservationComment,
        deleteServiceComment,
        fetchReservationComment,
        fetchServiceComment,
    } from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Select);
Vue.use(Rate);
Vue.use(Modal);

@Component({
  components: {
    PersonalHeader,
  },
})
export default class extends Vue {

  // 取消订单Modal显隐
  cancelVisible = false;
  curType = "全部";

  headerSelection = [
    { label: "全部", value: 0 },
    { label: "select1", value: 1 },
    { label: "select2", value: 2 },
  ];
  columns2 = [
    {
      title: "服务信息",
      dataIndex: "information",
      width: "2.87rem",
      align: "center",
      scopedSlots: { customRender: "information" },
    },
    {
      title: "评论内容",
      dataIndex: "content",
      width: "1.53rem",
      align: "center",
    },
    {
      title: "评论得分",
      dataIndex: "rating",
      width: "1.9rem",
      align: "center",
      scopedSlots: { customRender: "rating" },
    },
    {
      title: "时间",
      dataIndex: "time",
      width: "2.02rem",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "ids",
      scopedSlots: { customRender: "ids" },
      align: "center",
    },
  ];
  columns3 = [
    {
      title: "服务信息",
      dataIndex: "information",
      width: "2.87rem",
      align: "center",
      scopedSlots: { customRender: "information" },
    },
    {
      title: "评论内容",
      dataIndex: "content",
      width: "1.53rem",
      align: "center",
    },
    {
      title: "评论得分",
      dataIndex: "rating",
      width: "1.9rem",
      align: "center",
      scopedSlots: { customRender: "rating" },
    },
    {
      title: "时间",
      dataIndex: "time",
      width: "2.02rem",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "ids",
      scopedSlots: { customRender: "ids" },
      align: "center",
    },
  ];

  data2: Array<any> = [];
  data3: Array<any> = [];

  async created() {
    await this.getServiceComment();
    await this.getReservationComment();
  }
  // 获取服务评价
  getServiceComment() {
    fetchServiceComment({
      pageIndex: 1,
      pageSize: 99999999,
    }).then((res: any) => {
      if (res && res.code === 0) {
        this.data2 = [];
        // 处理数据
        res.data.comments.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.information = {
            name: item.serviceName,
            img: item.pic,
          };
          obj.content = item.content;
          obj.rating = item.rating;
          obj.time = item.updateTime;
          obj.ids = {
            serviceId: item.serviceId,
            commentId: item.commentId,
          };
          this.data2.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }
  // 点击删除服务评价
  handleDelServiceComment(value: any) {
    const that = this;
    Modal.confirm({
      title: "删除服务评价",
      content: "删除服务评价后不可恢复，您确定要删除吗？",
      onOk() {
        deleteServiceComment(value).then((res: any) => {
          if (res && res.code === 0) {
            // 处理数据
            message.success("删除成功！");
            that.getServiceComment();
          } else {
            message.error(res.msg);
          }
        });
      },
      onCancel() {

      },
    });
  }
  // 点击删除预约单评价
  handleDelReservationComment(value: any) {
    const that = this;
    Modal.confirm({
      title: "删除预约单评价",
      content: "删除预约单评价后不可恢复，您确定要删除吗？",
      onOk() {
        deleteReservationComment(value).then((res: any) => {
          if (res && res.code === 0) {
            // 处理数据
            message.success("删除成功！");
            that.getReservationComment();
          } else {
            message.error(res.msg);
          }
        });
      },
      onCancel() {

      },
    });
  }
  // 获取预约单评价
  getReservationComment() {
    fetchReservationComment({
      pageSize: 99999999,
      pageIndex: 1,
    }).then((res) => {
      if (res && res.code === 0) {
        this.data3 = [];
        // 处理数据

        res.data.comments.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.information = {
            name: item.agencyName,
            img: item.agencyPic,
          };
          obj.content = item.commentContent;
          obj.rating = item.overallScore;
          obj.time = item.commentTime;
          obj.ids = {
            orderId: item.orderId,
            commentId: item.commentId,
          };
          this.data3.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }
}





































import {Component, Vue} from "vue-property-decorator";
import {Table} from "ant-design-vue";
import {Mutation, State} from "vuex-class";
import {classServie2Detail, classServie31Detail, classServie32Detail} from "@/services/api";

Vue.use(Table);

@Component({
    components: {}
})
export default class extends Vue {
    @State detailItem;
    columns = [
        {
            title: "服务名称",
            dataIndex: "service",
            key: "service",
            align: "center",
            width: "3.3rem",
            scopedSlots: {customRender: "service"}
        },
        {
            title: "单价",
            dataIndex: "price",
            key: "price",
            align: "center"
        },
        {
            title: "数量",
            dataIndex: "count",
            key: "count",
            align: "center"
        },
        {
            title: "优惠",
            dataIndex: "discount",
            key: "discount",
            align: "center"
        },
        {
            title: "服务方式",
            dataIndex: "serviceStyle",
            key: "serviceStyle",
            align: "center"
        },
        {
            title: "服务状态",
            dataIndex: "state",
            key: "state",
            align: "center"
        },
        {
            title: "服务操作",
            dataIndex: "content",
            key: "content",
            align: "center",
            scopedSlots: {customRender: "content"}
        }
    ];

    getDetail(relationId, service) {
    }

    data() {
        return {
            tableData: []
        };
    }

    mounted() {
        let that: any = this;
        this.detailItem.services.forEach((el, i) => {
            if (el.skuInfo != null) {
                //服务规格列表拆开
                el.skuInfoList.forEach(el => {
                    const mapItem = el.skuValue
                    let cc = typeof (mapItem);
                    if (cc === 'string') {
                        let str = mapItem.replaceAll('[', '').replaceAll(']', '');
                        let itemArr = str.split(', ');
                        el.skuValue = itemArr;
                    }
                })
                //当前服务规格拆分
                let str = el.skuInfo.replaceAll('', '');
                let itemArrOne = str.split(' ');
                el.skuInfo = itemArrOne;
            }
            let newObj = {
                key: i,
                service: {
                    serviceName: el.serviceName,
                    serviceImg: el.servicePic,
                    serviceId: el.serviceId,
                    skuInfo: el.skuInfo,
                    skuInfoList: el.skuInfoList,
                },
                price: el.price,
                count: el.quantity,
                discount: (0).toFixed(2),
                state: el.serviceStatus,
                serviceStyle: el.serviceStyle,
                content: el
            };

            that.tableData.push(newObj);

        });
    }

    serviceDetailEvent(relationId: String, serviceType: Number, data) {

        let callback: (params: any) => Promise<any>,   // 根据不同的服务类型去请求不同的接口
            switchServiceStatus: Function, // 判断不同的状态
            hrefUrl: string; // 根据不同的服务类型跳转到不同的地址
        // 判断类型
        switch (serviceType) {
            case 432: // 纯线上服务（2类） 2类服务订单详情
                callback = classServie2Detail;
                hrefUrl = '/personal/classServiceTow';
                switchServiceStatus = (currStatus: string) => {
                    switch (currStatus as any) {
                        case "等待开启":
                            this.classServiceTwoStep["step1"] = true;
                            this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
                            break;
                        case "服务开启中":
                            this.classServiceTwoStep["step2"] = true;
                            this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
                            break;

                        case "服务完成":
                            this.classServiceTwoStep["step3"] = true;
                            this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
                            break;
                        case "服务开启完成":
                            this.classServiceTwoStep["step3"] = true;
                            this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
                            break;
                        case "已关闭":
                            this.classServiceTwoStep["step4"] = true;
                            this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
                    }
                }
                break;
            case 433: // 3.1类服务订单详情 线上线下相结合的服务（3.1类） 无需对接接口 如家政
                callback = classServie31Detail;
                hrefUrl = '/personal/classServiceOne';
                switchServiceStatus = (currStatus: string) => {
                    switch (currStatus as any) {
                        case "等待服务":
                            this.classServiceOneStep["step1"] = true;
                            this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            break;
                        case "服务进行中":
                            this.classServiceOneStep["step2"] = true;
                            this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            break;
                        case "服务完成":
                            this.classServiceOneStep["step3"] = true;
                            this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            break;

                        case "已关闭":
                            this.classServiceOneStep["step4"] = true;
                            this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                    }
                }
                break;
            case 434: //3.2类订单详情 线上线下相结合但需要对接接口（3.2 类） 如体检
                callback = classServie32Detail;
                hrefUrl = '/personal/ServiceProcessDetail';
                switchServiceStatus = (currStatus: string) => {
                    switch (currStatus as any) {
                        case "等待开启":
                            this.classServiceThreeStep["step1"] = true;
                            this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
                            break;
                        case "服务开启中":
                            this.classServiceThreeStep["step2"] = true;
                            this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
                            break;
                        case "服务开启完成":
                            this.classServiceThreeStep["step5"] = true;
                            this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
                            break;
                        case "已关闭":
                            this.classServiceThreeStep["step6"] = true;
                            this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
                            break;
                    }
                }
                break;
            default:
                callback = (params: any) => {
                    return new Promise((resolve, reject) => {
                        reject('服务类型错误');
                    });
                }
        }

        // 请求服务订单详细数据
        callback({
            relationId: relationId,
        }).then((res) => {
            if (res) {
                let data: any;
                data = (res as any).data;
                data.serviceType = serviceType; // 类型也一起存进去
                // 存储数据
                this.SET_CLASSSERVICEDETAIL(data || {});
                // 判断不同的状态
                switchServiceStatus(data.serviceStatus);
                // 跳转
                window.open(
                    this.$router.resolve(hrefUrl).href,
                    "_blank"
                );
            }
        });

    }

    @Mutation SET_CLASSSERVICEDETAIL;
    currStatus: "";

    classServiceTwoStep = {
        step1: false,
        step2: false,
        step3: false,
        step4: false
    };
    classServiceThreeStep = {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false
    };
    classServiceOneStep = {
        step1: false,
        step2: false,
        step3: false,
        step4: false
    };
    @Mutation SET_CLASSSERVICETHREESTEP;
    @Mutation SET_CLASSSERVICEONESTEP;
    @Mutation SET_CLASSSERVICETWOSTEP;

}

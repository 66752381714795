
































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Tabs, Input, Icon, List, Checkbox, Tooltip } from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ReservationTrashItem from "@/components/Personal/MyReservation/ReservationTrashItem.vue";
import ReservationTrashCanItem from "./reservationListTrashCanItem.vue";
Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
  components: {
    PersonalHeader,
    ReservationTrashItem,
    ReservationTrashCanItem
  }
})
export default class extends Vue {
  list = ["全部预约", "已预约", "已完成", "已取消", "待评价"];
  data = [
    {
      id: 1,
      status: 1,
      orderTime: "2019-11-20 175308",
      orderNum: "101643891418",
      storeName: "老年旗舰店",
      customer: "白桂金",
      totalPrice: 1065.01,
      payMode: "在线支付",
      remainingHour: 22,
      remainingMin: 8,
      content: "欧姆龙(OMRON)电子血压计 家用HEM-712(上臂式)",
      count: 3
    }
  ];
  onSearch() {}
}
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {baseUrl} from "../../../services/http"
import {aftersalesApplicationStatus} from "@/services/api";

@Component({
    props: {
        content: String,
        count: Number,
        status: Number,
        item: Object,
        currentStatus: Number,
        allcontentList: Object
    }
})
export default class GoodsDetail extends Vue {
    // @Prop() private msg!: string
    @Prop() public commodityList!: any;
    baseUrl = baseUrl;


    //退款退货、申请售后
    cancelOrder(value) {
        if (value.orderStatus === 262) {
            const {href} = this.$router.resolve({
                path: "/personal/ReturnGoodsOne",
                query: {
                    // commodityRelationId: JSON.stringify(value.orderCommodityId)
                    commodityRelationId: value.orderCommodityId
                }
            });
            window.open(href, "_blank");

        } else {
            const {href} = this.$router.resolve({
                path: "/personal/AskBuyAfter",
                query: value
                // {
                //         // params: JSON.stringify(value)
                //         // params: JSON.stringify(value)
                //     }
            });
            window.open(href, "_blank");
        }

    }

    //商品详情
    details(value) {
        open('/#/mall/detail/' + value.commodityId);
    }

    //查看退款
    seeRefund(value) {
        aftersalesApplicationStatus({returnId: value.refundId}).then(res => {
            //0是退款，1是退换货
            if (value.refundTypeClaim === 0) {
                if (res.data.statusDic === 269 || res.data.statusDic === 725) {
                    this.$router.push({
                        path: "/personal/ReturnGoodsTwo",
                        query: {
                            commodityRelationId: value.orderCommodityId,
                            returnId: value.refundId,
                        }
                    });
                } else if (res.data.statusDic === 270 || res.data.statusDic === 271) {
                    this.$router.push({
                        path: "/personal/ReturnGoodsThree",
                        query: {
                            params: value,
                            commodityRelationId: value.orderCommodityId,
                            returnId: value.refundId
                        }
                    });
                }
            } else if (value.refundTypeClaim === 1) {
                if (res.data.statusDic === 269 || res.data.statusDic === 725 || res.data.statusDic === 728) {
                    this.$router.push({
                        path: "/personal/ReturnGoods2",
                        query: {
                            commodityRelationId: value.orderCommodityId,
                            returnId: value.refundId,
                        }
                    });
                } else if (res.data.statusDic === 726 || res.data.statusDic === 727) {
                    this.$router.push({
                        path: "/personal/ReturnGoods3",
                        query: {
                            commodityRelationId: value.orderCommodityId,
                            returnId: value.refundId,
                            disposeStatus: res.data.disposeStatus
                        }
                    });
                } else if (res.data.statusDic === 270 || res.data.statusDic === 271) {
                    this.$router.push({
                        path: "/personal/ReturnGoods4",
                        query: {
                            commodityRelationId: value.orderCommodityId,
                            returnId: value.refundId,
                            statusDic: res.data.statusDic,
                        }
                    });
                }
            }
        });

    }
}

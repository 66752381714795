












































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ProcessItem from "./ProcessItem.vue";

@Component({
  components: {
    ProcessItem
  },
  props: {
    ProcessType: Number
  }
})
export default class Process extends Vue {
  // @Prop() private msg!: string
}

























































import {Component, Vue, Watch} from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import YlmsHeader from "@/components/YlmsHeader.vue";
import YlmsItem from "@/components/YlmsItemTwo.vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";
import Process from "@/components/Process.vue";
import {Breadcrumb, Pagination} from "ant-design-vue";
import {fetchYlmsDetail, singleYLMS} from "../services/api";
import {OrgList} from "../components/Ylms.vue";
import Ylorganization from "@/components/YlorganizationTwo.vue";

Vue.use(Breadcrumb);
Vue.use(Pagination);

@Component({
    components: {
        Header,
        Footer,
        Nav,
        YlmsHeader,
        YlmsItem,
        InstitutionInfo,
        Process,
        Ylorganization
    },
    data() {
        return {};
    }
})
export default class PensionInstitution extends Vue {
    pageContent: OrgList[] = [];

    pagination = {
        pageNum: 1,
        pageSize: 10,
        total: 0
    };

    id = "206";

    header = {};
    flow: any = [];

    mounted() {
        const city = parseInt(localStorage.getItem("provinceId") || "2", 10);
        const dicId = this.$route.params.dicId;

        this.id = String(dicId);
        const params = {
            pageSize: 10,
            pageIndex: 1,
            cityId: 2,
            formDicId: dicId
        };
        this.getYlmsDetail(params);
        this.soleYLms(dicId)
        this.flow = [
            {title: '第一步 选择养老机构', details: '用户根据实际的养老需求选择对应的养老机构。'},
            {title: '第二步 预约服务', details: '用户根据实际情况填写预约单，提交预约单给养老机构。'},
            {title: '第三步 电话沟通', details: '养老机构根据预约单信息及时联系用户进行沟通，确认用户基本信息以及具体需求'},
            {title: '第四步 养老机构评估', details: '养老机构对用户能否满足入住条件进行评估，以便明确相应的护理等级，提供更好的照护服务'},
            {title: '第五步 养老机构反馈', details: '养老机构对用户完成入住评估后，养老机构会尽快联系用户并告知入住评估结果。'},
        ]
    }

    @Watch("$route")
    onChangeValue(to: any, from: any) {
        this.id = to.params.dicId;

        this.pageContent.length = 0;
        const city = parseInt(localStorage.getItem("provinceId") || "2", 10);

        const params = {
            pageSize: 10,
            pageIndex: 1,
            cityId: city,
            formDicId: to.params.dicId
        };

        this.getYlmsDetail(params);
    }

    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});

        const temp = {
            ...this.pagination,
            pageNum: page,
            pageSize
        };
        this.pagination = temp;

        const dicId = parseInt(
            window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1,
                window.location.href.length
            )
        );

        const params = {
            pageSize: this.pagination.pageSize,
            pageIndex: this.pagination.pageNum,
            cityId: parseInt(localStorage.getItem("provinceId") || "2", 10),
            formDicId: dicId
        };
        this.getYlmsDetail(params);
    }

    //根据id获取单一养老模式
    soleYLms(value) {
        singleYLMS(value).then((res) => {
            if (res.code === 0) {
                this.header = res.ylform;
            }
        });
    }

    getYlmsDetail(params: any) {
        fetchYlmsDetail(params).then(res => {
            this.pageContent = [];
            if (res.data && res.data.orgInfoList) {
                res.data.orgInfoList.forEach((item: OrgList) =>
                    this.pageContent.push(item)
                );
                this.pagination = {
                    ...this.pagination,
                    total: res.data.totalCount
                };
            }
        });

    }

}

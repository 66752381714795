



































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button, Icon, Tabs, Table } from "ant-design-vue";
import { VNode } from "vue";

Vue.use(Button);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(Table);

@Component({
  components: {},
})
export default class extends Vue {
  pagination = {
    total: 3,
    showTotal: (total: number) => `共${total}条记录`,
    itemRender: (current: any, type: string, originalElement: any): any => {
      if (type === "prev") {
        return "< 上一页";
      } else if (type === "next") {
        return "下一页 >";
      }
      return originalElement;
    },
  };
  columns = [
    {
      title: "时间",
      dataIndex: "time",
      align: "center",
    },
    {
      title: "金额",
      dataIndex: "money",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "do",
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "other",
      align: "center",
    },
  ];
  data = [
    // {
    //   key: "1",
    //   time: "2019-11-9 11:53:59",
    //   money: "+11.20",
    //   do: "转入",
    //   other: "这是备注信息"
    // },
    // {
    //   key: "2",
    //   time: "2019-11-9 11:53:59",
    //   money: "+11.20",
    //   do: "转入",
    //   other: "这是备注信息"
    // },
    // {
    //   key: "3",
    //   time: "2019-11-9 11:53:59",
    //   money: "+11.20",
    //   do: "转入",
    //   other: "这是备注信息"
    // }
  ];
}

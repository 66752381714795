<template>
    <div v-if="showPage" class="billingDetails">
        <payHeader/>
        <div id="billingDetails-top">
            <div id="billingDetails-header">
                <div class="img-span">
                    <img src="../assets/images/homeImg/dlLogo.png" alt @click="jumpToHome"/>
                    <span></span>
                </div>
                <div class="billingDetails-input">
                    <div class="home_input">
                        <!--                        <a-input-search-->
                        <!--                                :value="searchVal"-->
                        <!--                                @change="onSearchChange"-->
                        <!--                                @search="onSearch"-->
                        <!--                                placeholder="请输入您想找的服务商或服务项目"-->
                        <!--                                enter-button-->
                        <!--                        />-->
                    </div>
                </div>

            </div>
        </div>


        <div class="serviceOrder">
            <div class="top-tis">
                <div class="top-tis-title">填写并核对服务订单信息</div>
                <div class="top-tis-flow">
                    <closeReturnStep :curStep="1" :stepList="stepList"/>
                </div>
            </div>
            <div class="serviceOrder-center">
                <a-tabs v-model="activeType" @change="activeTypeChange(activeType)">
                    <a-tab-pane key="1" tab="非到店服务">
                        <div class="servePeople">
                            <div class="servePeople-top">
                                <div class="address-tip">服务人信息</div>
                                <a-button class="address-button typeface14 set-btn-content" @click="addAddressNew">
                                    新增服务地址<img src="../assets/images/homeImg/add.png"/>
                                </a-button>
                            </div>
                            <div class="servePeople-center">
                                <div class="address" v-for="(item,index) of showAddressList" :key="index"
                                     @click="oneChange(item)"
                                     :class="choiceList===item?'addressClick':'addressNotClick'">
                                    <div class="divImg">
                                        <img src="../assets/images/homeImg/组 1286@2x.png"/>
                                    </div>
                                    <div class="divMessage">
                                        <div class="default">
                                            <div v-if="item.isDefault ===1"></div>
                                            <span v-if="item.isDefault ===1">默认</span>
                                        </div>
                                        <div class="detailed-information">
                                            <p class="p1">{{ item.receiverName }} <span>{{ item.phoneNumber }}</span>
                                            </p>
                                            <p class="p2"
                                               :title="item.receiverProvinceName+ item.receiverCityName+ item.receiverAreaName+ item.receiverStreetName+ item.receiverDetailAddress">
                                                {{ item.receiverProvinceName }} {{ item.receiverCityName }} {{
                                                item.receiverAreaName }} {{ item.receiverStreetName }} {{
                                                item.receiverDetailAddress }}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="evenMore">
                                <p v-if="serviceAddressList.length > 3">
                                    <span v-if="showAddressList.length == 3"
                                          @click="showAddressList = serviceAddressList">更多服务地址<a-icon
                                            style="margin-left: 0.05rem" type="down"/></span>
                                    <span v-if="showAddressList.length > 3" @click="packUp">收起更多服务地址<a-icon
                                            style="margin-left: 0.05rem" type="up"/></span>
                                </p>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="到店服务">
                        <div class="servePeople">
                            <div class="servePeople-top">
                                <div class="address-tip">门店信息</div>
                            </div>
                            <div class="servePeople-center">
                                <div class="address" v-for="(item,index) of shopListShow" :key="index"
                                     @click="oneChangeTwo(item)"
                                     :class="singleAddress===item?'addressClick':'addressNotClick'">
                                    <div class="divImg">
                                        <img src="../assets/images/homeImg/组 1286@2x2.png"/>
                                    </div>
                                    <div class="divMessage2">
                                        <div class="detailed-information">
                                            <div>
                                                <p class="p1">{{ item.storeName}}</p>
                                                <p class="p2" :title="item.fullAddress">{{ item.fullAddress }} </p>
                                            </div>
                                        </div>
                                        <div class="detailed-phone">
                                            <span>{{item.contactPhone }}</span>
                                            <span>{{item.businessTime}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="evenMore">
                                <p v-if="shopList.length > 3">
                                    <span v-if="shopListShow.length == 3" @click="shopListShow = shopList">更多门店地址<a-icon
                                            style="margin-left: 0.05rem" type="down"/></span>
                                    <span v-if="shopListShow.length > 3" @click="shopPackUp">收起更多门店地址<a-icon
                                            style="margin-left: 0.05rem" type="up"/></span>
                                </p>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
            <div class="top-tis">
                <div class="top-tis-title">服务清单</div>
            </div>
            <div class="detailedList">
                <div class="DetailsListing">
                    <div class="DetailsListing-top">服务商: {{ serviceOrder.storeName }}</div>
                    <div class="DetailsListing-header">
                        <div class="item" :style="{ width: '4.05rem' }">
                            <span>服务方式</span>
                        </div>
                        <div class="item" :style="{ width: '3.62rem' }">
                            <span>服务名称</span>
                        </div>
                        <div class="item" :style="{ width: '1.21rem' }">
                            <span>服务价格</span>
                        </div>
                        <div class="item" :style="{ width: '1.21rem' }">
                            <span>数量</span>
                        </div>
                        <div class="item" :style="{ width: '1.21rem' }">
                            <span>服务总价</span>
                        </div>
                    </div>
                    <div class="detailsSettlementServices">
                        <settlementList :serviceOrder="serviceOrder"  :amount="amount" @addAmount="addAmount"
                                        @minusAmount="minusAmount" @serviceRemarkCli="serviceRemarkCli" :totalAmount="totalAmount"/>
                    </div>
                </div>

            </div>

            <div class="ServiceSettlementInformation">
                <div class="message-1">
                    <div class="message-left">支付方式</div>
                    <div class="message-right">
                        <div class="pay" @click="payTypeChange(285)">在线支付</div>
                    </div>
                </div>
                <div class="message-2">
                    <div class="message-left">发票信息</div>
                    <div class="message-right">
                        <div class="checked">
                            <a-checkbox :checked="fapiao" @change="onChange"><span>需要开发票</span></a-checkbox>
                            <div class="checkbox-name" v-if="invoiceState"><span style="margin-right: 0.05rem">{{invoiceTitleType!=0?this.receiverEmail:''}}</span>
                                <span style="margin-right: 0.05rem">{{invoiceType==1?'普通发票':'增值税专用发票'}}</span> <span>{{invoiceTitleType==0?'个人/':''}}{{invoiceContent==0?'服务明细':'服务类别'}}</span>
                                <span class="upd"
                                      @click="() => (this.makeOutCheque = true,this.invoiceState=false)">修改</span></div>
                        </div>

                    </div>
                </div>
                <div class="message-3">
                    <div class="message-3-top" v-if="!showOrderDetail">优惠券信息<img
                            src="../assets/images/homeImg/组 1945@2x.png"/> <span
                            style="font-size: 0.14rem;color:#454F63;margin-left: 0.1rem;opacity: 1;font-weight:400"> 暂无可用优惠券</span>
                    </div>
                    <div class="message-3-top" hidden v-if="!showOrderDetail" @click="showOrderDetail= true">优惠券信息<img
                            src="../assets/images/homeImg/组 1945@2x.png"/></div>
                    <div class="message-3-top" @click="showOrderDetail = false" v-if="showOrderDetail">优惠券信息<img
                            class="img2" src="../assets/images/homeImg/组 1945@2x.png"/></div>
                    <div class="message-3-bottom" v-if="showOrderDetail">
                        <div class="message-3-type" v-if="conditionNumber!=1">
                            <span @click="condition(1)" :class="conditionNumber===1?'conditionTis':''">可用</span>
                            <span @click="condition(2)" :class="conditionNumber===2?'conditionTis':''">不可用</span>
                        </div>
                        <div class="discountCoupon" v-if="conditionNumber==1">
                            暂无可用优惠券
                            <!--                             <div class="discountCoupon-list"></div>-->
                            <!--                             <div class="discountCoupon-list"></div>-->
                        </div>
                        <div class="discountCoupon" v-else>
                            暂无可用优惠券
                            <!--<div class="notApplicable"></div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="settlementData">
                <div class="div1">
                    <div><span style="color: #39BEB8">{{amount}}</span> 项服务，总服务金额：</div>
                    <div>总服务费：</div>
                    <!--                    <div>服务优惠：</div>-->
                </div>
                <div class="div2">
                    <!--                    {{amount*serviceOrder.servicePrice}}-->
                    <div>￥ {{ amount*serviceOrder.servicePrice | formatMoney }}</div>
                    <div>￥ {{(+amount * serviceOrder.serviceStyleList[0].serviceFee)| formatMoney}}</div>
                    <!--                    <div>￥ 0.00</div>-->
                </div>
            </div>
            <div class="windUpAccount">
                <div class="windUpAccount-center">
                    <div>应付金额 <span style="margin-left: 0.05rem">￥</span><span class="amount-payable">{{totalAmountAll | formatMoney }}</span>
                    </div>
                    <div v-if="activeType == '1' && currAddress['receiverProvinceName']">
                        <span>服务地址： {{ currAddress["receiverProvinceName"] }}{{ currAddress["receiverCityName"] }}{{ currAddress["receiverAreaName"] }}{{ currAddress["receiverStreetName"] }}{{ currAddress["receiverDetailAddress"] }}</span>
                        <span style="margin-left: 0.05rem">服务人：{{ currAddress["receiverName"] }}   {{ currAddress["phoneNumber"] }}</span>
                    </div>
                    <div v-if="activeType == '2'">
                        <span v-show="singleAddress.storeId"
                              style="margin-right: 0.1rem">{{ singleAddress.storeName }}</span>
                        <span v-show="singleAddress.storeId" style="margin-right: 0.1rem">{{ singleAddress.fullAddress }}</span>
                        <span v-show="singleAddress.storeId" style="margin-right: 0.1rem">{{ singleAddress.contactPhone }}</span>
                        <span v-show="singleAddress.storeId">{{ singleAddress.businessTime }}</span>
                    </div>
                </div>
            </div>
            <div class="paymentButton">
                <a-button class="button" @click="serviceOrderSettlement">提交订单</a-button>
            </div>
        </div>
        <div class="otherServices" v-if="false">
            <div class="otherServices-title">以下服务项目与所选择服务方式不一致，请先结算符合当前服务方式的服务项目，结算后请通过“立即购买”下单，或者通过服务箱重新下单 。</div>
            <div class="service-details-two">
                <div class="header">
                    <div class="item" :style="{ width: '3.62rem' }">
                        <span>服务项目</span>
                    </div>
                    <div class="item" :style="{ width: '1.9rem' }">
                        <span>服务方式</span>
                    </div>
                    <div class="item" :style="{ width: '1.16rem' }">
                        <span>服务金额</span>
                    </div>
                    <div class="item" :style="{ width: '1.33rem' }">
                        <span>服务费</span>
                    </div>
                    <div class="item" :style="{ width: '2.05rem' }">
                        <span>备注</span>
                    </div>
                    <div class="item" :style="{ width: '1.23rem' }">
                        <span>应付金额</span>
                    </div>
                </div>
                <div class="detailsSettlementServices" v-for=" item of 1" :key="item">
                    <div class="detailsSettlementServices-top">服务商: 天津大学</div>
                    <div class="service-details" v-for="item of 1" :key="item">

                        <div class="DetailsTheData">
                            <div class="table-data">
                                <div class="name">
                                    <div class="detail-img">
                                        <img src="../../src/assets/images/app.png"/>
                                    </div>
                                    <div class="detail-content">
                                        <div class="span1 typeface14">上门做饭，四菜一汤一汤一汤 汤一汤，川菜</div>
                                        <div class="span2">
                                            <span>【mm320】白色</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pattern" style="width: 1.9rem;">到店服务</div>
                                <div class="pattern" style="width: 1.16rem;">399.00</div>
                                <div class="pattern" style="width: 1.33rem;">0.00</div>
                                <div class="pattern" style="width: 2.05rem">请通过服务详情重新下单</div>
                                <div class="totalPrices" style="width: 1.23rem;">48.00</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>

        <a-modal width="800px" style="top: 200px;" v-model="visible2" @ok="handleOk">
            <template slot="title">
                <div class="modal-title">
                    <p style="fontSize: 20px;color:#333333;">
                        <i/>新增服务信息
                    </p>
                </div>
            </template>
            <div id="addAddressBox">
                <a-form :form="form" @submit="handleSubmit">
                    <a-form-item v-bind="formItemLayout" label="服务地区">
                        <a-cascader
                                :options="options"
                                style="width: 6rem;font-size:0.16rem"
                                placeholder="服务地区"
                                :loadData="loadData"
                                v-decorator="[
                'address',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务地区'
                    }
                  ]
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="详细地址">
                        <a-input
                                style="width: 6rem;"
                                v-decorator="[
                'receicerDetailAddress',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写详细地址！'
                    }
                  ]
                }
              ]"
                        />
                    </a-form-item>

                    <a-form-item v-bind="formItemLayout" label="邮政编码">
                        <a-input style="width: 6rem;" v-decorator="['zipCode']"/>
                    </a-form-item>

                    <a-form-item v-bind="formItemLayout" label="联系人">
                        <a-input
                                style="width: 6rem;"
                                v-decorator="[
                'receiverName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写联系人！'
                    }
                  ]
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="联系电话">
                        <a-input
                                style="width: 6rem;"
                                v-decorator="[
                'phoneNumebr',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写联系电话！'
                    }
                  ]
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout">
                        <!-- <a-input style="width: 4rem;" v-decorator="['zipCode']" /> -->
                        <a-checkbox v-decorator="['isDefaut']">设置为默认服务信息</a-checkbox>
                    </a-form-item>
                </a-form>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <a-button key="back" style="width:135px;" @click="handleOk">取消</a-button>
                    <a-button key="submit" style="width:135px;" type="primary" @click="handleSubmit">保存</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal width="6.3rem" v-model="makeOutCheque" @click="() => (this.makeOutCheque = false)">
            <template slot="title">
                <div class="modal-header">
                    <span>发票信息</span>
                </div>
            </template>
            <div class="modal-invoice-center">
                <div v-if="makeOutCheque" class="form-box">
                    <dl>
                        <dd>
                            <span>发票类型</span>
                            <div class="InvoiceBtn">
                                <div :class="invoiceType===1?'InvoiceType':'InvoiceType2'" @click="InvoiceType(1)">
                                    普通发票
                                </div>
                                <div :class="invoiceType===2?'InvoiceType':'InvoiceType2'" @click="InvoiceType(2)">
                                    增值税专用发票
                                </div>
                            </div>

                        </dd>
                        <dd>
                            <span>发票内容</span>
                            <div class="InvoiceBtn">
                                <div :class="invoiceContent===0?'InvoiceType':'InvoiceType2'"
                                     @click="invoiceContentClick(0)">服务明细
                                </div>
                                <div :class="invoiceContent===1?'InvoiceType':'InvoiceType2'"
                                     @click="invoiceContentClick(1)">服务类别
                                </div>
                            </div>
                        </dd>
                        <dd>
                            <span></span>
                            <div class="InvoiceDetails">发票内容将显示详细商品名称与价格信息</div>
                        </dd>
                        <dd>
                            <span>抬头类型</span>
                            <a-radio-group v-model="invoiceTitleType" @change="radioChange">
                                <a-radio :value="0" style="font-size:0.14rem;color: #454F63;">个人</a-radio>
                                <a-radio :value="1" style="font-size:0.14rem;color: #454F63;">单位</a-radio>
                            </a-radio-group>
                        </dd>
                        <dd v-if="invoiceTitleType == 1"><span><span style="color:#f00">*</span>发票抬头</span>
                            <a-input style="width: 3rem;height:0.35rem" @input="activeName" v-model="receiverEmail"/>
                            <span style="color:#FF5242;font-size: 0.13rem;margin-left: 0.1rem">{{reminder}}</span>
                        </dd>
                        <dd v-if="invoiceTitleType == 1"><span><span style="color:#f00">*</span> 纳税人识别号</span>
                            <a-input style="width: 3rem;height:0.35rem" @input="activeName2" v-model="taxpayerId"/>
                            <span style="color:#FF5242;font-size: 0.13rem;margin-left: 0.1rem">{{reminder2}}</span>

                        </dd>
                        <dd v-else><span>个人发票抬头</span>
                            <a-input style="width: 3rem;height:0.35rem" v-model="personage"/>

                        </dd>
                    </dl>
                </div>
                <div class="presentation">
                    <p>1. 电子普通发票为增值税普通发票，报销、保存更便捷，并支持下载、打印，应国家税务总局报销政策要求， 开具企业抬头发票请填写纳税人识别号或统一社会信用代码。</p>
                    <p> 2. 电子普通发票是税务机关认可的有效收付款凭证，与纸质普通发票具有同等法律效力，可用于报销入账、售 后维权等。</p>
                </div>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="saveInvoice">
                        <span><img src="../assets/images/homeImg/yes.png"/>保存</span>
                    </div>
                    <div class="cancel-button" @click="() => (this.makeOutCheque = false)">
                        <span><img src="../assets/images/homeImg/no.png"/>取消</span>
                    </div>
                </div>
            </template>
        </a-modal>
        <a-modal
                :footer="null"
                :visible="visible"
                @cancel="visible = false"
                :width="'6.7rem'"
        >
            <template slot="title">
                <div class="modal-header">
                    <span>新增服务地址信息</span>
                </div>
            </template>
            <fuWuLocationAdd
                    v-if="visible"
                    :editData="editData"
                    :editType="editType"
                    @addSuccess="addSuccess"
                    @cancelSave="visible = false"
            />
        </a-modal>
        <a-modal
                :keyboard="maskClosable"
                :maskClosable="maskClosable"
                :closable="maskClosable"
                :footer="null"
                :visible="visibleTwo"
                @cancel="visibleTwo = false"
                :width="'6.7rem'"
        >
            <template slot="title">
                <div class="modal-header">
                    <span>新增服务地址信息</span>

                </div>
            </template>
            <fuWuServiceOrderLocationAdd
                    v-if="visibleTwo"
                    :editData="editData"
                    :editType="editType"
                    @addSuccessTwo="addSuccessTwo"
                    @cancelSave="visibleTwo = false"
            />
        </a-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    // import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import payHeader from "@/components/payHeader";
    import fuWuLocationAdd from "@/components/Personal/fuWuLocationAdd";
    import fuWuServiceOrderLocationAdd from "@/components/fuWuServiceOrderLocationAdd";
    import settlementList from "@/components/settlementList";
    import {Button, Cascader, Checkbox, Form, Input, message, Modal, Select, Upload,} from "ant-design-vue";
    import closeReturnStep from "@/components/closeReturnStep.vue";
    import {
        addAddress,
        fetchAddrCascader,
        fetchDetailByServiceId,
        fetchOrderSettlement,
        getServiceAddressList,
        saveTheInvoice,
        userdaibi
    } from "@/services/api";
    import {baseUrl, request} from "@/services/http";
    import AMapLoader from "@amap/amap-jsapi-loader";

    Vue.use(Checkbox);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Cascader);
    Vue.use(Upload);
    Vue.use(Modal);

    export default {
        components: {
            // Nav,
            Footer,
            payHeader,
            closeReturnStep,
            fuWuLocationAdd,
            settlementList,
            fuWuServiceOrderLocationAdd
        },
        data() {
            return {
                showPage: false,
                maskClosable: false,
                showTpbsaj: true,
                showOrderDetail: false,
                tokenInformation: false,
                invoiceState: false,
                visibleTwo: false,
                availableDaibi: 0,
                choiceList: [],
                singleAddress: [],
                conditionNumber: 1,
                searchVal: "",
                invoiceId: '',
                storeId: '',
                reminder: '',
                reminder2: '',

                currAddress: {
                    receiverProvinceName: "",
                    receiverCityName: "",
                    receiverAreaName: "",
                    receiverStreetName: "",
                    receiverDetailAddress: "",
                    receiverName: "",
                    phoneNumber: "",
                },
                serviceOrder: {},
                haveInvoice: 0,
                serviceRemark: "",
                serviceId: "",
                skuInfoId: '',
                visible: false,
                payType: 285,
                invoiceTitleType: 0,
                invoiceContent: 0,
                invoiceTitle: "个人",
                receiverEmail: "",
                personage: "",
                taxpayerId: "",
                amount: 0,
                // totalAmount: 95.0,
                tokenAmount: 11.2,
                isChecked: 0,
                fapiao: false,
                makeOutCheque: false,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 5},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 19},
                    },
                },
                stepList: [
                    {
                        name: "选购服务项目",
                    },
                    {
                        name: "填写服务订单",
                    },
                    {
                        name: "完成服务订单",
                    },
                ],
                options: [],

                radioStyle: {
                    display: "block",
                    height: "0.3rem",
                    lineHeight: "0.3rem",
                    fontSize: "0.16rem",
                    color: "#737373",
                },
                selectlist: {myAddress: "", shopAddress: ""},
                showAddressList: [],
                activeType: "1",
                serviceAddressList: [],
                useDaibiBool: false,
                invoiceType: 1,
                shopListShow: [],
                shopList: [],
                selectShop: {},
                invoiceTitleChangeBool: false,
                AMap: undefined,
                editType: 0,
                editData: {},
                visible2: false,


                serviceProviders: [], // 服务商的信息

            };

        },
        watch: {},
        computed: {

            totalAmount() {
                return (
                    this.serviceOrder.servicePrice * +this.amount +
                    +this.amount * this.serviceOrder.serviceStyleList[0].serviceFee
                );
            },
            totalAmountAll() {
                return (
                    this.serviceOrder.servicePrice * +this.amount +
                    +this.amount * this.serviceOrder.serviceStyleList[0].serviceFee -
                    (this.useDaibiBool ? +this.availableDaibi : 0)
                );
            },
        },
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        created() {
            let serid = this.$route.query.serviceId;

            let skuInfoId = this.$route.query.skuInfoId;
            this.skuInfoId = skuInfoId;
            request(`${baseUrl}/api/v1/services/settlement/special/${serid}`, {method: "post",}).then((res) => {
                let orderCode = res.orderCode;
                if (res.code == 601) {
                    let serid = this.$route.query.serviceId;
                    let mobile_1 = "1";

                    let ser_url;
                    let token_1;
                    let userInfo1 = localStorage.getItem("userInfo");
                    token_1 = JSON.parse(userInfo1).token;
                    request(`${baseUrl}/api/v1/api-services/zaixian/serviceType_forNextStep_byServiceId`, {
                        method: "get",
                        params: {
                            serviceId: serid,
                        },
                    }).then((res) => {
                        if (res.data.resultInfo.type == 9) {
                            ser_url = res.data.resultInfo.url;
                            let url1 = ser_url.split("@")[0];//入口1
                            request(`${baseUrl}/api/v1/accounts/info`, {
                                method: "get",
                                params: {
                                    token: token_1,
                                },
                            }).then((res) => {
                                let NewUrl = url1 + "?mbl=" + res.data.mobile + "&token=" + token_1 + "&orderCode=" + orderCode + "&cid=35";
                                window.location.href = NewUrl
                            });
                        } else if (res.data.resultInfo.type == 12) {
                            ser_url = res.data.resultInfo.url;
                            let NewUrl = ser_url;
                            window.location.href = NewUrl + "&eid=" + orderCode + "&post_id=" + token_1;
                        }

                    });
                }


                if (res.code != 601) {
                    this.showPage = true;
                    this.amount = this.$route.query.amount;
                    this.serviceId = this.$route.query.serviceId;
                    fetchDetailByServiceId({serviceId: this.serviceId}).then((res) => {
                        if (res.code === 0) {
                            this.serviceOrder = res.data.serviceInfo;
                            if (this.serviceOrder.serviceStyleList.length == 1) {
                                const t = setTimeout(() => {
                                    this.showTpbsaj = false;
                                    const el = document.getElementsByClassName("ant-tabs-nav-scroll");
                                    el[0].style.display = "none";
                                    const el2 = document.getElementsByClassName("ant-tabs-bar");
                                    el2[0].style.display = "none";
                                    clearTimeout(t);
                                });
                            }
                            if (
                                this.serviceOrder.serviceStyleList[0].serviceStyleId == "16" &&
                                this.serviceOrder.serviceStyleList.length > 1
                            ) {
                                const arr = this.serviceOrder.serviceStyleList;
                                this.serviceOrder.serviceStyleList = [
                                    ...arr.filter((item) => item.serviceStyleId != "16"),
                                    arr.find((item) => item.serviceStyleId == "16"),
                                ];
                            }
                            if (this.serviceOrder.serviceStyle.indexOf("到店服务") > -1) {
                                this.getShopList();
                                if (this.serviceOrder.serviceStyleList.length == 1) {
                                    this.activeType = "2";
                                }
                            }
                            // 处理sku信息
                            if (skuInfoId && this.serviceOrder.skuList) {
                                let selectSkuList = this.serviceOrder.skuList.filter(i => i.skuInfoId == skuInfoId)
                                if (selectSkuList && selectSkuList.length == 1) {
                                    let selectSku = selectSkuList[0]
                                    if (selectSku.skuImg && selectSku.skuImg.indexOf('http') >= 0)
                                        this.serviceOrder.serviceImage = selectSku.skuImg
                                    if (selectSku.skuInfo)
                                        this.serviceOrder.skuInfoName = selectSku.skuInfo
                                    if (selectSku.costPrice)
                                        this.serviceOrder.servicePrice = selectSku.costPrice
                                }
                            }
                        }
                    });
                }
            });
        },
        mounted() {
            this.userdaibi();
            this.getServiceAddressList();
            this.init();
            this.initAMap();
            // if(this.showAddressList.length===0){
            //     this.visibleTwo=true;
            // }
        },
        methods: {

            //保存发票
            saveInvoice() {
                let invoiceInfo = {
                    invoiceType: this.invoiceType === 0 ? false : true,
                    invoiceContent: this.invoiceContent === 0 ? false : true,
                    titleType: this.invoiceTitleType === 0 ? false : true,
                    invoiceTitle: this.invoiceTitleType === 0 ? this.personage : this.receiverEmail,
                };
                if (this.invoiceTitleType === 1) {
                    (invoiceInfo["taxpayerId"] = this.taxpayerId)
                }
                if (this.invoiceTitleType === 1) {
                    if (this.receiverEmail === '') {
                        this.reminder = '发票抬头不能为空'
                    } else if (this.taxpayerId === '') {
                        this.reminder2 = '纳税人识别号不能为空'
                    } else {
                        saveTheInvoice(invoiceInfo).then(res => {
                            if (res && res.code == 0) {
                                message.success('保存成功');
                                this.invoiceState = true
                                this.makeOutCheque = false;
                                this.invoiceId = res.invoiceId
                            }
                        })
                    }
                } else {
                    saveTheInvoice(invoiceInfo).then(res => {
                        if (res && res.code == 0) {
                            message.success('保存成功');
                            this.invoiceState = true
                            this.makeOutCheque = false;
                            this.invoiceId = res.invoiceId
                        }
                    })
                }

            },
            //添加数量
            addAmount(amount) {
                amount >= 99 ? 99 : +this.amount++;
            },
            //减少数量
            minusAmount(amount) {
                amount <= 1 ? 1 : +this.amount--;
            },
            //获取服务留言
            serviceRemarkCli(data){
                this.serviceRemark =data
            },
            onSearchChange(e) {
                this.searchVal = e.target.value;
                sessionStorage.setItem("search", e.target.value);
            },
            onSearch(value) {
                const path = window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1,
                    window.location.href.length
                );
                if (path !== "servicePage") {
                    this.$router.push("/servicePage");
                }
                if (this.$props.handleNavSearch) {
                    this.$props.handleNavSearch(value);
                }
            },
            showPage1() {
                let serid = this.$route.query.serviceId;
                request(`${baseUrl}/api/v1/services/settlement/special/${serid}`, {method: "post",}).then((res) => {
                    if (res.code == 601) {
                        return false;
                    } else {
                        return true;
                    }
                });
            },
            // 发票抬头状态
            activeName(newVal, oldVal) {
                if (this.receiverEmail != '') {
                    this.reminder = ''
                }
            },
            //纳税人识别号状态
            activeName2(newVal, oldVal) {
                if (this.taxpayerId != '') {
                    this.reminder2 = ''
                }
            },
            currAddressChange(e) {
                this.currAddress = e.target.value;
            },
            //优惠券信息
            condition(val) {
                this.conditionNumber = val
            },

            //获取门店地址变化
            oneChangeTwo(e) {
                this.singleAddress = e;
                this.storeId = e.storeId;
            },

            //获取收货信息变化
            oneChange(e) {
                this.choiceList = e
                this.currAddress = e
            },
            //收起收货信息,并选中
            packUp() {
                if (this.choiceList.length === 0) {
                    this.showAddressList = this.serviceAddressList.slice(0, 3)
                } else {
                    this.serviceAddressList.forEach((item, index) => {
                        if (this.choiceList.addressId === item.addressId) {
                            var str = this.serviceAddressList.splice(index, 1);
                            this.serviceAddressList.unshift(str[0]);
                            this.showAddressList = this.serviceAddressList.slice(0, 3)
                        }
                    })
                }
            },
            //收起门店信息，并选中
            shopPackUp() {
                if (this.singleAddress.length === 0) {
                    this.shopListShow = this.shopList.slice(0, 3)
                } else {
                    this.shopList.forEach((item, index) => {
                        if (this.singleAddress.storeId === item.storeId) {
                            var str = this.shopList.splice(index, 1);
                            this.shopList.unshift(str[0]);
                            this.shopListShow = this.shopList.slice(0, 3)
                        }
                    })

                }
            },

            activeTypeChange(type) {
                const arr = this.serviceOrder.serviceStyleList;
                if (type == "2") {
                    this.serviceOrder.serviceStyleList = [
                        arr.find((item) => item.serviceStyleId == "16"),
                        ...arr.filter((item) => item.serviceStyleId != "16"),
                    ];
                } else {
                    this.serviceOrder.serviceStyleList = [
                        ...arr.filter((item) => item.serviceStyleId != "16"),
                        arr.find((item) => item.serviceStyleId == "16"),
                    ];
                }
            },
            //到店时 获取门店列表
            getShopList() {
                request(`${baseUrl}/api/v1/org/store-address-info`, {
                    params: {orgId: this.serviceOrder.orgId},
                }).then((res) => {
                    if (res["code"] == 0) {
                        this.shopList = res.data;
                        this.shopListShow = this.shopList.slice(0, 3);
                        this.singleAddress = this.shopListShow[0];
                        this.storeId=this.singleAddress.storeId;
                    }
                });
            },
            // 初始化地址
            init() {
                fetchAddrCascader({
                    areaType: 0,
                    areaId: null,
                }).then((data) => {
                    if (data && data.code == 0) {
                        const list = data.data.list;
                        let children = [];
                        if (list) {
                            list.forEach((i) => {
                                children.push({
                                    label: i.name,
                                    value: i.id,
                                    id: i.id,
                                    level: 1,
                                    isLeaf: false,
                                });
                            });
                        }
                        this.options = children;
                    } else {
                        message.error(data.msg);
                    }
                });
            },
            loadData(selectedOptions) {
                const targetOption = selectedOptions[selectedOptions.length - 1];
                const {id} = targetOption;
                const {level} = targetOption;
                targetOption.loading = true;
                fetchAddrCascader({
                    areaType: level,
                    areaId: id,
                }).then((data) => {
                    targetOption.loading = false;
                    if (data && data.code == 0) {
                        const list = data.data.list;
                        let children = [];
                        if (list) {
                            list.forEach((i) => {
                                children.push({
                                    label: i.name,
                                    value: i.id,
                                    id: i.id,
                                    isLeaf: i.childNum == 0,
                                    level: level + 1,
                                });
                            });
                        }
                        targetOption.loading = false;
                        targetOption.children = children;
                        this.options = [...this.options];
                    } else {
                        message.error(data.msg);
                    }
                });
            },
            initAMap() {
                AMapLoader.load({
                    version: "1.4.15",
                    plugins: ["AMap.Geocoder"],
                    key: "c4ac5dbaa55b2994a57c6b369f28af53",
                })
                    .then((AMap) => {
                        this.AMap = AMap;
                    })
            },
            handleSubmit() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        let param = {
                            receiverProvince: values.address[0].toString(),
                            receiverCity: values.address[1].toString(),
                            receiverArea: values.address[2].toString(),
                            receiverStreet: values.address[3].toString(),
                            phoneNumber: values.phoneNumebr,
                            receiverDetailAddress: values.receicerDetailAddress,
                            receiverName: values.receiverName,
                            zipCode: values.zipCode,
                            isDefaut: values.isDefaut ? 1 : 0,
                        };
                        let bufferStr = "",
                            bufferArr = this.options;
                        for (let item of values.address.slice(0, 3)) {
                            let obj = bufferArr.find((val) => val.id == +item);
                            bufferArr = obj.children;
                            bufferStr += obj.label;
                        }
                        const geocoder = new this.AMap.Geocoder({
                            city: bufferStr,
                            batch: true,
                        });
                        geocoder.getLocation(
                            values.receicerDetailAddress,
                            (state, result) => {
                                if ((state = "complete" && result.info == "OK")) {
                                    param.longitude = result.geocodes[0].location.lng;
                                    param.latitude = result.geocodes[0].location.lat;
                                } else {
                                    param.longitude = 0;
                                    param.latitude = 0;
                                }
                                addAddress({
                                    ...param,
                                }).then((res) => {
                                    if (res.code === 0) {
                                        message.success("添加成功");
                                        this.visible = false;
                                        this.form.resetFields();
                                        this.getServiceAddressList(values.isDefaut ? 0 : 1);
                                    } else {
                                        message.error(res.msg);
                                    }
                                });
                            }
                        );
                    }
                });
            },
            addSuccess() {
                this.visible = false;
                this.form.resetFields();
                this.getServiceAddressList();
            },
            addSuccessTwo() {
                this.visibleTwo = false;
                this.form.resetFields();
                this.getServiceAddressList();
            },
            // 获取省id
            getProviceId(name, paramName, arrayData) {
                return arrayData.filter((el) => {
                    return el[paramName] === name;
                });
            },
            //发票类型
            InvoiceType(value) {
                this.invoiceType = value
            },
            //发票信息
            invoiceContentClick(value) {
                this.invoiceContent = value

            },
            userdaibi() {
                userdaibi().then((res) => {
                    this.availableDaibi = res.data.availableDaibi;
                });
            },
            getServiceAddressList(defaultA = 0) {
                getServiceAddressList().then((res) => {
                    this.serviceAddressList = res.data.serviceAddressList;
                    this.showAddressList = this.serviceAddressList.slice(0, 3);
                    if (this.showAddressList.length > 0) {
                        this.currAddress = this.showAddressList[defaultA];
                    }
                    if (res.data.serviceAddressList.length===0) {
                        this.visibleTwo = true;
                    }
                });

            },
            onChange(e) {
                this.fapiao = !this.fapiao;
                this.haveInvoice = this.fapiao ? 1 : 0;
                if (this.fapiao == true) {
                    this.makeOutCheque = true
                }
            },
            radioChange(e) {
                this.invoiceTitleType = e.target.value;
            },
            // invoiceContentChange(e) {
            //     this.invoiceContent = e.target.value;
            // },
            payTypeChange(key) {
                this.payType = key;
            },
            showModal() {
                this.visible = true;
            },

            handleOk() {
                this.visible = false;
            },
            jumpToHome() {
                this.$router.push("/");
            },

            /**
             * 提交订单去支付
             */
            /**
             * 提交订单去支付，重写支付接口之后
             * 重写的接口不处理0元的服务订单
             */
            serviceOrderSettlement() {
                const that = this
                // 不处理0元的订单，另外使用其他接口处理
                if (that.totalAmount === 0) return

                // 是否是到店服务支付页
                const ifShop = that.activeType !== '1';

                let serviceProvider = []

                // 服务单价 * 服务数量
                let serviceTotalAmount = that.amount * that.serviceOrder.servicePrice;

                // 服务费单价 * 服务数量
                let serviceFeeAmount = that.amount * that.serviceOrder.serviceStyleList[0].serviceFee;

                let serviceStyle = that.serviceOrder.serviceStyleList[0].serviceStyleId;
                let styleArray = [serviceStyle]
                // if (that.$route.query.skuInfoId != '0') {
                //   dataForm.serviceProviderInfo.serviceList["skuInfoId"] =  that.skuInfoId
                // }
                let dataForm = {
                    cityId: 0, // 所在市id
                    ifShop: ifShop, // 是否是到店服务
                    serviceAddressId: "", // 非到店服务人的所在地址id，如上门服务的用户服务地址
                    serviceProviderInfo: [ // 服务所属服务商信息
                        {
                            agencyId: that.serviceOrder.orgId, // 服务商id
                            serviceStyle: styleArray, // 服务方式
                            serviceList: [ // 服务
                                {
                                    serviceId: that.serviceOrder.serviceId, // 服务id
                                    skuInfoId: that.skuInfoId === '0' ? '' : that.skuInfoId, // 服务规格
                                    serviceNum: that.amount, // 购买的服务数量
                                    servicePrice: that.serviceOrder.servicePrice, // 服务单价
                                    serviceTotalAmount: serviceTotalAmount, // 服务价格 = 服务单价 * 服务数量
                                    serviceFeeAmount: serviceFeeAmount // 服务费 = 服务服务费单价 * 服务数量
                                }
                            ],
                            servicesSumAmount: serviceTotalAmount, // 服务合计金额，不计算服务费
                            totalServiceFee: serviceFeeAmount, // 服务费合计金额
                            totalSumAmount: serviceTotalAmount + serviceFeeAmount, // 总金额 = 服务合计 + 服务费合计
                            serviceRemark: that.serviceRemark, // 服务商留言50字以内
                            shopAddressId: '' // 服务商的门店地址id，这里要看服务方式是不是到店服务
                        }
                    ],
                    purchaseMethod: that.payType, // 支付方式 在线支付285；现场付款286；货到付款287
                    invoiceInfoId: this.invoiceId, // 发票id，没有可以为空
                    couponIdList: [], // 优惠券id列表，没有可以为空
                    tokenAmount: 0, // 代币总金额 没有为0
                    totalDiscountAmount: 0, // 优惠总金额 没有为0
                    totalAmount: that.totalAmountAll, // 总金额 最小为0.01，totalSumAmount之和
                    totalAmountForPay: that.totalAmountAll, // 应付总金额 最小为0.01， = 总金额 - 优惠总金额
                    orderSource: 349, // 订单来源 349-PC；350-移动设备
                    ignoreSettlement: false // 普通需要结算的订单，默认false
                }
                if (!ifShop) {
                    // 用户的服务地址，在非到店服务中需要填写
                    if (that.currAddress && that.currAddress.addressId)
                        dataForm.serviceAddressId = that.currAddress.addressId
                    else {

                    }
                } else {
                    // 如果是到店服务，获取服务商的门店地址
                    if (that.storeId === '') {
                        message.error('请选择服务地址')
                    }
                    dataForm.serviceProviderInfo[0].shopAddressId = this.storeId
                }


                fetchOrderSettlement(dataForm).then(res => {
                    if (res && res.code === 0) {

                        // 到店非到店
                        let serviceAddressInfo = ifShop ? res.info.providerInfo[0].storeInfo : res.info.receiverInfo;

                        // 创建订单成功
                        this.$router.push({
                            path: "/serviceOrderPaySuccess",
                            query: {
                                mainServiceOrderCode: res.info.mainServiceOrderCode,
                                payTotal: res.info.totalAmount,
                                serviceOrderId: res.info.providerInfo[0].serviceOrderId,
                                receiverName: ifShop ? serviceAddressInfo.storeName : serviceAddressInfo.receiverName,
                                addressDetail: serviceAddressInfo.addressDetail,
                                phoneNumber: serviceAddressInfo.phoneNumber,
                            },
                        });

                        this.$router.push({
                            path: "/serviceOrderPay",
                            query: {
                                mainServiceOrderCode: res.info.mainServiceOrderCode,
                            },
                        });

                        return;
                    } else {
                        message.error(res.msg);
                    }
                }).catch(e => message.error(e.errorMsg))
            },

            checkBoxChange(e) {
                this.isChecked = e.target.checked ? 1 : 0;
            },
            addAddressNew(event) {
                event.preventDefault();
                this.provinceDefalutValue = "";
                this.province = "";
                this.city = "";
                this.area = "";
                this.editType = 0;
                this.street = "";
                this.editData = {
                    phoneNumber: "",
                    receiverDetailAddress: "",
                    receiverName: "",
                    receiverStreet: 0,
                    receiverArea: 0,
                    receiverCity: 0,
                    receiverProvince: 0,
                    isDefault: false,
                    addressId: "",
                    lot: "", //经度
                    lat: "", //纬度
                    provinceValue: [],
                    userId: JSON.parse(localStorage.getItem("userInfo"))["userInfoId"],
                };
                this.visible = true;
            },
        },
    };
</script>

<style lang="less">
    .billingDetails {
        background: #F5F7FA;
        opacity: 1;
        min-width: 11.72rem;
        /*user-select: none;*/

        #billingDetails-top {
            height: 81px;
            width: 100%;
            min-width: 10rem;
            background: #ffffff;
            /*border: 1px  solid red;*/

            #billingDetails-header {
                user-select: none;
                height: 100%;
                width: 11.72rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                opacity: 1;
                margin: 0 auto;
                /*border: 1px  solid red;*/

                .img-span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    min-width: 2.3rem;

                    img {
                        width: 1.24rem;
                        height: 0.59rem;
                        cursor: pointer;

                    }

                    span {
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #39BEB8;
                        opacity: 1;
                        display: inline-block;
                        padding-left: 0.47rem;
                        margin-top: 0.12rem;
                    }
                }

                .billingDetails-input {
                    min-width: 3.74rem;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .home_input {
                        /*width: 4rem;*/
                        display: flex;
                        align-items: center;

                        .ant-input {
                            min-width: 2.95rem;
                            font-size: 0.14rem;
                            height: 0.48rem;

                        }

                        .ant-input {
                            &::placeholder {
                                color: #78849E;
                                font-size: 12px;
                                opacity: 1;
                            }
                        }

                        button.ant-btn.ant-btn-primary.ant-input-search-button {
                            width: 0.77rem;
                            height: 0.48rem;
                            background-color: #60c7c6;
                            border-radius: 0 0.5rem 0.5rem 0;
                            border-color: #60c7c6;
                            font-size: 0.2rem;
                        }

                        input.ant-input:focus {
                            border-color: #d9d9d9;
                            border-radius: 0.5rem 0 0 0.5rem;
                            -webkit-box-shadow: 0 0 0 0;
                            box-shadow: 0 0 0 0;
                        }

                        input.ant-input:hover {
                            border-color: #d9d9d9;
                        }

                        input.ant-input {
                            border-radius: 0.5rem 0 0 0.5rem;
                        }
                    }
                }


            }
        }


        .serviceOrder {
            margin: 0 auto;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
            padding-bottom: 0.2rem;
            /*height: 19.32rem;*/
            width: 11.72rem;
            /*border: 1px solid red;*/
            background: #ffffff;
            border-radius: 0.12rem;
            /*padding-left: 0.2rem;*/
            /*padding-right: 0.2rem;*/
            opacity: 1;

            .top-tis {
                width: 100%;
                height: 0.61rem;
                border-bottom: 1px solid #E4E9F2;
                display: flex;
                padding-left: 0.2rem;
                padding-right: 0.2rem;
                align-items: center;
                justify-content: space-between;

                .top-tis-title {
                    height: 100%;
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #454F63;
                    opacity: 1;
                    /*border: 1px solid red;*/
                    line-height: 0.61rem;
                }

                .top-tis-flow {
                    /*width: 5.88rem;*/
                    height: 100%;
                    /*border: 1px solid red;*/
                    display: flex;

                }
            }

            .serviceOrder-center {
                width: 100%;
                /*height:3rem;*/
                /*border: 1px solid red;*/
                padding-left: 0.2rem;
                padding-right: 0.2rem;

                .ant-tabs-nav-wrap {
                    text-align: center;
                    margin-top: 0.3rem;
                }

                /*下滑线隐藏*/

                .ant-tabs-bar {
                    border-bottom: none;
                }

                //选中框
                .ant-tabs-ink-bar {
                    background-color: #39BEB8;
                }

                .ant-tabs-nav .ant-tabs-tab:hover {
                    color: #39BEB8;
                }

                .ant-tabs-nav .ant-tabs-tab {
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #2A2E43;
                    position: relative;
                    opacity: 1;
                    margin: 0;
                    margin-right: 1rem;
                    padding: 12px 10px;
                }

                .ant-tabs-nav .ant-tabs-tab-active {
                    color: #39BEB8;
                    opacity: 1;
                    font-size: 0.16rem;
                    font-weight: bold;
                }


                .servePeople {
                    width: 100%;
                    padding: 0.1rem 0.2rem 0.1rem 0.2rem;
                    background: #F8F8F8;
                    border-radius: 0.12rem;
                    /*border: 1px solid red;*/
                    margin-top: 0.3rem;

                    .servePeople-top {
                        width: 100%;
                        height: 0.45rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .address-tip {
                            /*height: 100%;*/
                            font-size: 0.16rem;
                            font-weight: bold;
                            color: #454F63;
                            opacity: 1;
                        }

                        .address-button {
                            min-width: 1.52rem;
                            min-height: 0.4rem;
                            background: #39BEB8;
                            border: 1px solid #39BEB8;
                            opacity: 1;
                            border-radius: 0.2rem;
                            color: #fff;
                            font-weight: 400;

                            img {
                                width: 0.13rem;
                                height: 0.13rem;
                                margin-left: 0.05rem;
                            }
                        }

                        .address-button:hover {
                            opacity: 0.8;
                        }
                    }

                    .servePeople-center {
                        width: 100%;
                        max-height: 2.3rem;
                        margin-top: 0.1rem;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        overflow: auto;

                        .address {
                            width: 3.5rem;
                            height: 1.07rem;
                            background: #FFFFFF;
                            opacity: 1;
                            border-radius: 0.12rem;
                            margin-bottom: 0.1rem;
                            display: flex;
                            align-items: center;
                            padding-left: 0.2rem;
                            cursor: pointer;
                            user-select: none;

                            .divImg {
                                img {
                                    width: 0.36rem;
                                    height: 0.36rem;
                                }
                            }

                            .divMessage {
                                height: 1rem;
                                width: 100%;

                                .default {
                                    height: 0.19rem;
                                    text-align: right;
                                    /*border: 1px solid red;*/
                                    padding-right: 0.1rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                    margin-top: 0.05rem;

                                    div {
                                        width: 0.14rem;
                                        height: 0.14rem;
                                        background: #2BAAA9;
                                        border-radius: 50%;
                                        opacity: 1;
                                    }

                                    span {
                                        font-size: 0.14rem;
                                        font-weight: 400;
                                        color: #454F63;
                                        opacity: 1;
                                        margin-left: 0.05rem;
                                    }

                                }

                                .detailed-information {
                                    height: 0.78rem;
                                    padding: 0 0.1rem 0 0.1rem;

                                    .p1 {
                                        margin: 0;
                                        font-size: 0.17rem;
                                        font-weight: bold;
                                        color: #454F63;
                                        opacity: 1;

                                        span {
                                            font-size: 0.14rem;
                                            font-weight: 400;
                                            color: #454F63;
                                            opacity: 1;
                                        }
                                    }

                                    .p2 {
                                        margin: 0;
                                        font-size: 0.14rem;
                                        font-weight: 400;
                                        color: #454F63;
                                        opacity: 1;
                                        overflow: hidden;
                                        height: 0.4rem;
                                    }
                                }
                            }

                            .divMessage2 {
                                height: 1rem;
                                width: 100%;

                                .detailed-information {
                                    height: 0.7rem;
                                    padding: 0 0.1rem 0 0.1rem;
                                    display: flex;
                                    align-items: center;

                                    .p1 {
                                        margin: 0;
                                        font-size: 0.17rem;
                                        font-weight: bold;
                                        color: #454F63;
                                        opacity: 1;

                                        span {
                                            font-size: 0.14rem;
                                            font-weight: 400;
                                            color: #454F63;
                                            opacity: 1;
                                        }
                                    }

                                    .p2 {
                                        margin: 0;
                                        font-size: 0.14rem;
                                        font-weight: 400;
                                        color: #454F63;
                                        opacity: 1;
                                        overflow: hidden;
                                        max-height: 0.4rem;
                                    }
                                }

                                .detailed-phone {
                                    height: 0.3rem;
                                    /*border: 1px solid red;*/
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 0 0.1rem 0 0.1rem;

                                    span {
                                        font-size: 0.14rem;
                                        font-weight: 400;
                                        color: #454F63;
                                        opacity: 1;
                                    }
                                }
                            }


                        }

                        .addressNotClick {
                            border: none;
                        }

                        .addressClick {
                            border: 1px solid #39BEB8;
                        }

                        /* 如果最后一行是2个元素 */

                        .address:last-child:nth-child(3n - 1) {
                            margin-right: calc(32.6% + 4% / 3);
                        }
                    }

                    .evenMore {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        cursor: pointer;

                        p {
                            margin: 0;
                        }
                    }
                }
            }


            .order-container {
                width: 11.71rem;
                margin: 0 auto;
                margin-top: 0.2rem;

                .order {
                    width: 100%;
                    border: 1px solid #ebeef3;
                    padding: 0.3rem 0.4rem;

                    #nonVisit {
                        height: auto;

                        .moren {
                            background-color: #60c7c6;
                            padding: 0.02rem 0.15rem 0.03rem;
                            color: #fff;
                            border-radius: 0.06rem;
                            font-size: 0.16rem;
                        }

                        .showmore {
                            font-size: 0.16rem;
                            color: #737373;
                            margin-top: 0.12rem;
                            cursor: pointer;

                            span:hover {
                                color: #60c7c6;
                                text-decoration: underline;
                            }
                        }
                    }

                    #visit {
                        height: 1rem;
                    }

                    .tip {
                        text-align: center;
                        color: #999;
                        font-size: 0.16rem;
                    }

                    .ant-radio-inner::after {
                        background-color: #60c7c6;
                    }

                    .ant-radio-checked .ant-radio-inner {
                        border-color: #60c7c6;
                    }

                    .ant-tabs-nav {
                        display: block;
                        margin: 0 auto;
                        width: 2.2rem;
                        margin-left: 3.2rem;
                    }

                    .ant-tabs-tab {
                        padding: 0.1rem 0;
                        margin: 0 0.6rem;
                        color: #171717;
                    }

                    .ant-tabs-tab:hover {
                        color: #60c7c6;
                    }

                    .ant-tabs-tab-active {
                        color: #60c7c6;
                        border-bottom: 3px solid #60c7c6;
                    }

                    .ant-tabs-ink-bar {
                        background-color: #60c7c6;
                        visibility: hidden;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: #60c7c6;
                        border-color: #60c7c6;
                    }

                    .form-title {
                        border-top: 1px solid #f2f2f2;
                        padding-top: 0.26rem;
                        padding-bottom: 0.2rem;
                        width: 10.66rem;
                        margin: 0 auto;
                    }

                    .location {
                        display: flex;
                        align-items: center;
                        padding-left: 0.2rem;
                        position: relative;

                        .box {
                            width: 1.3rem;
                            height: 0.33rem;
                            border: 1px solid #60c7c6;
                            border-radius: 0.03rem;
                            color: #737373;
                            font-size: 0.16rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 0.14rem;
                            cursor: pointer;
                        }

                        .moren {
                            width: 0.56rem;
                            height: 0.22rem;
                            border-radius: 0.03rem;
                            background-color: #60c7c6;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 0.14rem;
                        }

                        .addlocation {
                            position: absolute;
                            right: 0;
                            top: -0.4rem;
                            color: #60c7c6;
                            font-size: 0.16rem;
                            cursor: pointer;
                        }
                    }

                    .table-content {
                        width: 100%;
                        margin-bottom: 0.2rem;

                        td {
                            text-align: center;
                        }
                    }

                    .table-title {
                        width: 100%;
                        border-bottom: 2px solid #60c7c6;

                        td {
                            font-size: 0.16rem;
                            color: #171717;
                            padding-bottom: 0.1rem;
                            vertical-align: middle;
                            border: none;
                        }
                    }

                    .list-item {
                        color: #171717;

                        #num {
                            display: inline-block;
                            // width: 0.5rem;
                            border: 1px solid #ccc;
                            user-select: none;

                            .mountOpera {
                                display: inline-block;
                                // width: 0.1rem;
                                background-color: #f1f1f1;
                                // padding: 0 0.04rem;
                                width: 0.15rem;
                                cursor: pointer;
                                text-align: center;
                            }
                        }

                        .title {
                            height: 0.4rem;
                            line-height: 0.4rem;
                        }

                        .item-row {
                            padding-bottom: 0.4rem;
                            position: relative;

                            td {
                                border: none;
                            }

                            .left-box {
                                width: 4.3rem;
                                text-align: left;
                                display: flex;

                                .serviceName {
                                    width: 100%;
                                    height: 0.69rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .img {
                                    width: 1.2rem;
                                    height: 0.68rem;
                                    display: inline-block;
                                    margin-right: 0.1rem;
                                }
                            }
                        }
                    }

                    .fapiao-box {
                        display: flex;
                        padding-bottom: 0.2rem;
                        border-bottom: 1px solid #f2f2f2;

                        .left {
                            .ant-checkbox-checked .ant-checkbox-inner {
                                background-color: #60c7c6;
                                border-color: #60c7c6;
                            }

                            .form-box {
                                ul {
                                    margin-left: -40px;
                                    list-style-type: none;

                                    li {
                                        color: #737373;
                                        font-size: 0.16rem;

                                        line-height: 0.41rem;
                                    }

                                    li > span:nth-child(1) {
                                        display: inline-block;
                                        padding-right: 0.15rem;
                                        width: 1.5rem;
                                        text-align: right;
                                        color: #737373;
                                        height: 0.3rem;
                                        line-height: 0.4rem;
                                        font-size: 0.16rem;
                                        float: left;
                                    }
                                }
                            }
                        }

                        .right {
                            width: 3.5rem;
                            border-left: 1px solid #f2f2f2;
                            padding-left: 0.2rem;

                            .youhui {
                                display: flex;
                                justify-content: space-between;
                                padding-bottom: 0.16rem;
                                padding-top: 0.05rem;
                                font-size: 0.16rem;
                                color: #171717;
                                border-bottom: 1px solid #f2f2f2;
                                margin-bottom: 0.18rem;
                            }

                            .youhui:last-child {
                                border: 0;
                            }
                        }
                    }

                    .paytype {
                        display: flex;
                        padding-left: 0.2rem;

                        .box {
                            width: 1rem;
                            height: 0.33rem;
                            border-radius: 0.03rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid #b2b2b2;
                            color: #171717;
                            font-size: 0.16rem;
                            margin-right: 0.25rem;
                            margin-bottom: 0.2rem;
                            cursor: pointer;
                        }

                        .active {
                            border-color: #60c7c6;
                            color: #60c7c6;
                        }
                    }
                }

                .paymoney {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 0.2rem;
                    margin-bottom: 0.2rem;

                    p {
                        margin-bottom: 0.06rem;
                        font-size: 0.16rem;
                    }

                    .left {
                        text-align: right;
                        color: #737373;
                    }

                    .right {
                        text-align: right;
                        color: #171717;
                        width: 1.57rem;
                        padding-right: 0.45rem;
                    }
                }

                .end {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    padding-right: 0.42rem;
                    background-color: #f8f8f8;
                    padding-bottom: 0.2rem;
                    padding-top: 0.23rem;

                    .money {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 0.16rem;
                        color: #737373;
                        font-weight: bold;

                        p {
                            margin-bottom: 0.12rem;
                        }
                    }
                }

                .ant-btn-primary {
                    background-color: #60c7c6;
                    border-color: #60c7c6;
                }

                .ant-btn-primary:hover,
                .ant-btn-primary:focus {
                    background-color: #60c7c6;
                    border-color: #60c7c6;
                }
            }

            .detailedList {
                width: 100%;
                /*height: 100%;*/
                padding: 0.2rem 0.2rem 0 0.2rem;


                .DetailsListing {
                    width: 11.31rem;
                    height: 100%;
                    /*border: 1px solid red;*/

                    .DetailsListing-top {
                        font-size: 0.14rem;
                        color: #454F63;
                        opacity: 1;
                        margin-bottom: 0.05rem;
                    }

                    .DetailsListing-header {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        height: 0.41rem;
                        width: 100%;
                        background-color: #70CECA;
                        opacity: 1;
                        border-radius: 0.12rem 0.12rem 0 0;

                        .item {
                            margin-top: 0.04rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            /*border: 1px solid red;*/

                            span {
                                color: #FFFFFF;
                                font-size: 0.14rem;
                                opacity: 1;
                            }
                        }

                    }

                }
            }

            .ServiceSettlementInformation {
                margin-top: 0.2rem;
                padding: 0 0.2rem 0 0.2rem;

                .message-1, .message-2 {
                    width: 100%;
                    height: 0.88rem;
                    border: 1px solid #E4E9F2;
                    border-bottom: none;
                    display: flex;
                    align-items: center;


                    .message-left {
                        font-size: 0.14rem;
                        font-weight: bold;
                        color: #454F63;
                        opacity: 1;
                        margin-left: 0.2rem;
                        margin-right: 0.4rem;
                    }
                }

                .message-1 {
                    border-radius: 0.12rem 0.12rem 0 0;
                    user-select: none;

                    .message-right {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .pay {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 1.14rem;
                            height: 0.4rem;
                            background: #39BEB8;
                            opacity: 1;
                            border-radius: 4px;
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                }

                .message-2 {
                    .message-left {
                        height: 100%;
                        padding-top: 0.19rem;
                    }

                    .message-right {
                        height: 100%;
                        display: flex;
                        padding-top: 0.19rem;

                        .checked {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            span {
                                font-size: 0.14rem;
                                color: #454F63;
                                opacity: 1;
                            }

                            .checkbox-name {
                                font-size: 0.14rem;
                                color: #454F63;
                                opacity: 1;
                                margin-top: 0.1rem;
                                user-select: none;

                                .upd {
                                    margin-left: 0.34rem;
                                    color: #39BEB8;
                                    cursor: pointer;
                                }
                            }
                        }


                    }
                }

                .message-3 {
                    width: 100%;
                    border: 1px solid #E4E9F2;
                    border-radius: 0 0 0.12rem 0.12rem;
                    padding-bottom: 0.1rem;

                    .message-3-top {
                        font-size: 0.14rem;
                        font-weight: bold;
                        color: #454F63;
                        opacity: 1;
                        margin-left: 0.2rem;
                        margin-right: 0.4rem;
                        margin-top: 0.2rem;

                        img {
                            width: 12.47px;
                            height: 8.76px;
                            margin-left: 0.1rem;
                            cursor: pointer;
                            transform: rotate(180deg);
                        }

                        .img2 {
                            transform: rotate(360deg);
                        }
                    }

                    .message-3-bottom {
                        padding: 0.1rem 0.2rem 0 0.2rem;

                        .message-3-type {
                            height: 0.4rem;
                            background: #F0F0F0;
                            opacity: 1;
                            display: flex;
                            align-items: center;
                            user-select: none;

                            span {
                                font-size: 0.14rem;
                                color: #2A2E43;
                                opacity: 1;
                                padding-left: 0.3rem;
                                cursor: pointer;
                            }

                            span:hover {
                                color: #39BEB8;
                            }

                            .conditionTis {
                                color: #39BEB8;
                            }
                        }

                        .discountCoupon {
                            margin-top: 0.2rem;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            font-size: 0.14rem;

                            .discountCoupon-list {
                                width: 3.50rem;
                                height: 0.8rem;
                                background-image: url("./../assets/images/homeImg/组 1325@2x.png");
                                background-size: cover;
                                background-position: center center;
                                border-radius: 0.08rem;
                                margin-bottom: 0.1rem;
                            }

                            .notApplicable {
                                width: 3.50rem;
                                height: 0.8rem;
                                background-image: url("./../assets/images/homeImg/组 1325@2x (1).png");
                                background-size: cover;
                                background-position: center center;
                                border-radius: 0.08rem;
                                margin-bottom: 0.1rem;
                            }

                            /* 如果最后一行是2个元素 */

                            .discountCoupon-list:last-child:nth-child(3n - 1) {
                                margin-right: calc(32.6% + 4% / 3);
                            }

                            .notApplicable:last-child:nth-child(3n - 1) {
                                margin-right: calc(32.6% + 4% / 3);
                            }

                        }
                    }
                }
            }

            .settlementData {
                width: 100%;
                margin-top: 0.1rem;
                padding: 0 0.5rem 0 0.2rem;
                display: flex;
                justify-content: flex-end;

                .div1 {
                    font-size: 0.14rem;
                    color: #454F63;
                    opacity: 1;
                    margin-top: 0.05rem;
                    line-height: 0.3rem;
                    text-align: right;
                }

                .div2 {
                    font-size: 0.14rem;
                    color: #454F63;
                    opacity: 1;
                    margin-top: 0.05rem;
                    line-height: 0.3rem;
                    margin-left: 0.4rem;
                }

            }

            .windUpAccount {
                width: 100%;
                height: 1rem;
                padding-left: 0.2rem;
                margin-top: 0.1rem;

                .windUpAccount-center {
                    width: 11.31rem;
                    height: 0.95rem;
                    background: #F9F9F9;
                    border: 1px solid #E4E9F2;
                    opacity: 1;
                    border-radius: 0.12rem;
                    padding-right: 0.3rem;

                    div {
                        font-size: 0.13rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        /*border: 1px solid red;*/
                        text-align: right;

                        span {
                            font-size: 0.13rem;
                            font-weight: 400;
                            color: #454F63;
                            opacity: 1;
                        }
                    }

                    div:first-child {
                        margin-top: 0.1rem;

                        span {
                            color: #FF5242;
                        }

                        .amount-payable {
                            font-size: 0.23rem;
                            font-family: Roboto;
                            font-weight: 900;
                            color: #FF5242;
                            opacity: 1;
                        }
                    }

                    div:last-child {
                        margin-top: 0.1rem;
                    }
                }
            }

            .paymentButton {
                height: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 0.2rem;
                margin-top: 0.1rem;

                .button {
                    width: 1.45rem;
                    height: 0.4rem;
                    background: #39BEB8;
                    opacity: 1;
                    border-radius: 0.2rem;
                    font-size: 0.14rem;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                }

                .button:hover {
                    border: 1px solid #39BEB8;
                    background: #39BEB8;
                }

            }


        }

        .otherServices {
            margin: 0 auto;
            margin-top: 0.2rem;
            margin-bottom: 0.5rem;
            padding: 0.2rem 0.2rem 0.2rem 0.2rem;
            width: 11.71rem;
            background: #ffffff;
            border-radius: 0.12rem;
            opacity: 1;

            .otherServices-title {
                font-size: 0.16rem;
                color: #454F63;
                opacity: 1;
                text-align: center;
                margin-bottom: 0.2rem;

            }

            .service-details-two {
                width: 100%;

                .detailsSettlementServices-top {
                    height: 0.46rem;
                    font-size: 0.14rem;
                    color: #454F63;
                    opacity: 1;
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                }

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    height: 0.41rem;
                    width: 100%;
                    background-color: #F0F0F0;
                    border: 1px solid #EDF1F7;
                    opacity: 1;
                    border-radius: 0.12rem 0.12rem 0 0;
                    margin: 0;

                    .item {
                        margin-top: 0.04rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        /*border: 1px solid red;*/

                        span {
                            color: #454F63;
                            font-size: 0.14rem;
                            opacity: 1;
                        }
                    }

                }

                .service-details {
                    width: 100%;
                    display: flex;
                    border: 1px solid #E4E9F2;
                    /*border-top: 1px solid #E4E9F2;*/
                    /*border-left: 1px solid #E4E9F2;*/
                    /*border-right: 1px solid #E4E9F2;*/
                    border-radius: 0.12rem;

                    .DetailsTheData {
                        .table-data {
                            width: 100%;
                            height: 1.2rem;
                            display: flex;

                            .name {
                                width: 3.62rem;
                                border-right: 1px solid #E4E9F2;
                                opacity: 1;
                                display: flex;

                                .detail-img {
                                    width: 0.81rem;
                                    height: 0.81rem;
                                    text-align: center;
                                    background-color: #f2f2f2;
                                    margin: 0.2rem 0 0 0.1rem;
                                    display: flex;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        cursor: pointer;
                                    }
                                }

                                .detail-content {
                                    /*border: 1px solid blue;*/
                                    width: 2.5rem;
                                    height: 0.72em;
                                    margin: 0.25rem 0 0 0.1rem;

                                    .span1 {
                                        font-weight: 400;
                                        text-align: left;
                                        width: 2.5rem;
                                        min-height: 0.4rem;
                                        overflow: hidden;
                                        letter-spacing: 0;
                                        display: -webkit-box;
                                        text-overflow: ellipsis;
                                        -webkit-line-clamp: 2; /*要显示的行数*/
                                        -webkit-box-orient: vertical;
                                    }

                                    .span1:hover {
                                        cursor: pointer;
                                        color: #39BEB8;
                                    }

                                    .span2 {
                                        margin-top: 0.1rem;
                                        text-align: left;
                                        white-space: nowrap;
                                        display: flex;

                                        span {
                                            font-size: 0.12rem;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #78849E;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .pattern, .totalPrices {
                                border-right: 1px solid #E4E9F2;
                                font-size: 0.14rem;
                                font-weight: 400;
                                color: #454F63;
                                opacity: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .totalPrices {
                                color: #FF5242;
                                border-right: none;
                            }
                        }
                    }
                }


            }
        }

        #addAddressBox {
            margin-top: 0.2rem;

            .ant-row {
                height: 0.35rem;
            }

            .ant-checkbox-wrapper {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                padding-left: 1.25rem;

                .ant-checkbox-checked::after {
                    border: 1px solid #87c7c6;
                }
            }

            .ant-cascader-menu-item-expand {
                font-size: 0.14rem;
            }

            .ant-cascader-menu-item {
                padding: 0.05rem 0.12rem;
                font-size: 0.14rem;
            }

            input {
                height: 0.4rem;
            }
        }
    }

</style>

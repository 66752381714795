<template>
  <div id="Spike">
    <div class="title">
      <img src="../../assets/images/mallHomeImg/miaosha@2x.png" alt />
    </div>
    <div style="margin-bottom: 0.5rem; position: relative">
      <spikeItem
        :list="list"
        :panicBuyingSessions="panicBuyingSessions"
        :panicBuyingName="panicBuyingName"
        :endTime="endTime"
        :selectItem="selectItem"
      ></spikeItem>

      <div class="paginationBox">
        <a-pagination
          :showTotal="(total) => `共 ${total} 条`"
          :defaultCurrent="1"
          :total="total"
          @change="onChange"
          :page-size.sync="condition.pageSize"
          :itemRender="itemRender"
          showQuickJumper
        ></a-pagination>
      </div>

      <sliderBar></sliderBar>
    </div>
    <div class="title">
      <img src="../../assets/images/mallHomeImg/gengduohaohuo@2x.png" alt />
    </div>
    <div style="padding-bottom: 0.2rem">
      <spikePart2></spikePart2>
    </div>
  </div>
</template>

<style lang="less" scoped>
#Spike {
  width: 13rem;
  margin: auto;
  padding-top: 0.35rem;
  .title {
    text-align: center;
    margin-bottom: 0.42rem;
    img {
      width: 1.8rem;
    }
  }
}
</style>
<script lang="js">
    import {sliderBar, spikeItem, spikePart2} from "@/components/mallComponents";
    import Vue from "vue";
    import {http} from "@/services/http";

    export default Vue.extend({
  components: { spikeItem, spikePart2, sliderBar },
  data() {
    return {
      condition: { pageIndex: 1, pageSize: 18 },
      list: [],
      total: 0,
      panicBuyingName: "",
      endTime: "00:00:00",
      selectItem: 0,
    };
  },
  created() {
    this.$store.commit("setfixedCurTop", -100);
  },
  mounted() {
    const arr = [
      { name: "正在抢购", top: 200, border: true, line: 2 },
      { name: "更多好货", top: 1200, border: false, line: 2 },
    ];

    this.$store.commit("setSliderBarItems", arr);
    this.getdata();
  },
  methods: {
    onChange(e) {
      this.condition.pageIndex = e;
      this.getdata();
    },
    getdata() {
      http
        .get(`/api/v1/commodities/panic-buying`, { params: this.condition })
        .then((res) => {
          this.getList(res["data"].commodities);
          this.total = res["data"].totalCount;
          this.panicBuyingSessions = res["data"].panicBuyingSessions;
          this.panicBuyingName = res["data"].panicBuyingName;
          this.endTime = res["data"].endTime;

          const item = this.panicBuyingSessions.find(
            (item) => item.panicBuyingName == this.panicBuyingName
          );
          this.selectItem = item.orderNum;
        });
    },
    getList(list) {
      this.list = [];
      for (let item of list) {
        item.suggestPrice = item.price;
        item.commodityImage = item.pic;
        item.commodityName = item.brand + item.commodityName;
        item.commodityId = item.commodityId;
      }
      list.map((item, index) => {
        if (index % 6 == 0) {
          this.list.push(list.slice(index, index + 6));
        }
      });
    },
    itemRender(current, type, originalElement) {
      const a = document.createElement("a");

      if (type === "prev") {
        a.innerHTML = "上一页";
        return a;
      } else if (type === "next") {
        a.innerHTML = "下一页";
        return a;
      }
      return originalElement;
    },
  },
});
</script>

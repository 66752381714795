



















































    import {Component, Vue} from "vue-property-decorator";
    import {Icon, Tag} from "ant-design-vue";
    import commercialTenantItem from "../components/commercialTenantItem.vue";
    import InstitutionInfo from "@/components/InstitutionInfo.vue";
    import {serviceItems, serviceProvider} from "@/components/Recommended";

    Vue.use(Icon);
Vue.use(Tag);

@Component({
  props: {
    pagination: Object,
    orgList: Object,
  },
  components: {
    commercialTenantItem,
    InstitutionInfo,
    serviceItems,
    serviceProvider,
  },
})
export default class extends Vue {
  selectChangetsuahValue: any = null;
  cancelServiceList: any = []


  mounted() {
    this.selectChangetsuahValue = undefined;
    this.cancelServiceList = [{id: 1, name: "按照评分高低"}, {id: 2, name: "入驻时间从早到晚"}, {id: 3, name: "入驻时间从晚到早"}]
  }

  onchange(page: any, pageSize: any) {
    window.scrollTo({top: 0});
    this.$emit("changePage", page);
  }

}


































































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Cascader, Form, Input, message, Modal} from "ant-design-vue";
    import addressLocationItem from "@/components/addressLocationItem.vue";
    import {deleteShippingAddress, shippingAddress, updateDefaultShippingAddress} from "@/services/api";
    import addressLocationAdd from "@/components/Personal/addressLocationAdd.vue";

    Vue.use(Form);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Cascader);

@Component({
  components: {addressLocationItem, addressLocationAdd},
})
export default class extends Vue {
  editDataTwo: any = {
    receiverName: "",
    provinceValue: [],
    spercificAddress: "",
    zipCode: "",
    phoneNumber: "",
    isDefaut: "",
    receiverStreet: null,
    receiverArea: null,
    receiverCity: null,
    receiverProvince: null,
  };
  loading:any=true

  addressSuccess() {
    this.getLocationList();
    this.visible = false;
  }

  shouHuoaddAddress(event) {
    event.preventDefault();
    this.receiverName = "";
    this.phoneNumber = "";
    this.receiverDetailAddress = "";
    this.zipCode = "";
    this.editTypeTwo = 0;
    this.editDataTwo = {
      receiverName: "",
      phoneNumber: "",
      receiverDetailAddress: "",
      zipCode: "",
      provinceValue: [],
      isDefaut: false,
      receiverStreet: 0,
      receiverArea: 0,
      receiverCity: 0,
      receiverProvince: 0,
      userId: JSON.parse(localStorage.getItem("userInfo") as string)[
          "userInfoId"
          ],
    };
    this.visible = true;
  }

  //设置默认地址
  handleSelectDefault(value) {
    updateDefaultShippingAddress({
      id: value,
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据
        message.success("默认地址更换成功");
        this.getLocationList();
      } else {
        message.error(`设置默认失败，错误原因：${res.msg}`);
      }
    });
  }

  columns = [
    {
      title: "收货人",
      dataIndex: "receiverName",
      align: "center",
      width: "1.37rem"
    },
    {
      title: "所在地区",
      scopedSlots: {customRender: "addressData"},
      align: "center",
      width: "2.6rem"
    },
    {
      title: "详细地址",
      scopedSlots: {customRender: "detail"},
      align: "center",
      width: "2.59rem"
    },
    // {
    //   title: "邮政编号",
    //   dataIndex: "zipCode",
    //   align: "center",
    //   width: "1.01rem"
    // },
    {
      title: "手机号",
      dataIndex: "phoneNumber",
      align: "center",
      width: "1.24rem"
    },
    {
      title: "是否默认",
      align: "center",
      scopedSlots: {customRender: "data"},
      width: "1.49rem"
    },
    {
      title: "操作",
      align: "center",
      colSpan: 2,//合并表头
      scopedSlots: {customRender: "modification"},
      width: "0.5rem",

    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,//合并表头
      scopedSlots: {customRender: "expurgate"},
      width: "0.5rem",

    }
  ];
  editTypeTwo = 0; // 新增或编辑 收货地址  0  新增 1  编辑
  visible = false;
  recycleBin = false;
  removeServiceId = '';
  provinceDefalutValue: any = []; // 级联选项默认值
  receiverName: any = "";
  receiverDetailAddress: any = "";
  zipCode: any = "";
  phoneNumber: any = "";
  // visible = false;
  addressList: any = []; // 服务地址信息列表
  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 15},
    },
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 15,
        offset: 10,
      },
    },
  };
  shopAddressPlaceholder = "";
  options = [];

  form: any;

  // 监听页面高度
  pageHeightCoefficient: number = window.innerHeight / 1080;

  @Watch("pageHeightCoefficient", {immediate: true, deep: true})
  setListerHeight(newval, oldnew) {
    this.pageHeightCoefficient = newval;
    const x = newval;
    let newHeight = (1.18463) * Math.pow(x, 0)
        + (-1.20676) * Math.pow(x, 1)
        + (-0.398135) * Math.pow(x, 2)
        + (0.755784) * Math.pow(x, 3)
        + (0.674309) * Math.pow(x, 4)
        + (-0.609826) * Math.pow(x, 5)
    this.$nextTick(() => {
      let findElement = document.querySelector('.shouHouLocation-page .location-box .ant-alert-info')
      if (findElement) {
        findElement['style']['height'] = `${newHeight}rem`
      }
      // $('.location-page .location-box .ant-alert-info').height = `${newHeight}rem`
    })
  }

  // created() {
  //   // this.form = this.$form.createForm(this);
  //   this.getLocationList();
  // }
  async created() {
    const that = this
    await this.getLocationList();
    window.onresize = () => {
      return (() => {
        that.pageHeightCoefficient = window.innerHeight / 1080
      })()
    }
  }

  maxAddressNum = 20;
  addressNum = 0;

  // 编辑服务地址
  editShippingAddress(data: any) {
    this.editTypeTwo = 1;
    this.editDataTwo = {
      ...data,

      receiverArea: +data.receiverArea,
      receiverCity: +data.receiverCity,
      receiverProvince: +data.receiverProvince,
      receiverStreet: +data.receiverStreet,
      receiverDetailAddress: data.receiverDetailAddress,
    };
    this.visible = true;
  }

  //删除服务地址提示
  handleDelete(value) {
    this.recycleBin = true;
    this.removeServiceId = value

  }

  //删除服务地址
  remove() {
    deleteShippingAddress({
      addressIdList: [this.removeServiceId],
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据
        message.success("删除成功");
        this.getLocationList()
      } else {
        message.error(`删除失败，错误原因：${res.msg}`);
      }
      this.recycleBin = false;
    });
  }

  //获取收货地址列表
  getLocationList() {
    this.addressList = [];
    shippingAddress().then((res) => {
      if (res.code === 0) {
        if (res.data.list.length > 0) {
          // 处理数据
          this.addressNum = res.data.addressNum;
          this.maxAddressNum = res.data.maxAddressNum;
          const index = res.data.list.findIndex((item: any, index: any) => {
            return item.isDefaut == 1;
          });
          let defaultObj = res.data.list[index];
          res.data.list.unshift(defaultObj);
          res.data.list.splice(index + 1, 1);
        }
        this.addressList = res.data.list;
        this.loading=false
      } else {
        message.error(`获取收货地址失败，错误原因：${res.msg}`);
      }
    });
  }

  // modal 关闭后回调
  modalClose() {
    this.editTypeTwo = 0;
  }
}

















































































































































































import {Component, Vue} from "vue-property-decorator";
import {message} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
import {getServiceList, openService2} from "@/services/api";
import aSpin from "@/components/aSpin.vue";

@Component({
    components: {
        PersonalHeader,
        OpenServiceItem,
        aSpin
    }
})
export default class extends Vue {
    currentRelationId = "";
    currentServiceType = 0;
    currentTab = 1;
    cancelVisible = false;
    loading = true;
    pagination = {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        queryStatus: 2
    };
    serviceStatus = 0;
    data = [];

    mounted() {
        // this.serviceStatus = 302;
        this.getServiceList();
    }

    getServiceList() {
        let data5566 = {
            ...this.pagination
        };
        getServiceList(data5566).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.returnInfos;
            this.pagination.pageSize = data.pageSize;
            this.loading = false;
        });
    }

    defaultValue = "";

    onSearch() {
        this.pagination["searchInput"] = this.defaultValue;
        this.emptyTextBySearch = true;
        this.getServiceList();
    }

    emptyTextBySearch = false;

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        this.getServiceList();
    }

    handleTabChange(key: any) {
        this.currentTab = key;
        this.emptyTextBySearch = false;
        if (key === "1") {
            // this.serviceStatus = 302;
            this.pagination["queryStatus"] = 2;
        } else {
            // this.serviceStatus = 303;
            this.pagination["queryStatus"] = 1;
        }
        this.pagination.pageIndex = 1;
        this.$nextTick(() => {
            this.getServiceList();
        });
    }

    setCancelModal(flag: boolean, relationId: string, serviceType: number) {
        this.cancelVisible = flag;
        this.currentRelationId = relationId;
        this.currentServiceType = serviceType;
    }

    handleOpenService(flag: boolean) {
        this.cancelVisible = flag;
        this.$router.push("/personal/classOpenServiceOne1");
    }

    openServiceEvent(flag: boolean) {
        this.cancelVisible = flag;
        openService2({
            relationId: this.currentRelationId
        }).then(res => {
            if (res.code === 0) {
                this.$router.push({
                    path: "/personal/classOpenServiceOne1",
                    query: {
                        relationId: this.currentRelationId
                    }
                });
            } else {
                message.error("开启服务失败，请稍后重试");
            }
        });

        // switch (this.currentServiceType) {
        //   case 432:
        //     //2类服务开启
        //     openService2({
        //       relationId: this.currentRelationId
        //     }).then(res => {
        //       if (res.code === 0) {
        //         this.$router.push({
        //           path: "/personal/classOpenServiceOne1",
        //           query: {
        //             relationId: this.currentRelationId
        //           }
        //         });
        //       } else {
        //         message.error("开启服务失败，请稍后重试");
        //       }
        //     });
        //     break;
        //   case 434:
        //     break;
        // }
    }
}






































import {Component, Vue} from "vue-property-decorator";
import InfoCard2 from "@/components/InfoCard2.vue";

@Component({
    components: {
        InfoCard2
    },
    props: {
        cardImage: String,
        cardTitle: String,
        cardContent: String,
        showMore: Boolean,
        servicesList: Array,
        categoryId: String,
        orgId: String,
        thirdServiceId: String,
        thirdServiceName: String
    }
})
export default class InfoCard extends Vue {

    gotopage(cardTitle: string) {
        this.$emit("gotopage", {
            title: this.$props.cardTitle,
            orgId: this.$props.orgId,
            categoryId: this.$props.categoryId,
            thirdServiceId: this.$props.thirdServiceId
        });
    }
}

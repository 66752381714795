



















































































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Input, message} from "ant-design-vue";
import RightOrderDetail from "@/components/RightOrderDetail.vue";
import GoodsRightOrderDetail from "../../../components/GoodsRightOrderDetail.vue";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import highConfigurationStep from "../../../components/highConfigurationStep.vue";
import {
    addressSeller,
    aftersalesApplicationStatus,
    applicationDetails,
    DetailsRefund,
    dictionariesWithDesc,
    expressDelivery,
    NegotiationHistory,
    refundInformation
} from "@/services/api";
import ReturnStep from "@/components/ReturnStep.vue";

Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
@Component({
    components: {
        ReturnStep,
        RightOrderDetail,
        GoodsRightOrderDetail,
        Breadcrumb,
        highConfigurationStep

    }
})
export default class extends Vue {
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的商品",
            path: "/personal/MyGoods",
            significance: 0
        },
        {
            name: "申请退款",
            path: "/",
            significance: 1
        },
    ];
    serviceHistory: any = []; // 协商历史
    stepList = [
        {
            name: "买家申请退款"
        },
        {
            name: "商家处理退款申请"
        },
        {
            name: "买家退货"
        },
        {
            name: "退款完毕"
        }
    ];
    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 9}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 15}
        }
    };

    tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    };
    form: any;
    status = false;
    Obj: Object = {};
    serviceItem = {};
    detailsRefund = {};
    addressTheSeller = {};
    courierServicesList = {};
    expressDeliveryData = {};
    statusDic = '';

    mounted() {
        let commodityRelationId = this.$route.query.commodityRelationId;
        let returnId = this.$route.query.returnId;
        this.orderInfo(commodityRelationId);
        this.afterSale(returnId)
        this.address(returnId);
        this.expressDeliveryList(returnId);
        this.courierServices();
        this.seeRefund(returnId)
        this.negotiation(returnId)
    }

    //查询售后申请状态
    seeRefund(value) {
        aftersalesApplicationStatus({returnId: value}).then(res => {
            this.statusDic = res.data.statusDic;
        });

    }

    beforeCreate() {
        this.form = this.$form.createForm(this);
    }

    //姓名加密
    getNickName(value) {
        if(value.length>3){
            const name: any[] = value.split("");
            return name[0] + "*****" + name[name.length - 1];
        }else{
            return  value
        }
    }

    //获取快递公司
    courierServices() {
        //获取物流数据
        dictionariesWithDesc(176).then(res => {
            let courierServicesList = {}
            res.values.forEach(item => {
                courierServicesList[item.key] = item.value;
            })
            this.courierServicesList = courierServicesList;
        })
    }

    //获取详情页面数据
    orderInfo(commodityRelationId) {
        DetailsRefund({commodityRelationId: commodityRelationId}).then(res => {
            this.serviceItem = res.data;
        });
    }

    //查看售后申请详情
    afterSale(value) {
        applicationDetails({
            returnId: value
        }).then(res => {
            if (res.data != '') {
                this.detailsRefund = res.data;
                this.status = true;
            }
        });

    }

    //查询退换货快递信息
    expressDeliveryList(value) {
        expressDelivery({
            returnId: value
        }).then(res => {
            if (res.data != '') {
                this.expressDeliveryData = res.data;
            }
        });

    }

    //获取商家地址
    address(value) {
        addressSeller({
            returnId: value
        }).then(res => {
            if (res.data != '') {
                this.addressTheSeller = res.data;
            } else {
                message.error(res.data.msg)
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
            if (!err) {
                for (let [key, value] of Object.entries(this.courierServicesList)) {
                    if (value === values.reason) {
                        values["reason"] = Number(key);
                    }
                }  //快递单号
                this.returnMoney(values);
            }
        });
    }

    //提交快递单号
    returnMoney(value) {
        refundInformation({
            expressOrderNum: value.expressOrderNum,
            companyId: value.reason,
            returnId: this.$route.query.returnId
        }).then(res => {
            if (res.code === 0) {
                message.success("提交成功，请等待商家收货。")
                this.$router.push({
                    path: "/personal/returngoods",
                });
            } else {
                message.error("提交失败，请稍后重试");
            }
        });
    }

    //获取协商历史
    negotiation(returnId) {
        NegotiationHistory({returnId: returnId}).then(res => {
            this.serviceHistory = res.data;
        });
    }

}




































import {Component, Prop, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";

Vue.use(Rate);

@Component({
  props: {
    avatarImg: String,
    nickName: String,
    rate: Number,
    comment: String
  }
})
export default class ServiceComment2 extends Vue {
  @Prop() private all!: any;
  getNickName() {
    if (this.all && this.all.userNickName) {
      const name: any[] = this.all.userNickName.split("");
      return name[0] + "*****" + name[name.length - 1];
    }
    return "";
  }
}




























import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class extends Vue {
  @Prop() private score!: number;

  get title() {
    if (this.score <= 50) return "信誉较差"
    if (this.score <= 70) return "信誉一般"
    if (this.score <= 85) return "信誉良好"
    if (this.score <= 100) return "信誉优秀"
    return ''
  }

  get styleWidth() {
    let width = 295.31 * (this.score / 100);
    return width;
  }

  mounted() {
    // 计算
  }
}














































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import reservationListItem from "@/components/Personal/reservationList/reservationListItem.vue";
import {diagnosisTreat, dictionariesWithDesc} from "@/services/api";
import aSpin from "@/components/aSpin.vue";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        PersonalHeader,
        reservationListItem,
        aSpin
    }
})
export default class extends Vue {
    @Prop() public emptyTextBySearch: any;
    allReservationStatus: any = {
        0: "预约单状态",
        239: "已预约",
        240: "进行中",
        241: "已完成",
        243: "已取消",
    };
    agencyAppointmentForm = false
    statusKey = 0;
    loading = true;
    @Prop({
        type: Number,
        required: true,
        default: null
    })
    public status!: null;

    @Watch("status", {immediate: true, deep: true})
    getStatus(newVal, oldVal) {
        this.statusKey = newVal;
        // this.reservationOrderList(newVal, "");
        if (newVal === 0) {
            this.reservationOrder(newVal, "")
        } else {
            this.reservationOrderList(newVal, "");
        }
    }

    cancelReason = [];

    created() {
        //取消预约单原因
        dictionariesWithDesc(183).then((res: any) => {
            this.cancelReason = res["values"];
        });
    }

    data = [];
    pagination:any = {
        total: 0,
        limit: 5,
        page: 1
    };
    //input框查询数据
    getSearchItem(val) {
        this.pagination.pageIndex = 1;
        this.reservationOrderList(this.statusKey, val);
    }
    //获取未选中的数据(tab切换显示用的数据)
    reservationOrder(status: Number, searchInput: String) {
        if (searchInput) {
            // this.emptyTextBySearch = true;
            this.$emit("emptyTextBySearch", true);
        } else {
            this.$emit("emptyTextBySearch", false);
            // this.emptyTextBySearch = false;
        }
        status = status || this.statusKey;
        diagnosisTreat({
            ...this.pagination,
            status: status === 0 ? 0 : status === 1 ? 239 : status === 2 ? 240 : status === 3 ? 241 : status === 4 ? 243 : status === 5 ? 1 : status,
            serviceName: searchInput
        }).then(res => {
            let data = res.page;
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.loading=false
            this.pagination.limit = data.pageSize;
        });
    }

    //提交预约单
    reservationOrderList(status: Number, searchInput: String) {
        if (searchInput) {
            this.$emit("emptyTextBySearch", true);
        } else {
            this.$emit("emptyTextBySearch", false);
        }
        status = status || this.statusKey;
        diagnosisTreat({
            ...this.pagination,
            status: status === 0 ? 0 : status === 1 ? 239 : status === 2 ? 240 : status === 3 ? 241 : status === 4 ? 243 : status === 5 ? 1 : status,
            serviceName: searchInput
        }).then(res => {

            let data = res.page;
            if (data.list.length===0) {
                this.$emit("emptyTextBySearch", true);
            }
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.loading=false
            this.pagination.limit = data.pageSize;
        });
    }

    againGetList() {
        this.pagination.page = 1;
        this.reservationOrderList(this.statusKey, "");
    }

    stateKeyShow = "预约单状态";

    //切换状态获取预约单
    reversationStatusChange(key, name) {
        this.agencyAppointmentForm=false
        this.emptyTextBySearch = false;
        this.stateKeyShow = name;
        this.pagination.page = 1;
        if(key==="0"){
            this.reservationOrderDropList('0');
        }else{
            this.reservationOrderDropList(key);
        }
    }

    reservationOrderDropList(key) {
        diagnosisTreat({
            ...this.pagination,
            status: key
        }).then(res => {
            let data = res.page;
            this.pagination.total = data.totalCount;
            this.data = data.list;
            this.pagination.limit = data.pageSize;
        });
    }

    //分页数据
    onChangePageSize(page, pageSize) {
        window.scrollTo(0, 0);
        this.pagination.page = page;
        this.reservationOrderList(this.statusKey, "");
    }
}
























































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
    cancellationOfOrder,
    confirmReceipt,
    fetchProvinceName,
    goIntoOvertime,
    invoicesInfo,
    invoicingCommodityOrders,
    PushOrderReason,
    saveTheInvoice,
    unpaidInformation
} from "@/services/api";
import {message} from "ant-design-vue";
import {Mutation, State} from "vuex-class";
import {mallUrl} from "@/services/http";

@Component({
    props: {
        status: Number
    }
})
export default class DetailInfo extends Vue {
    @Prop() commodityData: any;
    @Prop() shippingInfo: any;
    @Mutation SET_CANCELRESON;
    @State cancelReason;
    detailedAddress = "";
    servingTimeLeft = '';
    serveComplete = false;
    extendReceiving = false;
    servingTimeLeftInterval: any;
    serveCancel: Boolean = false;
    selectChangetsuahValue: any = [];
    cancelServiceList = {};
    informationData: any = {};
    shopAddressPlaceholder = "";

    province: any = '';
    city: any = '';
    area: any = '';
    street: any = '';

    orderId: any = '';
    makeOutCheque: Boolean = false;
    invoiceType: any = 0;
    invoiceContent = 0;
    invoiceTitleType = 0;
    taxpayerId: any = "";
    receiverEmail: any = "";
    personage: any = "";
    invoiceId: any = '';
    checkTheInvoice: Boolean = false;
    disabled: Boolean = true;


    //监听获取地址信息和收货时间倒计时
    @Watch("commodityData", {immediate: true, deep: true})
    addressList(newVal) {
        if (newVal != '') {
            this.orderId = newVal.orderId
            this.invoiceId = newVal.invoiceId
            if (newVal.status == 263) {
                this.servingTimeCaculate(newVal.shippingInfo.maxRemainingConfirmTime);
            } else if (newVal.status == 261) {
                this.servingTimeCaculate(newVal.maxRemainingPayTime);
            }
            fetchProvinceName({
                province: newVal.addressProvince,
                city: newVal.addressCity,
                area: newVal.addressArea,
                street: newVal.addressStress
            }).then(res => {
                if (res.code == 0) {
                    const add = res.info.addr;
                    this.detailedAddress = `${add.province} ${add.city} ${add.area}${add.street ? ` ${add.street}` : ""}`;
                } else {
                    message.error(res.msg);
                }
            })

        }
    }

    //取消商品订单列表
    getCancelServiceList() {
        PushOrderReason({}).then(({code, data}: any) => {
            if (code == 0) {
                this.cancelServiceList = data;
            }
        })
    }

    //收货时间倒计时
    servingTimeCaculate(dateTime) {
        let {days, hours, minutes, seconds} = dateTime;
        this.servingTimeLeft = `${days}天${hours}小时${minutes}分${seconds}秒`;
        var lefttime = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
        this.servingTimeLeftInterval = setInterval(() => {
            lefttime--;
            var d = Math.floor(lefttime / (24 * 60 * 60));
            var h = Math.floor((lefttime / (60 * 60)) % 24);
            var m = Math.floor((lefttime / 60) % 60);
            var s = Math.floor(lefttime % 60);
            this.servingTimeLeft = `${d}天${h}小时${m}分${s}秒`;
        }, 1000);
    }

    beforeDestroy() {
        clearInterval(this.servingTimeLeftInterval);
    }


    //确认收货
    serviceOrderConfirm(orderId) {
        confirmReceipt({
            orderInfoId: orderId,
        }).then((res) => {
            if (res.code === 0) {
                message.success("确认成功");
                this.$router.push("/personal/MyGoods");
                this.$emit("againGetList");
            } else {
                message.error("确认失败");
            }
        });
        this.serveComplete = false;
    }

    //申请退款
    applicationDrawback(value) {
        for (var i = 0; i < value.length; i++) {
            const {href} = this.$router.resolve({
                path: "/personal/AskBuyAfter",
                query: {
                    params: JSON.stringify(value[i])
                }
            });
            window.open(href, "_blank");
        }

    }

    //发票类型
    InvoiceType(value) {
        this.invoiceType = value
    }

    //发票信息
    invoiceContentClick(value) {
        this.invoiceContent = value
    }
    //发票抬头切换
    radioChange(e) {
        this.invoiceTitleType = e.target.value;
    }
    //保存发票
    saveInvoice() {
        let invoiceInfo: any = {
            invoiceType: this.invoiceType === 0 ? false : true,
            invoiceContent: this.invoiceContent === 0 ? false : true,
            titleType: this.invoiceTitleType === 0 ? false : true,
            invoiceTitle: this.invoiceTitleType === 0 ? this.personage : this.receiverEmail,
        };
        if (this.invoiceTitleType === 1) {
            (invoiceInfo["taxpayerId"] = this.taxpayerId)
        }
        saveTheInvoice(invoiceInfo).then(res => {
            if (res && res.code == 0) {
                invoicingCommodityOrders({
                    orderId: this.orderId,
                    invoiceInfoId: res.invoiceId,
                }).then(res => {
                    if (res && res.code == 0) {
                        message.success('保存成功');
                        this.makeOutCheque = false;
                        this.$emit("lookDetail");
                    } else {
                        message.error(res.code)
                    }
                })
            }
        })
    };
    //发票详情
    invoiceApply() {
        invoicesInfo({
            invoiceId: this.invoiceId
        }).then(res => {
            this.checkTheInvoice=true
            this.invoiceType= res.data.invoiceTypeId;
            this.invoiceContent=res.data.invoiceContentId;
            this.invoiceTitleType = res.data.titleTypeId;
            this.receiverEmail = res.data.invoiceTitle;
            this.taxpayerId = res.data.taxpayerIdentificationNumber;
            this.personage = res.data.invoiceTitle;
        })
    }

    //样板间服务
    productData() {
        this.$router.push("/personal/classProductService1");
    }

    //取消订单提示
    cancellationOrder(key: boolean) {
        this.getCancelServiceList()
        this.serveCancel = key;
    }

    //取消订单
    selectChange(orderId) {
        if (this.selectChangetsuahValue.length === 0) {
            message.error("请选择取消订单理由");
            return
        } else {
            cancellationOfOrder({
                orderInfoId: orderId,
                cancelReason: this.selectChangetsuahValue
            }).then((res) => {
                if (res.code === 0) {
                    message.success("取消订单成功");
                    this.$router.push("/personal/MyGoods");
                    this.$emit("againGetList");
                } else {
                    message.error(res.msg);
                }
            });
            this.serveComplete = false;
        }


    }

    //进行评价
    evaluate(value) {
        this.$router.push({
            path: '/personal/goodsJudgeService',
            query: {
                orderId: value,
            }
        })
    }

    //延长收货时间
    overtime(value) {
        goIntoOvertime({
            orderInfoId: value,
        }).then((res) => {
            if (res.code === 0) {
                message.success("延长收货成功");
                this.$router.push("/personal/MyGoods");
                this.$emit("againGetList");
            } else {
                message.error(res.msg);
            }
        });
        this.extendReceiving = false;
    }

    //获取付款信息
    //付款
    payment(value) {
        unpaidInformation({
            orderId: value,
        }).then((res) => {
            if (res.code === 0) {
                this.informationData = res.data;
                //根据地址id获取对应详情
                fetchProvinceName({
                    province: this.informationData.receiveInfo.addrProvince,
                    city: this.informationData.receiveInfo.addrCity,
                    area: this.informationData.receiveInfo.addrArea,
                    street: this.informationData.receiveInfo.addrStreet
                }).then(res => {
                    if (res.code == 0) {
                        const add = res.info.addr;
                        this.province = add.province;
                        this.city = add.city;
                        this.area = add.area;
                        this.street = add.street;

                        const allcontent = this.informationData;
                        const obj = {
                            commodityAddressInfo: {
                                province: this.province,
                                city: this.city,
                                area: this.area,
                                street: this.street,
                                addressDetail: allcontent.receiveInfo.detailAddress,
                                phoneNumber: allcontent.receiveInfo.receivePhoneNum,
                                receiverName: allcontent.receiveInfo.receiveName,
                            },
                            mainCommodityOrderCode: allcontent.mainCommodityOrderCode,
                            payTotal: allcontent.totalPayAmount,
                            resultOrderInfos: this.informationData.commodity,
                            mainCommodityType: "我的商品"
                        };
                        open(mallUrl + `/mall/checkstand?orderInfo=${JSON.stringify(obj)}`);
                    } else {
                        message.error(res.msg);
                    }
                });


            } else {
                message.error(res.msg);
            }
        });

    }
}

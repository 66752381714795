


























































































import {Component, Vue} from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import Facilitator from "./Facilitator.vue";
import ServiceProject from "./ServiceProject.vue";
import WholeService from "./WholeService.vue";
import nursingInstitutionAged from "./nursingInstitutionAged.vue";
import {fetchOrganizationsForSearch, fetchSearchService, fetchServiceProviderForSearch} from "@/services/api";
import {serviceItems, serviceProvider} from "@/components/Recommended";

@Component({
    components: {
        Nav,
        Footer,
        Header,
        Facilitator,
        ServiceProject,
        WholeService,
        serviceItems,
        serviceProvider,
        nursingInstitutionAged
    },
})
export default class extends Vue {
    currentTab = "1";
    searchContent = "";
    //服务项目
    projectPagination: any = {
        pageNum: 1,
        pageSize: 40,
        total: 1,
    };
    //服务商
    orgPagination: any = {
        pageNum: 1,
        pageSize: 10,
        total: 1,
    };
    //养老机构
    organizationtPagination: any = {
        pageNum: 1,
        pageSize: 10,
        total: 1,
    }

    mounted() {
        this.currentTab = this.$route.query.moreType
            ? +this.$route.query.moreType + 1 + ""
            : "1";
    }

    //我们离开这个页面的时候清空input中内容
    destroyed() {
        sessionStorage.setItem("search", "");
    }

    dbaskbd(e, zero) {
        this.currentTab = e == 't1' ? '3' : (e === "t2" ? '2' : '4');
        if (e == "t2" && zero[1] == 0) {

            setTimeout(() => {
                const el: any = document.getElementById("allDataButnska");
                el.click();
            });
        }
        if (e == "t3" && zero[0] == 0) {
            setTimeout(() => {
                const el: any = document.getElementById("allDAtaasdadsa");
                el.click();
            });
        }
    }

    searchnnn() {
        this.searchContent = "";
        this.changePage2(1);
        this.changePage1(1);
        this.changePage(1);
        (this.$refs.Nav as any).resetSearchVal();
        sessionStorage.setItem("search", "");
    }

    searchParams: any = {
        pageIndex: 1,
        pageSize: 10,
        searchInput: "",
        cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
        // cityId: 2,
        areaId: undefined,
        orgForm: undefined,
        orgType: undefined,
        orgBedNum: undefined,
        orgCharacter: undefined,
        lowestPrice: undefined,
        serviceObject: undefined,
        specialService: undefined,
    };
    organizationParams: any = {
        pageIndex: 1,
        pageSize: 20,
        searchInput: "",
        cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
        // cityId: 2,
        areaId: undefined,
        orgForm: undefined,
        orgType: undefined,
        orgBedNum: undefined,
        orgCharacter: undefined,
        lowestPrice: undefined,
        serviceObject: undefined,
        specialService: undefined,
    };

    projectSearchParams: any = {
        pageIndex: 1,
        pageSize: 40,
        searchInput: "",
        // cityId: 2
        cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
    };

    serviceProjectList: any[] = [];
    orgList: any[] = [];
    getOrganizationList: any[] = [];
    serviceClassificationId: any = "";

    async created() {
        const value = sessionStorage.getItem("search") || "";
        if (sessionStorage.getItem("serviceClassificationIdsasadd")) {
            this.serviceClassificationId = sessionStorage.getItem("serviceClassificationIdsasadd");
            this.handleSearch2({
                ...this.organizationParams,
                serviceItemType: this.serviceClassificationId,
            }).then((res) => (this.currentTab = "4"));
            this.handleSearch1({
                ...this.projectSearchParams,
                serviceItemType: this.serviceClassificationId,
            }).then((res) => (this.currentTab = "4"));
        } else {
            await this.getSearchInput(value);
        }
    }

    callback(value: any) {
        this.currentTab = value;
        this.projectPagination.pageNum = 1;
        this.projectSearchParams.pageIndex = 1;
        this.organizationParams.pageIndex = 1;
        const searchInput = sessionStorage.getItem("search") || "";
        this.faSongQuery(searchInput);
        let spdbjndsa1 = this.$refs.spdbjndsa1 as any;
        let spdbjndsa2 = this.$refs.spdbjndsa2 as any;
        let spdbjndsa3 = this.$refs.spdbjndsa3 as any;
        if (spdbjndsa2) spdbjndsa2["currentTypeChecked"] = -1;
        if (spdbjndsa3) spdbjndsa3["currentTypeChecked"] = -1;
        // this.serviceClassificationId || ;
        if (spdbjndsa1) spdbjndsa1.resetCHecks();
    }

    //分页
    changePage(value) {
        this.projectSearchParams.pageIndex = value;
        this.projectSearchParams = {
            ...this.projectSearchParams,
            searchInput: this.searchContent,
        };
        window.scrollTo({top: 0});
        this.handleSearch1(this.projectSearchParams);
    }

    changePage1(value) {
        this.searchParams.pageIndex = value;
        this.searchParams = {
            ...this.searchParams,
            searchInput: this.searchContent,
        };
        window.scrollTo({top: 0});

        this.handleSearch(this.searchParams);
    }

    changePage2(value) {
        this.searchParams.pageIndex = value;
        this.searchParams = {
            ...this.searchParams,
            searchInput: this.searchContent,
        };
        window.scrollTo({top: 0});

        this.handleSearch2(this.searchParams);
    }


    async getSearchInput(value: any) {
        this.searchContent = value;
        await this.faSongQuery(this.searchContent);
    }

    async faSongQuery(value: any) {
        switch (this.currentTab) {
            case "1": {
                this.organizationParams = {
                    pageSize: this.organizationParams.pageSize,
                    cityId: this.organizationParams.cityId,
                    pageIndex: this.organizationParams.pageIndex,
                    searchInput: decodeURIComponent(value),
                };
                await this.handleSearch2(this.organizationParams);
                this.searchParams = {
                    // ...this.searchParams,
                    pageSize: this.searchParams.pageSize,
                    cityId: this.searchParams.cityId,
                    pageIndex: this.searchParams.pageIndex,
                    searchInput: decodeURIComponent(value),
                };
                this.handleSearch(this.searchParams);
                this.projectSearchParams = {
                    pageSize: this.projectSearchParams.pageSize,
                    cityId: this.projectSearchParams.cityId,
                    pageIndex: this.projectSearchParams.pageIndex,
                    searchInput: decodeURIComponent(value),
                };
                await this.handleSearch1(this.projectSearchParams);


                break;
            }
            case "2": {
                this.searchParams = {
                    ...this.searchParams,
                    searchInput: decodeURIComponent(value),
                };
                this.handleSearch(this.searchParams);
                break;
            }
            case "3": {
                this.projectSearchParams = {
                    ...this.projectSearchParams,
                    searchInput: value,
                };
                this.handleSearch2(this.projectSearchParams);
                break;
            }
            case "4": {
                let projectSearchParamsList:any={};
                projectSearchParamsList = {
                    'cityId': this.projectSearchParams.cityId,
                    'pageIndex': this.projectSearchParams.pageIndex,
                    'pageSize': 40,
                    'searchInput': value,
                }
                this.projectSearchParams = {
                    ...this.projectSearchParams,
                    searchInput: value,
                };
                this.handleSearch1(projectSearchParamsList);
                break;
            }
        }
    }

    //机构按条件查询
    organizationFacilitatorSearch(key: any, value: any) {
        const that = this
        if (!Array.isArray(key)) {
            that.searchParams = {
                ...that.searchParams,
                [key]: value !== 0 ? value : undefined,
                pageIndex: 1,
            };
        } else {
            if (key.length == value.length) {
                // 多个参数
                for (let i = 0; i < key.length; i++) {
                    that.searchParams[key[i]] = value[i]
                }
                that.searchParams = {
                    ...that.searchParams,
                    pageIndex: 1,
                };
            } else {
                key.forEach((item) => {
                    delete that.searchParams[item];
                });
                (that.$refs.Nav as any).resetSearchVal();
                that.searchParams.searchInput = "";
                that.searchParams.pageIndex = 1;
                that.searchContent = "";
                sessionStorage.setItem("search", "");
            }

        }
        that.searchParams['cityId'] = localStorage.getItem('provinceId')
        that.handleSearch2(that.searchParams);
    }

    //服务商按条件查找
    organizationSearch(key: any, value: any) {
        if (!Array.isArray(key)) {
            this.searchParams = {
                ...this.searchParams,
                [key]: value !== 0 ? value : undefined,
                pageIndex: 1,
            };
        } else {
            key.forEach((item) => {
                delete this.searchParams[item];
            });
            (this.$refs.Nav as any).resetSearchVal();
            this.searchParams.searchInput = "";
            this.searchParams.pageIndex = 1;
            this.searchContent = "";
            sessionStorage.setItem("search", "");
        }
        this.handleSearch(this.searchParams);
    }

    //服务商按条件查询
    handleFacilitatorSearch(key: any, value: any) {
        const that = this
        if (!Array.isArray(key)) {
            that.searchParams = {
                ...that.searchParams,
                [key]: value !== 0 ? value : undefined,
                pageIndex: 1,
            };
        } else {
            key.forEach((item) => {
                delete that.searchParams[item];
            });
            (that.$refs.Nav as any).resetSearchVal();
            that.searchParams.searchInput = "";
            that.searchParams.pageIndex = 1;
            that.searchContent = "";
            sessionStorage.setItem("search", "");
        }
        this.handleSearch(that.searchParams);
    }

    //服务项目按条件查找
    handleProjectSearch(key: any, value: any,typeClass:any) {
        if (!Array.isArray(key)) {
            this.projectSearchParams = {
                ...this.projectSearchParams,
                [key]: value !== 0 ? value : undefined,
                pageIndex: 1,
                typeClass:typeClass
            };
        } else {
            key.forEach((item) => {
                delete this.projectSearchParams[item];
            });
            (this.$refs.Nav as any).resetSearchVal();
            this.projectSearchParams.searchInput = "";
            this.projectSearchParams.pageIndex = 1;
            this.searchContent = "";
            sessionStorage.setItem("search", "");
        }
        this.handleSearch1(this.projectSearchParams);
    }

    //查询获取的养老机构
    handleSearch2(params: any) {
        const that = this
        that.getOrganizationList.length = 0;
        return new Promise((resolve, reject) => {

            fetchOrganizationsForSearch(params).then(res => {
                if (res && res.code == 0) {
                    resolve(true);
                    if (res.data && res.data.list) {
                        that.getOrganizationList = res.data.list;
                        this.organizationtPagination.pageNum = res.data.totalPage;
                        this.organizationtPagination.total = res.data.totalCount;
                    }
                }
            }).catch((e) => reject());
        });
    }

    //查询获取的服务商
    handleSearch(params: any) {
        let data:any={}
        data={
            areaId:params.areaId,
            cityId:params.cityId,
            pageIndex:params.pageIndex,
            pageSize:20,
            searchInput:params.searchInput,
        }
        const that = this
        this.orgList.length = 0;
        fetchServiceProviderForSearch(data).then(res => {
            if (res && res.code == 0) {
                that.orgList = res.data.list;
                that.orgPagination.pageNum = res.data.totalPage;
                that.orgPagination.total = res.data.totalCount;
            }
        })
    }

    //查询获取的服务项目
    handleSearch1(params: any) {
        let data:any={}
         data={
            cityId:params.cityId,
            pageIndex:params.pageIndex,
            pageSize:params.pageSize,
            searchInput:params.searchInput,
            serviceItemType:params.serviceItemType,
            typeClass:params.typeClass,
        }
        const that = this
        this.serviceProjectList.length = 0;
        return new Promise((resolve, reject) => {
            fetchSearchService(data).then((res) => {
                if (res.code === 0) {
                    resolve(true);
                    if (res.data.serviceList && res.data.serviceList.serviceListInfo) {
                        this.serviceProjectList = [];
                        res.data.serviceList.serviceListInfo.forEach((item: any) => this.serviceProjectList.push(item));
                        this.projectPagination.pageNum = res.data.serviceList.totalPage;
                        this.projectPagination.total = res.data.serviceList.totalCount;
                    }
                } else reject();
            }).catch((e) => reject());
        });
    }
}

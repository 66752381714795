









































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Checkbox, Icon} from "ant-design-vue";
    import GoodsDetail from "@/components/Personal/myGoods/GoodsDetail.vue";
    import {serviceOrderDetal} from "@/services/api";
    import {Mutation} from "vuex-class";

    Vue.use(Icon);
Vue.use(Checkbox);

@Component({

  components: {
    GoodsDetail
  },
  props: {
    lastUpdateTime: String,
    serviceOrderInfoId: String,
    serviceFee: Number,
    // services: Array,
    contactPersonDetail: Object,
    totalAmount: Number,
    status: String,
    orgId: String,
    orderCode: String,
    agencyName: String
  }
})
export default class ServiceTrashItem extends Vue {
  @Prop() public services!: any;
  @Prop() private isAllCheck!: Boolean;
  @Prop() private id!: Number;
  @Prop() private ischeck!: Boolean;
  @Mutation SET_CURDETAILSTATUS;
  @Mutation SET_DETAILITEM;
  deleteModel: Boolean = false;
  curOrderStatus = {
    paixiafuwu: false,
    maijiafukuan: false,
    fuwutigongshang: false,
    querenfuwu: false,
    pingjia: false,
    guanbi: false
  };
  onChange(e, id, ischeck, serviceOrderInfoId) {
    if (!ischeck) {
      this.$emit("getOrderInfoList", {
        id: id,
        ischeck: ischeck,
        serviceOrderInfoId: serviceOrderInfoId
      });
    } else {
      this.$emit("deleteOrderInfoList", {
        id: id,
        ischeck: ischeck,
        serviceOrderInfoId: serviceOrderInfoId
      });
    }
  }
  singleDelete() {
    this.deleteModel = true;
  }
  //和我联系
  toChat(query) {
    let url = '/#/chat?'
    for (let key in query) {
      url += key + '=' + query[key] + '&'
    }
    url.substr(0, url.length - 1)
    open(url)
  }
  singleHuanYuan(serviceOrderInfoId) {
    this.$emit("singleHuanYuan", serviceOrderInfoId);
  }
  lookDetail(serviceOrderInfoId: any) {
    serviceOrderDetal({ serviceOrderInfoId: serviceOrderInfoId }).then(res => {
      if (res) {
        let data: any = (res as any).data || {};
        this.SET_DETAILITEM(data);
        switch (data.statusDic) {
          case 292: //未付款
            this.curOrderStatus["paixiafuwu"] = true;
            this.SET_CURDETAILSTATUS(this.curOrderStatus);
            // this.$router.push("/personal/serviceDetail1");
            break;
          case 293: //已付款
            this.curOrderStatus["maijiafukuan"] = true;
            this.SET_CURDETAILSTATUS(this.curOrderStatus);
            // this.$router.push("/personal/serviceDetail2");
            break;
          case 294: //已付款
            this.curOrderStatus["fuwutigongshang"] = true;
            this.SET_CURDETAILSTATUS(this.curOrderStatus);
            // this.$router.push("/personal/serviceDetail3");
            break;
          case 295: //已付款
            if (data.haveInvoice) {
              this.curOrderStatus["querenfuwu"] = true;
              this.SET_CURDETAILSTATUS(this.curOrderStatus);
              // this.$router.push("/personal/serviceDetail4");
            } else if (data.ifEvaluation) {
              this.curOrderStatus["guanbi"] = true;
              this.SET_CURDETAILSTATUS(this.curOrderStatus);
              // this.$router.push("/personal/serviceDetail6");
            } else {
              this.curOrderStatus["fuwutigongshang"] = true;
              this.SET_CURDETAILSTATUS(this.curOrderStatus);
              // this.$router.push("/personal/serviceDetail3");
            }
            break;
          case 296:
            this.curOrderStatus["guanbi"] = true;
            this.SET_CURDETAILSTATUS(this.curOrderStatus);
            // this.$router.push("/personal/serviceDetail6");
            break;
          default:
            this.curOrderStatus["guanbi"] = true;
            this.SET_CURDETAILSTATUS(this.curOrderStatus);
            // this.$router.push("/personal/serviceDetail6");
            break;
        }
        this.$router.push("/personal/serviceDetail");
      }
    });
  }
  comfrimDelete(serviceOrderInfoId) {
    this.$emit("singleDelete", serviceOrderInfoId);
  }
  mounted(){

}
}













































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Button, Form, Input, message, Radio, Select, Steps, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceProcessSteps from "./classServicesteps.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";
import {Mutation, State} from "vuex-class";
import {classServie31Detail, comfrimService, serviceConfirmExtend, serviceIfExtendConfirm,} from "@/services/api";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";

Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        ServiceProcessSteps,
        Breadcrumb,
        MyStepBar
    }
})
export default class extends Vue {
    @Mutation SET_CLASSSERVICEONESTEP;
    @Mutation SET_CLASSSERVICEDETAIL;

    /**
     * 确认服务的按钮事件
     */
    comfrimService() {
        const par = {
            relationId: this.classServieDetail.relationId,
            confirmType: 1
        };

        comfrimService({data: par}).then(({data, code, msg}: any) => {
            if (code == 0) {
                message.success(msg);
                window.close();
            } else {
                message.error(msg);
            }
        });
    }

    mounted() {
        const str1: any = localStorage.getItem("CLASSSERVICEONESTEP");
        this.SET_CLASSSERVICEONESTEP(JSON.parse(str1), true);
        const str2: any = localStorage.getItem("CLASSSERVICEDETAIL");
        this.SET_CLASSSERVICEDETAIL(JSON.parse(str2), true);
        this.currServiceDetailInfo = JSON.parse(str2)

        this.init();

        // localStorage.removeItem("CLASSSERVICEDETAIL");
        // localStorage.removeItem("CLASSSERVICEONESTEP");
    }

    @State classServieDetail;
    @State classServiceOneStep;
    relationId = "";
    delayIsShow = false;
    refundId: any;
    curStatus = {
        step1: false,
        step2: false,
        step3: false,
        step4: false
    };
    curStatusStep = 0;

    // 存储当前页面的服务详情信息，这居然是从数据仓库里面取出来的？
    currServiceDetailInfo: any = {}

    // @Watch("classServiceOneStep", {immediate: true, deep: true})
    // getCurStatus(newVal, oldVal) {
    //   if (newVal.step1) {
    //     this.curStatus.step1 = true;
    //     this.curStatusStep = 0;
    //   } else if (newVal.step2) {
    //     this.curStatus.step2 = true;
    //     this.curStatusStep = 1;
    //   } else if (newVal.step3) {
    //     this.curStatus.step3 = true;
    //     this.curStatusStep = 2;
    //   } else if (newVal.step4) {
    //     this.curStatus.step4 = true;
    //     this.curStatusStep = 3;
    //   }
    // }

    @Watch("classServieDetail", {immediate: true, deep: true})
    getdata(newVal, oldVal) {

        if (!(newVal && newVal.relationId)) return
        this.refundId = newVal.refundId;
        this.relationId = newVal.relationId;
        this.stepList[0].time = newVal.waitForServiceTime;
        this.stepList[1].time = newVal.servingTime;
        this.stepList[2].time = newVal.serviceFinishTime;
        this.serviceIfExtendConfirm();

        // 更新本地的数据内容
        this.currServiceDetailInfo = newVal
        // this.init()

        let columns456 = [
            {
                title: "服务名称",
                key: "service",
                dataIndex: "service",
                align: "center",
                width: "2.9rem",
                slots: {title: "service"},
                scopedSlots: {customRender: "service"}
            },
            {
                title: "服务方式",
                key: "serviceStyle",
                dataIndex: "serviceStyle",
                align: "center",
                width: "1.2rem"
            },
            {
                title: "服务人员",
                key: "servicePersonnelName",
                dataIndex: "servicePersonnelName",
                align: "center",
                width: "1.2rem"
            },
            {
                title: "联系方式",
                key: "contactTel",
                dataIndex: "contactTel",
                align: "center",
                width: "1.3rem"
            },
            {
                title: "服务价格",
                key: "price",
                dataIndex: "price",
                align: "center",
                width: "1.2rem"
            },
            {
                title: "服务费用",
                key: "tips",
                dataIndex: "tips",
                align: "center",
                width: "1.2rem"
            }
        ];

        if (newVal.serviceStyle && newVal.serviceStyle.toString().trim() != "到店服务")
            this.columns = columns456;

        // 这个意义不明的定时器是干嘛的？实时获取核销码？
        // let timeout = setTimeout(() => {
        //   // 这个页面基本上是获取 3.1 类服务的，这类服务的完成都是 301
        //   if (newVal.serviceStatusDic == 301) {
        //     // 服务已经完成了就不要再死循环了
        //     window.clearTimeout(timeout)
        //   }
        // }, 800);

        this.data = newVal.serviceStyle == "到店服务"
                ? [
                    {
                        key: 1,
                        service: [newVal.serviceName, newVal.pic, newVal.serviceId],
                        // institution: newVal.agencyName,
                        servicePersonnalName: newVal.servicePersonnalName,
                        serviceStyle: newVal.serviceStyle,
                        price: newVal.price,
                        merchantName: newVal.serviceStore.merchantName,
                        address: newVal.serviceStore.address,
                        tips: newVal.serviceFee.toFixed(2)
                    }
                ]
                : [
                    {
                        key: 1,
                        service: [newVal.serviceName, newVal.pic, newVal.serviceId],
                        servicePersonnelName: newVal.servicePersonnalName,
                        contactTel: newVal.contactTel,
                        serviceStyle: newVal.serviceStyle,
                        price: newVal.price,
                        tips: newVal.serviceFee.toFixed(2)
                    }
                ];
    }

    data: any = [
        {
            key: 1,
            service: ["", "", ""],
            servicePersonnalName: "",
            serviceStyle: "",
            price: "",
            tips: ""
        }
    ];
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的服务",
            path: "/personal/myService",
            significance: 0
        },
        {
            name: "服务详情",
            path: "/personal/classServiceOne",
            significance: 1
        },
    ]
    stepList = [
        {
            name: "等待服务商提供服务",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "服务进行中",
            time: "",
            img: require('@/assets/images/homeImg/flow-3.png'),
        },
        {
            name: "服务完成",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        }
    ];

    columns = [
        {
            title: "服务名称",
            key: "service",
            dataIndex: "service",
            align: "center",
            width: "3.2rem",
            slots: {title: "service"},
            scopedSlots: {customRender: "service"}
        },
        {
            title: "服务方式",
            key: "serviceStyle",
            dataIndex: "serviceStyle",
            align: "center",
            width: "1.5rem"
        },
        {
            title: "服务价格",
            key: "price",
            dataIndex: "price",
            align: "center",
            width: "1.5rem"
        },
        {
            title: "服务费",
            key: "tips",
            dataIndex: "tips",
            align: "center",
            width: "1.5rem"
        } ,
        {
            title: "门店名称",
            key: "merchantName",
            dataIndex: "merchantName",
            align: "center",
            width: "1.57rem"
        },
        {
            title: "门店地址",
            key: "address",
            dataIndex: "address",
            align: "center",
            width: "2.5rem"
        }
    ];

    serviceIfExtendConfirm() {
        serviceIfExtendConfirm({
            relationId: this.relationId
        }).then(res => {
            if (res["code"] == 0) {
                this.delayIsShow = res.data.ifShow;
                this.newEndTime = res.data.endTime;
            }
        });
    }

    applyReturnMoney() {
        this.$router.push({
            path: "/personal/returnService1",
            query: {
                relationId: this.relationId
            }
        });
    }

    newEndTime = "";

    startDelayTime() {
        var that = this;
        this.$confirm({
            title: `确认服务时间延长至${that.newEndTime},请及时完成服务，以免服务自动确认完成`,
            content: "您只能主动延长一次确认服务时间，延长后不能再次主动延长时间，且超出延长期后无法再次延长。",
            width: '5rem',
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                // serviceIfExtend({
                //   relationId: that.relationId
                // }).then(res => {
                //   if (res.data) {
                serviceConfirmExtend({relationId: that.relationId}).then(res => {
                    if (res.code == 0) {
                        this.delayIsShow = false;
                        message.success("申请延长确认服务时间成功");
                        that.$router.push("/personal/myService");
                    } else {
                        message.error("申请延长服务时间失败");
                    }
                });
                //   } else {
                //     message.error(res["msg"]);
                //   }
                // });
            },
            onCancel() {
            },
            class: "test"
        });
    }

    lookReturnMoney() {
        this.$router.push({
            path: "/personal/returnService2",
            query: {
                returnId: this.refundId,
                relationId: this.relationId
            }
        });
    }

    init() {
        this.serviceDetailEvent(this.currServiceDetailInfo.relationId, this.currServiceDetailInfo.serviceType)
    }

    /**
     * 获取服务详情数据，根据不同的服务类型去获取
     * @param relationId 服务-订单关联id
     * @param serviceType 当前服务的类型 是2类还是3类
     */
    serviceDetailEvent(relationId: String, serviceType: Number) {
        let callback: (params: any) => Promise<any>,   // 根据不同的服务类型去请求不同的接口
            switchServiceStatus: Function // 判断不同的状态
        // 判断类型
        switch (serviceType) {
            case 433: // 3.1类服务订单详情 线上线下相结合的服务（3.1类） 无需对接接口 如家政
                callback = classServie31Detail;
                switchServiceStatus = (currStatus: string) => {
                    const that = this
                    that.curStatus = {
                        step1: false,
                        step2: false,
                        step3: false,
                        step4: false
                    };
                    that.curStatusStep = 0;
                    switch (currStatus as any) {
                        case "等待服务":
                            that.classServiceOneStep["step1"] = true;
                            that.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            that.curStatus.step1 = true;
                            that.curStatusStep = 1;
                            break;
                        case "服务进行中":
                            that.classServiceOneStep["step2"] = true;
                            that.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            that.curStatus.step2 = true;
                            that.curStatusStep = 2;
                            break;
                        case "服务完成":
                            that.classServiceOneStep["step3"] = true;
                            that.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            that.curStatus.step3 = true;
                            that.curStatusStep = 3;
                            break;
                        case "已关闭":
                            that.classServiceOneStep["step4"] = true;
                            that.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
                            that.curStatus.step4 = true;
                            that.curStatusStep = 3;
                    }
                }
                break;
            default:
                callback = (params: any) => {
                    return new Promise((resolve, reject) => {
                        reject('服务类型错误');
                    });
                }
        }

        // 请求服务订单详细数据
        if (callback) {
            callback({
                relationId: relationId,
            }).then((res) => {
                if (res) {
                    let data: any;
                    data = (res as any).data;
                    data.serviceType = serviceType; // 类型也一起存进去
                    // 存储数据
                    this.SET_CLASSSERVICEDETAIL(data || {});
                    // 判断不同的状态
                    switchServiceStatus(data.serviceStatus);
                }
            });
        }
    }

}

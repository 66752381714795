<template>
    <div id="top">
        <Header/>
        <div class="ServiceJoinStep1">
            <div class="stepTop">
                <img src="../../assets/images/homeImg/组 11@2x.png" height="240" width="3840"/>
                <div class="rz-type">服务商入驻</div>
            </div>
            <div class="progressBar">
                <MyStepBar :stepData="stepData" :status="1"/>
            </div>
            <div class="form-box">
                <a-form :form="form" @submit="handleSubmit">
                    <a-form-item v-bind="formItemLayout" label="公司信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司名称">
                        <a-input
                                placeholder="请输入公司名称"
                                v-decorator="[
                'companyName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.companyNamecheck.bind(this),
                    },
                  ],
                  initialValue: formData.organizationInfo.companyName,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="统一社会信用代码">
                        <a-input
                                placeholder="请输入统一社会信用代码"
                                v-decorator="[
                'orgCode',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.companyCodecheck.bind(this),
                    },
                  ],
                  initialValue: formData.organizationInfo.orgCode,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司所在地">
                        <a-cascader
                                :options="options"
                                style="width: 3.53rem"
                                :placeholder="addrPlaceHolder.companyPlaceHolder"
                                :loadData="loadData"
                                @change="selectCompanyAddress"
                                v-decorator="[
                'companyAddress',
                {
                  initialValue: formData.organizationInfo.companyAddress,
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.companyAddrcheck.bind(this),
                    },
                  ],
                },
              ]"
                        >
                        </a-cascader>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司详细地址">
                        <a-input
                                placeholder="请输入公司详细地址"
                                v-decorator="[
                'companyAddressDetail',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '公司详细地址不能为空',
                    },
                  ],
                  initialValue: formData.organizationInfo.companyAddressDetail,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司电话">
                        <a-input
                                placeholder="请输入公司电话,如：021-87888822,13812345678"
                                v-decorator="[
                'companyContactNumber',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.phoneCheck.bind(this),
                    },
                  ],
                  initialValue: formData.organizationInfo.companyContactNumber,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="联系人及账户信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人姓名">
                        <a-input
                                placeholder="请输入法定代表人姓名"
                                v-decorator="[
                'lpName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.farenName.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.lpName,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人身份证号">
                        <a-input
                                placeholder="请输入法定代表人身份证号"
                                v-decorator="[
                'lpIdNum',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.infoCardCheck.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.lpIdNum,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="法定代表人联系方式">
                        <a-input
                                placeholder="请输入法定代表人联系方式"
                                v-decorator="[
                'lpTel',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.lpTel,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="银行开户名">
                        <a-input
                                placeholder="请输入银行开户名"
                                v-decorator="[
                'accountName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.farenName.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.accountName,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="银行开户账号">
                        <a-input
                                placeholder="请输入银行开户账号"
                                v-decorator="[
                'accountNumber',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '银行开户账号不能为空',
                    },
                    // {
                    //   validator: this.yinhanCard.bind(this)
                    // }
                  ],
                  initialValue: formData.accountInfo.accountNumber,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行名称">
                        <a-input
                                placeholder="请输入开户银行名称"
                                v-decorator="[
                'bankName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '开户银行名称不能为空',
                    },
                  ],
                  initialValue: formData.accountInfo.bankName,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行所在地">
                        <a-cascader
                                :options="options"
                                style="width: 3.53rem"
                                :placeholder="addrPlaceHolder.accountPlaceHolder"
                                :loadData="loadData"
                                @change="selectAccountAddress"
                                v-decorator="[
                'accountAddress',
                {
                  trigger: 'change',
                  initialValue: formData.accountInfo.accountAddress,
                  rules: [
                    {
                      required: true,
                      validator: this.accountAddrcheck.bind(this),
                    },
                  ],
                },
              ]"
                        >
                        </a-cascader>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="开户银行详细地址">
                        <a-input
                                placeholder="请输入开户银行详细地址"
                                v-decorator="[
                'accountDetailAdd',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '开户银行详细地址不能为空',
                    },
                  ],
                  initialValue: formData.accountInfo.accountDetailAdd,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司紧急联系人">
                        <a-input
                                placeholder="请输入公司紧急联系人"
                                v-decorator="[
                'emergencyContactName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.farenName.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.emergencyContactName,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="公司紧急联系人手机">
                        <a-input
                                placeholder="请输入公司紧急联系人手机"
                                v-decorator="[
                'emergencyContactPhone',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this),
                    },
                  ],
                  initialValue: formData.accountInfo.emergencyContactPhone,
                },
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="证件信息" class="tip"></a-form-item>
                    <div class="imageUpload">
                        <a-form-item v-bind="formItemLayout" label="法人身份证照片（正面）">
                            <a-upload
                                    name="file"
                                    :customRequest="lpIdPicturePositive"
                                    @preview="handlePicturePreview"
                                    :file-list="lpIdPicturePositiveFileList"
                                    :remove="lpIdPicturePositiveRemove"
                                    list-type="picture-card"
                                    v-decorator="[
                'hh',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.lpIdPicturePositiveFileCheck.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div v-if="lpIdPicturePositiveFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="法人身份证照片（反面）" >
                            <a-upload
                                    name="file"
                                    :customRequest="lpIdPictureNegative"
                                    :remove="lpIdPictureNegativeRemove"
                                    @preview="handlePicturePreview"
                                    :file-list="lpIdPictureNegativeFileList"
                                    v-decorator="[
                'ff',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.lpIdPictureNegativeFileCheck.bind(this),
                    },
                  ],
                },
              ]"
                                    list-type="picture-card"
                            >
                                <div v-if="lpIdPictureNegativeFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="营业执照照片">
                            <a-upload
                                    name="file"
                                    :customRequest="businessLicensePicture"
                                    :remove="businessLicensePictureRemove"
                                    @preview="handlePicturePreview"
                                    :file-list="businessLicensePictureFileList"
                                    v-decorator="[
                'fuerih',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.businessLicensePictureFileCheck.bind(
                        this
                      ),
                    },
                  ],
                },
              ]"
                                    list-type="picture-card"
                            >
                                <div v-if="businessLicensePictureFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="开户许可证">
                            <a-upload
                                    name="file"
                                    :customRequest="accountOpeningPermit"
                                    :remove="accountOpeningPermitRemove"
                                    @preview="handlePicturePreview"
                                    :file-list="accountOpeningPermitList"
                                    v-decorator="[
                'openAccount',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                          validator: this.openAnAccountCheck.bind(this),
                    },
                  ],
                },
              ]"
                                    list-type="picture-card"
                            >
                                <div v-if="accountOpeningPermitList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="经营许可证" >
                            <a-upload
                                    name="file"
                                    :customRequest="uploadAtlasAll"
                                    :remove="uploadAtlasRemove"
                                    :multiple="true"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="uploadAtlasList"
                                    v-decorator="[
                'imageUrl',
                {
                  rules: [
                    {
                      required: false,
                      validator: this.uploadCoverFileCheckOne.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div v-if="uploadAtlasList.length < 3">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>

                            </a-upload>

                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="产品资质证书">
                            <a-upload
                                    name="file"
                                    :customRequest="uploadAtlasAllTwo"
                                    :remove="uploadAtlasRemoveTwo"
                                    :multiple="true"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="ProductQualificationList"
                                    v-decorator="[
                'imageUrlTwo',
                {
                  rules: [
                    {
                      required: false,
                      validator: this.uploadCoverFileCheckTwo.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div v-if="ProductQualificationList.length < 3">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图片</div>
                                </div>

                            </a-upload>
                            <div class="fileTipBox20200506">
                                文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张
                            </div>
                        </a-form-item>

                    </div>




                    <a-form-item v-bind="tailFormItemLayout" class="present">
                        <a-button class="button1" @click="back"><img src="../../assets/images/homeImg/路径 2330@2x.png"/>返回上一步
                        </a-button>
                        <a-button type="primary" v-if="enableClick" html-type="submit" class="button2"
                                  @click="handleSubmit">下一步
                        </a-button>
                        <a-button class="button2" v-else disabled type="primary">下一步</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <Footer/>

        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="'30vw'">
            <div style="margin-top: 0.2rem;">
                <img alt="example" style="width: 100%;max-height: 5rem;margin-bottom: 0.3rem" :src="previewImage"/>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import MyStepBar from "@/components/enterMyStepBar";
    import {Button, Cascader, Checkbox, Form, Input, message, Progress, Select, Upload,} from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import {
        dictionaries,
        fetchAddrCascader,
        fetchAlipayOpenauth,
        fetchAlipayOpenauthInfo,
        fetchProvinceName,
        uploadServiceFile,
    } from "@/services/api";
    import {baseUrl} from "@/services/http";

    Vue.use(Checkbox);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Cascader);
    Vue.use(Upload);
    Vue.use(Progress);

    export default {
        components: {
            Nav,
            Footer,
            Header,
            ReturnStep,
            MyStepBar
        },
        data() {
            return {
                enableClick: true,
                t: null,
                checked: false,
                formItemLayout: {
                    labelCol: {
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 10,
                        },
                    },
                    wrapperCol: {
                        xs: {
                            span: 24,
                        },
                        sm: {
                            span: 14,
                        },
                    },
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 24,
                            offset: 0,
                        },
                        sm: {
                            span: 13,
                            offset: 10,
                        },
                    },
                },
                stepData: [
                    {
                        name: "入驻协议",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-1.png'),
                    },
                    {
                        name: "主体信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-2.png'),
                    },
                    {
                        name: "服务商信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-3.png'),
                    },
                ],
                options: [],
                orgType: [], //服务类别
                companyAddrPlaceHolder: "", // 选择公司地址占位

                vcodes: "",
                isSending: false, // 是否发送
                isSendingFisrt: true,
                remainTime: 60,
                interval: null,
                formData: {
                    organizationInfo: {
                        companyName: "",
                        orgCode: "",
                        companyProvince: "",
                        companyCity: "",
                        companyArea: "",
                        companyStreet: "",
                        companyAddressDetail: "",
                        companyContactNumber: "",
                        detailDescription: "",
                        companyAddress: [],
                    },
                    accountInfo: {
                        lpName: "",
                        lpIdNum: "",
                        lpTel: "",
                        accountName: "",
                        accountNumber: "",
                        bankName: "",
                        accountProvince: "",
                        accountCity: "",
                        accountArea: "",
                        accountStreet: "",
                        accountAddress: [],
                        accountDetailAdd: "",
                        lpIdPicturePositive: "",
                        lpIdPictureNegative: "",
                        businessLicensePicture: "",
                        emergencyContactName: "",
                        emergencyContactPhone:"",
                        accountOpeningPermit:"",
                        operatingLicense:"",
                        productQualificationCertificate:"",
                    },
                    administratorInfo: {
                        adminName: "",
                        adminIdNum: "",
                        adminTel: "",
                    },
                    expansionInfo: {
                        coverImage: "",
                        createTime: "",
                        briefIntro: "",
                        orgRemark: "",
                        areaSize: 0,
                        orgIntro: "",
                    },
                    shopInfo: {
                        orgAccount: JSON.parse(localStorage.getItem("userInfo"))["userName"],
                        orgName: "",
                        orgForm: [],
                        orgImagesList: [],
                        servicesCategoryList: [],
                        orgCharacter: "",
                        addressProvince: "",
                        addressCity: "",
                        addressArea: "",
                        addressStreet: "",
                        addressDetail: "",
                        lowestPrice: null,
                        highestPrice: null,
                        orgTel: "",
                        openTime: "",
                        closeTime: "",
                        shopAddress: [],
                    },
                    label: {
                        specialService: "",
                    },
                },
                alipayBind: {
                    // 支付宝绑定问题
                    allowbindclick: true, // 不允许点击
                    loading: false,
                    btnHolder: "绑定账号",
                    alipayHelpTip: "",
                    showModal: false, // 展示对话框
                    showModalOkBtn: "下一步", // 展示对话框
                },
                previewVisible: false,
                previewImage: "",

                lpIdPicturePositiveFileList: [],
                lpIdPictureNegativeFileList: [],
                businessLicensePictureFileList: [],
                accountOpeningPermitList:[],
                ProductQualificationImagesList: [], //经营许可证存储当前图片的路径
                ProductQualificationPathList: [], // 产品资质证书存储当前图片的路径,
                maxProductQualificationSize: 3, // 存储当前图片的路径
                uploadAtlasList: [],
                ifImageCanUploadTwo: true,
                ProductQualificationList: [],
                ProductQualificationUpload: true,


                ifImageCanUpload: true,
                addrPlaceHolder: {
                    companyPlaceHolder: "请选择公司所在地",
                    accountPlaceHolder: "请选择开户银行所在地",
                },
            };
        },
        beforeCreate() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.form = this.$form.createForm(this);
            } else {
                this.$router.push("/login");
            }
        },
        async mounted() {

            await this.init();
            dictionaries([65])
                .then((res) => {
                    if (res && res.code == 0) {
                        this.orgType = res.values[65];
                    } else {
                        message.error("获取服务类别失败");
                    }
                })
                .then(() => {
                    let item = localStorage.getItem("serviceJoinForm");
                    if (item) {
                        let data = JSON.parse(item);
                        this.formData = data;
                        if (this.formData.organizationInfo.companyProvince)
                            this.formData.organizationInfo.companyAddress = [
                                +this.formData.organizationInfo.companyProvince,
                                +this.formData.organizationInfo.companyCity,
                                +this.formData.organizationInfo.companyArea,
                                +this.formData.organizationInfo.companyStreet,
                            ];
                        else this.formData.organizationInfo.companyAddress = [];
                        if (this.formData.accountInfo.accountProvince)
                            this.formData.accountInfo.accountAddress = [
                                +this.formData.accountInfo.accountProvince,
                                +this.formData.accountInfo.accountCity,
                                +this.formData.accountInfo.accountArea,
                                +this.formData.accountInfo.accountStreet,
                            ];
                        else this.formData.accountInfo.accountAddress = [];

                        this.initAllArea([
                            this.formData.organizationInfo.companyProvince,
                            this.formData.organizationInfo.companyCity,
                            this.formData.organizationInfo.companyArea,
                        ]);
                        this.initAllArea([
                            this.formData.accountInfo.accountProvince,
                            this.formData.accountInfo.accountCity,
                            this.formData.accountInfo.accountArea,
                        ]);
                        //法人正面
                        if (this.formData.accountInfo.lpIdPicturePositive) {
                            this.lpIdPicturePositiveFileList.push({
                                uid: "lpidpicturepositivefilelist",
                                name: "lpIdPicturePositiveFile.png",
                                status: "done",
                                url:
                                    this.formData.accountInfo.lpIdPicturePositive.indexOf(
                                        "/fileupload"
                                    ) == 0
                                        ? `${baseUrl}${this.formData.accountInfo.lpIdPicturePositive}`
                                        : this.formData.accountInfo.lpIdPicturePositive,
                            });
                        }
                        //法人反面
                        if (this.formData.accountInfo.lpIdPictureNegative) {
                            this.lpIdPictureNegativeFileList.push({
                                uid: "lpidpicturenegativefilelist",
                                name: "lpIdPictureNegativeFile.png",
                                status: "done",
                                url:
                                    this.formData.accountInfo.lpIdPictureNegative.indexOf(
                                        "/fileupload"
                                    ) == 0
                                        ? `${baseUrl}${this.formData.accountInfo.lpIdPictureNegative}`
                                        : this.formData.accountInfo.lpIdPictureNegative,
                            });
                        }
                        //营业执照
                        if (this.formData.accountInfo.businessLicensePicture) {
                            this.businessLicensePictureFileList.push({
                                uid: "businesslicensepicturefilelist",
                                name: "businessLicensePictureFile.png",
                                status: "done",
                                url:
                                    this.formData.accountInfo.businessLicensePicture.indexOf(
                                        "/fileupload"
                                    ) == 0
                                        ? `${baseUrl}${this.formData.accountInfo.businessLicensePicture}`
                                        : this.formData.accountInfo.businessLicensePicture,
                            });
                        }
                        //开户许可证
                        if (this.formData.accountInfo.accountOpeningPermit) {
                            this.accountOpeningPermitList.push({
                                uid: "accountOpeningPermitList",
                                name: "accountOpeningPermitList.png",
                                status: "done",
                                url: this.formData.accountInfo.accountOpeningPermit.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.accountInfo.accountOpeningPermit}` : this.formData.accountInfo.accountOpeningPermit,
                            });
                        }
                        //经营许可证多图片
                        if (this.formData.accountInfo.operatingLicense) {
                            let operatingLicense = this.formData.accountInfo.operatingLicense.split(",");
                            operatingLicense.forEach((item, index) => {
                                if (this.formData.accountInfo.operatingLicense) {
                                    //图片数据赋值出来
                                    this.ProductQualificationImagesList.push({
                                        "uid": "imageUrl" + index,
                                        "path": item
                                    })
                                    this.uploadAtlasList.push({
                                        uid: "imageUrl" + index,
                                        name: "imageUrl" + index + ".png",
                                        status: "done",
                                        url: item.indexOf("/fileupload") == 0 ? `${baseUrl}${item}` : item,
                                    });

                                }
                            })

                        }
                        //产品资质证书
                        if (this.formData.accountInfo.productQualificationCertificate) {
                            let operatingLicense = this.formData.accountInfo.productQualificationCertificate.split(",");
                            operatingLicense.forEach((item, index) => {
                                if (this.formData.accountInfo.productQualificationCertificate) {
                                    //图片数据赋值出来
                                    this.ProductQualificationPathList.push({
                                        "uid": "imageUrl" + index,
                                        "path": item
                                    })
                                    //图片数据回填
                                    this.ProductQualificationList.push({
                                        uid: "imageUrl" + index,
                                        name: "imageUrl" + index + ".png",
                                        status: "done",
                                        url: item.indexOf("/fileupload") == 0 ? `${baseUrl}${item}` : item,
                                    });
                                }
                            })

                        }
                    }
                })
                .then((_) => {
                    if (
                        this.formData.organizationInfo.companyCity ||
                        this.formData.accountInfo.accountCity
                    ) {
                        // 请求公司地址
                        fetchProvinceName({
                            province: this.formData.organizationInfo.companyProvince,
                            city: this.formData.organizationInfo.companyCity,
                            area: this.formData.organizationInfo.companyArea,
                            street: this.formData.organizationInfo.companyStreet,
                        }).then((res) => {
                            if (res.code == 0) {
                                const add = res.info.addr;
                                this.addrPlaceHolder.companyPlaceHolder = `${add.province}/${
                                    add.city
                                }/${add.area}${add.street ? `/${add.street}` : ""}`;
                            } else {
                                message.error(res.msg);
                            }
                        });
                        // 请求开户地址
                        fetchProvinceName({
                            province: this.formData.accountInfo.accountProvince,
                            city: this.formData.accountInfo.accountCity,
                            area: this.formData.accountInfo.accountArea,
                            street: this.formData.accountInfo.accountStreet,
                        }).then((res) => {
                            if (res.code == 0) {
                                const add = res.info.addr;
                                this.addrPlaceHolder.accountPlaceHolder = `${add.province}/${
                                    add.city
                                }/${add.area}${add.street ? `/${add.street}` : ""}`;
                            } else {
                                message.error(res.msg);
                            }
                        });
                    }
                    this.$nextTick(() => {
                        this.enableClick = true;
                    });
                });
        },
        methods: {
            back() {
                this.saveJoinForm();
                // this.$router.back();
                this.$router.push("/ServiceJoinRead");
            },
            // 上传图片统一处理
            uploadFile(options, typeId, callback) {
                if (!this.ifImageCanUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", typeId);
                let p = {
                    percent: 0,
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({
                            percent: 99.8,
                        });
                    }
                })
                    .then((data) => {
                        // this.loading = false;
                        if (data && data.code == 0) {
                            options.onSuccess();
                            // message.success("上传成功");
                            callback(data.filePath);
                        } else {
                            message.error(data.msg);
                            callback(false);
                            // options.onError(); //上传失败
                        }
                    })
                    .catch((error) => {
                        message.error("上传失败");
                        callback(false);
                    });
            },
            // 图片预览
            async handlePicturePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await this.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            // 将文件转成Base64
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            // 预览框关闭
            handleCancel() {
                this.previewVisible = false;
            },
            // 上传法人身份证照片（正面）
            lpIdPicturePositive(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 0, (url) => {
                        if (url){
                            this.formData.accountInfo.lpIdPicturePositive = url;
                            this.lpIdPicturePositiveFileList.push({
                                uid: "lpidpicturepositivefilelist",
                                name: "lpIdPicturePositiveFile.png",
                                status: "done",
                                url: this.formData.accountInfo.lpIdPicturePositive.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url,
                            });
                        }
                        else {
                            this.lpIdPicturePositiveFileList.pop();
                        }
                    });
                }, 1000);
            },
            // 移除法人身份证照片（正面）
            lpIdPicturePositiveRemove(file) {
                this.lpIdPicturePositiveFileList = [];
                this.formData.accountInfo.lpIdPicturePositive = "";
            },
            // 校验法人身份证照片（正面）
            lpIdPicturePositiveFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.lpIdPicturePositive) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    this.ifImageCanUpload = false;
                    callback("请上传法人身份证电子版（正面）！");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({hh: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            // 上传法人身份证照片（反面）
            lpIdPictureNegative(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 1, (url) => {
                        if (url){
                            this.formData.accountInfo.lpIdPictureNegative = url;
                            this.lpIdPictureNegativeFileList.push({
                                uid: "lpidpicturenegativefilelist",
                                name: "lpIdPictureNegativeFile.png",
                                status: "done",
                                url: this.formData.accountInfo.lpIdPictureNegative.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url,
                            });
                        }
                        else {
                            this.lpIdPictureNegativeFileList.pop();
                        }
                    });
                }, 800);
            },
            // 移除法人身份证照片（反面）
            lpIdPictureNegativeRemove(file) {
                this.lpIdPictureNegativeFileList = [];
                this.formData.accountInfo.lpIdPictureNegative = "";
            },
            // 校验法人身份证照片（反面）
            lpIdPictureNegativeFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.lpIdPictureNegative) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传法人身份证电子版（反面）！");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({ff: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();
                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            // 上传营业执照图片
            businessLicensePicture(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 2, (url) => {
                        if (url){
                            this.formData.accountInfo.businessLicensePicture = url;
                            this.businessLicensePictureFileList.push({
                                uid: "businesslicensepicturefilelist",
                                name: "businessLicensePictureFile.png",
                                status: "done",
                                url: this.formData.accountInfo.businessLicensePicture.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url,
                            });
                        } else {
                            this.businessLicensePictureFileList.pop();
                        }
                    });
                }, 800);
            },
            // 移除营业执照图片
            businessLicensePictureRemove(file) {
                this.businessLicensePictureFileList = [];
                this.formData.accountInfo.businessLicensePicture = "";
            },
            // 校验营业执照图片
            businessLicensePictureFileCheck(rule, value, callback) {
                if (this.formData.accountInfo.businessLicensePicture) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传营业执照电子版！");
                    return;
                }

                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({fuerih: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            //上传开户许可证
            accountOpeningPermit(options) {
                const t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadFile(options, 2, (url) => {
                        if (url){
                            this.formData.accountInfo.accountOpeningPermit = url;
                            this.accountOpeningPermitList.push({
                                uid: "accountOpeningPermitList",
                                name: "accountOpeningPermitList.png",
                                status: "done",
                                url: this.formData.accountInfo.accountOpeningPermit.indexOf("/fileupload") == 0 ? `${baseUrl}${url}` : url,
                            });
                        } else {
                            this.accountOpeningPermitList.pop();
                        }
                    });
                }, 800);
            },
            // 移除开户许可证
            accountOpeningPermitRemove(file) {
                this.accountOpeningPermitList = [];
                this.formData.accountInfo.accountOpeningPermit = "";
            },
            //检验开户许可证
            openAnAccountCheck(rule, value, callback) {
                if (this.formData.accountInfo.accountOpeningPermit) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传开户许可证！");
                    return;
                }

                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({openAccount: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();

                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },

            //上传经营许可证
            uploadAtlasAll(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadAtlas(options);
                }, 1000);
            },
            //上传经营许可证
            uploadAtlas(options) {
                if (!this.ifImageCanUploadTwo) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                }).then(data => {

                    if (data && data.code == 0) {
                        options.onSuccess();

                        const file = options.file;
                        if (this.ProductQualificationImagesList.length > this.maxProductQualificationSize) {
                            return;
                        }
                        //获取上传数据
                        this.ProductQualificationImagesList.push({
                            "uid": file.uid,
                            "path": data.filePath,
                        })
                        //上传显示大图而不是缩略图
                        this.uploadAtlasList.push({
                            "uid": file.uid,
                            "name": "imageUrl" + file.uid + ".png",
                            "status": "done",
                            "url": `${baseUrl}${data.filePath}`,
                        })
                    } else {
                        message.error(data.msg);
                        this.uploadAtlasList.pop();
                    }
                }).catch(error => {
                    message.error("上传失败");
                    this.uploadAtlasList.pop();
                });
            },
            //移除经营许可证
            uploadAtlasRemove(file) {
                let removeId = file.uid;
                //储存数据移除
                this.ProductQualificationImagesList = this.ProductQualificationImagesList.filter(i => i.uid !== removeId);
                //展示图片移除
                this.uploadAtlasList = this.uploadAtlasList.filter(i => i.uid !== removeId)
            },
            //校验经营许可证上传
            uploadCoverFileCheckOne(rule, value, callback) {
                if (value!=undefined) {
                    if (value.file.size > 3000 * 1000 * 1) {
                        this.ifImageCanUpload = false;
                        value.fileList.length = 0;
                        this.form.setFieldsValue({imageUrlTwo: ""});
                        message.error("上传失败，请控制图片在3M之内");
                        callback();
                        return;
                    } else {
                        this.ifImageCanUpload = true;
                        callback();
                    }
                }else{
                    callback();
                    return;
                }
            },


            //上传产品资质证书
            uploadAtlasAllTwo(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadAtlasTwo(options);
                }, 1000);
            },
            //上传产品资质证书
            uploadAtlasTwo(options) {
                if (!this.ProductQualificationUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                }).then(data => {
                    if (data && data.code == 0) {
                        options.onSuccess();
                        const file = options.file;
                        if (this.ProductQualificationPathList.length > this.maxProductQualificationSize) {
                            return;
                        }
                        //获取上传数据
                        this.ProductQualificationPathList.push({
                            "uid": file.uid,
                            "path": data.filePath,
                        })
                        //上传显示大图而不是缩略图
                        this.ProductQualificationList.push({
                            "uid": file.uid,
                            "name": "imageUrl" + file.uid + ".png",
                            "status": "done",
                            "url": `${baseUrl}${data.filePath}`,
                        })
                    } else {
                        message.error(data.msg);
                        this.ProductQualificationList.pop();
                    }
                }).catch(error => {
                    message.error("上传失败");
                    this.ProductQualificationList.pop();
                });
            },
            //移除产品资质证书
            uploadAtlasRemoveTwo(file) {
                let removeId = file.uid;
                //储存数据移除
                this.ProductQualificationPathList = this.ProductQualificationPathList.filter(i => i.uid !== removeId);
                //展示图片移除
                this.ProductQualificationList = this.ProductQualificationList.filter(i => i.uid !== removeId)
            },
            //校验产品资质证书上传
            uploadCoverFileCheckTwo(rule, value, callback) {
                if (value!=undefined) {
                    if (value.file.size > 3000 * 1000 * 1) {
                        this.ifImageCanUpload = false;
                        value.fileList.length = 0;
                        this.form.setFieldsValue({imageUrlTwo: ""});
                        message.error("上传失败，请控制图片在3M之内");
                        callback();
                        return;
                    } else {
                        this.ifImageCanUpload = true;
                        callback();
                    }
                }else{
                    callback();
                    return;
                }
            },

            /*  选择公司地址*/
            selectCompanyAddress(value, selectedOptions) {
                if (value.length >= 3 && value.length <= 4) {
                    this.formData.organizationInfo.companyProvince = value[0];
                    this.formData.organizationInfo.companyCity = value[1];
                    this.formData.organizationInfo.companyArea = value[2];
                    this.formData.organizationInfo.companyStreet = value[3] || "";
                    this.formData.organizationInfo.companyAddress = value;
                    fetchProvinceName({
                        province: this.formData.organizationInfo.companyProvince,
                        city: this.formData.organizationInfo.companyCity,
                        area: this.formData.organizationInfo.companyArea,
                        street: this.formData.organizationInfo.companyStreet,
                    }).then((res) => {
                        if (res.code == 0) {
                            const add = res.info.addr;
                            this.addrPlaceHolder.companyPlaceHolder = `${add.province}/${
                                add.city
                            }/${add.area}${add.street ? `/${add.street}` : ""}`;
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            },
            /* 选择开户地址*/
            selectAccountAddress(value, selectedOptions) {
                if (value.length >= 3 && value.length <= 4) {
                    this.formData.accountInfo.accountProvince = value[0];
                    this.formData.accountInfo.accountCity = value[1];
                    this.formData.accountInfo.accountArea = value[2];
                    this.formData.accountInfo.accountStreet = value[3] || "";
                    this.formData.accountInfo.accountAddress = value;

                    fetchProvinceName({
                        province: this.formData.accountInfo.accountProvince,
                        city: this.formData.accountInfo.accountCity,
                        area: this.formData.accountInfo.accountArea,
                        street: this.formData.accountInfo.accountStreet,
                    }).then((res) => {
                        if (res.code == 0) {
                            const add = res.info.addr;
                            this.addrPlaceHolder.accountPlaceHolder = `${add.province}/${
                                add.city
                            }/${add.area}${add.street ? `/${add.street}` : ""}`;
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            },



            // 提交，下一步
            handleSubmit(e) {
                this.saveJoinForm();
                e.preventDefault();
                if (this.enableClick) {
                    this.enableClick = false
                    this.form.validateFieldsAndScroll(
                        null,
                        {
                            first: false,
                            scroll: {
                                offsetTop: 120,
                            },
                        },

                        (err, values) => {
                            if (!err) {
                                this.saveJoinForm();
                                this.$router.push("/serviceJoinStep2");

                            } else {
                                this.enableClick = true;
                            }
                        }
                    );
                }
            },
            async initAllArea([a, b, c]) {
                let opton = this.options;
                for (let level of [a, b, c]) {
                    let item = opton.find((item) => item.value == level);
                    await this.loadData([item]);
                    opton = item.children;
                }
            },
            // 获取地址信息
            loadData(selectedOptions) {
                const targetOption = selectedOptions[selectedOptions.length - 1];
                const {value} = targetOption;
                const {level} = targetOption;
                targetOption.loading = true;

                return new Promise((resolve) => {
                    fetchAddrCascader({
                        areaType: level,
                        areaId: value,
                    }).then((data) => {
                        resolve();
                        targetOption.loading = false;
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach((i) => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        isLeaf: i.childNum == 0,
                                        level: level + 1,
                                    });
                                });
                            }
                            targetOption.loading = false;
                            targetOption.children = children;
                            this.options = [...this.options];
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },
            // 初始化地址
            init(place) {
                return new Promise((resolve) => {
                    fetchAddrCascader({
                        areaType: 0,
                        areaId: "",
                    }).then((data) => {
                        resolve();
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach((i) => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        level: 1,
                                        isLeaf: false,
                                    });
                                });
                            }
                            this.options = children;
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },

            /*校验公司名称*/
            companyNamecheck(rule, value, callback) {
                if (!value) {
                    callback("公司名称不能为空");
                    return;
                }
                callback();
            },
            /*校验统一社会信用代码 */
            companyCodecheck(rule, value, callback) {
                const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
                if (!value) {
                    callback("统一社会信用代码不能为空");
                    return;
                }
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的统一社会信用代码");
                    return;
                }
                callback();

            },
            /* 校验公司地址选择 */
            companyAddrcheck(rule, value, callback) {
                if (this.formData.organizationInfo.companyAddress.length == 0) {
                    callback(new Error("公司所在地不能为空"));
                } else {
                    callback();
                }
            },
            /** 校验请选择开户地址*/
            accountAddrcheck(rule, value, callback) {
                if (this.formData.accountInfo.accountAddress.length == 0) {
                    callback(new Error("开户银行所在地不能为空"));
                } else {
                    callback();
                }
            },

            /* 校验电话号码 */
            phoneCheck(rule, value, callback) {
                if (!value) {
                    callback("公司电话不能为空");
                }
                const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
                const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value) && !reg2.test(value)) {
                    callback(
                        "格式不正确，请输入正确格式的公司电话,如：021-87888822,13812345678"
                    );
                } else {
                    callback();
                }
            },
            /*校验身份证号*/
            infoCardCheck(rule, value, callback) {
                const trans = {
                    lpIdNum: "法定代表人身份证号",
                    adminIdNum: "管理员身份证编号",
                };
                if (!value) {
                    callback(trans[rule.field] + "不能为空");
                }
                const reg =
                    /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的身份证号");
                } else {
                    callback();
                }
            },
            /* 校验手机号 */
            telCheck(rule, value, callback) {
                const trans = {
                    lpTel: "法定代表人联系方式",
                    emergencyContactPhone: "公司紧急联系人手机",
                    adminTel: "管理员手机号",
                };
                if (!value) {
                    callback(trans[rule.field] + "不能为空");
                    return;
                }
                // ^\d{15}|\d{18}$
                const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的手机号码");
                } else {
                    callback();
                }
            },
            /* 法人名字校验*/
            farenName(rule, value, callback) {
                const tanslation = {
                    lpName: "法定代表人姓名",
                    accountName: "银行开户名",
                    bankName: "开户银行名称",
                    emergencyContactName: "公司紧急联系人",
                    adminName: "管理员姓名",
                };
                if (!value) {
                    callback(tanslation[rule.field] + "不能为空");
                    return;
                }
                // const reg = /^[\u4e00-\u9fa5a-zA-Z ]{0,20}$/;
                //
                // if (!reg.test(value)) {
                //   callback("只能输入汉字或字母");
                //   return;
                // } else if (value && value.trim().length < 2) {
                //   callback("请填写正确格式的姓名");
                //   return;
                // }
                callback();
            },
            /* 校验开户账号*/
            yinhanCard(rule, value, callback) {
                const reg = /^([1-9]{1})(\d{15}|\d{18})$/;
                if (!reg.test(value)) {
                    callback("请输入正确的开户账号");
                    return;
                }
                callback();
            },
            /*校验支付宝绑定*/
            alipaCheck(rule, value, callback) {
                // if (rule) {
                //   callback();
                //   return;
                // }
                if (
                    !this.formData.paymentInfo ||
                    (!this.formData.paymentInfo.alipayId &&
                        !this.formData.paymentInfo.wechatId)
                ) {
                    callback(new Error("请绑定收款账号"));
                } else {
                    callback();
                }
            },
            /* 绑定 支付宝*/
            alipayBindClick() {
                // this.alipayBind.showModal = true;

                // 原来的绑定支付宝的内容
                this.alipayBind.allowbindclick = false;
                this.alipayBind.loading = true;
                // 获取登录成功回调的页面地址

                // 点了重新绑定，就把原来的信息删掉。不管最后绑定有没有成功
                // this.formData.paymentInfo.alipayId = "";
                this.formData.paymentInfo.alipayNickname = "";
                this.formData.paymentInfo.alipayRealName = "";

                fetchAlipayOpenauth(`${location.origin}`).then((res) => {
                    if (res && res.code == 0) {
                        // 直接打开页面
                        window.open(res.url);
                        // 请求查看是否获取到了
                        localStorage.setItem("ALIPAY_OPENAUTH_CALLBACK_SUCCESS", undefined);
                        let inter = setInterval(() => {
                            let res = parseInt(
                                localStorage.getItem("ALIPAY_OPENAUTH_CALLBACK_SUCCESS")
                            );
                            if (res == 1) {
                                fetchAlipayOpenauthInfo().then((res) => {
                                    if (res.code == 0) {
                                        // const info = json.parse(res.alipayinfo);
                                        // if (info.alipayId) {
                                        //   this.alipayBind.alipayHelpTip = `支付宝id：${info.alipayId}
                                        //                         ${
                                        //                           info.alipayNickname
                                        //                             ? `\r\n支付宝昵称：${info.alipayNickname}`
                                        //                             : ""
                                        //                         }
                                        //                         ${
                                        //                           info.alipayRealName
                                        //                             ? `\r\n真实姓名：${info.alipayRealName}`
                                        //                             : ""
                                        //                         }
                                        //                         `;
                                        //
                                        //   this.formData.paymentInfo.alipayId = info.alipayId;
                                        //   this.formData.paymentInfo.alipayNickname =
                                        //     info.alipayNickname;
                                        //   this.formData.paymentInfo.alipayRealName =
                                        //     info.alipayRealName;
                                        //
                                        //   this.formData.paymentInfo.alipayId = info.alipayId;
                                        //
                                        //   this.alipayBind.allowbindclick = true;
                                        //   this.alipayBind.loading = false;
                                        //   this.alipayBind.btnHolder = "修改绑定";
                                        //   localStorage.removeItem("ALIPAY_OPENAUTH_CALLBACK_SUCCESS");
                                        //   window.clearInterval(inter);
                                        // } else {
                                        //
                                        // }
                                        this.alipayBind.allowbindclick = true;
                                        this.alipayBind.loading = false;
                                    } else {
                                        this.alipayBind.allowbindclick = true;
                                        this.alipayBind.loading = false;
                                        message.error(res.msg);
                                    }
                                });
                            }
                            if (res == 0) {
                                this.alipayBind.allowbindclick = true;
                                this.alipayBind.loading = false;
                            }
                        }, 1000);
                    } else {
                        message.error(res.msg);
                    }
                });
            },
            // 支付宝对话框
            hideModal() {
                // 操作
                this.$refs.alipayBind.checkCurrent();

                let currentStep = this.$refs.alipayBind.currentStep;

                if (currentStep == 2) {
                    this.alipayBind.showModalOkBtn = "确认";
                }
                // if (currentStep >= 3) {
                //   this.formData.paymentInfo.alipayauthToken = this.$refs.alipayBind.formData.paymentInfo.alipayauthToken;
                //   // this.formData.paymentInfo.alipayId = this.$refs.alipayBind.formData.paymentInfo.alipayId;
                //   this.formData.paymentInfo.alipayNickname = this.$refs.alipayBind.formData.paymentInfo.alipayNickname;
                //   this.formData.paymentInfo.alipayRealName = this.$refs.alipayBind.formData.paymentInfo.alipayRealName;
                //
                //   let info = this.formData.paymentInfo;
                //   this.alipayBind.alipayHelpTip = `支付宝id：${info.alipayId}
                //                                     ${
                //           info.alipayNickname
                //                   ? `\r\n支付宝昵称：${info.alipayNickname}`
                //                   : ""
                //   }
                //                                     ${
                //           info.alipayRealName
                //                   ? `\r\n真实姓名：${info.alipayRealName}`
                //                   : ""
                //   }
                //                                     `;
                //   this.alipayBind.showModal = false;
                // }
            },
            // 存储数据
            saveJoinForm() {
                // 公司名称
                let getFormValue = (name) => this.form.getFieldValue(name);
                this.formData.organizationInfo.companyName = getFormValue("companyName");
                this.formData.organizationInfo.orgCode = getFormValue("orgCode");
                this.formData.organizationInfo.companyAddressDetail = getFormValue("companyAddressDetail");
                this.formData.organizationInfo.companyContactNumber = getFormValue("companyContactNumber");

                this.formData.accountInfo.lpName = getFormValue("lpName");
                this.formData.accountInfo.lpIdNum = getFormValue("lpIdNum");
                this.formData.accountInfo.lpTel = getFormValue("lpTel");
                this.formData.accountInfo.accountName = getFormValue("accountName");
                this.formData.accountInfo.accountNumber = getFormValue("accountNumber");
                this.formData.accountInfo.bankName = getFormValue("bankName");
                this.formData.accountInfo.accountDetailAdd = getFormValue("accountDetailAdd");
                this.formData.accountInfo.emergencyContactName = getFormValue("emergencyContactName");
                this.formData.accountInfo.emergencyContactPhone = getFormValue("emergencyContactPhone");
                this.formData.accountInfo.operatingLicense = this.ProductQualificationImagesList.map(i => i.path).join(',') //经营许可证
                this.formData.accountInfo.productQualificationCertificate = this.ProductQualificationPathList.map(i => i.path).join(',') //产品资质证书

                localStorage.setItem("serviceJoinForm", JSON.stringify(this.formData));
            },

        },
    };
</script>

<style lang="less">
    #top {

        .ServiceJoinStep1 {
            margin-top: -0.01rem;

            .stepTop {
                width: 100%;
                display: flex;
                margin-bottom: 0.6rem;
                position: relative;

                img {
                    width: 100%;
                    height: 1.2rem;
                }

                .rz-type {
                    position: absolute;
                    left: 4.4rem;
                    top: 0.39rem;
                    font-size: 0.24rem;
                    color: #fff;
                    font-weight: bold;
                    opacity: 1;
                }
            }

            .progressBar {
                width: 4.5rem;
                height: 1rem;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                /*border: 1px solid red;*/
            }

            .form-box {
                padding: 0.1rem 0;
                margin-bottom: 0.7rem;

                .tip {
                    margin-top: 0.2rem;
                    .ant-form-item-label label {
                        font-size: 0.14rem;
                        font-weight: bold;
                        color: #454F63;
                        opacity: 1;
                    }

                    .ant-form-item-label > label::after {
                        content: '';
                    }
                }

                .ant-input {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;
                    width: 3.53rem;
                    height: 0.4rem;

                    &::placeholder {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #8F9BA8;
                        opacity: 1;
                    }

                }

                .ant-form-item-label label {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;
                }

                .ant-cascader-picker-label {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;
                }

                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                        margin-left: 3.7rem;
                        margin-top: -0.33rem;
                    }
                }

                .present {
                    margin-top: 0.8rem;

                    button {
                        width: 1.46rem;
                        height: 0.4rem;
                        opacity: 1;
                        border-radius: 0.2rem;
                        font-size: 0.14rem;

                        img {
                            width: 7.75px;
                            height: 13.38px;
                        }
                    }

                    .button1 {
                        border: 1px solid #78849E;
                        margin-right: 0.5rem;
                        color: #454F63;

                        img {
                            margin-right: 0.07rem;
                        }
                    }

                    .button2 {
                        color: #FFFFFF;

                        img {
                            margin-left: 0.07rem;
                            transform: rotate(180deg);
                        }
                    }
                }

                .ant-form-item {
                    margin-bottom: 0.2rem;
                }

                .ant-input:hover {
                    border-color: #60c7c6;
                }

                .ant-select-selection:hover {
                    border-color: #60c7c6;
                }

                .ant-btn-primary {
                    background-color: #60c7c6;
                    border-color: #60c7c6;
                }

                .ant-btn-primary:hover,
                .ant-btn-primary:focus {
                    background-color: #60c7c6;
                    border-color: #60c7c6;
                }
            }
            .imageUpload {
                .ant-form-item{
                    min-height: 1.3rem;
                }
                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                        margin-left: 3.7rem;
                        margin-top: -0.8rem;
                    }
                }
                .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                    width: 100%;
                    /*object-fit: contain;*/
                    object-fit: fill;
                    border-radius: 4px;
                }
                .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
                    padding: 0;
                }
            }
            .fileTipBox20200506 {
                position: absolute;
                font-size: 0.14rem;
                font-weight: 400;
                color: #a9a9a9;
                opacity: 1;
                top: 0.17rem;
                display: flex;
                align-items: center;
                height: 0.2rem;
            }

        }

    }

</style>

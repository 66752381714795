

    import {Component, Vue} from "vue-property-decorator";
    import spikePart2Item from "./spikePart2Item.vue";
    import {http} from "@/services/http";

    @Component({ components: { spikePart2Item } })
export default class spikePart2 extends Vue {
  condition = { pageIndex: 1, pageSize: 15 };
  total:any = 0;
  list:any = [];
  created() {
    this.getdata();
  }
  getdata() {
    http.get("/api/v1/commodities/search", {
        params: { hot: 1, ...this.condition }
      }).then(res => {
       let data = (res as any).data;
        this.total = data.totalCount;
        this.getList(data.list);
      });
  }

  getList(list) {
    this.list = [];
    for (let item of list) {
      item.linePrice = item.suggestPrice;
    }
    list.map((item, index) => {
      if (index % 5 == 0) {
        this.list.push(list.slice(index, index + 5));
      }
    });
  }
}

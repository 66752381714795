

















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class searchItem extends Vue {
    @Prop() public data!: any;

    jump(id: string) {
        this.$router.push("/mall/detail/" + id);
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register"},[_c('div',{staticClass:"login-logo-resister"},[_c('div',{staticClass:"login-logo-left"},[_c('div',{staticClass:"log-logo",on:{"click":_vm.jumpToHome}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',{staticClass:"login-text"},[_vm._v("免费注册")])],1),_vm._m(0)]),_c('div',{staticClass:"register-form-box"},[_c('div',{staticClass:"register-center"},[_c('div',{staticClass:"register-center-top"},[_c('div',{staticClass:"top-left"},[_vm._v("正在注册")]),_c('div',{staticClass:"top-right",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("已有账号？去登录"),_c('a-icon',{staticStyle:{"margin-left":"0.05rem"},attrs:{"type":"arrow-right"}})],1)]),_c('div',{staticClass:"register-center-matter"},[_c('div',{staticClass:"item"},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_vm._v(" 用户名 "),_c('div',{staticClass:"inputBox"},[_c('a-input',{attrs:{"placeholder":"可用于登录和名称显示","maxlength":"20","id":"username"},on:{"change":function($event){_vm.tip.usernameTip = '',
                        _vm.info.username = _vm.info.username.slice(0, 20)},"focus":function($event){_vm.tip.usernameTip = '支持数字、字母大小写，6~20个字符'},"blur":function($event){return _vm.testusername()}},model:{value:(_vm.info.username),callback:function ($$v) {_vm.$set(_vm.info, "username", $$v)},expression:"info.username"}})],1),_c('span',{staticClass:"tip",class:{ tipOk: _vm.tip.usernameTip == 'OK' }},[(_vm.tip.usernameTip != 'OK')?_c('span',[_vm._v(_vm._s(_vm.tip.usernameTip))]):_c('a-icon',{attrs:{"type":"check-circle"}})],1)]),_c('div',{staticClass:"item"},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_vm._v(" 密码 "),_c('div',{staticClass:"inputBox"},[_c('a-input-password',{attrs:{"placeholder":"请输入密码","id":"psw","maxlength":"20"},on:{"change":function($event){_vm.tip.pswTip = '',
                        _vm.info.psw = _vm.info.psw.slice(0, 20)},"focus":function($event){_vm.tip.pswTip = '密码长度8-20个字符（密码必须包括数字和英文字母）'},"blur":function($event){return _vm.testPsw()}},model:{value:(_vm.info.psw),callback:function ($$v) {_vm.$set(_vm.info, "psw", $$v)},expression:"info.psw"}})],1),_c('span',{staticClass:"tip",class:{ tipOk: _vm.tip.pswTip == 'OK' }},[(_vm.tip.pswTip != 'OK')?_c('span',[_vm._v(_vm._s(_vm.tip.pswTip))]):_c('a-icon',{attrs:{"type":"check-circle"}})],1)]),_c('div',{staticClass:"item"},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_vm._v(" 确认密码 "),_c('div',{staticClass:"inputBox"},[_c('a-input-password',{attrs:{"placeholder":"请再次输入密码","id":"pswAgain","maxlength":"20"},on:{"focus":function($event){_vm.tip.pswAgainTip = '请再次输入密码'},"change":function($event){_vm.tip.pswAgainTip = '',
                        _vm.info.pswAgain = _vm.info.pswAgain.slice(0, 20)},"blur":function($event){_vm.tip.pswAgainTip =
                          _vm.info.pswAgain == _vm.info.psw ? 'OK' : '两次输入的密码不一致'}},model:{value:(_vm.info.pswAgain),callback:function ($$v) {_vm.$set(_vm.info, "pswAgain", $$v)},expression:"info.pswAgain"}})],1),_c('span',{staticClass:"tip",class:{ tipOk: _vm.tip.pswAgainTip == 'OK' }},[(_vm.tip.pswAgainTip != 'OK')?_c('span',[_vm._v(_vm._s(_vm.tip.pswAgainTip))]):_c('a-icon',{attrs:{"type":"check-circle"}})],1)]),_c('div',{staticClass:"item"},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_vm._v(" 手机号 "),_c('div',{staticClass:"inputBox"},[_c('a-input',{attrs:{"placeholder":"可用于登录和密码找回","id":"phone"},on:{"change":function($event){_vm.tip.phoneTip = '',
                        _vm.info.phone = _vm.info.phone.slice(0, 11)},"focus":function($event){_vm.tip.phoneTip = '注册成功后，可使用该手机号登录或找回密码'},"blur":function($event){return _vm.phoneTest()}},model:{value:(_vm.info.phone),callback:function ($$v) {_vm.$set(_vm.info, "phone", $$v)},expression:"info.phone"}})],1),_c('span',{staticClass:"tip",class:{ tipOk: _vm.tip.phoneTip == 'OK' }},[(_vm.tip.phoneTip != 'OK')?_c('span',[_vm._v(_vm._s(_vm.tip.phoneTip))]):_c('a-icon',{attrs:{"type":"check-circle"}})],1)]),_c('div',{staticClass:"item"},[_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")]),_vm._v(" 验证码 "),_c('div',{staticClass:"inputBox",staticStyle:{"text-align":"left"}},[_c('a-input',{staticStyle:{"width":"1.8rem"},attrs:{"placeholder":"请输入验证码","id":"code"},on:{"focus":function($event){_vm.tip.codeTip = '请输入验证码'},"change":function($event){_vm.tip.codeTip = ''}},model:{value:(_vm.info.code),callback:function ($$v) {_vm.$set(_vm.info, "code", $$v)},expression:"info.code"}}),_c('a-button',{style:({
                        backgroundColor: _vm.isSending ? '#c5c5c5' : '',
                        fontSize: '0.14rem'
                      }),attrs:{"type":"primary","disabled":_vm.isSending},on:{"click":_vm.handleSendCode}},[_vm._v(_vm._s(_vm.buttonContent)+" ")])],1),_c('span',{staticClass:"tip",class:{ tipOk: _vm.tip.codeTip == 'OK' }},[(_vm.tip.codeTip != 'OK')?_c('span',[_vm._v(_vm._s(_vm.tip.codeTip))]):_c('a-icon',{attrs:{"type":"check-circle"}})],1)]),_c('div',{staticClass:"item",staticStyle:{"padding-left":"0.9rem"}},[_c('span',{attrs:{"id":"checkXieYi36652525"}},[_c('a-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("我已阅读并同意以下协议")]),_c('a',{attrs:{"href":"/html/养老平台注册协议.html","target":"_blank"}},[_vm._v("《注册协议》")]),_c('a',{attrs:{"href":"/html/隐私政策.html","target":"_blank"}},[_vm._v("《隐私政策》")])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"registerButon"},[_c('a-button',{style:({ backgroundColor: !_vm.checked ? '#c5c5c5' : '' }),attrs:{"disabled":!_vm.checked},on:{"click":_vm.handleSubmit}},[_vm._v("注册 ")])],1)])])])]),_c('loginFooter',{staticStyle:{"height":"7%"}}),_c('a-modal',{staticStyle:{"text-align":"center","padding-top":"150px"},attrs:{"title":" ","footer":null},on:{"cancel":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"modal-image"}),_c('p',{staticStyle:{"color":"#000","font-size":"20px","margin-bottom":"43px"}},[_vm._v("恭喜您！注册成功")]),_c('a-button',{staticStyle:{"width":"135px","height":"39.6px","margin":"0 auto","margin-bottom":"44px","background-color":"#60c7c6","color":"white"},on:{"click":_vm.registerSuccessConfirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"promptTone"},[_c('span',[_vm._v("你好，欢迎来到老年服务综合平台 !")])])}]

export { render, staticRenderFns }
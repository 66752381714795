


















































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Breadcrumb} from "ant-design-vue";
    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Header from "@/components/Header.vue";
    import serviceItemAllOrder from "./serviceItemAllOrder.vue";
    import Cansole from "./cansole.vue";
    import servieItemHeader from "./servieItemHeader.vue";
    import InfoCard from "../../components/InfoCard.vue";
    import {briefInfo, serviceList} from "../../services/api";

    Vue.use(Breadcrumb);
@Component({
  components: {
    Nav,
    Footer,
    Header,
    serviceItemAllOrder,
    Cansole,
    servieItemHeader,
    InfoCard
  }
})
export default class extends Vue {
  pagination = {
    pageIndex: 1,
    pageSize: 999,
    total: 0
  };

  pageContent = [];
  content = {};
  allService = [];
  orgId = "";
  data() {
    const pageContent: Array<any> = [];
    return {
      pageContent: pageContent
    };
  }

  get _address() {
    const addressProvince = this.content["addressProvince"] || "";
    const addressCity = this.content["addressCity"] || "";
    const addressArea = this.content["addressArea"] || "";
    const addressStreet = this.content["addressStreet"] || "";
    const detailAddress = this.content["detailAddress"] || "";

    return (
      addressProvince +
      addressCity +
      addressArea +
      addressStreet +
      detailAddress
    );
  }

  secondId = "";
  @Watch("$route")
  routechange(to: any, from: any) {
    this.orgId = to.query.orgId;
    this.briefInfo(this.orgId);
    this.serviceList(this.orgId);
  }
  gotopage(params) {
    this.$router.push({
      path: "/servieItemDetail",
      query: params
    });
  }

  mounted() {
    this.orgId = this.$route.query.orgId;
    this.briefInfo(this.orgId);
    this.serviceList(this.orgId);
  }
  briefInfo(orgId) {
    briefInfo({
      orgId: orgId
    }).then(res => {
      this.content = res.data;
    });
  }
  onchange(page, pageSize) {
    window.scrollTo({ top: 0 });
    this.pagination.pageIndex = page;
    this.orgId = this.$route.query.orgId;
    this.serviceList(this.orgId);
  }
  serviceList(orgId) {
    serviceList({
      orgId: orgId,
      ...this.pagination
    }).then(res => {
      this.allService = res.data.thirdCategoryList;
      this.pagination.total = res.data.totalCount;
    });
  }
}

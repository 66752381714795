




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class YlmsContent extends Vue {
  // @Prop() private msg!: string
}
















































































































    import {Component, Vue} from "vue-property-decorator";
    import {Checkbox, Icon, Input, List, message, Tabs, Tooltip,} from "ant-design-vue";

    import ServiceTrashItem from "@/components/Personal/MyService/ServiceTrashItem.vue";
    import recycleMyGoodsItem from "@/components/Personal/myGoods/recycleMyGoodsItem.vue";
    import {State} from "vuex-class";
    import {deleteBatches, massReduction, orderFormList,} from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
  components: {
    ServiceTrashItem,
    recycleMyGoodsItem
  },
})
export default class extends Vue {
  @State allOrderStatus;
  @State allServiceStaus;
  serviceStatus = {};
  isAllCheck: Boolean = false;
  deleteModel: Boolean = false;
  serviceOrderInfoList: Array<any> = [];
  data() {
    return {
      OrdersStatus: {},
      data: [],
    };
  }
    //返回上一页
  chnabftab(e) {
    e.preventDefault();
    this.$emit("chnabftab");
  }

  pagination = {
    total: 0,
    pageSize: 5,
    pageIndex: 1,
  };

  created() {
    this.getList();
  }

  //全选
  onChange(){
    this.isAllCheck = !this.isAllCheck;
    if (this.isAllCheck) {
      this.serviceOrderInfoList = [];
      (this as any).data.forEach((el) => {
        el.ischeck = true;
        this.serviceOrderInfoList.push(el.orderInfoId);
      });
    } else {
      let data = (this as any).data.filter((el) => el.ischeck === true);
      if (data.length === this.data.length) {
        this.serviceOrderInfoList = [];
        (this as any).data.forEach((el) => {
          el.ischeck = false;
        });
      }
    }
  }





  //从新获取数据
  onChangePageSize(page, pageSize) {
    this.pagination.pageIndex = page;
    this.getList();
  }
  //单个数据选中
  getOrderInfoList(obj) {
    this.data[obj.id].ischeck = true;
    this.serviceOrderInfoList.push(obj.serviceOrderInfoId);
    if (this.serviceOrderInfoList.length == this.data.length) {
      this.isAllCheck = true;
    } else {
      this.isAllCheck = false;
    }
  }
  //单个数据取消选中
  deleteOrderInfoList(obj) {
    this.data[obj.id].ischeck = false;
    let newArr: any = [];
    for (let i = 0; i < this.serviceOrderInfoList.length; i++) {
      let newObj: any = JSON.parse(JSON.stringify(this.serviceOrderInfoList[i]));
      newArr.push(newObj);
    }
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].serviceOrderId === obj.serviceOrderInfoId) {
        newArr.splice(i, 1);
      }
    }
    this.serviceOrderInfoList = newArr;
    this.isAllCheck = false;
  }
  getList() {
    (this as any).data = [];
    orderFormList({
      ...this.pagination,
      status: 7
    }).then(res => {
      let data = (res as any).data;
      this.pagination.total = data.totalCount;
      data.list.forEach((el, i) => {
        (this as any).data.push({
          ischeck: false,
          id: i,
          ...el,
        });
      });
    });
  }
  //单个还原
  revivification(id) {
    this.serviceOrderInfoList = [];
    this.serviceOrderInfoList.push(id);
    this.huanyuanByQueryEvent();
  }
  //批量还原
  huanyuanByQuery() {
    this.huanyuanByQueryEvent();
  }
  //删除提示
  deleteByQuery(value) {
    this.deleteModel = true;
    if(value.length!=undefined){
      this.serviceOrderInfoList = [];
      this.serviceOrderInfoList.push(value);
    }
  }
  //确认删除
  comfrimDelete() {
    deleteBatches({
      orderInfoIdList: this.serviceOrderInfoList,
    }).then((res) => {
      if (res.code === 0) {
        message.success("删除成功");
        this.serviceOrderInfoList = [];
        this.getList();
      } else {
        message.error("删除失败");
      }
      this.deleteModel = false;
    });
  }
  //还原方法
  huanyuanByQueryEvent() {
    massReduction({
      orderInfoIdList: this.serviceOrderInfoList,
    }).then((res) => {
      if (res.code === 0) {
        message.success("还原成功");
        this.serviceOrderInfoList = [];
        this.getList();
      } else {
        message.error("还原失败");
      }
    });
  }

}

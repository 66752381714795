<template>
  <div>
    <div class="content-box">
      <iframe
        :src="url"
        frameborder="0"
        style="width:12rem;height:680px"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "CooperationAgreement",
  data() {
    return {
      url: "./html/110222021620.html",
      content:
        "<div>\n" +
        "        <h3>签约须知：</h3>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;《芳园天伦智慧养老平台+养老机构合作协议》（以下简称“本协议”）是由芳圆天伦智慧养老平台（以下简称“甲方”）与以独立经营者身份入驻芳圆天伦的法律实体（养老机构以独立经营店铺在甲方网站填写的入驻人为准，以下简称“乙方”）达成的关于提供和使用平台的各项条款、条件和规则。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;甲方在此特别提醒乙方认真阅读、充分理解本协议各条款（对于本协议中以加粗字体和加线显示的内容，应重点阅读），并请乙方审慎考虑并选择接受或不接受本协议。如果乙方一经点击“我已经阅读并且同意《用户协议》”按钮，即表示其已接受本协议，并同意受本协议各项条款的约束。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;此“签约须知”为本协议正文的组成部分。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;一、协议内容概述：</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.1 协议内容。本协议包括协议正文、附件及所有甲方已经发布的或将来可能发布的各类规则、规范、规则解读、实施细则、通知、公告等（以下合称“平台规则”）。所有附件及平台规则均为本协议不可分割的一部分，与协议正文具有同等法律效力。协议正文、附件与平台规则冲突的，以发布在后的文件为准执行。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.2 乙方首次申请入驻平台时，在签署或在线接受本协议后，本协议立即生效，但本协议项下的平台服务并不立即开通。乙方在履行上传《营业执照》及相关经营资质、店铺资料等义务，经甲方审核通过并向乙方发出服务开通通知（通知方式包括但不限于邮箱、手机短信等）时，本协议项下的平台服务正式开通。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.3 协议变更及生效。甲方有权变更（包括但不限于制定、修订、废止）本协议正文、附件及/或平台规则，将至少提前 5日在甲方网站公示，并载明生效日期，乙方应实时关注公示内容。如乙方不接受变更，应当在公示期间内申请退店，则乙方店铺将进入“关店准备”状态，本协议将终止。乙方清楚知晓、同意并确认前述“关店准备”状态下的店铺功能限制，甲方对于该等限制、协议终止不负有任何违约责任或其他责任。如乙方未按前述约定申请退店，即视为乙方接受前述变更事项。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.4 根据市场运营需要，甲乙双方共同策划、为乙方开展促销运营活动，甲方根据广告费用收费标准出具广告费收费单给乙方，并提供广告费用发票。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.5 乙方负责店铺的日常维护及管理，甲方为乙方提供必要的技术支持及培训指导。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;二、合作及利益分配形式</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;1.免费使用。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.1 甲方将自主研发的养老机构应用管理软件提供给乙方免费注册使用、 0 年费，并负责对该管理软件免费进行升级、维护（升级仅限于甲方研发层面）。乙方如要定制专门的管理软件，则另行协议收费。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.2 乙方负责将老人及其子女注册成为平台养老机构的会员。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;2.利益分享。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.1 子女会员通过平台有选择服务商、查看老人服务信息等权利</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.2 乙方享受注册会员在平台养老商城的消费收益，甲方将收入按照粉尘比例的分成（含税）分享给乙方。甲方建议乙方将机构所得的会员消费收益，进一步按一定的比例与相应的照护老人的护工进行再分配。分配比例由乙方根据护工等级等情况自行确定。此建议体现了甲方的运营理念，乙方应予以重视和配合。无特殊情况，拒不配合的，甲方有权针对该类养老机构对其在会员消费收益分配的规则上做出调整。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;三、双方的权利与义务</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.1甲方的权利与义务</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.1 甲方拥有本协议条款项下服务和/或产品的所有权。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.2 甲方有权变更、暂停或取消部分服务和/或产品及其某种特性、功能或API接口。在进行前述操作前，甲方将在平台网站上公布或以邮件、短信的方式通知乙方，即视为乙方已知悉该情况。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.3 甲方有权对平台及其部分功能、服务等进行修改、删除等；也有权自行开发新的模块、功能和软件。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.4 甲方有权根据国家法律法规变化及网站运营需要，不定期地对平台进行升级。在进行前述操作前，甲方将在平台网站上公布或以邮件、短信的方式通知乙方，即视为乙方已知悉该情况。因乙方不配合甲方完成平台升级而造成的任何后果，甲方不承担任何责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.5 若甲方发现乙方的行为违反相关法律法规、本协议、平台管理规则及其他相关协议时，甲方有权根据自己的独立判断随时单方面采取相应措施，所造成的一切后果均由乙方自行承担。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.6 甲方有权对乙方提供的证明文件及信息进行不定时的抽查，并有权要求乙方提供资质证明等文件原件或提供更多证明文件、信息以供审查，乙方应当予以配合。如乙方家不能提供，则甲方有权立即中止/解除本协议。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.7 甲方有权对乙方的服务质量进行监督检查，根据平台规则对乙方进行考核，并对服务不达标、客服回复率或者回复态度不符合要求的进行处理。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.8 甲方负责提供本协议条款项下服务和/或产品及相应的技术支持。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.1.9 甲方会及时排除乙方在使用平台过程中非人为操作原因所出现的故障。但因乙方原因造成的、不可抗力导致的以及非甲方控制范围之内的故障除外。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2 乙方的权利与义务</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.1 根据本协议，乙方有权使用乙方的账户登录平台，并可自行选择平台中的相关产品和/或服务。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.2 乙方在使用平台时所产生的原始数据所有权归属于乙方；但甲方经过对乙方的姓名、身份证和移动电话进行充分脱敏处理后的数据，以及在原始数据上进行大数据分析得出的模型和相关衍生物的所有权归属于甲方。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.3 在使用平台及相关服务和/或产品的过程中，乙方应当遵守相关法律法规、规章制度、本协议、平台管理规则等规定，并对乙方的违法或违约行为自行承担责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.4 乙方同意并遵守本协议及平台公布的各相关管理流程、服务条款。若本协议及相关服务条款等内容发生变更的，甲方将提前在平台网站上公布或以邮件、短信的方式通知乙方，即视为乙方已知悉该情况。若乙方对修改的内容有异议的，乙方有权选择暂停或停止使用平台。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.5 乙方承诺不得利用平台从事以下违法、违规、违约等活动。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.5.1 不得进行任何破坏或试图破坏网络安全的行为，此种行为包括但不限于：钓鱼，黑客，网络诈骗；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.5.2 在经营内容中不得含有或涉嫌散播病毒、木马、恶意代码，或利用系统漏洞获取不正当利益等。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.5.3 不得有任何改变或试图改变平台提供的系统配置或破坏系统安全的行为。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.5.4 不得以违反本协议约定的方式或目的使用平台，违反本协议约定的方式包括但不限于：修改、涂抹、篡改或以其它方式创建云服务中任何软件的衍生品。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.2.5.5 乙方不得进行反向工程、反汇编或反编译。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.6 乙方应当正确使用乙方的账户及平台，采取一切合理、合法措施保护乙方账户的安全，对乙方的内容进行保护和安全备份。由于乙方未采取措施或采取的措施不当导致的一切结果，均由乙方自行承担。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.7 管理、维护乙方在平台上的店铺，在店铺运营过程中严格遵守法律、法规及平台规则提供服务；同时乙方应依法为消费者开具正规的服务发票，相关税费由乙方自行承担。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.8 乙方提供的服务质量应符合国家相关规定要求，确保客户能得到满意的服务和品质保证。积极有效地解决客户的投诉，切实保障客户的合法权益。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.9 乙方应保证其向甲方及相关合作方提供的全部文件及信息全面、真实、准确、合法、有效。乙方提供虚假、过期、失效文件或信息，或未及时通知并更新其文件或信息的，应独立承担全部法律责任。由此导致乙方不符合甲方入驻标准或不再具备履行本协议的能力的，甲方有权立即中止/解除本协议。给甲方或其他任何第三方造成损失的，乙方须赔偿其全部损失，且甲方有权立即解除本协议。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.10 乙方应遵守法律规定，确保产品以及发布的所有信息包括但不限于产品宣传信息，应全部真实、合法，且不损害他人合法权益，且甲方有权随时解除本协议。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.2.11 乙方不得做出任何可能对甲方造成负面影响的欺诈、违法或不道德的行为。乙方应当切实维护用户和其他参与者的合法权益，不应策划、参与任何违法或有损市场公平准则的营销活动。否则，甲方有权随时解除本协议。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;四、账户安全</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.1 乙方已知悉并同意：乙方签署本协议并提供相关证件资料，审核通过后，自从平台方获得账户开通成功的通知（邮件通知或其他方式）起即获得了平台的账户。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.2 乙方应当妥善保管好乙方的登录账号和密码，保证正确、安全地使用乙方的账户，并对乙方账户下的所有内容和行为承担全部责任；因乙方未能保管好该账户和密码而导致的一切损失和后果均由乙方全部承担。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.3 乙方承诺乙方的账户仅限于乙方自己使用，未经甲方事先书面同意，乙方不得将乙方的账户以任何方式（包括但不限于：转让、授权、出租、出借）提供给第三方使用或与第三方共同使用。如有合理理由怀疑乙方有前述情况，甲方有权根据实际情况采取以下措施：</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;a.向乙方发出改正通知；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;b.不经通知而单方面冻结或禁止乙方账户的使用；甲方对此不承担任何责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4.4 若乙方忘记或遗失乙方的登录密码，乙方应及时登录平台重置密码，必要时应及时通知甲方采取措施停止乙方账户的登录和使用。因乙方未及时采取前述操作或其他合理措施而造成的后果，均由乙方自行承担。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;五、知识产权条款</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.1 《芳圆天伦智慧养老平台+》中的服务和/或产品、云计算系统软件及相关技术资料的知识产权属甲方所有（第三方系统软件除外），乙方仅享有本协议项下的非独占、非排他的一般使用权；本协议终止后，前述一般使用权也自行终止。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.2 除本协议规定外，未经对方书面同意，任何一方不得使用或允许任何第三方使用另一方的任何商号、商标、品牌。双方明确理解：一方的商号、商标、品牌均为该方的专有财产，本协议中的任何规定均不构成向另一方授予对上述商号、商标、品牌使用的许可。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.3 双方确认：甲方在本协议项下提供的产品和/或服务可能包括第三方软件，该第三方软件是甲方合法取得使用权或著作权的软件。对该第三方软件乙方仅享有本协议项下的非独占、非排他的一般使用权，乙方不得删除、修改或掩藏任何第三方所提供的产品和/或软件中包含的任何版权、商标或其他专有权声明。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.4 乙方保证乙方自行使用的非甲方提供的软件的合法性。若乙方违反本条规定，甲方有权终止某一项或全部服务，且此行为不视为甲方违约。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.5 双方对在签订和履行本协议过程中获知的对方的技术、商业秘密等信息负有保密责任，无论在本协议期限内还是协议终止后，均应共同遵守相关法律法规及本协议的规定，尊重对方的知识产权和商业秘密。未经对方事先书面授权，任一方不得以任何方式将对方的技术、商业秘密等信息提供给任何第三方使用或与任何第三方共同使用或不正当使用。违反本条规定给对方造成损失的，违约方应承担相关责任；但依据有关法律、法规应当提供的，或司法、行政机关要求甲方必须提供的除外。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 5.6 本条款在本协议终止之后仍对本协议双方具有约束力。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;六、隐私条款</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.1 甲方将对乙方在使用平台过程中所提供的信息，包括注册账户时的信息、购买相关产品和/或服务时提供的信息、资料、登录记录等（以下统称：信息）进行严格保密。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.2 未经乙方同意，甲方不会将已知悉的乙方的信息泄露给第三方，但不包括以下情况：</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6.2.1 依据法律法规的规定可以提供的；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6.2.2 司法、行政等政府部门要求提供的；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6.2.3 依据本协议或其他相关协议、规则等规定可以提供的；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6.2.4 为防止严重违法行为或涉嫌犯罪行为的发生而采取必要、合理行动所必须提供的。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.3 未经乙方同意，甲方不会向第三方提供乙方的信息及数据。但乙方理解并同意：为给乙方提供更好的服务体验、基于产品正常运行、运营、维护管理等需要，甲方将有可能采用业界领先的云服务器提供商，对乙方的相关信息及数据进行迁移、维护等操作，此等操作并不构成对乙方的隐私资料及保密信息的侵犯。 &nbsp;</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.4 协议双方承诺在本协议终止之后仍然继续承担在此条款下的保密义务，任何一方有权对其他方故意或过失泄露商业秘密所造成的损失提出赔偿。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.5 因对方书面同意以及国家、行政、司法强制行为而披露商业秘密的，披露方不承担责任；该商业秘密已为公众所知悉的，披露方不承担责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.6 乙方未经甲方许可，不得擅自获取、使用、传播甲方平台的任何资料，不得将从甲方平台获取的任何信息或数据用于本协议约定以外的用途。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;七、系统故障中断或故障声明</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;乙方理解并同意：在使用平台的过程中，可能会因为网络及计算机的特殊原因导致服务中断、访问速度下降、数据丢失及其他损失和风险。甲方将尽力避免其风险，但对此不承担违约责任，亦不会对由此造成的损失承担责任。引发前述情况的原因包括但不限于：</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.1 不可抗力，包括但不限于自然灾害、政府行为、罢工、动乱；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.2 基础运营商过错，包括但不限于电信部门技术调整、电信/电力部门对电信网络/电力资源进行安装、改造、维护；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.3 网络安全事故，包括但不限于计算机病毒、木马或其他恶意程序、黑客攻击；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.4 乙方自身原因造成的，如：乙方操作不当或乙方的电脑软件、系统、硬件和通信线路出现故障；乙方通过非甲方授权的方式使用相关服务；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.5 网络调整和/或维护，或者Internet上通路的阻塞；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 7.6 其他非甲方过错、无法控制或预见的情形。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;八、责任范围及责任限制</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.1 乙方理解并同意：甲方在提前通知乙方进行数据备份或业务暂停时，有权对平台网站进行维护，此种维护包括但不限于：故障处理、系统升级、系统调优、系统扩容。因上述维护导致的乙方业务的暂停，不属于甲方违约。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.2 乙方理解并同意：甲方在没有重大过失或恶意的情况下，对乙方在使用平台过程中所遇到的数据传输迟延、不准确或错误及由此遭受的损失不承担责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.3 乙方理解并同意：乙方自行使用非甲方提供的软件、服务而影响乙方对平台的使用，或造成网络安全问题而影响其他使用平台的用户时，将由乙方承担全部责任，甲方不承担任何责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.4 乙方理解并同意：因国家政策调整或有关政府部门的行为，限制或禁止乙方使用平台时，甲方在知晓此情况时将及时通知乙方。此种情况下甲方不承担任何责任。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.5 乙方理解并同意：甲方不能保证平台没有任何瑕疵。如果平台存在瑕疵，但此种瑕疵是当时行业技术水平所无法发现或无法避免的，则不属于甲方违约。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8.6 乙方理解并同意：在法律允许的情况下，甲方对与本协议相关的或由本协议引起的间接的、惩罚性的、派生的损失（包括但不限于：业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约而产生的，还是由侵权造成的，均不负有任何责任。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;九、不可抗力</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.1 本协议中的不可抗力系指本协议任何一方不能预见、不能避免且不可克服的事件，包括但不限于：</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9.1.1 自然灾害：如地震、洪水、冰雹等；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9.1.2 社会异常事件：如战争、罢工、骚乱等；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9.1.3 政府行为，如征收、征用、行政许可的授予及撤回等；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;9.1.4 电力供应中断、黑客攻击、计算机病毒等。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.2 本协议双方因不可抗力而影响本协议履行的，应当在不可抗力事件发生之日起10日内将具体情况以电子邮件或传真的方式告知对方，并采取合理、必要的措施防止损失的扩大。遭受不可抗力的一方应当在不可抗力事件消除之日起3日内以电子邮件或传真形式通知对方。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 9.3 双方同意本协议履行期限可在不可抗力影响范围内延长；任何一方均不对不可抗力产生的后果承担责任。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;十、协议解除</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;除本协议另有约定之外，甲方在以下情形下，有权随时书面通知乙方终止本协议：</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 10.1 依据法律法规或行政、司法机关的要求；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 10.2 由于法律或政策变动原因使甲方无法继续向乙方提供平台的；</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 10.3 因乙方的原因导致甲方继续向乙方提供服务将会对甲方造成巨大损失或风险的；</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;十一、法律适用</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp;本协议的订立、履行、解释及因本协议及与之相关协议所发生的争议，均适用于中华人民共和国法律。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;十二、违约责任</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.1 在平台进行的交易中，如因乙方违反法律、法规、有关政策、本协议/甲方网站规则/其对用户发布的承诺，致使用户受损时。甲方有权对该事件做出独立的判断，并最终有权要求乙方赔偿甲方遭受的损失。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.2 因任何一方违反基于本协议项目下的陈述、承诺、保证或义务，而使另一方遭受任何诉讼、纠纷、索赔、处罚等情形的，由违约方负责解决。过程中导致守约方产生任何费用、连带责任或遭受直接经济损失的，违约方应当负责赔偿。前述损失包括但不限于因违约造成的直接经济损失以及因涉诉导致的诉讼费、律师代理费、交通费、评估费等费用。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.3 当一方违反基于本协议项目中其应尽之义务，并且经非违约方请求后仍不履行的，守约方有权单方面解除本合同并要求违约方赔偿因此产生的损失。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.4 任何情况下甲方及其董事、员工或代理人，均不会对乙方或任何第三方就任何间接性、偶发性、特殊性或惩罚性的损害承担责任，即使甲方已经知道或已被告知发生上述损害的可能性。</p>\n" +
        "        <h4>&nbsp; &nbsp; &nbsp; &nbsp;十三、其他事宜</h4>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.1 本协议、平台规则及与之相关的协议的解释权归甲方所有，任何其他方做出的解释均无效。</p>\n" +
        "        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 12.2 因本协议及与之相关协议所发生的一切争议，双方均应友好协商解决。协商不成的，任何一方均可通过向甲方所在地有管辖权的法院提起诉讼进行解决。</p>\n" +
        "    </div>"
    };
  }
};
</script>

<style scoped lang="less">
.content-box {
  font-size: 0.2rem;
  text-align: left;
  line-height: 0.35rem;
  .intent {
    text-indent: 2em;
  }
}
</style>











































import {Component, Vue} from "vue-property-decorator";
import {fetchHotService} from "@/services/api";

@Component
export default class serviceItems extends Vue {
    hotServices: any[] = [];
    position: boolean = false;

    updated() {
        this.position = location.href.includes("servicePage");
    }

    getCityId() {
        return new Promise((resolve) => {
            const t1 = setInterval(() => {
                if (localStorage.getItem("provinceId")) {
                    resolve(localStorage.getItem("provinceId"));
                    clearInterval(t1);
                }
            }, 200);
            const t2 = setTimeout(() => {
                resolve("2");
                clearTimeout(t2);
                clearInterval(t1);
            }, 5000);
        });
    }

    async mounted() {
        const cityId: any = await this.getCityId();

        fetchHotService({
            cityId,
            limit: 8,
        }).then((res) => {
            if (res.data && res.data.serviceList && res.data.serviceList.length) {
                res.data.serviceList.forEach((item) => this.hotServices.push(item));
            }
        });
    }
}

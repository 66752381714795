















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  props: {
    img: String,
    title: String,
    content: String,
    from: String,
    newsId: String,
    publishTime: String,
    newsType: String
  }
})
export default class PensionNewsItem extends Vue {
  // @Prop() private msg!: string
  // @Prop() private next: object;
  // @Prop() private pre: object;
  @Prop() labelsList: any;
  get lables() {
    return this.labelsList.split(",");
  }
  get contentF() {

     if(!this.$props.content) return "";

    let a = Base64.decode(this.$props.content)
      .replace(/<br>/g, "")
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .trim();
     let  regex = new RegExp("<.+?>", "g");
let strOutput = a.replace("&nbsp;", "").replace(regex,"");
return strOutput;
  }
  handleViewDetail() {
    const { href } = this.$router.resolve(`/artical/${this.$props.newsId}`);
    open(href);
  }
}

<template>
    <div class="ServiceOrderPay-body">
        <payHeader/>
        <div id="ServiceOrderPay-top">
            <div id="ServiceOrderPay-header">
                <div class="img-span">
                    <img src="../assets/images/homeImg/dlLogo.png" alt @click="jumpToHome"/>
                    <span></span>
                </div>
                <div class="ServiceOrderPay-input">
                    <div class="home_input">
                        <a-input-search
                                :value="searchVal"
                                @change="onSearchChange"
                                @search="onSearch"
                                placeholder="请输入您想找的服务商或服务项目"
                                enter-button
                        />
                    </div>
                </div>

            </div>
        </div>
        <div class="ServiceOrderPay-center">
            <div class="ServiceOrderPay-details">
                <div class="submit-order">
                    <img src="../assets/images/homeImg/路径 2368@2x.png"/>
                    <div class="accountFor">
                        <p class="p1">服务订单提交成功，请尽快付款！</p>
                        <p class="p2">请您在24小时内完成支付，否则服务订单会被自动取消</p>
                    </div>
                </div>
                <div class="submit-order-sum">
          <span class="money">应付总金额 <span class="money-red">￥ </span><span
                  class="money-yuan">{{ paymentDetails.totalCost }}</span> 元</span>
                    <span class="order-details" @click="showOrderDetail = true" v-if="!showOrderDetail">服务订单详情<a-icon
                            style="font-size: 0.13rem;margin-left: 0.03rem" type="down"/></span>
                    <span class="order-details" @click="showOrderDetail = false" v-if="showOrderDetail">收起服务订单详情<a-icon
                            style="font-size: 0.13rem;margin-left: 0.03rem" type="up"/></span>
                </div>
                <div class="line-item" v-show="showOrderDetail">
                    <div v-if="paymentDetails.ifShop">
                        <div v-for="(store,index) in paymentDetails.stores" v-if="index<1">
                            <div class="information">
                                <p>门店名称： {{ store.storeName || "--" }}</p>
                                <p>门店电话： {{ store.contactPhone || "--" }}</p>
                            </div>
                            <div class="information">
                                <p>门店地址： {{ store.fullAddress }}</p>
                            </div>
                            <div class="information">
                                <p>服务名称：{{ store.serviceInfo[0].serviceName }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="!paymentDetails.ifShop">
                        <div  v-for="(item,index) in paymentDetails.serviceInfo" v-if="index<1">
                            <div class="information">
                                <p>联系人：{{ paymentDetails.receiverName || "--" }}</p>
                                <p>联系人电话：{{ paymentDetails.phoneNumber || "--" }}</p>
                            </div>
                            <div class="information">
                                <p>服务地址： {{ address }}</p>
                            </div>
                            <div class="information">
                                <p>服务名称：{{ item.serviceName }}</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="payment-details">
                <div class="pattern">支付方式</div>
                <div class="radio-box">
                    <a-radio-group name="radioGroup" v-model="value">
                        <a-radio :value="1">
                            <span class="payStyle"><img src="../assets/images/homeImg/路径 2018@2x.png"/>支付宝支付</span>
                        </a-radio>
                    </a-radio-group>
                </div>
                <div class="payment-btn">
                    <a-button class="payment-button" @click="showModal"><img
                            src="../assets/images/homeImg/路径 2360@2x.png"/>立即支付
                    </a-button>
                </div>

            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Vue from "vue";
    // import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import payHeader from "@/components/payHeader";
    import {Button, Cascader, Checkbox, Form, Input, message, Modal, Select, Table, Upload,} from "ant-design-vue";
    import {servicePay} from "@/services/api";
    import {baseUrl, request} from "@/services/http";

    Vue.use(Checkbox);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Cascader);
    Vue.use(Upload);
    Vue.use(Modal);
    Vue.use(Table);

    export default {
        components: {
            // Nav,
            Footer,
            payHeader,
        },
        data() {
            return {
                value: 1,
                address: "",
                mainServiceOrderCode: "",
                searchVal: '',
                showOrderDetail: false,
                paymentDetails: {}
            };
        },
        mounted() {
            this.mainServiceOrderCode = this.$route.query.mainServiceOrderCode;
            this.getAddress(this.mainServiceOrderCode);
        },

        methods: {

            //支付调用支付宝
            showModal() {
                let params = {
                    mainServiceOrderCode: this.mainServiceOrderCode,
                    purchaseMethod: this.value,
                    payTotal: this.paymentDetails.totalCost,
                };
                servicePay(params).then((res) => {
                    if (res.code) {
                        message.error(res.msg);
                    } else {
                        let win = window.open("", "_self");
                        const div = document.createElement("div"); // 创建div
                        div.innerHTML = res; // 将返回的form 放入div
                        win.document.body.appendChild(div);
                        win.document.forms[0].submit();
                    }

                })
            },
            //搜索
            onSearchChange(e) {
                this.searchVal = e.target.value;
                sessionStorage.setItem("search", e.target.value);
            },
            //搜索
            onSearch(value) {
                const path = window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1,
                    window.location.href.length
                );
                if (path !== "servicePage") {
                    this.$router.push("/servicePage");
                }
                if (this.$props.handleNavSearch) {
                    this.$props.handleNavSearch(value);
                }
            },
            //返回首页
            jumpToHome() {
                this.$router.push("/");
            },
            //根据联合订单编号获取地址
            getAddress() {
                const that = this
                if (that.mainServiceOrderCode) {
                    request(`${baseUrl}/api/v1/services/pay/info/${that.mainServiceOrderCode}`, {}).then((res) => {
                        if (res.code == 0 && res.info) {
                            that.paymentDetails = res.info;
                            that.address = res.info.addressProvince + res.info.addressCity + res.info.addressArea + (res.info.addressStreet==null?'':res.info.addressStreet) + res.info.addressDetail;
                        }
                    });
                }

            }
        },
    };
</script>

<style lang="less">
    .ServiceOrderPay-body {
        background: #F5F7FA;
        opacity: 1;
        min-width: 11.72rem;
        user-select: none;

        #ServiceOrderPay-top {
            height: 81px;
            width: 100%;
            min-width: 10rem;
            background: #ffffff;

            #ServiceOrderPay-header {
                user-select: none;
                height: 100%;
                width: 11.72rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                opacity: 1;
                margin: 0 auto;

                .img-span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    min-width: 2.3rem;

                    img {
                        width: 1.24rem;
                        height: 0.59rem;
                        cursor: pointer;

                    }

                    span {
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #39BEB8;
                        opacity: 1;
                        display: inline-block;
                        padding-left: 0.47rem;
                        margin-top: 0.12rem;
                    }
                }

                .ServiceOrderPay-input {
                    min-width: 3.74rem;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .home_input {
                        /*width: 4rem;*/
                        display: flex;
                        align-items: center;

                        .ant-input {
                            min-width: 2.95rem;
                            font-size: 0.14rem;
                            height: 0.48rem;

                        }

                        .ant-input {
                            &::placeholder {
                                color: #78849E;
                                font-size: 12px;
                                opacity: 1;
                            }
                        }

                        button.ant-btn.ant-btn-primary.ant-input-search-button {
                            width: 0.77rem;
                            height: 0.48rem;
                            background-color: #60c7c6;
                            border-radius: 0 0.5rem 0.5rem 0;
                            border-color: #60c7c6;
                            font-size: 0.2rem;
                        }

                        input.ant-input:focus {
                            border-color: #d9d9d9;
                            border-radius: 0.5rem 0 0 0.5rem;
                            -webkit-box-shadow: 0 0 0 0;
                            box-shadow: 0 0 0 0;
                        }

                        input.ant-input:hover {
                            border-color: #d9d9d9;
                        }

                        input.ant-input {
                            border-radius: 0.5rem 0 0 0.5rem;
                        }
                    }
                }


            }
        }

        .ServiceOrderPay-center {
            width: 100%;

            .ServiceOrderPay-details {
                margin: 0 auto;
                margin-top: 0.25rem;
                width: 11.72rem;
                min-height: 3.17rem;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 0.12rem;
                padding-top: 0.3rem;
                padding-bottom: 0.2rem;

                .submit-order {
                    width: 523px;
                    height: 1.07rem;
                    background: #F9F9F9;
                    opacity: 1;
                    border-radius: 0.12rem;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    user-select: none;

                    img {
                        width: 0.41rem;
                        height: 0.41rem;
                        margin-left: 0.86rem;
                    }

                    .accountFor {
                        width: 296px;
                        margin-left: 0.14rem;

                        .p1 {
                            font-size: 0.16rem;
                            font-weight: bold;
                            color: #454F63;
                            opacity: 1;
                            padding: 0;
                            margin: 0;
                        }

                        .p2 {
                            font-size: 0.12rem;
                            font-weight: 400;
                            color: #454F63;
                            opacity: 1;
                            padding: 0;
                            margin: 0;
                            margin-top: 0.03rem;
                        }
                    }
                }

                .submit-order-sum {
                    width: 5.23rem;
                    height: 0.4rem;
                    /*border: 1px solid red;*/
                    border-bottom: 1px solid #E4E9F2;
                    margin: 0 auto;
                    margin-top: 0.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /*vertical-align: bottom;*/
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                    user-select: none;

                    .money {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin-top: -0.09rem;
                        margin-right: 0.48rem;
                        /*border: 1px solid red;*/

                        .money-red {
                            font-family: Roboto;
                            font-weight: bold;
                            color: #FF5242;
                            opacity: 1;
                        }

                        .money-yuan {
                            font-size: 0.24rem;
                            font-family: Roboto;
                            font-weight: 900;
                            color: #FF5242;
                            opacity: 1;
                        }
                    }

                    .order-details {
                        min-width: 1.3rem;
                        font-size: 0.13rem;
                        font-weight: 400;
                        color: #5ABAFA;
                        opacity: 1;
                        vertical-align: bottom;
                        cursor: pointer;
                        /*border: 1px solid red;*/
                        /*margin-bottom: 0.03rem;*/
                    }

                }

                .line-item {
                    text-align: center;
                    width: 5.23rem;
                    margin: 0 auto;
                    margin-top: 0.19rem;

                    p {
                        font-size: 0.13rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin: 0;
                        margin-right: 0.3rem;
                        text-align: left;
                    }

                    .information {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.05rem;
                    }

                }

            }

            .payment-details {
                width: 11.71rem;
                height: 2.48rem;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 0.12rem;
                margin: 0 auto;
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;

                .pattern {
                    height: 0.61rem;
                    border-bottom: 1px solid #E4E9F2;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #454F63;
                    padding-left: 0.2rem;

                }

                .radio-box {
                    margin: 0 auto;
                    width: 7rem;
                    display: flex;
                    margin-top: 0.32rem;
                    /*border:1px  solid red;*/

                    .payStyle {
                        /*border: 1px solid red;*/
                        display: inline-block;
                        font-size: 0.14rem;
                        font-weight: bold;
                        color: #454F63;
                        opacity: 1;
                        /*margin: 0 auto;*/
                        margin-right: 0.5rem;


                        img {
                            width: 0.26rem;
                            height: 0.26rem;
                            display: inline-block;
                            margin-right: 0.16rem;
                        }
                    }

                    .ant-radio-checked .ant-radio-inner {
                        border-color: #60c7c6;
                    }

                    .ant-radio-inner::after {
                        background-color: #60c7c6;
                    }
                }

                .payment-btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 0.39rem;

                    .payment-button {
                        width: 1.46rem;
                        height: 0.4rem;
                        background: #39BEB8;
                        opacity: 1;
                        border-radius: 0.2rem;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 14.8px;
                            height: 14.79px;
                            margin-right: 0.04rem;
                            margin-top: 0.03rem;
                        }

                    }

                    .payment-button:hover {
                        border: 1px solid #39BEB8;
                        background: #39BEB8;
                    }
                }


            }

        }

    }
</style>

















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {collectionsOrgService, fetchCollection} from "@/services/api";

@Component({
    props: {
        image: String,
        title: String,
        price: Number,
        count: Number,
        markPrice: Number,
        serviceId: String,
        allInfo: Object,
        orgImages: Array,
    },
})
export default class CookInHomeHeader extends Vue {
    serviceInfoId = "";

    // @Prop() private allInfo: Object;
    skuInfoId = "";
    skuInfo = "";
    curType: any = 0;
    amount = 1;
    serviceSpecification = '';
    serviceSpecificationType = false;
    servicePrice: any = 0;
    collectionString = "收藏";
    skuList: any = [];
    skuSettingMap: any = {};
    select: any = {};
    currentImageIndex = 0;
    showImage:any = false;
    flag = -1;
    @Prop() private orgId!: string;

    /**
     * 获取服务的图片，如果选中了规格，
     * 并且规格有设置自己的图片，
     * 则按照规格的图片来显示
     */
    get _imgUrl() {

        const that = this
        let serviceImageSelect = that.$props.orgImages.length > 0 ? that.$props.orgImages[that.currentImageIndex].orgImage : that.$props.image;

        // 判断当前是否选择了有规格设置
        let skuFind = that.skuList.find(i => i.skuInfoId == that.skuInfoId);

        if (skuFind && skuFind.skuImg && skuFind.skuImg.indexOf('http') >= 0) {
            serviceImageSelect = skuFind.skuImg
        }
        return serviceImageSelect;
    }

    @Watch("orgId", {immediate: true, deep: true})
    getorgId(newVal, oldVal) {
        // this.isCollectionsOrgService(newVal);
    }

    //获取规格信息
    @Watch("allInfo", {immediate: true, deep: true})
    getallInfo(newVal) {
        this.skuList = newVal.skuList
        this.skuSettingMap = newVal.skuSettingMap;
        // //默认选中第几个
        this.skuSettingMap.forEach((element) => {
            if (element.skuValue.length === 1) {
                element.select = 0;
                Object.assign(this.select, {[element.skuName]: element.skuValue[0]});
            }

        });
        // //字符串转数组
        this.skuSettingMap.forEach(el => {
            const mapItem = el.skuValue
            let cc = typeof (mapItem);
            if (cc === 'string') {
                let str = mapItem.replaceAll('[', '').replaceAll(']', '');
                let itemArr = str.split(', ');
                el.skuValue = itemArr;
            }

        })
        if (this.skuSettingMap.length) {
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.trim().includes(val));
            });
            // this.skuChange(arr[0]);
        } else {
            this.$nextTick().then((r) => this.skuChange(this.skuList[0]));
        }
    }

    @Watch("price", {immediate: true, deep: true})
    getprice(newVal) {
        this.servicePrice = newVal
    }
    //
    // created() {
    //
    // }

    mounted() {
        this.serviceInfoId = this.$route.params.serviceId;
        this.isCollectionsOrgService();
    }

    handlePreImg() {
        if (this.currentImageIndex > 0) {
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft =
                    -(this.currentImageIndex - 4) * 1.53 + "rem";
            }
            this.currentImageIndex -= 1;
        }
    }

    handleNextImg() {
        if (this.currentImageIndex < this.$props.orgImages.length - 1) {
            this.currentImageIndex += 1;
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft = -this.currentImageIndex * 1.53 + "rem";
            }
        }
    }


    //服务内容
    typeClick2(item, i, val) {
        if (i != this.flag) {
            //点击选中
            this.flag = i;
            val.select = i;
            Object.assign(this.select, {[val.skuName]: item.trim()});
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.split(' ').includes(val))
            });
            this.skuChange1(arr[0]);
            if (arr.length === 1) {
                this.skuChange(arr[0]);
            }

        } else{
            //点击取消选中
            this.flag = -1;
            val.select = -1;
            Object.assign(this.select, {[val.skuName]: item.trim()});
            let arr = this.skuList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.skuInfo.split(' ').includes(val))
            });
            this.skuInfoId=''
            this.$forceUpdate()
        }
    }


    skuChange(item) {
        if (item) {
            this.servicePrice = item.costPrice
            this.skuInfoId = item.skuInfoId;
            this.skuInfo = item.skuInfo;
        }
        this.$forceUpdate()
        if (this.skuInfoId != '') {
            this.serviceSpecificationType = false;
        }

    }

    //选中一个
    skuChange1(item) {
        if (item) {
            this.skuInfoId = '';
        }
        this.$forceUpdate()
    }

    add() {
        this.amount += 1;
    }

    minus() {
        if (this.amount > 1) {
            this.amount -= 1;
        }
    }
    //立即下单
    goPersonalMyGoods() {
        const userInfo = localStorage.getItem("userInfo");
        if (this.skuList.length === 0) {
            this.skuInfoId = '0'
        }
        if (userInfo) {
            if (this.skuInfoId === '') {
                this.serviceSpecificationType = true;
                return;

            } else {
                this.serviceSpecificationType = false;
                // 在这里对服务进行判断，如果是保险类的需要创建假订单，然后直接跳转到他们的地址中去选择金额支付，完成之后再跳到我们自己的链接中
                this.$router.push({
                    path: "/serviceOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        skuInfoId: this.skuInfoId,
                        amount: this.amount.toString(),
                    },
                });
            }

        } else {
            if (this.skuInfoId === '') {
                this.serviceSpecificationType = true;
                return;

            } else {
                const {href} = this.$router.resolve({
                    path: "/serviceOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        skuInfoId: this.skuInfoId,
                        amount: this.amount.toString(),
                    },
                });
                sessionStorage.setItem("pay", href);
                this.$router.push("/login");
            }
        }
    }
    purchaseOrder(){
        const userInfo = localStorage.getItem("userInfo");
        if (this.skuList.length === 0) {
            this.skuInfoId = '0'
        }
        if (userInfo) {
            if (this.skuInfoId === '') {
                this.serviceSpecificationType = true;
                return;

            } else {
                this.serviceSpecificationType = false;
                // 在这里对服务进行判断，如果是保险类的需要创建假订单，然后直接跳转到他们的地址中去选择金额支付，完成之后再跳到我们自己的链接中
                this.$router.push({
                    path: "/purchaseOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        skuInfoId: this.skuInfoId,
                        amount: this.amount.toString(),
                    },
                });
            }

        } else {
            if (this.skuInfoId === '') {
                this.serviceSpecificationType = true;
                return;

            } else {
                const {href} = this.$router.resolve({
                    path: "/purchaseOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        skuInfoId: this.skuInfoId,
                        amount: this.amount.toString(),
                    },
                });
                sessionStorage.setItem("pay", href);
                this.$router.push("/login");
            }
        }
    }

    isCollectionsOrgService() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem("userInfo")) {
                resolve("");
                return;
            }
            collectionsOrgService({
                itemId: this.serviceInfoId,
                itemType: 237,
            }).then((res) => {
                if (res.data) {
                    this.collectionString = res.data.isCollected === 0 ? "收藏" : "已收藏";
                    resolve(res);
                }
            });
        });
    }

    fetchCollection(operation) {
        const params = {
            itemId: this.serviceInfoId,
            itemType: 237,
        };
        return new Promise((resolve, reject) => {
            fetchCollection({...params, operation: operation}).then((res) => {
                resolve(res);
            });
        });
    }

    async collection() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            let operation = 0;
            if (this.collectionString === "已收藏") {
                operation = 1;
            } else {
                operation = 0;
            }
            await this.fetchCollection(operation);
            await this.isCollectionsOrgService();
        } else {
            this.$router.push("/login");
        }
    }
}
































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Icon} from "ant-design-vue";
import serviceOrderDetail from "@/components/Personal/serviceOrderForm/serviceOrderDetail.vue";
import {baseUrl} from "../../../services/http";
import {Mutation} from "vuex-class";

Vue.use(Icon);
@Component({
    components: {
        serviceOrderDetail
    },
    props: {
        location: String,
        allcontent: Object
    }
})
export default class MyGoodsItem extends Vue {
    baseUrl = baseUrl;
    @Prop() private allcontent!: Object;
    @Prop() private orderId!: String;
    @Prop() public services!: any;
    @Prop() private isAllCheck!: Boolean;
    @Prop() private id!: Number;
    @Prop() private ischeck!: Boolean;
    cancelServiceList = {};
    @Mutation SET_CURDETAILSTATUS;

    //订单详情
    lookDetail(orderInfoId: any) {
        window.open("#/personal/OrderDetail");
        this.SET_CURDETAILSTATUS(orderInfoId);
    }


    //单选
    onChange(e, id, ischeck, orderId) {
        if (!ischeck) {
            this.$emit("getOrderInfoList", {
                id: id,
                ischeck: ischeck,
                orderId: orderId
            });
        } else {
            this.$emit("deleteOrderInfoList", {
                id: id,
                ischeck: ischeck,
                orderId: orderId
            });
        }
    }

    //和我联系
    toChat(query) {
        let url = '/#/chat?'
        for (let key in query) {
            url += key + '=' + query[key] + '&'
        }
        url.substr(0, url.length - 1)
        open(url)
    }

    //单个删除
    deleteEvent(value) {
        this.$emit("deleteEvent", value);
    }

    //单个还原
    revivification(value) {
        this.$emit("revivification", value);
    }

}






























































































































































































































import {Component, Vue} from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import castAbout from "../../creditInvestigation/components/castAbout.vue";
import homeScore from "../../creditInvestigation/particulars/home-score.vue";
import {getServiceDetail, getServiceProviderDetailInfo} from "@/services/api";
import {message} from "ant-design-vue";


@Component({
  components: {
    castAbout, homeScore, Footer
  },
})
export default class extends Vue {
  consumptionDetailData: any = []; // 商品消费明细列表
  value = 3;
  previewVisible=false;
  columns1 = [
    {
      title: "服务订单号",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "服务详情",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "评价内容",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "评价人",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "服务评分",
      dataIndex: "",
      align: "center"
    },
    {
      title: "服务商评分",
      dataIndex: "",
      align: "center"
    }
  ];
  columns = [
    {
      title: "服务详情",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "服务价格",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "服务方式",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "销售笔数",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "服务评分",
      dataIndex: "",
      align: "center"
    }
  ];
  previewImage: "";
  getSuccess: boolean; // 成功获取
  serviceProviderDetailInfo = {// 详情数据
    agencyId: "",
    agencyImage: "",
    agencyName: "",
    briefIntro: "",
    businessLicensePicture: "",
    closeTime: "",
    companyAddress: "",
    companyContactNumber: "",
    companyName: "",
    contactIdNum: "",
    contactName: "",
    contactTel: "",
    highestPrice: 0,
    joinDate: "",
    lowestPrice: 0,
    lpIdPictureNegative: "",
    lpIdPicturePositive: "",
    lpName: "",
    onStatus: 0,
    openTime: "",
    orgCharacter: "",
    orgCode: "",
    orgForm: "",
    orgOveralScore: 0,
    orgTypes: null,
    organizationAttitude: 0,
    organizationSpeed: 0,
    serviceAddress: "",
    serviceCompliance: 0,
    serviceIttitude: 0,
    serviceOrderComment: null,
    serviceSaleInfo: null,
    serviceTel: "",
    totalScore: 5,
  };

  mounted() {
    const that = this
    let agencyId = that.$route.query['agencyId'];
    that.getSuccess = false
    if (agencyId) {
      getServiceProviderDetailInfo(agencyId).then(res => {
        if (res && res.code == 0) {
          that.serviceProviderDetailInfo = res.info
          that.getSuccess = true;
        }
      })
    }
  }
  handlePicturePreview(val){
    this.previewVisible=true
    this.previewImage=val

  }
  handleCancelOK() {
    this.previewVisible = false;
  }

  consumptionDetail() {

  }

}

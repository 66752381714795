











































































import {Component, Vue} from "vue-property-decorator";
import {http} from "@/services/http";

@Component
    export default class Nav extends Vue {
        menuLevel1: Array<any> = [];
        menuLevel2: Array<any> = [];
        menuLevel3: Array<any> = [];
        bannerHeight = "4.75rem";
        YLMSShowBool: any = false;
        hiStoreOrNot: any = true;
        SearchOurStore: any = true;
        selectedDefault: any = true;

        resetHeight(n) {
            n = n * 0.36;
            if (n + 0.03 > 4.75) {
                this.bannerHeight = n + 0.03 + "rem";
                this.$store.commit("setHomeBannerHeight", this.bannerHeight);
            }
        }

        created() {
          const that = this
            if (that.$route.name == "shop" || that.$route.name=='店铺详情') {
              that.hiStoreOrNot = false;
              that.SearchOurStore = false;
            }
          that.$router.afterEach((to: any, from: any) => {
            that.getshowLevel();
            });

            http.get("/api/v1/commodity-types/list").then((res: any) => {
                if (res["code"] == 0) {
                    let data: Array<any> = res["data"];

                  that.menuLevel1 = data.filter((item) => item.columnDepth == 0);
                  that.menuLevel3 = data.filter((item) => item.columnDepth == 2);
                  that.menuLevel2 = data.filter((item) => item.columnDepth == 1);
                  that.resetHeight(that.menuLevel2.length);
                  that.menuLevel2.sort((a, b) => a.orderNumber - b.orderNumber);
                  that.menuLevel2.map((item) => {
                        item.child = that.menuLevel3.filter(
                            (val) => val.parentId == item.typeId
                        );

                        item.child.sort((a, b) => a.orderNumber - b.orderNumber);
                    });
                  that.menuLevel2.forEach((element) => {
                        if (element.child.every((item) => item.typeName.length <= 5)) {
                            element.num = 6;
                        } else if (element.child.every((item) => item.typeName.length <= 7)) {
                            element.num = 5;
                        } else {
                            element.num = 4;
                        }
                    });
                  that.getRandomMenulevel3();
                  that.saveMenu();
                }
            });
          that.getCartNumber();
        }

        searchThisShop() {
            const {href} = this.$router.resolve(
                `/mall/shop/${this.$route.params.id}?keywords=${encodeURI(
                    this.searchKeywords || this.placeHolder
                )}`
            );
            open(href, "_self");
        }

        toHome() {
            const {href} = this.$router.resolve("/home");
            open(href, "_self");
        }

        saveMenu() {
            localStorage.setItem("menuLevel2", JSON.stringify(this.menuLevel2));
        }

        ifHome = true;
        searchKeywords = "";


        RandomMenulevel3: any[] = [];

        getRandomMenulevel3() {
            const lng = 6;
            for (let i = 0; i < lng; i++) {
                let ram = Math.floor(Math.random() * this.menuLevel3.length);
                this.RandomMenulevel3.push(this.menuLevel3[ram]);
            }
            this.placeHolder = this.menuLevel3[
                Math.floor(Math.random() * this.menuLevel3.length)
                ].typeName;
            this.placeHolderTimer = setInterval(() => {
                this.placeHolder = this.menuLevel3[
                    Math.floor(Math.random() * this.menuLevel3.length)
                    ].typeName;
            }, 5000);
        }

        placeHolder = "";
        placeHolderTimer: any;

        beforeDestroy() {
            clearInterval(this.placeHolderTimer);
        }

        //头部搜索
        jumpRuter(keywords?: string) {
            keywords = keywords || this.searchKeywords;
            open(`/#/mall/search?keywords=${encodeURI(keywords)}`, "_self");
        }

        jumpS(type: string, id: string, path: string, genre: string) {
            localStorage.setItem("menupath", path);
            open(`/#/mall/search?class=${type}&id=${id}&genre=${genre}`);
        }

        jumptoHomeAll() {
            window.open("/#/mall/home",'_self');
            location.reload();
        }

        jumptoHome() {
            this.selectedDefault = true;
            const {href} = this.$router.resolve("/home");
            window.location.href = href;
        }

        jumpCart() {
            this.$router.push("/mall/cart");
        }

        getCartNumber() {
            if (localStorage.getItem("userInfo"))
                http.get(`/api/v1/cart/count`).then(({code, data}: any) => {
                    if (code == 0) this.$store.commit("setProducNumInCart", data.allCount);
                    else this.$store.commit("setProducNumInCart", 0);
                });
        }

        showLevel = true;

        getshowLevel() {
            this.showLevel = !location.href.includes("mall/shop");
        }
    }









































































































import {Component, Vue} from "vue-property-decorator";
import {servicePay} from "@/services/api";
import {baseUrl, http, request} from "@/services/http";

@Component
export default class Checkstand extends Vue {
    visible = false;
    value = 1;
    orderInfo: any = {};
    phoneNumber = "";
    receiverName = "";
    address = "";
    searchKeywords = "";
    openTime = "";
    mainCommodityOrderCode = "";
    resultOrderInfos = [];
    clickPay = false;
    kPayState = "稍后付款";
    paysuccess = false;
    data:any = [];
    columns = [
        {
            title: "商品名称",
            dataIndex: "commodityName",
            key: 1,
            align: "center",
        },
        {
            title: "店铺名称",
            dataIndex: "merchantName",
            key: 2,
            align: "center",
        },
        {
            title: "数量",
            dataIndex: "serviceNum",
            key: 3,
            align: "center",
        },
        {
            title: "商品金额",
            dataIndex: "commodityPrice",
            key: 4,
            align: "center",
        },
    ];
    showOrderDetail = false;
    setIntervalUU:any = null;
    payTotal = 0;
    commodityType = false;

    getinfoBy() {
        return http.post(
            `/api/v1/commodities/settlement/${this.orderInfo.mainCommodityOrderCode}`,
            {}
        );
    }

    async created() {
        this.orderInfo = JSON.parse(this.$route.query.orderInfo as any);
        const {data}: any = await this.getinfoBy();

        const {commodityAddressInfo, mainCommodityOrderCode} = this.orderInfo;
        //电话加密
        this.phoneNumber = commodityAddressInfo.phoneNumber;
        // this.phoneNumber  = commodityAddressInfo.phoneNumber.replace(/^(\d{3})\d{4}(\d+)/, "$1****$2");
        //收货人加密
        this.receiverName = commodityAddressInfo.receiverName
        // this.receiverName =  this.formatName(commodityAddressInfo.receiverName)
        this.address =
            (commodityAddressInfo.province || "") +
            (commodityAddressInfo.city || "") +
            (commodityAddressInfo.area || "") +
            (commodityAddressInfo.street || "") +
            (commodityAddressInfo.addressDetail || "");

        // if (this.orderInfo.mainCommodityType === "我的商品") {
        //   this.orderInfo.resultOrderInfos.forEach((ele) => {
        //     this.data.push({
        //       commodityName: ele.commodityName,
        //       commodityPrice: ele.commodityPrice.toFixed(2),
        //       merchantName: ele.merchantName,
        //       serviceNum: ele.commodityNum,
        //     });
        //   });
        // } else {
        data.commodity.forEach((ele) => {
            this.data.push({
                commodityName: ele.commodityName,
                commodityPrice: ele.commodityPrice.toFixed(2),
                merchantName: ele.merchantName,
                serviceNum: ele.commodityNum,
            });
        });
        // }
        this.payTotal = data.totalPayAmount;
        this.mainCommodityOrderCode = mainCommodityOrderCode;
    }

    //姓名加密
    formatName(name) {
        let newStr;
        if (name.length === 2) {
            newStr = name.substr(0, 1) + "*"; // 通过substr截取字符串从第0位开始截取,截取1个
        } else if (name.length > 2) {
            // 当名字大于2位时
            let char = "";
            for (let i = 0, len = name.length - 2; i < len; i++) {
                // 循环遍历字符串
                char += "*";
            }
            newStr = name.substr(0, 1) + char + name.substr(-1, 1);
        } else {
            newStr = name;
        }
        return newStr;
    };

    beforeDestroy() {
        if (this.setIntervalUU) clearInterval(this.setIntervalUU);
    }

    // // 获取是否商品订单
    // commodityId(value){
    //
    // }

    // 点击服务订单详情
    handleClickUnfoldDetail() {
        const table:any = document.querySelector(".ant-table-tbody");
        table["style"].display = "";
    }
    //头部搜索
    jumpRuter(keywords?: string) {
        keywords = keywords || this.searchKeywords;
        open(`/#/mall/search?keywords=${encodeURI(keywords)}`, "_self");
    }
    laterPay() {
        // if (this.setIntervalUU) clearInterval(this.setIntervalUU);
        // let path = localStorage.getItem("laterPath");
        // this.$router.push(path);
        this.$router.push("/home");
    }

    // 点击收起订单详情
    handleClickRetractDetail() {
        const table:any = document.querySelector(".ant-table-tbody");
        table["style"].display = "none";
    }

    showModal() {
        let params = {
            mainServiceOrderCode: this.mainCommodityOrderCode,
            purchaseMethod: this.value,
            payTotal: this.payTotal,
            ifCommodityOrder: true,

        };

        servicePay(params).then((res) => {
            let win:any = window.open("", "_self");
            const div = document.createElement("div"); // 创建div
            div.innerHTML = res; // 将返回的form 放入div
            win.document.body.appendChild(div);
            win.document.forms[0].submit();
            this.checkPayState();
        }).catch((error) => {
                // this.visible = true;
            });
    }

    checkPayState() {
        if (this.setIntervalUU) return;
        this.setIntervalUU = setInterval(() => {
            request(`${baseUrl}/api/v1/services/pay/status`, {
                params: {
                    mainServiceOrderCode: this.mainCommodityOrderCode,
                    ifCommodityOrder: true,
                },
            }).then((res:any) => {
                if (res["data"].payStatus && !this.paysuccess) {
                    this.paysuccess = true;
                    clearInterval(this.setIntervalUU);
                    this.setIntervalUU = undefined;
                    window.close();
                    // this.handleOk();
                }
            });
        }, 1000);
    }

    payOk() {
        request(`${baseUrl}/api/v1/services/pay/status`, {
            params: {
                mainServiceOrderCode: this.mainCommodityOrderCode,
                ifCommodityOrder: true,
            },
        }).then((res:any) => {
            if (res["data"].payStatus) {
                if (this.setIntervalUU) clearInterval(this.setIntervalUU);
                this.handleOk();
            } else {
                this.showModal();
            }
        });
    }

    handleOk() {
        this.visible = false;
        this.$router.push({
            path: "/mall/serviceOrderPaySuccess",
            query: {
                mainServiceOrderCode: this.orderInfo.mainCommodityOrderCode,
                payTotal: this.payTotal + "",
                serviceOrderId: this.orderInfo.serviceOrderInfo.serviceOrderId,
            },
        });
    }

    handleOkLater() {
        if (this.setIntervalUU) clearInterval(this.setIntervalUU);
        this.laterPay();
    }

    jumpToHome() {
        this.$router.push("/");
    }
}












































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {http} from "@/services/http";
import {State} from "vuex-class";
import newAddress from "@/components/mallComponents/common/newAddress.vue";
import newAddressTwo from "@/components/mallComponents/common/newAddressTwo.vue";
import ReturnStep from "@/components/mallComponents/orderInfo/ReturnStep.vue";
import closeReturnStep from "@/components/mallComponents/orderInfo/closeReturnStep.vue";
import settlementList from "@/components/mallComponents/orderInfo/settlementList.vue";
import {message} from "ant-design-vue";
import {saveTheInvoice} from "@/services/api";
import addressLocationAdd from "@/components/Personal/addressLocationAdd.vue";
import addressLocationItem from "@/components/addressLocationItem.vue";
@Component({
    components: {
        ReturnStep,
        closeReturnStep,
        settlementList,
        addressLocationAdd,
        addressLocationItem
    },
})
export default class OrderInfo extends Vue {
    editDataTwo: any = {
    phoneNumber: "",
    receiverDetailAddress: "",
    receiverName: "",
    receiverStreet: null,
    receiverArea: null,
    receiverCity: null,
    receiverProvince: null,
    isDefault: "",
    addressId: "",
    provinceValue: [],
    };
    loading:any=true
    visible = false;
    visibleTwo = false;
    showOrderDetail = false;

    tokenInformation = false;
    editType = 0;
    editData = {};


  addressSuccess() {
        this.visible = false;
        this.getAddressList();
    }
    addSuccessTwo() {
        this.getAddressList();
        this.visibleTwo = false;

    }
    currAddress = {
        receiverProvince: "",
        receiverCity: "",
        receiverArea: "",
        receiverStreet: "",
        receiverDetailAddress: "",
        receiverName: "",
        phoneNumber: "",
    };
    addressId = "";
    searchKeywords = "";
    serviceAddressList = [];
    haveInvoice: any = 0;
    amount = 0;
    maskClosable= false;

    invoiceTitleType = 0;
    serviceRemark = "";
    invoiceContent = 0;
    invoiceTitleChangeBool = false;
    payType = 285;
    isChecked = 0;
    useDaibiBool = false;
    fapiao = false;
    availableDaibi = 0;
    choiceList: any = [];
    receiverEmail: any = "";
    personage: any = "";
    taxpayerId: any = "";
    makeOutCheque: any = false;
    invoiceType: any = 1;
    invoiceId: any = '';
    cartPayData:any={};
    radioStyle = {
        display: "block",
        height: "0.3rem",
        lineHeight: "0.3rem",
        color: "#737373",
    };

    // @click="showAddressList = serviceAddressList.slice(0, 3)"

    showAddressList = [];

    getAddressList(defaultA = 0) {
        http.get("/api/v1/addresses/list").then((res: any) => {
            if (res["code"] === 0) {
                this.serviceAddressList = res.data;
                this.showAddressList = this.serviceAddressList.slice(0, 3);
                this.addressId = this.cartPayData.addressId;
                if (this.serviceAddressList.length){
                    this.currAddress = this.showAddressList[defaultA];
                }
                if(this.showAddressList.length===0){
                    this.visibleTwo=true
                }
            }
        });

    }
    //头部搜索
    jumpRuter(keywords?: string) {
        keywords = keywords || this.searchKeywords;
        open(`/#/mall/search?keywords=${encodeURI(keywords)}`, "_self");
    }

    //发票类型
    InvoiceType(value) {
        this.invoiceType = value
    }

    //发票信息
    invoiceContentClick(value) {
        this.invoiceContent = value
    }

    //保存发票
    saveInvoice() {
        let invoiceInfo: any = {
            invoiceType: this.invoiceType === 0 ? false : true,
            invoiceContent: this.invoiceContent === 0 ? false : true,
            titleType: this.invoiceTitleType === 0 ? false : true,
            // taxpayerId: this.taxpayerId,
            invoiceTitle: this.invoiceTitleType === 0 ? this.personage : this.receiverEmail,
        };
        if (this.invoiceTitleType === 1) {
            (invoiceInfo["taxpayerId"] = this.taxpayerId)
        }
        saveTheInvoice(invoiceInfo).then(res => {
            if (res && res.code == 0) {
                message.success('保存成功');
                this.makeOutCheque = false;
                this.invoiceId = res.invoiceId
            }
        })
    };

    //获取收货信息变化
    oneChange(e) {
        this.choiceList = e
        this.currAddress = e
    }

    //收起收货信息,并选中
    packUp() {
        if (this.choiceList.length === 0) {
            this.showAddressList = this.serviceAddressList.slice(0, 3)
        } else {
            this.serviceAddressList.forEach((item:any, index) => {
                if (this.choiceList.addressId === item.addressId) {
                    var str = this.serviceAddressList.splice(index, 1);
                    this.serviceAddressList.unshift(str[0]);
                    this.showAddressList = this.serviceAddressList.slice(0, 3)
                }
            })

        }
    }

    currAddressChange(e) {
        this.currAddress = e.target.value;
    }

    payTypeChange(key) {
        this.payType = key;
    }

    showTable = true;

    onChange(e, item) {
        this.fapiao = !this.fapiao;
        this.haveInvoice = this.fapiao ? 1 : 0;
        if (this.fapiao == true) {
            this.makeOutCheque = true
        }
    }

    addAddressNew(event) {
      event.preventDefault();
      this.receiverName = "";
      this.phoneNumber = "";
      this.receiverDetailAddress = "";
      this.zipCode = "";
      this.editTypeTwo = 0;
      this.editDataTwo = {
        receiverName: "",
        phoneNumber: "",
        receiverDetailAddress: "",
        zipCode: "",
        provinceValue: [],
        isDefaut: false,
        receiverStreet: 0,
        receiverArea: 0,
        receiverCity: 0,
        receiverProvince: 0,
        userId: JSON.parse(localStorage.getItem("userInfo") as string)[
            "userInfoId"
            ],
      };
      this.visible = true;
    }
    editTypeTwo = 0; // 新增或编辑 收货地址  0  新增 1  编辑

    provinceDefalutValue: any = []; // 级联选项默认值
    receiverName: any = "";
    receiverDetailAddress: any = "";
    zipCode: any = "";
    phoneNumber: any = "";
    addressList: any = []; // 服务地址信息列表
    radioChange(e, item) {
        this.invoiceTitleType = e.target.value;
    }

    invoiceContentChange(e) {
        this.invoiceContent = e.target.value;
    }

    checkBoxChange(e) {
        this.isChecked = e.target.checked ? 1 : 0;
    }


    totalAmount = 0;
    stepList = [
        {
            name: "选购商品",
        },
        {
            name: "填写商品订单",
        },
        {
            name: "完成商品订单",
        },
    ];

    mounted() {
        let invoiceInfo = {
            invoiceType: 1,
            invoiceContent: 0,
            invoiceTitleType: 0,
            invoiceTitle: "",
            taxpayerId: "",
            receiverEmail: "",
            invoiceTitleChangeBool: false,
        };
        this.cartPayData =  JSON.parse(this.$route.query.dataList as any)
        this.stepList[0].name = !this.cartPayData.cart ? "选购商品" : "我的购物车";
        this.cartPayData.data.forEach((element) => {
            element.invoiceInfo = invoiceInfo;
            element.commodityList = element.commoditiesInCarts;
            element.haveInvoice = 0;
            element.orderRemark = "";
            element.shopDiscountPrice = 0;
            element.orderFreight = 0;
            element.commodityOrderTotal = 0;
            element.orderFreight = 0;
            element.commoditiesInCarts.forEach((val) => {
                this.amount += val.quantity
                element.commodityOrderTotal += val.quantity * val.price;
                element.orderFreight =
                    val.freight >= element.orderFreight ? val.freight : element.orderFreight;
                val.commodityPrice = val.price;
                val.ptActivityDiscount = 0;
                val.siId = val.skuId;
                // val.sku;
            });
            element.commodityOrderTotal += element.orderFreight;

            this.totalAmount += element.commodityOrderTotal;
            this.totalFreight += element.orderFreight;
        });

        this.getAddressList();


    }

    totalFreight = 0;

    serviceOrderPay() {
        const req = {
            addressId: this.currAddress["addressId"],
            totalCost: Number(this.totalAmount).toFixed(2),
            paymentMethod: this.payType,
            useToken: this.useDaibiBool ? 1 : 0,
            tokenAmount: this.availableDaibi,
            discountCoupon: 0,
            payTotal: this.useDaibiBool ? Number(this.totalAmount - this.availableDaibi).toFixed(2) :Number(this.totalAmount).toFixed(2) ,
            couponList: [],
            commodityOrderList: this.cartPayData.data,
            invoiceInfoId :this.invoiceId
        };
        if(req.addressId!=undefined){
            http.post("/api/v1/commodities/settlement", req).then(({code, data, msg, free}: any) => {
                if (free) {
                    this.$router.push({
                        path: "/mall/serviceOrderPaySuccess",
                        query: {
                            mainServiceOrderCode: data.mainCommodityOrderCode,
                            payTotal: data.payTotal,
                        },
                    });
                    return;
                }
                if (code == 0) {
                    this.$router.push({
                        path: "/mall/checkstand",
                        query: {orderInfo: JSON.stringify(data)},
                    });
                } else {
                    message.error(msg);
                }
            }).catch((error) => {
                message.error(error.msg);
            });
        }else{
            message.error('收货地址不能为空!');
            this.visible = true;
        }


    }
}

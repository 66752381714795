




























































































































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Icon, Tag} from "ant-design-vue";
    import Ylorganization from "@/components/Ylorganization.vue";
    import InstitutionInfo from "@/components/InstitutionInfo.vue";
    import noResult from "./noResult.vue";
    import {serviceItems, serviceProvider} from "@/components/Recommended";
    import {fetchArea} from "@/services/api";

    Vue.use(Icon);
Vue.use(Tag);

@Component({
  props: {
    onSearch: Function,
    list: Array,
    pagination: Object,
    searchContent: String,
  },
  components: {
    Ylorganization,
    noResult,
    InstitutionInfo,
    serviceItems,
    serviceProvider,
  },
})
export default class extends Vue {
  pullDown = 0;
  pullDownTwo = 0;
  pullDown2 = 0;
  pullDown1 = 0;
  screenWidth = 0;
  tags: any[] = [];
  // 养老模式
  /**
   * null代表不限，206-机构养老；207-居家养老；208-社区养老；209-旅居养老；210-医养结合；216-其它
   */

  projectSelect = [
    {
      label: "不限",
      value: 0,
    },
    {
      label: "机构养老",
      value: 206,
    },
    {
      label: "居家养老",
      value: 207,
    },
    {
      label: "社区养老",
      value: 208,
    },
    {
      label: "旅居养老",
      value: 209,
    },
    {
      label: "医养结合",
      value: 210,
    },
  ];
  typeSelect: any = [];
  xzqy = [];
  xzqy2 = [];
  typeSelect2: any = [];
  /**
   * null代表不限，166：50以内；167：50-100；
   * 68：100-200；169：200-300，170：300-500，171：500以上
   */
  bedAmount = [
    {
      label: "不限",
      value: 0,
      orgBedNumLowest: 0,
      orgBedNumHeight: null,
    },
    {
      label: "50以内",
      value: 166,
      orgBedNumLowest: 0,
      orgBedNumHeight: 50,
    },
    {
      label: "50-100",
      value: 167,
      orgBedNumLowest: 50,
      orgBedNumHeight: 100,
    },
    {
      label: "100-200",
      value: 168,
      orgBedNumLowest: 100,
      orgBedNumHeight: 200,
    },
    {
      label: "200-300",
      value: 169,
      orgBedNumLowest: 200,
      orgBedNumHeight: 300,
    },
    {
      label: "300-500",
      value: 170,
      orgBedNumLowest: 300,
      orgBedNumHeight: 500,
    },
    {
      label: "500以上",
      value: 171,
      orgBedNumLowest: 500,
      orgBedNumHeight: null,
    },
  ];
  // 机构性质
  /**
   * null代表不限，172-国营机构；173-民营机构
   * 174-社会团体；175-公办民营；176-公助民办；177-其它
   */

  jgxz = [
    {
      label: "不限",
      value: 0,
    },
    {
      label: "国营机构",
      value: 172,
    },
    {
      label: "公办民营",
      value: 175,
    },
    {
      label: "民营机构",
      value: 173,
    },
    {
      label: "社会团体",
      value: 174,
    },
    {
      label: "公助民办",
      value: 176,
    },
    {
      label: "其他",
      value: 177,
    },
  ];
  // 收费标准
  /**
   * null代表不限，178：500以下；179：500-1000；180：1000-2000；
   * 181：2000-3000；182：3000-5000；183：5000以上
   */

  sfbz = [
    {
      label: "不限",
      value: 0,
      feeLowest: 0,
      feeHeight: null,
    },
    {
      label: "500以下",
      value: 178,
      feeLowest: 0,
      feeHeight: 500,
    },
    {
      label: "500-1000",
      value: 179,
      feeLowest: 500,
      feeHeight: 1000,
    },
    {
      label: "1000-2000",
      value: 180,
      feeLowest: 1000,
      feeHeight: 2000,
    },
    {
      label: "2000-3000",
      value: 181,
      feeLowest: 2000,
      feeHeight: 3000,
    },
    {
      label: "3000-5000",
      value: 182,
      feeLowest: 3000,
      feeHeight: 5000,
    },
    {
      label: "5000以上",
      value: 183,
      feeLowest: 5000,
      feeHeight: null,
    },
  ];
  // 收住对象
  /**
   * null代表不限，184-自理；185-半自理/介助；186-不能自理/介护；187-特护
   */
  szdx = [
    {
      label: "不限",
      value: 0,
    },
    {
      label: "自理",
      value: 184,
    },
    {
      label: "半自助/介助",
      value: 185,
    },
    {
      label: "不能自理/介护",
      value: 186,
    },
    {
      label: "特护",
      value: 187,
    },
  ];
  // 特色服务
  /**
   * 188-支持医保；189-异地收住；190-可候鸟旅居；191-可试住体验；360-连锁品牌
   */
  tsfw = [
    {
      label: "不限",
      value: 0,
    },
    {
      label: "支持医保",
      value: 188,
    },
    {
      label: "异地收住",
      value: 189,
    },
    {
      label: "可候鸟旅居",
      value: 190,
    },
    {
      label: "可试住体验",
      value: 191,
    },
    {
      label: "连锁品牌",
      value: 360,
    },
  ];
  xzqyChecked = 0;
  ylmsChecked = 0;
  orgTypeChecked = 0;
  bedAmountChecked = 0;
  jgxzChecked = 0;
  sfbzChecked = 0;
  szdxChecked = 0;
  tsfwChecked = 0;
  // 这个没用
  checked = true;
  cityName = "";
  // 判断当前选择的是否是全国？
  selectNationwide: boolean = false;

  /**
   * 获取当前地区名称
   */
  get _currCityName() {
    const that = this
    const allList = JSON.parse(sessionStorage.getItem("allAreaData") as any);
    let cityId: string | null = localStorage.getItem("provinceId");

    let selectId, cityName = ''
    // 如果当前选择了其他地址，则替换掉这个默认所在的地址
    if (that.xzqyChecked) {
      selectId = that.xzqyChecked.toString()
    }

    if (that.selectNationwide) {
      // 当前所在地是全国，判断是否有筛选 “选择区域”
      if (selectId) {
        // 筛选了地址，则找到是哪个省，显示省地址的简短名称
        for (const allListKey in allList) {
          const item = allList[allListKey]
          if (item.provinceId == parseInt(selectId || '0')) {
            cityName = item.shortName;
            break;
          }
        }
      } else {
        // 选择的是不限，则显示  全国
        cityName = "全国";
      }
    } else {
      // 当前所在地是某个市
      if (selectId) {
        // 选择了具体的某个区县，则显示区县的名称
        let find = that.xzqy2.find((item) => item["id"] == selectId);
        if (find) {
          cityName = find["name"];
        }
      } else {
        // 选择的是不限，则显示  当前市的名称
        let setCity: any = JSON.parse(localStorage.getItem("setCity") as any);
        if (setCity) cityName = setCity["cityName"];
      }
    }

    return cityName
  }
  getCityName() {
    const allList = JSON.parse(sessionStorage.getItem("allAreaData") as any);
    const cityId = localStorage.getItem("provinceId");

    let obj = {};
    allList.map((item) => {
      const m = item.cityList.find((item) => item.cityId == cityId);

      m ? (this.cityName = m.shortName) : "";
    });
    if (cityId == "0") {
      this.cityName = "全国";
    }
    // fetchCity
  }
  allDAta(evt) {
    evt.preventDefault();
    let index = 0;
    this.xzqyChecked = 0;
    this.ylmsChecked = 0;
    this.orgTypeChecked = index;
    this.bedAmountChecked = index;
    this.jgxzChecked = index;
    this.sfbzChecked = index;
    this.szdxChecked = index;
    this.tsfwChecked = index;
    const keys = [
      "specialService",
      "serviceObject",
      "areaId",
      "orgCharacter",
      "orgBedNum",
      "orgType",
      "orgForm",
    ];
    this.$props.onSearch(keys);
  }

  resetCHecks() {
    this.xzqyChecked = 0;
    this.ylmsChecked = 0;
    this.orgTypeChecked = 0;
    this.bedAmountChecked = 0;
    this.jgxzChecked = 0;
    this.sfbzChecked = 0;
    this.szdxChecked = 0;
    this.tsfwChecked = 0;
  }

  mounted() {
    this.getCityName();
    //获取区域宽度
    this.screenWidth = (this.$refs.barparent as any).clientWidth;
    const that = this
    window.onresize = () => {
      return (() => {
        (window as any).screenWidth = (this.$refs.barparent as any).clientWidth
        that.screenWidth = (window as any).screenWidth
      })()
    }
    this.typeSelect = [
      {
        label: "不限",
        value: 0,
      },
      {
        label: "敬老院",
        value: 157,
      },
      {
        label: "老年公寓",
        value: 158,
      },
      {
        label: "护理院",
        value: 159,
      },
      {
        label: "疗养院",
        value: 160,
      },
      {
        label: "养老照料中心",
        value: 161,
      },
      {
        label: "福利院",
        value: 163,
      },
      {
        label: "养老社区/CCRC",
        value: 162,
      },
      {
        label: "养老院",
        value: 164,
      },
      {
        label: "其他",
        value: 165,
      },
    ];
  }

  //监听区域宽度变化
  @Watch("screenWidth")
  getScreenWidth(val) {
    this.district(val)
  }

  //获取选择的区域
  district(val) {
    const that = this
    const cityId: any = localStorage.getItem("provinceId");
    if (cityId === '0') {
      // 选择全国，加载省
      that.selectNationwide = true
      fetchArea({
        areaId: cityId,
        areaType: 0,
      }).then(res => {
        let data = (res as any).data;
        data.list.unshift({name: '不限', id: 0});
        that.xzqy = data.list.slice(0, 10);
        that.pullDown = 9
        that.pullDownTwo = 10

        that.xzqy2 = data.list;

      });
    } else {
      that.selectNationwide = false
      fetchArea({
        areaId: cityId,
        areaType: 2,
      }).then(res => {
        let data = (res as any).data;
        data.list.unshift({name: '不限', id: 0});
        // this.xzqy = data.list.slice(0, 15);
        that.xzqy = data.list.slice(0, 10);
        that.pullDown = 9
        that.pullDownTwo = 10
        that.xzqy2 = data.list;
      });
    }
  }

  onchange(page: any, pageSize: any) {
    window.scrollTo({top: 0});
    this.$emit("changePage", page);
  }

  addFilter(tag: any) {
    this.tags.push(tag);
    const temp = this.projectSelect.filter((item) => item !== tag);
    this.projectSelect = temp;
  }

  cancelOrgType(num: number) {
    this.orgTypeChecked = num;
  }

  /**
   * 选择区域
   * @param checked
   * @param index
   */
  handleXzqyCheckedChange(checked: boolean, index: number) {
    this.xzqyChecked = index;
    this.$props.onSearch(this.selectNationwide ? "provinceId" : "areaId", index);
  }

  /**
   * 养老模式
   * @param checked
   * @param index
   */
  handleYlmsCheckedChange(checked: boolean, index: number) {
    this.ylmsChecked = index;
    this.$props.onSearch("orgForm", index);
  }

  /**
   * 类别
   * @param checked
   * @param index
   */
  handleOrgTypeCheckedChange(checked: boolean, index: number) {
    this.orgTypeChecked = index;
    this.$props.onSearch("orgType", index);
  }

  /**
   * 床位数
   * @param checked
   * @param item
   */
  handleBedAmountCheckedChange(checked: boolean, item: Object) {
    this.bedAmountChecked = item["value"];
    this.$props.onSearch(["orgBedNumLowest", "orgBedNumHeight"], [item["orgBedNumLowest"], item["orgBedNumHeight"]]);
  }

  /**
   * 机构性质
   * @param checked
   * @param index
   */
  handleJgxzCheckedChange(checked: boolean, index: number) {
    this.jgxzChecked = index;
    this.$props.onSearch("orgCharacter", index);
  }

  /**
   * 收费标准
   * @param checked
   * @param item
   */
  handleSfbzCheckedChange(checked: boolean, item: Object) {
    this.sfbzChecked = item['value'];
    this.$props.onSearch(["feeLowest", "feeHeight"], [item["feeLowest"], item["feeHeight"]]);
  }


  /**
   * 收住对象
   * @param checked
   * @param index
   */
  handleSzdxCheckedChange(checked: boolean, index: number) {
    this.szdxChecked = index;
    this.$props.onSearch("orgObject", index);
  }

  /**
   * 特色服务
   * @param checked
   * @param index
   */
  handleTsfwCheckedChange(checked: boolean, index: number) {
    this.tsfwChecked = index;
    this.$props.onSearch("specialService", index);
  }
}

<template>
    <div>
        <Header/>
        <div class="businessJoinStep2">
            <div class="stepTop">
                <img src="../../assets/images/homeImg/组 11@2x.png" height="240" width="3840"/>
                <div class="rz-type">商家入驻</div>
            </div>
            <div class="progressBar">
                <MyStepBar :stepData="stepData" :status="3"/>
            </div>
            <div class="form-box">
                <a-form :form="form">
                    <a-form-item v-bind="formItemLayout" label="基本信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="商家账号">
                        <a-input
                                aria-readonly="true"
                                placeholder="请输入商家账号"
                                v-decorator="[
                'merchantAccount',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkMerAccount.bind(this)
                    }
                  ],
                  initialValue: formData.shopInfo.merchantAccount
                }
              ]"
                        />
                        <div class="fileTipBox2020050611">
                            此账号为日后登录并管理商家中心时使用，注册后不可修改，请牢记。默认密码是123456
                        </div>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="店铺名称" style="margin-top: 0.33rem;">
                        <a-input
                                placeholder="请输入店铺名称"
                                v-decorator="[
                'storeName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkShopName.bind(this)
                    }
                  ],
                  initialValue: formData.shopInfo.storeName
                }
              ]"
                        />
                        <div class="fileTipBox2020050611">
                            店铺名称注册后不可修改，请认真填写。
                        </div>
                    </a-form-item>
                    <div class="imageUpload">
                        <a-form-item v-bind="formItemLayout" label="封面图片" style="margin-top: 0.33rem;height: 1.05rem;">
                            <a-upload
                                    name="file"
                                    :customRequest="uploadCoverAll"
                                    :remove="uploadCoverRemove"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="uploadCoverFileList"
                                    v-decorator="[
                'coverPicture',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.uploadCoverFileCheck.bind(this)
                    }
                  ]
                }
              ]"
                            >
                                <div v-if="uploadCoverFileList.length < 1">
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传封面</div>
                                </div>
                            </a-upload>
                        </a-form-item>
                    </div>
                    <a-form-item v-bind="formItemLayout" label="店铺简介" >
                        <a-textarea
                                style="height: 1.5rem"
                                placeholder="请输入店铺简介"
                                :rows="6"
                                v-decorator="[
                'merchantIntro',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '店铺简介不能为空'
                    },
                    {
                      validator: this.jianjieCheck.bind(this)
                    }
                  ],
                  initialValue: formData.expansionInfo.merchantIntro
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="店铺所在地">
                        <a-cascader
                                :options="options"
                                :loadData="loadData"
                                :placeholder=" '请选择店铺所在地'"
                                v-decorator="[
                'shopAddress',
                {
                  initialValue: formData.shopInfo.shopAddress,
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.companyAddrcheck.bind(this)
                    }
                  ]
                }
              ]"
                                @change="onChange"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="详细地址">
                        <a-input
                                placeholder="请输入商户详细地址"
                                v-decorator="[
                'detailAddress',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      message: '商户详细地址不能为空'
                    }
                  ],
                  initialValue: formData.shopInfo.detailAddress
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="店铺电话">
                        <a-input
                                placeholder="请输入店铺电话,如：010-12345678,13812345678":style
                                aria-readonly="true"
                                v-decorator="[
                'merchantTel',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      validator: this.phoneCheck.bind(this),
                      required: true
                    }
                  ],
                  initialValue: formData.shopInfo.merchantTel
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="备注">
                        <a-textarea
                                style="height: 1.5rem"
                                :rows="2"
                                v-decorator="[
                'merchantRemark',
                {
                  initialValue: formData.expansionInfo.merchantRemark
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="经营信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayoutTwo" label="经营类目">

                        <a-input
                                v-decorator="['serviceCategory', { rules:[{ required: true, validator: this.newDatecs.bind(this)}] }]"
                                style="display: none"></a-input>
                        <span style="color: #60c7c6; font-size: 0.14rem; cursor: pointer" @click="add"><a-icon type="plus-circle" style="margin-right: 5px"/>新增商品类目</span>
                        <el-table v-if="serviceCategoryList.length!=0" :data="serviceCategoryList" :span-method="objectSpanMethod" border class="table_top">
                            <el-table-column prop="categoryOneName" label="一级服务类目" align="center"></el-table-column>
                            <el-table-column prop="categoryTwoName" label="二级服务类目" align="center"></el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="remove" @click="delServiceInfo(scope.row)">删除</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="管理员信息" class="tip"></a-form-item>
                    <a-form-item v-bind="formItemLayout" label="管理员姓名">
                        <a-input
                                placeholder="请输入管理员姓名"
                                v-decorator="[
                'adminName',
                {
                  rules: [
                    {
                      required: true,
                      message: '管理员姓名不能为空'
                    }
                  ],
                  initialValue: formData.administratorInfo.adminName
                }
              ]"
                        />
                        <div class="fileTipBox2020050611">
                            请确保管理员信息准确，后续结算账户修改及支付密码等重要信息设置均需管理员验证
                        </div>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="管理员身份证号" style="margin-top: 0.33rem;">
                        <a-input
                                placeholder="请输入管理员身份证号"
                                v-decorator="[
                'admininfoCard',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.infoCardCheck.bind(this)
                    }
                  ],
                  initialValue: formData.administratorInfo.adminIDNum
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="管理员联系手机">
                        <a-input
                                placeholder="请输入管理员联系手机"
                                v-decorator="[
                'adminTel',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this)
                    }
                  ],
                  initialValue: formData.administratorInfo.adminTel
                }
              ]"
                        />
                        <div class="authCode">
                            <a-button v-if="!isSending" @click="handleSendCode">{{ isSendingFisrt ? "点击获取验证码" : "重新获取"}}</a-button>
                            <a-button v-else style="font-size:0.16rem">{{`${remainTime}s后重新获取`}}</a-button>
                        </div>
                    </a-form-item>
                    <a-form-item v-bind="formItemLayout" label="验证码">
                        <a-input
                                placeholder="请输入验证码"
                                v-decorator="[
                'yzm',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.yzmCheck.bind(this)
                    }
                  ]
                }
              ]"
                        />
                    </a-form-item>
                    <a-form-item v-bind="tailFormItemLayout" class="present">
                        <a-button class="button1" html-type="submit" @click="routerBack()"><img src="../../assets/images/homeImg/路径 2330@2x.png"/>返回上一步</a-button>
                        <a-button class="button2" v-if="buttonSet.enableClick" :loading="buttonSet.loading" type="primary" @click="handleSubmit"><img src="../../assets/images/homeImg/yes.png"/>提交审核</a-button>
                        <a-button class="button2" v-else :loading="buttonSet.loading" type="primary" disabled><img src="../../assets/images/homeImg/yes.png"/>提交审核</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <a-modal width="6.6rem" v-model="visibleConfirm" @ok="handleCancel" :afterClose="handleCancel">
            <template slot="title">
                <div class="modal-header">
                    <span>入驻信息</span>
                </div>
            </template>
            <Confirm/>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="handleCancel">
                        <span><img src="../../assets/images/homeImg/yes.png"/>确认</span>
                    </div>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="'30vw'">
            <div style="margin-top: 0.2rem;">
                <img alt="example" style="width: 100%;max-height: 5rem;margin-bottom: 0.3rem" :src="previewImage"/>
            </div>
        </a-modal>
        <a-modal :visible="serviceCategoryModel" @cancel="handleCancelTwo" width="6.3rem">
            <template slot="title">
                <div class="modal-header">
                    <span>新增经营类目</span>
                </div>
            </template>
            <div class="erviceCategoryStyle">
                <el-cascader-panel v-model="serviceData" :options="serviceTypes" :props="{ multiple: true}" clearable @change="value => cascaderChange(value)">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader-panel>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="erviceCategoryDate">
                        <span><img src="../../assets/images/homeImg/yes.png"/>确认</span>
                    </div>
                    <div class="cancel-button" @click="handleCancelTwo">
                        <span><img src="../../assets/images/homeImg/no.png"/>取消</span>
                    </div>
                </div>
            </template>
        </a-modal>
        <Footer/>
    </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import Confirm from "./BusinessJoinStep4";
    import {Button, Cascader, DatePicker, Form, Icon, Input, message, Select, Upload} from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import MyStepBar from "@/components/enterMyStepBar";
    import {
        checkMerAccountExist,
        checkMobileValidateCode,
        checkShopNameExist,
        dictionaries,
        dictionariesWithDesc,
        fetchAddrCascader,
        fetchMerCategories,
        fetchMerchantAdd,
        fetchProvinceName,
        getVerificationCode,
        uploadServiceFile
    } from "@/services/api";
    import {baseUrl} from "@/services/http";
    import moment from "moment";
    import "moment/locale/zh-cn";
    import ElementUI from 'element-ui';

    Vue.use(ElementUI);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Upload);
    Vue.use(Icon);
    Vue.use(DatePicker);
    Vue.use(Cascader);

    export default {
        components: {
            Nav,
            Footer,
            Header,
            ReturnStep,
            Confirm,
            MyStepBar
        },
        data() {
            return {
                list: [1],
                issubmit: false,
                isSendingFisrt: true,
                cheYZMBynet: false,
                isSending: false, // 是否发送
                selectCategory: [],
                uploadCoverFileList: [],
                ifImageCanUpload: true,
                showRepeat: false,
                moment,
                imageUrl: "",
                loading: false,
                options: [],
                serviceTypes: [],
                businessPathMap: [],
                industryInvolvedList: [], // 行业类型
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 10}
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 14}
                    }
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 24,
                            offset: 0
                        },
                        sm: {
                            span: 13,
                            offset: 10
                        }
                    }
                },
                stepData: [
                    {
                        name: "入驻协议",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-1.png'),
                    },
                    {
                        name: "主体信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-2.png'),
                    },
                    {
                        name: "店铺信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-3.png'),
                    },
                ],
                formData: {
                    merchantInfo: {
                        // 公司信息
                        merchantName: "", // 公司名称
                        merchantCode: "", // 公司组织机构代码
                        addressDetail: "", // 公司详细地址
                        merchantTel: "", // 公司电话
                        addressProvince: "", // 公司地址（省、直辖市、自治区等）
                        addressCity: "", // 公司地址（市）
                        addressArea: "", // 公司地址（区、县）
                        addressStreet: "" // 公司地址（街道、乡）
                    },
                    contactAccountInfo: {
                        // 联系人及账户信息
                        lpName: "", // 法人名称
                        lpIdNum: "", // 法人身份证编号
                        lpTel: "", // 法人联系方式
                        accountName: "", // 银行开户名
                        accountNumber: "", // 银行开户账号
                        bankName: "", // 开户银行名称
                        accountDetailAdd: "", // 开户详细地址
                        businessLicensePicture: "", // 营业执照照片
                        accountProvince: "",
                        accountCity: "",
                        accountArea: "",
                        accountStreet: "",
                        lpIdPicturePositive: "", // 法人身份证照片正面
                        lpPictureNegative: "", // 法人身份证照片反面
                        emergencyContactName: "", // 紧急联系人姓名
                        emergencyContactPhone: "", // 紧急联系人-联系方式
                        accountOpeningPermit:"",
                        operatingLicense:"",
                        productQualificationCertificate:"",
                    },
                    administratorInfo: {
                        // 管理员信息
                        adminName: "", // 管理员姓名
                        adminIDNum: "", // 管理员身份证编号
                        adminTel: "" // 管理员手机号
                    },
                    expansionInfo: {
                        // 扩展信息
                        coverPicture: "", // 店铺封面
                        createTime: "", // 店铺创建时间
                        registeredCapital: "", //注册资本
                        industryInvolved: "", // 所属行业
                        merchantIntro: "", // 公司简介
                        merchantRemark: "" // 备注
                    },
                    shopInfo: {
                        // 店铺信息
                        merchantAccount: JSON.parse(localStorage.getItem("userInfo"))["userName"], // 商家账号
                        storeName: "", // 店铺名称
                        addressProvince: "", // 店铺地址
                        addressCity: "", // 店铺地址
                        addressArea: "", // 店铺地址
                        addressStreet: "", // 店铺地址
                        detailAddress: "", // 店铺地址
                        shopAddress: [],
                        categoryList: [], // 经营类目
                        shopAddressPlaceholder: "选择店铺所在地",
                        merchantTel: "" // 店铺电话
                    },
                    paymentInfo: {
                        // 后端存储，前端不存储
                        alipayId: "",
                        wechatId: ""
                    },
                    uploads: {
                        // 上传图片由于某些数据的原因需要暂存
                        businessLicensePictureUrl: "", // 营业执照暂存
                        lpIdPicturePositiveUrl: "", // 身份证正面照片暂存
                        lpIdPictureNegativeUrl: "", // 身份证反面照片暂存
                        coverImage: "" // 封面照暂存
                    }
                },
                initialValue: {
                    serviceTypes: [] // 经营类目
                },
                previewVisible: false, // 预览框展示
                remainTime: 60, // 预览框展示
                previewImage: "",
                buttonSet: {
                    loading: false,
                    enableClick: false,
                },
                visibleConfirm: false,
                selectedData: [],
                serviceCategoryList: [],
                serviceData: [],
                serviceCategoryModel: false,
                formItemLayoutTwo: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 9},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 7},
                    },
                },
            };
        },

        beforeCreate() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.form = this.$form.createForm(this);
            } else {
                this.$router.push("/login");
            }
        },
        async mounted() {
            this.getResions();
            await this.init();
            let initRefr = {};
            dictionaries([8]).then(() => {
                // 获取经营类目
                fetchMerCategories().then(res => {
                    if (res && res.code == 0) {
                        this.serviceTypes = res.list.types;
                    } else {
                        message.error(res.msg);
                    }
                })
                    .then(_ => {
                        // 返回上一步的时候数据给他填回去
                        let item = localStorage.getItem("merchantJoinForm");
                        if (item == null) {
                            message.error("前置信息获取失败，请重新填写");
                            this.$router.back();
                            return;
                        }
                        if (item) {
                            let data = JSON.parse(item);
                            this.formData = data;
                            if (data.shopInfo.merchantAccount == "") {
                                this.formData.shopInfo.merchantAccount = JSON.parse(localStorage.getItem("userInfo"))["userName"]
                            }
                            this.testImages();

                            if (this.formData.shopInfo.addressProvince != '' && this.formData.shopInfo.addressCity != '' && this.formData.shopInfo.addressArea != '' && this.formData.shopInfo.addressStreet != '') {
                                var addressProvinceOne = Number(this.formData.shopInfo.addressProvince);
                                var addressCityOne = Number(this.formData.shopInfo.addressCity);
                                var addressAreaOne = Number(this.formData.shopInfo.addressArea);
                                var addressStreetOne = Number(this.formData.shopInfo.addressStreet);
                                this.formData.shopInfo.shopAddress = [addressProvinceOne, addressCityOne, addressAreaOne, addressStreetOne];
                            }
                            if (this.formData.shopInfo.shopAddress != '') {
                                let mm = JSON.parse(JSON.stringify(this.formData.shopInfo.shopAddress));
                                mm.pop();
                                this.initAllArea(mm);
                            }

                            // 经营类目
                            if (data.shopInfo.categoryList.length != 0) {
                                var dataNumber = [];
                                var dataNumber2 = [];
                                const list = data.shopInfo.categoryList
                                list.forEach(item => {
                                    this.serviceTypes.forEach(item2 => {
                                        item2.children.forEach(item3 => {
                                            if (item === item3.value) {
                                                dataNumber.push({
                                                    categoryId: item3.value,
                                                    categoryOneName: item2.label,
                                                    categoryTwoName: item3.label
                                                })
                                                dataNumber2.push([item2.value, item3.value])
                                            }
                                        })
                                    })
                                })
                                this.serviceData = dataNumber2
                                this.sort_pro(dataNumber)

                            }

                        } else {
                            message.error("前置信息获取失败，请重新填写");
                            this.$router.back();
                        }
                        this.buttonSet.loading = false;
                        this.buttonSet.enableClick = true;
                    })


                    .then(_ => {
                        this.form.setFieldsValue(initRefr);
                    });
            });
            this.visibleConfirm = false;
        },
        methods: {
            //图片回填
            testImages() {
                if (this.formData.expansionInfo.coverPicture) {
                    this.uploadCoverFileList.push({
                        uid: "uploadCoverFileList",
                        name: "lpIdPicturePositiveFile.png",
                        status: "done",
                        url:
                            this.formData.expansionInfo.coverPicture.indexOf("/fileupload") == 0
                                ? `${baseUrl}${this.formData.expansionInfo.coverPicture}`
                                : this.formData.expansionInfo.coverPicture
                    });
                }
            },
            //获取地址信息并回填
            async initAllArea([a, b, c]) {
                let opton = this.options;
                for (let level of [a, b, c]) {
                    let item = opton.find(item => item.value == level);
                    await this.loadData([item]);
                    opton = item.children;
                }
            },

            //获取数据排序
            sort_pro(data) {

                let group = {}
                let groupId = []
                let endList = []
                //按照拼音首字母进行排序
                data.sort((a, b)=> a.categoryTwoName.localeCompare(b.categoryTwoName, 'zh'));
                data.forEach(i => {
                    // group[i.name] = group[i.name] || []
                    if (group[i.categoryOneName]) {
                        group[i.categoryOneName] = group[i.categoryOneName]
                    } else {
                        group[i.categoryOneName] = []
                        groupId.push(i.categoryOneName)
                    }
                    group[i.categoryOneName].push(i)
                })

                groupId.forEach(i => {
                    endList = endList.concat(group[i])
                })
                this.DataPush(endList)
            },
            DataPush(value) {
                this.serviceCategoryList = value
                this.getSpanArr(value);

            },
            // 合并表格数据
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1)
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].categoryOneName === data[i - 1].categoryOneName) {
                            this.spanArr[this.pos] += 1
                            this.spanArr.push(0)
                        } else {
                            this.spanArr.push(1)
                            this.pos = i
                        }
                    }
                }
            },
            // 表格合并
            objectSpanMethod({rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.spanArr[rowIndex]
                    const _col = _row > 0 ? 1 : 0
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                } else {
                    return false
                }
            },
            // 新增类目
            add() {
                this.serviceCategoryModel = true;
            },
            //类目选择框变化
            cascaderChange(value) {
                if (value.length === 0) {
                    this.selectedData = this.serviceCategoryList
                } else {
                    this.selectedData = value
                }
            },
            //服务类目为空判断
            newDatecs(rule, value, callback) {
                if (this.serviceCategoryList.length === 0) {
                    callback("服务类目不能为空，请选择。");
                    return
                }
                callback();
            },
            //确认新增类目
            erviceCategoryDate() {
                var dataNumber = [];
                var dataNumber2 = [];
                if (this.selectedData.length === 0) {
                    this.serviceCategoryList.forEach(item => {
                        this.serviceTypes.forEach(item2 => {
                            item2.children.forEach(item3 => {
                                if (item.categoryId === item3.value) {
                                    dataNumber.push({
                                        categoryId: item3.value,
                                        categoryOneName: item2.label,
                                        categoryTwoName: item3.label
                                    })
                                    dataNumber2.push([item2.value, item3.value])
                                }
                            })
                        })
                    })
                } else {
                    this.selectedData.forEach(item => {
                        this.serviceTypes.forEach(item2 => {
                            item2.children.forEach(item3 => {
                                if (item[1] === item3.value) {
                                    dataNumber.push({
                                        categoryId: item3.value,
                                        categoryOneName: item2.label,
                                        categoryTwoName: item3.label
                                    })
                                    dataNumber2.push([item2.value, item3.value])
                                }
                            })
                        })
                    })
                }
                this.sort_pro(dataNumber);
                this.serviceData = dataNumber2
                this.serviceCategoryModel = false

                this.form.setFieldsValue({
                    serviceCategory: 0
                })
                this.form.validateFields(['serviceCategory'], (err) => {
                })
            },
            //删除类目
            delServiceInfo(row) {
                const dataNumber22 = [];
                const dataNumber33 = [];
                this.serviceCategoryList.splice(this.serviceCategoryList.indexOf(row), 1)
                this.serviceCategoryList.forEach(item => {
                    this.serviceTypes.forEach(item2 => {
                        item2.children.forEach(item3 => {
                            if (item.categoryId === item3.value) {
                                dataNumber33.push({
                                    categoryId: item3.value,
                                    categoryOneName: item2.label,
                                    categoryTwoName: item3.label
                                })
                                dataNumber22.push([item2.value, item3.value])
                            }
                        })
                    })
                })
                this.sort_pro(dataNumber33);
                this.serviceData = dataNumber22
                if (this.serviceData.length === 0) {
                    this.form.setFieldsValue({
                        serviceCategory: 0
                    })
                    this.form.validateFields(['serviceCategory'], (err) => {
                    })
                }
            },
            //关闭弹窗
            handleCancelTwo() {
                this.serviceCategoryModel = false;
            },


            // 上一步
            routerBack() {
                this.saveJoinForm();
                this.$router.push("/BusinessJoinStep1");
            },
            //获取行业类别
            getResions() {
                dictionariesWithDesc(8).then(res => {
                    this.industryInvolvedList = res.values;
                });
            },
            //行业类别赋值
            selectChange(value) {

                this.formData.expansionInfo.industryInvolved = value;
            },
            // /*选择店铺地址*/
            loadData(selectedOptions) {
                if (selectedOptions[0] != undefined) {
                    const targetOption = selectedOptions[selectedOptions.length - 1];
                    const {value} = targetOption;
                    const {level} = targetOption;
                    targetOption.loading = true;

                    return new Promise(resolve => {
                        fetchAddrCascader({
                            areaType: level,
                            areaId: value
                        }).then(data => {
                            resolve();
                            targetOption.loading = false;
                            if (data && data.code == 0) {
                                const list = data.data.list;
                                let children = [];
                                if (list) {
                                    list.forEach(i => {
                                        children.push({
                                            label: i.name,
                                            value: i.id,
                                            isLeaf: i.childNum == 0,
                                            level: level + 1
                                        });
                                    });
                                }
                                targetOption.loading = false;
                                targetOption.children = children;
                                this.options = [...this.options];
                            } else {
                                message.error(data.msg);
                            }
                        });
                    });
                }
            },
            // 初始化地址
            init(place) {
                return new Promise(resolve => {
                    fetchAddrCascader({
                        areaType: 0,
                        areaId: ""
                    }).then(data => {
                        resolve();
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach(i => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        level: 1,
                                        isLeaf: false
                                    });
                                });
                            }
                            this.options = children;
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },

            // yPeng 店铺所在地
            companyAddrcheck(rule, value, callback) {
                if (this.formData.shopInfo.shopAddress.length == 0) {
                    callback("店铺所在地不能为空");
                } else {
                    callback();
                }
            },
            // yPeng 获取店铺地址
            onChange(value, selectedOptions) {
                if (value.length >= 3 && value.length <= 4) {
                    this.formData.shopInfo.addressProvince = value[0];
                    this.formData.shopInfo.addressCity = value[1];
                    this.formData.shopInfo.addressArea = value[2];
                    this.formData.shopInfo.addressStreet = value[3] || "";
                    this.formData.shopInfo.shopAddress = value;
                    fetchProvinceName({
                        province: this.formData.shopInfo.addressProvince,
                        city: this.formData.shopInfo.addressCity,
                        area: this.formData.shopInfo.addressArea,
                        street: this.formData.shopInfo.addressStreet
                    }).then(res => {
                        if (res.code == 0) {
                            const add = res.info.addr;
                            this.formData.shopInfo.shopAddressPlaceholder = `${add.province}/${add.city}/${add.area}${add.street ? `/${add.street}` : ""}`;
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            },

            getFormValue(name) {
                return this.form.getFieldValue(name);
            },
            /* 校验公司简介*/
            jianjieCheck(rule, value, callback) {
                if (value && value.length <= 9) {
                    callback("公司简介最少10个字（包含10个）");
                    return;
                }
                callback();
            },

            //校验电话
            phoneCheck(rule, value, callback) {
                if (!value) {
                    callback("店铺电话不能为空");
                }
                const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
                const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value) && !reg2.test(value)) {
                    callback(
                        "格式不正确，请输入正确格式的公司电话,如：021-87888822,13812345678"
                    );
                } else {
                    callback();
                }
            },

            // 校验商户账号
            checkMerAccount(rule, value, callback) {
                if (!value) {
                    callback(new Error("商家账号不能为空"));
                    return;
                }
                if (/^[0-9]*$/.test(value)) {
                    callback(new Error("商家账号不能为纯数字，请重新输入"));
                    return;
                }

                // 异步校验重复
                checkMerAccountExist(value).then(res => {
                    if (res && res.code == 0) {
                        callback();
                    } else {
                        callback(new Error(res.msg));
                    }
                });
            },
            /* 校验手机号*/
            telCheck(rule, value, callback) {
                const field = rule.field;
                const fieldsObject = {
                    farenphone: "法定代表人联系方式",
                    harryTel: "公司紧急联系人手机",
                    adminTel: "管理员联系手机"
                };
                if (!value) {
                    callback(`${fieldsObject[field]}不能为空`);
                    return;
                }

                const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的手机号");
                    return;
                }
                callback();
            },
            /**
             * 校验身份证号
             * @param rule
             * @param value
             * @param callback
             */
            infoCardCheck(rule, value, callback) {
                const field = rule.field;
                const fieldsObject = {
                    farenInfoCard: "法定代表人",
                    admininfoCard: "管理员"
                };
                if (!value) {
                    callback(`${fieldsObject[field]}身份证号不能为空`);
                    return;
                }

                // ^\d{15}|\d{18}$
                const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的身份证号");
                    return;
                }
                callback();
            },
            //检验验证码
            yzmCheck(rule, value, callback) {
                if (!value) {
                    callback("请填写验证码");
                    return;
                }
                if (!this.cheYZMBynet) {
                    return;
                }

                let getFormValue = name => this.form.getFieldValue(name);

                if (this.t) {
                    clearTimeout(this.t);
                }
                this.t = setTimeout(() => {
                    checkMobileValidateCode({
                        mobile: getFormValue("adminTel"),
                        code: getFormValue("yzm")
                    }).then(res => {
                        if (res && res.code == 0) {
                            callback();
                        } else {
                            callback("短信验证码错误或已失效，请重新获取");
                        }
                    });
                }, 100);
            },
            /* 发送验证码*/
            handleSendCode() {
                let mobile = this.form.getFieldValue("adminTel");
                this.form.validateFields(["adminTel"], (errors, values) => {
                    if (!errors) {
                        if (!this.isSending) {
                            this.isSending = true;
                            this.interval = setInterval(() => {
                                if (this.remainTime >= 1) {
                                    this.remainTime -= 1;
                                } else {
                                    this.isSending = false;
                                    window.clearInterval(this.interval);
                                    this.remainTime = 60;
                                }
                            }, 1000);
                            getVerificationCode(mobile).then(res => {
                                this.isSendingFisrt = false;
                                if (res && res.code === 0) {
                                    message.success("验证码已发送！");
                                } else {
                                    message.error(res.msg);
                                }
                            });
                        }
                    } else {
                        return;
                    }
                });
            },

            // 校验店铺名称是否存在
            checkShopName(rule, value, callback) {
                if (!value) {
                    callback("商户名称不能为空");
                    return;
                }
                checkShopNameExist(value).then(res => {
                    if (res && res.code == 0) {
                        callback();
                    } else {
                        callback(new Error(res.msg));
                    }
                });
            },

            getImageShow(imageUrl) {
                return `${baseUrl}${imageUrl}`;
            },
            uploadCoverAll(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadCover(options);
                }, 1000);
            },
            // 上传封面
            uploadCover(options) {
                if (!this.ifImageCanUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                })
                    .then(data => {
                        if (data && data.code == 0) {
                            options.onSuccess();
                            this.formData.expansionInfo.coverPicture = data.filePath;
                            this.uploadCoverFileList.push({
                                uid: "uploadCoverFileList",
                                name: "lpIdPicturePositiveFile.png",
                                status: "done",
                                url: this.formData.expansionInfo.coverPicture.indexOf("/fileupload") == 0 ? `${baseUrl}${data.filePath}` : data.filePath
                            });
                        } else {
                            message.error(data.msg);
                            this.uploadCoverFileList.pop();
                        }
                    })
                    .catch(error => {
                        message.error("上传失败");
                        this.uploadCoverFileList.pop();
                        this.form.validateFields(["coverPicture"], errors => {
                        });
                    });
            },
            // 移除封面
            uploadCoverRemove(file) {
                this.uploadCoverFileList = [];
                this.formData.expansionInfo.coverPicture = "";
            },
            //校验图片上传
            uploadCoverFileCheck(rule, value, callback) {
                if (this.formData.expansionInfo.coverPicture) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传封面图片");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({coverPicture: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();
                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            // 图片预览
            async handlePicturePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await this.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            // 将文件转成Base64
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            handleCancelOK() {
                this.previewVisible = false;
            },
            handleCancel() {
                this.$router.push("/BusinessJoinStepProcess");
            },


            // 提交
            handleSubmit(e) {
                e.preventDefault();
                if (this.buttonSet.enableClick) {
                    this.buttonSet.loading = true;
                    this.buttonSet.enableClick = false;
                    this.cheYZMBynet = true;
                    this.form.validateFieldsAndScroll(
                        null,
                        {
                            first: false,
                            scroll: {
                                offsetTop: 120,
                            },
                        },
                        (err, values) => {
                            this.cheYZMBynet = false
                            if (!err) {
                                this.saveJoinForm();
                                fetchMerchantAdd(this.formData).then((res) => {
                                    this.buttonSet.loading = false;
                                    this.buttonSet.enableClick = true;

                                    if (res && res.code == 0) {
                                        message.success("提交成功，等待审核");

                                        this.buttonSet.loading = false;
                                        this.buttonSet.enableClick = true;

                                        this.visibleConfirm = true;
                                    } else {
                                        this.buttonSet.loading = false;
                                        this.buttonSet.enableClick = true;
                                        message.error(res.msg);
                                    }
                                });
                            } else {
                                this.buttonSet.loading = false;
                                this.buttonSet.enableClick = true;
                            }
                        }
                    );
                }
            },
            // 存储数据
            saveJoinForm() {
                let getFormValue = name => this.form.getFieldValue(name);
                // 填充数据
                this.formData.shopInfo.merchantAccount = getFormValue("merchantAccount");
                this.formData.shopInfo.storeName = getFormValue("storeName");
                this.formData.shopInfo.detailAddress = getFormValue("detailAddress");
                this.formData.shopInfo.shopAddress = getFormValue("shopAddress");
                this.formData.shopInfo.shopAddressPlaceholder = getFormValue("shopAddressPlaceholder");
                this.formData.shopInfo.merchantTel = getFormValue("merchantTel");
                this.formData.expansionInfo.merchantIntro = getFormValue("merchantIntro");
                this.formData.expansionInfo.merchantRemark = getFormValue("merchantRemark");

                // 经营类目填充
                this.formData.shopInfo.categoryList = [];
                var serviceCategoryType = []
                this.serviceCategoryList.forEach((i) => {
                    if (i != '') {
                        this.formData.shopInfo.categoryList.push(i.categoryId);
                    }
                });
                // 管理员信息
                this.formData.administratorInfo.adminName = getFormValue("adminName");
                this.formData.administratorInfo.adminIDNum = getFormValue("admininfoCard");
                this.formData.administratorInfo.adminTel = getFormValue("adminTel");

                localStorage.setItem("merchantJoinForm", JSON.stringify(this.formData));
            }
        }
    };
</script>

<style lang="less">
    .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
        color: #60c7c6 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #60c7c6 !important;
        border-color: #60c7c6 !important;
    }
    //隐藏弹出框最外边框
    .el-cascader-panel.is-bordered{
        border: none !important;
    }
    //设置类目弹出框框中样式（下三个）
   .el-cascader-menu{
       height: 1.8rem;
        border:1px solid #E4E7ED !important;
       border-radius: 4px;

    }
   .el-cascader-menu:first-child{
       margin-left: 0.3rem;
   }
   .el-cascader-menu:last-child{
       margin-left: 0.4rem;
       .el-cascader-node__label{
           min-width: 1.73rem;
           overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
       }
   }
    //设置类目表格样式（下三个）
    .el-table th>.cell {
        font-weight: 600;
        font-size: 14px;
        color: #606979;
        opacity: 1;
    }
    .cell{
        font-size: 0.14rem;
        color: #606979;
        opacity: 1;
    }
    //操作删除样式
    .remove{
        font-size:0.14rem;
        color: #39BEB8;
        opacity: 1;
        cursor: pointer;
    }

    .businessJoinStep2 {
        margin-top: -0.01rem;

        .stepTop {
            width: 100%;
            display: flex;
            margin-bottom: 0.6rem;
            position: relative;

            img {
                width: 100%;
                height: 1.2rem;
            }

            .rz-type {
                position: absolute;
                left: 4.4rem;
                top: 0.39rem;
                font-size: 0.24rem;
                color: #fff;
                font-weight: bold;
                opacity: 1;
            }
        }

        .progressBar {
            width: 4.5rem;
            height: 1rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form-box {
            padding: 0.3rem 0;
            margin-bottom: 0.7rem;
            /*border: 1px solid red;*/


            .tip {
                margin-top: 0.2rem;
                .ant-form-item-label label {
                    font-size: 0.14rem;
                    font-weight: bold;
                    color: #454F63;
                    opacity: 1;
                }

                .ant-form-item-label > label::after {
                    content: '';
                }
            }

            .ant-input {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
                width: 3.53rem;
                height: 0.4rem;
                /*border: 1px solid red;*/

                &::placeholder {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #8F9BA8;
                    opacity: 1;

                }
            }

            .ant-form-item-control-wrapper {
                .ant-form-explain {
                    font-size: 0.14rem;
                    color: #FF4021;
                    opacity: 1;
                    margin-left: 3.7rem;
                    margin-top: -0.33rem;
                }
            }
            .imageUpload {
                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                        margin-left: 3.7rem;
                        margin-top: -0.8rem;
                    }
                }
                .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                    width: 100%;
                    /*object-fit: contain;*/
                    object-fit: fill;
                    border-radius: 4px;
                }
                .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
                    padding: 0;
                }
            }

            .authCode {
                position: absolute;
                top: -0.115rem;
                left: 2.15rem;
                height: 0.41rem;

                button {
                    width: 1.39rem;
                    height: 100%;
                    background: #F5AA21;
                    font-size: 0.14rem;
                    color: #FFFFFF;
                    opacity: 1;
                }

                button:hover {
                    border: 1px solid #F5AA21;
                    background: #F5AA21;
                }

            }


            .present {
                margin-top: 0.8rem;

                button {
                    width: 1.46rem;
                    height: 0.4rem;
                    opacity: 1;
                    border-radius: 0.2rem;
                    font-size: 0.14rem;

                    img {
                        width: 7.75px;
                        height: 13.38px;
                    }
                }

                .button1 {
                    border: 1px solid #78849E;
                    margin-right: 0.5rem;
                    color: #454F63;

                    img {
                        margin-right: 0.07rem;
                    }
                }

                .button2 {
                    color: #FFFFFF;

                    img {
                        width: 14.3px;
                        height: 14px;
                        margin-right: 0.07rem;
                    }
                }
            }

            .ant-cascader-picker-label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-form-item-label label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-form-item {
                margin-bottom: 0.2rem;
            }

            .ant-input:hover {
                border-color: #60c7c6;
            }

            .ant-select-selection:hover {
                border-color: #60c7c6;
            }

            .ant-radio-checked .ant-radio-inner {
                border-color: #60c7c6;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }

            .ant-radio-inner::after {
                background-color: #60c7c6;
            }

            .ant-btn-primary {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:focus {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }
        }

        .fileTipBox2020050611 {
            position: absolute;
            font-size: 0.14rem;
            font-weight: 400;
            color: #a9a9a9;
            opacity: 1;
            top: 0.35rem;
            display: flex;
            align-items: center;
            height: 0.2rem;
            width: 9rem;
            /*border: 1px solid red;*/
        }
    }
</style>

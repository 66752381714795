<template>
  <div>
    <div>
      <!--      <h2>当前系统outsno：<strong>{{globalUUid}}</strong></h2>-->
      <div>
        <!--        <input v-model="uuids" placeholder="请输入同系统下多个id，用逗号分割" />-->
        <button @click="batchQuery">结果上传平台</button>
        <!--        <p>{{Result}}</p>-->
      </div>
    </div>
    <div id="shadow_ct"></div>
  </div>
</template>

<script>
    import {baseUrl, request} from "@/services/http";

    // function getUUid(val){
//   globalUUid = val;
// }

export default {
  data() {
    return {
      uuids: "",
      shadow: null,
      globalUUid: null,
      Result: null,
      url: "",
      RelationId: null
    };
  },
  mounted() {
    this.getPackage();
    this.initShadow();
  },
  methods: {
    NewURL() {
      window.location.href = this.url;
    },

    getUUidNow(val) {
      this.globalUUid = val;
      this.$data.globalUUid = val;
    },

    getPackage() {
      // eslint-disable-next-line no-irregular-whitespace
      const scriptEl = document.createElement("script");
      // eslint-disable-next-line no-irregular-whitespace
      scriptEl.src =
        "https://static.guahao.cn/front/ai-cdn-static-private/dist/ai-shadow/ai-shadow@0.0.18.min.js";
      document.body.append(scriptEl);
    },
    initShadow() {
      if (!window.shadowModule) {
        setTimeout(() => {
          this.initShadow();
        }, 1000);
        return;
      }

      this.shadow = new window.shadowModule({
        env: "pro",
        imageType: this.$route.query.typeResult,
        outSno: "",
        id: "#shadow_ct",
        organizeCode: "22a5d139bbc04f7d8716e14ee422659f",
        sdkTokenApi: "http://ai.guahao.cn/platform/user/getAuthToken",
        weDemo: true,
        uploadCallback: function() {
        },

        //初始化生成唯一标识
        // eslint-disable-next-line no-irregular-whitespace
        initCallback: this.getUUidNow,

        // 分析结果的回调
        analyzeCallback: function(obj) {
        },
        // 保存按钮的回调
        //saveCallback:getResult1,
        saveCallback: function(obj) {
        },
        system: "sdk"
      });

      // this.shadow.getQueryResults([this.globalUUid]).then(res => {
      //   this.Result = res;
      // });
    },
    batchQuery() {
      this.shadow.getQueryResults(this.globalUUid.split(",")).then(res => {
        this.Result = res;
      });
      this.RelationId = this.$route.query.relationId;
      let formData = new FormData();
      formData.append("relationId", this.RelationId);
      formData.append("Result", this.Result);
      request(`${baseUrl}/api/v1/api-services/zaixian/guahao-upload-result `, {
        method: "POST",
        data: formData
      });
    }
  },
  beforeDestroy() {
    this.$eventBus.$emit("showFooter");
  }
};
</script>


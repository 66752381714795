var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pof66542"}},[_c('div',{staticClass:"form-box"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"inline"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"contactInformation"},[_c('div',{staticClass:"yuyueTitle"},[_vm._v("联系信息")]),_c('a-form-item',_vm._b({attrs:{"label":"联系人"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'contactName',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '联系人不能为空'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'contactName',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '联系人不能为空'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请输入联系人姓名"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"联系人电话"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'contactTel',
                              {
                                rules: [
                                  {
                                    required: true,
                                    validator: this.phoneCheck.bind(this)
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'contactTel',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    validator: this.phoneCheck.bind(this)\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请输入联系人电话"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"服务人所在地"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'address',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择服务人所在地区'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'address',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请选择服务人所在地区'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"options":_vm.options,"placeholder":"请选择服务人所在地","loadData":_vm.loadData}})],1),_c('a-form-item',_vm._b({attrs:{"label":"详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'detailAdd',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '详细地址不能为空'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'detailAdd',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '详细地址不能为空'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请补充地址信息，如三号楼4单元212室"}})],1)],1),_c('div',{staticClass:"oldInformation"},[_c('div',{staticClass:"yuyueTitle"},[_vm._v("老人信息")]),_c('a-form-item',_vm._b({attrs:{"label":"老人年龄"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'elderAge',
                              {
                                rules: [
                                  {
                                    required: true,
                                    validator: this.timeOfLife.bind(this),
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'elderAge',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    validator: this.timeOfLife.bind(this),\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请输入老人年龄"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"性别"}},'a-form-item',_vm.formItemLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'elderGender',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择老人性别'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'elderGender',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请选择老人性别'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"options":_vm.contentOption}})],1),_c('a-form-item',_vm._b({attrs:{"label":"身体情况"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'physicalCondition',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择老人身体情况'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'physicalCondition',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请选择老人身体情况'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请选择老人身体情况"}},_vm._l((_vm.physicalConditionList),function(val){return _c('a-select-option',{key:val},[_c('span',{staticStyle:{"font-size":"0.14rem"}},[_vm._v(_vm._s(val))])])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"可接受价位"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'priceRange',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择可接受价位'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'priceRange',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请选择可接受价位'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请选择可接受价位"}},_vm._l((_vm.priceRangeList),function(val){return _c('a-select-option',{key:val},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"需求时段"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'demandPeriod',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择需求时段'
                                  }
                                ]
                              }
                            ]),expression:"[\n                              'demandPeriod',\n                              {\n                                rules: [\n                                  {\n                                    required: true,\n                                    message: '请选择需求时段'\n                                  }\n                                ]\n                              }\n                            ]"}],attrs:{"placeholder":"请选择需求时段"}},_vm._l((_vm.demandPeriodList),function(val){return _c('a-select-option',{key:val,staticStyle:{"font-size":"0.16rem"}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1),_c('a-form-item',_vm._b({staticStyle:{"height":"0.55rem"},attrs:{"label":"需求备注"}},'a-form-item',{
                            labelCol: {
                              xs: { span: 24 },
                              sm: { span: 5 }
                            },
                            wrapperCol: {
                              xs: { span: 24 },
                              sm: { span: 15 }
                            }
                          },false),[_c('a-textarea',{attrs:{"placeholder":"请说明您的需求备注"},model:{value:(_vm.demandRemark),callback:function ($$v) {_vm.demandRemark=$$v},expression:"demandRemark"}})],1)],1)]),_c('div',{attrs:{"id":"pensionOrderForemButtonBox"}},[(_vm.allowSubmit)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_c('img',{attrs:{"src":require("../assets/images/homeImg/组 121@2x.png")}}),_vm._v("提交 ")]):_vm._e(),(!_vm.allowSubmit)?_c('a-button',{attrs:{"type":"primary"}},[_c('img',{attrs:{"src":require("../assets/images/homeImg/组 121@2x.png")}}),_vm._v("提交 ")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
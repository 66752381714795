



















































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message} from "ant-design-vue";
    import {addServiceAddress, serviceAreaMap, updateServiceAddress} from "@/services/api";
    import AMapLoader from "@amap/amap-jsapi-loader";

    @Component({})
    export default class fuWuLocationAdd extends Vue {
        form: any;
        @Prop() editData: any;
        @Prop() editType: string;
        data: any = {
            phoneNumber: "",
            receiverDetailAddress: "",
            receiverName: "",
            receiverArea: null,
            receiverCity: null,
            receiverProvince: null,
            isDefault: false,
            addressId: "",
            lot: "",
            lat: "",
            provinceValueList: '',
            provinceValue: [],
        };
        map: any;
        formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 15}
            }
        };

        beforeCreate() {
            this.form = this.$form.createForm(this);
        }

        mounted() {
            this.acquire()
            this.initMap();
        }


        acquire() {
            //父组件数据
            const data = this.editData;
            this.data = {...data, point: data.lot ? data.lot : null};
            this.data
            this.form.setFieldsValue({
                receiverName: this.data.receiverName,
                phoneNumber: this.data.phoneNumber,
                provinceValueList: this.data.provinceValueList,
                receiverDetailAddress: this.data.receiverDetailAddress,
                isDefault: this.data.isDefault
            });
        }

        async initMap() {
            // this.$baiDuKey
            // console.log(this.$baiDuKey)
            const AMap = await AMapLoader.load({
                key: "c4ac5dbaa55b2994a57c6b369f28af53",
                plugins: [
                    "AMap.Geocoder",
                    "AMap.Scale",
                    "AMap.Geolocation",
                    "AMap.Autocomplete",
                    "AMap.PlaceSearch"
                ],
                version: "1.4.15"
            });
            await this.loadAmapUI();
            const AMapUI: any = (window as any).AMapUI;
            this.map = new AMap.Map("map");

            //输入提示
            var autoOptions = {
                input: "pickerInput"
            };
            var auto = new AMap.Autocomplete(autoOptions);
            console.log(auto)


            var placeSearch = new AMap.PlaceSearch({
                map: this.map
            }); //构造地点查询类

            AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
            var that = this;
            var marker = new AMap.Marker({
                // draggable: true,
                // cursor: "move"
            });
            marker.setMap(that.map);

            if (this.data.lot && this.data.lat) {
                let lnglat = new AMap.LngLat(+this.data.lot, +this.data.lat);
                marker.setPosition(lnglat);
                this.map.setFitView();

                if (this.data.receiverDetailAddress)
                    marker.setLabel({
                        content: this.data.receiverDetailAddress,
                        offset: new AMap.Pixel(0, -5),
                        direction: "top"
                    });
            }

            function select(e) {
                const poi = e.poi;
                marker.setLabel({
                    content: poi.name,
                    offset: new AMap.Pixel(0, -5),
                    direction: "top"
                });

                marker.setPosition(poi.location);
                that.form.setFieldsValue({
                    lot: poi.location.lng,
                    lat: poi.location.lat,
                    point: poi.location,
                    receiverDetailAddress: poi.name
                });
                that.map.setFitView();
                //根据地图选点获取服务地区
                if (e) {
                    serviceAreaMap(e.poi.adcode).then(res => {
                        if (res && res.code === 0) {
                            let dataName: any = '';
                            let dataId: any = '';
                            res.data.forEach(item => {
                                dataName += item.name + '/'
                                dataId += item.id + ','
                            })
                            dataName = dataName.substring(0, dataName.lastIndexOf('/'));
                            that.data.provinceValueList = dataName;
                            dataId = dataId.substring(0, dataId.lastIndexOf(','));
                            that.data.provinceValue = dataId.split(",")
                            that.data.receiverProvince = that.data.provinceValue[0]
                            that.data.receiverCity = that.data.provinceValue[1]
                            that.data.receiverArea = that.data.provinceValue[2]
                            that.form.setFieldsValue({provinceValueList: that.data.provinceValueList});
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
                placeSearch.setCity(e.poi.adcode);
                // placeSearch.search(e.poi.name); //关键字查询查询
            }
        }

        poi(AMapUI, AMap) {
            console.log('执行了么')
            var _this = this;
            AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
                var poiPicker = new PoiPicker({
                    input: "pickerInput"
                });
                poiPickerReady(poiPicker);
            });

            function poiPickerReady(poiPicker) {
                (window as any).poiPicker = poiPicker;

                var marker = new AMap.Marker({
                    draggable: true,
                    cursor: "move"
                });
                var Geocoder = new AMap.Geocoder();
                marker.on("dragend", evt => {
                    _this.form.setFieldsValue({
                        lot: evt.lnglat.lng,
                        lat: evt.lnglat.lat,
                        point: evt.lnglat
                    });
                    // Geocoder.getAddress(evt.lnglat, (status, result) => {
                    //   if (status == "complete") {
                    //     let str = result.regeocode.formattedAddress;
                    //     _this.preName.forEach(item => {
                    //       let reg = new RegExp(item);
                    //       str = str.replace(reg, "");
                    //     });

                    //     _this.form.setFieldsValue({
                    //       receiverDetailAddress: str
                    //     });
                    //   } else message.error("自动转换失败，请手动输入详细地址");
                    // });
                });

                if (_this.data.lot && _this.data.lat) {
                    marker.setMap(_this.map);
                    let lnglat = new AMap.LngLat(+_this.data.lot, +_this.data.lat);
                    marker.setPosition(lnglat);
                    _this.map.setCenter(lnglat);
                }
                poiPicker.on("poiPicked", function (poiResult) {
                    var source = poiResult.source,
                        poi = poiResult.item;
                    marker.setMap(_this.map);
                    marker.setPosition(poi.location);
                    _this.form.setFieldsValue({
                        lot: poi.location.lng,
                        lat: poi.location.lat,
                        point: poi.location
                    });
                    _this.form.setFieldsValue({
                        receiverDetailAddress: poi.name
                    });
                    _this.map.setCenter(poi.location);
                });
            }
        }

        loadAmapUI(url?: string) {
            url = url || "https://cache.amap.com/lbs/static/addToolbar.js";

            function createScript(url) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.setAttribute("data-esri-loader", "loading");
                return script;
            }

            function getScript() {
                return document.querySelector("script[data-esri-loader]");
            }

            function handleScriptLoad(script, callback, errback) {
                var onScriptError;
                var onScriptLoad = function () {
                    // pass the script to the callback
                    callback(script);
                    // remove this event listener
                    script.removeEventListener("load", onScriptLoad, false);
                    if (onScriptError) {
                        // remove the error listener as well
                        script.removeEventListener("error", onScriptError, false);
                    }
                };
                script.addEventListener("load", onScriptLoad, false);
            }

            var script: any = getScript();
            return new Promise((resolve, reject) => {
                if (!script) {
                    script = createScript(url);
                    handleScriptLoad(
                        script,
                        function () {
                            // update the status of the script
                            script.setAttribute("data-esri-loader", "loaded");
                            // return the script
                            resolve(script);
                        },
                        reject
                    );
                    document.body.appendChild(script);
                } else {
                    resolve();
                }
            });
        }


        //保存服务
        handleSubmit(evt) {
            evt.preventDefault();

            this.form.validateFields((err: any, values: any) => {
                if (!err) {
                    const data = this.form.getFieldsValue();
                    if (this.editType) {
                        this.editAddress(data);
                    } else {
                        this.addAddress(data);
                    }
                }
            });
        }

        //修改地址
        editAddress(data) {
            const a = {
                ...this.data,
                ...data,
                phoneNumebr: data.phoneNumber,
                isServiceAddress: 1,
                isDefault: data.isDefault ? 1 : 0,
                longitude: data.point.lng,
                latitude: data.point.lat
            };
            updateServiceAddress(a).then(res => {
                if (res && res.code === 0) {
                    message.success("更新服务地址成功");
                    this.$emit("editSuccess");
                } else {
                    message.error(res.msg);
                }
            });
        }

        //新增地址
        addAddress(data) {
            const a = {
                ...this.data,
                ...data,
                phoneNumebr: data.phoneNumber,
                isServiceAddress: 1,
                isDefault: data.isDefault ? 1 : 0,
                longitude: data.point.lng,
                latitude: data.point.lat
            };
            addServiceAddress(a).then(res => {
                if (res && res.code === 0) {
                    message.success("新增服务地址成功");
                    this.$emit("addSuccess");
                } else {
                    message.error(res.msg);
                }
            });
        }

//手机号验证
        checkMobile(rule, value, callback) {
            const telRegxp = /^1[3456789]\d{9}$/;
            if (value && !telRegxp.test(value)) {
                callback("请输入正确的手机号");
            } else {
                callback();
            }
        }


        provinceOptions: any = []; // 省市区街道列表

        //取消保存
        cancelTheSave() {
            this.$emit("cancelSave");
        }
    }

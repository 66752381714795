










































































































































































































































import {Component, Vue} from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import castAbout from "../../creditInvestigation/components/castAbout.vue";
import homeScore from "../../creditInvestigation/particulars/home-score.vue";
import {getServiceDetail} from "@/services/api";
import {message} from "ant-design-vue";


@Component({
    components: {
        castAbout, homeScore, Footer
    },
})
export default class extends Vue {
    consumptionDetailData: any = []; // 商品消费明细列表
    friendlyLink: any = [];
    value = 3;
    columns1 = [
        {
            title: "服务订单号",
            dataIndex: "seriesNum",
            align: "center"
        },
        {
            title: "服务详情",
            dataIndex: "actualAmount",
            align: "center"
        },
        {
            title: "服务方式",
            dataIndex: "purchaseMethod",
            align: "center"
        },
        {
            title: "实收金额",
            dataIndex: "createTime",
            align: "center"
        },
        {
            title: "服务订单状态",
            dataIndex: "",
            align: "center"
        },
        {
            title: "下单时间",
            dataIndex: "",
            align: "center"
        }
    ];
    columns2 = [
        {
            title: "预约单编号",
            dataIndex: "seriesNum",
            align: "center"
        },
        {
            title: "预约详情",
            dataIndex: "actualAmount",
            align: "center"
        },
        {
            title: "养老机构名称",
            dataIndex: "purchaseMethod",
            align: "center"
        },
        {
            title: "联系人",
            dataIndex: "createTime",
            align: "center"
        },
        {
            title: "预约单状态",
            dataIndex: "",
            align: "center"
        },
        {
            title: "预约时间",
            dataIndex: "",
            align: "center"
        }
    ];
    columns = [
        {
            title: "商品订单编号",
            dataIndex: "seriesNum",
            align: "center"
        },
        {
            title: "商品名称",
            dataIndex: "actualAmount",
            align: "center"
        },
        {
            title: "实收金额",
            dataIndex: "purchaseMethod",
            align: "center"
        },
        {
            title: "收货人",
            dataIndex: "createTime",
            align: "center"
        },
        {
            title: "商品订单状态",
            dataIndex: "",
            align: "center"
        },
        {
            title: "下单时间",
            dataIndex: "",
            align: "center"
        }
    ];

    mounted() {
        this.consumptionDetail()
        this.friendlyLink = [
            {img: require('@/assets/zxImages/icon_haoping@2x.png'), name: '累计好评数',num:'100'},
            {img: require('@/assets/zxImages/icon_zhongping@2x.png'), name: '累计中评数',num:'100'},
            {img: require('@/assets/zxImages/icon_chaping@2x.png'), name: '累计差评数',num:'100'},
            {img: require('@/assets/zxImages/icon_tuikuan@2x.png'), name: '退款笔数',num:'100'},
            {img: require('@/assets/zxImages/icon_quxiao@2x.png'), name: '取消笔数',num:'100'},
            {img: require('@/assets/zxImages/icon_weifukuan@2x.png'), name: '未付款笔数',num:'100'},
        ];
    }

    consumptionDetail() {
        getServiceDetail({
            pageIndex: 1,
            pageSize: 5,
        }).then((res: any) => {
            if (res && res.code === 0) {
                this.consumptionDetailData = res.data;
            } else {
                message.error(res.msg);
            }
        });
    }

}









































































import {Component, Vue} from "vue-property-decorator";
import {dictionaries, fetchYlmsNextMenuList} from "@/services/api";

@Component
export default class Footer extends Vue {
    thisYear = 2019;
    YLMS: any = []
    YLFW: any = []
    YLFW2: any = []
    YLSC: any = []
    YLFWLIST: any = []
    ylfwList: any[] = []
    scanCodeToDownload: any = []

    created() {
        //console.log("%c黄%c绿%c蓝","color:yellow","color:red","color:blue")
        const that = this
        this.thisYear = new Date().getFullYear();
        //获取二维码信息
        dictionaries([188]).then((res) => {
            if (res && res.code == 0) {
                let levelOneTypeList = res.values[188];
                const imgPoistion = {
                    769: '平台APP下载',
                    770: '怡亲安安小程序',
                    771: '服务人员小程序',
                };
                levelOneTypeList.map((item) => {
                    item.name = imgPoistion[item.key];
                });
                this.scanCodeToDownload = levelOneTypeList
            }
        })


        this.YLMS = [
            {name: "机构养老", pathId: '206'},
            {name: "居家养老", pathId: '207'},
            {name: "社区养老", pathId: '208'},
            {name: "旅居养老", pathId: '209'},
            {name: "医养结合", pathId: '210'}
        ];


        let chunkArr = (arr: any[], size) => {
            //判断如果不是数组(就没有length)，或者size没有传值，size小于1，就返回空数组
            if (!arr.length || !size || size < 1) return []
            let start, end, result: any[] = []
            for (let i = 0; i < Math.ceil(arr.length / size); i++) {
                start = i * size
                end = start + size
                result.push(arr.slice(start, end))
            }
            return result
        }
        //养老服务
        let serviceForTheAgedData: any = localStorage.getItem("serviceForTheAged");
        if (!serviceForTheAgedData) {
            fetchYlmsNextMenuList().then(res => {
                if (res && res.code == 0) {
                    let fwList: any = []
                    localStorage.setItem("serviceForTheAged", JSON.stringify(res));
                    res.data.secondTypeList.forEach(item => {
                        fwList.push({
                            name: item.classificationName,
                            pathId: item.serviceClassificationId
                        })
                    })
                    this.ylfwList = chunkArr(fwList, 5)
                }
            })
        } else {
            const serviceForTheAgedDa = JSON.parse(serviceForTheAgedData);
            let fwList: any = []
            serviceForTheAgedDa.data.secondTypeList.forEach(item => {
                fwList.push({
                    name: item.classificationName,
                    pathId: item.serviceClassificationId
                })
            })
            this.ylfwList = chunkArr(fwList, 5)
        }

        this.YLSC = [
            {name: "征信平台入口", path: '/creditInvestigation/creditReportingHome'},
            {name: "光彩旅居平台", path: 'http://47.102.158.125/most_frontend/#/'},
            {name: "养老服务集成平台", path: 'http://39.107.81.201:8086/#/adminlogin?redirect=%2FserviceManage%2Fonline'},
            {name: "养老服务托管平台", path: 'http://39.96.76.138:8082/#/login'},
            {name: "数据分析平台", path: 'http://47.102.158.125/big_data_analysis_platform/#/'}
        ];
    }

    scheme(item) {
        if (item == '/mall') {
            (location.href.includes("test") || location.href.includes("develop") || location.href.includes("adv")) ? open("/mall") : alert("暂未开放");
        } else if (item == '/creditInvestigation/creditReportingHome') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                const {href}: any = this.$router.resolve(item)
                window.open(href, "_blank");
            } else {
                this.$router.push("/login");
            }
        } else {
            if (item.substr(0, 7).toLowerCase() == "http://") {
                open(item)
            } else {
                const {href}: any = this.$router.resolve(item)
                window.open(href, "_blank");
            }

        }

    }

  /**
   * 打开新标签页面
   * @param url 地址
   */
  openNewTagForImg(url) {
    window.open(url)
  }
}

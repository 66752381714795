



























import {Component, Vue} from "vue-property-decorator";
import YlmsHeader from "./YlmsHeader.vue";
import YlmsItem from "./YlmsItemTwo.vue";
import YlmsContent from "./YlmsContent.vue";
import Ylorganization from "@/components/YlorganizationTwo.vue";

interface OrgLabels {
    labelDesc: string;
    labelId: string;
    labelName: string;
    orgId: string;
}

export interface OrgList {
    addressArea: string;
    addressCity: string;
    addressProvince: string;
    addressStreet: string;
    areaSize: number;
    bedNum: number;
    coverPicture: string;
    createTime: string;
    detailAddress: string;
    fatherId: string;
    highestPrice: number;
    ifParentOrg: number;
    lowestPrice: number;
    orgAttitudeScore: number;
    orgCharacterName: string;
    orgEfficiencyScore: number;
    orgForm: string;
    orgId: string;
    orgIntro: string;
    orgLabels: OrgLabels[];
    orgOveralScore: number;
    orgRemark: string;
    orgSeries: string;
    orgServiceComplianceScore: number;
    orgTel: string;
    orgTypeName: string;
    serviceObject: string;
    specialService: string;
    storeName: string;
    updateTime: string;
    formDicId: Number;
}

@Component({
    components: {
        YlmsHeader,
        YlmsContent,
        YlmsItem,
        Ylorganization
    },
    props: {
        headerImage: String,
        headerTitle: String,
        headerContent: String,
        headerMoreVisible: Boolean,
        imgPoistion: String,
        formDicId: Number,
        orgList: Array
    }
})
export default class Ylms extends Vue {
    // @Prop() private msg!: string
    // @Watch("orgList", { immediate: true, deep: true })
    // getOrgList(newval, oldnew) {
    // }
}




































































import {Component, Vue, Watch} from "vue-property-decorator";
import ServiceProjectCard from "@/components/ServiceProjectCard.vue";
import YlmsItem from "@/components/YlmsItem.vue";
import YlJGItem from "@/components/YlJGItem.vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";
import noResult from "./noResult.vue";
import aSpin from "@/components/aSpin.vue";

@Component({
    props: {
        projectList: Array,
        orgList: Array,
        getOrganizationList: Array,
        projectPagination: Object,
        searchContent: String,
        orgPagination: Object,
        organizationtPagination: Object,
    },
    components: {
        ServiceProjectCard,
        YlmsItem,
        YlJGItem,
        InstitutionInfo,
        noResult,
        aSpin
    },
})
export default class extends Vue {
    cityName = "";
    loading = true;

    @Watch("getOrganizationList", {immediate: true, deep: true})
    getGetOrganizationList(newVal, oldVal) {
        this.loading = false
    }

    bdakbdka(type) {
        window.scrollTo({top: 0});
        this.$emit("bdaskj", type, [
            this.$props.projectPagination.total,
            this.$props.orgPagination.total,
            this.$props.organizationtPagination.total,
        ]);
    }

    searchnnn() {
        this.$emit("searchnnn");
    }

    getCityName() {
        const allList = JSON.parse(sessionStorage.getItem("allAreaData") as any);
        const cityId = localStorage.getItem("provinceId");
        let obj = {};
        allList.map((item) => {
            const m = item.cityList.find((item) => item.cityId == cityId);
            m ? (this.cityName = m.shortName) : "";
        });
    }

    created() {
        this.getCityName();
    }

    onProjectChange(page: any, pageSize: any) {
        window.scrollTo({top: 0});
        this.$emit("onProjectChange", page);
    }

    onOrgPagination(page: any, pageSize: any) {
        window.scrollTo({top: 0});
        this.$emit("onOrgPagination", page);
    }

    organizationPagination(page: any, pageSize: any) {
        window.scrollTo({top: 0});
        this.$emit("organizationPagination", page);
    }

    //切换城市
    junpCity() {
        this.$router.push("/pickCity");
    }

}

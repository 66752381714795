



























































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import CollectionScrollItem from "./CollectionScrollItem.vue";
    import {fetchCollection, topside} from "@/services/api";
    import {message} from "ant-design-vue";

    @Component({components: {CollectionScrollItem}})
export default class serviceProviderItem extends Vue {
  @Prop() public item!: any;
  @Prop() seviceList: any;
  currentScrollPage = 0;
  totalScrollPage = 0;
  nowList = [];
  serveCancel = false

  created() {
    this.nowList = this.seviceList.slice(0, 5);
    this.totalScrollPage = Math.ceil(this.seviceList.length / 5);
  }

  //置顶、取消置顶
  cancelTheTop(value) {
    topside({
      collectionId: value,
    }).then(res => {
      if (res && res.code === 0) {
        // 处理数据
        this.$emit("getListAgain");
      } else {
        message.error(res.msg);
      }
    });
  }

  cancel() {
    this.serveCancel = true
  }

  enshrine() {
    fetchCollection({
      itemType: 238,
      itemId: this.item.orgId,
      operation: 1,
    }).then((res) => {
      if (res.code == 0) {
        this.$emit("getListAgain");
        this.serveCancel = false
      } else {
        this.$message.error(res.msg);
      }
    });
  }

  handlePre() {
    if (this.currentScrollPage > 0) {
      this.currentScrollPage--;
      this.nowList = this.seviceList.slice(
          this.currentScrollPage * 5,
          this.currentScrollPage * 5 + 5
      );
    }
  }

  handleNext() {
    if (this.currentScrollPage < this.totalScrollPage - 1) {
      this.currentScrollPage++;
      this.nowList = this.seviceList.slice(
          this.currentScrollPage * 5,
          this.currentScrollPage * 5 + 5
      );
    }
  }
}








































































































    import {Vue} from "vue-property-decorator";
    import {Icon} from "ant-design-vue";
    import GoodsDetail from "@/components/Personal/myGoods/GoodsDetail.vue";

    Vue.use(Icon);
//
// @Component({
//
//
//
//
// })
export default {
  components: {
    GoodsDetail
  },
  props: {
    status: Number,
    orderTime: String,
    orderNum: String,
    storeName: String,
    customer: String,
    totalPrice: Number,
    payMode: String,
    remainingHour: Number,
    remainingMin: Number,
    content: String,
    productStatus: String,
    count: Number,
    shippingFee: Number,
    cancelOrder: Function
  },
  data() {
    return {
      commodityHeight: 0
    };
  },

  mounted() {
    // this.getcommodityHeight();
  },
  methods: {
    getcommodityHeight() {
      // this.commodityHeight = this.$refs.commodity_height.offsetHeight + "px";
    },
    lookDetail() {
      // let href = this.$router.resolve("/personal/serviceDetail");
      window.open("#/personal/OrderDetail");
    }
  }
};

// export default class MyGoodsItem extends Vue {
//   // @Prop() private msg!: string
// }

<template>
    <div class="reminder-service">
     <div class="divImg">
         <img src="../../assets/images/homeImg/路径 2315@2x.png"/>
     </div>
        <div class="divP">
            <span class="p1">入驻信息已经成功提交，等待平台审核</span>
            <span class="p2">我们会在1—2个工作日内完成审核工作</span>
        </div>
    </div>

</template>
<script>
    import Vue from "vue";
    import {Button} from "ant-design-vue";

    Vue.use(Button);

    export default {};
</script>

<style lang="less">
    .reminder-service {
        /*height: 2.05rem;*/
        /*border: 1px solid red;*/
        .divImg {
            width: 100%;
            display: flex;
            justify-content: center;
            img {
                width: 80.67px;
                height: 80.67px;
                margin-top: 0.31rem;
            }
        }
        .divP{
            width: 100%;
            text-align: center;
            /*border: 1px solid red;*/
            .p1{
                font-size: 0.16rem;
                font-weight: bold;
                color: #2A2E43;
                opacity: 1;
                display: block;
                margin-top: 0.3rem;
            }
            .p2{
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
                display: block;
                margin-top: 0.1rem;
                /*margin-bottom: 0.3rem;*/
            }
        }

    }

</style>

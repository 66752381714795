

















































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {Mutation} from "vuex-class";

    import {classServie2Detail, classServie31Detail, classServie32Detail,} from "@/services/api";

    @Component({
  props: {
    updateTime: String,
    servicOrderId: String,
    quantity: Number,
    serviceName: String,
    agencyName: String,
    orgId: String,
    serviceStatus: String,
    serviceFee: Number,
    price: Number,
    contactPerson: String,
    status: Number,
    servicePrice: Number,
    servicePicture: String,
    serviceId: String,
    openService: Function,
    relationId: String,
    serviceType: Number,
    openTime: String,
  },
})
export default class OpenServiceItem extends Vue {
  // @Prop() private msg!: string
  @Prop() private itemIndex: Number;
  @Mutation SET_DETAILITEM;
  @Mutation SET_CLASSSERVICEDETAIL;
  @Mutation SET_CURDETAILSTATUS;
  @Mutation SET_PAYTIMES;
  @Mutation SET_CANCELRESON;
  @Mutation SET_ALLORDERSTATUS;
  @Mutation SET_RELATIONID;
  @Mutation SET_CLASSSERVICEONESTEP;
  @Mutation SET_CLASSSERVICETWOSTEP;
  @Mutation SET_CLASSSERVICETHREESTEP;

  //查看开启服务
  viewOpenService(relationId: string, serviceType: number) {
    switch (serviceType) {
      case 432:
        //查看2类
        this.$router.push({
          path: "/personal/classOpenServiceOne2",
          query: {
            relationId: relationId,
          },
        });
        break;
      case 434:
        break;
    }
  }

  serviceDetailEvent(relationId: string, serviceType: number) {
    let callback: (params: any) => Promise<any>,   // 根据不同的服务类型去请求不同的接口
        switchServiceStatus: Function, // 判断不同的状态
        hrefUrl: string; // 根据不同的服务类型跳转到不同的地址
    // 判断类型
    switch (serviceType) {
      case 432: // 纯线上服务（2类） 2类服务订单详情
        callback = classServie2Detail;
        hrefUrl = '/personal/classServiceTow';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待开启":
              this.classServiceTwoStep["step1"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "服务开启中":
              this.classServiceTwoStep["step2"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;

            case "服务完成":
              this.classServiceTwoStep["step3"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "服务开启完成":
              this.classServiceTwoStep["step3"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "已关闭":
              this.classServiceTwoStep["step4"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
          }
        }
        break;
      case 433: // 3.1类服务订单详情 线上线下相结合的服务（3.1类） 无需对接接口 如家政
        callback = classServie31Detail;
        hrefUrl = '/personal/classServiceOne';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待服务":
              this.classServiceOneStep["step1"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;
            case "服务进行中":
              this.classServiceOneStep["step2"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;
            case "服务完成":
              this.classServiceOneStep["step3"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;

            case "已关闭":
              this.classServiceOneStep["step4"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
          }
        }
        break;
      case 434: //3.2类订单详情 线上线下相结合但需要对接接口（3.2 类） 如体检
        callback = classServie32Detail;
        hrefUrl = '/personal/ServiceProcessDetail';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待开启":
              this.classServiceThreeStep["step1"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "服务开启中":
              this.classServiceThreeStep["step2"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "服务开启完成":
              this.classServiceThreeStep["step5"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "已关闭":
              this.classServiceThreeStep["step6"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
          }
        }
        break;
      default:
        callback = (params: any) => {
          return new Promise((resolve, reject) => {
            reject('服务类型错误');
          });
        }
    }

    // 请求服务订单详细数据
    callback({
      relationId: relationId,
    }).then((res) => {
      if (res) {
        let data: any;
        data = (res as any).data;
        data.serviceType = serviceType; // 类型也一起存进去
        // 存储数据
        this.SET_CLASSSERVICEDETAIL(data || {});
        // 判断不同的状态
        switchServiceStatus(data.serviceStatus);
        // 跳转
        window.open(
            this.$router.resolve(hrefUrl).href,
            "_blank"
        );
      }
    });

  }

  currStatus: "";
  time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  curOrderStatus = {
    paixiafuwu: false,
    maijiafukuan: false,
    fuwutigongshang: false,
    querenfuwu: false,
    pingjia: false,
    guanbi: false,
  };
  classServiceOneStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };
  classServiceTwoStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };
  classServiceThreeStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  };
}

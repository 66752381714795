var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-body"},[_c('a',{staticClass:"arrow-pre",style:(_vm.canPre
        ? {}
        : {
            backgroundImage: ("url(" + (require('./../assets/images/moren_zuo_icon_zhucan.png')) + ")")
          }),on:{"click":_vm.handlePre}}),_c('div',{staticClass:"card-body"},[_c('div',{ref:'card1',staticClass:"card",style:({ left: (_vm.leftOffset + "rem") })},_vm._l((_vm.orgList),function(item,index){return _c('PicItem',{key:index,attrs:{"id":"picItem","itemPic":item.coverPicture,"itemName":item.storeName,"itemLocation":item.detailAddress},nativeOn:{"click":function($event){return _vm.toPage(item)}}})}),1)]),_c('a',{staticClass:"arrow-next",style:(_vm.canNext
        ? {}
        : {
            backgroundImage: ("url(" + (require('./../assets/images/moren_you_icon_dyfwxm.png')) + ")")
          }),on:{"click":_vm.handleNext}})])}
var staticRenderFns = []

export { render, staticRenderFns }
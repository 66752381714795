










































































































































































    import {Component, Vue} from "vue-property-decorator";
    import Footer from "@/components/Footer.vue";
    import castAbout from "../../creditInvestigation/components/castAbout.vue";
    import homeScore from "../../creditInvestigation/particulars/home-score.vue";
    import {getCommodityDetailInfo} from "@/services/api";


    @Component({
  components: {
    castAbout, homeScore, Footer
  },
})
export default class extends Vue {
  consumptionDetailData: any = [];
  list: any = [];
  friendlyLink: any = [];
  value = 3;
  columns1 = [
    {
      title: "服务订单号",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "服务详情",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "评价内容",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "评价人",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "服务评分",
      dataIndex: "",
      align: "center"
    },
    {
      title: "服务商评分",
      dataIndex: "",
      align: "center"
    }
  ];
  columns = [
    {
      title: "服务详情",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "服务价格",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "服务方式",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "销售笔数",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "服务评分",
      dataIndex: "",
      align: "center"
    }
  ];
  columns2 = [
    {
      title: "名称",
      dataIndex: "name",
      align: "center"
    },
    {
      title: "简介",
      dataIndex: "synopsis",
      align: "left"
    },
  ];

  commodityDataInfo = {
    commodityId: "",
    commodityName: "",
    onStatus: "",
    commodityImage: "",
    agencyName: "",
    commodityPrice: "",
    commodityContent: "",
    commoditySaleNum: 0,
    totalScore: 0,
    commodityInfoScore: [],
    processScore: 0,
    commodityCount: null,
    commodityWaitingPayingCount: 0,
    commodityWaitingPayingCancelCount: {
      cancelBySelfCount: 0,
      cancelByOtherCount: 0,
      cancelBySelfNegotiateCount: 0,
      cancelByProviderNegotiateCount: 0,
      cancelByProviderCount: 0
    },
    commodityOngoingCount: 0,
    commodityOngoingCancelCount: {
      cancelBySelfCount: 0,
      cancelByOtherCount: 0,
      cancelBySelfNegotiateCount: 0,
      cancelByProviderNegotiateCount: 0,
      cancelByProviderCount: 0
    },
    commodityComplateCount: 0,
    commodityCancelCount: 0,
    complationRate: 0.00,
    refundNum: 0,
    praiseReviewNum: 0,
    averageReviewNum: 0,
    badReviewNum: 0
  }

  mounted() {

    const that = this
    let commodityId = that.$route.query['commodityId'];
    if (commodityId) {
      getCommodityDetailInfo(commodityId).then(res => {
        if (res && res.code == 0) {
          that.commodityDataInfo = res.info

          this.mattessWeek(that.commodityDataInfo.commodityInfoScore);
          this.mattessWeekTwo([
            that.commodityDataInfo.commodityWaitingPayingCount,
            that.commodityDataInfo.commodityOngoingCount,
            that.commodityDataInfo.commodityComplateCount,
            that.commodityDataInfo.commodityCancelCount
          ]);
          this.list = [
            {name: "有形性", synopsis: "产品名称，服务简介，服务提供人员的资质说明、工作经历以及服务地点和设施"}
            , {name: "移情性", synopsis: "服务适用对象和服务个性化选择"}
            , {name: "保证性", synopsis: "服务流程化规范化，法律信息的完整性和正确性"}
            , {name: "响应性", synopsis: "确认预约时间和确认派单时间"}
            , {name: "可靠性", synopsis: "包括联系方式，服务时长，价格，售后以及投诉渠道"}
          ];
          this.friendlyLink = [
            {
              img: require('@/assets/zxImages/icon_haoping@2x.png'),
              name: '累计好评数',
              num: that.commodityDataInfo.praiseReviewNum
            },
            {
              img: require('@/assets/zxImages/icon_zhongping@2x.png'),
              name: '累计中评数',
              num: that.commodityDataInfo.averageReviewNum
            },
            {
              img: require('@/assets/zxImages/icon_chaping@2x.png'),
              name: '累计差评数',
              num: that.commodityDataInfo.badReviewNum
            },
            {
              img: require('@/assets/zxImages/icon_tuikuan@2x.png'),
              name: '退款笔数',
              num: that.commodityDataInfo.refundNum
            },
            {
              img: require('@/assets/zxImages/icon_quxiao@2x.png'),
              name: '取消笔数',
              num: that.commodityDataInfo.commodityCancelCount
            },
            {
              img: require('@/assets/zxImages/icon_weifukuan@2x.png'),
              name: '未付款笔数',
              num: that.commodityDataInfo.commodityWaitingPayingCount
            },
          ];

        }
      })
    }
  }

  mattessWeek(arr) {
    // 基于准备好的dom，初始化echarts实例
    var echarts = require('echarts')
    var myChart = echarts.init(document.getElementById('myChart1'))
    // 绘制图表
    myChart.setOption({
      tooltip: {},
      legend: {
        data: ['服务产品信息评分']
      },
      radar: {
        // shape: 'circle',
        name: {
          textStyle: {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: 3,
            padding: [3, 5]
          }
        },
        indicator: [
          {name: '有形性', max: 100},
          {name: '移情性', max: 100},
          {name: '报证性', max: 100},
          {name: '相应性', max: 100},
          {name: '可靠性', max: 100},
        ]
      },
      series: [{
        name: '服务产品信息评分',
        type: 'radar',
        data: [
          {
            value: arr || [50, 50, 50, 50, 50],
            name: '具体得分'
          },

        ]
      }]
    })
  }

  mattessWeekTwo(arr) {
    // 基于准备好的dom，初始化echarts实例
    var echarts = require('echarts')
    var myChart = echarts.init(document.getElementById('myChart2'))
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['预约阶段', '进行阶段', '完成阶段', '终止阶段'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '60%',
          data: arr || [1, 1, 1, 1]
        }
      ]
    })
  }

  consumptionDetail() {

  }

}

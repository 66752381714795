












































import {Component, Vue} from "vue-property-decorator";
import {Input, message, Modal, Rate, Select, Table, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import shopDiscuss from "@/components/Personal/MyComment/shopDiscuss.vue";
import {commodityEvaluation, deleteCommodityEvaluation} from "@/services/api";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Select);
Vue.use(Rate);
Vue.use(Modal);

@Component({
    components: {
        PersonalHeader,shopDiscuss
    }
})
export default class extends Vue {
    // 取消订单Modal显隐
    serveComplete = false;
    serveCompleteData = '';
    curType = "全部";

    eventbsakd(key) {
        if (key == 1) this.$router.push("/personal/serveComment");
        if (key == 2) this.$router.push("/personal/shopComment");
        if (key == 3) this.$router.push("/personal/yuyveComment");
        if (key == 4) this.$router.push("/personal/diagnosisTreatComment");
    }

    data2: Array<any> = [];
    topLis = [
        {
            title: "商品信息",
            width: "4rem"

        },
        {
            title: "评价得分",
            width: "3rem"
        },
        {
            title: "评价时间",
            width: "3rem"
        },
        {
            title: "操作",
            width: "2.5rem"
        }
    ];
    pagination = {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        queryStatus: 2
    };

    async created() {
        await this.getServiceComment();
    }

    // 获取商品评价
    getServiceComment() {
        commodityEvaluation({
           ...this.pagination
        }).then((res: any) => {
            if (res && res.code === 0) {
                this.data2 = [];
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                res.data.list.forEach((item: any, index: any) => {
                    let obj: any = {};
                    obj.information = {
                        commodityName: item.commodityName,
                        pic: item.pic
                    };
                    obj.rating = item.rating;
                    obj.createTime = item.createTime;
                    obj.ids = {
                        commodityId: item.commodityId,
                        commentId: item.commentId
                    };
                    this.data2.push(obj);
                });
            } else {
                message.error(res.msg);
            }
        });
    }

    // 点击删除商品评价
    handleDelServiceComment(value: any) {
        this.serveComplete=true
        this.serveCompleteData=value
    }
    serviceOrderConfirm(){
        deleteCommodityEvaluation(this.serveCompleteData).then((res: any) => {
            if (res && res.code === 0) {
                // 处理数据
                message.success("删除成功！");
                this.getServiceComment();
            } else {
                message.error(res.msg);
            }
        }).then(_ => {
          this.serveComplete = false
        });
    }

    //查看商品评价
    checkDetail(item) {
        let {href} = this.$router.resolve({
            path: "/personal/goodsJudgeService",
            query: {

                commentId: item.commentId
            }
        });
        open(href);
    }
}

















































































































































    import {Component, Vue} from "vue-property-decorator";
    import Footer from "@/components/Footer.vue";
    import castAbout from "../../creditInvestigation/components/castAbout.vue";
    import homeScore from "../../creditInvestigation/particulars/home-score.vue";
    import {getServiceDetailInfo} from "@/services/api";


    @Component({
  components: {
    castAbout, homeScore, Footer
  },
})
export default class extends Vue {
  consumptionDetailData: any = [];
  list: any = [];
  friendlyLink: any = [];
  value = 3;
  columns = [
    {
      title: "服务详情",
      dataIndex: "seriesNum",
      align: "center"
    },
    {
      title: "服务价格",
      dataIndex: "actualAmount",
      align: "center"
    },
    {
      title: "服务方式",
      dataIndex: "purchaseMethod",
      align: "center"
    },
    {
      title: "销售笔数",
      dataIndex: "createTime",
      align: "center"
    },
    {
      title: "服务评分",
      dataIndex: "",
      align: "center"
    }
  ];
  columns2 = [
    {
      title: "名称",
      dataIndex: "name",
      align: "center"
    },
    {
      title: "简介",
      dataIndex: "synopsis",
      align: "left"
    },
  ];

  serviceDataInfo = {
    serviceId: "",
    serviceName: "",
    onStatus: "",
    serviceImage: "",
    agencyName: "",
    servicePrice: "",
    serviceContent: "",
    serviceSaleNum: 0,
    serviceStyle: "",
    totalScore: 0,
    serviceInfoScore: [],
    processScore: null,
    serviceCount: null,
    serviceWaitingPayingCount: null,
    serviceWaitingPayingCancelCount: {
      cancelBySelfCount: 0,
      cancelByOtherCount: 0,
      cancelBySelfNegotiateCount: 0,
      cancelByProviderNegotiateCount: 0,
      cancelByProviderCount: 0
    },
    serviceOngoingCount: 0,
    serviceOngoingCancelCount: {
      cancelBySelfCount: 0,
      cancelByOtherCount: 0,
      cancelBySelfNegotiateCount: 0,
      cancelByProviderNegotiateCount: 0,
      cancelByProviderCount: 0
    },
    serviceComplateCount: null,
    serviceCancelCount: 0,
    complationRate: null,
    refundNum: 0,
    praiseReviewNum: null,
    averageReviewNum: 0,
    badReviewNum: 0
  }

  mounted() {
    const that = this
    let serviceId = that.$route.query['serviceId'];
    if (serviceId) {
      getServiceDetailInfo(serviceId).then(res => {
        if (res && res.code == 0) {

          that.serviceDataInfo = res.info

          this.mattessWeek(that.serviceDataInfo.serviceInfoScore);
          this.mattessWeekTwo([
            that.serviceDataInfo.serviceWaitingPayingCount,
            that.serviceDataInfo.serviceOngoingCount,
            that.serviceDataInfo.serviceComplateCount,
            that.serviceDataInfo.serviceCancelCount
          ]);
          this.list = [
            {name: "有形性", synopsis: "产品名称，服务简介，服务提供人员的资质说明、工作经历以及服务地点和设施"}
            , {name: "移情性", synopsis: "服务适用对象和服务个性化选择"}
            , {name: "保证性", synopsis: "服务流程化规范化，法律信息的完整性和正确性"}
            , {name: "响应性", synopsis: "确认预约时间和确认派单时间"}
            , {name: "可靠性", synopsis: "包括联系方式，服务时长，价格，售后以及投诉渠道"}
          ];
          this.friendlyLink = [
            {img: require('@/assets/zxImages/icon_haoping@2x.png'), name: '累计好评数', num: that.serviceDataInfo.praiseReviewNum},
            {img: require('@/assets/zxImages/icon_zhongping@2x.png'), name: '累计中评数', num: that.serviceDataInfo.averageReviewNum},
            {img: require('@/assets/zxImages/icon_chaping@2x.png'), name: '累计差评数', num: that.serviceDataInfo.badReviewNum},
            {img: require('@/assets/zxImages/icon_tuikuan@2x.png'), name: '退款笔数', num: that.serviceDataInfo.refundNum},
            {img: require('@/assets/zxImages/icon_quxiao@2x.png'), name: '取消笔数', num: that.serviceDataInfo.serviceCancelCount},
            {img: require('@/assets/zxImages/icon_weifukuan@2x.png'), name: '未付款笔数', num: that.serviceDataInfo.serviceWaitingPayingCount},
          ];

        }
      })
    }


  }

  /**
   * 这里初始化服务产品信息评分
   */
  mattessWeek(arr) {
    // 基于准备好的dom，初始化echarts实例
    var echarts = require('echarts')
    var myChart = echarts.init(document.getElementById('myChart1'))
    // 绘制图表
    myChart.setOption({
      tooltip: {},
      legend: {
        data: ['服务产品信息评分']
      },
      radar: {
        // shape: 'circle',
        name: {
          textStyle: {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: 3,
            padding: [3, 5]
          }
        },
        indicator: [
          {name: '有形性', max: 100},
          {name: '移情性', max: 100},
          {name: '报证性', max: 100},
          {name: '相应性', max: 100},
          {name: '可靠性', max: 100},
        ]
      },
      series: [{
        name: '服务产品信息评分',
        type: 'radar',
        data: [
          {
            value: arr || [50, 50, 50, 50, 50],
            name: '具体得分'
          },

        ]
      }]
    })
  }

  mattessWeekTwo(arr) {
    // 基于准备好的dom，初始化echarts实例
    var echarts = require('echarts')
    var myChart = echarts.init(document.getElementById('myChart2'))
    // 绘制图表
    myChart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['预约阶段', '进行阶段', '完成阶段', '终止阶段'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '60%',
          data: arr || [1, 1, 1, 1]
        }
      ]
    })
  }

  consumptionDetail() {
    // getServiceDetail({
    //     pageIndex: 1,
    //     pageSize: 5,
    // }).then((res: any) => {
    //     if (res && res.code === 0) {
    //         this.consumptionDetailData = res.data;
    //
    //     } else {
    //         message.error(res.msg);
    //     }
    // });
  }

}



    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {Action} from "vuex-class";
    import {http} from "@/services/http";

    @Component({})
export default class Abbreviation extends Vue {
  @Prop() couponList: any;
  @Prop() brandRecommend: any;
  @Prop() hotLeaderboard: any;
  @Watch("brandRecommend") t(val) {
    this.changeBrandRecommend();
  }
  brandRecommendShow = [];

  mounted() {
    // this.brandRecommendShow=
  }
  changeBrandRecommend() {
    const a:any = [];
    for (let i = 0; i < 9; i++) {
      const m = Math.random() * this.brandRecommend.length;
      a.push(this.brandRecommend[Math.floor(m)]);
    }
    this.brandRecommendShow = a;
  }
  @Action("judgeIfLogin") judgeIfLogin: any;
  callback() {}
  more() {
    this.$router.push("/couponCenter");
  }
  quanSelect = 0;
  getafterChange(i) {
    this.quanSelect = i;
  }
  getCoupons(id) {
    this.judgeIfLogin();
    if (localStorage.getItem("userInfo"))
      http
        .post(`/api/v1/users/coupons/receive`, { couponId: id })
        .then((res) => {
          this.$message.success((res as any)["msg"]);
        });
  }
  jump(id) {
    this.$router.push("/detail/" + id);
  }
}












    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component
export default class ProductsItem extends Vue {
  @Prop() data: any;

  get _url() {
    const url: string = this.data.commodityImage || this.data.pic || "";

    return url
      ? url.includes("http")
        ? url
        : `http://admin.dev.fytlun.com${this.data.pic}`
      : "";
  }

  jump() {
    this.$router.push(`/mall/detail/${this.data.commodityId}`);
  }
}





































import {Component, Vue} from "vue-property-decorator";
import {giveAMark} from "@/services/api";

@Component
export default class extends Vue {
    score:any=80;
    scores:any=[];
    get title() {
        if (this.score <= 50) return "信誉较差"
        if (this.score <= 70) return "信誉一般"
        if (this.score <= 85) return "信誉良好"
        if (this.score <= 100) return "信誉优秀"
        return ''
    }

    get styleWidth() {
        let width = 295.31 * (this.score / 100);
        return width;
    }
    mounted() {
        this.mattessWeek();
        this.gettingData();
    }
    //获取统计数据
    gettingData(){
        giveAMark( {}).then(({info, code}) => {
            if (code===0){
                this.score = info.totalScore;
                this.scores = JSON.parse(info.scoresStr);
                // 基于准备好的dom，初始化echarts实例
                var echarts = require('echarts')
                var myChart = echarts.init(document.getElementById('myChart1'))
                // 绘制图表
                myChart.setOption({
                    tooltip: {},
                    legend: {
                        data: ['']
                    },
                    radar: {
                        // shape: 'circle',
                        name: {
                            textStyle: {
                                color: '#fff',
                                backgroundColor: '#999',
                                borderRadius: 3,
                                padding: [3, 5]
                            }
                        },
                        indicator: [
                            {name: '信息完整率', max: 100},
                            {name: '退款率', max: 100},
                            {name: '申诉率', max: 100},
                            {name: '好评率', max: 100},
                            {name: '差评率', max: 100},
                        ]
                    },
                    series: [{
                        name: '服务产品信息评分',
                        type: 'radar',
                        data: [
                            {
                                value:[Object.values( this.scores[0])[0], Object.values( this.scores[1])[0], Object.values( this.scores[2])[0], Object.values( this.scores[3])[0], Object.values( this.scores[4])[0]],
                                name: '具体得分'
                            },

                        ]
                    }]
                })
            }

        });

    }
    /**
     * 这里初始化服务产品信息评分
     */
    mattessWeek() {


    }
}

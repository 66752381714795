<script>
    import Vue from "vue";
    import atDesign from "ant-design-vue";
    import {request} from '../services/http'

    Vue.use(atDesign);

    export default {
        name: "RenderJsonToService",
        data() {
            return {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                JsonPack: {},           // 获取到的Json内容
                variableLibrary: {},    // 存储变量的变量库
                defaultRender: null,    // 默认渲染的页面
                pages: {},              // 页面 只解析index
                methodsLibrary: {},     // 方法栈
                commonStr: {
                    varRegex: /^\$\{([a-zA-Z0-9._$]*)\}$/,     // 匹配 ${xxx}
                    test: {},
                    uploadFileUrl: 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
                }
            }
        },
        created() {
            this.initData();
            this.getJsonInit()
            this.analyseData()
        },
        render() {
            return (
                <div class="body-box">
                    {this.defaultRender}
                </div>
            )
        },
        methods: {
            /**
             * 解析json
             */
            analyseData() {
                const jsonPack = this.JsonPack;

                if (this.emptyObject(jsonPack)) return;
                const indexPage = this.JsonPack['index'];
                if (this.emptyObject(jsonPack[indexPage])) return;
                for (let itemName in jsonPack) { // 先把默认参数建起来
                    this.variableLibrary[itemName] = {
                        "$params": {}, // 添加默认参数
                    }
                    let item = jsonPack[itemName];
                    switch (item.type) {
                        case 'page':
                            this.pages[itemName] = (<div></div>);
                            break;
                        case 'action':
                            this.methodsLibrary[itemName] = new Function();
                    }
                }

                // 解析主页面
                this.analysePage(indexPage)

            },
            /**
             * 解析页面
             * @param pageName 所属页面，有需要设置数据的内容
             */
            analysePage(pageName) {
                const jsonPack = this.JsonPack;

                let page = this.JsonPack[pageName];
                // 1 往变量库中添加页面变量，首先是 $params 为默认参数，然后扫描data，如果为空则不添加，有则挂载在下面
                // 不能设置值，要设置地址
                // 注意： 如果是index ,就解析data和page 如果不是，就只解析data

                // 找到所有的要用的action，倒序
                let pageAction = this.analysePageAction([], JSON.stringify(page)).reverse();

                // action优先
                // action优先，扫描index页中是否要调用某个函数，如果是，就创建，如果不是就不管了
                // 然后还要注意函数里面是否存在套娃关系
                pageAction.forEach(itemName => {
                    let item = jsonPack[itemName];
                    if (!this.emptyObject(item) && item.type && item.type == 'action' ) {
                        this.createAction(item, itemName, itemName);
                    }
                })
                this.setDataDefaultValueWithType(pageName)

                // 2 创建页面内容
                let indexPage = this.JsonPack['index'];
                if (indexPage == pageName) {
                    this.createPage(pageName)
                }
                this.defaultRender = this.pages[indexPage]

            },
            /**
             * 解析跳转行为，并不是解析动作
             * @param behavior 行为体, 并不是指方法栈中的某方法，而是指向行为的，比如提交成功做什么、异步成功/失败之后做什么
             * @param pageName 所属页面，有需要设置数据的内容
             */
            analyseBehavior(behavior, pageName) {
                if (this.emptyObject(behavior)) return;
                if (typeof behavior != 'object') return;

                let actionFunc
                let targetName
                switch (behavior['type']) {
                    case 'open-page': {
                        // 打开一个页面
                        targetName = behavior['page'];
                        if (this.emptyObject(this.pages[targetName])) return;
                        if (typeof targetName == "undefined") return;
                        actionFunc = () => {
                            // 跳转页面，只能跳转本规则下的页面
                            this.JsonPack["index"] = targetName

                            // 2 先处理参数传递，然后行为就直接return
                            if (!this.emptyObject(behavior['setparams'])) {
                                let setParams = behavior['setparams'];
                                for (let paramsName in setParams) {
                                    // 如果带着 ${}, 代表时变量，如果没有，则代表是常量
                                    // 赋值给action的参数域
                                    // 传参赋值，赋值为 {path:#xxx, val: xxx}
                                    this.variableLibrary[targetName]['$params'][paramsName] =
                                        this.analyseValueToSet(setParams[paramsName], pageName, targetName)
                                }
                            }
                            this.analysePage(targetName)
                        }
                    }
                        break;
                    case 'open-action': {
                        targetName = behavior['action'];

                        if (typeof targetName == "undefined") return;

                        if (typeof targetName == 'string') {
                            // 直接赋值func
                            actionFunc = this.methodsLibrary[targetName];
                        }
                        if (typeof targetName == 'object') {
                            // 需要先生成action，然后将action抽出来，再赋值地址
                            let actionName = `${pageName}_submit`;
                            this.createAction(targetName, actionName, pageName)
                            behavior['action'] = actionName
                            targetName = actionName
                        }

                        if (typeof this.methodsLibrary[targetName] != 'function') return;

                        // 2 先处理参数传递，然后行为就直接return
                        if (!this.emptyObject(behavior['setparams'])) {
                            let setParams = behavior['setparams'];
                            for (let paramsName in setParams) {
                                // 如果带着 ${}, 代表时变量，如果没有，则代表是常量
                                // 赋值给action的参数域
                                // 传参赋值，赋值为 {path:#xxx, val: xxx}
                                this.variableLibrary[targetName]['$params'][paramsName] =
                                    this.analyseValueToSet(setParams[paramsName], pageName, targetName)
                            }
                        }

                        actionFunc = this.methodsLibrary[targetName];
                    }
                        break;
                    case 'open-route': {
                        targetName = behavior['route'];
                        if (!targetName) return;
                        actionFunc = () => {
                            this.$router.push(`/${targetName}`)
                        }
                    }
                        break;
                    case 'open-message':
                        break;
                    case 'open-dialog':
                        break;
                }
                if (!actionFunc || (typeof actionFunc != 'function')) return;

                return actionFunc;
            },
            /**
             * 分析value值，是动态值还是静态值, 这里是取值，不是赋值page和action里面都有取值问题，大多是从$params中取值
             * @param valueRes value中的值 "xxx" 或者 "${xxx}"
             * @param pageName 所属页面，有需要设置数据的内容
             */
            analyseValue(valueRes, pageName) {
                // 也是一样，如果直接是类型数据，就直接填，如果是#${xx}，就需要分析取值
                if (!valueRes) return;
                let value = null;
                if (typeof valueRes == 'string') {
                    if (this.commonStr.varRegex.test(valueRes)) {
                        // 是变量
                        let match = valueRes.match(this.commonStr.varRegex);
                        let valuePath = "#"
                        if (match.length == 2) {
                            // 变量值为 match[1]，是否需要判断里面有没有.?
                            let matchValue = match[1];
                            if (matchValue.indexOf('.') > -1) {
                                // 有 ${xx.xxx.x} 的形式，xx是挂载在 this.variableLibrary[pageName] 挂载路径不需要this
                                let valueVal = this.variableLibrary[pageName]
                                if (this.emptyObject(valueVal)) return
                                valuePath = `${valuePath}variableLibrary.${pageName}`
                                matchValue.split('.').forEach(i => { // 不清楚到底是几级，直接循环

                                    if (typeof valueVal[i] == 'undefined') {

                                        valueVal[i] = {}
                                    }
                                    valueVal = valueVal[i]
                                    valuePath = `${valuePath}.${i}`
                                })

                                // 到这里基本就得到了 valuePath = this.variableLibrary.xxx.$params.xx
                                if (typeof valueVal['_path__'] != "undefined") {

                                    value = {
                                        "_path__": valueVal['_path__'],
                                        "_val": valueVal['_val']
                                    }
                                } else {
                                    value = {
                                        "_path__": valuePath,
                                        "_val": valueVal['_val']
                                    }
                                }

                            } else {
                                // 直接是 ${xxx} 格式
                                // 直接取 this.variableLibrary[pageName][matchValue]
                                // 然后分析_path__, 如果存在值，就直接将path丢回去，val不用管
                                if (typeof this.variableLibrary[pageName][matchValue]['_path__'] != "undefined") {
                                    value = {
                                        "_path__": `${valuePath}variableLibrary.${pageName}.${matchValue}`,
                                        "_val": this.variableLibrary[pageName][matchValue]['_val']
                                    }
                                } else {
                                    value = {
                                        "_path__": `${valuePath}variableLibrary.${pageName}.${matchValue}`,
                                        "_val": this.variableLibrary[pageName][matchValue]['_val']
                                    }
                                }
                            }

                        }
                    } else {
                        // 是字符串常量，直接赋值
                        value = {
                            "_path__": ".", // 代表直接就是当前量
                            "_val": valueRes['_val']
                        };
                    }
                } else {
                    switch (typeof valueRes) {
                        case "bigint":
                        case "boolean":
                        case "number":
                        case "object":
                        case "symbol":
                            value = {
                                "_path__": ".", // 代表直接就是当前量
                                "_val": valueRes
                            };
                            break;
                        // case "string":
                        // case "function":
                        // case "undefined":
                        //     default:
                    }
                }
                return value
            },
            /**
             * 分析value值，是动态值还是静态值, 这里是赋值，赋值基本就简单了，直接赋值给 $params
             * @param valueRes setparams中的值 "xxx" 或者 "${xxx}"
             * @param pageName 赋值是赋给谁
             */
            analyseValueToSet(valueRes, pageName) {
                if (typeof valueRes == "undefined") return;
                let value = null;
                if (typeof valueRes == 'string') {
                    if (this.commonStr.varRegex.test(valueRes)) {
                        // 是变量
                        let match = valueRes.match(this.commonStr.varRegex);
                        let valuePath = "#"
                        if (match.length == 2) {
                            // 变量值为 match[1]，是否需要判断里面有没有.?
                            let matchValue = match[1];
                            if (matchValue.indexOf('.') > -1) {
                                // 有 ${xx.xxx.x} 的形式，xx是挂载在 this.variableLibrary[pageName] 挂载路径不需要this
                                let valueVal = this.variableLibrary[pageName]
                                if (this.emptyObject(valueVal)) return
                                valuePath = `${valuePath}variableLibrary.${pageName}`
                                matchValue.split('.').forEach(i => { // 不清楚到底是几级，直接循环
                                    if (typeof valueVal[i] == 'undefined') {
                                        valueVal[i] = {}
                                    }
                                    valueVal = valueVal[i]
                                    valuePath = `${valuePath}.${i}`
                                })

                                value = {
                                    "_path__": valuePath,
                                    "_val": valueVal['_val']
                                }
                            } else {
                                // 直接是 ${xxx} 格式
                                value = {
                                    "_path__": `${valuePath}variableLibrary.${pageName}.${matchValue}`,
                                    "_val": this.variableLibrary[pageName][matchValue]['_val']
                                }
                            }

                        }
                    } else {
                        // 是字符串常量，直接赋值
                        value = {
                            "_path__": ".", // 代表直接就是当前量
                            "_val": valueRes['_val']
                        };
                    }
                } else {
                    switch (typeof valueRes) {
                        case "bigint":
                        case "boolean":
                        case "number":
                        case "object":
                        case "symbol":
                            value = {
                                "_path__": ".", // 代表直接就是当前量
                                "val": valueRes
                            };
                            break;
                        // case "string":
                        // case "function":
                        // case "undefined":
                        //     default:
                    }
                }
                return value
            },
            /**
             * 分析页面中的函数调用规则，返回需要调用到的函数从前往后的顺序
             * @params actionArray 初始没有数据
             * @params jsonStr 需要分析的页面/方法
             */
            analysePageAction(actionArray, jsonStr) {
                let regOpenAction = /"type":.*"open-action",/;
                let regAction = /"action":.*"([a-zA-z0-9_.]*)",/;
                if (!regOpenAction.test(jsonStr) || !regAction.test(jsonStr)) return actionArray
                // 找到action的内容
                let match = jsonStr.match(regAction);
                if (match.length != 2) return actionArray
                if (this.emptyObject(this.JsonPack[match[1]])) return actionArray
                actionArray.push(match[1]) // 从前往后放入
                return this.analysePageAction(actionArray, JSON.stringify(this.JsonPack[match[1]]))
            },
            /**
             * 将页面的变量挂载到变量库中，并判断数据内容，设置初始值
             * @param name 页面/行为名称
             */
            setDataDefaultValueWithType(name) {
                let element = this.JsonPack[name];
                // this.variableLibrary[name] = {
                //     "$params": {}, // 默认参数 这不不能被重置
                // }
                if (!this.emptyObject(element.data)) {
                    for (let dataItem in element.data) {
                        let item = element.data[dataItem];
                        // 1 判断内容的类型
                        // if (typeof item == 'string') { // 设置默认值
                        //     this.variableLibrary[name][dataItem] = item
                        // }
                        // 转换成我们定义的数据格式
                        this.variableLibrary[name][dataItem] = {
                            "_path__": `#variableLibrary.${name}.${dataItem}`,
                            "_val": item
                        }
                        // if (typeof item == 'object') { // 设置类型和默认值// 还是直接设置默认值吧
                        //     if (item.type) { // type不为空，就根据type设置默认值
                        //         switch (item.type) {
                        //             case 'string':
                        //                 this.variableLibrary[name][dataItem] = '';
                        //                 break;
                        //             case 'number':
                        //                 this.variableLibrary[name][dataItem] = 0;
                        //                 break;
                        //             case 'object':
                        //                 this.variableLibrary[name][dataItem] = {};
                        //                 break;
                        //             case 'bool':
                        //             case 'boolean':
                        //                 this.variableLibrary[name][dataItem] = false;
                        //                 break;
                        //             default:
                        //                 this.variableLibrary[name][dataItem] = null;
                        //         }
                        //     }
                        //     // 2 判断是否有初始值
                        //     if (item.value) {// value不为空，就直接设置为value
                        //         this.variableLibrary[name][dataItem] = item.value;
                        //     }
                        // }

                    }
                }
            },
            /**
             * 根据内容创建页面，然后挂载到pages下面
             * @param pageName
             */
            createPage(pageName) {
                const page = this.JsonPack[pageName];
                let pageTitle = page.title || pageName;
                // 1 挂载在 pages 下面
                this.pages[pageName] = null
                // 2 读取 form 节点
                const pageForm = page["form"];
                if (!this.emptyObject(pageForm)) {

                    // 1 生成page
                    // 1.1 生成title
                    let title = (<div></div>)
                    if (typeof pageTitle != "undefined") {
                        title = (
                            <div class="title-box text-center column-2">
                                <h5>{pageTitle}</h5>
                            </div>
                        )
                    }

                    if (this.emptyObject(pageForm['children'])) {
                        return;
                    }
                    const children = pageForm['children']
                    // 1.2 form 是否是真的表单是否存在 ，是否需要挂载 提交函数
                    const submit = pageForm['submit'];
                    let submitAction; // 生成提交node
                    let submitNode = (<div></div>); // 生成提交node
                    // 提交方法，跟异步的success是一样的
                    if (submit && !this.emptyObject(submit)) {
                        submitAction = this.analyseBehavior(submit, pageName);
                        // 3 生成提交节点
                        submitNode = (
                            <a-form-model-item class="text-center">
                                <a-button type="primary" onClick={submitAction}>提交</a-button>
                            </a-form-model-item>
                        )
                        // 没有action直接跳出
                        let action = submit['action'];
                        if (action) {
                            // 1 判断action是指向一个action还是直接写在里面
                            // 如果action下面的是string，就代表是使用了函数，
                            // 如果直接是object ，判断里面的type是否是action
                            let actionName;
                            if (typeof action == 'string') {
                                // 直接赋值在submit中
                                actionName = action
                            }
                            if (typeof action == 'object') {
                                // 需要生成action
                                actionName = `${pageName}_submit`;
                                this.createAction(action, actionName, pageName)
                                submit['action'] = actionName
                            }

                            // submitAction = this.methodsLibrary[actionName]
                            // // 2 处理参数传递
                            // let submitSetParams = submit['setparams'];
                            // if (!this.emptyObject(submitSetParams)) {
                            //     for (let paramsName in submitSetParams) {
                            //         // 如果带着 ${}, 代表时变量，如果没有，则代表是常量
                            //         // 赋值给action的参数域
                            //         this.variableLibrary[actionName]['$params'][paramsName] =
                            //             this.analyseValue(submitSetParams[paramsName], pageName)
                            //     }
                            // }


                        }
                    }

                    // 开始渲染form
                    // 需要从下往上渲染
                    let nodes = [];
                    for (let nodeName in children) {
                        nodes.push(this.createNode(nodeName, children[nodeName], pageName))
                    }

                    // 组合节点
                    this.pages[pageName] = (
                        <div>
                            {title}
                            <a-form-model label-col={this.labelCol} wrapper-col={this.wrapperCol}>
                                {nodes.map(item => {
                                    return (<div>{item}</div>);
                                })}
                                {submitNode}
                            </a-form-model>
                        </div>
                    )
                }
            },
            /**
             * 创建节点
             * @param nodeName 节点名称，确定类型
             * @param node 节点内容
             * @param pageName 所属页面，有需要设置数据的内容
             */
            createNode(nodeName, node, pageName) {
                // 1 判断节点的类型 node['tag']
                // 2 生成节点
                // 2.1 生成节点jsx，配置内容
                const elementTag = node['tag'];
                if (!elementTag) return;
                let label = node['label'] || nodeName
                let value = this.analyseValue(node["value"], pageName) || null
                switch (elementTag) {
                    case "div": { // 这标签貌似没什么意义  xx: {tag:div, children: form}
                        return (
                            <div>{this.createNode()}</div>
                        )
                    }
                    case "textarea": {
                        const val = this.getVal(value);
                        return (
                            <a-form-model-item label={label}>
                                <a-input type="textarea" defaultValue={val}
                                         onChange={this.hangdleValueChange.bind(this, value)}/>
                            </a-form-model-item>
                        )
                    }
                    case "input-text": {

                        return (
                            <a-form-model-item label={label}>
                                <a-input type="text" defaultValue={this.getVal(value)}
                                         onChange={this.hangdleValueChange.bind(this, value)}
                                         placeholder={`请输入${label}`}/>
                            </a-form-model-item>
                        )
                    }
                    case "input-file": {
                        return (
                            <a-form-model-item label={label}>
                                <a-upload name="file" action={this.commonStr.uploadFileUrl}
                                          onChange={this.hangdleUploadChange.bind(this, value)}>
                                    <a-button>
                                        <a-icon type="upload"/>
                                        点击上传
                                    </a-button>
                                </a-upload>
                            </a-form-model-item>
                        )
                    }
                    case "text": {
                        return (
                            <a-form-model-item label={label}>
                                <p>{value}</p>
                            </a-form-model-item>
                        )
                    }
                    case "image": {
                        const val = this.getVal(value);
                        return (
                            <a-form-model-item label={label}>
                                <img src={val} alt={label} />
                            </a-form-model-item>
                        )
                    }
                }
            },
            /**
             * 根据内容创建提交表单的异步方法或者直接跳转，然后挂载到 methodsLibrary下面
             * @param action 行为内容体
             * @param actionName 行为名称
             * @param pageName 所属页面名称/行为名称
             */
            createAction(action, actionName, pageName) {
                if (typeof action != 'object') return;
                if (action['type'] != 'action') return;
                // 所有参数都在
                // 暂时只设置 异步提交的方法
                let actionUrl = action['url'];
                if (actionUrl) {
                    let method = action["method"] || "get";

                    // 处理成功和失败问题
                    let success = (res) => {
                    };
                    let error = (e) => {
                    };
                    if (typeof action['success'] != "undefined") {
                        success = this.analyseBehavior(action['success'], pageName)
                    }
                    if (typeof action['error'] != "undefined") {
                        error = this.analyseBehavior(action['error'], pageName)
                    }
                    // 创建方法
                    this.methodsLibrary[actionName] = () => {
                        // 取值
                        let params = {}
                        const urlparams = action['urlparams'];
                        if (urlparams && typeof urlparams == 'object' && !this.emptyObject(urlparams)) {
                            for (const urlparamsKey in urlparams) {
                                let paramsGet = this.analyseValue(urlparams[urlparamsKey], pageName) || null;
                                params[urlparamsKey] = this.getVal(paramsGet)
                            }
                        }
                        request(actionUrl, {
                            method: method,
                            data: params,
                        }).then(res => {
                            // 修改 $urlresult
                            this.variableLibrary[actionName]['$urlresult'] = res
                            if (res && res.code == 0) {
                                success(res.data)
                            } else {
                                error(res.msg)
                            }
                        }).catch(e => error(e))
                    }
                }
            },
            /**
             * 初始化被本组件内的数据，防止数据残余
             */
            initData() {
                this.JsonPack = {};
                this.defaultRender = null;
                this.variableLibrary = {};
                this.pages = {};
            },
            /**
             * 获取Json页面渲染数据
             */
            getJsonInit() {
                this.JsonPack = {
                    "index": "page1",
                    "page1": {
                        "type": "page",
                        "title": "黑眼圈检测",
                        "data": {
                            "sy": "输入症状描述",
                            "syy": "a",
                            "options": ["a", "b", "dsa"],
                            "pic": {}
                        },
                        "form": {
                            "children": {
                                "sy": {
                                    "tag": "input-text",
                                    "label": "症状",
                                    "value": "${sy}"  // $value 动态赋值
                                },
                                "syy": {
                                    "tag": "select",
                                    "options": "${options}",
                                    "label": "症状2",
                                    "value": "${syy}"  // $value 动态赋值
                                },
                                "pic": { // 提供一个默认的上传接口
                                    "tag": "input-file",
                                    "label": "图片",
                                    "value": "${pic}"
                                }
                            },
                            "submit": {
                                "type": "open-action",
                                "action": "action1",
                                "setparams": { // $setparams动态赋值 每个单位拥有 $getparams为默认数据
                                    "sy": "${sy}",  // 添加this.variableLibrary.pagexxx.sy
                                    "syy": "${syy}",  // 添加this.variableLibrary.pagexxx.sy
                                    "pic": "${pic}" // 添加this.variableLibrary.pagexxx.pic
                                }
                                // "type": "open-page",
                                // "page": "page2",
                                // "setparams": {
                                //     "res": "${sy}"
                                // }
                            }
                        }
                    },
                    "page2": {
                        "type": "page",
                        "title": "检测结果",
                        "form": {
                            "children": {
                                "txt": {
                                    "tag": "textarea",
                                    "label": "结果",
                                    "value": "${$params.res}"
                                },
                                "img": {
                                    "tag": "image",
                                    "label": "图片",
                                    "value": "${$params.img}"
                                }
                            }
                        }
                    },
                    "action1": {
                        "type": "action",
                        "url": "http://localhost:8080/test/test",
                        "method": "post",
                        "urlparams": { // setparams动态赋值 urlparams则直接赋死值
                            "sy": "${$params.sy}",
                            "file": "${$params.pic}"
                        },
                        "success": {
                            "type": "open-page",
                            "page": "page2",
                            "setparams": {
                                "res": "${$urlresult.msg}",
                                "img": "${$urlresult.success.fileget.url}",
                            }
                        }
                    },
                }
            },
            /**
             * 判断对象是否为空
             * @param obj 对象
             */
            emptyObject(obj) {
                if (typeof obj != "object") return false; // 首先你得是对象
                for (var i in obj) {
                    return false
                }
                return true
            },
            /**
             * 上传调用的接口，有则调用，没有则算了
             * @param info this 包含file和 fileList两个参数
             * @param value 这个参数在jsx中定义好，就是当前上传节点绑定的值
             */
            hangdleUploadChange(value, info) {
                let valPath = this
                switch (typeof value) {
                    case "string":
                    case "number":
                    case "boolean":
                    case "bigint":
                    case "symbol":
                    case "function":
                        value = info.fileList;
                        break
                    case "object": {
                        // 转成string 判断 有没有 val
                        let jsonStr = JSON.stringify(value);
                        if (jsonStr.indexOf("_val") > -1 && jsonStr.indexOf("_path__") > -1 ) { // 是我们自己定义的类型
                            let path = value['_path__'];
                            if (path == ".") {
                                value = value["_val"]
                            }
                            if (path.indexOf('#') == 0 && jsonStr.indexOf(".") > -1) {
                                // 解析path
                                path.replace("#", "").split('.').forEach(i => {
                                    if (valPath[i]) {
                                        valPath = valPath[i]
                                    } else { // 没有路径怎么办……报错？
                                        valPath[i] = {}
                                        valPath = valPath[i]
                                    }
                                })
                                valPath["_val"] = info.fileList
                            }
                        } else { // 不是
                            value = info.file
                        }
                    }
                        break;
                }
                // if (info.file.status !== 'uploading') {
                // }
                // if (info.file.status === 'done') {
                //     this.$message.success(`${info.file.name} file uploaded successfully`);
                // } else if (info.file.status === 'error') {
                //     this.$message.error(`${info.file.name} file upload failed.`);
                // }
            },
            /**
             * 监测变动事件，并将新的数据写入到value中，写入value不需要改变地址
             * @param value this 包含file和 fileList两个参数
             * @param event 这个参数在jsx中定义好，就是当前上传节点绑定的值
             */
            hangdleValueChange(value, event) {
                let valPath = this
                switch (typeof value) {
                    case "string":
                    case "number":
                    case "boolean":
                    case "bigint":
                    case "symbol":
                    case "function":
                        value = event.srcElement.value;
                        break
                    case "object": {
                        // 转成string 判断 有没有 val
                        let jsonStr = JSON.stringify(value);
                        if (jsonStr.indexOf("_path__") > -1) { // 是我们自己定义的类型
                            let path = value['_path__'];
                            if (path == ".") {
                                value = value["_val"]
                            }
                            if (path.indexOf('#') == 0 && jsonStr.indexOf(".") > -1) {
                                // 解析path
                                path.replace("#", "").split('.').forEach(i => {
                                    if (typeof valPath == "object" && typeof valPath[i] != "undefined") {
                                        valPath = valPath[i]
                                    } else { // 没有路径怎么办……报错？
                                        valPath[i] = {}
                                        valPath = valPath[i]
                                    }
                                })
                                valPath["_val"] = event.srcElement.value
                            }
                        } else { // 不是
                            value = event.srcElement.value
                        }
                    }
                        break;
                }
            },
            /**
             * 模拟重写获取数据的方法，获取需要根据地址获取数据
             * @param value
             */
            getVal(value) {
                let valPath = this
                switch (typeof value) {
                    case "string":
                    case "number":
                    case "boolean":
                    case "bigint":
                    case "symbol":
                    case "function":
                        return value
                    case "object": {
                        // 转成string 判断 有没有 val
                        let jsonStr = JSON.stringify(value);
                        if (jsonStr.indexOf("_path__") > -1 ) { // 是我们自己定义的类型
                            // 判断 _path__
                            let path = value['_path__'];
                            if (path == ".") {
                                return value["_val"]
                            }
                            if (path.indexOf('#') == 0 && jsonStr.indexOf(".") > -1) {
                                // 解析path
                                path.replace("#", "").split('.').forEach(i => {
                                    if (i == "sy") {
                                    }
                                    if (typeof valPath == "object" && typeof valPath[i] != "undefined") {
                                        valPath = valPath[i]
                                    }
                                    // else { // 没有路径怎么办……报错？
                                    //     valPath[i] = {}
                                    //     valPath = valPath[i]
                                    // }
                                })

                                // 接下来就要判断数据问题了
                                if (JSON.stringify(value).indexOf("_val") > -1) {
                                    return valPath["_val"] // 这就直接取到值了?
                                } else {
                                    return this.getVal(valPath)
                                }
                            }

                        } else { // 不是
                            return value
                        }
                    }
                }
                return null
            },
            // 测试用
            test() {
            }
        }
    }
</script>

<style lang="less">
    .body-box {

        .title-box {
            font-size: 30px;
        }

        @dire: center, left, right;
        .for-text-dire(@direData, @i: 1) when (@i =< length(@direData)) {
            @dire: extract(@direData, @i);
            .text-@{dire} {
                text-align: (@dire);
            }
            .for-text-dire(@direData, (@i + 1));
        }
        .for-text-dire(@dire);

        @size: 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100;
        .for(@sizeData, @i: 1) when (@i =< length(@sizeData)) {
            @size: extract(@sizeData, @i);
            .ml-@{size} {
                margin-left: (@size * 1px);
            }
            .mr-@{size} {
                margin-right: (@size * 1px);
            }
            .mt-@{size} {
                margin-top: (@size * 1px);
            }
            .mb-@{size} {
                margin-bottom: (@size * 1px);
            }

            .pl-@{size} {
                padding-left: (@size * 1px);
            }
            .pr-@{size} {
                padding-right: (@size * 1px);
            }
            .pt-@{size} {
                padding-top: (@size * 1px);
            }
            .pb-@{size} {
                padding-bottop: (@size * 1px);
            }

            .for(@sizeData, (@i + 1));
        }
        .for(@size);

    }
</style>


































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DoctorDesc extends Vue {
  @Prop() private content!: Array<any>;
  @Prop() private serviceFeaturePhoto!: String;
  currentImage: Number = 0;

  get _content() {
    return this.content
      ? this.content.sort((a, b) => a.orderNum - b.orderNum)
      : [];
  }

  handleMouseOver(index: Number) {
    this.currentImage = index;
  }
  handleMouseOut() {
    this.currentImage = 0;
  }
  sliceArray(arr, start, end) {
    if (arr) return arr.slice(start, end);
  }
}

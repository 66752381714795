




























    import {Component, Vue} from "vue-property-decorator";
    import {message, Select, Table} from "ant-design-vue";
    import {baseUrl, request} from "@/services/http";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";

    Vue.use(Table);
Vue.use(Select);

@Component({
  components: {PersonalHeader},
})
export default class extends Vue {
  columns = [
    {
      title: "服务订单号",
      dataIndex: "seriesNum",
      align: "center",
    },
    {
      title: "消费金额",
      dataIndex: "actualAmount",
      align: "center",
    },
    {
      title: "支付方式",
      dataIndex: "purchaseMethod",
      align: "center",
    },
    {
      title: "消费时间",
      dataIndex: "createTime",
      align: "center",
    },
  ];
  data = [];

  payStyle = [
    {
      index: 141,
      name: "支付宝",
    },
    {
      index: 142,
      name: "微信",
    },
    {
      index: 143,
      name: "银联",
    },
    {
      index: 403,
      name: "代币",
    },
    {
      index: 404,
      name: "代币+支付宝",
    },
    {
      index: 405,
      name: "代币+微信",
    },
    {
      index: 406,
      name: "代币+银联",
    },
  ];

  serviceDetail: any = []; // 服务消费明细列表

  async created() {
    await this.getServiceDetail();
  }

  // 格式化支付方式字典项
  formatPayStyle(value: any) {
    const arr = this.payStyle.filter((item: any, index: any) => {
      return item.index == value;
    });
    return arr[0].name;
  }

  // 查询消费明细
  getServiceDetail() {
    request(`${baseUrl}/api/v1/service-orders/payment/list`, {
      params: { pageSize: 9999, pageIndex: 1 },
    })
      // getServiceDetail({
      //   type: 2
      // })
      .then((res: any) => {
        if (res.code === 0) {
          // 处理数据
          this.data = res.data.list;
        } else {
          message.error(`查询消费明细失败，错误原因：${res.msg}`);
        }
      });
  }
}

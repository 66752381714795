






































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";

Vue.use(Rate);

    @Component({
        props: {
            infoTitle: String,
            desc: String,
            price: Number,
            rate: Number,
            saleCount: Number,
            location: String,
            serviceId: String,
            cardImage: String,
            storeName: String,
            thirdServiceName: String,
            thirdServiceId: String,
            orgId: String,
            parentOrgId: String,
            serviceContent: String,
            ifParentOrg: Number,
            telemedicineReservation: Boolean,
        },
    })
    export default class InfoCard2 extends Vue {
        // @Prop() private msg!: string
        handleBuy() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                // this.$router.push("/serviceOrder");
                this.$router.push({
                    path: "/serviceOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        amount: `1`,
                        // orderImage: this.$props.cardImage,
                        // orderTitle: this.$props.infoTitle,
                        // orderPrice: this.$props.price,
                        // orderArea: this.$props.location
                    },
                });
            } else {
                const {href} = this.$router.resolve({
                    path: "/serviceOrder",
                    query: {
                        serviceId: this.$props.serviceId,
                        amount: "1",
                    },
                });
                sessionStorage.setItem("pay", href);
                this.$router.push("/login");
            }
        }

        //远程医疗查看详情
        particulars(serviceId) {
            sessionStorage.setItem("serviceId", serviceId);
            this.$router.push(`/cookInHome/${serviceId}`);
        }

        toDetail() {
            this.$router.push(`/cookInHome/${this.$props.serviceId}`);
        }

        gotopage(serviceId: string) {
            sessionStorage.setItem("serviceId", serviceId);
            this.$router.push(`/cookInHome/${serviceId}`);
        }

        servieItemDetailAll = false;

        mounted() {
            if (location.href.includes("servieItemDetailAll"))
                this.servieItemDetailAll = true;
        }

        saksjaksna123() {
            if (this.$props.ifParentOrg)
                this.$router.push("/orgDetail/" + this.$props.orgId);
            else
                this.$router.push(
                    `/servieItemDetail?title=${
                        this.$props.thirdServiceName.includes("服务")
                            ? this.$props.thirdServiceName
                            : this.$props.thirdServiceName + "服务"
                    }&orgId=${this.$props.orgId}&categoryId=${this.$props.thirdServiceId}`
                );
        }
    }

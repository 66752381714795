







































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class Selling extends Vue {
    @Prop() data: any;
    ulLeft = 0.05;

    mounted() {
        this.totalPage = Math.ceil(this.data.commodities.length / 4);
        if (this.totalPage == 1) this.leftCan = this.rightCan = false;
        this.runInv();
    }

    leftCan = true;
    rightCan = false;
    totalPage = 1;
    page = 1;
    timer:any = null

    // 移入
    enter() {
        clearInterval(this.timer)
    }

    //移出
    leave() {
        this.runInv()
    }

    //定时器
    runInv() {
        this.timer = setInterval(() => {
            if (this.page < this.totalPage) {
                this.ulLeft -= 10.46;
                this.page++;
                this.leftCan = this.page != this.totalPage;
                this.rightCan = this.page != 1;
            } else if (this.page > 1) {
                this.ulLeft = 0.05;
                this.page=1;
                this.rightCan = this.page != 1;
                this.leftCan = this.page != this.totalPage;
            }
        }, 5000)
    }

    leftTo() {
        clearInterval(this.timer)
        if (this.page < this.totalPage) {
            this.ulLeft -= 10.46;
            this.page++;
            this.leftCan = this.page != this.totalPage;
            this.rightCan = this.page != 1;
        }
    }

    rightTo() {
        clearInterval(this.timer)
        if (this.page > 1) {
            this.ulLeft += 10.46;
            this.page--;
            this.rightCan = this.page != 1;
            this.leftCan = this.page != this.totalPage;
        }
    }
}





























    import {Component, Vue} from "vue-property-decorator";

    @Component({
  props: {
    institutionName: String,
    institutionLocation: String,
    institutionPhone: String,
    subOrgId: String,
    allcontent: Object
  }
})
export default class InstitutionInfo extends Vue {
  // @Prop() private msg!: string
  jumpToOrgDetail() {
    this.$router.push({
      path: "/servieItemDetailAll",
      query: {
        orgId: this.$props.allcontent.subOrgId,
      },
    });
  }
  get _address() {
    const allcontent = this.$props.allcontent;
    const addressProvince = allcontent["addressProvince"] || "";
    const addressCity = allcontent["addressCity"] || "";
    const addressArea = allcontent["addressArea"] || "";
    const detailAddress = allcontent["detailAddress"] || "";
    const addressStreet = allcontent["addressStreet"] || "";

    return (
      addressProvince +
      addressCity +
      addressArea +
      addressStreet +
      detailAddress
    );
  }
  // lookDetail() {

  // }
}

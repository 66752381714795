<script>

export default {
  props: {
    me: {
      type: Object,
      default: function (){return {}}
    }
  },
  data() {
    return {
      backgroundImage: 'url(' + require('../svg/card.svg') + ')',
      searchIcon: require('../svg/search.svg')
    }
  },
  methods: {
    onKeyup (e) {
      this.$emit('search',e.target.value)
    }
  }
};
</script>

<template>
  <div class="card">
    <header :style="'background-image: ' + backgroundImage">
      <img class="avatar" width="34" height="34" :alt="me.name" :src="me.headIcon">
      <p class="name">{{me.name}}</p>
    </header>
    <footer>
      <div class="search">
        <img class="avatar"  width="14" height="14" :src="searchIcon">
        <input type="text" placeholder="搜索最近联系人" @keyup="onKeyup">
      </div>
    </footer>
  </div>
</template>

<style scoped lang="less">
.card {
  padding: 0px;
  height: 110px;

  header {
    text-align: center;
    height: 60px;
    line-height: 60px;
  }

  footer {
    margin: 0px;
    padding: 10px;
  }

  .avatar, .name {
    vertical-align: middle;
  }
  .avatar {
    border: 1px solid #E4E9F2;
    border-radius: 17px;
  }
  .name {
    display: inline-block;
    margin: 0 0 0 8px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 38px;
    color: #FFFFFF;
  }
  .search {
    font-size: 12px;
    color: #78849E;
    height: 30px;
    width: 100%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;
    outline: none;
    img {
      margin: 7px;
    }
    input {
      float: right;
      width: calc(100% - 30px);
      height: 28px;
      border: 0px;
      border-radius: 15px;
      outline: none;
    }
  }
}
</style>

























































































































































































    import {
        enjoyService,
        enjoyService1,
        enjoyService_token_id_RSA_encode,
        openService2Result,
        openService2Status
    } from "@/services/api";
    import {Component, Vue} from "vue-property-decorator";
    import {Button, Form, Input, message, Radio, Select, Steps, Table,} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ServiceProcessSteps from "./classServicesteps.vue";
    import MyStepBar from "@/components/Personal/MyStepBar.vue";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import getBase62x from "../../../../../src/Base62x.class.js"
    //import {test12} from "../../../../../static/test.js"
    import {baseUrl, request} from "@/services/http";

    Vue.use(Steps);
    Vue.use(Table);
    Vue.use(Form);
    Vue.use(Radio);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Button);
    //Vue.use(Base62x);

    @Component({
        components: {
            PersonalHeader,
            ServiceProcessSteps,
            Breadcrumb,
            MyStepBar
        },
    })
    export default class extends Vue {
        visible = false;
        fileNow = "";
        status = 0;
        userInfo: any = {};
        curStatus = {
            step1: false,
            step2: false,
        };
        result = "";
        previewVisible = false;
        previewImage = "";
        fileList: any = [];
        //面包屑
        breadcrumb = [
            {
                name: "个人中心",
                path: "/personal/myHomePage",
                significance: 0

            },
            {
                name: "我的服务",
                path: "/personal/myService",
                significance: 0
            },
            {
                name: "服务开启",
                path: "/personal/classServiceOne",
                significance: 1
            },
        ]
        stepList = [
            {
                name: "提交资料/影像",
                time: "",
                img: require('@/assets/images/homeImg/flow-1.png'),
            },
            {
                name: "反馈结果",
                time: "",
                img: require('@/assets/images/homeImg/flow-4.png'),
            },
        ];
        leftForm: any;
        rightForm: any;

        created() {
            this.findProcessTime();
            this.getNamebyRelationId();
            this.getserviceTypeForNextStep();
            //test12();
        }

        handleRemove(file) {
            const index = this.fileList.indexOf(file);
            const newFileList = this.fileList.slice();
            newFileList.splice(index, 1);
            this.fileList = newFileList;
        }

        beforeUpload(file) {
            // ...this.fileList,
            this.fileList = [file];
            return false;
        }

        handleCancel() {
            this.previewVisible = false;
        }

        async handlePreview(file: any) {
            this.fileNow = file;
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        }

        handleChange({fileList}) {
            this.fileList = fileList;
        }

        formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 9},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 15},
            },
        };

        data = [
            {
                service: "上门做饭",
                institution: "天津大学养老机构",
                serviceNum: "102839430",
                price: 100,
                tips: 0,
            },
        ];

        beforeMounted() {
        }

        handleTypeChange() {
        }

        handleLeftSubmit() {
        }

        handleRightSubmit() {
        }

        serviceName = "";
        NewUrl = '';
        token_RSA = '';
        relationId_RSA = '';
        Type1ForNextStep = false;//上传文件字符串结果
        Type2ForNextStep = false;//上传文件json结果
        Type3ForNextStep = false;//跳转网页
        Type4ForNextStep = false;//跳转SDK组件
        Type5ForNextStep = false;//枣庄学院短信诈骗判断
        Type6ForNextStep = false;//跳转网页得到结果
        Type7ForNextStep = false;//跳转网页得到结果
        Type9ForNextStep = false;//跳转网页得到结果
        Type14ForNextStep = false; //河北大学获取密钥后跳转
        Type15ForNextStep = false; //河北大学心电服务
        Type12ForNextStep = false;//跳转网页得到结果 平安
        Type6resShow = false;

        //这里是枣庄学院短信诈骗的数据
        mobile = "";
        message = "";
        //河北大学心电
        HBXDmsg = {};
        HBXDid = "";

        getNamebyRelationId() {
            request(`${baseUrl}/api/v1/api-services/zaixian/service-name`, {
                method: "get",
                params: {
                    relationId: this.$route.query.relationId,
                },
            }).then((res: any) => {
                this.serviceName = res.data.resultInfo;
            });
        }

        getserviceTypeForNextStep() {
            request(`${baseUrl}/api/v1/api-services/zaixian/serviceType_forNextStep`, {
                method: "get",
                params: {
                    relationId: this.$route.query.relationId,
                },
            }).then((res: any) => {

                switch (parseInt(res.data.resultInfo)) {
                    case 1:
                        this.Type1ForNextStep = true;
                        break;
                    case 2:
                        this.Type2ForNextStep = true;
                        break;
                    case 3:
                        this.Type3ForNextStep = true;
                        break;
                    case 4:
                        this.Type4ForNextStep = true;
                        break;
                    case 5:
                        this.Type5ForNextStep = true;
                        break;
                    case 6:
                        this.Type6ForNextStep = true;
                        break;
                    case 7:
                        this.Type7ForNextStep = true;
                        break;
                    case 9:
                        this.Type9ForNextStep = true;
                        break;
                    case 12:
                        this.Type12ForNextStep = true;
                        break;
                    case 14:
                        this.Type14ForNextStep = true;
                        break;
                    case 15:
                        this.Type15ForNextStep = true;
                        break;
                }
            });
        }


        findProcessTime() {
            //查看2类服务状态
            openService2Status({
                relationId: this.$route.query.relationId,
            }).then((res) => {
                if (res.code === 0) {
                    let that: any = this;
                    (res as any).data.servingStatusList.forEach((el) => {
                        if (that.stepList[el.statusOrder - 1]) {
                            that.stepList[el.statusOrder - 1]["time"] = el.createTime || "";
                            that.stepList[el.statusOrder - 1].name = el.servingStatus;
                        } else {
                            that.stepList.push({
                                name: el.servingStatus,
                                time: el.createTime || "",
                            });
                        }
                    });
                    if (that.stepList[1].time === "") {
                        //反馈结果时间为空 则为提交状态
                        that.status = 0;
                        that.curStatus.step1 = true;
                    } else {
                        //反馈结果时间不为空 则为反馈结果状态
                        that.status = 1;
                        that.curStatus.step2 = true;
                        that.curStatus.step1 = false;
                        this.getResult();
                    }
                } else {
                    message.error("查询服务失败，请稍后重试！");
                }
            });
        }

        getResult() {
            openService2Result({
                relationId: this.$route.query.relationId,
            }).then((res) => {
                if (res.code === 0) {
                    if (this.Type3ForNextStep == true) {
                        this.result = "本次服务已完成，谢谢使用！"
                    } else {
                        this.result = res.data.resultInfo;
                    }
                } else {
                    message.error("查询服务失败，请稍后重试！");
                }
            });
        }

        setResShow() {
            this.Type6resShow = true;
        }

        setFile(file) {
            this.fileNow = file;
        }

        //开始检测
        startTesting() {
            let formData_enjoy = new FormData();
            formData_enjoy.append("relationId", this.$route.query.relationId);
            formData_enjoy.append("uploadFile", this.fileList[0]);
            if(this.fileList[0]!=undefined){
                this.visible = true;
                enjoyService(formData_enjoy).then((res) => {
                    this.visible = false;
                    this.findProcessTime();
                    this.curStatus.step2 = true;
                    this.curStatus.step1 = false;
                    this.$router.push({name: "反馈结果", query: {relationId: this.$route.query.relationId, res: res}});

                    return res;

                })
            }else{
                message.error("请先上传检测文件。");
            }




            // this.fileList.forEach((element) => {
            //   formData.append("uploadFile", element);
            // });
            // let params = {
            //   relationId: this.$route.query.relationId,
            //   uploadFile:this.fileList[0],
            // };
            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   this.visible = false;
            //   this.findProcessTime();
            //   this.curStatus.step2 = true;
            //   this.curStatus.step1 = false;
            //   this.$router.push({name:"反馈结果",query: { relationId: this.$route.query.relationId,res:res}});
            //
            //   return res;
            // });
            // openService2Enjoy(formData).then(res => {
            //   if (res.code === 0) {
            //     router.go(0);
            //   } else {
            //     message.error("检测失败，请稍后重试");
            //   }
            // });
        }

        //开始检测跳转网页类型
        starTesting_Type3() {


            let formData = new FormData();
            formData.append("relationId", this.$route.query.relationId);
            this.visible = true;

            let params = {
                relationId: this.$route.query.relationId,

            };

            enjoyService1(params).then((res) => {
                this.visible = false;
                this.findProcessTime();
                this.NewUrl = String(res.data.resultInfo);
                let temp_window: any = window.open('_blank');
                temp_window.location = this.NewUrl;
            })


            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   this.visible = false;
            //   this.findProcessTime();
            //   this.NewUrl = String(res.data.resultInfo);
            //   let temp_window:any =  window.open('_blank');
            //   temp_window.location=this.NewUrl;
            //
            //   //window.location.href = this.NewUrl;
            // });
        }

        //开始跳转SDK组件
        starTesting_Type4() {
            let formData = new FormData();
            formData.append("relationId", this.$route.query.relationId);
            this.visible = true;

            let params = {
                relationId: this.$route.query.relationId,

            };

            enjoyService1(params).then((res) => {
                this.findProcessTime();
                var typeResult = String(res.data.resultInfo);
                this.$router.push({
                    name: "挂号网影像类SDK",
                    query: {relationId: this.$route.query.relationId, typeResult: typeResult}
                });
            })

            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   //this.visible = false;
            //   this.findProcessTime();
            //   var typeResult = String(res.data.resultInfo);
            //   this.$router.push({name:"挂号网影像类SDK",query: { relationId: this.$route.query.relationId,typeResult:typeResult }});
            // });
        }

        //开始短信诈骗类检测
        starTesting_Type5() {
            // if(this.mobile==null||this.mobile==null)
            // {
            // }
            // else{
            let formData = new FormData();
            formData.append("relationId", this.$route.query.relationId);
            //formData.append("mobile", this.mobile);
            formData.append("message", this.message);
            this.visible = true;
            let params = {
                relationId: this.$route.query.relationId,
                //mobile:this.mobile,
                message: this.message,
            };
            enjoyService1(params).then((res) => {
                this.visible = false;
                this.findProcessTime();
                this.curStatus.step2 = true;
                this.curStatus.step1 = false;
                this.$router.push({name: "反馈结果", query: {relationId: this.$route.query.relationId, res: res}});
            })


            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   this.visible = false;
            //   this.findProcessTime();
            //   this.curStatus.step2 = true;
            //   this.curStatus.step1 = false;
            //   this.$router.push({name:"反馈结果",query: { relationId: this.$route.query.relationId,res:res}});
            // });
            // }
        }

        //跳转网页得到结果
        starTesting_Type6() {

            //得到当前token和relationid
            this.userInfo = localStorage.getItem("userInfo");
            var token_web = JSON.parse(this.userInfo).token;
            var relationId_web = this.$route.query.relationId;

            //调用接口 完成RSA加密
            let formData = new FormData();
            formData.append("relationId", relationId_web);
            formData.append("token", token_web);

            let params = {
                relationId: this.$route.query.relationId,
                token: token_web,
            };


            enjoyService_token_id_RSA_encode(formData).then((res) => {
                this.token_RSA = String(res.token);
                this.relationId_RSA = String(res.relationId);
                var result_token = getBase62x.encode(this.token_RSA);//base62x加密去特殊字符
                var jiemi_token = getBase62x.decode(result_token);//解密
                var result_id = getBase62x.encode(this.relationId_RSA); //加密
                var jiemi_id = getBase62x.decode(result_id); //解密
                let params1 = {
                    relationId: this.$route.query.relationId,
                };
                enjoyService1(params1).then((res) => {
                    this.visible = false;
                    this.findProcessTime();
                    this.NewUrl = String(res.data.resultInfo);
                    // let temp_window:any =  window.open('_blank');
                    // temp_window.location=this.NewUrl;
                    //window.location.href = this.NewUrl;
                    var url3 = this.NewUrl;
                    var url;
                    if (url3.indexOf("#")) {
                        var url1 = url3.split("#")[0];
                        var url2 = url3.split("#")[1];
                        url = url1 + "?token=" + result_token + "&id=" + result_id + "#" + url2;
                    } else {
                        url = url3 + "?token=" + result_token + "&id=" + result_id;
                    }

                    let temp_window: any = window.open('_blank');
                    this.NewUrl = url;
                    temp_window.location = this.NewUrl;
                })
            })


            // request(`${baseUrl}/api/v1/api-services/zaixian/token_id_RSA_encode `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   this.token_RSA = String(res.token);
            //   this.relationId_RSA = String(res.relationId);
            //   var result_token = getBase62x.encode(this.token_RSA);//base62x加密去特殊字符
            //   var jiemi_token = getBase62x.decode(result_token);//解密
            //   var result_id = getBase62x.encode(this.relationId_RSA); //加密
            //   var jiemi_id = getBase62x.decode(result_id); //解密
            //   //调用接口 得到跳转网址
            //   let formData1 = new FormData();
            //   formData1.append("relationId", this.$route.query.relationId);
            //   this.visible = true;
            //   request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
            //     method: "POST",
            //     data: formData1,
            //   }).then((res:any) => {
            //     this.visible = false;
            //     this.findProcessTime();
            //     this.NewUrl = String(res.data.resultInfo);
            //     // let temp_window:any =  window.open('_blank');
            //     // temp_window.location=this.NewUrl;
            //     //window.location.href = this.NewUrl;
            //     var url3 =this.NewUrl;
            //     var url;
            //     if(url3.indexOf("#"))
            //     {
            //       var url1 = url3.split("#")[0];
            //       var url2 = url3.split("#")[1];
            //       url = url1 + "?token=" + result_token + "&id=" + result_id +"#" + url2;
            //     }
            //     else{
            //       url = url3 + "?token=" + result_token + "&id=" + result_id;
            //     }
            //
            //     let temp_window:any =  window.open('_blank');
            //     this.NewUrl = url;
            //     temp_window.location=this.NewUrl;
            //   });
            //
            // });


        }

        //易特科
        starTesting_Type7() {
            //得到当前relationid
            let formData = new FormData();
            formData.append("relationId", this.$route.query.relationId);
            this.visible = true;

            let params = {
                relationId: this.$route.query.relationId,
            };


            enjoyService1(params).then((res) => {
                this.findProcessTime();
                let url3 = String(res.data.resultInfo);
                let url1 = url3.split("@")[0];//输入网页
                let url2 = url3.split("@")[1];//结果网页
                let temp_window: any = window.open('_blank');
                let url_type7 = url1 + this.$route.query.relationId;
                this.NewUrl = url_type7;
                temp_window.location = this.NewUrl;
                this.$router.push({name: "反馈结果", query: {relationId: this.$route.query.relationId, url2: url2}});
            })

            // request (`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `,{
            //   method: "POST",
            //   data: formData,}).then((res:any)=> {});

            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1`, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   //this.visible = false;
            //   this.findProcessTime();
            //   let url3 = String(res.data.resultInfo);
            //   let url1 = url3.split("@")[0];//输入网页
            //   let url2 = url3.split("@")[1];//结果网页
            //   let temp_window:any =  window.open('_blank');
            //   let url_type7 = url1 + this.$route.query.relationId;
            //   this.NewUrl = url_type7;
            //   temp_window.location=this.NewUrl;
            //   this.$router.push({name:"反馈结果",query: { relationId: this.$route.query.relationId,url2:url2 }});
            // });
        }

        //网新体检
        starTesting_Type9() {
            //得到当前relationid
            let formData = new FormData();
            formData.append("relationId", this.$route.query.relationId);
            this.visible = true;
            let params = {
                relationId: this.$route.query.relationId,
            };
            enjoyService1(params).then((res) => {
                //this.visible = false;
                this.findProcessTime();
                this.userInfo = localStorage.getItem("userInfo");
                var token_1 = JSON.parse(this.userInfo).token;

                let url4 = String(res.data.resultInfo);
                let url1 = url4.split("@")[0];//入口1
                let url2 = url4.split("@")[1];//入口2
                let url3 = url4.split("@")[2];//入口3
                let temp_window: any = window.open('_blank');
                request(`${baseUrl}/api/v1/accounts/info`, {
                    method: "get",
                    params: {
                        token: token_1,
                    },
                }).then((res: any) => {
                    let url_type9 = url2 + "?mbl=" + res.data.mobile;
                    let url3_type9 = url3 + "?mbl=" + res.data.mobile;
                    this.NewUrl = url_type9;
                    temp_window.location = this.NewUrl;
                    this.$router.push({
                        name: "反馈结果",
                        query: {relationId: this.$route.query.relationId, url2: url3_type9,}
                    });
                });

            })


            // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
            //   method: "POST",
            //   data: formData,
            // }).then((res:any) => {
            //   //this.visible = false;
            //   this.findProcessTime();
            //   this.userInfo = localStorage.getItem("userInfo");
            //   var token_1 = JSON.parse(this.userInfo).token;
            //
            //   let url4 = String(res.data.resultInfo);
            //   let url1 = url4.split("@")[0];//入口1
            //   let url2 = url4.split("@")[1];//入口2
            //   let url3 = url4.split("@")[2];//入口3
            //   let temp_window:any =  window.open('_blank');
            //   request(`${baseUrl}/api/v1/accounts/info`, {
            //     method: "get",
            //     params: {
            //       token: token_1,
            //     },}).then((res:any) => {
            //     let url_type9 = url2 +"?mbl="+ res.data.mobile;
            //     let url3_type9 = url3 +"?mbl="+ res.data.mobile;
            //     this.NewUrl = url_type9;
            //     temp_window.location=this.NewUrl;
            //     this.$router.push({name:"反馈结果",query: { relationId: this.$route.query.relationId,url2:url3_type9, }});
            //   });
            //
            // });
        }

        //平安保单下下载
        starTesting_Type12() {
            //得到当前relationid
            request(`${baseUrl}/api/v1/api-services/zaixian/getPolicyNobyRelationID`, {
                method: "get",
                params: {
                    relationId: this.$route.query.relationId,
                },
            }).then((res: any) => {
                let policyNo = encodeURIComponent(res.data.resultInfo);
                window.location.href = "http://mcp-test-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo=" + policyNo;
                let url3_type12 = "http://mcp-test-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo=" + policyNo;
                this.$router.push({
                    name: "反馈结果",
                    query: {relationId: this.$route.query.relationId, url2: url3_type12,}
                });
            })
        }


        //河北大学
        starTesting_Type14() {
            //得到当前relationid
            this.visible = true;
            this.userInfo = localStorage.getItem("userInfo");
            var token_1 = JSON.parse(this.userInfo).token;
            request(`${baseUrl}/api/v1/accounts/info`, {
                method: "get",
                params: {
                    token: token_1,
                },
            }).then((res: any) => {
                this.mobile = res.data.mobile;
                let formData2 = new FormData();
                formData2.append("relationId", this.$route.query.relationId);
                formData2.append("mobile", res.data.mobile);
                let params = {
                    relationId: this.$route.query.relationId,
                    mobile: res.data.mobile,
                };

                enjoyService1(params).then((res) => {
                    let temp_window: any = window.open('_blank');
                    let url = "https://healthcare.hbu.cn/dLogin.xhtml?key=" + res.data.resultInfo + "&mobile=" + this.mobile;
                    temp_window.location = url;
                    this.$router.push({name: "反馈结果", query: {relationId: this.$route.query.relationId}});
                })


                // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
                //   method: "POST",
                //   data: "relationId",
                // }).then((res:any) => {
                //   let temp_window:any =  window.open('_blank');
                //   let url ="https://healthcare.hbu.cn/dLogin.xhtml?key="+res.data.resultInfo+"&mobile="+this.mobile;
                //   //temp_window.location=url;
                // })
            })
        }


//河北大学心电
        starTesting_Type15() {
            //得到当前relationid

            this.visible = true;
            this.userInfo = localStorage.getItem("userInfo");
            var token_1 = JSON.parse(this.userInfo).token;
            request(`${baseUrl}/api/v1/accounts/info`, {
                method: "get",
                params: {
                    token: token_1,
                },
            }).then((res: any) => {
                this.mobile = res.data.mobile;
                let formData2 = new FormData();
                formData2.append("relationId", this.$route.query.relationId);
                formData2.append("mobile", res.data.mobile);
                let params = {
                    relationId: this.$route.query.relationId,

                };

                enjoyService1(params).then((res1) => {
                    let url = res1.data.resultInfo
                    let HBXDform = new FormData();
                    HBXDform.append('file', this.fileList[0]);
                    HBXDform.append('lead', "1");
                    HBXDform.append('base', "0");
                    HBXDform.append('phonenum', this.mobile);
                    fetch(url, {
                        method: 'POST',
                        body: HBXDform,
                    })
                        .then(response => response.json())
                        .then(json => {
                                //this.HBXDmsg=json.data;
                                this.HBXDid = json.id;
                                if (json.id != null) {
                                    let url2 = 'http://www.vipmember.com.cn/ecg/getRealData?phonenum=' + res.data.mobile + '&id=' + json.id;
                                    this.$router.push({
                                        name: "反馈结果",
                                        query: {relationId: this.$route.query.relationId, HDXDurl: url2}
                                    });

                                }
                            }
                        )


                    //this.$router.push({name:"反馈结果",query: { relationId: this.$route.query.relationId }});
                })


                // request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1 `, {
                //   method: "POST",
                //   data: "relationId",
                // }).then((res:any) => {
                //   let temp_window:any =  window.open('_blank');
                //   let url ="https://healthcare.hbu.cn/dLogin.xhtml?key="+res.data.resultInfo+"&mobile="+this.mobile;
                //   //temp_window.location=url;
                // })
            })
        }


    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }


















































































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";

Vue.use(Rate);

@Component({
  props: {
    children: Array,
    orgItem: Object
  },
  components: {
    InstitutionInfo
  }
})
export default class YlmsItem extends Vue {
  // @Prop() private msg!: string
  tagBgColor = ["#8285ec", "#52b2ef", "#f87180"];
  allshow = false;
  listDate = 10;
  bgColor = '';
  showArr = [];
  colorlists = [
    'hsl(42, 48%, 54%)',
    'hsl(138, 24%, 48%)',
    'rgb(200, 138, 131)',
    'rgb(84, 221, 226)',
    'rgb(178, 199, 168)',
    'rgb(16, 195, 195)',
    'hsl(0, 21%, 68%)',
    'rgb(226, 166, 198)',
    'hsl(278, 17%, 66%)',
    'rgb(153, 199, 235)',
    'blueviolet'
  ]

  mounted() {
    if (this.$props.children) {
      this.showArr = this.$props.children.slice(0, 3);
    }
  }

  getColor() {
    let rgb: any = Math.random();
    let rgba: any = rgb * 256
    let r = parseInt(rgba)
    let g = parseInt(rgba)
    let b = parseInt(rgba)

    this.bgColor = `rgba(${r},${g},${b},0.3)`
  };

  handleJumpToOrgDetail() {
    if (this.$props.orgItem.orgId)
      this.$router.push(`/organizationDetail/${this.$props.orgItem.orgId}`);
  }
}

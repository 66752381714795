
































































    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {message} from "ant-design-vue";
    import {collectionsOrgService, fetchCollection} from "@/services/api";

    @Component({
  components: {},
  props: {
    coverPicture: String,
    storeName: String,
    orgTel: String,
    orgId: String,
    orgRemark: String,
    beloneTo: String,
    address: {
      type: String,
      default: "--",
    },
    orgServiceComplianceScore: Number,
    orgAttitudeScore: Number,
    orgEfficiencyScore: Number,
    ifParentOrg: Number,
    fatherId: String,
  },
})
export default class extends Vue {
  rateScore = 0;
  @Prop() private orgId!: "";
  @Watch("orgId", { immediate: true, deep: true })
  getorgId(newVal, oldVal) {
    this.isCollectionsOrgService(newVal);
  }
  jumpfather(ifParentOrg,fatherId,orgId) {
    if(ifParentOrg===true){
      this.$router.push(`/orgDetail/${orgId}`);
    }else{
      this.$router.push(`/orgDetail/${fatherId}`);
    }
  }
  toPage() {
    if (this.$props.fatherId != "") {
      this.$router.push(`/orgDetail/${this.$props.fatherId}`);
    } else {
      this.$router.push(`/orgDetail/${this.$props.orgId}`);
    }
  }
  isCollectionsOrgService(newVal) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem("userInfo")) {
        resolve("");
        return;
      }
      collectionsOrgService({
        itemId: newVal,
        itemType: 238,
      }).then((res) => {
        this.rateScore = res.data.isCollected === 0 ? 0 : 1;
        resolve(res);
      });
    });
  }
  fetchCollection(operation) {
    const params = {
      itemId: this.$props.orgId,
      itemType: 238,
    };
    return new Promise((resolve, reject) => {
      fetchCollection({ ...params, operation: operation }).then((res) => {
        resolve(res);
      });
    });
  }
  async changeScore(value: number) {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      // let operation = 0;
      // if (value === 0) {
      //   operation = 1;
      // } else {
      //   operation = 0;
      // }
      await this.fetchCollection(value);
      await this.isCollectionsOrgService(this.$props.orgId);
      this.rateScore === 0
        ? message.success("已取消收藏")
        : message.success("已成功收藏");
    } else {
      this.$router.push("/login");
    }
  }
}



























































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";
import Nav from "@/components/Nav.vue";
import OrderItem from "@/components/OrderItem.vue";
import reservationListItem from "@/components/reservationListItem.vue";
import Footer from "@/components/Footer.vue";
import personalHeaderList from "@/components/personalHeader.vue";
import CookInHomeHeader from "@/components/CookInHomeHeader.vue";
import reservationList from "@/components/reservationList.vue";
import Process from "@/components/Process.vue";
import beNull from "@/components/beNull.vue";

import ServiceComment from "@/components/ServiceComment.vue";
import {addBrowsingHistory, fetchDetailByServiceId, fetchServiceComments, sameTypeList,} from "@/services/api";

Vue.use(Rate);

@Component({
    components: {
        Nav,
        Footer,
        personalHeaderList,
        CookInHomeHeader,
        reservationList,
        Process,
        ServiceComment,
        OrderItem,
        reservationListItem,
        beNull
    },
})
export default class extends Vue {
    comments = [];
    lookMoreShow = false;
    commentScore = 0;
    pagination = {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    };
    serviceId = "";
    orgId = "";
    servicesList = [];
    serviceCategoryId = "";
    beNullList: any = {}
    commentsList: any[] = [];
    serviceOnShelfStatus: any = 0;
    serviceDetail: any = {
        serviceStyle: ''
    };
    showServiceScope: boolean = true;
    flow: any = [];
    p_first = {};
    loading: any = true

    get _servicesList() {
        if (this.servicesList) {
            return this.servicesList.length > 0;
        } else return false;
    }

    jumpAll(e) {
        e.preventDefault();
        this.$router.push({
            path: "/pensionFood",
            query: {
                serviceClassificationId: this.serviceDetail["serviceCategoryId"],
            },
        });
    }

    toPage() {
        this.$router.push(`/orgDetail/${this.serviceDetail.orgId}`);
        // if (
        //     this.serviceDetail.parentOrgId != "" &&
        //     this.serviceDetail.parentOrgId != null &&
        //     this.serviceDetail.parentOrgId != undefined
        // ) {
        //     this.$router.push(`/orgDetail/${this.serviceDetail.parentOrgId}`);
        // } else {
        //     this.$router.push(`/orgDetail/${this.serviceDetail.orgId}`);
        // }
    }

    mounted() {

        const userInfo = localStorage.getItem("userInfo");
        if (userInfo != null) {
            this.browsingHistoryList(this.$route.params.serviceId);
        }
        this.serviceId = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1,
            window.location.href.length
        );

        fetchDetailByServiceId({serviceId: this.serviceId}).then((res) => {
            if (res.code === 0) {
                this.serviceDetail = res.data.serviceInfo;
                if (this.serviceDetail.serviceStatus == 344) {
                    this.serviceOnShelfStatus = 1
                } else {
                    this.serviceOnShelfStatus = 2
                }
                this.loading = false
                this.showServiceScope = this.serviceDetail.serviceStyle.includes("上门服务");
                this.orgId = this.serviceDetail.orgId;
                this.serviceCategoryId = this.serviceDetail.serviceCategoryId;
                switch (this.serviceDetail.serviceStyleId) {
                    case "14":
                        this.flow = [
                            {title: '第一步 选择服务项目', details: '通过平台选购适合的服务项目'},
                            {title: '第二步 提交服务订单', details: '选择服务方式，填写服务地址及服务信息。'},
                            {title: '第三步 结算服务订单', details: '确认服务订单信息无误后，对服务订单进行结算付款。'},
                            {title: '第四步 开启服务项目', details: '在服务开启列表中进行开启服务的操作，保证服务正常使用。'},
                            {title: '第五步 评价服务及服务商', details: '服务完成后对服务以及服务商进行评价。'},
                        ]
                        break
                    case "15":
                        this.flow = [
                            {title: '第一步 选择服务项目', details: '通过平台选购适合的服务项目'},
                            {title: '第二步 提交服务订单', details: '确认服务人员信息，提交服务订单。'},
                            {title: '第三步 结算服务订单', details: '确认服务订单信息无误后，对服务订单进行结算付款。'},
                            {title: '第四步 服务人员上门服务', details: '等待服务人员与您联系，确认服务地址后服务人员上门服务。'},
                            {title: '第五步 评价服务及服务商', details: '服务完成后对服务以及服务商进行评价。'},
                        ]
                        break
                    case "16":
                        this.flow = [
                            {title: '第一步 选择服务项目', details: '通过平台选购适合的服务项目'},
                            {title: '第二步 提交服务订单', details: '选择要进行服务的门店信息，并确认服务订单。'},
                            {title: '第三步 结算服务订单', details: '付款完成后，通过短信获取核销码。'},
                            {title: '第四步 预约到店服务', details: '致电门店预约，到达门店凭借核销码进行服务。'},
                            {title: '第五步 评价服务及服务商', details: '服务完成后对服务以及服务商进行评价。'},
                        ]
                        break
                    case "17":
                        this.flow = [
                            {title: '第一步 选择服务项目', details: '通过平台选购适合的服务项目'},
                            {title: '第二步 提交服务订单', details: '选择服务方式，填写服务地址及服务信息。'},
                            {title: '第三步 结算服务订单', details: '确认服务订单信息无误后，对服务订单进行结算付款。'},
                            {title: '第四步 开启服务项目', details: '在服务开启列表中进行开启服务的操作，保证服务正常使用。'},
                            {title: '第五步 评价服务及服务商', details: '服务完成后对服务以及服务商进行评价。'},
                        ]
                        break
                    default:
                        this.flow = [
                            {title: '第一步 选择服务项目', details: '选择需要预约的服务，填写预约信息并提交。'},
                            {title: '第二步 提交服务订单', details: '确认服务人员信息，提交服务订单。'},
                            {title: '第三步 服务商提供服务', details: '等待服务商联系，确认预约信息以及需准备的资料。'},
                            {title: '第四步 等待报告反馈', details: '获取服务结果报告。'},
                            {title: '第五步 等待报告反馈', details: '报告反馈完成后对服务以及服务商进行评价。'},
                        ]

                }
                this.sameTypeList({
                    pageSize: 3,
                    pageIndex: 1,
                    // orgId: this.orgId,
                    thirdCategoryId: this.serviceCategoryId,
                    currentServiceId: this.serviceDetail.serviceId,
                });
            }
        });

        this.fetchServiceComments({
            ...this.pagination,
            serviceId: this.serviceId,
        });
        this.beNullList = {
            title: '很抱歉，您查看的服务不存在，可能已下架或者被转移。',
            list: [{data: "您可以:"}, {data: "很抱歉，您查看的服务不存在，可能已下架或者被转移。"}, {data: "2.在顶部搜索框重新输入关键词搜索"}, {data: "3.去其他的地方逛逛"}],
            path: '/personal/myCollection?type=1',
            pathName: '收藏的服务商'
        }
    }

    //获取服务ID，添加到浏览记录
    browsingHistoryList(value) {
        addBrowsingHistory({ifCommodity: 0, contentId: value});
    }


    sameTypeList(params) {
        sameTypeList(params).then((res) => {
            this.servicesList = res.data.serviceList;
            if (res.data.totalCount > 3) {
                this.lookMoreShow = !this.lookMoreShow;
            }
        });
    }

    fetchServiceComments(params) {
        fetchServiceComments({...params}).then((res) => {
            if (res.code === 0) {
                this.commentsList = [];
                res.data.comments.forEach((item: any) => this.commentsList.push(item));
                this.pagination.total = res.data.totalCount;
                this.commentScore = res.data.serviceOveralScore || 0;
            }
        });
    }

    onchange(page, pageSize) {
        window.scrollTo({top: 0});

        this.pagination.pageIndex = page;
        this.fetchServiceComments({
            ...this.pagination,
            serviceId: this.serviceId,
        });
    }

    onPageChange() {
    }

    lookMore() {
        this.sameTypeList({
            pageSize: 9999,
            pageIndex: 1,
            orgId: this.orgId,
            thirdCategoryId: this.serviceCategoryId,
            currentServiceId: this.serviceDetail.serviceId,
        });
    }

}

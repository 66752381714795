







































import { Component, Prop, Vue } from "vue-property-decorator";
import { Rate, message } from "ant-design-vue";

Vue.use(Rate);

@Component({
  props: {
    orderImage: String,
    orderTitle: String,
    orderPrcie: Number,
    saleCount: Number,
    orderdes: String,
    serviceId: String,
  },
})
export default class OrderItem extends Vue {
  // @Prop() private msg!: string
  handleViewDetail(serviceId: string) {
    sessionStorage.setItem("serviceId", serviceId);
    this.$router.push(`/cookInHome/${serviceId}`);
  }

  handleBuy() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.$router.push(
        "/serviceOrder?serviceId=" + this.$props.serviceId + "&amount=1"
      );
    } else {
      const href =
        "#/serviceOrder?serviceId=" + this.$props.serviceId + "&amount=1";
      sessionStorage.setItem("pay", href);
      this.$router.push("/login");
    }
  }
}

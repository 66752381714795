// const urlPath: string = "ws://admin.test.fytlun.com/api/v1/socket"
const urlPath: string = "wss://admin.fytlun.com/api/v1/socket"

// const urlPath: string = "ws://localhost:8084/api/v1/socket"

export class Socket {
    static socketEntity: WebSocket;
    static ifInit = false
    lockReconnect: boolean = false;//是否真正建立连接
    connectionStatus: boolean = false;//连接状态
    timeout: number = 58 * 1000;//58秒一次心跳
    timeoutObj: number = 0;//心跳心跳倒计时
    serverTimeoutObj: number = 0;//心跳倒计时
    timeoutNum: number = 0;//断开 重连倒计时
    public static get instance(): Socket {
        let socket: Socket
        if (!Socket.ifInit) {
            socket = new Socket()
            socket.init()
        }
        // @ts-ignore
        return socket;
    }

    //重新连接
    reconnect() {
        // console.log('关闭后首次执行')
        const that = this;
        // console.log(that.lockReconnect)
        if (that.lockReconnect) {
            return;
        }
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutNum && clearTimeout(that.timeoutNum);
        that.timeoutNum = setTimeout(_ => {
            //新连接
            let socket: Socket
            socket = new Socket();
            Socket.ifInit = false
            socket.init();
            // console.log('到达新连接，执行过init')
            that.lockReconnect = false;
        }, 800);
    }

    //重置心跳
    reset() {
        const that = this
        //清除时间
        clearTimeout(that.timeoutObj);
        clearTimeout(that.serverTimeoutObj);
        //重启心跳
        that.start();
    }

    //开启心跳
    start() {
        const that = this
        // console.log('开启心跳');
        that.timeoutObj && clearTimeout(that.timeoutObj);
        that.serverTimeoutObj && clearTimeout(that.serverTimeoutObj);
        that.timeoutObj = setTimeout(_ => {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            if (Socket.socketEntity.readyState == 1) {//如果连接正常
                Socket.socketEntity.send("heartCheck"); //这里可以自己跟后端约定
            } else {//否则重连
                that.reconnect();
            }
            that.serverTimeoutObj = setTimeout(_ => {
                //超时关闭
                Socket.socketEntity.close();
            }, that.timeout);
        }, that.timeout)
    }

    // WebSocket连接状态
    init() {
        if (typeof (WebSocket) === "undefined") {
            alert("您的浏览器不支持socket")
        } else {
            if (!Socket.ifInit) {
                try {
                    // 实例化socket
                    Socket.socketEntity = new WebSocket(urlPath);
                    // console.log( Socket.socketEntity)
                    // 监听socket连接
                    Socket.socketEntity.onopen = (e) => {
                        // console.log("socket连接成功", e);
                        this.start()
                        this.connectionStatus = true
                    }
                    // 监听socket错误信息
                    Socket.socketEntity.onerror = (e) => {
                        console.log("连接错误", e);
                    }

                    Socket.socketEntity.onclose = (ev: CloseEvent) => {
                        // console.log("socket已经关闭", ev);
                        this.connectionStatus = false
                        // this.reconnect()
                    }
                    Socket.ifInit = true
                } catch (e) {
                    console.log("连接失败", e);
                }
            }
        }
    }

    onSend(msg: string, callback) {
        if (Socket.socketEntity && Socket.socketEntity.readyState == 1 && callback) {
            // 监听socket消息
            Socket.socketEntity.onmessage = (msg: MessageEvent) => {
                let data = msg.data;
                if (data.indexOf('{"code":') == 0) {
                    let res = JSON.parse(data)
                    callback(res)
                }
            }
            Socket.socketEntity.send(msg)
        } else {
            this.reconnect();
            var remainTime = 1;
            const t = setInterval(() => {
                remainTime -= 1;
                if (remainTime == 0) {
                    clearInterval(t);
                    Socket.socketEntity.onmessage = (msg: MessageEvent) => {
                        let data = msg.data;
                        if (data.indexOf('{"code":') == 0) {
                            let res = JSON.parse(data)
                            callback(res)
                        }
                    }
                    Socket.socketEntity.send(msg)
                }
            }, 1000);
        }


    }

    onDestroy() {
        Socket.socketEntity.close()
    }
}





























































































    import {Component, Vue} from "vue-property-decorator";
    import {Checkbox, Icon, Input, List, message, Tabs, Tooltip} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ReservationTrashItem from "@/components/Personal/MyReservation/ReservationTrashItem.vue";
    import {State} from "vuex-class";
    import {
        reservationOrderDropList,
        reservationOrderList,
        reservationRecyclerDelete,
        reservationRecyclerReduction
    } from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
  components: {
    PersonalHeader,
    ReservationTrashItem
  }
})
export default class extends Vue {
  @State allReservationStatus;
  isAllCheck: Boolean = false;
  deleteModel: Boolean = false;
  serviceOrderInfoList: Array<any> = [];
  pagination = {
    total: 0,
    pageSize: 1000,
    pageIndex: 1
  };
  data: any = [];
  created() {
    this.getList();
  }
  jumpAllyuyue(evt) {
    evt.preventDefault();
    this.$emit("jumpAllyuyue");
  }
  getList() {
    (this as any).data = [];
    reservationOrderList({
      ...this.pagination,
      status: 6
    }).then(res => {
      let data = res.data;
      this.pagination.total = data.totalCount;
      data.serviceOrderInfos.forEach((el, i) => {
        (this as any).data.push({
          ischeck: false,
          id: i,
          ...el
        });
      });
      this.pagination.pageSize = data.pageSize;
    });
  }
  onChange(e) {
    this.isAllCheck = !this.isAllCheck;
    if (this.isAllCheck) {
      this.serviceOrderInfoList = [];
      (this as any).data.forEach(el => {
        el.ischeck = true;
        this.serviceOrderInfoList.push({
          reservationId: el.reservationId
        });
      });
    } else {
      let data = (this as any).data.filter(el => el.ischeck === true);
      if (data.length === this.data.length) {
        this.serviceOrderInfoList = [];
        (this as any).data.forEach(el => {
          el.ischeck = false;
        });
      }
    }
  }
  getOrderInfoList(obj) {
    this.data[obj.id].ischeck = true;
    this.serviceOrderInfoList.push({ reservationId: obj.reservationId });
    if (this.serviceOrderInfoList.length == this.data.length) {
      this.isAllCheck = true;
    } else {
      this.isAllCheck = false;
    }
  }
  deleteOrderInfoList(obj) {
    this.data[obj.id].ischeck = false;
    let newArr: any = [];
    for (let i = 0; i < this.serviceOrderInfoList.length; i++) {
      let newObj: any = JSON.parse(
        JSON.stringify(this.serviceOrderInfoList[i])
      );
      newArr.push(newObj);
    }
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].reservationId === obj.reservationId) {
        newArr.splice(i, 1);
      }
    }
    this.serviceOrderInfoList = newArr;
    this.isAllCheck = false;
  }
  singleDelete(id) {
    this.serviceOrderInfoList = [];
    this.serviceOrderInfoList.push({ reservationId: id });
    this.deleteByQueryEvent();
  }
  singleHuanYuan(id) {
    this.serviceOrderInfoList = [];
    this.serviceOrderInfoList.push({ reservationId: id });
    this.huanyuanByQueryEvent();
  }
  comfrimDelete() {
    this.deleteByQueryEvent();
    this.deleteModel = false;
  }
  deleteByQueryEvent() {
    reservationRecyclerDelete({
      reservationIdList: this.serviceOrderInfoList
    }).then(res => {
      if (res.code === 0) {
        message.success("删除成功");
        this.serviceOrderInfoList = [];
        this.getList();
      } else {
        message.error("删除失败");
      }
      this.isAllCheck = false;
    });
  }
  huanyuanByQueryEvent() {
    reservationRecyclerReduction({
      reservationIdList: this.serviceOrderInfoList
    }).then(res => {
      if (res.code === 0) {
        message.success("还原成功");
        this.serviceOrderInfoList = [];
        this.getList();
      } else {
        message.error("还原失败");
      }
      this.isAllCheck = false;
    });
  }

  huanyuanByQuery() {
    this.huanyuanByQueryEvent();
  }
  deleteByQuery() {
    this.deleteModel = true;
  }
  reversationStatusChange(key) {
    this.pagination.pageIndex = 1;
    // this.statusKey = key;
    this.reservationOrderDropList(key);
  }
  reservationOrderDropList(key) {
    reservationOrderDropList({
      ...this.pagination,
      rStatus: key
    }).then(res => {
      let data = res.data;
      this.pagination.total = data.totalCount;
      this.data = data.serviceOrderInfos;
      this.pagination.pageSize = data.pageSize;
    });
  }
}


































































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Cascader, Form, Input, message, Modal} from "ant-design-vue";
    import LocationItem from "@/components/LocationItem.vue";
    import fuWuLocationAdd from "@/components/Personal/fuWuLocationAdd.vue";

    import {deleteServiceAddress, fetchSerAddress, updateDefaultAddress,} from "@/services/api";

    Vue.use(Form);
Vue.use(Input);
Vue.use(Modal);
Vue.use(Cascader);

@Component({
  components: {LocationItem, fuWuLocationAdd},
})
export default class extends Vue {
  editData: any = {
    phoneNumber: "",
    receiverDetailAddress: "",
    receiverName: "",
    receiverStreet: null,
    receiverArea: null,
    receiverCity: null,
    receiverProvince: null,
    isDefault: false,
    addressId: "",
    lot: "",
    lat: "",
    provinceValue: [],
    provinceValueList:'',
  };
  loading=true;
  columns = [
    {
      title: "服务人员",
      dataIndex: "receiverName",
      align: "center",
      width: "1.37rem"
    },
    {
      title: "所在地区",
      scopedSlots: {customRender: "addressData"},
      align: "center",
      width: "2.8rem"
    },
    {
      title: "详细地址",
      scopedSlots: {customRender: "detail"},
      align: "center",
      width: "2.59rem"
    },
    // {
    //   title: "邮政编号",
    //   dataIndex: "zipCode",
    //   align: "center",
    //   width: "1.01rem"
    // },
    {
      title: "手机号",
      dataIndex: "phoneNumber",
      align: "center",
      width: "1.24rem"
    },
    {
      title: "是否默认",
      align: "center",
      scopedSlots: {customRender: "data"},
      width: "1.49rem"
    },
    {
      title: "操作",
      align: "center",
      colSpan: 2,//合并表头
      scopedSlots: {customRender: "modification"},
      width: "0.5rem",

    },
    {
      title: "操作",
      align: "center",
      colSpan: 0,//合并表头
      scopedSlots: {customRender: "expurgate"},
      width: "0.5rem",

    }
  ];

  addSuccess() {
    this.getServiceAddress();
    this.visible = false;
  }

  addAddress(event) {
    event.preventDefault();
    this.provinceDefalutValue = "";
    this.province = "";
    this.city = "";
    this.area = "";
    this.editType = 0;
    this.street = "";
    this.editData = {
      phoneNumber: "",
      receiverDetailAddress: "",
      receiverName: "",
      receiverArea: 0,
      receiverCity: 0,
      receiverProvince: 0,
      isDefault: false,
      addressId: "",
      lot: "", //经度
      lat: "", //纬度
      provinceValue: [],
      provinceValueList:'',
      userId: JSON.parse(localStorage.getItem("userInfo") as string)["userInfoId"],
    };
    this.visible = true;
  }

  visible = false;
  recycleBin = false;
  editVisible = false;
  removeServiceId = '';
  editType = 0; // 新增或编辑 服务地址  0  新增 1  编辑
  provinceDefalutValue: any = []; // 级联选项默认值
  provinceOptions: any = []; // 省市区街道列表
  province: any = ""; // 省
  city: any = ""; // 市
  area: any = ""; // 区
  street: any = ""; // 街道
  provinceName: any = ""; // 省名称
  cityName: any = ""; // 市名称
  areaName: any = ""; // 区名称
  streetName: any = ""; // 街道名称
  addressId: any = ""; // 服务地址Id

  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 15},
    },
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 15,
        offset: 10,
      },
    },
  };
  maxAddressNum: any = "20"; // 最多可创建多少个
  addressNum: any = "0"; // 已创建几个
  locationInfo: any = []; // 服务地址信息列表
  editAddressInfo: any = {}; // 编辑服务地址信息项
  form: any;

  // 监听页面高度
  pageHeightCoefficient: number = window.innerHeight / 1080;

  @Watch("pageHeightCoefficient", {immediate: true, deep: true})
  setListerHeight(newval, oldnew) {
    this.pageHeightCoefficient = newval;
    const x = newval;
    let newHeight = (1.18463) * Math.pow(x, 0)
        + (-1.20676) * Math.pow(x, 1)
        + (-0.398135) * Math.pow(x, 2)
        + (0.755784) * Math.pow(x, 3)
        + (0.674309) * Math.pow(x, 4)
        + (-0.609826) * Math.pow(x, 5)
    this.$nextTick(() => {
      let findElement = document.querySelector('.location-page .location-box .ant-alert-info')
      if (findElement) {
        findElement['style']['height'] = `${newHeight}rem`
      }
      // $('.location-page .location-box .ant-alert-info').height = `${newHeight}rem`
    })
  }

  async created() {
    const that = this
    await this.getServiceAddress();
    window.onresize = () => {
      return (() => {
        that.pageHeightCoefficient = window.innerHeight / 1080
      })()
    }
  }

  // 编辑服务地址
  editServiceAddress(data: any) {
    this.editType = 1;
    this.editData = {
      ...data,
      receiverArea: +data.receiverArea,
      receiverCity: +data.receiverCity,
      receiverProvince: +data.receiverProvince,
      receiverStreet: +data.receiverStreet,
      lot: data.longitude, //经度
      lat: data.latitude, //纬度,
      isDefault: data.isDefault===1?true:false,
      receiverDetailAddress: data.receiverDetailAddress,
      provinceValueList: data.receiverProvinceName+'/'+data.receiverCityName+'/'+data.receiverAreaName,
    };

    this.visible = true;
  }

  // 点击设为默认
  handleSelectDefault(value) {
    updateDefaultAddress({
      id: value,
    }).then((res) => {
      if (res.code === 0) {
        // 处理数据
        message.success("默认地址更换成功");
        this.getServiceAddress();
      } else {
        message.error(`设置默认失败，错误原因：${res.msg}`);
      }
    });
  }

  //删除提示
  handleDelete(value) {
    this.recycleBin = true
    this.removeServiceId = value
  }

  //删除
  remove() {
    deleteServiceAddress({
      addressIdList: [this.removeServiceId],
    }).then((res) => {
      if (res.code === 0) {
        message.success("删除成功");
        this.getServiceAddress();
      } else {
        message.error(`删除失败，错误原因：${res.msg}`);
      }
      this.recycleBin = false;
    });
  }

  // 获取服务地址
  getServiceAddress() {
    this.locationInfo.length = 0;
    fetchSerAddress().then((res) => {
      if (res.code === 0) {
        if (res.data.list.length > 0) {
          // 处理数据
          this.addressNum = res.data.addressNum;
          this.maxAddressNum = res.data.maxAddressNum;
          const index = res.data.list.findIndex((item: any, index: any) => {
            return item.isDefaut == 1;
          });
          let defaultObj = res.data.list[index];
          res.data.list.unshift(defaultObj);
          res.data.list.splice(index + 1, 1);
        }
        this.locationInfo = res.data.list;
        this.loading=false
      } else {
        message.error(`获取服务地址失败，错误原因：${res.msg}`);
      }
    });
  }

  // modal 关闭后回调
  modalClose() {
    this.editType = 0;
  }
}

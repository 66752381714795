










































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message} from "ant-design-vue";
    import {getReservationCancel, reservationDelete} from "@/services/api";

    @Component({})
export default class ReservationItem extends Vue {
  @Prop() cancelReason;
  @Prop() private content!: Object;
  @Prop() private itemIndex: Number;
  cancelId = 0;
  cancelVisible = false;
  deletelVisible = false;

  selectChange(value) {
    this.cancelId = value;
  }

  yuyuedanDetail() {
    const {href} = this.$router.resolve({
      path: "/personal/reservationDetail",
      query: {
        reservationOrderId: this.content["reservationId"]
      }
    });
    open(href);
  }

  yuyuedanCommnet() {
    const content: any = this.content;
    const {href} = this.$router.resolve({
      path: "/personal/ReservationCommnet",
      query: {
        reservationOrderId: content.reservationId,
        agencyName: content.agencyName,
        createdTime: content.createdTime,
        seriesNum: content.seriesNum,
        orgId: content.orgId
      }
    });
    open(href);
  }

  deleteEvent() {
    // this.reservationDelete(reservationId);
    this.deletelVisible = true;
  }

  reservationDelete(reservationId: String) {
    reservationDelete({
      reservationId: reservationId
    }).then(res => {
      if (res.code === 0) {
        message.success("删除成功,已移到服务订单回收站");
        this.$emit("againGetList");
      } else {
        message.error("只有已完成和已取消的订单能够放入回收站");
      }
    });
  }

  reservationDeleteEvent(reservationId) {
    this.reservationDelete(reservationId);
    this.deletelVisible = false;
  }

  reservationCancel(reservationId) {
    if (!this.cancelId) {
      message.warning("请选择取消原因");
      return;
    }

    getReservationCancel({
      reservationId: reservationId,
      cancelReason: this.cancelId
    }).then(res => {
      if (res.code === 0) {
        message.success("提交成功");
        this.cancelId = 0;
        this.$emit("againGetList");
      } else {
        message.error("提交失败");
      }
      this.cancelVisible = false;
    });
  }
}

import {baseUrl, baseUrl2, request} from "./http";
// export const baseUrl = "/ttt"; //跨域代理api，正式环境请更改成 http://39.97.106.60

// const baseUrl = "http://39.97.106.60";

export interface ResContent {
    code: number;
    data?: any;
    msg: string;
}

// 获取城市列表
export function fetchCityList(areaType: Number): Promise<any> {
    return request(`${baseUrl}/api/v1/citys/list?areaType=${areaType}`);
}


/**
 * 获取幂等id，防止重复提交
 */
export function getChangeCode(): Promise<any> {
    return request(`${baseUrl}/api/v1/change/code`);
}

// 获取手机验证码
export function getVerificationCode(phone: String): Promise<any> {
    return request(`${baseUrl}/userapp/verificationCode/${phone}`);
}

// 登录接口(账号密码登录)
export function userLogin(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/login`, {
        method: "post",
        data: params,
    });
}

//登录获取微信二维码
export function oAuthWxLoginCode(code: string, state: string): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/wx/login`, {
        method: "get",
        params: {
            code: code,
            state: state
        },
    });
}

//微信绑定二维码
export function oAuthWxBindCode(code: string, state: string): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/wx/bind`, {
        method: "get",
        params: {
            code: code,
            state: state
        },
    });
}

// 退出接口
export function userLogout(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/loginout`, {
        method: "post",
        data: params,
    });
}

// 快捷登录(手机验证码登录)
export function userLoginByPhone(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/login/phone`, {
        method: "post",
        data: params,
    });
}

export function userLoginPassword(params: any): Promise<any> {
    return request(`${baseUrl}/userapp/register/mobile`, {
        method: "post",
        data: params,
    });
}

// 用户注册
export function userRegister(params: any): Promise<any> {
    return request(`${baseUrl}/userapp/userRegister`, {
        method: "post",
        data: params,
    });
}

//微信登录（手机已注册）
export function userDetermine(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/bind/check`, {
        method: "post",
        data: params,
    });
}

//微信登录已注册（确认账号）
export function confirmTheAccount(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/bind`, {
        method: "post",
        data: params,
    });
}

//微信登录未注册（确认账号）
export function wxUnregistered(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/bind/register`, {
        method: "post",
        data: params,
    });
}


// 字典类型数据获取
export function dictionaries(params: Array<number>): Promise<any> {
    return request(`${baseUrl}/sys/dictionary/gets`, {
        method: "post",
        data: params,
    });
}

// 字典类型数据获取
export function dictionariesWithDesc(params: number): Promise<any> {
    return request(`${baseUrl}/sys/dictionary/getinfo/${params}`, {
        method: "get",
    });
}

// 地址选择器
interface AddrSelectParams {
    areaType: number;
    areaId: string;
}

/**
 * 根据id查询地址，其中street可以为空，有些地址没有第四级
 */
interface HolderAddress {
    province: string;
    city: string;
    area: string;
    street: string;
}

export function fetchAddrCascader(params: AddrSelectParams): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/address/list`, {
        method: "get",
        params: params,
    });
}

export function fetchAddrInfo(params: HolderAddress): Promise<any> {
    return request(
        `${baseUrl}/api/v1/agency/addrcode/${params.province}/${params.city}/${params.area}/${params.street}`,
        {
            method: "get",
        }
    );
}

// -----------------------------------------首页------------------------------------------//

// Nav的养老模式一级菜单
interface YlmsNavMenuListParam {
    typeId: string;
}

export function fetchYlmsNavMenuList(params: YlmsNavMenuListParam): Promise<any> {
    return request(`${baseUrl}/api/v1/dicList/${params.typeId}`);
}

// Nav的养老服务一级菜单
export function fetchYlmsNextMenuList(): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/list/all`);
}

//根据一级菜单显示二级菜单
//
export function getSecondaryMenu(params: number): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/list/second/${params}`, {
        method: "get",
    });
}

// Nav的养老服务一级菜单带分页
interface YlfwMenuListParams {
    pageSize: number;
    pageIndex: number;
}

export function fetchMenuYlfwList(params: YlfwMenuListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/list`, {
        method: "get",
        params: params,
    });
}

// 最新入住机构列表
export function fetchAdditionList(): Promise<any> {
    return request(`${baseUrl}/api/v1/home/latest-additions/list`);
}

// 养老咨询列表
interface PensionNewsListParam {
    messageCategoryId: string;
}

export function fetchPensionNewsList(): Promise<any> {
    return request(`${baseUrl}/api/v1/home/recommended-news/list`);
}

// 首页轮播图展示
interface BannerListParams {
    bannerLocation: number; // 248-服务首页；249-商城首页
    platformType: number; // 244-PC；245-H5；246-Android；247-iPhone
}

export function fetchBannerList(params: BannerListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/home/banners/list`, {
        method: "get",
        params: params,
    });
}

// 首页热门推荐(推荐服务商)
interface HotInstitutionParams {
    cityId: number;
    areaId?: number;
    limit: number;
}

export function fetchHotInstitution(params: HotInstitutionParams): Promise<any> {
    return request(`${baseUrl}/api/v1/home/hot-recommend/orgs?t=${new Date().getTime()}`, {
        method: "get",
        params: params,
    });
}

export function recommendationInstitutions(params: HotInstitutionParams): Promise<any> {
    return request(`${baseUrl}/api/v1/home/hot-recommend/newOrgs`, {
        method: "get",
        params: params,
    });
}

// 首页热门推荐(推荐服务)
interface HotServiceParams {
    cityId: number;
    areaId?: number;
    limit: number;
}

export function fetchHotService(params: HotServiceParams): Promise<any> {
    return request(`${baseUrl}/api/v1/home/hot-recommend/services`, {
        method: "get",
        params: params,
    });
}

// 搜索服务机构
interface SearchParams {
    pageSize: number;
    pageIndex: number;
    searchInput: string;
    cityId: number;
    areaId?: number;
    orgForm?: number;
    orgType?: number;
    orgBedNum?: number;
    orgCharacter?: number;
    lowestPrice?: number;
    serviceObject?: number;
    specialService?: number;
}

export function fetchSearch(params: SearchParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/search`, {
        method: "get",
        params,
    });
}

// 搜索服务
interface SearchServiceParams {
    pageSize: number;
    pageIndex: number;
    searchInput: string;
    cityId: number;
    areaId?: number;
    serviceItemType?: string;
    serviceItemPrice?: number;
}

export function fetchSearchService(params: SearchServiceParams): Promise<any> {
    return request(`${baseUrl}/api/v1/services/search`, {
        method: "get",
        params,
    });
}

//养老机构
interface nursingInstitution {
    pageSize: number;
    pageIndex: number;
    searchInput: string;
    provinceId: number;
    cityId: number;
    areaId?: number;
    orgForm?: number;
    orgType?: number;
    orgBedNum?: number;
    orgBedNumLowest?: number;
    orgBedNumHeight?: number;
    feeLowest?: number;
    feeHeight?: number;
    orgCharacter?: number;
    lowestPrice?: number;
    serviceObject?: number;
    orgObject?: number;
    specialService?: number;
}

export function nursingInstitution(params: nursingInstitution): Promise<any> {
    return request(`${baseUrl}/api/v1/agencies/search`, {
        method: "get",
        params,
    });
}

/**
 * 查询机构
 * @param params
 */
export function fetchOrganizationsForSearch(params: nursingInstitution): Promise<any> {
    return request(`${baseUrl}/api/v1/search/organizations`, {
        method: "get",
        params,
    });
}

/**
 * 查询服务商
 * @param params
 */
export function fetchServiceProviderForSearch(params: nursingInstitution): Promise<any> {
    return request(`${baseUrl}/api/v1/search/serviceProvider`, {
        method: "get",
        params,
    });
}

//-----------------------------------------养老模式----------------------------------------//

// 指定养老模式的详情(机构养老，居家养老...界面内容)
interface YlmsDetailParams {
    pageSize: number;
    pageIndex: number;
    cityId: number;
    formDicId: number; // 206-机构养老；207-居家养老；208-社区养老；209-旅居养老；210-医养结合
    areaId?: number;
}

export function fetchYlmsDetail(params: YlmsDetailParams): Promise<any> {
    return request(`${baseUrl}/api/v1/ylform/${params.formDicId}/list`, {
        method: "get",
        params: {
            pageSize: params.pageSize,
            pageIndex: params.pageIndex,
            cityId: params.cityId,
            areaId: params.areaId,
        },
    });
}

//获取养老模式
export function endowmentPattern(): Promise<any> {
    return request(`${baseUrl}/api/v1/ylModel/allModel`, {
        method: "get",
    });
}

export function recommendedRetirement(): Promise<any> {
    return request(`${baseUrl}/api/v1/ylModel/recommendModel`, {
        method: "get",
    });
}

//获取单个养老模式数据
export function singleYLMS(params: string): Promise<any> {
    return request(`${baseUrl}/api/v1/ylModel/info-pt/${params}`, {
        method: "get",
        data: params,
    });
}

export function getAllYlms(): Promise<any> {
    return request(`${baseUrl}/api/v1/ylModel/allModel`, {
        method: "get",
        //
    });
}

// 获取服务商详情
interface OrgDetailParams {
    orgId: string;
}

export function fetchOrgDetail(params: OrgDetailParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/${params.orgId}/info`, {
        method: "get" + '',
        params: {
            cityId: localStorage.getItem("provinceId"),
        },
    });
}

//获取机构和服务商接口
export function organizationDetail(params: OrgDetailParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/org-info/${params.orgId}`, {
        method: "get",
        params: {
            cityId: localStorage.getItem("provinceId"),
        },
    });
}

//获取机构和服务商的服务项目接口
export function OrganizationOrServiceProvider(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/category/service/${params.orgId}`, {
        method: "get",
        params: {
            categoryIds: params.categoryIds,
            cityId: parseInt(params.cityId),
            lowestPrice: parseFloat(params.lowestPrice),
            highestPrice: parseFloat(params.highestPrice),
        },

    });
}

//获取机构和服务商的服务项目接口
export function OrganizationOrServiceProviderCityIds(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/category/service/cities/${params.orgId}`, {
        method: "get",
        params: {
            categoryIds: params.categoryIds,
            cityId: params.cityId
        },
    });
}

export function fetchInstitutionDetail(params: OrgDetailParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/${params.orgId}/brief-info`);
}

// 机构详情 -> 提交预约单
interface AddReservationParams {
    orgId: string;
    contactName: string;
    contactTel: string;
    addressProvince: number;
    addressCity: number;
    addressArea: number;
    addressStreet: number;
    detailAdd: string;
    elderAge: number;
    elderGender: number;
    physicalCondition: number;
    priceRange: number;
    demandPeriod: number;
    demandRemark: string;
    token: string;
}

export function fetchAddReservation(params: AddReservationParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/reservation/add`, {
        method: "post",
        data: params,
    });
}

// 取消订单
interface CancelOrder {
    orderInfoId: string;
    cancelReason: string;
}

export function fetchCancelOrder(params: CancelOrder): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/cancel`, {
        method: "post",
        data: params,
    });
}

// 养老模式内容列表
interface YlmsListParams {
    cityId: number;
    areaId?: number;
}

export function fetchYlmsList(params: YlmsListParams) {
    request(`${baseUrl}/api/v1/news/types`).then(({code, data}: any) => {
        if (code == 0) {
            localStorage.setItem("newTags", JSON.stringify(data));
        }
    });

    return request(`${baseUrl}/api/v1/ylform/list`, {
        method: "get",
        params: params,
    });
}

// 养老模式—查询某一机构下某一特定第三级服务分类下的所有服务项目
interface YlmsThirdListParams {
    pageSize: number;
    pageIndex: number;
    orgId: string;
    thirdCategoryId: string;
}

export function fetchThirdYlmsList(params: YlmsThirdListParams): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});
    return request(`${baseUrl}/api/v1/org/services/third-category/list`, {
        method: "get",
        params: params,
    });
}

// 养老模式—查询某一机构的所有服务项目（用于显示第三级服务分类及其下属服务项目）
interface YlmsAllServicesParams {
    pageSize: number;
    pageIndex: number;
    orgId: string;
}

export function fetchYlmsAllService(params: YlmsAllServicesParams): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});

    return request(`${baseUrl}/api/v1/org/services/list`, {
        method: "get",
        params: params,
    });
}

// 养老服务—根据服务的第三级分类id，获取它的详情页，包括它下面的服务项目
interface YlfwGetDetailByThirdIdParams {
    pageSize: number;
    pageIndex: number;
    cityId: number;
    areaId?: number;
    searchInput?: string;
    thirdServicesId: string;
}

export function fetchDetailByThirdId(params: YlfwGetDetailByThirdIdParams): Promise<any> {
    return request(`${baseUrl}/api/v1/services/${params.thirdServicesId}/list`, {
        method: "get",
        params: {
            pageSize: params.pageSize,
            pageIndex: params.pageIndex,
            cityId: params.cityId,
            areaId: params.areaId,
            searchInput: params.searchInput,
        },
    });
}

// 养老服务—根据服务的第二级分类id，获取它的详情页，包括它下面的服务项目
interface YlfwGetDetailBySecondIdParams {
    pageSize: number;
    pageIndex: number;
    cityId: number;
    areaId?: number;
    secondServicesId: string;
}

export function fetchDetailBySecondId(params: YlfwGetDetailBySecondIdParams): Promise<any> {
    return request(`${baseUrl}/api/v1/services/second/${params.secondServicesId}/list`, {
        method: "get",
        params: {
            pageSize: params.pageSize,
            pageIndex: params.pageIndex,
            cityId: params.cityId,
            areaId: params.areaId,
        },
    });
}

// 养老服务—根据服务id获取服务详细信息
interface GetDetailByServiceIdParams {
    serviceId: string;
}

export function fetchDetailByServiceId(params: GetDetailByServiceIdParams): Promise<any> {
    return request(`${baseUrl}/api/v1/service/info`, {
        method: "get",
        params: params,
    });
}

// 获取服务相关评价
interface ServiceCommentsParams {
    pageSize: number;
    pageIndex: number;
    serviceId: string;
}

export function fetchServiceComments(params: ServiceCommentsParams): Promise<any> {
    return request(`${baseUrl}/api/v1/service/comments/list`, {
        method: "get",
        params: params,
    });
}

// 收藏或取消机构／服务
interface CollectionParams {
    itemId: string;
    itemType: number;
    operation: number;
}

export function fetchCollection(params: CollectionParams): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/org-service`, {
        method: "post",
        data: params,
    });
}

// 获取地图信息
interface MapInfoParams {
    cityId: number;
    areaId: number;
    orgForm?: number;
    categoryId?: string;
    searchInput?: string;
}

export function fetchMapInfo(params: MapInfoParams) {
    return request(`${baseUrl}/api/v1/pension-map/search/orgs`, {
        method: "get",
        params: params,
    });
}

export function fetchIfSpecialOrder(params: string): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/org-service`, {
        method: "post",
        data: params,
    });
}

// 服务结算-获取用户可用代币
export function fetchUserCoin(): Promise<any> {
    return request(`${baseUrl}/api/v1/services/settlement/user-daibi`);
}

// 服务订单结算
interface OrderSettlementParams {
    cityId: number; // 所在市id
    ifShop: boolean; // 是否是到店服务
    serviceAddressId: string; // 非到店服务人的所在地址id，如上门服务的用户服务地址
    serviceProviderInfo: ServiceProviderInfo[]; // 服务所属服务商信息
    purchaseMethod: number; // 支付方式 在线支付285；现场付款286；货到付款287
    invoiceInfoId: string; // 发票id，没有可以为空
    couponIdList: string[]; // 优惠券id列表，没有可以为空
    tokenAmount: number; // 代币总金额 没有为0
    totalDiscountAmount: number; // 优惠总金额 没有为0
    totalAmount: number; // 总金额 最小为0.01，totalSumAmount之和
    totalAmountForPay: number; // 应付总金额 最小为0.01， = 总金额 - 优惠总金额
    orderSource: number; // 订单来源 349-PC；350-移动设备
    ignoreSettlement: boolean; // 普通需要结算的订单，默认false
}

/**
 * 提交的服务订单列表里的服务商，服务商下面有这个服务商的服务
 */
interface ServiceProviderInfo {
    agencyId: string; // 服务商id
    serviceStyle: number[]; // 服务方式
    serviceList: ServiceList[]; // 服务
    servicesSumAmount: number; // 服务合计金额，不计算服务费 最小0.01
    totalServiceFee: number; // 服务费合计金额
    totalSumAmount: number; // 总金额 = 服务合计 + 服务费合计 最小0.01
    serviceRemark: string; // 服务商留言50字以内
    shopAddressId: string; // 服务商的门店地址id，这里要看服务方式是不是到店服务
}

/**
 * 提交的服务订单列表里的单个服务
 */
interface ServiceList {
    serviceId: string; // 服务id
    skuInfoId: string; // 服务规格
    serviceNum: number; // 购买的服务数量
    servicePrice: number; // 服务单价
    serviceTotalAmount: number; // 服务价格 = 服务单价 * 服务数量
    serviceFeeAmount: number; // 服务费 = 服务服务费单价 * 服务数量
}

/**
 * 提交服务订单去支付
 *
 * {http://rap2.taobao.org/repository/editor?id=231951&mod=327794&itf=1948481}
 *
 * @param params
 */
export function fetchOrderSettlement(params: OrderSettlementParams): Promise<any> {
    return request(`${baseUrl}/api/v1/services/order/settlement`, {
        method: "post",
        data: params,
    });
}
//发票提交
export function saveTheInvoice(params: OrderSettlementParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/invoices/create`, {
        method: "post",
        data: params,
    });
}

// ------------------------------------ 养老资讯 ------------------------------------ //
// 获取养老咨询页的首页数据
interface PensionNewsHomeParams {
    newsType: number;
    pageSize: number;
    pageIndex: number;
}

export function fetchPensionNewsHome(params: PensionNewsHomeParams): Promise<any> {
    return request(`${baseUrl}/api/v1/news/home`, {
        method: "get",
        params,
    });
}

// 查询所有资讯类别
export function fetchAllNewsType(): Promise<any> {
    return request(`${baseUrl}/api/v1/news/types`);
}

// 查询咨询详情
interface NewsDetailParams {
    newsId: string;
}

export function ferchNewsDetail(params: NewsDetailParams): Promise<any> {
    return request(`${baseUrl}/api/v1/news/info?newsId=${params.newsId}`);
}

// 查询相关类型的文章
interface RelateNewsListParams {
    newsId: string;
    pageSize: number;
    pageIndex: number;
}

export function fetchRelateNewsList(params: RelateNewsListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/news/relate-news/list`, {
        method: "get",
        params,
    });
}

// 添加文章评论
interface AddNewsCommentParams {
    messageId: string;
    commentContent: string;
    isAnonymity: boolean;
}

export function fetchAddNewsComment(params: AddNewsCommentParams): Promise<any> {
    return request(`${baseUrl}/api/v1/comments-news/comments/add`, {
        method: "post",
        data: params,
    });
}

// 查看文章评论列表
interface NewsCommentsListParams {
    newsId: string;
    pageSize: number;
    pageIndex: number;
}

export function fetchNewsCommentsList(params: NewsCommentsListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/news/comments/list`, {
        method: "get",
        params,
    });
}

// -----------------------------------养老一张图------------------------------------ //

// 根据定位信息、搜索内容、选择的养老模式或服务分类获取相关的机构地理分布
interface PensionMapInfoSearch {
    cityId: number;
    areaId: number;
    orgForm?: number;
    categoryId?: string;
    searchInput?: string;
}

export function fetchSearchPensionMapInfo(params: PensionMapInfoSearch): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-map/search/orgs`, {
        method: "get",
        params,
    });
}

//养老一张图-根据定位信息、搜索内容查询相关的机构地理分布
// export const getOrgByInput = (params: { cityId: number; areaId?: number; searchInput?: string }) => {
//     return request(`${baseUrl}/api/v1/pension-map/input-search/orgs`, {
//         method: "get",
//         params,
//     });
// };
export const getOrgByInput = (params: { cityId: number; areaId?: number; searchInput?: string }) => {
    return request(`${baseUrl}/api/v1/map/list`, {
        method: "get",
        params,
    });
};

// 养老一张图-根据定位信息、养老模式查询相关的机构地理分布
export const getOrgByYlform = (params: { cityId: number; areaId?: number; orgForm?: string }) => {
    return request(`${baseUrl}/api/v1/pension-map/ylform-search/orgs`, {
        method: "get",
        params,
    });
};
// 养老一张图-根据定位信息、服务第一级分类查询相关的机构地理分布
export const getOrgByCategory = (params: { cityId: number; areaId?: number; categoryId?: string }) => {
    return request(`${baseUrl}/api/v1/pension-map/category-search/orgs`, {
        method: "get",
        params,
    });
};

// 获取服务的分类信息（养老一张图左侧服务）
export function fetchPensionMapService(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/third-type/list/all`, {
        method: "get",
        params: params,
    });
}

// ---------------------------------我的收藏---------------------------------- //

// 商品收藏
interface GoodsCollectionListParams {
    pageSize: number;
    pageIndex: number;
}

export function fetchGoodsCollectionList(params: GoodsCollectionListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/commodities/list`, {
        method: "get",
        params: params,
    });
}

// 店铺收藏
interface StoreCollectionListParams {
    pageSize: number;
    pageIndex: number;
}

export function fetchStoreCollectionList(params: StoreCollectionListParams): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/merchants/list`, {
        method: "get",
        params: params,
    });
}

// -------------------------------- 账户--------------------------------- //
export function fetchAccountInfo(): Promise<any> {
    return request(`${baseUrl}/api/v1/accounts/info`, {
        method: "get",
    });
}

//微信解除绑定
export function WxRemoveBinding(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/oauth/wx/unbind`, {
        method: "get",
    });
}

//验证手机验证码
export function verificationCode(params: any): Promise<any> {
    // /userapp/checkmobile/{mobile}/{code}
    return request(`${baseUrl}/userapp/checkmobile/${params.mobile}/${params.code}`, {
        method: "get",
        params: params,
    });
}

// 获取省
export function fetchProvince(): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/address/list`, {
        method: "get",
        params: {
            areaType: 0,
        },
    });
}

// 获取市
export function fetchCity(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/address/list`, {
        method: "get",
        params: params,
    });
}

// 获取区
export function fetchArea(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/address/list`, {
        method: "get",
        params: params,
    });
}

// 获取街道
export function fetchStreet(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/address/list`, {
        method: "get",
        params: params,
    });
}


// 完全匹配名称获取地区，1获取省；2获取市；3获取县区
// 匹不匹配看天意
export function fetchAreaByName(areaType: number, areaName: string): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/addr/query/${areaType}/${areaName}`, {
        method: "get",
    });
}

// 获取证件或社保类型
export function fetchCredentials(params: any): Promise<any> {
    return request(`${baseUrl}/sys/dictionary/get/${params.type}`, {
        method: "get",
    });
}

// 获取民族
export function fetchNation(): Promise<any> {
    return request(`${baseUrl}/api/v1/nation/get`, {
        method: "get",
    });
}

// 根据省市区街道id获取名字
export function fetchProvinceName(params: any): Promise<any> {
    return request(
        `${baseUrl}/api/v1/agency/addrid/${params.province}/${params.city}/${params.area}${
            params.street ? "/" + params.street : ""
        }`,
        {
            method: "get",
        }
    );
}

// 更新账户信息
export function updateAccount(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/accounts/update`, {
        method: "post",
        data: params,
    });
}

//头像上传
export function pictureUpload(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/accounts/update/header`, {
        method: "post",
        data: params,
    });
}

// 修改登录密码
export function updatePassword(params: any): Promise<any> {
    return request(`${baseUrl}/user/changepwd`, {
        method: "post",
        data: params,
    });
}

//获取收货地址
export function shippingAddress(): Promise<any> {
    return request(`${baseUrl}/api/v1/addresses/list`, {
        method: "get",
    });
}

// 新增收货地址
export function addShippingAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/addresses/add`, {
        method: "post",
        data: params,
    });
}

// 删除收货地址
export function deleteShippingAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/addresses/delete`, {
        method: "post",
        data: params,
    });
}

// 更新默认地址
export function updateDefaultShippingAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/addresses/default/${params.id}`, {
        method: "get",
    });
}

// 编辑服务地址
export function updateShippingAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/addresses/update`, {
        method: "post",
        data: params,
    });
}

// 获取服务地址
export function fetchSerAddress(): Promise<any> {
    return request(`${baseUrl}/api/v1/service-info/list`, {
        method: "get",
    });
}

// 更新默认地址
export function updateDefaultAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-info/default/${params.id}`, {
        method: "get",
    });
}

// 新增服务地址
export function addServiceAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-info/add`, {
        method: "post",
        data: params,
    });
}
//根据地图选点获取服务地区
export function serviceAreaMap(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/addr/code/query/${params}`, {
        method: "get",
    });
}

// 编辑服务地址
export function updateServiceAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-info/update`, {
        method: "post",
        data: params,
    });
}

// 删除服务地址
export function deleteServiceAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-info/delete`, {
        method: "post",
        data: params,
    });
}

// 查询商品消费明细
export function getServiceDetail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/accounts/income-expand/list`, {
        method: "get",
        params: params,
    });
}

// 获取服务评价
export function fetchServiceComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/comment-list`, {
        method: "get",
        params: params,
    });
}

//获取诊疗预约单评价
export function clinicAppointmentFormComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/evaluation/list`, {
        method: "get",
        params: params,
    });
}

// 删除服务评价
export function deleteServiceComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/delete/${params.commentId}`, {
        method: "post",
    });
}

//获取服务订购单评价
export function evaluationServiceOrder(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/orderService/evaluation/${params.orderId}`, {
        method: "get",
        params,
    });
}
//服务订购单评价提交
export function purchaseOrderEvaluation(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/orderService/evaluation/doEvaluation`, {
        method: "post",
        data: params,
    });
}

//删除远程医疗评价
export function telemedicineComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/evaluation/del/${params}`, {
        method: "post",
    });
}

//获取诊疗预约评论详情
export function telemedicineDetails(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/evaluation/${params.evaluationId}`, {
        method: "get",
        params: params,
    });
}

//获取商品订单评价
export function commodityEvaluation(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/commodities/comments/list`, {
        method: "get",
        params: params,
    });
}

//删除商品订单评价
export function deleteCommodityEvaluation(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/commodities/comments/delete/${params.commentId}`, {
        method: "get",
        params: params,
    });
}

//查看商品评价详情
export function evaluationDetails(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/commodities/comments/info`, {
        method: "get",
        params: params,
    });
}

// 获取预约单评价
export function fetchReservationComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/reservations/list`, {
        method: "get",
        params: params,
    });
}

// 删除预约单评价
export function deleteReservationComment(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/reservation/delete/${params.commentId}`, {
        method: "post",
    });
}

// 获取服务订单记录
export function fetchServiceOrderRecord(): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/cancel/list`, {
        method: "get",
        params: {
            pageSize: 99999999,
            pageIndex: 1,
        },
    });
}

// 获取申请服务退款列表
export function fetchApplyForReturn(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services/refundable/list`, {
        method: "get",
        params: params,
    });
}

// 获取退款记录列表
export function fetchReturnRecord(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/history`, {
        method: "get",
        params: params,
    });
}

// 删除退款记录
export function deleteReturnRecord(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/delete/${params.returnId}`, {
        method: "post",
    });
}

// 申请售后/退款获取退款详情
export function fetchReturnOrderDetail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/order-info`, {
        method: "get",
        params: params,
    });
}

// 申请售后/退款获取退款原因
export function fetchReturnReson(): Promise<any> {
    return request(`${baseUrl}/api/v1/dicList/refund-reasons`, {
        method: "get",
    });
}

// 申请售后/退款提交退款申请
export function applyReturn(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money`, {
        method: "post",
        data: params,
    });
}

// 上传文件
export function uploadTokenFile(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/upload`, {
        method: "post",
        data: params,
    });
}

// 查询售后服务申请状态
export function fetchAfterSaleStatus(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/status`, {
        method: "get",
        params: params,
    });
}

// 获取服务订单详情
export function fetchServiceOrderDetail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/info`, {
        method: "get",
        params: params,
    });
}

// 查询售后协商历史
export function fetchServiceHistory(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/negotiation/services/history`, {
        method: "get",
        params: params,
    });
}

// 撤销申请
export function cancellApply(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/cancel`, {
        method: "post",
        data: params,
    });
}

// 修改申请获取数据
export function fetchReturnApplyInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/application-info`, {
        method: "get",
        params: params,
    });
}

// 修改申请提交
export function modifyApplyReturn(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/update`, {
        method: "post",
        data: params,
    });
}

// 获取服务收藏分类
export function fetchServiceCollectCategory(): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/service/categoryList`, {
        method: "get",
    });
}

// 获取商品收藏分类
export function fetchServiceMerchandise(): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/merchants/categoryList`, {
        method: "get",
    });
}

// 获取服务收藏列表
export function fetchServiceCollectList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/services/list`, {
        method: "get",
        params: params,
    });
}

// 点击取消收藏
export function postCancelCollect(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/organization-service/delete/${params.serviceCollectId}`, {
        method: "post",
        data: params,
    });
}

//置顶、取消置顶（商品、店铺）
export function callOffTop(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/commodities-merchants/top/${params.collectionId}`, {
        method: "post",
        data: params,
    });
}

//置顶、取消置顶(服务、服务商、养老机构)
export function topside(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/services-organizations/top/${params.collectionId}`, {
        method: "post",
        data: params,
    });
}


// 获取服务商收藏列表
export function fetchServiceStoreCollectList(params: any): Promise<any> {
    params.pageSize = params.pageSize > 9999 ? 9999 : params.pageSize;
    return request(`${baseUrl}/api/v1/collections/organizations/list`, {
        method: "get",
        params: params,
    });
}

// 获取机构的收藏列表
export function organizationList(params: any): Promise<any> {
    params.pageSize = params.pageSize > 9999 ? 9999 : params.pageSize;
    return request(`${baseUrl}/api/v1/collections/newOrganizations/list`, {
        method: "get",
        params: params,
    });
}

// 获取商品收藏列表
export function getCollectionList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/commodities/list`, {
        method: "get",
        params: params,
    });
}

//获取店铺收藏列表
export function getShopCollectList(params: any): Promise<any> {
    params.pageSize = params.pageSize > 9999 ? 9999 : params.pageSize;
    return request(`${baseUrl}/api/v1/collections/merchants/list`, {
        method: "get",
        params: params,
    });
}

//取消商品/店铺收藏
// export function cancelCollection (params: any): Promise<any> {
//   return request(`${baseUrl}/api/v1/collections/shop-commodity/delete${params.serviceCollectId}`, {
//     method: "get",
//     params: params,
//   });
// }
// 取消商品/店铺收藏
interface cancelCollectionParams {
    collectionId: string;
}

export function cancelCollection(params: cancelCollectionParams): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/shop-commodity/delete`, {
        method: "post",
        data: params,
    });
}

export function addShopping(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/cart/add`, {
        method: "post",
        data: params,
    });
}

// -------------------------------- 入驻 -------------------------------- //

// 查询所有机构服务类目(机构入驻)
export function fetchServiceTypes(): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/service-types/list`);
}

// 查询服务商入驻进度
export function fetchServiceProviderStatus(): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/status`);
}

// 查询机构入驻进度(机构入驻)
export function fetchOrganizationsStatus(): Promise<any> {
    return request(`${baseUrl}/api/v1/org/join/status`);
}

// 入驻审核失败后重新修改入驻信息，获取入驻信息内容（服务商）
export function fetchGetOrganizationsJoinInfo(): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/getjoininfo`);
}

//入驻审核失败后重新修改入驻信息，获取入驻信息内容（机构）
export function fetchGetOrganizationsInFailure(): Promise<any> {
    return request(`${baseUrl}/api/v1/org/join/getjoininfo`);
}

// 查询商家入驻进度(商家入驻)
export function fetchMerchantStatus(): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/status`);
}

// 查询地址表(机构入驻)
export function fetchServiceAddress(): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/services/address`);
}

// 所有服务类目
export function fetchAllShopTypes(): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/shop-types/list`);
}

// 商家入驻
interface MerchantInfoJoinParams {
    merchantInfo: MerchantInfo;
    contactAccountInfo: ContactAccountInfo;
    administratorInfo: AdministratorInfo;
    expansionInfo: ExpansionInfo;
    shopInfo: ShopInfo;
}

// 商家公司信息
export interface MerchantInfo {
    merchantName: string;
    merchantCode: string;
    addressDetail: string;
    merchantTel: string;
    addressProvince: string;
    addressCity: string;
    addressArea: string;
    addressStreet: string;
}

// 联系人及账户信息
export interface ContactAccountInfo {
    lpName: string;
    lpIdNum: string;
    lpTel: string;
    accountName: string;
    accountNumber: string;
    bankName: string;
    accountDetailAdd: string;
    businessLicensePicture: string;
    accountProvince: string;
    accountCity: string;
    accountArea: string;
    accountStreet: string;
    lpIdPicturePositive: string;
    lpPictureNegative: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
}

// 管理员信息
interface AdministratorInfo {
    adminName: string;
    adminIDNum: string;
    adminTel: string;
}

// 扩展信息
interface ExpansionInfo {
    coverPicture: string;
    createTime: string;
    registeredCapital: string;
    industryInvolved: number;
    merchantIntro: string;
    merchantRemark?: string;
}

// 店铺信息
interface ShopInfo {
    merchantAccount: string;
    storeName: string;
    addressProvince: string;
    addressCity: string;
    addressArea: string;
    addressStreet: string;
    detailAddress: string;
    merchantTel: string;
    categoryList: string[];
    shoreRemark: string;
}

// 商家入驻
export function fetchMerchantAdd(params: MerchantInfoJoinParams): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/add`, {
        method: "post",
        data: params,
    });
}

// 服务商入住
interface OrgInfoJoinParams {
    organizationInfo: OrganizationInfo;
    accountInfo: ContactAccountInfo;
    administratorInfo: AdministratorInfo;
    expansionInfo: OrgExpansionInfo;
    shopInfo: OrgShopInfo;
    label: OrgLabel;
    paymentInfo: PaymentInfo;
}

interface OrganizationInfo {
    companyName: string;
    orgCode: string;
    companyProvince: string;
    companyCity: string;
    companyArea: string;
    companyStreet: string;
    companyAddressDetail: string;
    companyContactNumber: string;
    detailDescription: string;
}

interface OrgExpansionInfo {
    coverImage: string;
    createTime: string;
    briefIntro: string;
    orgRemark: string;
    areaSize: number;
    orgIntro: string;
}

interface OrgShopInfo {
    orgAccount: string;
    orgName: string;
    orgForm: string;
    servicesCategoryList: string[];
    orgType: string;
    orgCharacter: string;
    serviceOdject: string;
    addressProvince: string;
    addressCity: string;
    addressArea: string;
    addressStreet: string;
    addressDetail: string;
    bedNum: string;
    lowestPrice: number;
    highestPrice: number;
    orgTel: string;
}

interface OrgLabel {
    specialService: string;
}

interface PaymentInfo {
    alipayId: string;
    alipayNickname: string;
    alipayRealName: string;
    wechatId: string;
}

// 服务商入驻
export function fetchOrganizationsAdd(params: OrgInfoJoinParams): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/addorg`, {
        method: "post",
        data: params,
    });
}

// 上传服务商图片
export function uploadServiceFile(data: FormData, fn: Function): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/upload`, {
        method: "post",
        data: data,
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: fn,
    });
}

// 上传商家图片
export function uploadMerchantFile(data: FormData, fn: Function): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/upload`, {
        method: "post",
        data: data,
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress: fn,
    });
}

// 商家经营类目
// interface CategoryGetParams {
//   level: number;
//   parentId: string;
// }
export function fetchMerCategories(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/commcategory/alltype`, {
        method: "get",
        params,
    });
}

interface MobileValidateCodeParams {
    mobile: string;
    code: string;
}

// 入驻校验手机号及验证码
export function checkMobileValidateCode(params: MobileValidateCodeParams): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checkmobile/${params.mobile}/${params.code}`, {
        method: "post",
    });
}

// 服务商入驻校验公司名称是否已存在
export function checkMerNameExist(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checksamename/${name}`, {
        method: "get",
    });
}

// 服务商入驻/商家入驻校验账户是否已存在
export function checkUserNameExist(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checkaccount/${name}`, {
        method: "get",
    });
}

// 服务商入驻/商家入驻校验账户是否已存在
export function checkUserNameExistForRegister(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checkaccountforregister/${name}`, {
        method: "get",
    });
}

// // 商家入驻校验公司名称是否已存在 不在需要检验重复
// export function checkMerNameExist(name: string): Promise<any> {
//   return request(`${baseUrl}/api/v1/merchants/checksamename/${name}`, {
//     method: "get",
//   });
// }

// 商家入驻审核不通过，数据回填，从服务器获取入驻的信息
export function getJoinData(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/joindata`, {
        method: "get",
    });
}

// 商家入驻校验公司名称是否已存在
export function checkShopNameExist(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/checksameshopname/${name}`, {
        method: "get",
    });
}

// 商家入驻校验公司机构代码是否已存在
export function checkMerCodeExist(code: string): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/checksamecode/${code}`, {
        method: "get",
    });
}

// 服务商入驻校验公司机构代码是否已存在
export function checkOrgCodeExist(code: string): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checkcode/${code}`, {
        method: "get",
    });
}

// 校验服务商名称是否已经被注册
export function checkAccountNameExist(name: string): Promise<any> {
    return request(`${baseUrl}/api/v1/organizations/checkname/${name}`, {
        method: "get",
    });
}

// 校验商户账户是否已经被占用
export function checkMerAccountExist(account: string): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/checkaccount/${account}`, {
        method: "get",
    });
}

// 查询商户信息
export function fetchMerchantInfo(): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/info`);
}

// 商户进度
interface MerchantStatusParams {
    merchantId: string;
}

export function fetchMerChantStatus(params: MerchantStatusParams): Promise<any> {
    return request(`${baseUrl}/api/v1/merchants/status`, {
        method: "get",
        params,
    });
}

// 申请绑定支付宝
export function fetchAlipayOpenauth(path: string): Promise<any> {
    return request(`${baseUrl}/api/v1/alipay/openauth?path=${path}`, {
        method: "get",
    });
}

// 申请绑定支付宝授权支付
export function fetchAlipayAuthToken(path: string): Promise<any> {
    return request(`${baseUrl}/api/v1/alipay/authtoken?path=${path}`, {
        method: "get",
    });
}

// 绑定支付宝之后，获取绑定信息
export function fetchAlipayOpenauthInfo(path: string): Promise<any> {
    return request(`${baseUrl}/api/v1/alipay/res`, {
        method: "get",
    });
}

// 绑定支付宝之后，获取绑定信息
export function fetchAlipayAuthTokenInfo(path: string): Promise<any> {
    return request(`${baseUrl}/api/v1/alipay/tokenres`, {
        method: "get",
    });
}

//机构入驻
export function institutionsIn(params: OrgInfoJoinParams): Promise<any> {
    return request(`${baseUrl}/api/v1/org/join/add`, {
        method: "post",
        data: params,
    });
}


// -------------------------------- 忘记密码 ---------------------------------
// 点击立即重置
export function clickReset(params: any) {
    return request(`${baseUrl}/api/v1/verification/phoneNum`, {
        method: "post",
        data: params,
    });
}

// 重置密码点击提交
export function userRepassword(params: any) {
    return request(`${baseUrl}/userapp/userRepassword`, {
        method: "post",
        data: params,
    });
}

//
export function modifyThePhoneNumber(params: any) {
    return request(`${baseUrl}/api/v1/userRemobile`, {
        method: "post",
        data: params,
    });
}

// -------------------------------- 个人中心 ---------------------------------
//个人中心-订单数量
export function orderQuantity(params: any) {
    return request(`${baseUrl}/api/v1/orderNumber`, {
        method: "get",
        params,
    });
}

//个人中心-浏览记录展示
export function browsingHistory(params: any) {
    return request(`${baseUrl}/api/v1/browseRecord`, {
        method: "get",
        params,
    });
}

//新增浏览记录
export function addBrowsingHistory(params: any) {
    return request(`${baseUrl}/api/v1/addBrowseRecord`, {
        method: "post",
        data: params,
    });
}

// 服务订单-查询服务订单列表
export function getServiceOrdersList(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/list`, {
        method: "get",
        params,
    });
}

// 搜索服务名称/服务单号
export function getServiceOrdersSearch(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/search`, {
        method: "get",
        params,
    });
}

//服务订单-服务订单状态下拉
export function getServiceOrdersStatus(params: any) {
    return request(`${baseUrl}/api/v1/dicList/service-order-status`, {
        method: "get",
        params,
    });
}

// 服务订单-下拉框查询服务订单列表
export function getServicedropList(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/drop/list`, {
        method: "get",
        params,
    });
}

// 服务订单-服务状态下拉
export function getServiceStatus(params: any) {
    return request(`${baseUrl}/api/v1/dicList/service-status`, {
        method: "get",
        params,
    });
}

// 查询服务列表
export function getServiceList(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/services/list`, {
        method: "get",
        params,
    });
}

//服务订单（预约单）取消原因
export function getCancelServiceList(params: any) {
    return request(`${baseUrl}/api/v1/dicList/cancel-reasons`, {
        method: "get",
        params,
    });
}

// 取消服务
export function cancelOrderSubmit(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/cancel`, {
        method: "POST",
        data: params,
    });
}

// 确认服务完成
export function comfrimService(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/service/confirm`, {
        method: "POST",
        ...params,
    });
}

// 服务订单详情
export function serviceOrderDetal(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/info`, {
        method: "get",
        params,
    });
}

//2类 查询订单服务详情
export function classServie2Detail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services-sec/detail`, {
        method: "get",
        params,
    });
}

//3.1 类 查询订单服务详情
export function classServie31Detail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services-thipt/detail`, {
        method: "get",
        params,
    });
}

//3.2类 查询订单服务详情
export function classServie32Detail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services-thipth/detail`, {
        method: "get",
        params,
    });
}

//个人中心---服务订购单
export function serviceOrderList(params: any) {
    return request(`${baseUrl}/api/v1/agency/orderService/order/list`, {
        method: "get",
        params,
    });
}

//服务订购单详情
export function serviceOrderDetailsDate(params: any) {
    return request(`${baseUrl}/api/v1/agency/orderService/order/info/${params.orderId}`, {
        method: "get",
        params,
    });
}

//取消订购单
export function reasonOfCancellation(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/orderService/order/cancel/${params.orderId}`, {
        method: "post",
        data: params,
    });
}
//确认订购单
export function confirmationOrder(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/agency/orderService/order/confirm/${params.orderId}`, {
        method: "post",
        data: params,
    });
}

//删除取消订购(删除到回收站)
export function deletingServiceOrder(params: any) {
    return request(`${baseUrl}/api/v1/agency/orderService/order/recycle`, {
        method: "post",
        data: params,
    });
}

//彻底删除
export function completelyCancel(params: any) {
    return request(`${baseUrl}/api/v1/agency/orderService/order/delete`, {
        method: "post",
        data: params,
    });
}

//恢复、批量恢复
export function batchRecovery(params: any) {
    return request(`${baseUrl}/api/v1/agency/orderService/order/restore`, {
        method: "post",
        data: params,
    });
}


//  个人中心---我的商品
//订单列表
export function orderFormList(params: any) {
    params.pageSize = 10;
    return request(`${baseUrl}/api/v1/orders/list`, {
        method: "get",
        params,
    });
}

export function whetherToken(params: any) {
    params.pageSize = 10;
    return request(`${baseUrl}/api/v1/token`, {
        method: "get",
        params,
    });
}

//查询商品订单详情
export function TheOrderDetails(params: any) {
    return request(`${baseUrl}/api/v1/orders/info/${params.orderInfoId}`, {
        method: "get",
        params,
    });
}

//服务支付成功调用接口
export function serviceSuccessful(params: any) {
    return request(`${baseUrl}/api/v1/services/pay/info/${params.mainServiceOrderCode}`, {
        method: "get",
        params,
    });
}

//服务获取联合订单编号
export function jointOrder(params: any) {
    return request(`${baseUrl}/api/v1/services/order/pay/later/${params.orderId}`, {
        method: "post",
        params,
    });
}

//已发货或已收货状态下获取订单信息
export function physicalDistribution(params: any) {
    return request(`${baseUrl}/api/v1/orders/info/shipping/${params.orderInfoId}`, {
        method: "get",
        params,
    });
}

//催发货
export function businessman(params: any) {
    return request(`${baseUrl}/api/orders/urge-ship`, {
        method: "post",
        data: params,
    });
}

//进行商品评价时展示的店铺和商品信息
export function evaluationShow(params: any) {
    return request(`${baseUrl}/api/v1/commodities/comments/merchant-comment`, {
        method: "get",
        params,
    });
}

//取消商品订单列表
export function PushOrderReason(params: any) {
    return request(`${baseUrl}/api/v1/orders/cancelReason`, {
        method: "get",
        params,
    });
}

//申请退款-订单详情
export function DetailsRefund(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/order-info`, {
        method: "get",
        params,
    });
}

//提交退款申请-商品
export function commodityRefund(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/return-money`, {
        method: "post",
        data: params,
    });
}

//修改退款申请-商品
export function returnCommodityUpdate(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/return-money/update`, {
        method: "post",
        data: params,
    });
}

//撤销商品退款申请
export function RefundedGoods(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/cancel`, {
        method: "post",
        data: params,
    });
}

//获取售后协商历史
export function NegotiationHistory(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/negotiation/commodities/history`, {
        method: "get",
        params,
    });
}

//添加商品评价
export function commentOnCommodity(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/commodities/comments/add`, {
        method: "post",
        data: params,
    });
}

//个人中心-用户评分
export function giveAMark(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/user`, {
        method: "get",
    });
}

// 取消订单
export function cancellationOfOrder(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/cancel`, {
        method: "post",
        data: params,
    });
}

//延长收货时间
export function goIntoOvertime(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/commodity-orders/delay`, {
        method: "post",
        data: params,
    });
}

// 商品订单-放入回收站
export function commodityRecyclingStation(params: any) {
    return request(`${baseUrl}/api/v1/orders/delete`, {
        method: "post",
        data: params,
    });
}

//商品订单回收站-批量删除
export function deleteBatches(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/order-recycler/delete`, {
        method: "post",
        data: params,
    });
}

//商品订单回收站-批量还原
export function massReduction(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/order-recycler/reduction`, {
        method: "post",
        data: params,
    });
}

// 商品撤销退款申请
export function withdrawApplication(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/cancel`, {
        method: "post",
        data: params,
    });
}

// 获取申请商品退款列表
export function changingRefundingList(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/history`, {
        method: "get",
        params: params,
    });
}

// 提交（修改）退款申请-退款原因
export function aRefundReason(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/reasons`, {
        method: "get",
    });
}

//售后查询可申请退换货列表
export function ApplyExchangeList(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/list`, {
        method: "get",
        params: params,
    });
}

//查询售后申请详情
export function applicationDetails(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/info`, {
        method: "get",
        params: params,
    });
}

//撤销退款申请时查看是否可申请
export function whetherRevokeRefund(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/if-cancel`, {
        method: "get",
        params: params,
    });
}

//撤销退款申请时查看是否可申请-服务
export function whetherRevokeRefundService(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/if-cancel`, {
        method: "get",
        params: params,
    });
}

//申请平台介入-商品
export function getInvolved(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/intervention/${params.returnId}`, {
        method: "post",
        params,
    });
}

//申请平台介入-服务
export function getInvolvedService(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/intervention`, {
        method: "post",
        params,
    });
}

//查询售后申请状态
export function aftersalesApplicationStatus(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/status`, {
        method: "get",
        params: params,
    });
}

//获取卖家同意退货时查询信息
export function addressSeller(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/commodities/approve/info`, {
        method: "get",
        params: params,
    });
}

//查询快递公司列表
export function courierServicesCompany(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/delivery/company/list`, {
        method: "get",
    });
}

//查询退换货快递信息
export function expressDelivery(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/delivery/info`, {
        method: "get",
        params,
    });
}

//申请售后-提交买家退货信息
export function refundInformation(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/after-sale/return-commodity`, {
        method: "post",
        data: params,
    });
}

//获取未付款订单付款-获取订单信息
export function unpaidInformation(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/repay/info/${params.orderId}`, {
        method: "get",
        params,
    });
}

// 17、确认商品订单完成
export function confirmReceipt(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/commodity-orders/confirm`, {
        method: "post",
        data: params,
    });
}
//商品订单开票
export function invoicingCommodityOrders(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/set/invoices`, {
        method: "post",
        data: params,
    });
}
// 10、服务订单-放入回收站
export function deleteOrder(params: any) {
    return request(`${baseUrl}/api/v1/service-orders/delete`, {
        method: "post",
        data: params,
    });
}

// 13、服务订单回收站-批量删除
export function deleteByQuery(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-order-recycler/delete`, {
        method: "post",
        data: params,
    });
}

//14.服务订单回收站-批量还原
export function huanyuanByQuery(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-order-recycler/reduction`, {
        method: "post",
        data: params,
    });
}

//44、评价时查询机构信息
export function evaluationOrgInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-order/evaluation/orgInfo`, {
        method: "get",
        params,
    });
}

// 45、评价时查询订单对应服务信息
// /api/v1/service-orders/evaluation/serviceInfo
export function evaluationServiceInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/evaluation/serviceInfo`, {
        method: "get",
        params,
    });
}

// 15、添加服务评价
// /api/v1/services/comments/add
export function commentsAdd(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/add`, {
        method: "post",
        data: params,
    });
}

// 退款原因
// 20、提交（修改）退款申请-退款原因
export function refundReasons(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/dicList/refund-reasons`, {
        method: "get",
        params,
    });
}

// /api/v1/after-sale/services/order-info
// 49、申请退款第一步中订单详情
export function orderInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/order-info`, {
        method: "get",
        params,
    });
}
//根据服务订单号获取当前服务状态
export function  returnServiceOrderCode(orderCode: any, relationId): Promise<any> {
    return request(`${baseUrl}/api/v1/order/status/${orderCode}?relationId=${relationId}`, {
        method: "get",
         params: orderCode,
    });
}

// 46、申请退款时上传图片
export function uploadPicture(): string {
    return `${baseUrl}/api/v1/after-sale/services/upload`;
}

// 21、提交退款申请-服务
// /api/v1/after-sale/services/return-money
export function returnMoney(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money`, {
        method: "post",
        data: params,
    });
}

//24、查询售后申请详情-服务
// /api/v1/after-sale/services/info
export function returnServiceinfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/info`, {
        method: "get",
        params,
    });
}

// 48、修改退款申请是查申请数据
export function applicationInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/application-info`, {
        method: "get",
        params,
    });
}

// 22、修改退款申请
export function returnMoneyUpdate(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/update`, {
        method: "post",
        data: params,
    });
}

// 25、撤销退款申请
export function returnMoneyCancel(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/cancel`, {
        method: "post",
        data: params,
    });
}

// 23、查询售后申请状态-服务
///api/v1/after-sale/services/status
export function afterSaleStatus(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/status`, {
        method: "get",
        params,
    });
}

// /api/v1/after-sale/negotiation/services/history
// 26、查询售后协商历史-服务
export function afterSaleServiceHistory(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/negotiation/services/history`, {
        method: "get",
        params,
    });
}

// 42、服务订单-查询所有咨询信息
export function getConsultationsList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/consultations/list`, {
        method: "get",
        params,
    });
}

// 43、服务订单-删除咨询问题
// /api/v1/consultations/delete/{consultationId}
export function deleteConsultationId(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/consultations/delete/${params.consultationId}`, {
        method: "post",
        data: params,
    });
}

// 32、查询预约单列表
export function reservationOrderList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation-order/list`, {
        method: "get",
        params,
    });
}

// 33、预约单状态下拉
export function reservationOrderStatus(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/dicList/reservation-order-status`, {
        method: "get",
        params,
    });
}

// 34、下拉列表查询机构预约单列表
// /api/v1/reservation-order/drop/list
export function reservationOrderDropList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation-order/drop/list`, {
        method: "get",
        params,
    });
}

//下拉列表查询诊疗预约单列表
export function diagnosisTreat(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/reservationList/${params.status}`, {
        method: "get",
        params,
    });
}


// /api/v1/reservation-order/cancel
// 36、取消预约单
export function getReservationCancel(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation-order/cancel`, {
        method: "post",
        data: params,
    });
}

//取消诊疗预约单
export function diagnosisTreatCancel(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/cancel`, {
        method: "post",
        data: params,
    });
}

// 35、查询预约单详情
export function getReservationDetail(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation-order/info`, {
        method: "get",
        params,
    });
}

//查询诊疗预约单详情
export function diagnosisTreatmentDetails(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/reservation/info/${params.reservationId}`, {
        method: "get",
        params,
    });
}

//获取诊疗单数据
export function diagnosisAndTreatData(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/evaluation/service/${params.reservationId}`, {
        method: "get",
        params,
    });
}

// 37、服务预约单-放入回收站
export function reservationDelete(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation-order/delete`, {
        method: "post",
        data: params,
    });
}

//删除诊疗预约单
export function diagnosisTreatDelete(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/del/${params.reservationId}`, {
        method: "post",
        data: params,
    });
}

// 38、服务预约单回收站-批量删除
export function reservationRecyclerDelete(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-reservation-recycler/delete`, {
        method: "post",
        data: params,
    });
}

//诊疗预约单-删除
export function medicalAppointmentListDeleted(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/remove`, {
        method: "post",
        data: params,
    });
}

//诊疗预约单查看报告
export function medicalReport(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/doc/${params.reservationId}`, {
        method: "get",
        params,
    });
}

// 39、服务预约单回收站-批量还原
export function reservationRecyclerReduction(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-reservation-recycler/reduction`, {
        method: "post",
        data: params,
    });
}

//诊疗预约单-还原
export function RestoreMedicalAppointmentForm(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/restore`, {
        method: "post",
        data: params,
    });
}

// 40、预约单评价
export function reservationCommentAdd(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/comments/reservation/add`, {
        method: "post",
        data: params,
    });
}

//诊疗预约单评价
export function appointmentOrderEvaluation(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/evaluation`, {
        method: "post",
        data: params,
    });
}

// 52、待开启的服务是否可以延长开启时间
export function serviceIfExtend(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services/if-extend-start`, {
        method: "get",
        params,
    });
}

//53、待开启的服务延长开启时间
export function serviceStartExtend(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services/delay-start`, {
        method: "post",
        data: params,
    });
}

export function serviceConfirmExtend(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/delay`, {
        method: "post",
        data: params,
    });
}

// 申请发票
export function invoicesAdd(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/invoices/add`, {
        method: "post",
        data: params,
    });
}

// 查询发票详情
export function invoicesInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/invoices/info`, {
        method: "get",
        params,
    });
}

// 修改发票申请
export function invoicesUpdate(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orders/invoices/update`, {
        method: "post",
        data: params,
    });
}

// 47、取消预约单记录
// /api/v1/reservation/cancel/list
export function reservationCancelList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/cancel/list`, {
        method: "get",
        params,
    });
}

// /api/v1/service-orders/order/confirm
// 17、确认服务订单完成
export function serviceOrderConfirm(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/order/confirm`, {
        method: "post",
        data: params,
    });
}

// 50、撤销退款申请时查看是否可申请
// /api/v1/after-sale/services/return-money/if-cancel
export function returnMoneyIfCancel(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/after-sale/services/return-money/if-cancel`, {
        method: "get",
        params,
    });
}

// 51、服务详情-进行中的服务是否可以延长确认时间
export function serviceIfExtendConfirm(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services/if-extend-confirm`, {
        method: "get",
        params,
    });
}

// 52、待开启的服务是否可以延长开启时间
export function ifExtendStart(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/service-orders/services/if-extend-start`, {
        method: "get",
        params,
    });
}

// -------------------------------- 个人中心-2类服务开启服务demo ---------------------------------
// 在线检测—开启服务
export function openService2(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/start-service`, {
        method: "post",
        params,
    });
}

//在线检测—开始服务
export function openService2Enjoy(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service`, {
        method: "post",
        data: params,
    });
}

//在线检测—获取检测结果
export function openService2Result(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/get-result`, {
        method: "get",
        params,
    });
}

//在线检测—查看正在开启服务的状态
export function openService2Status(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/serving-status`, {
        method: "get",
        params,
    });
}

export function ylFormList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/ylform/{form-dic-id}/list`, {
        method: "post",
    });
}

///api/v1/citys/first-letter/list
export function firstLetterList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/citys/first-letter/list`, {
        method: "get",
    });
}

///api/v1/citys/three-level/list
export function threeLevelList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/citys/three-level/list`, {
        method: "get",
    });
}

///api/v1/citys/search-input/list
export function citySearchInputList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/citys/search-input/list`, {
        method: "get",
        params,
    });
}

// /api/v1/pension-service/recommend/list

export function pensionServiceRecommendList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/recommend/list`, {
        method: "get",
        params,
    });
}

// 养老模式—根据机构id获取它的评价信息
export function orgCommentsList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/org/comments/list`, {
        method: "get",
        params,
    });
}

// 养老服务-获取第二级服务分类的详情介绍（文案介绍）
export function serviceTypeInfo(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/pension-service/service-type/info`, {
        method: "get",
        params,
    });
}

// 养老模式 - 获取机构基本信息（父或者子机构基本信息）（如果查询的是子机构，也会把其下属的其他子机构查询出来）
interface briefOriginParams {
    orgId: string;
}

export function briefInfo(params: briefOriginParams): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/${params.orgId}/brief-info`, {
        method: "get",
        params,
    });
}

//养老模式—查询某一机构的所有服务项目（用于显示第二级服务分类及其下属服务项目）
export function serviceList(params: any): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});

    return request(`${baseUrl}/api/v1/org/services/list`, {
        method: "get",
        params,
    });
}

//养老服务-第二级服务分类详情页下面的“其他服务商”查询（查询出来的是父机构）
export function otherOrgs(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/second-category/other-orgs`, {
        method: "get",
        params,
    });
}

// 服务结算—获取用户可用的代币
export function userdaibi(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/settlement/user-daibi`, {
        method: "get",
        params,
    });
}

//服务结算-获取当前用户的服务地址列表
export function getServiceAddressList(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/user-address/get/service-address`, {
        method: "get",
        params,
    });
}

// 服务结算-为当前登录的用户新增一个服务地址
export function addAddress(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/user-address/add/service-address`, {
        method: "post",
        data: params,
    });
}

// 养老模式—机构详情，提交预约单
export function reservationAdd(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/orgs/reservation/add`, {
        method: "post",
        data: params,
    });
}

// 远程医疗预约单
export function telemedicineAdd(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/reservation/telemedicine/reservation`, {
        method: "post",
        data: params,
    });
}

// 服务首页-获取“养老模式”栏目下的类型列表（仅获取模式名称列表）
export function dicListType(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/dicList/${params.typeId}`, {
        method: "get",
    });
}

// 服务订单结算
export function settlement(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/settlement`, {
        method: "post",
        data: params,
    });
}

// 养老模式—查询某一机构的所有服务项目（用于显示第二级服务分类及其下属服务项目）
// /api/v1/org/services/list
export function orgServicesList(params: any): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});

    return request(`${baseUrl}/api/v1/org/services/list`, {
        method: "get",
        params,
    });
}

// 养老服务—单一服务项目详情页下面的“同类型服务项目”查询
export function sameTypeList(params: any): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});
    return request(`${baseUrl}/api/v1/services/same-type/list`, {
        method: "get",
        params,
    });
}

// 服务结算—订单第三方付款
export function servicePay(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/services/pay`, {
        method: "post",
        data: params,
    });
}

// 用户是否已收藏该服务商或服务项目
export function collectionsOrgService(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/collections/org-service/collected`, {
        method: "get",
        params,
    });
}

// 养老模式—查询某一机构下某一特定第二级服务分类下的所有服务项目
export function thirdCategoryList(params: any): Promise<any> {
    Object.assign(params, {cityId: localStorage.getItem("provinceId")});

    return request(`${baseUrl}/api/v1/org/services/third-category/list`, {
        method: "get",
        params,
    });
}


// -----------------------------------------征信平台------------------------------------------//

// 征信平台首页获取优质服务商等信息
export function getMainIndexInfo(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/credit/investigation/recommend`, {
        method: "get",
        params,
    });
}

// 征信平台 获取服务商详情
export function getServiceProviderDetailInfo(agencyId: string): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/serviceProvider/${agencyId}`, {
        method: "get",
    });
}

// 征信平台 获取养老机构详情
export function getOrganizationDetailInfo(agencyId: string): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/organization/${agencyId}`, {
        method: "get",
    });
}

// 征信平台 获取商户详情
export function getMerchantDetailInfo(agencyId: string): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/merchant/${agencyId}`, {
        method: "get",
    });
}

// 征信平台 获取服务详情
export function getServiceDetailInfo(serviceId: string): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/service/${serviceId}`, {
        method: "get",
    });
}

// 征信平台 获取商品详情
export function getCommodityDetailInfo(commodityId: string): Promise<any> {
    return request(`${baseUrl}/api/v1/credit/investigation/commodity/${commodityId}`, {
        method: "get",
    });
}

// -----------------------------------------服务上架------------------------------------------//

// 服务上架—包含文件
export function enjoyService(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service`, {
        method: "post",
        data: params,
    });
}

// 服务上架—不含文件
export function enjoyService1(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/enjoy-service1`, {
        method: "post",
        data: params,
    });
}

// 服务上架—加密
export function enjoyService_token_id_RSA_encode(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/token_id_RSA_encode`, {
        method: "post",
        data: params,
    });
}

// 服务上架—test
export function enjoyService_TestClassdto(params: any): Promise<any> {
    return request(`${baseUrl}/api/v1/api-services/zaixian/TestClassdto`, {
        method: "post",
        data: params,
    });
}

// -----------------------------------------征信平台接口------------------------------------------//

//input搜索（综合结果）
export function comprehensiveResult(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/credit/investigation/search/all/${params.choice}?query=${params.value}`, {
        method: "get",
    });
}

//input搜索（养老机构、服务商、商户）
export function comprehensiveSearch(params: any): Promise<any> {
    return request(`${baseUrl2}/api/v1/credit/investigation/search?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&queryType=${params.queryType}&creditStatus=${params.choice}&query=${params.value}`, {
        method: "get",
    });
}

//http://api.test.mall.fytlun.com/api/v1/credit/investigation/search?pageIndex=1&pageSize=10&queryType=0&creditStatus=395&query=乐天养老服务中心






































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Input, Radio, Select, Steps, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceProcessSteps from "./classServicesteps.vue";
import {openService2Result} from "@/services/api";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";
import {baseUrl, request} from "@/services/http";

Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        ServiceProcessSteps,
        Breadcrumb,
        MyStepBar
    }
})
export default class extends Vue {

    Type1ForNextStep = false;//上传文件字符串结果
    Type2ForNextStep = false;//上传文件json结果
    Type3ForNextStep = false;//跳转网页
    Type4ForNextStep = false;//跳转SDK组件
    Type5ForNextStep = false;//枣庄学院短信诈骗判断
    NormalMsgFlag = false; //枣庄学院正常短信
    UnnormalMsgFlag = false; //枣庄学院正常短信
    Type6ForNextStep = false;//跳转网页得到结果
    Type7ForNextStep = false;//跳转网页得到结果
    Type9ForNextStep = false;//跳转网页得到结果网新
    Type12ForNextStep = false;//平安保险
    Type14ForNextStep = false;//河北心电
    Type15ForNextStep = false;//河北心电
    buttonFlag = true;//按钮点完隐藏
    status = 1;
    result = "";
    ZZZmsg = "";
    stepList = [
        {
            name: "提交资料/影像",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "反馈结果",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        },
    ];
    leftForm: any;
    rightForm: any;
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的服务",
            path: "/personal/myService",
            significance: 0
        },
        {
            name: "服务开启",
            path: "/personal/classServiceOne",
            significance: 1
        },
    ]
    marrige = [
        {label: "已婚", value: "1"},
        {label: "未婚", value: "2"}
    ];
    url_result = "";

    pagination = {
        total: 3,
        showTotal: (total: number) => `共${total}条记录`,
        itemRender: (current: any, type: string, originalElement: any): any => {
            if (type === "prev") {
                return "< 上一页";
            } else if (type === "next") {
                return "下一页 >";
            }
            return originalElement;
        }
    };

    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 9}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 15}
        }
    };

    beforeMounted() {
        this.leftForm = this.$form.createForm(this);
        this.rightForm = this.$form.createForm(this);
    }

    created() {
        this.getserviceTypeForNextStep();
    }

    getserviceTypeForNextStep() {
        request(`${baseUrl}/api/v1/api-services/zaixian/serviceType_forNextStep`, {
            method: "get",
            params: {
                relationId: this.$route.query.relationId,
            },
        }).then((res: any) => {

            switch (parseInt(res.data.resultInfo)) {
                case 1:
                    this.Type1ForNextStep = true;
                    break;
                case 2:
                    this.Type2ForNextStep = true;
                    break;
                case 3:
                    this.Type3ForNextStep = true;
                    break;
                case 4:
                    this.Type4ForNextStep = true;
                    break;
                case 5:
                    this.Type5ForNextStep = true;
                    break;
                case 6:
                    this.Type6ForNextStep = true;
                    break;
                case 7:
                    this.Type7ForNextStep = true;
                    break;
                case 9:
                    this.Type9ForNextStep = true;
                    break;
                case 12:
                    this.Type12ForNextStep = true;
                    break;
                case 14:
                    this.Type14ForNextStep = true;
                    break;
                case 15:
                    this.Type15ForNextStep = true;
                    break;
            }
        });
    }


    getResultType7() {
        let relationId = this.$route.query.relationId;
        let url2 = this.$route.query.url2;
        this.url_result = url2 + relationId
        let temp_window: any = window.open('_blank');
        temp_window.location = this.url_result;
    }

    getResultType9() {

        let url2 = this.$route.query.url2;
        this.url_result = url2
        let temp_window: any = window.open('_blank');
        temp_window.location = this.url_result;
    }

    getResultType15() {

        let url2 = this.$route.query.HDXDurl;
        this.url_result = url2
        fetch(url2)
            .then(response => response.json())
            .then(json => {
                    this.result = JSON.stringify(json.data);
                }
            )

    }

    getResult() {
        openService2Result({
            relationId: this.$route.query.relationId,
        }).then((res) => {
            if (res.code === 0) {
                this.result = res.data.resultInfo;
            }
        });
    }

    getResultType5() {
        this.buttonFlag = false;
        openService2Result({
            relationId: this.$route.query.relationId,
        }).then((res) => {
            if (res.code === 0) {
                let ZZmsg = res.data.resultInfo;
                let jsonmsg = JSON.parse(ZZmsg)

                if (jsonmsg.isFraud == "正常短信") {
                    this.NormalMsgFlag = true;
                    this.ZZZmsg = ZZmsg.slice(25, -1);
                } else if (jsonmsg.isFraud == "异常短信") {
                    this.UnnormalMsgFlag = true;
                    this.ZZZmsg = ZZmsg.slice(25, -1);
                }
            }
        });
    }


    handleTypeChange() {
    }

    handleLeftSubmit() {
    }

    handleRightSubmit() {
    }
}



    import {Component, Vue} from "vue-property-decorator";

    @Component({})
export default class LeaderboardBody extends Vue {
  data = [];
  setData(data) {
    this.data = data.commodities;
    this.data.sort((a:any, b:any) => {
      return (a.order) - b.order;
    });
  }
  jump(id) {
    this.$router.push("/detail/" + id);
  }
}

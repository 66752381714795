














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReturnStep extends Vue {
  @Prop() private stepList!: [];
  @Prop() private curStep!: number;
}












































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
    props: {
        status: Number
    }
})
export default class DetailPriceCard extends Vue {
    @Prop() commodityData: any;

    paymentList="";

    @Watch("commodityData.status", { immediate: true, deep: true })
    getOrderStatus(newVal, oldVal) {
            if(newVal ===261){
                this.paymentList="需付总额"
            }else{
                this.paymentList="实付总额"
            }
    }

}





















































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import organizationDetailHeader from "@/components/organizationDetailHeader.vue";
import SubOrgItem from "@/components/SubOrgItem.vue";
import SubServiceItem from "@/components/SubServiceItem.vue";
import ServiceComment from "@/components/ServiceComment.vue";

import AMapLoader from "@amap/amap-jsapi-loader";
import {organizationDetail, orgCommentsList, sameTypeList} from "@/services/api";
import {Mutation} from "vuex-class";
import nearbyItem from "./nearbyItem.vue";
import beNull from "@/components/beNull.vue";

declare var $: any;
@Component({
    components: {
        Nav,
        Footer,
        Header,
        organizationDetailHeader,
        SubOrgItem,
        SubServiceItem,
        ServiceComment,
        nearbyItem,
        beNull
    },
})
export default class extends Vue {
    @Mutation SET_ORGFORM;
    isHide = false;
    pagination: any = {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    };
    AMap: any;
    showContent: any = [];
    hiddenContent: any = [];
    orgId: any = "";
    commentsList = [];
    orgOveralScore = 0;
    orgDetail = {};
    comments = [];
    nearbyOrgList = [];
    beNullList:{}
    nearStore = [];
    serviceCompliance = 0;
    organizationAttitude = 0;
    organizationSpeed = 0;

    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});

        this.pagination.pageIndex = page;
        this.orgCommentsList(this.orgId);
    }

    orgChildNodesShowPage = [];

    get _location() {
        const addressProvince = this.orgDetail["addressProvince"] || "";
        const addressCity = this.orgDetail["addressCity"] || "";
        const addressArea = this.orgDetail["addressArea"] || "";
        const addressStreet = this.content["addressStreet"] || "";

        const detailAddress = this.orgDetail["detailAddress"] || "";

        return (
            addressProvince +
            addressCity +
            addressArea +
            addressStreet +
            detailAddress
        );
    }

    thirdCategoryShowPage = [];

    orgCommentsList(orgId) {
        orgCommentsList({
            ...this.pagination,
            orgId: orgId,
        }).then((res) => {
            this.orgOveralScore = res.data.orgOveralScore;
            this.serviceCompliance = res.data.serviceCompliance;
            this.organizationAttitude = res.data.organizationAttitude;
            this.organizationSpeed = res.data.organizationSpeed;

            //
            this.comments = res.data.comments;
            this.pagination.total = res.data.totalCount;
        });
    }

    sameTypeList(params) {
        sameTypeList({...params}).then((res) => {
        });
    }

    content = "";

    get hiddenContentBoolen() {
        let a: any = this.content.match(/<p>/g) || [];
        return a.length > 10 || this.content.length > 750 || this.shouheight >= 228;
    }

    checkStatus = "";
    //40 - 审核中
    // 41 - 审核成功
    // 42 - 审核不通过

    shouheight = 0;

    mounted() {
        this.orgId = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1,
            window.location.href.length
        );
        organizationDetail({orgId: this.orgId}).then((res) => {
            this.orgDetail = res.data;
            var a = this.orgDetail["checkStatus"] || "41";
            this.checkStatus = a.toString()
            this.orgChildNodesShowPage = this.orgDetail["orgChildNodes"];
            this.thirdCategoryShowPage = this.orgDetail["thirdCategory"];
            this.orgChildNodesShowPage = this.orgChildNodesShowPage.slice(0, 6);
            this.thirdCategoryShowPage = this.thirdCategoryShowPage.slice(0, 10);
            this.nearbyOrgList = this.orgDetail["nearbyOrgList"].slice(0, 6);
            this.content = this.orgDetail["orgIntro"];

            this.SET_ORGFORM((this as any).orgDetail.orgFormJson);
            this.initMap();
            setTimeout(() => {
                const el: any = document.querySelector(".detailInfo-snas");
                this.shouheight = el.offsetHeight;
            });
        });
        this.orgCommentsList(this.orgId);
        this.beNullList={
            title:'很抱歉，没有找到相应养老机构信息',
            list:[{data:"您可以:"},{data:"1.该养老机构可能已经下架或重新审核中，您可以联系平台咨询。"},{data:"2.检查刚才的输入"},{data:"3.去其他的地方逛逛"}],
            path:'/personal/myCollection?type=5',
            pathName:'收藏的养老机构'
        }
    }

    async initMap() {
        const AMap = await AMapLoader.load({
            key: "c4ac5dbaa55b2994a57c6b369f28af53",
            plugins: ["AMap.Geocoder"],
            version: "1.4.15",
        });
        const map = new AMap.Map("Map");
        const geocoder = new AMap.Geocoder({
            city: this.orgDetail["addressCity"],
            batch: true,
        });
        geocoder.getLocation(this.orgDetail["detailAddress"], (state, result) => {
            if (state === "complete" && result.geocodes.length) {
                const markers: any[] = [];
                for (let i = 0; i < result.geocodes.length; i += 1) {
                    var markerContent = `<div class="marker" ><img src="http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png" alt=""></div>`;
                    var marker = new AMap.Marker({
                        position: result.geocodes[i].location,
                        content: markerContent,
                    });
                    markers.push(marker);
                }

                map.add(markers);
                map.setFitView(markers);
            }
        });
    }

    toDetail(item) {
        if (item.ifParentOrg === 1) {
            this.$router.push(`/orgDetail/${item.orgId}`);
        } else {
            this.$router.push({
                path: "/servieItemDetail",
                query: {
                    orgId: item.orgId,
                    title: item.storeName,
                },
            });
        }
    }

    onPageChange() {
    }
}



















































































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import {} from "ant-design-vue";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    Nav,
    Footer,
    Header
  }
})
export default class extends Vue {}



















    import {Component, Vue} from "vue-property-decorator";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ServiceProcessSteps from "@/views/Personal/OtherPage/MyService/classServicesteps.vue";
    import {Button, Form, Input, Radio, Select, Steps, Table} from "ant-design-vue";

    Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
@Component({
  components: {
    PersonalHeader,
    ServiceProcessSteps,
  },
})
export default class classProductService1 extends Vue {
  productCode:"";
  visible01 = true;
  visible02 = false;
  CORSmsg={};
  productDetailValue:"";
  onSearch(value){
    this.productCode=value;
    this.handleCode(value)

  };
  handleCode(value){
    if(value=="tju2021001"){
      fetch('http://112.74.177.95:7004/api/v1/yangbanjian/bedAlarm')
              .then(response => response.json())
              .then(json=>{
                this.CORSmsg=json.data;
                this.productDetailValue=json.data.bed_alarm;
                }
              )

    }

    this.visible01=false;
    this.visible02=true;
  }

}

<template>
  <div class="register">
    <!-- <div class="register-page-header">
      <router-link to="/login" :style="{ marginRight: '0.4rem', color: '#737373' }">会员登录</router-link>
      <router-link to="/register" :style="{ marginRight: '2.76rem', color: '#737373' }">免费注册</router-link>
    </div>-->
    <div class="login-logo">
      <div
        style="width:2.43rem;height:0.84rem"
        class="logo"
        @click="jumpToHome"
      ></div>
      <a-divider type="vertical" />
      <span class="text">忘记密码</span>
      <span style="float:right;font-size:0.2rem;position:absolute;right:0.3rem">
        去
        <router-link to="/login" style="color:#60c7c6">登录</router-link>
      </span>
    </div>
    <div class="form-box">
      <!-- 步骤条 -->
      <div class="step">
        <div class="step1"></div>
        <div class="step1-font">
          1
          <div style="color:#60C7C6" class="step-font">输入账号名</div>
        </div>
        <div class="step1-width"></div>
        <div
          :style="{ background: step >= 2 ? '#60C7C6' : '#ccc' }"
          class="step2-font"
        >
          2
          <div
            :style="{ color: step >= 2 ? '#60C7C6' : '#ccc' }"
            class="step-font"
          >
            验证身份
          </div>
        </div>
        <div
          :style="{ background: step >= 2 ? '#60C7C6' : '#ccc' }"
          class="step2-width"
        ></div>
        <div
          :style="{ background: step >= 3 ? '#60C7C6' : '#ccc' }"
          class="step3-font"
        >
          3
          <div
            :style="{ color: step >= 3 ? '#60C7C6' : '#ccc' }"
            class="step-font"
          >
            重置密码
          </div>
        </div>
        <div
          :style="{ background: step >= 3 ? '#60C7C6' : '#ccc' }"
          class="step3-width"
        ></div>
        <div
          :style="{ background: step >= 4 ? '#60C7C6' : '#ccc' }"
          class="step4-font"
        >
          4
          <div
            :style="{ color: step >= 4 ? '#60C7C6' : '#ccc' }"
            class="step-font"
          >
            重置成功
          </div>
        </div>
        <div
          :style="{ background: step >= 4 ? '#60C7C6' : '#ccc' }"
          class="step4-width"
        ></div>
      </div>
      <a-form :form="form" @submit="handleSubmit" hideRequiredMark>
        <!-- 步骤1 -->
        <div v-if="step == 1">
          <a-form-item v-bind="formItemLayout" :colon="false" label="用户名">
            <a-input
              style="width: 3.5rem;height:0.5rem;"
              placeholder="请输入账号名（用户名/手机号）"
              v-decorator="[
                'username',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写用户名！'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <!-- <a-form-item v-bind="formItemLayout" :colon="false" label="密码">
          <a-input
            style="width: 3.5rem;height:0.5rem;"
            placeholder="请输入密码"
            type="password"
            v-decorator="[
              'userpwd',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码'
                  }
                ]
              }
            ]"
          />
          <span
            :style="{ color:'#737373', marginLeft: '0.28rem', fontSize: '0.16rem' }"
          >密码长度8-20个字符（密码必须包括数字和英文字母）</span>
          </a-form-item>-->
          <!-- <a-form-item v-bind="formItemLayout" :colon="false" label="确认密码">
          <a-input
            style="width: 3.5rem;height:0.5rem;"
            placeholder="请再次输入密码"
            type="password"
            v-decorator="[
              'userpwd2',
              {
                rules: [
                  {
                    required: true,
                    message: '请再次输入密码'
                  },
                  {
                    validator: compareToFirstPassword
                  }
                ]
              }
            ]"
          />
          <span :style="{ color:'#737373', marginLeft: '0.28rem', fontSize: '0.16rem' }">请再次输入</span>
          </a-form-item>-->
          <!-- <a-form-item v-bind="formItemLayout" :colon="false" label="手机号">
          <a-input
            style="width: 3.5rem;height:0.5rem;"
            placeholder="可用于登录和密码找回"
            v-decorator="[
              'usermobile',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写手机号！'
                  }
                ]
              }
            ]"
          />
          <span
            :style="{ color:'#737373', marginLeft: '0.28rem', fontSize: '0.16rem' }"
          >请输入中国大陆手机号，其他用户不可见</span>
          </a-form-item>-->
          <a-form-item v-bind="formItemLayout" :colon="false" label="验证码">
            <a-input
              style="width: 2rem;height:0.5rem;"
              placeholder="请输入验证码"
              v-decorator="[
                'identify',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入验证码'
                    }
                  ]
                }
              ]"
            />
            <img
              style="cursor:pointer;font-size:0.3rem;width: 1.3rem;height:0.5rem;margin-left: 0.2rem;background-color: #f6ab1a; border: none"
              @click="handleToggleCode"
              :src="identifyUrl"
              alt
            />
          </a-form-item>
          <a-form-item v-bind="tailFormItemLayout" label=" ">
            <a-button
              type="primary"
              html-type="submit"
              style="width: 3.4rem;height:0.5rem;"
              @click="handleClickReset"
              >立即重置</a-button
            >
          </a-form-item>
        </div>
        <!-- 步骤2 -->
        <div style="margin-top:1.46rem" v-if="step == 2">
          <div
            v-if="isSending"
            style="text-align:center;font-size:0.2rem;color:#171717;margin-bottom:0.5rem"
          >
            已经向{{ username.slice(0, 3) }}****{{
              username.slice(7, 11)
            }}发送验证码
          </div>
          <a-form-item v-bind="formItemLayout" :colon="false" label="验证码">
            <a-input
              style="width: 2rem;height:0.5rem;"
              placeholder="请输入验证码"
              v-decorator="[
                'vcodes',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入验证码'
                    }
                  ]
                }
              ]"
            />
            <a-button
              v-if="!isSending"
              type="primary"
              style="width: 1.3rem;height:0.5rem;margin-left: 0.2rem;background-color: #f6ab1a; border: none"
              @click="handleSendCode"
              >发送验证码</a-button
            >
            <a-button
              v-if="isSending"
              disabled
              type="primary"
              style="width: 1.3rem;height:0.5rem;margin-left: 0.2rem;background-color: #dddddd; border: none"
              >{{ `已发送(${remainTime})` }}</a-button
            >
          </a-form-item>
          <a-form-item v-bind="tailFormItemLayout" label=" ">
            <a-button
              type="primary"
              html-type="submit"
              style="width: 3.4rem;height:0.5rem;"
              @click="handleClickNext"
              >下一步</a-button
            >
          </a-form-item>
        </div>

        <div v-if="step == 3">
          <a-form-item v-bind="formItemLayout" :colon="false" label="新密码">
            <a-input
              style="width: 3.5rem;height:0.5rem;"
              placeholder="密码长度8-20个字符，由数字和英文字母组成"
              type="password"
              v-decorator="[
                'userpwd',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入密码'
                    },
                    {
                      min: 8,
                      message: '密码最少为8位'
                    },
                    {
                      max: 20,
                      message: '密码最多为20位'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" :colon="false" label="确认密码">
            <a-input
              style="width: 3.5rem;height:0.5rem;"
              placeholder="请再次输入密码"
              type="password"
              v-decorator="[
                'userpwd2',
                {
                  rules: [
                    {
                      required: true,
                      message: '请再次输入密码'
                    },
                    {
                      validator: compareToFirstPassword
                    },
                    {
                      min: 8,
                      message: '密码最少为8位'
                    },
                    {
                      max: 20,
                      message: '密码最多为20位'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="tailFormItemLayout" label=" ">
            <a-button
              type="primary"
              html-type="submit"
              style="width: 3.4rem;height:0.5rem;"
              @click="handleClickSubmit"
              >提交</a-button
            >
          </a-form-item>
        </div>

        <div v-if="step == 4">
          <div style="display:flex;justify-content:center">
            <img
              style="width:0.87rem;height:0.87rem;margin-bottom:0.3rem"
              src="../assets/images/zhucechenggong_icon_zc.png"
              alt
            />
          </div>
          <div
            style="text-align:center;font-size:0.3rem;color:#030303;margin-bottom:0.75rem"
          >
            恭喜您，重置密码成功，请牢记新的登录密码！
          </div>
          <div style="display:flex;justify-content:center">
            <div
              @click="handleLogin"
              style="cursor:pointer;font-size:0.24rem;margin-bottom:0.4rem;width:3.4rem;height:0.5rem;background-color:#F88311;border:1px solid rgba(248,131,17,1);border-radius:3px;text-align:center;color:#fff;line-height:0.5rem"
            >
              重新登录
            </div>
          </div>
          <div style="display:flex;justify-content:center">
            <div
              @click="jumpToHome"
              style="cursor:pointer;font-size:0.24rem;margin-bottom:0.53rem;width:3.4rem;height:0.5rem;background-color:#60C7C6;border:1px solid rgba(96,199,198,1);border-radius:3px;text-align:center;color:#fff;line-height:0.5rem"
            >
              返回首页
            </div>
          </div>
        </div>
      </a-form>
    </div>
    <div class="register-footer">
      <span :style="{ marginRight: '0.28rem' }"
        >2019养老服务网 www.fytlun.com</span
      >
      <span>版权所有：天津大学 <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20035226号</a></span>
    </div>
  </div>
</template>

<script>
    import {Vue} from "vue-property-decorator";
    import {Button, Checkbox, Form, Input, message} from "ant-design-vue";
    import {clickReset, getVerificationCode, userRepassword} from "@/services/api";
    import {baseUrl, request} from "@/services/http";

    Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Form);
export default {
  data() {
    return {
      step: 1, // 步骤条步骤  1  2  3  4
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 14,
            offset: 10
          }
        }
      },
      remainTime: 60,
      isSending: false,
      checked: false,
      visible: false,
      username: "", // 用户名
      identify: 0, // 手机验证码
      identifyUuid: 0, // 随机uuid
      identifyUrl: "" // 验证码请求地址
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    this.identifyUuid = this.getUuid();
    this.identifyUrl = `${baseUrl}/mcaptcha.jpg?uuid=${this.identifyUuid}`;
  },
  methods: {
    // 随机生成32位uuid
    getUuid() {
      const s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23];
      const uuid = s.join("");
      return uuid;
    },
    // 点击切换验证码
    handleToggleCode() {
      this.identifyUuid = this.getUuid();
      this.identifyUrl = `${baseUrl}/mcaptcha.jpg?uuid=${this.identifyUuid}`;
    },

    // 点击立即重置
    handleClickReset() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let r = await request(
            `${baseUrl}/api/v1/exist/${this.form.getFieldValue("username")}`
          );
          if (!r.exist) {
            message.error(`用户名不存在或者手机号未注册`);
            return;
          }
          clickReset({
            userMobile: this.form.getFieldValue("username"),
            captcha: this.form.getFieldValue("identify"),
            uuid: this.identifyUuid
          }).then(res => {
            if (res && res.code === 0) {
              this.username = this.form.getFieldValue("username");
              if (r.isname) {
                request(
                  `${baseUrl}/api/v1/getmobile/${this.form.getFieldValue(
                    "username"
                  )}`
                ).then(res => {
                  this.username = res.mobile;
                });
              }
              this.step = 2;
            } else {
              message.error(res.msg);
            }
          });
        }
      });
    },
    // 点击下一步
    handleClickNext() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // this.step = 3;
          request(
            `${baseUrl}/api/v1/checkcode/${
              this.username
            }/${this.form.getFieldValue("vcodes")}`
          ).then(res => {
            if (res.check) {
              this.step = 3;
            } else {
              message.warning("验证码错误！");
            }
          });
        }
      });
    },

    // 点击发送验证码
    handleSendCode() {
      this.isSending = true;
      this.remainTime = 60;
      const t = setInterval(() => {
        if (this.remainTime > 0) {
          this.remainTime -= 1;
        } else {
          this.isSending = false;
          clearInterval(t);
        }
      }, 1000);
      getVerificationCode(this.username).then(res => {
        if (res && res.code === 0) {
          message.success("验证码已发送！");
        } else {
          message.error(res.msg);
        }
      });
    },
    // 检查验证码
    // checkIdentify(rule, value, callback) {
    //   const form = this.form;
    //   if (value && value != this.identify) {
    //     callback("验证码输入错误！");
    //   } else {
    //     callback();
    //   }
    // },
    // 检查新密码和确认密码
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userpwd")) {
        callback("两次密码输入不同!");
      } else {
        callback();
      }
    },
    // 跳转首页
    jumpToHome() {
      this.$router.push("/");
    },
    // 重新登录
    handleLogin() {
      this.$router.push("/login");
    },
    handleClickSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          userRepassword({
            phone_number: this.username,
            verification_code: this.form.getFieldValue("vcodes"),
            new_password: this.form.getFieldValue("userpwd")
          }).then(res => {
            if (res && res.code === 200) {
              this.step = 4;
            } else if (res && res.code === 405) {
              this.form.getFieldValue("vcodes").value ='';
              message.error("验证码失效，请从新获取。");
              this.step = 2;
            }  else {
              message.error(res.msg);
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="less">
.modal-image {
  width: 78px;
  height: 78px;
  margin: 0 auto;
  margin-bottom: 18px;
  background-image: url("../assets/images/zhucechenggong_icon_zc.png");
  background-position: center center;
  background-size: cover;
}
.register {
  input {
    font-size: 0.16rem;
  }
  .ant-form-explain {
    padding-top: 0.08rem;
  }
  .register-page-header {
    // width: 100%;
    height: 0.39rem;
    border-bottom: 1px solid #ebeef3;
    font-size: 0.14rem;
    color: #737373;
    // display: flex;
    // justify-content: flex-end;
    align-items: center;
    span {
      cursor: pointer;
    }
  }
  .login-logo {
    // width: 100%;
    height: 1.59rem;
    // display: flex;
    border-bottom: 1px solid #ebeef3;
    // justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 12.66rem;
    margin: 0 auto;
    .logo {
      // height: 0.84rem;
      width: 2.43rem;
      margin-left: 0.5rem;
      background-image: url("../assets/images/logo.png");
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .ant-divider {
      background-color: #737373;
    }
    .ant-divider,
    .ant-divider-vertical {
      height: 0.4rem;
      width: 2px;
      margin-left: 0.37rem;
      margin-right: 0.33rem;
    }
    .text {
      font-size: 0.3rem;
      color: #737373;
    }
    // .right-txt {
    //   position: absolute;
    //   color: #171717;
    //   font-size: 0.18rem;
    //   right: 2.73rem;
    //   top: 0.64rem;
    // }
  }
  .register-logo {
    width: 100%;
    height: 0.9rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      // height: 0.77rem;
      width: 2.43rem;
      background-image: url("../assets/images/logo.png");
      background-position: center center;
      background-size: cover;
      margin-left: 2.95rem;
      cursor: pointer;
    }
    .text {
      font-size: 0.2rem;
      color: #737373;
      margin-left: 0.72rem;
    }
  }
  .form-box {
    margin-top: 0.4rem;
    .send-message {
      height: 0.5rem;
      width: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #f6ab1a;
      border-radius: 3px;
      font-size: 0.16rem;
      cursor: pointer;
    }
    .sending {
      height: 0.5rem;
      width: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #b1b1b1;
      border-radius: 3px;
      font-size: 0.16rem;
    }
    .ant-form-item {
      margin-bottom: 0.2rem;
    }
    .ant-input:hover {
      border-color: #60c7c6;
    }
    .ant-select-selection:hover {
      border-color: #60c7c6;
    }
    .ant-form-item-label {
      line-height: 0.5rem;
    }
    .ant-form-item-label label {
      color: #737373;
      font-size: 0.16rem;
    }
    .ant-btn-primary {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
    .step {
      margin: 0 auto;
      margin-bottom: 1.3rem;
      position: relative;
      width: 10rem;
      .step-font {
        position: absolute;
        top: 0.4rem;
        color: #ccc;
        font-size: 0.16rem;
        width: 1.5rem;
        left: -0.6rem;
      }
      .step1 {
        width: 2rem;
        height: 0.04rem;
        background-color: #60c7c6;
        position: absolute;
        left: 0;
        top: 0.13rem;
      }
      .step1-font {
        position: absolute;
        left: 1.83rem;
        top: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: #60c7c6;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.24rem;
      }
      .step1-width {
        width: 2rem;
        height: 0.04rem;
        background-color: #60c7c6;
        position: absolute;
        left: 2.1rem;
        top: 0.13rem;
      }
      .step2-font {
        position: absolute;
        left: 3.93rem;
        top: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: #ccc;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.24rem;
      }
      .step2-width {
        width: 2rem;
        height: 0.04rem;
        background-color: #ccc;
        position: absolute;
        left: 4.2rem;
        top: 0.13rem;
      }
      .step3-font {
        position: absolute;
        left: 6.13rem;
        top: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: #ccc;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.24rem;
      }
      .step3-width {
        width: 2rem;
        height: 0.04rem;
        background-color: #ccc;
        position: absolute;
        left: 6.4rem;
        top: 0.13rem;
      }
      .step4-font {
        position: absolute;
        left: 8.23rem;
        top: 0;
        width: 0.3rem;
        height: 0.3rem;
        background: #ccc;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.24rem;
      }
      .step4-width {
        width: 2rem;
        height: 0.04rem;
        background-color: #ccc;
        position: absolute;
        left: 8.5rem;
        top: 0.13rem;
      }
    }
  }
  .register-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.28rem;
    color: #737373;
    font-size: 0.16rem;
  }
}
</style>

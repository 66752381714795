
































































  import {Component, Prop, Vue, Watch} from "vue-property-decorator";

  @Component({})
export default class RightOrderDetail extends Vue {
  @Prop() status!: boolean;
  @Prop() serviceItem!: Object;
  @Watch("serviceItem", { immediate: true, deep: true })
  getdetailItem(newVal, oldVal) {
  }
}






























import {Component, Prop, Vue} from "vue-property-decorator";
import {baseUrl} from "../../../services/http"

@Component({
    props: {
        content: String,
        count: Number,
        status: Number,
        item: Object,
        currentStatus: Number,
    }
})
export default class GoodsDetail extends Vue {
    @Prop() public commodityList!: any;
    baseUrl = baseUrl;

    details(val) {
        this.$router.push(`/cookInHome/${val.serviceId}`);
    }
}

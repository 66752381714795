<template>
  <div id="Selling">
    <div id="title">
      <img src="../../assets/images/mallHomeImg/组44@2x.png" alt />
    </div>
    <div id="sort">
      排序：
      <span
        v-for="(item, index) of sortList"
        :key="index"
        @click="sortType(index)"
        :class="{ select: selectSort == index }"
      >
        {{ item.name }}
        <a-icon type="arrow-down" v-if="index != 2" />
        <a-icon type="arrow-up" v-if="index == 2" />
      </span>
    </div>
    <spikePart1
      v-for="(item, index) of list"
      :key="index"
      :data="item"
    ></spikePart1>

    <div class="paginationBox">
      <a-pagination
        :showTotal="(total) => `共 ${total} 条`"
        :defaultCurrent="1"
        :total="total"
        @change="onChange"
        :page-size.sync="condition.pageSize"
        :itemRender="itemRender"
      ></a-pagination>
    </div>
  </div>
</template>
<script lang="js">
    import Vue from "vue";
    import {spikePart1} from "@/components/mallComponents";
    import {http} from "@/services/http";

    export default Vue.extend({
  name: "Selling",
  components: { spikePart1 },
  data() {
    return {
      selectSort: 0,
      sortList: [],
      sortCondition: ["综合评分", "销量", "价格", "价格", "上架时间"],
      condition: { pageIndex: 1, pageSize: 24 },
      total: 0,
      list: [],
    };
  },
  mounted() {
    this.getdata();
    this.sortCondition.map((item, index) => {
      this.sortList.push({
        name: item,
        id: index,
      });
    });
  },
  methods: {
    getdata() {
      http
        .get("/api/v1/commodities/search", {
          params: { hot: 1, ...this.condition },
        })
        .then((res) => {
          this.total = res["data"].totalCount;
          this.getList(res["data"].list);
        });
    },
    getList(list) {
      this.list = [];
      for (let item of list) {
        item.linePrice = item.suggestPrice;
      }
      list.map((item, index) => {
        if (index % 6 == 0) {
          this.list.push(list.slice(index, index + 6));
        }
      });
    },
    onChange(e) {
      this.condition.pageIndex = e;
      this.getdata();
    },
    sortType(i) {
      this.selectSort = i;
      this.condition.pageIndex = 1;
      this.condition.order = i == 0 ? "" : i;
      this.getdata();
      if (i == 2 || i == 3) this.priceUp = !this.priceUp;
    },
    itemRender(current, type, originalElement) {
      const a = document.createElement("a");

      if (type === "prev") {
        a.innerHTML = "上一页";
        return a;
      } else if (type === "next") {
        a.innerHTML = "下一页";
        return a;
      }
      return originalElement;
    },
  },
});
</script>

<style lang="less" scoped>
#Selling {
  width: 13rem;
  margin: auto;
  padding: 0.35rem 0;
  #title {
    text-align: center;
    margin-bottom: 0.23rem;
    img {
      width: 1.8rem;
    }
  }
  #sort {
    width: 13rem;
    height: 0.35rem;
    background-color: #f1f1f1;
    border: solid 0.01rem #e6e6e6;
    margin-bottom: 0.22rem;
    letter-spacing: 0rem;
    padding-left: 0.16rem;
    color: #989898;
    span {
      line-height: 0.35rem;
      font-size: 0.15rem;
      color: #171717;
      margin: 0 0.06rem;
      cursor: pointer;
      border: solid 0.01rem #f1f1f1;
      padding: 0.02rem 0.08rem;
    }
    span.select {
      background-color: #60c7c6;
      border: solid 0.01rem #e6e6e6;
      color: #fff;
    }
    span:hover {
      background-color: #60c7c6;
      border: solid 0.01rem #e6e6e6;
      color: #fff;
    }
  }
}
</style>



    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({})
export default class banner extends Vue {
  @Prop() public bannerList!: any;
  //去平台
  goPlatform(val) {
    if(val.includes('https://')){
      window.open(val,"_blank");
    }else{
      let url ='http://www.test.fytlun.com/#/BusinessJoin'
      window.open(url,"_blank");
    }

  }
}




































































































































































































































































    import {Component, Vue} from "vue-property-decorator";
    import {Button, Form, message} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {State} from "vuex-class";
    import {invoicesAdd, invoicesInfo, invoicesUpdate} from "@/services/api";

    Vue.use(Button);
Vue.use(Form);
@Component({
  components: {
    PersonalHeader
  }
})
export default class extends Vue {
  get typeByJugge() {
    if (!this.invoiceInfoData["invoiceType"]) {
      this.obj.invoiceTitleType = 0;
      return 0;
    } else return this.invoiceInfoData["invoiceType"].includes("个人") ? 0 : 1;
  }

  @State detailItem;
  form: any;
  formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 }
  };
  obj = {
    invoinceId: "",
    orderInfoId: "",
    orderType: 2,
    invoiceType: "1",
    invoiceContent: "",
    invoiceTitleType: 1,
    invoiceTitle: "",
    taxpayerIdentificationNumber: "",
    receiverEmail: "",
    receiverPhone: ""
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 15,
        offset: 9
      }
    }
  };
  invoiceId = "";
  invoiceInfoData = {};
  isAddInvoice = false;
  haveInvoice = false;
  // 1申请中 2完成
  invoiceStatusDic = 307; // 发票状态字典 307-待开票 308-已开票
  beforeCreate() {
    this.form = this.$form.createForm(this);
  }
  created() {
    this.haveInvoice = this.detailItem.haveInvoice;
    if (this.haveInvoice) {
      this.invoiceId = this.detailItem.invoiceId;
      this.getInvoiceInfo();
    } else {
      this.isAddInvoice = true;
      this.invoiceStatusDic = 307;
    }
  }
  phoneCheck(rule, value, callback) {
    const phonereg = /^(13|14|15|18|19|16|17)\d{9}$/;
    if (!phonereg.test(value)) {
      callback("请输入正确的手机号");
      return;
    }
    callback();
  }
  Email(rule, value, callback) {
    const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!reg.test(value)) {
      callback("请输入正确的邮箱");
      return;
    }
    callback();
  }
  getInvoiceInfo() {
    invoicesInfo({
      invoiceId: this.invoiceId
    }).then(res => {
      this.invoiceInfoData = res.data;
      this.obj.invoiceTitleType = this.invoiceInfoData["invoiceType"].includes(
        "个人"
      )
        ? 0
        : 1;
      // this.currentState = 2;
      this.invoiceStatusDic = res.data.invoiceStatusDic;
    });
  }
  handleSubmitEvent(e) {
    e.preventDefault();

    if (this.haveInvoice) {
      this.handleSubmitUpdate(e);
    } else {
      this.handleSubmitAdd(e);
    }
  }
  handleSubmitAdd(e) {
    e.preventDefault();
    this.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.obj.orderInfoId = this.detailItem.serviceOrderInfoId;
        this.obj.invoiceTitleType = values.invoiceTitleType;
        this.obj.invoiceTitle = values.invoiceTitle;
        this.obj.taxpayerIdentificationNumber =
          values.taxpayerIdentificationNumber;
        this.obj.invoiceContent = values.invoiceContent.toString();
        this.obj.receiverPhone = values.receiverPhone;
        this.obj.receiverEmail = values.receiverEmail;
        invoicesAdd({ ...this.obj }).then(res => {
          if (res.code === 0) {
            message.success("申请成功");
            this.invoiceId = res.invoiceId;
            this.getInvoiceInfo();
            this.isAddInvoice = false;
            this.invoiceStatusDic = 307;
            this.haveInvoice = false;
          }
        });
      }
    });
  }
  handleSubmitUpdate(e) {
    this.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.obj.invoinceId = this.invoiceId;
        this.obj.invoiceTitleType = values.invoiceTitleType;
        this.obj.invoiceTitle = values.invoiceTitle;
        this.obj.taxpayerIdentificationNumber =
          values.taxpayerIdentificationNumber;
        this.obj.invoiceContent = values.invoiceContent.toString();
        this.obj.receiverPhone = values.receiverPhone;
        this.obj.receiverEmail = values.receiverEmail;
        invoicesUpdate({ ...this.obj }).then(res => {
          if (res.code === 0) {
            message.success("修改成功");
            this.getInvoiceInfo();
            this.isAddInvoice = false;
            this.invoiceStatusDic = 307;
          }
        });
      }
    });
  }
  updateInvoice() {
    this.$confirm({
      title: "修改发票申请",
      content:
        "您只有一次修改发票的机会，修改后将生成新的申请，由卖家重新处理，原申请若已开票可能被作废",
      cancelText: "取消",
      okText: "修改",
      onOk: () => {
        this.getInvoiceInfo();
        this.isAddInvoice = true;
      },
      onCancel() {
      },
      class: "test"
    });
  }
  radioStyleChange(val) {
    this.obj.invoiceTitleType = val.target.value;
  }
}




































import {Component, Vue} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import serviceOrderModule from "./serviceOrderModule.vue";
import serviceOrderCanItem from "./serviceOrderCanItem.vue";

Vue.use(Tabs);
    Vue.use(Input);
    Vue.use(List);
    Vue.use(Icon);
    Vue.use(Modal);
    Vue.use(Select);
    @Component({
        components: {
            PersonalHeader,
            serviceOrderModule,
            serviceOrderCanItem
        }
    })
    export default class extends Vue {
        tabTitle:any = [{key:0,value:"全部服务订单"}, {key:762,value:"已订购"}, {key:763,value:"待服务"}, {key:764,value:"服务中"}, {key:765,value:"已完成"}, {key:766,value:"已取消"}, {key:1,value:"待评价"}];
        searchItem = "";
        cancelVisible = false;
        timer = null;
        hackReset = true;
        defaultValue = "";
        OrdersStatus = {};
        serviceStatus = {};
        allRefundReasons = {};
        acKEY: any = 0;

        onSearch(value) {
            if (this.acKEY == 7) this.acKEY = 0;
            this.emptyTextBySearch = true;
            this.searchItem = value;
        }

        emptyTextBySearch = false;
        allServieStyleList = [];


        created() {
            const acKEY = sessionStorage.getItem("acKEY");
            if (acKEY != null) {
                this.acKEY = Number(acKEY);
            } else {
                this.change(-1);
            }
        }

        mounted() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.$router.push("/personal/serviceOrderList");
            } else {
                this.$router.push("/login");
            }

            this.$nextTick().then(() => {
                let m: any = document.querySelectorAll(".ant-tabs-tab");
                m[7].style.borderLeft = "1px solid #E4E9F2";
                m[7].style.height = "0.2rem";
                m[7].style.paddingTop = "0";
                m[6].style.paddingRight = "0.2rem";
                m[7].style.paddingLeft = "0.3rem";
                m[7].style.marginLeft = "0.1rem";
                m.forEach(element => {
                    element.onclick = () => {
                        if(this.$refs["serviceItem" + this.acKEY]!=undefined){
                            const el = (this.$refs["serviceItem" + this.acKEY] as any)[0];
                            this.emptyTextBySearch = true;
                            el.getList();
                        }
                    };
                });
                const acKEY = sessionStorage.getItem("acKEY");
                if (acKEY != null) {
                    // 创建一个鼠标事件
                    var e = document.createEvent('MouseEvent');
                    // 事件初始化绑定一个点击
                    e.initEvent('click', false, false);
                    // 获取到第二个待付款元素
                    const el = m[Number(acKEY)];
                    // 分发事件
                    el.dispatchEvent(e)
                    sessionStorage.removeItem("acKEY");
                }
            });
        }



        //切换获取状态下对应的信息
        change(activeKey) {
            let a: any = document.querySelectorAll(".ant-tabs-ink-bar");
            if (activeKey === 8) {
                a[0].style.background = 'none';
            } else if (a.length != 0) {
                a[0].style.backgroundColor = '#39BEB8';
            }
            // this.defaultValue = "";
            if (activeKey < 0)
                this.$nextTick().then(res => {
                    (this.$refs[`serviceItem` + 0] as any)[0].getList();
                });
        }
    }

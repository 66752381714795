















































import {Component, Prop, Vue} from "vue-property-decorator";
import {State} from "vuex-class";

@Component({
    props: {
        status: Number
    }
})
export default class ServiceDetailPriceCard extends Vue {
    @State detailItem;
}

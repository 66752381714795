














































    import {Component, Vue} from "vue-property-decorator";
    import {Tabs} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";

    Vue.use(Tabs);

@Component({
  components: {
    PersonalHeader
  },

})
export default class extends Vue {
  currentTab = 1;
  defaultValue= "";
  // onSearch() {
  //   // this.pagination["searchInput"] = this.defaultValue;
  //   // this.emptyTextBySearch = true;
  //   // this.getServiceList();
  // }
  handleTabChange(key: any) {
    this.currentTab = key;
  }
}

























































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {collectionsOrgService, fetchCollection} from "@/services/api";
import pensionOrderForm from "../views/PensionOrderForm.vue";
import PensionOrderSuccess from "../views/PensionOrderSuccess.vue";
import magnifier from "../components/magnifier.vue";

@Component({
    props: {
        title: String,
        // orgId: String,
        location: String,
        lowPrice: Number,
        highPrice: Number,
        orgType: String,
        orgDistribut: String,
        buildYear: String,
        size: String,
        bedNum: Number,
        faceTo: String,
        orgImages: Array,
        coverPicture: String,
        orgId: String,
        checkStatus: String,
    },
    components: {
        pensionOrderForm,
        PensionOrderSuccess,
        magnifier,
    },
})
export default class OrgDetailHeader extends Vue {
    @Prop() private orgId!: string;
    currentImageIndex = 0;
    collectionString = "收藏";
    visible: boolean = false;
    visible2 = false;
    showImage = false;

    reserveSuccessEvent(e) {
        this.visible = false;
        this.visible2 = true;
    }

    get _imgUrl() {
        return this.$props.orgImages.length > 0
            ? this.$props.orgImages[this.currentImageIndex].orgImage
            : "";
    }

    revivification(value) {
        this.$emit("revivification", value);
    }

    toDetail() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            this.visible = true;
        } else {
            this.$router.push("/login");
        }
    }

    deleteOrder() {
        this.visible2 = false
    }

    created() {
        this.isCollectionsOrgService();
    }

    isCollectionsOrgService() {
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem("userInfo")) {
                resolve("");
                return;
            }
            collectionsOrgService({
                itemId: this.$props.orgId,
                itemType: 238,
            }).then((res) => {
                this.collectionString = res.data.isCollected === 0 ? "收藏" : "已收藏";
                resolve(res);
            });
        });
    }

    fetchCollection(operation) {
        const params = {
            itemId: this.$props.orgId,
            itemType: 238,
        };
        return new Promise((resolve, reject) => {
            fetchCollection({...params, operation: operation}).then((res) => {
                resolve(res);
            });
        });
    }

    async collection() {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            let operation = 0;
            if (this.collectionString === "已收藏") {
                operation = 1;
            } else {
                operation = 0;
            }
            await this.fetchCollection(operation);
            await this.isCollectionsOrgService();
        } else {
            this.$router.push("/login?collectionOrgid=" + this.$props.orgId);
        }
    }

    handlePreImg() {
        if (this.currentImageIndex > 0) {
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft =
                    -(this.currentImageIndex - 4) * 1.53 + "rem";
            }
            this.currentImageIndex -= 1;
        }
    }

    handleNextImg() {
        if (this.currentImageIndex < this.$props.orgImages.length - 1) {
            this.currentImageIndex += 1;
            if (this.currentImageIndex % 4 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft = -this.currentImageIndex * 1.53 + "rem";
            }
        }
    }
}



























































































































import {Component, Vue} from "vue-property-decorator";
import {endowmentPattern, fetchYlmsNextMenuList,} from "@/services/api";

declare var $: any;

interface YlmsNavMenu {
    dicTypeId: number;
    dictionaryId: number;
    dictionaryName: string;
    dictionaryOrder: number;
}

interface YlfwNavMenu {
    classificationLevel: number;
    classificationName: string;
    description?: string;
    fatherId: string;
    orderId: number;
    serviceClassificationId: string;
}

@Component({
    props: {
        handleNavSearch: Function,
    },
})
export default class Nav extends Vue {
    resetSearchVal() {
        this.searchVal = "";
    }

    // @Prop() private msg!: string
    isFixed = false;
    byDefault = false;
    currentSearchType: number = 1;
    submenuVisible: boolean = false;
    currenMenu: string = "";
    searchVal: string = "";
    YlmsNavMenu: YlmsNavMenu[] = [];
    YlfwNavMenu: YlfwNavMenu[] = [];
    cityId = parseInt(localStorage.getItem("provinceId") || "0", 10);
    YLMSShowBool = false;
    YLFWShowBool = false;

    iAmWhere: any = "";
    whrerArr = [
        "pensionInstitution",
        "pensionMap",
        "home",
        "pensionMode",
        "pensionNews",
        "pensionService",
        "pensionInfo",
        "pensionSolution",
        "artical",
        "qrCode",
        "qrCodeTwo",
        "zhengxin"
    ];

    jumpToMall(e) {
        let {href} =  this.$router.resolve('/mall/home')
        window.open(href, "_blank");
    }

    //征信平台
    creditInvestigation() {
        (location.href.includes("test") || location.href.includes("develop")) ? this.$router.push(`/creditInvestigation/creditReportingHome`) : this.$router.push(`/creditInvestigation/creditReportingHome`);
    }
    jumpMap(){
        let {href} = this.$router.resolve({path: "/pensionMap"});
        window.open(href, "_blank");

    }

    mounted() {
        this.iAmWhere = this.whrerArr.find((item) =>
            window.location.href.includes(item)
        );
        window.addEventListener("scroll", this.handleScroll);

        this.searchVal = sessionStorage.getItem("search") || "";
        // 获取养老模式一级菜单
        // fetchYlmsNavMenuList({typeId: "74"}).then((res) => {
        //     if (
        //         res.data &&
        //         res.data.dictionaryList &&
        //         res.data.dictionaryList.length
        //     ) {
        //         res.data.dictionaryList.forEach((item: YlmsNavMenu) =>
        //             this.YlmsNavMenu.push(item)
        //         );
        //     }
        // });

        // 获取养老模式一级菜单(可控制)
        endowmentPattern().then((res) => {
            if (res.code === 0) {
                this.YlmsNavMenu = res.data
            }

        });

        fetchYlmsNextMenuList().then((res) => {
            if (
                res.data &&
                res.data.secondTypeList &&
                res.data.secondTypeList.length
            ) {
                res.data.secondTypeList.forEach((item: YlfwNavMenu) =>
                    this.YlfwNavMenu.push(item)
                );
            }
        });
    }

    selectedDefault(val:any) {
        this.$router.push(val);
    }

    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(e: any) {
        const scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;

        if (scrollTop > 120) {
            this.isFixed = true;
        } else {
            this.isFixed = false;
        }
        this.$store.commit("show_fakeDiv", this.isFixed);
    }

    handleJumpMode(dicId: string) {
        this.$router.push(`/pensionInstitution/${dicId}`);
    }

    jumpToInfo(secondId: string) {
        this.$router.push(`/pensionInfo/${secondId}`);
    }

    jumpToHome() {
        this.$router.push("/");
    }

    onSearchChange(e: any) {
        this.searchVal = e.target.value;
        sessionStorage.setItem("search", e.target.value);
    }

    onSearch(value: string) {
        const path = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1,
            window.location.href.length
        );
        if (path !== "servicePage") {
            this.$router.push("/servicePage");
        }
        if (this.$props.handleNavSearch) {
            this.$props.handleNavSearch(value);
        }
    }

    searchTypeChange(type: number) {
        this.currentSearchType = type;
    }

    showSubmenu(e: any) {
        this.submenuVisible = true;
        if (e.target.localName === "li") {
            this.currenMenu = e.target.innerText;
        }
    }

    hideSubmenu() {
        this.submenuVisible = false;
    }
}

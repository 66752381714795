
































import {Component, Prop, Vue} from "vue-property-decorator";
import {Mutation, State} from "vuex-class";

@Component({})
export default class ProductsItem extends Vue {
    @Prop() public color!: string;
    @Prop() public index!: string;
    @State sliderBarItems: any;
    @Mutation setSliderBarItems: any;
    @Prop() data: any;

    mounted() {
        this.data.commodities = [
            ...this.data.commodities,
        ];
        this.setSlider65();
    }

    setSlider65() {
        if (this.$store.state.sliderBarItems.length == 0) {
            setTimeout(() => {
                this.setSlider65();
            }, 1000);
            return;
        }
        let m = JSON.parse(JSON.stringify(this.sliderBarItems));
        const ii: any =
            (document.getElementById("home_body2") as any).offsetTop -
            document.documentElement.clientHeight;
        this.$store.commit(
            "setfixedCurTop",
            ii + (document.querySelector(".ProductsItem0") as any).clientHeight < 0
                ? 0
                : ii + (document.querySelector(".ProductsItem0") as any).clientHeight
        );
        for (let item of m) {
            if (item.name.includes(parseInt(this.index) + 1)) {
                item.top =
                    (document.querySelector(".ProductsItem" + this.index) as any)["offsetTop"] +
                    ii +
                    (document.querySelector(".ProductsItem" + this.index) as any).clientHeight;
            }
        }
        this.setSliderBarItems(m);
    }

    jumpSearch(classtype: string, id: any, name?: string) {
        const str = this.data.typeName + (name ? "/" + name : "");
        localStorage.setItem("menupath", str);
        open(`/#/mall/search?class=${classtype}&id=${id}`);
    }

    getUrl(pic) {
        if (!pic) return this.$store.state.noimage;
        return pic.includes("http://") ? pic : "http://39.97.106.60:8084/" + pic;
    }
}

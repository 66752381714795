import { render, staticRenderFns } from "./LeaderboardBody.vue?vue&type=template&id=cf538d88&scoped=true&"
import script from "./LeaderboardBody.vue?vue&type=script&lang=ts&"
export * from "./LeaderboardBody.vue?vue&type=script&lang=ts&"
import style0 from "./LeaderboardBody.vue?vue&type=style&index=0&id=cf538d88&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf538d88",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('Nav'),_c('div',{staticClass:"businessJoinStep3"},[_c('div',{staticStyle:{"width":"10rem","margin":"0 auto","marginTop":"-0.4rem"}},[_c('ReturnStep',{attrs:{"curStep":2,"stepList":_vm.stepList}})],1),_c('div',{staticClass:"form-box"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({attrs:{"label":"商家账号"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'merAccount',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写商家账号！'
                  },
                  {
                    validator: this.checkExist.bind(this)
                  }
                ],
                initialValue: _vm.formData.shopInfo.merchantAccount
              }
            ]),expression:"[\n              'merAccount',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请填写商家账号！'\n                  },\n                  {\n                    validator: this.checkExist.bind(this)\n                  }\n                ],\n                initialValue: formData.shopInfo.merchantAccount\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"商家账号，用于登录商家管理平台"}}),_c('p',{staticStyle:{"margin":"0","marginBottom":"-0.15rem","color":"#ED6666","fontSize":"0.14rem"}},[_vm._v(" 此帐号为日后登录并管理商家中心时使用，注册后不可修改，请牢记。默认密码123456 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"店铺名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'ceocj',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写店铺名称！'
                  },
                  {
                    validator: this.checkShopNameExist.bind(this)
                  }
                ],
                initialValue: _vm.formData.shopInfo.storeName
              }
            ]),expression:"[\n              'ceocj',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请填写店铺名称！'\n                  },\n                  {\n                    validator: this.checkShopNameExist.bind(this)\n                  }\n                ],\n                initialValue: formData.shopInfo.storeName\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请填写店铺名称"}}),_c('p',{staticStyle:{"margin":"0","marginBottom":"-0.15rem","color":"#ED6666","fontSize":"0.14rem"}},[_vm._v(" 店铺名称注册后不可修改，请认真填写 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"店铺封面"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'coverImage',
              {
                rules: [
                  {
                    required: true,
                    validator: this.uploadCoverFileCheck.bind(this)
                  }
                ]
              }
            ]),expression:"[\n              'coverImage',\n              {\n                rules: [\n                  {\n                    required: true,\n                    validator: this.uploadCoverFileCheck.bind(this)\n                  }\n                ]\n              }\n            ]"}],attrs:{"name":"file","customRequest":_vm.uploadCoverAll,"remove":_vm.uploadCoverRemove,"file-list":_vm.uploadCoverFileList,"list-type":"picture-card"},on:{"preview":_vm.handlePicturePreview,"change":_vm.handleChange}},[(_vm.uploadCoverFileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传封面")])],1):_vm._e()])],1),_c('a-form-item',_vm._b({attrs:{"label":"店铺简介"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'summary',
              {
                rules: [
                  {
                    required: true,
                    message: '店铺简介不能为空'
                  }
                ],
                initialValue: _vm.formData.expansionInfo.merchantIntro
              }
            ]),expression:"[\n              'summary',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '店铺简介不能为空'\n                  }\n                ],\n                initialValue: formData.expansionInfo.merchantIntro\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"rows":6,"placeholder":"请输入店铺简介"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"店铺地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shopaddress',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择公司地址！'
                  }
                ]
              }
            ]),expression:"[\n              'shopaddress',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择公司地址！'\n                  }\n                ]\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"options":_vm.optionsaddr,"placeholder":"请选择店铺地址","loadData":_vm.addrLoadData}})],1),_c('a-form-item',_vm._b({attrs:{"label":"详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shopDetailAddress',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写详细地址！'
                  }
                ],
                initialValue: _vm.formData.shopInfo.detailAddress
              }
            ]),expression:"[\n              'shopDetailAddress',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请填写详细地址！'\n                  }\n                ],\n                initialValue: formData.shopInfo.detailAddress\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请输入详细地址"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"店铺电话"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shopphone',
              {
                rules: [
                  {
                    required: true,
                    message: '请填写店铺电话！'
                  },
                  {
                    validator: this.phoneCheck.bind(this)
                  }
                ],
                initialValue: _vm.formData.shopInfo.merchantTel
              }
            ]),expression:"[\n              'shopphone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: '请填写店铺电话！'\n                  },\n                  {\n                    validator: this.phoneCheck.bind(this)\n                  }\n                ],\n                initialValue: formData.shopInfo.merchantTel\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请输入店铺电话"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"经营类目"}},'a-form-item',_vm.formItemLayout,false),[_vm._l((_vm.list),function(item){return _c('div',{key:item},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                ("refr" + item),
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择经营类目！'
                    }
                  ]
                }
              ]),expression:"[\n                `refr${item}`,\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请选择经营类目！'\n                    }\n                  ]\n                }\n              ]"}],staticStyle:{"width":"4rem"},attrs:{"options":_vm.options,"loadData":_vm.loadData,"placeholder":"请选择经营类目"},on:{"change":function (value) { return _vm.onChange(value, ("refr" + item)); }}}),(item > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",staticStyle:{"marginLeft":"5px"},attrs:{"type":"minus-circle-o"},on:{"click":function () { return _vm.remove(item); }}}):_vm._e()],1)}),_c('span',{staticStyle:{"color":"#60C7C6","fontSize":"0.14rem","cursor":"pointer"},on:{"click":_vm.add}},[_c('a-icon',{staticStyle:{"marginRight":"5px"},attrs:{"type":"plus-circle"}}),_vm._v("新增经营类目")],1)],2),_c('a-form-item',_vm._b({attrs:{"label":"备注"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shoreRemark',
              {
                initialValue: _vm.formData.shopInfo.shoreRemark
              }
            ]),expression:"[\n              'shoreRemark',\n              {\n                initialValue: formData.shopInfo.shoreRemark\n              }\n            ]"}],staticStyle:{"width":"4rem"},attrs:{"rows":2}})],1),_c('a-form-item',_vm._b({staticStyle:{"margin":"1rem 0"}},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem","marginRight":"1rem","background":"#fff","color":"#60c7c6"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("上一步 ")]),(_vm.buttonSet.enableClick)?_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem"},attrs:{"loading":_vm.buttonSet.loading,"type":"primary","html-type":"submit"}},[_vm._v("提交审核 ")]):_c('a-button',{staticStyle:{"width":"2rem","height":"0.44rem"},attrs:{"loading":_vm.buttonSet.loading,"type":"primary","disabled":""}},[_vm._v("提交审核 ")])],1)],1)],1)]),_c('Footer'),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
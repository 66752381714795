
















































































































































































































    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {Mutation, State} from "vuex-class";
    import {message} from "ant-design-vue";
    import {
        cancelOrderSubmit,
        classServie2Detail,
        classServie31Detail,
        classServie32Detail,
        comfrimService,
        deleteOrder,
        jointOrder,
        openService2,
        serviceOrderConfirm,
        serviceOrderDetal
    } from "@/services/api";
    import {baseUrl, request} from "@/services/http";

    @Component({
  props: {
    location: String,
    allcontent: Object,
  },
})
export default class ServiceItem extends Vue {
  // @Prop() private msg!: string
  @Prop() private status!: String;
  @Prop() private statusDic!: Number;
  @Prop() private itemIndex: Number;
  @Prop() private allcontent!: Object;
  @Prop() private serviceOrderInfoId!: String;
  @Prop() public services!: any;

  @Mutation SET_DETAILITEM;
  @Mutation SET_CLASSSERVICEDETAIL;
  @Mutation SET_CURDETAILSTATUS;
  @Mutation SET_PAYTIMES;
  @Mutation SET_CANCELRESON;
  @Mutation SET_ALLORDERSTATUS;
  @Mutation SET_RELATIONID;
  @Mutation SET_CLASSSERVICEONESTEP;
  @Mutation SET_CLASSSERVICETWOSTEP;
  @Mutation SET_CLASSSERVICETHREESTEP;
  @State cancelReason;
  @State relationId;
  currStatus: "";
  openService = false;
  serveComplete = false;
  serveCancel = false;
  recycleBin = false;
  curIndex = 0;
  cancelServiceList = {};
  allowSubmit = true;
  time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  curOrderStatus = {
    paixiafuwu: false,
    maijiafukuan: false,
    fuwutigongshang: false,
    querenfuwu: false,
    pingjia: false,
    guanbi: false,
  };
  classServiceOneStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };
  classServiceTwoStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };
  classServiceThreeStep = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  };
  select = {};

  get showQueRenFuWuDingDan() {
    return this.services.every((item) => item.servicesStatusDic == 300);
  }

  @Watch("services", {immediate: true, deep: true})
  getSearchItem(newVal, oldVal) {
    this.allcontent["statusDic"] = this.allcontent["statusDic"] || this.allcontent["serviceOrderStatusDic"];
  }

  @Watch("allcontent", {immediate: true, deep: true})
  getAllcontent(newVal, oldVal) {
    if (this.isHaveAttr(newVal, "orderRemainingPayTime")) {
      this.time.days = newVal.orderRemainingPayTime.days;
      this.time.hours = newVal.orderRemainingPayTime.hours;
      this.time.minutes = newVal.orderRemainingPayTime.minutes;
      this.time.seconds = newVal.orderRemainingPayTime.seconds;
      this.payTime();
    } else if (this.isHaveAttr(newVal, "orderRemainingConfirmTime")) {
      this.time.days = newVal.orderRemainingConfirmTime.days;
      this.time.hours = newVal.orderRemainingConfirmTime.hours;
      this.time.minutes = newVal.orderRemainingConfirmTime.minutes;
      this.time.seconds = newVal.orderRemainingConfirmTime.seconds;
      this.payTime();
    }
    if (
        this.isHaveAttr(newVal, "orderConfirmTime") ||
        this.isHaveAttr(newVal, "orderPayTime")
    ) {
      const key = this.isHaveAttr(newVal, "orderConfirmTime")
          ? "orderConfirmTime"
          : "orderPayTime";

      const allSecond =
          (new Date(newVal[key]).getTime() - new Date().getTime()) / 1000;
      if (allSecond <= 0) {
        setZero(this);
        return;
      }
      this.time.days = Math.floor(allSecond / (60 * 60 * 24));
      this.time.hours = Math.floor(
          (allSecond - this.time.days * (60 * 60 * 24)) / (60 * 60)
      );
    }

    function setZero(_this: any) {
      _this.time.days = 0;
      _this.time.hours = 0;
      _this.time.minutes = 0;
      _this.time.seconds = 0;
    }
  }

  buyAgain(services) {
    this.$router.push("/cookInHome/" + services[0].serviceId);
  }

  applyReturnMoney(index, type) {
    this.$router.push({
      path: `/personal/returnService${type ? 3 : 2}`,
      query: {
        returnId: this.services[index].refundId,
        relationId: this.services[index].relationId,
      },
    });
  }

  @Watch("cancelReason", {immediate: true, deep: true})
  getcancelList() {
    this.cancelServiceList = this.cancelReason;
  }

  mounted() {
    this.allcontent["statusDic"] = this.allcontent["statusDic"] || this.allcontent["serviceOrderStatusDic"];
    this.serviceOrderInfoId = this.allcontent["serviceOrderInfoId"];
    this.selectChangetsuahValue = undefined;
  }

  //假订单类型
  falseOrders(valueList) {
    let res = true
    valueList.forEach((item => {
      if (item.serviceType === 732) {
        res = false
      }
    }))
    return res;
  }

  openServiceEvent(openService: boolean, index: number) {
    openService2({
      relationId: this.services[index].relationId,
    }).then((res) => {
      if (res.code === 0) {
        this.$router.push({
          path: "/personal/classOpenServiceOne1",
          query: {
            relationId: this.services[index].relationId,
          },
        });
      } else {
        message.error("开启服务失败，请稍后重试");
      }
    });
    this.openService = openService;
  }

  serviceDetailEvent(relationId: String, serviceType: Number) {

    let callback: (params: any) => Promise<any>,   // 根据不同的服务类型去请求不同的接口
        switchServiceStatus: Function, // 判断不同的状态
        hrefUrl: string; // 根据不同的服务类型跳转到不同的地址
    // 判断类型
    switch (serviceType) {
      case 432: // 纯线上服务（2类） 2类服务订单详情
        callback = classServie2Detail;
        hrefUrl = '/personal/classServiceTow';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待开启":
              this.classServiceTwoStep["step1"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "服务开启中":
              this.classServiceTwoStep["step2"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;

            case "服务完成":
              this.classServiceTwoStep["step3"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "服务开启完成":
              this.classServiceTwoStep["step3"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
              break;
            case "已关闭":
              this.classServiceTwoStep["step4"] = true;
              this.SET_CLASSSERVICETWOSTEP(this.classServiceTwoStep);
          }
        }
        break;
      case 433: // 3.1类服务订单详情 线上线下相结合的服务（3.1类） 无需对接接口 如家政
        callback = classServie31Detail;
        hrefUrl = '/personal/classServiceOne';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待服务":
              this.classServiceOneStep["step1"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;
            case "服务进行中":
              this.classServiceOneStep["step2"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;
            case "服务完成":
              this.classServiceOneStep["step3"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
              break;

            case "已关闭":
              this.classServiceOneStep["step4"] = true;
              this.SET_CLASSSERVICEONESTEP(this.classServiceOneStep);
          }
        }
        break;
      case 434: //3.2类订单详情 线上线下相结合但需要对接接口（3.2 类） 如体检
        callback = classServie32Detail;
        hrefUrl = '/personal/ServiceProcessDetail';
        switchServiceStatus = (currStatus: string) => {
          switch (currStatus as any) {
            case "等待开启":
              this.classServiceThreeStep["step1"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "服务开启中":
              this.classServiceThreeStep["step2"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "服务开启完成":
              this.classServiceThreeStep["step5"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
            case "已关闭":
              this.classServiceThreeStep["step6"] = true;
              this.SET_CLASSSERVICETHREESTEP(this.classServiceThreeStep);
              break;
          }
        }
        break;
      default:
        callback = (params: any) => {
          return new Promise((resolve, reject) => {
            reject('服务类型错误');
          });
        }
    }

    // 请求服务订单详细数据
    callback({
      relationId: relationId,
    }).then((res) => {
      if (res) {
        let data: any;
        data = (res as any).data;
        data.serviceType = serviceType; // 类型也一起存进去
        // 存储数据
        this.SET_CLASSSERVICEDETAIL(data || {});
        // 判断不同的状态
        switchServiceStatus(data.serviceStatus);
        // 跳转
        window.open(
            this.$router.resolve(hrefUrl).href,
            "_blank"
        );
      }
    });

  }

  cancelServiceEnvet(key: boolean) {
    this.serveCancel = key;
    // this.openService = key;
  }

  judgeService() {
    const {href} = this.$router.resolve({
      path: "/personal/judgeService",
      query: {
        serviceOrderInfoId: this.allcontent["serviceOrderInfoId"],
        orgId: this.allcontent["orgId"],
        ifShop: this.allcontent["ifShop"],
      },
    });
    open(href);
  }

  lookDetail(serviceOrderInfoId: any) {
    this.SET_DETAILITEM();
    serviceOrderDetal({serviceOrderInfoId: serviceOrderInfoId}).then(
        (res) => {
          if (res) {
            let data: any = (res as any).data || {};
            data.serviceOrderInfoId = serviceOrderInfoId;
            this.SET_DETAILITEM(data);
            // this.SET_RELATIONID(this.services[i]);
            // window.sessionStorage.setItem(
            //   "relationId",
            //   this.services[i].relationId
            // );
            switch (data.statusDic) {
              case 292: //未付款
                this.curOrderStatus["paixiafuwu"] = true;
                this.SET_CURDETAILSTATUS(this.curOrderStatus);
                // this.$router.push("/personal/serviceDetail1");
                break;
              case 293: //已付款
                this.curOrderStatus["maijiafukuan"] = true;
                this.SET_CURDETAILSTATUS(this.curOrderStatus);
                // this.$router.push("/personal/serviceDetail2");
                break;
              case 294: //进行中
                this.curOrderStatus["fuwutigongshang"] = true;
                this.SET_CURDETAILSTATUS(this.curOrderStatus);
                // this.$router.push("/personal/serviceDetail3");
                break;
              case 295: {
                //已完成
                // if (data.haveInvoice) {

                // this.$router.push("/personal/serviceDetail4");
                // } else
                if (data.ifEvaluation) {
                  this.curOrderStatus["pingjia"] = true;
                  this.SET_CURDETAILSTATUS(this.curOrderStatus);
                } else {
                  this.curOrderStatus["querenfuwu"] = true;
                  this.SET_CURDETAILSTATUS(this.curOrderStatus);
                }
                //   // this.$router.push("/personal/serviceDetail6");
                // } else {
                //   this.curOrderStatus["fuwutigongshang"] = true;
                //   this.SET_CURDETAILSTATUS(this.curOrderStatus);
                //   // this.$router.push("/personal/serviceDetail3");
                // }
                break;
              }
              case 296: //已取消
                this.curOrderStatus["guanbi"] = true;
                this.SET_CURDETAILSTATUS(this.curOrderStatus);
                // this.$router.push("/personal/serviceDetail6");
                break;
              default:
                //已关闭
                this.curOrderStatus["guanbi"] = true;
                this.SET_CURDETAILSTATUS(this.curOrderStatus);
                // this.$router.push("/personal/serviceDetail6");
                break;
            }
            if(data!=''){
              let {href} = this.$router.resolve("/personal/serviceDetail");
              window.open(href, "_blank");
            }
          }
        }
    );
  }

  selectChangetsuahValue: any = null;

  selectChange() {
    if (this.allowSubmit) {
      this.allowSubmit = false;
      cancelOrderSubmit({
        serviceOrderInfoId: this.serviceOrderInfoId,
        cancelReason: this.selectChangetsuahValue,
      }).then((res) => {
        if ((res as any).code === 0) {
          this.allowSubmit = false
          message.success("提交成功");
          this.$emit("againGetList");
          this.serveCancel = false;
        } else {
          message.error("提交失败");
          this.allowSubmit = true
        }
        this.serveCancel = false;
      });
    }
  }

  serveOver = false;
  serveOverIndex = 0;

  comfrimServie(key: any) {
    this.serveOver = true;
    this.serveOverIndex = key;
    // comfrimService({
    //   relationId: this.services[key].relationId,
    //   confirmType: 1
    // }).then((res: any) => {
    //   if (res.code === 0) {
    //     message.success("确认成功");
    //     this.$emit("againGetList");
    //   } else {
    //     message.error("确认失败");
    //   }
    // });
    // this.serveComplete = false;
  }

  serviceConfirm() {
    comfrimService({
      data: {
        relationId: this.services[this.serveOverIndex].relationId,
        confirmType: 1,
      },
    }).then(({data, code, msg}: any) => {
      this.serveOver = false;
      this.$emit("againGetList");

      if (code == 0) {
        message.success(msg);
      } else {
        message.error(msg);
      }
    });
  }

  refundingEvent(index) {
    this.$router.push({
      path: "/personal/returnService2",
      query: {
        returnId: this.services[index].refundId,
        relationId: this.services[index].relationId,
      },
    });
  }

  deleteEvent(serviceOrderInfoId: String) {
    this.recycleBin = true
  }

  deleteOrder(serviceOrderInfoId: String) {
    if (
        this.allcontent["statusDic"] === 295 ||
        this.allcontent["statusDic"] === 296 ||
        this.allcontent["statusDic"] === 297
    ) {
      deleteOrder({
        serviceOrderInfoId,
      }).then((res) => {
        if ((res as any).code === 0) {
          message.success("删除成功,已移到服务订单回收站");
          this.$emit("againGetList");
        } else {
          message.error("只有已完成、已取消、已关闭的订单能够放入回收站");
        }
        this.recycleBin = false
      });
    } else {
      message.error("只有已完成、已取消、已关闭的订单能够放入回收站");
      this.recycleBin = false

    }
  }

  isHaveAttr(Obj: Object, attr: any) {
    if (Obj.hasOwnProperty(`${attr}`)) {
      return true;
    } else {
      return false;
    }
  }
//我的服务-支付
  topay(allcontent) {
    jointOrder({
      orderId: allcontent.serviceOrderInfoId,
    }).then((res:any) => {
      if(res.code==0){
        this.$router.push({
          path: "/serviceOrderPay",
          query: {
            mainServiceOrderCode: res.mainServiceCode,
          },
        });
      }

    })
  }

  testEntry(services) {
    let localUrl = window.location.href
    let testEnvFlag = true
    //查看是否是正式环境
    if ((localUrl.indexOf("test") == -1) && (localUrl.indexOf("localhost") == -1) && (localUrl.indexOf("develop") == -1)) {
      testEnvFlag = false
    } else {
    }
    let relationID = JSON.stringify(services[0].relationId)
    relationID = relationID.substring(1, relationID.length - 1)
    request(`${baseUrl}/api/v1/api-services/zaixian/serviceType_forNextStep`, {
      method: "get",
      params: {
        relationId: services[0].relationId,
      },
    }).then((res: any) => {
      if (res.data.resultInfo != 12) {
      } else if (res.data.resultInfo == 12) {
        if (testEnvFlag) {
          //window.location.href="http://mcp-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo="+res.data.resultInfo;
          request(`${baseUrl}/api/v1/api-services/zaixian/getPolicyNobyRelationID`, {
            method: "get",
            params: {
              relationId: relationID,
            },
          }).then((res: any) => {
            window.location.href = "http://mcp-test-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo=" + res.data.resultInfo;

          })
        } else {
          request(`${baseUrl}/api/v1/api-services/zaixian/getPolicyNobyRelationID_N`, {
            method: "get",
            params: {
              relationId: relationID,
            },
          }).then((res: any) => {
            window.location.href = "http://mcp-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo=" + res.data.resultInfo;

          })
        }


      }
    })
  }

  applyReturn(index) {
    const o: any = {other: this.services[index]};
    this.$router.push({
      path: "/personal/returnService1",
      query: {relationId: o.other.relationId},
    })
    // this.$router.push({
    //   path: "/personal/AskBuyAfterService",
    //   query: {params: json.stringify(o)},
    // });
  }

  applyReturning(index) {
    // this.$router.push({
    //   path: "/personal/AskBuyAfterService",
    //   // query: { params: "111" }
    // });
  }

  comfrimOpenService(index) {
    this.openService = true;
    this.curIndex = index;
  }

  comfrimServiceOrder() {
    this.serveComplete = true;
  }

  serviceOrderConfirm() {
    serviceOrderConfirm({
      serviceOrderId: this.serviceOrderInfoId,
    }).then((res) => {
      if (res.code === 0) {
        message.success("确认成功");
        this.$emit("againGetList");
      } else {
        message.error("确认失败");
      }
    });
    this.serveComplete = false;
  }

  serviceReceiptApply(serviceOrderInfoId: any, i) {
    serviceOrderDetal({serviceOrderInfoId: serviceOrderInfoId}).then(
        (res) => {
          if (res) {
            let data: any = (res as any).data || {};
            data.serviceOrderInfoId = serviceOrderInfoId;

            this.SET_DETAILITEM(data);
            // this.SET_RELATIONID(this.services[i]);
            // window.sessionStorage.setItem(
            //   "relationId",
            //   this.services[i].relationId
            // );
            this.$router.push("/personal/serviceReceiptDetail");
          }
        }
    );
  }

  payTime() {
    window.setInterval(() => {
      if (this.time.seconds != 0) {
        --this.time.seconds;
      } else if (this.time.minutes != 0) {
        this.time.minutes = this.time.minutes - 1;
        this.time.seconds = 59;
      } else if (this.time.hours != 0) {
        --this.time.hours;
        this.time.minutes = 60;
        // this.time.seconds = 60;
      } else if (this.time.days != 0) {
        --this.time.days;
        this.time.hours = 24;
        this.time.seconds = 60;
      }
    }, 1000);
  }

  toChat(query) {
    let url = '/#/chat?'
    for (let key in query) {
      url += key + '=' + query[key] + '&'
    }
    url.substr(0, url.length - 1)
    open(url)
  }
}

import { render, staticRenderFns } from "./cooperationWithMerchants.vue?vue&type=template&id=2eaaba4a&scoped=true&"
import script from "./cooperationWithMerchants.vue?vue&type=script&lang=ts&"
export * from "./cooperationWithMerchants.vue?vue&type=script&lang=ts&"
import style0 from "./cooperationWithMerchants.vue?vue&type=style&index=0&id=2eaaba4a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eaaba4a",
  null
  
)

export default component.exports
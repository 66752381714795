































































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button } from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";

Vue.use(Button);

@Component({
  components: {
    PersonalHeader
  }
})
export default class extends Vue {
  // 1申请中 2完成
  currentState = 2;
  // 1个人 2企业
  receiptType = 2;
}

<template>
    <div>
        <Header/>
        <Nav/>
        <div class="breadcrumb">
            <div class="container">
                <a-breadcrumb separator=">">
                    <a-breadcrumb-item>
                        <router-link to="/home" class="breadcrumbO">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link to="/pensionService" class="breadcrumbO">养老服务</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item class="breadcrumbO" :style="{ color: '#39BEB8' }">
                        {{fatherName }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
        </div>
        <div class="body">
            <InfoCard
                    v-for="item in pageContent"
                    :key="item.thirdServiceId"
                    :cardImage="item.classificationPhoto"
                    :cardTitle="item.thirdServiceName"
                    :cardContent="item.description"
                    :servicesList="item.servicesList"
                    :showMore="item.servicesList.length > 0"
                    :thirdServiceId="item.thirdServiceId"
                    :thirdServiceName="item.thirdServiceName"
                    @gotopage="gotopage"
            />
            <div class="pagination">
                <a-pagination
                        :total="pagination.total"
                        :pageSize="pagination.pageSize"
                        :showTotal="total => `共 ${pagination.total} 条`"
                        @change="onchange"
                        :item-render="$store.state.itemRender"
                        showQuickJumper
                />
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Component, Vue, Watch} from "vue-property-decorator";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import InfoCard from "@/components/InfoCard.vue";
    import InfoCard2 from "@/components/InfoCard2";
    import {fetchDetailBySecondId} from "../services/api";

    @Component({
        components: {
            Nav,
            Footer,
            Header,
            InfoCard,
            InfoCard2
        },
        data() {
            return {};
        }
    })
    export default class PensionInfo extends Vue {
        pagination = {
            pageNum: 1,
            pageSize: 10,
            total: 0
        };

        pageContent = [];

        secondId = 1;
        fatherName = '';

        gotopage(params) {
            this.$router.push({
                path: "/pensionFood",
                query: {serviceClassificationId: params.thirdServiceId}
            });
        }

        mounted() {
            const secondId = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1,
                window.location.href.length
            );
            this.secondId = secondId;
            const cityId = parseInt(localStorage.getItem("provinceId") || "2", 10);
            const params = {
                pageSize: 10,
                pageIndex: 1,
                cityId: cityId,
                secondServicesId: secondId
            };
            this.getSecondServiceList(params);
        }

        onchange(page, pageSize) {
            window.scrollTo({top: 0});

            const temp = {
                ...this.pagination,
                pageNum: page,
                pageSize
            };
            this.pagination = temp;

            const secondId = window.location.href.substring(
                window.location.href.lastIndexOf("/") + 1,
                window.location.href.length
            );
            const cityId = parseInt(localStorage.getItem("provinceId") || "2", 10);
            this.pageContent.length = 0;
            const params = {
                pageSize: this.pagination.pageSize,
                pageIndex: this.pagination.pageNum,
                cityId: cityId,
                secondServicesId: secondId
            };
            this.getSecondServiceList(params);
        }

        @Watch("$route")
        onChangeValue(to, from) {
            this.pageContent.length = 0;
            const cityId = parseInt(localStorage.getItem("provinceId") || "2", 10);
            const params = {
                pageSize: 10,
                pageIndex: 1,
                cityId: cityId,
                secondServicesId: to.params.secondId
            };
            this.secondId = to.params.secondId;
            this.getSecondServiceList(params);
        }

        getSecondServiceList(params) {
            fetchDetailBySecondId(params).then(res => {
                if (res.code === 0) {
                    this.fatherName = res.date.fatherName
                    let that = this;
                    res.date.thirdServices.forEach(item => that.pageContent.push(item));
                    this.pagination = {
                        ...this.pagination,
                        total: res.date.totalCount
                    };
                }
            });
        }
    }
</script>

<style lang="less" scoped>
    .breadcrumb {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.18rem 0 0.18rem;
        background-color: #fff;
        .container {
            width: 13.66rem;
            margin: 0 auto;
            .breadcrumbO{
                font-size: 0.14rem;
                color: #2A2E43;
                opacity: 1;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background-color: #fff;
    }
</style>









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: String,
    img:String

  }
})
export default class PersonalHeader extends Vue {
  // @Prop() private msg!: string
}

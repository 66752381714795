import {RootStateTypes} from "./types ";
import {MutationTree} from "vuex";

const mutations: MutationTree<RootStateTypes> = {
  SET_AUTHOR(state: RootStateTypes, data: string) {
    state.author = data;
  },
  SET_DETAILITEM(state: RootStateTypes, data: string, save?: boolean) {
    if (!save) {
      localStorage.setItem("DETAILITEM", JSON.stringify(data));
    }
    state.detailItem = data;
  },
  SET_CLASSSERVICEDETAIL(state: RootStateTypes, data: string, save?: boolean) {
    if (!save) {
      localStorage.setItem("CLASSSERVICEDETAIL", JSON.stringify(data));
    }
    state.classServieDetail = data;
  },
  SET_CURDETAILSTATUS(state: RootStateTypes, data: string, save?: boolean) {
    if (!save) {
      localStorage.setItem("CURDETAILSTATUS", JSON.stringify(data));
    }
    state.curOrderStatus = data;
  },
  SET_PAYTIMES(state: RootStateTypes, data: string) {
    state.payTime = data;
  },
  SET_CANCELRESON(state: RootStateTypes, data: any) {
    state.cancelReason = data;
  },
  SET_ALLORDERSTATUS(state: RootStateTypes, data: string) {
    state.allOrderStatus = data;
  },
  SET_ALLSERVICESTATUS(state: RootStateTypes, data: string) {
    state.allServiceStaus = data;
  },
  SET_REFUNDREASONS(state: RootStateTypes, data: string) {
    state.refundReasons = data;
  },
  SET_RETURNID(state: RootStateTypes, data: string) {
    state.returnId = data;
  },
  SET_RELATIONID(state: RootStateTypes, data: Object) {
    state.relationId = data;
  },
  SET_ALLRSERVATIONSTATUS(state: RootStateTypes, data: Object) {
    state.allReservationStatus = data;
  },
  SET_CLASSSERVICEONESTEP(state: RootStateTypes, data: Object, save?: boolean) {
    if (!save) {
      localStorage.setItem("CLASSSERVICEONESTEP", JSON.stringify(data));
    }
    state.classServiceOneStep = data;
  },
  SET_CLASSSERVICETWOSTEP(state: RootStateTypes, data: Object, save?: boolean) {
    if (!save) {
      localStorage.setItem("CLASSSERVICETWOSTEP", JSON.stringify(data));
    }
    state.classServiceTwoStep = data;
  },
  SET_CLASSSERVICETHREESTEP(
    state: RootStateTypes,
    data: Object,
    save?: boolean
  ) {
    if (!save) {
      localStorage.setItem("CLASSSERVICETHREESTEP", JSON.stringify(data));
    }
    state.classServiceThreeStep = data;
  },
  SET_ORGFORM(state: RootStateTypes, data: Object) {
    state.orgFormJson = data;
  },
  show_fakeDiv(state, bool) {
    state.fakeDivBool = bool;
  },
  show_zxptDiv(state, bool) {
    state.zxptDivBool = bool;
  },
  setServiceJoinStep(state, val) {
    state.serviceJoinStep = val;
  },
//下面是商城里存的仓库数据
  setHomeBannerHeight(state:any, height) {
    state.homeBannerHeight = height;
  },
  setScrollTop(state:any, height) {
    state.scrollTopHeight = height;
  },
  setSliderBarItems(state:any, arr) {
    state.sliderBarItems = arr;
  },
  setfixedCurTop(state:any, fixedCurTop) {
    state.fixedCurTop = fixedCurTop;
  },
  setProducNumInCart(state:any, num: number) {
    state.producNumInCart = num;
  },
  seCartPayData(state:any, data: any) {
    state.cartPayData = data;
  },
  setProductCommontTotalCount(state:any, data: any) {
    state.productCommontTotalCount = data;
  },
  // 设置注册成功之后跳转到哪个页面
  setRegisterSuccessTargetPage(state:any, data: String) {
    state.registerSuccessTargetPage = data;
  },
};

export default mutations;























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {http} from "@/services/http";

@Component({})
export default class searchHeader extends Vue {
    @Prop() menuId: any;
    @Prop() keywords: any;
    @Prop() classId: any;
    @Prop() totalCount: number;
    @Prop() genre: string;

    created() {
        this.getClassList();
        this.getBrands();
        this.sortCondition.map((item, index) => {
            this.sortList.push({
                name: item,
                id: index,
            });
        });
    }

    brandList = [];
    brandListTwo = [];
    classList = [];
    classListTwo = [];
    cityNumber: any = '0';
    genreNumber: any = '0';
    priceNumber: any = '0';
    state: any = false;
    pullDown: any = 0;
    pullDownTwo: any = 0;
    brandListDown: any = 0;
    brandListDownTwo: any = 0;
    typeValue: any = 0;
    ce = false
    priceList = [
        {
            name: "0-200元",
            id: 488,
        },
        {
            name: "200-500元",
            id: 489,
        },
        {
            name: "500-1000元",
            id: 490,
        },
        {
            name: "1000-5000元",
            id: 491,
        },
        {
            name: "5000元以上",
            id: 492,
        },
    ];
    shopSortContentArr = [
        {name: "综合", value: 0},
        {name: "销量", value: 1},
        {name: "价格", value: 2},
        // { name: "上架时间", value: 3 },
    ];
    sortList: any = [];
    classLevelType = 2;
    flag = true;
    flagTwo = true;
    screenWidth: any = window.innerWidth;

    mounted() {
        let that = this;
        let screenWidth: any = window.screen.width
        window.onresize = () => {
            return (() => {
                screenWidth = window.innerWidth;
                this.screenWidth = screenWidth;
            })()
        }
    }

    @Watch('screenHeight', {deep: true})
    handleWatch(val) {
        this.screenWidth = val
    }

    showTag() {
        this.flag = !this.flag
    }

    showTagTwo() {
        this.flagTwo = !this.flagTwo
    }

    //获取头部搜索内容
    getClassList(ClassListFS?: any) {
        if (ClassListFS) this.classLevelType = 3;
        const str: string = localStorage.getItem("menuLevel2") as string;
        this.classList = JSON.parse(str).slice(0, 8);
        this.classListTwo = JSON.parse(str);
        this.pullDown = 6
        this.pullDownTwo = 8
        if (this.$route.query.id)
            this.classList.forEach((v:any) => {
                if (v.commodityTypeId == this.$route.query.id) {
                    this.selectConditions.push({
                        name: v.typeName,
                        type: "商品类目",
                        id: v.commodityTypeId,
                    });
                }
            });
    }

    getBrands() {
        //根据首页输入关键字信息查询相关信息
        if (!this.menuId) {
            http.get(`/api/v1/shop-mall/input-relation/brands`, {
                params: {searchInput: this.keywords},
            }).then((res: any) => {
                this.brandList = res["data"].brandList.slice(0, 12);
                this.brandListTwo = res["data"].brandList;
                this.brandListDown = 8
                this.brandListDownTwo = 12
            });
        } else {
            //根据首页左侧商品分类查询信息
            http.get(`/api/v1/shop-mall/type-relation/brands`, {
                params: {commodityType: this.menuId, typeClass: this.classId},
            }).then((res) => {
                this.brandList = (res as any)["data"].brandList.slice(0, 12);
                this.brandListTwo = (res as any)["data"].brandList;
                this.brandListDown = 8;
                this.brandListDownTwo = 12;
                (this.$refs.searchHeader as any).getClassList(
                    (res as any)["data"].commodityNextTypes
                );
            });
        }
    }

    ifBrandMore = true;

    topTO0() {
        const el: any = document.querySelector(".right");
        el.scrollTo({top: 0});
    }

    celerCondition(e: any) {
        e.preventDefault();
        this.selectConditions = [];

    }

    addToSelected(type: string, name: string, id?: string) {
        this.cityNumber = id

        if (!this.selectConditions.find((item: any) => item.name == name && item.type == type)) {
            this.selectConditions.push({name, type, id: id});
        }
    }

    removecCondition(item: { type: string; name: string }) {
        this.selectConditions = this.selectConditions.filter((val: any) => val != item);
    }

    selectConditions: any = [];
    sortCondition = ["综合评分", "销量", "价格", "价格", "上架时间"];
    selectSort = 0;
    priceUp = true;

    sortType(i: number) {
        this.selectSort = i;
        this.typeValue = i
        this.$emit("conditionChange", this.selectConditions);

        if (i == 2 || i == 3) this.priceUp = !this.priceUp;
    }

    @Watch("selectConditions") selectConditionsChange() {
        this.$emit("conditionChange", this.selectConditions);
    }
}
























































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    props: {
        // commodityOrder: Object,
        // amount:any
    },
})
export default class settlementList extends Vue {
    @Prop() amount: any;
    @Prop() commodityOrder: any;
    @Prop() commoditiesInCarts: any;
    @Prop() carriage: any;
    @Prop() combination: any;
    @Prop() orderRemark: any;
    // addAmount() {
    //     this.$emit("addAmount", this.amount);
    // };
    // minusAmount() {
    //     this.$emit("minusAmount", this.amount);
    // }
    // //计算服务商合计金额
    // get totalAmount() {
    //         return (
    //             this.serviceOrder.servicePrice * +this.amount +
    //             +this.amount * this.serviceOrder.serviceStyleList[0].serviceFee
    //         );
    // }
    combinationList=''
    get totalAmount() {
        let  totalPrices =0
        this.commoditiesInCarts.forEach(item=>{
            totalPrices+= item.totalPrice
        })
        return totalPrices;
    }
    //监听区域宽度变化
    // @Watch("combinationList")
    // getCombinationList(val) {
    //     // this.orderRemark=val
    //     this.$emit('orderRemarkModal',val)
    // }
    blur(){
        // this.orderRemark =this.combinationList
        this.$emit('orderRemarkModal',this.combinationList)
    }
    mounted(){
        // this.orderRemark = this.combinationList;
    }



}




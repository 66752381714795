











































    import {Component, Vue} from "vue-property-decorator";
    import {Table} from "ant-design-vue";
    import {baseUrl} from "../../../services/http";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";

    Vue.use(Table);

    @Component({
        components: {
            Breadcrumb
        }
    })
    export default class extends Vue {
        baseUrl = baseUrl
        data: any = {}; // 页面数据
        //面包屑
        breadcrumb = [
            {
                name: "个人中心",
                path: "/personal/myHomePage",
                significance: 0

            },
            {
                name: "我的商品",
                path: "/personal/MyGoods",
                significance: 0
            },
            {
                name: "申请退款",
                path: "/",
                significance: 1
            },
        ]

        mounted() {
            this.data = this.$route.query
        }


//我要退款
        reimburse() {
            const {href} = this.$router.resolve({
                path: "/personal/ReturnGoodsOne",
                query: {
                    // commodityRelationId: JSON.stringify(this.data.orderCommodityId)
                    commodityRelationId: this.data.orderCommodityId
                }
            });
            window.open(href, "_blank");

        }

//申请退货退款
        refundReturnProduct() {
            const {href} = this.$router.resolve({
                path: "/personal/returnGoods1",
                query: {
                    commodityRelationId: this.data.orderCommodityId
                    // commodityRelationId: JSON.stringify(this.data.orderCommodityId)
                }
            });
            window.open(href, "_blank");
        }


    }








































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";

Vue.use(Rate);

@Component({
    props: {
        avatarImg: String,
        nickName: String,
        rate: Number,
        comment: String,
        createTime: String,
        headIcon: String,
        agencyReply: Object,
        adminReply: Object
    }
})
export default class ServiceComment extends Vue {
    // @Prop() private msg!: string
    getNickName() {
        const value = this.$props.nickName;
        if(value.length>3){
            const name: any[] = this.$props.nickName.split("");
            return name[0] + "*****" + name[name.length - 1];
        }else{
            return  value
        }

        // return name[0] + "*****" + name[name.length - 1];
    }
}





















































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button, Input, Form, Select } from "ant-design-vue";

Vue.use(Button);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);

@Component({
  components: {}
})
export default class extends Vue {
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 }
    }
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 14,
        offset: 10
      }
    }
  };
  form: any;
  beforeCreate() {
    this.form = this.$form.createForm(this);
  }
}






















































































import {Component, Prop, Vue} from "vue-property-decorator";
import CommodityCollectionScrollItem from "./CommodityCollectionScrollItem.vue";
import {callOffTop, cancelCollection} from "@/services/api";
import {baseUrl, mallUrl} from "../../../services/http";
import {message} from "ant-design-vue";

@Component({components: {CommodityCollectionScrollItem}})
    export default class serviceProviderItem extends Vue {
        baseUrl = baseUrl;
        mallUrl = mallUrl;
        @Prop() public item!: any;
        @Prop() seviceList: any;
        currentScrollPage = 0;
        totalScrollPage = 0;
        nowList = [];
        mouseIsEnter = false;
        serveCancel = false;

        created() {
            this.nowList = this.seviceList.slice(0, 5);
            this.totalScrollPage = Math.ceil(this.seviceList.length / 5);
        }

        //置顶、取消置顶
        cancelTheTop(value) {
            callOffTop({
                collectionId: value,
            }).then(res => {
                if (res && res.code === 0) {
                    // 处理数据
                    this.$emit("getListAgain");
                } else {
                    message.error(res.msg);
                }
            });
        }

        //取消收藏
        cancel() {
            this.serveCancel = true;
        }
        enshrine(){
            cancelCollection({
                collectionId: this.item.collectId,
            }).then(res => {
                if (res.code == 0) {
                    this.$emit("getListAgain");
                    this.serveCancel = false;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }

        handlePre() {
            if (this.currentScrollPage > 0) {
                this.currentScrollPage--;
                this.nowList = this.seviceList.slice(
                    this.currentScrollPage * 5,
                    this.currentScrollPage * 5 + 5
                );
            }
        }

        //跳转店铺
        enterStore(data) {
            open(mallUrl + '/#/mall/shop/' + data.merchantsId);
        }

        handleNext() {
            if (this.currentScrollPage < this.totalScrollPage - 1) {
                this.currentScrollPage++;
                this.nowList = this.seviceList.slice(
                    this.currentScrollPage * 5,
                    this.currentScrollPage * 5 + 5
                );
            }
        }
    }

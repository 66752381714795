











































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
import aSpin from "@/components/aSpin.vue";

@Component({
    components: {
        PersonalHeader,
        OpenServiceItem,
        aSpin
    },
})
export default class extends Vue {
    @Prop() topLis: any;
    @Prop() data: any;
    @Prop() pagination: any;
    @Prop() emptyTextBySearch: any;
    currentRelationId = "";
    currentServiceType = 0;
    currentTab = 1;
    serviceStatus = 0;
    loading:any=true;
    @Watch("data", {immediate: true, deep: true})
    getData(newVal) {
        this.loading=false
    }

    // 点击查看退款记录
    handleApplyReturn(id) {
        this.$emit("handleApplyReturn", id);
    }

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        this.$emit("getApplyReturn");
    }


}





















import {message} from "ant-design-vue";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
    props: {
        img: String,
        title: String,
        content: String,
        phone: String,
        location: String,
        subOrgId: String,
    },
})
export default class SubOrgItem extends Vue {
    @Prop() private subOrgId!: string;
    @Prop() private checkStatus: string;
    @Prop() private datatype: string;

    lookDetail(value) {
        if (this.checkStatus != "41") {
            message.warn("该机构已下架或机构重审核中");
            return;
        }
        this.$router.push({
            path: `/subordinateBody/${value}`,
        });

    }
}







































































































import {Component, Vue} from "vue-property-decorator";
import {Divider, Input, message} from "ant-design-vue";
// import ce from "../creditInvestigation/json/login.json"
import {fetchCollection,} from "@/services/api";
import Footer from "@/components/Footer.vue";

Vue.use(Input);
Vue.use(Divider);

@Component({
    components: {
        Footer,
    },
})
export default class extends Vue {
    username = "";
    password = "";
    remember = "";
    checked = false;
    orgId = "";
    keypressEvent: any;

    mounted() {
        this.keypressEvent = (e) => {
            if (e.key == "Enter") {
                this.handleLogin();
            }
        };
        document.body.addEventListener("keypress", this.keypressEvent, false);

    }

    created() {
        this.orgId = location.href.split("login?collectionOrgid=")[1];
    }


    fetchCollection(operation = 0) {
        const href = sessionStorage.getItem("pay");
        if (href) {
            open(href, "_self");
            window.close();
            return "pay";
        }

        const params = {
            itemId: this.orgId,
            itemType: 238,
        };
        fetchCollection({
            ...params,
            operation: operation,
        }).then((res) => {
        });
    }

    loginBeforePath: any = "";

    //登录提交方法
    handleLogin() {
        if (!this.username) {
            this.usernameTip = "请输入用户名";
            return false;
        }
        if (!this.password) {
            this.pswTip = "请输入密码";
            return false;
        }
        //登录接口（账号密码登录）

        if (this.username === '15028072724' && this.password === 'admin123') {
            this.$router.push(`/creditInvestigation/creditReportingHome`);
        } else {
            message.error('用户名和密码不匹配，请重新输入');
        }


        // request(`json/login.json`, {
        //     method: "get",
        // }).then((res: any) => {
        //     res.list.forEach((item, index) => {
        //
        //     })
        // });


        // const loginBeforePath = localStorage.getItem("loginBeforePath");
        // if (!this.username) {
        //     this.usernameTip = "请输入用户名";
        //     return false;
        // }
        // if (!this.password) {
        //     this.pswTip = "请输入密码";
        //     return false;
        // }
        // //登录接口（账号密码登录）
        // userLogin({
        //     username: this.username,
        //     password: this.password,
        // }).then((res) => {
        //     if (res && res.code === 200) {
        //         //清空enter键赋值，登录成功后禁止按enter键再次重新登录
        //         (document as any).onkeydown = undefined;
        //         localStorage.setItem("userInfo", json.stringify(res.data));
        //         if (this.fetchCollection()) return;
        //
        //         document.body.removeEventListener(
        //             "keypress",
        //             this.keypressEvent,
        //             false
        //         );
        //         if (
        //             loginBeforePath == "/register" ||
        //             loginBeforePath == "/forgetPasswordNew"
        //         )
        //             this.$router.push("/home");
        //         else this.$router.back();
        //     } else {
        //         this.pswTip = res.msg;
        //         //   "用户名和密码不匹配，请重新输入";
        //         // message.error(res.msg);
        //     }
        // })
    }

    changePwd(e: any) {
        this.password = e.target.value;
        this.pswTip = "";
    }


    usernameTip = "";
    pswTip = "";


    jumpToHome() {
        this.$router.push("/");
    }
}

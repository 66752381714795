





























import {Component, Prop, Vue} from "vue-property-decorator";
import {Pagination, Tooltip} from "ant-design-vue";
import {fetchMenuYlfwList, pensionServiceRecommendList} from "@/services/api";

Vue.use(Tooltip);
Vue.use(Pagination);

interface YlfwPageContent {
    classificationLevel: number;
    classificationName: string;
    description: string;
    fatherId: string;
    orderId: number;
    serviceClassificationId: string;
    thirdTypeList?: YlfwPageContent[];
}

@Component
export default class Ylfw extends Vue {
    @Prop() private isRecommend!: boolean;

    pagination = {
        pageNum: 1,
        pageSize: 10,
        total: 0
    };

    ylfwContent: YlfwPageContent[] = [];

    mounted() {
        const params = {
            pageSize: 10,
            pageIndex: 1
        };
        if (this.isRecommend) {
            this.pensionServiceRecommendList();
        } else {
            this.getYlmsList({
                pageSize: 100,
                pageIndex: 1
            });
        }
    }

    //查看详情
    // () => $router.push(`/pensionInfo/${item.serviceClassificationId}`)
    particulars(id,name) {
        this.$router.push({
            path: '/pensionInfo/'+`${id}`
        })
    }

    goPath(path, serviceClassificationId, e) {
        e.stopPropagation();
        this.$router.push({
            path: path,
            query: {serviceClassificationId: serviceClassificationId}
        });
    }

    getYlmsList(params: any) {
        fetchMenuYlfwList(params).then(res => {
            if (res.data && res.data.secondTypeList) {
                res.data.secondTypeList.forEach((item: YlfwPageContent) =>
                    this.ylfwContent.push(item)
                );
                this.pagination = {
                    ...this.pagination,
                    total: res.data.totalCount
                };
            }
        });

    }

    pensionServiceRecommendList() {
        pensionServiceRecommendList({}).then(res => {
            this.ylfwContent = res.data.secondTypeList;
        });
    }

    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});

        const temp = {
            ...this.pagination,
            pageNum: page,
            pageSize
        };
        this.pagination = temp;

        const params = {
            pageSize: this.pagination.pageSize,
            pageIndex: this.pagination.pageNum
        };
        this.getYlmsList(params);
    }
}

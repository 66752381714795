<template>
  <div class="message" v-scroll-bottom="(session.messages,that)">
    <ul v-if="session">
      <li v-for="(item, index) in session.messages">
        <p class="time" v-if="index == 0 || item.sentTime - session.messages[index - 1].sentTime > intervalTime * 60000">
          <span>{{ item.sentTime | time }}</span>
        </p>
        <div :class="{ self: item.senderUserId != item.targetId }">
          <img class="avatar" width="30" height="30" :src="item.senderUserId != item.targetId ? me.headIcon : session.headIcon" />
          <div class="content" @click="clickMessageHandle(item)">
            <span v-if="item.messageType=='RC:TxtMsg'">{{ item.content.content }}</span>
            <img v-if="item.messageType=='RC:ImgMsg'" :src="'data:image/jpeg;base64,' + item.content.content" @click="openPic(item.content.imageUri)">
            <div v-if="item.messageType=='FYTL:Service'">
              <img :src="item.content.servicePic">
              <p class="text">{{ item.content.serviceName}}</p>
              <span>￥{{ item.content.price}}</span>
            </div>
            <div v-if="item.messageType=='FYTL:ServiceOrder'">
              <img :src="item.content.servicePic">
              <p class="text">{{ item.content.serviceName}}</p>
              <span>订单金额￥{{ item.content.totalAmount}}</span>
              <div class="button">{{ item.content.status}}</div>
            </div>
            <div v-if="item.messageType=='FYTL:Commodity'">
              <img :src="item.content.commodityPic">
              <p class="text">{{ item.content.commodityName}}</p>
              <span>￥{{ item.content.price}}</span>
            </div>
            <div v-if="item.messageType=='FYTL:CommodityOrder'">
              <img :src="item.content.commodityPic">
              <p class="text">{{ item.content.commodityName}}</p>
              <span>订单金额￥{{ item.content.totalAmount}}</span>
              <div class="button">{{ item.content.status}}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      session: {
        type: Object,
        default: function (){return {}}
      },
      me: {
        type: Object,
        default: function (){return {}}
      },
      intervalTime: {
        // 显示时间标签的最大间隔（单位：分钟）
        // 若当前消息距离上一条消息的时间大于此值，将在当前消息上方显示发送时间
        type: Number,
        default: 5
      }
    },
    model: {
      prop: 'session',
      event: 'change'
    },
    watch: {
      session() {
        this.$emit('change', this.session)
      }
    },
    data() {
      return {
        that: this,
      }
    },
    methods: {
      clickMessageHandle(message) {
        this.$emit('click', message);
      },
      openPic(uri) {
        window.open(uri)
      }
    },
    filters: {
      // 将日期过滤为 hour:minutes
      time (date) {
        date = new Date(date);
        return date.getHours() + ':' + (date.getMinutes() < 10 ? '0': '') + date.getMinutes().toString();
      }
    },
    directives: {
      // 发送消息后滚动到底部
      'scroll-bottom' (el, binding) {
        console.log('滚动到底部')
        console.log(el)
        console.log(binding)
        let that = binding.value.that
        that.$nextTick(() => {
          that.$el.scrollTop = that.$el.scrollHeight - that.$el.clientHeight;
        });
      }
    }
  };
</script>



<style lang="less" scoped>
  .text {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 19px;
    height: 19px;
    color: #454F63;
  }
  .message {
    padding: 10px 15px;
    overflow-y: scroll;
    background-color: #ffffff !important;

    ul {
      padding-left: 0px;
    }
    li {
      margin-bottom: 15px;
      list-style-type: none;
    }
    .time {
      margin: 7px 0;
      text-align: center;

      > span {
        display: inline-block;
        padding: 0 18px;
        font-size: 12px;
        color: #fff;
        border-radius: 2px;
        background-color: #dcdcdc;
      }
    }
    .avatar {
      float: left;
      margin: 0 10px 0 0;
      border-radius: 3px;
    }
    .content {
      display: inline-block;
      position: relative;
      max-width: 310px;
      min-height: 30px;
      line-height: 2.5;
      font-size: 12px;
      text-align: left;
      word-break: break-all;
      background-color: #fafafa;
      border: 1px solid #E4E9F2;
      border-radius: 4px;
      img {
        cursor: pointer;
        max-width: inherit;
        padding: 0;
      }
      span {
        display:inline-block;
        padding: 0px 10px 0px 10px;
      }
      div {
        &:hover {
          background: #EEF3FA;
        }
        cursor: pointer;
        transition: all .15s;
        position: relative;
        background: #ffffff;
        padding: 0px;
        margin: 0px;
        width: 310px;
        height: 100px;
        border: 1px solid #E4E9F2;
        border-radius: 4px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 12px;
          margin: 10px;
        }
        p {
          margin: 0px;
          padding: 0px;
          position: absolute;
          left: 100px;
          top: 15px;
          width: calc(100% - 110px);
          height: 40px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        span {
          margin: 0px;
          padding: 0px;
          position: absolute;
          left: 100px;
          bottom: 18px;
          height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #78849E;
        }
        .button {
          position: absolute;
          right: -1px;
          bottom: 14px;
          width: 76px;
          height: 30px;
          background-color: #ffffff;
          border: 1px solid #78849E;
          border-radius: 15px;
          margin-right: 10px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 28px;
          color: #78849E;
          transition: all .15s;
        }
      }
    }

    .self {
      text-align: right;
      .avatar {
        float: right;
        margin: 0 0 0 10px;
      }
      .content {
        background-color: #ffffff;
        max-width: 310px;
      }
    }
  }
</style>






























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Icon, message } from "ant-design-vue";
import GoodsDetail from "@/components/Personal/myGoods/GoodsDetail.vue";
import { baseUrl } from "../../../services/http";
import {
  confirmReceipt,
  PushOrderReason,
  cancellationOfOrder, unpaidInformation, fetchProvinceName,
  commodityRecyclingStation, aftersalesApplicationStatus
} from "@/services/api";
import {mallUrl} from "@/services/http"

import { Mutation, State } from "vuex-class";

Vue.use(Icon);
@Component({
  components: {
    GoodsDetail
  },
  props: {
    location: String,
    allcontent: Object
  }
})
export default class MyGoodsItem extends Vue {
  baseUrl = baseUrl;
  @Prop() private allcontent!: Object;
  @Prop() private serviceOrderInfoId!: String;
  @Prop() public services!: any;
  @Prop() private isAllCheck!: Boolean;
  @Prop() private id!: Number;
  @Prop() private ischeck!: Boolean;
  cancelServiceList = {};
  @Mutation SET_CURDETAILSTATUS;

  //订单详情
  lookDetail(orderInfoId: any) {
    window.open("#/personal/OrderDetail");
    this.SET_CURDETAILSTATUS(orderInfoId);
  }



  //单选
  onChange(e, id, ischeck, serviceOrderInfoId) {
    if (!ischeck) {
      this.$emit("getOrderInfoList", {
        id: id,
        ischeck: ischeck,
        serviceOrderInfoId: serviceOrderInfoId
      });
    } else {
      this.$emit("deleteOrderInfoList", {
        id: id,
        ischeck: ischeck,
        serviceOrderInfoId: serviceOrderInfoId
      });
    }
  }
  //和我联系
  toChat(query) {
    let url = '/#/chat?'
    for(let key in query) {
      url += key + '=' + query[key] + '&'
    }
    url.substr(0, url.length - 1)
    open(url)
  }
  //单个删除
  deleteEvent(value) {
    this.$emit("deleteEvent", value);
  }
  //单个还原
  revivification(value) {
    this.$emit("revivification", value);
  }

}









































import {Component, Vue} from "vue-property-decorator";
import {message, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import {dictionariesWithDesc, orderFormList} from "@/services/api";
import {baseUrl} from "../../../../services/http";

Vue.use(Table);

@Component({
    components: {
        PersonalHeader
    }
})
export default class extends Vue {
    baseUrl = baseUrl
    // status:any = 6;
    serviceList:any =  []
    pagination = {
        pageSize: 5,
        pageIndex: 1
    };
    columns = [
        {
            title: "商品订单编号",
            dataIndex: "seriesNum",
            width: "2rem",
            align: "center"
        },
        {
            title: "商品名称",
            dataIndex: "commodityInfos",
            width: "3.25rem",
            scopedSlots: {customRender: "commodityInfos"},
            align: "center"
        },
        {
            title: "提交时间",
            dataIndex: "cancelTime",
            width: "1.95rem",
            align: "center"
        },
        {
            title: "商品总金额",
            dataIndex: "totalAmount",
            width: "1.4rem",
            align: "center",
            scopedSlots: {customRender: "totalAmount"}
        },
        {
            title: "是否支付",
            dataIndex: "isPay",
            width: "1.1rem",
            align: "center",
            scopedSlots: {customRender: "isPay"}
        },
        {
            title: "取消原因",
            key: "cancelReason",
            dataIndex: "cancelReason",
            width: "1.4rem",
            align: "center"
        }
    ];
    consumptionDetailData: any = []; // 商品取消订单记录
    mounted() {
        this.reasonCancellation()
        this.consumptionDetail();

    }

    //获取取消原因
    reasonCancellation() {
        dictionariesWithDesc(179).then((res) => {
            if (res && res.code == 0) {
                this.serviceList = res.values;


                orderFormList({
                    pageIndex: 1,
                    pageSize: 10,
                    status: 6
                }).then((res: any) => {
                    if (res && res.code === 0) {
                        // 处理数据
                        this.consumptionDetailData = [];
                        res.data.list.forEach((item: any, index: any) => {
                            let obj: any = {};
                            obj.seriesNum = item.seriesNum; //订单编号
                            obj.cancelTime = item.cancelTime; //取消时间
                            obj.totalAmount = item.totalAmount; //总金额
                            obj.isPay = item.isPay; //是否支付
                            obj.commodityInfos = item.commodityInfos; //商品名称
                            for (var i = 0; i < this.serviceList.length; i++) {
                                if (this.serviceList[i].key == item.cancelReason) {
                                    obj.cancelReason =this.serviceList[i].value; //取消原因
                                }
                            }

                            this.consumptionDetailData.push(obj);
                        });
                    } else {
                        message.error(res.msg);
                    }
                });
            } else {
                message.error(res.msg);
            }
        });
    }

    //商品消费明细
    consumptionDetail() {


    }

}

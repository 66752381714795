<script>

export default {
  props: {
    'sessions': {
      type: Array,
      default: ()=>{return []}
    },
    'target-id': {
      type: String,
      default: undefined
    },
    'filter-key': {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'target-id',
    event: 'change'
  },
  watch: {
    currentId() {
      this.$emit('change', this.currentId)
    },
    targetId() {
      this.currentId = this.targetId
    },
    sessions() {
      this.updateSessions()
    },
    filterKey() {
      this.updateSessions()
    }
  },
  data() {
    return {
      currentId: this.targetId,
      visibleSessions: [],
      contextmenuVisibleTargetId: false,
      contextmenuPosition: {
        x: 0,
        y: 0,
      }
    }
  },
  methods: {
    refresh() {
      this.updateSessions();
      this.$nextTick(()=>{
        this.$forceUpdate()
      })
    },
    selectSession (id) {
      this.currentId = id;
    },
    updateSessions() {
      this.visibleSessions = []
      this.sessions.forEach(session => {
        let visibleUsername = session.username && session.username.includes(this.filterKey);
        let visibleNickname = session.nickname && session.nickname.includes(this.filterKey);
        if(visibleUsername || visibleNickname) {
          this.visibleSessions.push(session)
        }
      })
    },
    onContextMenuShow(event,targetId) {
      this.contextmenuVisibleTargetId = targetId;
      this.contextmenuPosition.x = event.clientX
      this.contextmenuPosition.y = event.clientY
    },
    emitDelete(event, targetId) {
      this.contextmenuVisibleTargetId = false
      this.$emit('destory', targetId);
      event.stopPropagation();
    }
  },
  created() {
    let that = this
    const hideMenu = ()=>{that.contextmenuVisibleTargetId = undefined}
    document.getElementsByTagName("body")[0].addEventListener('click', hideMenu);
    document.getElementsByTagName("body")[0].addEventListener('blur', hideMenu);
    document.getElementsByTagName("body")[0].addEventListener('mousewheel', hideMenu);
    document.getElementsByTagName("body")[0].addEventListener('scroll', hideMenu);
  }
};
</script>

<template>
  <div class="list">
    <ul>
      <li v-for="item in visibleSessions" :class="{ active: item.targetId === currentId }"
          @contextmenu.prevent="onContextMenuShow($event, item.targetId)"
          @click="selectSession(item.targetId)">
        <span class="contextmenu" v-if="contextmenuVisibleTargetId == item.targetId" @click="emitDelete($event,item.targetId)"
              :style="'top: ' + contextmenuPosition.y + 'px; left: ' + contextmenuPosition.x + 'px;'">删除</span>
        <img class="avatar"  width="31" height="31" :alt="item.nickname" :src="item.headIcon">
        <p class="name">{{item.nickname ? item.nickname : item.username}}</p>
        <span :class="{'tag':true, 'orange': item.userType == '养老机构', 'blue': item.userType == '店铺', 'green': item.userType == '服务商' }"
              v-if="item.userType">{{item.userType}}</span>
<!--        <button style="float: right" @click="$emit('destory', item.targetId)">del</button>-->
      </li>
    </ul>
  </div>
</template>

<style scoped lang="less">
.list {
  position: relative;
  .contextmenu {
    z-index: 999;
    position: fixed;
    margin: 0;
    padding: 10px;
    background: #ffffff;
    color: #454F63;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid #E4E9F2;
    opacity: 1;
    border-radius: 4px;
    &:hover {
      background: #EEF3FA;
    }
    &:active {
      background: #D7F2F9;
    }
  }
  ul {
    padding-left: 0px;
    margin: 0px;
  }
  li {
    position: relative;
    height: 51px;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color .1s;
    list-style-type: none;
    background: #F3F5F9;

    &:hover {
      background: #EEF3FA;
    }
    &.active {
      background: #D7F2F9;
    }
  }
  .avatar, .name {
    vertical-align: middle;
  }
  .avatar {
    border: 1px solid #E4E9F2;
  }
  .name {
    display: inline-block;
    margin: 0 0 0 15px;
    overflow: hidden;
    width: 120px;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .tag {
    position: absolute;
    right: 13px;
    top: 15px;
    width: 60px;
    height: 22px;
    border-radius: 4px;
    text-align: center;
    line-height: 22px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
  }
  .orange {
    background-color: #FC982E;
  }
  .blue {
    background-color: #41AEF8;
  }
  .green {
    background-color: #51C2C1;
  }
}
</style>




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class magnifier extends Vue {
  @Prop() imageUrl: string;
  mounted() {
    this.inintThis();
  }
  inintThis() {
    var box: any = document.getElementById("box");
    var smallbox: any = box.children[0];
    var bigbox = box.children[1];
    var bigimg = bigbox.children[0];
    var smallpic = smallbox.children[0];
    var i_box = smallbox.children[1];

    smallbox.onmouseover = function() {
      i_box.style.display = "block";
      bigbox.style.display = "block";

      smallbox.onmousemove = function(event) {
        event = event || window.event;

        var pinX =
          event.clientX -
          smallbox.offsetParent.offsetLeft -
          i_box.offsetWidth / 2;
        var pinY =
          event.clientY -
          smallbox.offsetParent.offsetTop -
          i_box.offsetHeight / 2;

        if (pinX < 0) {
          pinX = 0;
        }
        if (pinX >= smallbox.offsetWidth - i_box.offsetWidth) {
          pinX = smallbox.offsetWidth - i_box.offsetWidth;
        }

        if (pinY < 0) {
          pinY = 0;
        }
        if (pinY >= smallbox.offsetHeight - i_box.offsetHeight) {
          pinY = smallbox.offsetHeight - i_box.offsetHeight;
        }

        i_box.style.left = pinX + "px";
        i_box.style.top = pinY + "px";

        bigimg.style.left =
          -pinX / (i_box.offsetWidth / bigbox.offsetWidth) + "px";
        bigimg.style.top =
          -pinY / (i_box.offsetHeight / bigbox.offsetHeight) + "px";
      };
    };

    smallbox.onmouseout = function() {
      i_box.style.display = "none";
      bigbox.style.display = "none";
    };
  }
}

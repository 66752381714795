













































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";

Vue.use(Rate);

@Component({
    props: {
        itemData:Object
    },
    components: {
        InstitutionInfo
    }
})
export default class YljgItem extends Vue {
    showArr = [];



}

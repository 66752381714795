import Vue from "vue";
import Vuex, {Store} from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
// modules

Vue.use(Vuex);
//
const store: Store<any> = new Vuex.Store({
  actions,
  mutations,
  state,
  modules: {
    //添加自定义模块

  }
});

export default store;

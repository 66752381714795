var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"businessJoinStep1"},[_vm._m(0),_c('div',{staticClass:"progressBar"},[_c('MyStepBar',{attrs:{"stepData":_vm.stepData,"status":2}})],1),_c('div',{staticClass:"form-box"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',_vm._b({staticClass:"tip",attrs:{"label":"公司信息"}},'a-form-item',_vm.formItemLayout,false)),_c('a-form-item',_vm._b({attrs:{"label":"公司名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyName',
            {
              rules: [
                {
                  required: true,
                  validator: this.companyNamecheck.bind(this)
                }
              ],
              initialValue: _vm.formData.merchantInfo.merchantName
            }
          ]),expression:"[\n            'companyName',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.companyNamecheck.bind(this)\n                }\n              ],\n              initialValue: formData.merchantInfo.merchantName\n            }\n          ]"}],attrs:{"placeholder":"请输入公司名称"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"统一社会信用代码"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyCode',
            {
              rules: [
                {
                  required: true,
                  validator: this.companyCodecheck.bind(this)
                }
              ],
              initialValue: _vm.formData.merchantInfo.merchantCode
            }
          ]),expression:"[\n            'companyCode',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.companyCodecheck.bind(this)\n                }\n              ],\n              initialValue: formData.merchantInfo.merchantCode\n            }\n          ]"}],attrs:{"placeholder":"请输入统一社会信用代码"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"公司所在地"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyaddress',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  message: '公司所在地不能为空'
                }
              ],
              initialValue: _vm.formData.merchantInfo.companyAddress
            }
          ]),expression:"[\n            'companyaddress',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  message: '公司所在地不能为空'\n                }\n              ],\n              initialValue: formData.merchantInfo.companyAddress\n            }\n          ]"}],staticStyle:{"width":"3.53rem"},attrs:{"options":_vm.options,"placeholder":"请选择公司所在地","loadData":_vm.loadData}})],1),_c('a-form-item',_vm._b({attrs:{"label":"公司详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyDetailAddress',
            {
              rules: [
                {
                  required: true,
                  message: '公司详细地址不能为空'
                }
              ],
              initialValue: _vm.formData.merchantInfo.addressDetail
            }
          ]),expression:"[\n            'companyDetailAddress',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '公司详细地址不能为空'\n                }\n              ],\n              initialValue: formData.merchantInfo.addressDetail\n            }\n          ]"}],attrs:{"placeholder":"请输入公司详细地址"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"公司电话"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'companyphone',
            {
              rules: [
                {
                  required: true,
                  validator: this.phoneCheck.bind(this)
                }
              ],
              initialValue: _vm.formData.merchantInfo.merchantTel
            }
          ]),expression:"[\n            'companyphone',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.phoneCheck.bind(this)\n                }\n              ],\n              initialValue: formData.merchantInfo.merchantTel\n            }\n          ]"}],attrs:{"placeholder":"请输入公司电话,如：010-12345678,13812345678"}})],1),_c('a-form-item',_vm._b({staticClass:"tip",attrs:{"label":"联系人及账户信息"}},'a-form-item',_vm.formItemLayout,false)),_c('a-form-item',_vm._b({attrs:{"label":"法定代表人姓名"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'farenName',
            {
              rules: [
                {
                  required: true,
                  message: '法定代表人姓名不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.lpName
            }
          ]),expression:"[\n            'farenName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '法定代表人姓名不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.lpName\n            }\n          ]"}],attrs:{"placeholder":"请输入法定代表人姓名"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"法定代表人身份证号"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'farenInfoCard',
            {
              rules: [
                {
                  required: true,
                  validator: this.infoCardCheck.bind(this)
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.lpIdNum
            }
          ]),expression:"[\n            'farenInfoCard',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.infoCardCheck.bind(this)\n                }\n              ],\n              initialValue: formData.contactAccountInfo.lpIdNum\n            }\n          ]"}],attrs:{"placeholder":"请输入法定代表人身份证号"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"法定代表人联系方式"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'farenphone',
            {
              rules: [
                {
                  required: true,
                  validator: this.telCheck.bind(this)
                },
                {}
              ],
              initialValue: _vm.formData.contactAccountInfo.lpTel
            }
          ]),expression:"[\n            'farenphone',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.telCheck.bind(this)\n                },\n                {}\n              ],\n              initialValue: formData.contactAccountInfo.lpTel\n            }\n          ]"}],attrs:{"placeholder":"请输入法定代表人联系方式"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"银行开户名"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bankKaiName',
            {
              rules: [
                {
                  required: true,
                  message: '银行开户名不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.accountName
            }
          ]),expression:"[\n            'bankKaiName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '银行开户名不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.accountName\n            }\n          ]"}],attrs:{"placeholder":"请输入银行开户名"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"银行开户账号"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bankZhanghao',
            {
              rules: [
                {
                  required: true,
                  message: '银行开户账号不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.accountNumber
            }
          ]),expression:"[\n            'bankZhanghao',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '银行开户账号不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.accountNumber\n            }\n          ]"}],attrs:{"placeholder":"请输入银行开户账号"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"开户银行名称"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bankName',
            {
              rules: [
                {
                  required: true,
                  message: '开户银行名称不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.bankName
            }
          ]),expression:"[\n            'bankName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '开户银行名称不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.bankName\n            }\n          ]"}],attrs:{"placeholder":"请输入开户银行名称"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"开户银行所在地"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'kaihuAddress',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  message: '开户银行所在地不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.accountAddress
            }
          ]),expression:"[\n            'kaihuAddress',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  message: '开户银行所在地不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.accountAddress\n            }\n          ]"}],staticStyle:{"width":"3.53rem"},attrs:{"options":_vm.options,"placeholder":"请选择开户银行所在地","loadData":_vm.loadData}})],1),_c('a-form-item',_vm._b({attrs:{"label":"开户银行详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'kaihuDeatilAddress',
            {
              rules: [
                {
                  required: true,
                  message: '开户银行详细地址不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.accountDetailAdd
            }
          ]),expression:"[\n            'kaihuDeatilAddress',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '开户银行详细地址不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.accountDetailAdd\n            }\n          ]"}],attrs:{"placeholder":"请输入开户银行详细地址"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"公司紧急联系人"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'harryName',
            {
              rules: [
                {
                  required: true,
                  message: '公司紧急联系人不能为空'
                }
              ],
              initialValue: _vm.formData.contactAccountInfo.emergencyContactName
            }
          ]),expression:"[\n            'harryName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '公司紧急联系人不能为空'\n                }\n              ],\n              initialValue: formData.contactAccountInfo.emergencyContactName\n            }\n          ]"}],attrs:{"placeholder":"请输入公司紧急联系人"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"公司紧急联系人手机"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'harryTel',
            {
              rules: [
                {
                  required: true,
                  validator: this.telCheck.bind(this)
                }
              ],
              initialValue:
                _vm.formData.contactAccountInfo.emergencyContactPhone
            }
          ]),expression:"[\n            'harryTel',\n            {\n              rules: [\n                {\n                  required: true,\n                  validator: this.telCheck.bind(this)\n                }\n              ],\n              initialValue:\n                formData.contactAccountInfo.emergencyContactPhone\n            }\n          ]"}],attrs:{"placeholder":"请输入公司紧急联系人手机"}})],1),_c('div',{staticClass:"imageUpload"},[_c('a-form-item',_vm._b({attrs:{"label":"法人身份证照片（正面）"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'zhengmianupload',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  validator: this.lpIdPicturePositiveFileCheck.bind(this)
                }
              ]
            }
          ]),expression:"[\n            'zhengmianupload',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  validator: this.lpIdPicturePositiveFileCheck.bind(this)\n                }\n              ]\n            }\n          ]"}],attrs:{"name":"file","customRequest":_vm.lpIdPicturePositive,"remove":_vm.lpIdPicturePositiveRemove,"file-list":_vm.lpIdPicturePositiveFileList,"list-type":"picture-card"},on:{"preview":_vm.handlePicturePreview}},[(_vm.lpIdPicturePositiveFileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"法人身份证照片（反面）"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'fanmianupload',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  validator: this.lpIdPictureNegativeFileCheck.bind(this)
                }
              ]
            }
          ]),expression:"[\n            'fanmianupload',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  validator: this.lpIdPictureNegativeFileCheck.bind(this)\n                }\n              ]\n            }\n          ]"}],attrs:{"name":"file","customRequest":_vm.lpIdPictureNegative,"remove":_vm.lpIdPictureNegativeRemove,"list-type":"picture-card","file-list":_vm.lpIdPictureNegativeFileList},on:{"preview":_vm.handlePicturePreview}},[(_vm.lpIdPictureNegativeFileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"营业执照照片"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'yinyephone',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  validator: this.businessLicensePictureFileCheck.bind(this)
                }
              ]
            }
          ]),expression:"[\n            'yinyephone',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  validator: this.businessLicensePictureFileCheck.bind(this)\n                }\n              ]\n            }\n          ]"}],attrs:{"name":"file","customRequest":_vm.businessLicensePicture,"remove":_vm.businessLicensePictureRemove,"list-type":"picture-card","file-list":_vm.businessLicensePictureFileList},on:{"preview":_vm.handlePicturePreview}},[(_vm.businessLicensePictureFileList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"开户许可证"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'openAccount',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  validator: this.accountOpeningPermitFileCheck.bind(this),
                } ],
            } ]),expression:"[\n            'openAccount',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  validator: this.accountOpeningPermitFileCheck.bind(this),\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"file","customRequest":_vm.accountOpeningPermit,"remove":_vm.accountOpeningPermitRemove,"file-list":_vm.accountOpeningPermitList,"list-type":"picture-card"},on:{"preview":_vm.handlePicturePreview}},[(_vm.accountOpeningPermitList.length < 1)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"经营许可证"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'imageUrl',
            {
              rules: [
                {
                  required: false,
                  validator: this.uploadCoverFileCheckOne.bind(this),
                } ],
            } ]),expression:"[\n            'imageUrl',\n            {\n              rules: [\n                {\n                  required: false,\n                  validator: this.uploadCoverFileCheckOne.bind(this),\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"file","customRequest":_vm.uploadAtlasAll,"remove":_vm.uploadAtlasRemove,"multiple":true,"list-type":"picture-card","file-list":_vm.uploadAtlasList},on:{"preview":_vm.handlePicturePreview}},[(_vm.uploadAtlasList.length < 3)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张 ")])],1),_c('a-form-item',_vm._b({attrs:{"label":"产品资质证书"}},'a-form-item',_vm.formItemLayout,false),[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'imageUrlTwo',
            {
              rules: [
                {
                  required: false,
                  validator: this.uploadCoverFileCheckTwo.bind(this),
                } ],
            } ]),expression:"[\n            'imageUrlTwo',\n            {\n              rules: [\n                {\n                  required: false,\n                  validator: this.uploadCoverFileCheckTwo.bind(this),\n                },\n              ],\n            },\n          ]"}],attrs:{"name":"file","customRequest":_vm.uploadAtlasAllTwo,"remove":_vm.uploadAtlasRemoveTwo,"multiple":true,"list-type":"picture-card","file-list":_vm.ProductQualificationList},on:{"preview":_vm.handlePicturePreview}},[(_vm.ProductQualificationList.length < 3)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1):_vm._e()]),_c('div',{staticClass:"fileTipBox20200506"},[_vm._v(" 文件大小在3M以内，支持png，jpeg，gif格式，最多可上传三张 ")])],1)],1),_c('a-form-item',_vm._b({staticClass:"present"},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"button1",on:{"click":_vm.previous}},[_c('img',{attrs:{"src":require("../../assets/images/homeImg/路径 2330@2x.png")}}),_vm._v("返回上一步")]),_c('a-button',{staticClass:"button2",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("下一步")])],1)],1)],1)]),_c('Footer'),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null,"width":'30vw'},on:{"cancel":_vm.handleCancel}},[_c('div',{staticStyle:{"margin-top":"0.2rem"}},[_c('img',{staticStyle:{"width":"100%","max-height":"5rem","margin-bottom":"0.3rem"},attrs:{"alt":"example","src":_vm.previewImage}})])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepTop"},[_c('img',{attrs:{"src":require("../../assets/images/homeImg/组 11@2x.png"),"height":"240","width":"3840"}}),_c('div',{staticClass:"rz-type"},[_vm._v("商家入驻")])])}]

export { render, staticRenderFns }





























































































































    import {Component, Vue} from "vue-property-decorator";
    import {Checkbox, Icon, Input, List, Tabs, Tooltip} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import TrashCanItem from "@/components/Personal/TrashCanItem.vue";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
  components: {
    PersonalHeader,
    TrashCanItem
  }
})
export default class extends Vue {
  pagination = {
    total: 3,
    showTotal: (total: number) => `共${total}条记录`,
    itemRender: (current: any, type: string, originalElement: any): any => {
      if (type === "prev") {
        return "< 上一页";
      } else if (type === "next") {
        return "下一页 >";
      }
      return originalElement;
    }
  };
  data = [
    {
      id: 1,
      status: 1,
      orderTime: "2019-11-20 175308",
      orderNum: "101643891418",
      storeName: "老年旗舰店",
      customer: "白桂金",
      totalPrice: 1065.01,
      payMode: "在线支付",
      remainingHour: 22,
      remainingMin: 8,
      content: "欧姆龙(OMRON)电子血压计 家用HEM-712(上臂式)",
      count: 3
    }
  ];
  onSearch() {}

  // 点击筛选订单状态
  handleScreenOrders(key: Boolean) {
  }
}
















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceItem from "../tripartite/commonServiceItem.vue";
import {Mutation, State} from "vuex-class";
import {getServicedropList, getServiceList, getServiceOrdersList, getServiceOrdersSearch} from "@/services/api";
import {baseUrl, request} from "@/services/http";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        PersonalHeader,
        ServiceItem
    }
})
export default class extends Vue {
    @Prop() public emptyTextBySearch: any;
    @Prop() public all: any;

    @Mutation SET_ALLORDERSTATUS;
    @Mutation SET_ALLSERVICESTATUS;
    @State allOrderStatus;
    @State allServiceStaus;
    cancelVisible = false;
    currentIndex = 0;
    statusKey = 0;
    searchContent = "";
    serviceStatus = {};
    data = [];
    OrdersStatus = [];
    serviceCurStatus: 0;
    serviceState=false;
    serviceStateTwo=false;
    pagination = {
        total: 0,
        pageSize: 5,
        pageIndex: 1
    };
    dropdownndasld = "服务方式";
    @Prop({
        type: Number,
        required: true,
        default: null
    })

    @Prop()
    public status!: number;
    // @Watch("status", { immediate: true, deep: true })
    // getStatus() {
    //   this.getList();
    // }
    loaded = false;

    mounted() {
        this.getAllServieStyleList()
        //   this.getList();
        this.loaded = true;

    }


    @Watch("allOrderStatus", {immediate: true, deep: true})
    getOrderStatus(newVal, oldVal) {
        newVal[0] = "订单状态";
        this.OrdersStatus = newVal;
    }

    @Watch("allServiceStaus", {immediate: true, deep: true})
    getServiceStatus(newVal, oldVal) {
        newVal[0] = "服务状态";
        this.serviceStatus = newVal;
    }

    @Prop({
        type: String,
        required: true,
        default: null
    })
    public searchItem!: null;

    //监听搜索框
    @Watch("searchItem", {immediate: true, deep: true})
    getSearchItem(newVal, oldVal) {
        if (newVal != "" || this.loaded) {
            this.pagination.pageIndex = 1;
            this.getSearch(newVal);
            this.currentIndex = 3;
        }
    }

    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        switch (this.currentIndex) {
            case 0:
                this.getList();
                break;
            case 1:
                this.getServicedropList(this.statusKey);
                break;
            case 2:
                this.getServiceList(this.serviceCurStatus);
                break;
            case 3:
                this.getSearch(this.searchItem);
        }
    }

    allServieStyleList = [];

    getAllServieStyleList() {
        request(`${baseUrl}/api/v1/dicList/service-style`).then((res: any) => {
            if (res["code"] == 0) {

                this.allServieStyleList = res["data"];
            }
        });
    }

    //查询服务订单列表
    getList() {
        getServiceOrdersList({
            ...this.pagination,
            status: this.status === 0 ? null : this.status
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = 0;
            this.data = [];
            // this.pagination.total = data.totalCount;
            // this.data = data.serviceOrderInfos;
            // for (let item of this.data) {
            //     for (let val of item["services"] as any) {
            //         val["contactPerson"] = item["ifShop"]
            //             ? item["contactPersonDetail"]["shopName"]
            //             : item["contactPersonDetail"]["receiverName"];
            //
            //     }
            //     (item["services"] as any).forEach((item) => {
            //         if (item.skuInfo != null) {
            //             //服务规格列表拆开
            //             item.skuInfoList.forEach(el => {
            //                 const mapItem = el.skuValue
            //                 let cc = typeof (mapItem);
            //                 if (cc === 'string') {
            //                     let str = mapItem.replaceAll('[', '').replaceAll(']', '');
            //                     let itemArr = str.split(', ');
            //                     el.skuValue = itemArr;
            //                 }
            //             })
            //             //当前服务规格拆分
            //             let str = item.skuInfo.replaceAll('', '');
            //             let itemArrOne = str.split(' ');
            //             item.skuInfo = itemArrOne;
            //         }
            //     });
            // }
            this.pagination.pageSize = data.pageSize;
        });
    }

    getSearch(searchInput: any) {
        getServiceOrdersSearch({
            ...this.pagination,
            searchInput: searchInput
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.serviceOrderInfos;
            this.pagination.pageSize = data.pageSize;
        });
    }

    getServicedropList(status: any) {
        if (status === null) status = "";
        getServicedropList({
            ...this.pagination,
            status: status
        }).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.data = data.serviceOrderInfos;
            this.pagination.pageSize = data.pageSize;
        });
    }

    getServiceListCondition = {serviceStyle: null, serviceStatus: null};

    getServiceList(status: any, type = "state") {
        const obj: any = {
            ...this.pagination,
            queryStatus: this.status,
            ...this.getServiceListCondition
        };
        if (!obj.serviceStyle) delete obj.serviceStyle;
        if (!obj.serviceStatus) delete obj.serviceStatus;
        getServiceList(obj).then(res => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            this.pagination.pageSize = data.pageSize;
            let that: any = this;
            that.data = [];
            data.returnInfos.forEach((el, i) => {
                let newObj: Object = [
                    {
                        contactPerson: el.ifShop
                            ? el.contactPersonDetail.shopName
                            : el.contactPersonDetail.receiverName,
                        ifRedund: el.ifRedund,
                        price: el.price,
                        quantity: el.quantity,
                        refundId: el.refundId,
                        refundStatus: el.refundStatus,
                        refundStatusDic: el.refundStatusDic,
                        relationId: el.servicRelationId,
                        serviceNum: el.serviceNum,
                        serviceFee: el.serviceFee,
                        serviceId: el.serviceId,
                        serviceName: el.serviceName,
                        servicePic: el.servicePicture,
                        serviceStatus: el.serviceStatus,
                        serviceStyle: el.serviceStyle,
                        serviceType: el.serviceType,
                        servicesStatusDic: el.servicesStatusDic
                    }
                ];
                // el["services"] = newObj;
                Vue.set(el, "services", newObj);
                Vue.set(el, "orderCode", el.serviceOrderSeriesNum);
                Vue.set(el, "totalAmount", el.price);
                Vue.set(el, "serviceOrderInfoId", el.servicOrderId);
                (this as any).data.push(el);
            });
        });
    }

    dhabjshsacj = "订单状态";
    visibleChange(key: string) {
        this.serviceStateTwo = false;
        this.emptyTextBySearch = false;
        this.$emit("changeKey");
        this.currentIndex = 1;
        this.statusKey = +key.split("$")[0];
        this.dhabjshsacj = key.split("$")[1];
        this.pagination.pageIndex = 1;
        if(key==="0$订单状态"){
            this.getServicedropList( '');
        }else{
            this.getServicedropList(key.split("$")[0]);
        }
    }

    dasjdads = "服务状态";

    //服务状态
    serviceStatusChange(type, key: any) {
        this.serviceState = false;
        this.emptyTextBySearch = false;
        this.currentIndex = 2;
        this.pagination.pageIndex = 1;
        this.serviceCurStatus = key;

        this.getServiceListCondition["serviceStatus"] = key.split("$5$")[0] == "0" ? null : key.split("$5$")[0];
        this.dasjdads = key.split("$5$")[1];

        this.getServiceList(key, type);
    }

    setCancelModal(flag: boolean) {
        this.cancelVisible = flag;
    }

    handleCancelOrder() {
    }

    againGetList() {
        this.pagination.pageIndex = 1;
        this.getList();
    }
}

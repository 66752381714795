





























import Header from "./../../components/Header.vue";
import Footer from "./../../components/Footer.vue";
import {Component, Vue} from "vue-property-decorator";
import { serviceProviderUrl, MerchantUrl,facilitatorUrl } from "@/services/http";
@Component({
    components: {
        Header,
        Footer
    }
})
export default class Home extends Vue {
    orgAdminUrl = serviceProviderUrl;
    facilitatoAdminUrl = facilitatorUrl;
    meradminUrl = MerchantUrl;
    institution(value){
        switch(value){
            case '1':
                window.open(this.orgAdminUrl,"_blank");
                break;
            case '2':
                window.open(this.meradminUrl,"_blank");
                break;
            default:
                window.open(this.facilitatoAdminUrl,"_blank");
        }
    }
    jumpToHome() {
        this.$router.push("/");
    }
}

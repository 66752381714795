import { render, staticRenderFns } from "./RightOrderDetail.vue?vue&type=template&id=699d13a4&scoped=true&"
import script from "./RightOrderDetail.vue?vue&type=script&lang=ts&"
export * from "./RightOrderDetail.vue?vue&type=script&lang=ts&"
import style0 from "./RightOrderDetail.vue?vue&type=style&index=0&id=699d13a4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699d13a4",
  null
  
)

export default component.exports
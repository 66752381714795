














































import {Component, Vue} from "vue-property-decorator";

@Component({
    props: {
        item: Object,
        // img: String,
        // title: String,
        // price: Number,
        // orgId: String,
        // categoryId: String,
    },
})
export default class SubServiceItem extends Vue {
    serveCancel = false;
    price: any = 0;


    toDetail(val) {
        let arr: any = [];
        var min;
        val.forEach(item => {
            arr.push(item.servicePrice)
        })
        min = Math.min.apply(null, arr);
        this.price = min
        this.serveCancel = true
    }

    examine(serviceInfoId) {
        this.$router.push({
            path: "/cookInHome/" + serviceInfoId
        });
    }
}

import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex/index";
import {Form} from "ant-design-vue";


// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
// Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
// import Astrict from './vuex/astrict'  //设置长时间登录退出
// Vue.use(Astrict)
// import "ant-design-vue/dist/antd.css";
import "./style.less";

import protoWebSocket from "@/services/websocket-api";

Vue.use(protoWebSocket);

Vue.config.productionTip = false;
Vue.prototype.$form = Form;
Vue.filter("formatMoney", function(value: string) {
  if (!value) return "0.00";
  return (+value).toFixed(2);
});
// (window as any).Base64 = Base64;
(window as any).$vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


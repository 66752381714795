
  import {Component, Vue} from "vue-property-decorator";
  import {baseUrl, request} from "@/services/http";

  @Component
export default class extends Vue {
  parm: any;
  address:any="";
  receiverName="";
  phoneNumber="";
  searchKeywords = "";
  created() {
    this.parm = this.$route.query;
    request(`${baseUrl}/api/v1/services/pay/info`, {
      params: {
        mainServiceOrderCode: this.parm.mainServiceOrderCode,
        ifCommodityOrder: true,
      },
    }).then((res:any) => {
      if (res.code == 0) {
        this.address = res.data.receiverProvince + res.data.receiverCity + res.data.receiverArea + res.data.receiverStreet + res.data.receiverDetailAddress;
        this.receiverName = res.data.receiverName;
        this.phoneNumber = res.data.phoneNumber;
      }
    });

  }
  jumpToHome() {}
  lookDetail() {
    open("/#/personal/MyGoods", "_self");
  }
  //头部搜索
  jumpRuter(keywords?: string) {
    keywords = keywords || this.searchKeywords;
    open(`/#/mall/search?keywords=${encodeURI(keywords)}`, "_self");
  }
}

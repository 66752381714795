





















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {http} from "@/services/http";
import {message} from "ant-design-vue";

@Component({
    props: {}
})
export default class mallDetailHeader extends Vue {
    @Prop() commodityInfoDto: any;
    @Prop() merchantInfo!: any;
    @Prop() skuInfoList: any;
    @Prop() skuSettingVos: any;
    @Prop() detailPics: any;
    batchInto = false;
    shoppingTrolley = false;
    select = {};


    @Watch("skuInfoList", {immediate: true, deep: true})
    getSkuInfoList(val) {
        this.skuSettingVos.forEach((element) => {
            if (element.skuValues.length === 1) {
                element.select = 0;
                Object.assign(this.select, {[element.skuName]: element.skuValue[0]});
            }
        });

        if (this.skuSettingVos.length) {
            let arr = this.skuInfoList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.siInfo.includes(val));
            });
            // this.skuChange(arr[0]);
        } else this.$nextTick().then((r) => this.skuChange(this.skuInfoList[0]));
    }


    @Watch("commodityInfoDto") getcommodityInfoDto(val) {
    }

    @Watch("detailPics", {immediate: true, deep: true})
    getDetailPics(newVal, oldVal) {
        this.totalPage = Math.ceil(newVal.length / 4);
        if (this.totalPage == 1) this.leftCan = this.rightCan = false;
    }

    mount: number = 1;

    ulLeft = 0.1;
    leftCan = true;
    rightCan = false;
    totalPage = 1;
    page = 1;
    stockNum = 0;
    selecy = 0;
    flag = -1;
    mainImageUrl = "";
    serviceSpecificationType: any = false;
    isCollectionsOrgService: any;

    selecyChange(url, i) {
        this.selecy = i;
        this.mainImageUrl = url;
    }

    mounted() {
        this.getCollectState();
        this.setMain();
    }

    setMain() {
        if (!this.detailPics[0].pictureAddress) {
            const t = setTimeout(() => {
                this.setMain();
                clearTimeout(t);
            }, 1000);
            return;
        }
        this.selecyChange(this.detailPics[0].pictureAddress || "", 0);
    }

    CollectionGoods() {
        open('/#/personal/myCollection?type=2')
    }

    leftTo() {
        if (this.page < this.totalPage) {
            this.ulLeft -= 3.8;
            this.page++;
            this.leftCan = this.page != this.totalPage;
            this.rightCan = this.page != 1;
        }
    }

    rightTo() {
        if (this.page > 1) {
            this.ulLeft += 3.8;
            this.page--;
            this.rightCan = this.page != 1;
            this.leftCan = this.page != this.totalPage;
        }
    }

    mountPlus() {
        if (!this.commodityInfoDto.isLimit) {
            if (this.mount < this.stockNum) {
                this.mount++;
            } else {
                this.$message.warning(`库存不足！`);
            }
        } else {
            if (this.mount < this.commodityInfoDto.purchaseLimit) {
                this.mount++;
            } else {
                this.$message.warning(
                    `当前商品每位用户最多购买${this.commodityInfoDto.purchaseLimit}件`
                );
            }
        }
    }

    siId: any = '';
    //选择服务
    skuChangeKey(item, i, val) {
        if (i != this.flag) {
            this.flag = i;
            val.select = i;
            Object.assign(this.select, {[val.typeName]: item.trim()});
            let arr = this.skuInfoList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.siInfo.split(' ').includes(val));
            });
            this.skuChange1(arr[0]);
            if (arr.length === 1) {
                this.skuChange(arr[0]);
            }
        } else {
            this.flag = -1;
            val.select = -1;
            Object.assign(this.select, {[val.typeName]: item.trim()});
            let arr = this.skuInfoList;
            Object.values(this.select).forEach((val) => {
                arr = arr.filter((item) => item.siInfo.split(' ').includes(val));
            });
            this.siId = ''
            this.$forceUpdate()
        }

    }

    skuChange(item) {
        if (item) {
            this.commodityInfoDto.suggestPrice = item.suggestPrice;
            this.stockNum = -1;
            this.stockNum = item.stockNum;
            this.siId = item.siId;
        }
        this.$forceUpdate()
        if (this.siId != '') {
            this.serviceSpecificationType = false;
        }
    }
    //选中一个
    skuChange1(item) {
        if (item) {
            this.siId = '';
        }
        this.$forceUpdate()
    }
    //加入购物车
    addToCart() {
        const userInfo = localStorage.getItem("userInfo");
        if (!userInfo) {
            this.saveCartToSession();
            return;
        }
        if (this.skuInfoList.length === 0) {
            this.siId = ''
        }
        if (this.siId === '') {
            this.serviceSpecificationType = true;
            return;

        } else {
            this.serviceSpecificationType = false;
            http.post("/api/v1/cart/add", {siId: this.siId, quantity: this.mount}).then((res:any) => {
                if (res.code == 0) {
                    // message.success("已添加至购物车！");
                    this.shoppingTrolley = true
                    this.getCartNumber();
                } else message.error(res.msg);
            }).catch((error) => {
                message.error(error.response.data.msg);
            });
        }

    }

    //立即购买
    buyNow() {
        if (this.skuInfoList.length === 0) {
            this.siId = ''
        }
        if (this.siId === '') {
            this.serviceSpecificationType = true;
            return;

        } else {
            this.serviceSpecificationType = false;
            this.$emit("buyNow", {
                quantity: this.mount,
                ...this.skuInfoList.find((item) => item.siId == this.siId),
            });
        }

    }

    saveCartToSession() {
        open("/#/login?byMall=" + this.$route.fullPath, "_self");
    }

    getCartNumber() {
        http.get(`/api/v1/cart/count`).then(({code, data}: any) => {
            if (code == 0) this.$store.commit("setProducNumInCart", data.allCount);
            else this.$store.commit("setProducNumInCart", 0);
        });
    }

    collectState = false;

    getCollectState() {
        if (localStorage.getItem("userInfo"))
            http.get(`/api/v1/collections/shop-commodity/status`, {
                params: {id: this.$route.params.id, type: "235"},
            })
                .then((res:any) => {
                    if (res.code == 0) this.collectState = res.data;
                });
    }

    //收藏、取消收藏
    collecEvent() {
        if (localStorage.getItem("userInfo"))
            http.post(`/api/v1/collections/shop-commodity`, {
                id: this.$route.params.id,
                type: "235",
            })
                .then((res:any) => {
                    if (res.code == 0) {
                        // message.success("收藏成功");
                        if (!this.collectState) {
                            this.batchInto = true;
                        } else {
                            this.batchInto = true;
                        }

                        this.getCollectState();

                    } else {
                        message.error("收藏失败");
                    }
                });
        else {
            open("/#/login", "_self");
        }
    }
}

































    import {Component, Vue} from "vue-property-decorator";
    import {Input, message, Modal, Table, Tabs} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ApplicationServiceRefund from "@/components/Personal/ApplicationServiceRefund.vue";
    import aRefundRecord from "@/components/Personal/aRefundRecord.vue";
    import {deleteReturnRecord, fetchApplyForReturn, fetchReturnRecord,} from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Modal);

@Component({
  components: {
    PersonalHeader,
    ApplicationServiceRefund,
    aRefundRecord
  },
})
export default class extends Vue {
  // 取消订单Modal显隐
  cancelVisible = false;
  curType = "全部";
  applyReturnValue: any = ""; // 申请服务退款输入框value
  returnRecordValue: any = ""; // 退款记录输入框value
  topLis = [

    {
      title: "服务详情",
      width: "5.5rem"

    },
    // {
    //     title: "服务订单状态",
    //     width: "2.5rem"
    // },
    {
      title: "下单时间",
      width: "3.5rem"
    },
    {
      title: "操作",
      width: "3.5rem"
    }
  ];
  pagination = {
    total: 0,
    pageSize: 10,
    pageIndex: 1,
    queryStatus: 2
  };
  pagination2 = {
    total: 0,
    pageSize: 10,
    pageIndex: 1,
    queryStatus: 2
  };
  topLis2 = [
    {
      title: "服务详情",
      width: "4.2rem"

    },
    {
      title: "退款金额",
      width: "1.6rem"
    },
    // {
    //     title: "退款类型",
    //     width: "1.5rem"
    // },
    {
      title: "申请时间",
      width: "1.81rem"
    },
    {
      title: "退款状态",
      width: "2.03rem"
    },
    {
      title: "操作",
      width: "2.31rem"
    }
  ];
  data: Array<any> = [];
  data2: Array<any> = [];
  emptyTextBySearch = false
  private msg: string = "MyGoods页面！";

  async created() {
    await this.getApplyReturn();
    await this.getReturnRecord();
  }

  // 点击查看退款记录
  handleRecordCheck(value: any) {
    if (value.statusDic == 203 || value.statusDic == 435) {
      let {href2}:any = this.$router.push({
        path: "/personal/returnService2",
        query: {
          params: value,
          returnId: value.other.returnId,
        },
      });
      window.open(href2, "_blank");
    } else {
      let {href} = this.$router.resolve({
        path: "/personal/returnService3",
        query: {
          params: value,
          returnId: value.other.returnId,
        },
      });
      window.open(href, "_blank");
    }
  }

  // 点击删除退款记录
  handleRecordDelete(value: any) {
    const that = this;
    Modal.confirm({
      title: "删除退款记录",
      width: '5rem',
      okText: '确认',
      cancelText: '取消',
      content: "删除退款记录后不可恢复，您确定要删除吗？",
      onOk() {
        deleteReturnRecord(value.other).then((res: any) => {
          if (res && res.code === 0) {
            // 处理数据
            message.success("删除成功");
            that.getReturnRecord();
          } else {
            message.error(res.msg);
          }
        });
      },
      onCancel() {
      },
    });
  }

  // 获取申请退款列表
  getApplyReturn() {
    fetchApplyForReturn({
      ...this.pagination2,
      searchInput: this.applyReturnValue,
    }).then((res: any) => {
      if (res && res.code === 0) {
        this.pagination2.total = res.data.totalCount;
        this.pagination2.pageSize = res.data.pageSize;
        // 处理数据
        this.data = [];
        this.data = res.data.returnInfos
      } else {
        message.error(res.msg);
      }
    });
  }

  // 获取退款记录列表
  getReturnRecord() {
    fetchReturnRecord({
      ...this.pagination,
      searchInput: this.returnRecordValue,
    }).then((res: any) => {
      if (res && res.code === 0) {
        // 处理数据
        this.data2 = [];
        this.pagination.total = res.data.totalCount;
        this.pagination.pageSize = res.data.pageSize;
        res.data.returnInfos.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.serviceRefundNum = item.serviceRefundNum;
          obj.orderSeriesNum = item.orderSeriesNum;
          obj.serviceName = item.serviceName;
          obj.creatTime = item.creatTime;
          obj.refundDetail = item.refundDetail;
          obj.statusDic = item.statusDic;
          obj.serviceStyle = item.refundType;
          obj.serviceId = item.serviceId;
          obj.serviceLogo = item.serviceLogo;
          obj.agencyName = item.agencyName;

          obj.other = item;
          this.data2.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }

  // 点击进入申请退款
  handleApplyReturn(value: any) {
    const {href} = this.$router.resolve({
      path: "/personal/returnService1",
      query: {
        relationId: value.servicRelationId || value.other.relationId
      },
    });
    window.open(href, "_blank");
  }

  // 点击搜索查询申请服务退款
  onSearchApplyReturn(value: any) {
    this.getApplyReturn();
    this.emptyTextBySearch = true

  }

  // 点击搜索查询退换记录
  onSearchReturnRecord(value: any) {
    this.getReturnRecord();
    this.emptyTextBySearch = true
  }

}

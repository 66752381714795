<template>
    <div class="good-judge-body">
        <div class="judge-detail-top">
            <Breadcrumb :breadcrumb="breadcrumb"/>
        </div>
        <div class="comment-top">
            <div class="top-bg">
                <div class="bg-left" @click="jumpService">
                    <img :src="orgInfo.merchantLogo"/>
                </div>
                <div class="bg-right">
          <span class="bg-right-header-title" @click="jumpService">{{
            orgInfo.merchantName
          }}</span>
                    <div class="bg-right-header-rate">
                        <div>综合</div>
                        <div>商品</div>
                        <div>态度</div>
                        <div>物流</div>
                    </div>
                    <div class="bg-right-header-item">
                        <div>{{ parseFloat(orgInfo.merOveralScore).toFixed(2) || 0 }}</div>
                        <div>
                            {{ parseFloat(orgInfo.merCommodityCompliance).toFixed(2) || 0 }}
                        </div>
                        <div>{{ parseFloat(orgInfo.merAttitude).toFixed(2) || 0 }}</div>
                        <div>
                            {{ parseFloat(orgInfo.merDeliverySpeed).toFixed(2) || 0 }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="comment-merchant">
                <span>商品符合度</span>
                <a-rate
                        :disabled="commentId"
                        style="font-size:0.18rem;margin-right: 0.1rem"
                        v-model="orgInfo.commodityCompliance"
                />
                <span
                >{{
            orgInfo.commodityCompliance
              ? parseInt(orgInfo.commodityCompliance).toFixed(1)
              : 0
          }}分</span
                >
            </div>
            <div class="comment-merchant">
                <span>服务态度</span>
                <a-rate
                        :disabled="commentId"
                        style="font-size:0.18rem;margin-right: 0.1rem"
                        v-model="orgInfo.merchantAttitude"
                />
                <span
                >{{
            orgInfo.merchantAttitude
              ? parseInt(orgInfo.merchantAttitude).toFixed(1)
              : 0
          }}分</span
                >
            </div>
            <div class="comment-merchant">
                <span>物流服务</span>
                <a-rate
                        :disabled="commentId"
                        style="font-size:0.18rem;margin-right: 0.1rem"
                        v-model="orgInfo.deliverySpeed"
                />
                <span
                >{{
            orgInfo.deliverySpeed
              ? parseInt(orgInfo.deliverySpeed).toFixed(1)
              : 0
          }}分</span
                >
            </div>
        </div>

        <div
                class="comment-center"
                v-for="(item, index) of orgInfo.particulars"
                :key="item.relationId"
        >
            <div class="comment-center-left">
                <div class="rate-body-comment">
                    <div class="good-img-comment">
                        <img :src="item.pic"/>
                    </div>
                    <span class="good-name-comment">{{
            item.commodityName || "--"
          }}</span>
                    <span class="good-price-comment"
                    >￥{{ item.price || "--" | formatMoney }}</span
                    >
                </div>
            </div>
            <div class="comment-center-right">
                <div class="judge-form" v-if="commentId">
                    <div class="rate-item" style="margin-top: 0.5rem">
                        <span class="pin">评价时间</span>
                        <div class="exhibition">{{ orgInfo.createTime }}</div>
                    </div>
                </div>
                <div class="judge-form">
                    <div
                            class="rate-item"
                            :style="{
              alignItems: 'center',
              marginBottom: '0.21rem',
              marginTop: commentId ? 'auto' : '0.5rem'
            }"
                    >
                        <span class="pin">商品评分</span>
                        <a-rate
                                style="font-size:0.18rem"
                                :defaultValue="0"
                                :disabled="commentId"
                                v-model="item.commentStar"
                        />
                        <span class="num"
                        >{{
                item.commentStar ? parseInt(item.commentStar).toFixed(1) : 0
              }}分</span
                        >
                    </div>
                    <div class="Tiptip">{{ item.commentStarTip }}</div>

                    <div class="rate-item" :style="{ marginBottom: '0.1rem' }">
                        <span class="pin">商品评价</span>
                        <a-textarea
                                v-if="commentId != true"
                                placeholder="快把您的感受分享给大家吧"
                                v-model="item.commentContent"
                                :style="{
                width: '6rem',
                fontSize: '0.14rem',
                resize: 'none',
                height: '1.4rem'
              }"
                        ></a-textarea>
                        <div v-else class="exhibition">
                            {{ orgInfo.commentContent }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="reviewSubmission">
            <div class="button" v-if="!commentId">
                <div class="btn" v-if="enableClick" @click="handleSubmitJudge()">
                    <img src="../../../../assets/images/homeImg/路径 2314@2x.png"/>
                    发表
                </div>
                <div class="btn" v-else>
                    <img src="../../../../assets/images/homeImg/路径 2314@2x.png"/>
                    发表
                </div>
                <div>
                    <a-checkbox
                            :style="{ fontSize: '0.14rem' }"
                            v-model="orgInfo.anonymity"
                    >匿名
                    </a-checkbox
                    >
                </div>
            </div>
        </div>
        <a-modal
                width="6.3rem"
                :visible="judgeModal"
                @cancel="() => (this.judgeModal = false)"
        >
            <template slot="title">
                <div class="modal-header">
                    <span>评价成功</span>
                </div>
            </template>
            <div class="modal-center">
                <p class="confirm-single">评价已完成，以下商品未评价快去评价吧！</p>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <div
                            class="ok-button"
                            @click="() => $router.push('/personal/MyGoods')"
                    >
            <span
            ><img
                    src="../../../../assets/images/homeImg/yes.png"
            />继续评价</span
            >
                    </div>
                    <!--          <div class="cancel-button" @click="closeModal">-->
                    <!--            <span><img src="../../../../assets/images/homeImg/no.png"/>返回我的商品</span>-->
                    <!--          </div>-->
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import {Button, Checkbox, Icon, Input, message, Modal, Rate} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {commentOnCommodity, evaluationDetails, evaluationShow} from "@/services/api";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";

    Vue.use(Icon);
    Vue.use(Rate);
    Vue.use(Input);
    Vue.use(Checkbox);
    Vue.use(Modal);
    Vue.use(message);
    Vue.use(Button);

    @Component({
        components: {
            PersonalHeader,
            Breadcrumb
        }
    })
    export default class JudgeService extends Vue {
        orgInfo = {};
        merchantId = "";
        orderId = "";
        relationId = "";
        creationTime = "";
        commentId = true;
        judgeModal = false;
        // 防重复提交
        enableClick = true;
        //面包屑
        breadcrumb = [
            {
                name: "个人中心",
                path: "/personal/myHomePage",
                significance: 0
            },
            {
                name: "我的商品",
                path: "/personal/MyGoods",
                significance: 0
            },
            {
                name: "商品订单评价",
                path: "/personal/goodsJudgeService",
                significance: 1
            }
        ];

        mounted() {
            let commentId = this.$route.query.commentId;
            let orderId = this.$route.query.orderId;
            if (commentId) {
                this.commentId = true;
                this.getContentBycommentId(commentId);
            }
            if (orderId) {
                this.commentId = false;
                this.commentCommodity(orderId);
            }
        }

        //从商品订单查看详情过来 - 获取商品评价订单页面数据
        getContentBycommentId(commentId) {
            evaluationDetails({
                commodityCommentId: commentId
            }).then(({data, code}) => {
                this.orgInfo = {
                    commodityOrderId: data.seriesNum,
                    createTime: data.createTime,
                    merchantName: data.merchantName,
                    merchantLogo: data.merchantLogo,
                    merOveralScore: data.merOveralScore.toFixed(1),
                    merCommodityCompliance: data.merCommodityCompliance,
                    merAttitude: data.merAttitude,
                    merDeliverySpeed: data.merDeliverySpeed,
                    commodityCompliance: data.commodityCompliance,
                    merchantAttitude: data.merchantAttitude,
                    deliverySpeed: data.deliverySpeed,
                    deliveryAttitude: data.deliveryAttitude,
                    commentStar: data.commentStar,
                    commentContent: data.commentContent,
                    particulars: [
                        {
                            commodityName: data.commodityName,
                            commentStar: data.commentStar,
                            pic: data.pic,
                            price: data.price
                        }
                    ]
                };
                this.merchantId = data.merchantId;
                this.creationTime = "商品评价时间:";
            });
        }

        //点击店铺名称跳转店铺
        jumpService() {
            if (this.merchantId) open("/mall/shop/" + this.merchantId);
        }

        //继续评价
        closeModal() {
            this.judgeModal = false;
            this.$router.push("/personal/MyGoods");
        }

        //进行商品评价时展示的店铺和商品信息
        commentCommodity(value) {
            evaluationShow({
                orderId: value
            }).then(({data, code}) => {
                this.orgInfo = {
                    merchantName: data.merchantName,
                    merchantLogo: data.merchantLogo,
                    commodityOrderId: data.seriesNum,
                    createTime: data.orderCreatTime,
                    merOveralScore: data.merOveralScore,
                    merCommodityCompliance: data.commodityCompliance,
                    merAttitude: data.merchantAttitude,
                    merDeliverySpeed: data.deliverySpeed,
                    particulars: data.commodityList
                };
                for (var i = 0; i < data.commodityList.length; i++) {
                    this.relationId = data.commodityList[i].relationId;
                }
                this.orderId = data.orderId;
                this.creationTime = "订单创建时间:";
            });
        }

        //发表评论
        handleSubmitJudge() {
            const that = this
            const commentsList = [];

            // 数据校验
            if (!that.orgInfo.commodityCompliance) {
              message.error("商品符合度评分不能为空");
              return
            }
            if (!that.orgInfo.merchantAttitude) {
              message.error("服务态度评分不能为空");
              return
            }
            if (!that.orgInfo.deliverySpeed) {
              message.error("物流服务评分不能为空");
              return
            }

            that.enableClick = false
            that.orgInfo.particulars.forEach(item => {
                var obj = {
                    relationId: item.relationId,
                    commodityScore: item.commentStar,
                    content: item.commentContent
                };
                commentsList.push(obj);
            });
            const data = {
                orderId: that.orderId,
                commodityCompliance: that.orgInfo.commodityCompliance,
                serviceAttitude: that.orgInfo.merchantAttitude,
                deliverySpeed: that.orgInfo.deliverySpeed,
                anonymity: that.orgInfo.anonymity ? 1 : 0,
                comments: commentsList
            };

            commentOnCommodity(data).then(res => {
                if (res.code === 0) {
                    that.judgeModal = true;
                    message.success("商品评价成功");
                } else {
                    message.error(res.msg);
                }
            }).then(_ => {
              that.enableClick = true
            });
        }
    }
</script>

<style lang="less" scoped>
    .Tiptip {
        padding-left: 14.7%;
        color: #f00;
        margin-top: -0.1rem;
        margin-bottom: 0.1rem;
        font-size: 0.14rem;
    }

    .good-judge-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        background-color: #f2f2f2;

        .judge-detail-top {
            margin: 0.2rem 0.3rem;
            display: flex;
            align-items: center;
        }

        .comment-top {
            height: 1.16rem;
            width: 100%;
            background: #ffffff;
            opacity: 1;
            border-radius: 0.12rem;
            display: flex;

            .top-bg {
                width: 3.11rem;
                height: 100%;
                background-image: url("./../../../../assets/images/homeImg/组 1188@2x.png");
                background-size: cover;
                background-position: center center;
                display: flex;

                .bg-left {
                    cursor: pointer;
                    width: 0.68rem;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    img {
                        width: 0.47rem;
                        height: 0.48rem;
                        border-radius: 0.05rem;
                    }
                }

                .bg-right {
                    width: 2.43rem;
                    height: 100%;
                    text-align: center;

                    .bg-right-header-title {
                        height: 0.25rem;
                        cursor: pointer;
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #ffffff;
                        opacity: 1;
                        display: block;
                        margin-top: 0.2rem;
                        margin-bottom: 0.1rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        padding-left: 0.1rem;
                        padding-right: 0.1rem;
                    }

                    .bg-right-header-rate {
                        width: 1.8rem;
                        height: 0.19rem;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        div {
                            font-size: 0.14rem;
                            color: #ffffff;
                            opacity: 1;
                        }
                    }

                    .bg-right-header-item {
                        width: 2.02rem;
                        height: 0.25rem;
                        margin: 0 auto;
                        margin-top: 0.06rem;
                        border-radius: 0.12rem;
                        background: #ffffff;
                        opacity: 1;
                        display: flex;
                        align-items: center;

                        div {
                            min-width: 0.3rem;
                            font-size: 0.12rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ff7700;
                            opacity: 1;
                            margin: 0.14rem 0.1rem;
                        }
                    }
                }
            }

            .comment-merchant {
                padding-left: 0.3rem;
                display: flex;
                align-items: center;
                /*border: 1px solid red;*/

                span {
                    font-size: 0.14rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #363c51;
                    opacity: 1;
                    margin-right: 0.2rem;
                }
            }
        }

        .comment-center {
            width: 100%;
            height: 4rem;
            background: #ffffff;
            opacity: 1;
            border-radius: 0.12rem;
            margin-top: 0.3rem;
            display: flex;
            /*border: 1px solid red;*/

            .comment-center-left {
                width: 2.75rem;
                height: 100%;
                /*border: 1px solid red;*/

                .rate-body-comment {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .good-img-comment {
                        background-color: #dcdcdc;
                        margin-top: 0.6rem;
                        border-radius: 0.1rem;

                        img {
                            width: 205px;
                            height: 180px;
                            border-radius: 0.1rem;
                        }
                    }

                    .good-name-comment {
                        width: 1.88rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        font-size: 0.16rem;
                        font-weight: 400;
                        color: #454f63;
                        opacity: 1;
                        margin-top: 0.1rem;
                        text-align: center;
                    }

                    .good-price-comment {
                        font-size: 0.2rem;
                        color: #ff1010;
                        opacity: 1;
                        margin-top: 0.1rem;
                    }

                    .good-specification {
                        font-size: 0.12rem;
                        font-weight: 400;
                        color: #78849e;
                        opacity: 1;
                        margin-top: 0.1rem;
                    }
                }
            }

            .comment-center-right {
                width: 9.4rem;
                height: 100%;
                /*border: 1px solid red;*/

                .judge-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 0 0.93rem;
                    /*border: 1px solid yellow;*/
                    margin-top: 0.5rem;

                    .rate-item {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-bottom: 0.21rem;
                        /*border: 1px solid red;*/

                        .exhibition {
                            font-size: 0.14rem;
                            width: 6rem;
                            resize: none;
                            display: inline-block;
                        }

                        .pin {
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #363c51;
                            opacity: 1;
                            display: inline;
                            width: 0.9rem;
                            margin-right: 0.2rem;
                            text-align: right;
                        }

                        .num {
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #363c51;
                            opacity: 1;
                            margin-left: 0.1rem;
                        }
                    }
                }
            }
        }

        .reviewSubmission {
            width: 100%;
            height: 1rem;
            background-color: #fff;
            margin-top: -0.2rem;
            border-radius: 0 0 0.12rem 0.12rem;
            /*border: 1px solid red;*/

            .button {
                width: 100%;
                height: 0.45rem;
                display: flex;
                justify-content: center;
                align-items: center;
                /*margin-top: 0.4rem;*/

                .btn {
                    width: 1.03rem;
                    height: 0.4rem;
                    background: #39beb8;
                    opacity: 1;
                    border-radius: 0.2rem;
                    font-size: 0.14rem;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-right: 0.3rem;

                    img {
                        width: 13.69px;
                        height: 13.69px;
                        margin-right: 0.05rem;
                    }
                }

                .btn:hover {
                    opacity: 0.8;
                }
            }
        }
    }
</style>



















































import {Component, Vue} from "vue-property-decorator";
import {Input, message, Modal, Rate, Select, Table, Tabs,} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import yuyveDiscuss from "@/components/Personal/MyComment/yuyveDiscuss.vue";
import {deleteReservationComment, fetchReservationComment,} from "@/services/api";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Select);
Vue.use(Rate);
Vue.use(Modal);

@Component({
    components: {
        PersonalHeader,
        yuyveDiscuss
    },
})
export default class extends Vue {
    // 取消订单Modal显隐
    cancelVisible = false;
    curType = "全部";
    data3: Array<any> = [];
    topLis = [
        {
            title: "养老机构",
            width: "4rem"

        },
        {
            title: "评价得分",
            width: "3rem"
        },
        {
            title: "评价时间",
            width: "3rem"
        },
        {
            title: "操作",
            width: "2.5rem"
        }
    ];
    pagination = {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        queryStatus: 2
    };
    serveComplete = false;
    serveCompleteData = '';


    eventbsakd(key) {
        if (key == 1) this.$router.push("/personal/serveComment");
        if (key == 2) this.$router.push("/personal/shopComment");
        if (key == 3) this.$router.push("/personal/yuyveComment");
        if (key == 4) this.$router.push("/personal/diagnosisTreatComment");
    }

    async created() {
        await this.getReservationComment();
    }

    // 点击删除服务评价
    handleDelServiceComment(value: any) {
        this.serveComplete = true
        this.serveCompleteData = value
    }

        //删除调用方法
    serviceOrderConfirm() {
        deleteReservationComment(this.serveCompleteData).then((res: any) => {
            if (res && res.code === 0) {
                // 处理数据
                message.success("删除成功！");
                this.getReservationComment();
            } else {
                message.error(res.msg);
            }
        }).then(_ => {
          this.serveComplete = false
        });
    }

    //查看评价
    checKComment6655(item: any) {
        const {href} = this.$router.resolve({
            path: "/personal/ReservationCommnet",
            query: {
                reservationOrderId: item.orderId,
                // agencyName: item.agencyName,
                // createdTime: item.commentTime,
                // seriesNum: item.orderCode,
                commentId: item.commentId,
                // agencyPic: item.agencyPic,
            },
        });
        open(href);
    }


    // 获取预约单评价
    getReservationComment() {
        fetchReservationComment({
            ...this.pagination
        }).then((res) => {
            if (res && res.code === 0) {
                this.data3 = [];
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                res.data.comments.forEach((item: any, index: any) => {
                    let obj: any = {};
                    obj.information = {
                        name: item.agencyName,
                        img: item.agencyPic,
                    };
                    obj.content = item.commentContent;
                    obj.rating = item.overallScore;
                    obj.time = item.commentTime;
                    obj.orderCode = item.orderCode;

                    obj.ids = {
                        orderId: item.orderId,
                        commentId: item.commentId,
                        ...item,
                    };
                    this.data3.push(obj);
                });
            } else {
                message.error(res.msg);
            }
        });
    }
}










































































    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({})
export default class RightOrderDetailService extends Vue {
  @Prop() status!: boolean;
  @Prop() data: any;

}

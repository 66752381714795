





































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message} from "ant-design-vue";
    import {diagnosisTreatCancel, diagnosisTreatDelete, medicalReport} from "@/services/api";

    @Component({})
export default class ReservationItem extends Vue {
  @Prop() cancelReason;
  cancelId = 0;
  cancelVisible = false;
  deletelVisible = false;
  @Prop() private content!: Object;
  @Prop() private itemIndex: Number;

  selectChange(key) {
    let obj = this.cancelReason.filter(i => i.key == key)[0].value
    this.cancelId = obj;
  }

  yuyuedanDetail() {
    const {href} = this.$router.resolve({
      path: "/personal/reservationListDetail",
      query: {
        reservationId: this.content["telemedicineReservationId"]
      }
    });
    open(href);
  }

//评价
  yuyuedanCommnet() {
    const content: any = this.content;
    const {href} = this.$router.resolve({
      path: "/personal/diagnosisEvaluationCommnet",
      query: {
        reservationOrderId: content.telemedicineReservationId,
        ifEvaluation: content.ifEvaluation,
      }
    });
    open(href);
  }

  //查看报告(下载)
  report(value) {
    medicalReport({
      reservationId: value
    }).then(res => {
      if (res.code === 0) {
        //文件下载
        let link = document.createElement('a')  // 创建a标签
        link.style.display = 'none'
        // 这里是将url转成blob地址，
        fetch(res.path).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob) // 设置下载地址
          link.download = ''
          document.body.appendChild(link)
          link.click();
          message.success("下载成功,请前往所选下载路径进行查看。");
        })
      } else {
        message.error(res.msg);
      }
    });

  }

  deleteEvent() {
    this.deletelVisible = true;
  }

  reservationDelete(reservationId: String) {
    diagnosisTreatDelete({
      reservationId: reservationId
    }).then(res => {
      if (res.code === 0) {
        message.success("删除成功,已移到服务订单回收站");
        this.$emit("againGetList");
      } else {
        message.error("只有已完成和已取消的订单能够放入回收站");
      }
    });
  }

  reservationDeleteEvent(reservationId) {
    this.reservationDelete(reservationId);
    this.deletelVisible = false;
  }

  reservationCancel(reservationId) {
    if (!this.cancelId) {
      message.warning("请选择取消原因");
      return;
    }
    diagnosisTreatCancel({
      reservationId: reservationId,
      cancelReason: this.cancelId
    }).then(res => {
      if (res.code === 0) {
        message.success("提交成功");
        this.cancelId = 0;
        this.$emit("againGetList");
      } else {
        message.error("提交失败");
      }
      this.cancelVisible = false;
    });
  }
}

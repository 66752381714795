












































    import {Component, Vue} from "vue-property-decorator";
    import {message, Table} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {fetchServiceOrderRecord} from "@/services/api";

    Vue.use(Table);

@Component({
  components: {
    PersonalHeader,
  },
})
export default class extends Vue {
  columns = [
    {
      title: "服务订单编号",
      dataIndex: "orderCode",
      width: "2.4rem",
      align: "center",
    },
    {
      title: "服务名称",
      dataIndex: "services",
      width: "3rem",
      scopedSlots: { customRender: "services" },
      align: "center",
    },
    {
      title: "提交时间",
      dataIndex: "cancelTime",
      width: "2.05rem",
      align: "center",
    },
    {
      title: "服务总金额",
      dataIndex: "totalAmount",
      width: "1.3rem",
      align: "center",
      scopedSlots: { customRender: "totalAmount" },
    },
    {
      title: "是否支付",
      dataIndex: "ifPay",
      width: "1.2rem",
      align: "center",
      scopedSlots: { customRender: "ifPay" },
    },
    {
      title: "取消原因",
      key: "cancelReason",
      dataIndex: "cancelReason",
      width: "1.8rem",
      align: "center",
    },
  ];
  data: Array<any> = [];
  async created() {
    await this.getServiceOrderRecord();
  }
  // 获取服务订单记录
  getServiceOrderRecord() {
    fetchServiceOrderRecord().then((res: any) => {
      if (res && res.code === 0) {
        // 处理数据
        this.data = [];
        res.data.serviceOrderInfos.forEach((item: any, index: any) => {
          let obj: any = {};

          obj.orderCode = item.orderCode;
          obj.services = item.services;
          obj.cancelTime = item.cancelTime;
          obj.totalAmount = item.totalAmount;
          obj.ifPay = item.ifPay;
          obj.cancelReason = item.cancelReason;
          this.data.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }
}

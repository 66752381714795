import {RootStateTypes} from "./types ";
import router from "../router/index";

const state: RootStateTypes = {
  serviceJoinStep: 0,
  author: "陈小生",
  detailItem: {},
  classServieDetail: {},
  curOrderStatus: {
    paixiafuwu: false,
    maijiafukuan: false,
    fuwutigongshang: false,
    querenfuwu: false,
    pingjia: false,
    guanbi: false,
  },
  classServiceOneStep: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  },
  classServiceTwoStep: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  },
  classServiceThreeStep: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  },

  payTime: {},
  cancelReason: {},
  allOrderStatus: {},
  allServiceStaus: {},
  refundReasons: {},
  returnId: "",
  relationId: {},
  allReservationStatus: {},
  orgFormJson: {},
  fakeDivBool: false,
  zxptDivBool: false,
  scrollTopHeight: 0,
  sliderBarItems: [],
  fixedCurTop: 0,
  noimage: "./img/noimage.png",
  producNumInCart: 0,
  cartPayData: [],
  productCommontTotalCount: 0,
  homeBannerHeight: "4.75rem",
  // 注册成功后，默认跳转的页面
  registerSuccessTargetPage: "/home",

  itemRender(current, type, originalElement) {
    // const a = document.createElement("a");
    //
    // if (type === "prev") {
    //   a.innerHTML = "上一页";
    //   return a;
    // } else if (type === "next") {
    //   a.innerHTML = "下一页";
    //   return a;
    // }
    return originalElement;
  },
  openHome(evt) {
    evt.preventDefault();
    const { href } = router.resolve({ path: "/home" });
    window.open(href, "_blank");
  },
};

export default state;






































import { Component, Prop, Vue } from "vue-property-decorator";
import YlmsItem from "./YlmsItem.vue";

@Component({
  props: {
    itemTitle: String,
    itemContent: String,
    itemImage: String,
    itemType: Number,
    isEnd: Boolean,
    long: Boolean,
    showArrow: Boolean
  }
})
export default class ProcessItem extends Vue {
  // @Prop() private msg!: string
}
























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Tabs } from "ant-design-vue";
import BankCard from "@/components/BankCard.vue";

Vue.use(Tabs);

@Component({
  components: {
    BankCard
  }
})
export default class extends Vue {}



























































  import {Component, Vue} from "vue-property-decorator";
  import {Input, message, Modal, Rate, Select, Table, Tabs,} from "ant-design-vue";
  import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
  import {clinicAppointmentFormComment, telemedicineComment} from "@/services/api";

  Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Select);
Vue.use(Rate);
Vue.use(Modal);

@Component({
  components: {
    PersonalHeader,
  },
})
export default class extends Vue {
  // 取消订单Modal显隐
  cancelVisible = false;
  curType = "全部";
  columns2 = [
    {
      title: "预约信息",
      dataIndex: "information",
      width: "3.87rem",
      align: "center",
      scopedSlots: {
        customRender: "information",
      },
    },
    {
      title: "评价得分",
      dataIndex: "rating",
      width: "2.4rem",
      align: "center",
      scopedSlots: {
        customRender: "rating",
      },
    },
    {
      title: "评价时间",
      dataIndex: "time",
      width: "2.52rem",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "ids",
      scopedSlots: {
        customRender: "ids",
      },
      align: "center",
    },
  ];
  data3: Array<any> = [];

  // 退订理由
  cancelReason = [
    {
      label: "不想买了",
      value: 1,
    },
    {
      label: "货不好",
      value: 2,
    },
    {
      label: "太贵了，被坑了",
      value: 3,
    },
    {
      label: "根本就是坏的",
      value: 4,
    },
    {
      label: "退货还需要理由？",
      value: 5,
    },
  ];

  onSearch() {}

  eventbsakd(key) {
    if (key == 1) this.$router.push("/personal/serveComment");
    if (key == 2) this.$router.push("/personal/shopComment");
    if (key == 3) this.$router.push("/personal/yuyveComment");
    if (key == 4) this.$router.push("/personal/diagnosisTreatComment");
  }
  async created() {
    await this.getReservationComment();
  }
  // 点击删除服务评价
  handleDelServiceComment(value: any) {
    const that = this;
    Modal.confirm({
      title: "删除预约单评价",
      content: "删除预约单评价后不可恢复，您确定要删除吗？",
      width:"5rem",
      onOk() {
        telemedicineComment(value.serviceCommentId).then((res: any) => {
          if (res && res.code === 0) {
            // 处理数据
            message.success("删除成功！");
            that.getReservationComment();
          } else {
            message.error(res.msg);
          }
        });
      },
      cancelText: "取消",
      okText: "确定",

      onCancel() {
      },
    });
  }
  checKComment6655(item: any) {
    const { href } = this.$router.resolve({
    path: "/personal/diagnosisEvaluationCommnet",
       query: {
         serviceCommentId:item.serviceCommentId,
         reservationOrderId:item.telemedicineReservationId
      },
  });
    open(href);
  }

  // 获取预约单评价
  getReservationComment() {
    clinicAppointmentFormComment({
      page: 1,
      limit: 10,
    }).then((res) => {
      if (res && res.code === 0) {
        this.data3 = [];
        // 处理数据
        res.page.list.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.information = {
            name: item.serviceName,
            img: item.servicePic,
          };
          obj.content = item.serviceCommentId;
          obj.rating = item.commentStar;
          obj.time = item.createTime;
          obj.ids = {
            serviceCommentId: item.serviceCommentId,
            telemedicineReservationId: item.telemedicineReservationId,
          };
          this.data3.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }
}

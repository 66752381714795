<template>
  <div>
    <Header />
    <Nav />
    <div class="breadcrumb">
      <div class="container">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link to="/home" class="breadcrumbO">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item :style="{ color: '#60c7c6' }"
            >养老资讯</a-breadcrumb-item
          >
        </a-breadcrumb>
      </div>
    </div>
    <div class="news-body">
      <div class="news-header">
        <div
          v-for="(item, index) in newTags"
          :key="`${index}newsTag`"
          class="tags"
        >
          <div
            :class="currentChecked === item.newsTypeNum ? 'checked' : 'text'"
            @click="() => checkedChange(item.newsTypeNum)"
          >
            {{ item.newTypeName }}
          </div>
        </div>
      </div>
      <PensionNewsItem
        :style="{ marginBottom: '0.3rem' }"
        v-for="(item, index) in newsList"
        :key="`${item.newsId + index}pensionNews`"
        :newsId="item.newsId"
        :title="item.mainTitle"
        :img="item.surfacePicture"
        :content="item.content"
        :publishTime="item.publishTime"
        :from="item.author"
        :newsType="item.newsType"
        :labelsList="item.labelsList"
      />
      <!--  :next="{id:newTags[index+1].newsId||'',title:newTags[index+1].mainTitle||''}"
      :pre="{id:newTags[index-1].newsId||'',title:newTags[index-1].mainTitle||''}"-->
      <div class="pagination">
        <a-pagination
          :showTotal="total => `共 ${pagination.total} 条`"
          :total="pagination.total"
          @change="onchange"
          :item-render="$store.state.itemRender"
          showQuickJumper
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import Nav from "@/components/Nav.vue";
    import Footer from "@/components/Footer.vue";
    import Header from "@/components/Header.vue";
    import PensionNewsItem from "@/components/PensionNewsItem.vue";
    import {fetchPensionNewsHome, fetchYlmsList} from "@/services/api";

    @Component({
  components: {
    Nav,
    Footer,
    Header,
    PensionNewsItem
  }
})
export default class extends Vue {
  currentChecked = "total";
  pagination = {
    pageNum: 1,
    pageSize: 10,
    total: 0
  };
  newTags = [
    {
      newTypeName: "全部资讯",
      newsTypeNum: "total"
    }
    // {
    //   label: "最新资讯",
    //   value: 1
    // },
    // {
    //   label: "政策法规",
    //   value: 2
    // },
    // {
    //   label: "产业观察",
    //   value: 3
    // },
    // {
    //   label: "养老百科",
    //   value: 4
    // },
    // {
    //   label: "生活娱乐",
    //   value: 5
    // },
    // {
    //   label: "养老保险",
    //   value: 6
    // },
    // {
    //   label: "热门资讯",
    //   value: 7
    // },
    // {
    //   label: "养老研究",
    //   value: 8
    // },
    // {
    //   label: "互动养老",
    //   value: 9
    // }
  ];

  newIdofHome = {
    "151": "热门资讯",
    "146": "政策法规",
    "150": "养老保险",
    "145": "最新资讯"
  };

  async getnewTags() {
    await fetchYlmsList();
    const str = localStorage.getItem("newTags");
    if (str) for (let item of JSON.parse(str)) this.newTags.push(item);
  }

  pensionNews = [
    {
      img: "jpg1_zixunliebiao",
      content:
        "近年来，我国老年人口数量和比重持续增长，人口红利对经济增长驱动作用逐年下降，日渐加深的人口老龄化正深刻影响社会供求结构调整，关系到经济高质量发展路径和目标，需要未雨绸缪积极应对。",
      title: "积极应对人口老龄化，增强经济运行弹性"
    },
    {
      img: "jpg2_zixunliebiao",
      content:
        "近年来，我国老年人口数量和比重持续增长，人口红利对经济增长驱动作用逐年下降，日渐加深的人口老龄化正深刻影响社会供求结构调整，关系到经济高质量发展路径和目标，需要未雨绸缪积极应对。",
      title: "养老新政密集发布，金融地产等跨界入局者跑步进场"
    },
    {
      img: "jpg3_zixunliebiao",
      content:
        "近年来，我国老年人口数量和比重持续增长，人口红利对经济增长驱动作用逐年下降，日渐加深的人口老龄化正深刻影响社会供求结构调整，关系到经济高质量发展路径和目标，需要未雨绸缪积极应对。",
      title: "积极应对人口老龄化，增强经济运行弹性"
    },
    {
      img: "jpg4_zixunliebiao",
      content:
        "近年来，我国老年人口数量和比重持续增长，人口红利对经济增长驱动作用逐年下降，日渐加深的人口老龄化正深刻影响社会供求结构调整，关系到经济高质量发展路径和目标，需要未雨绸缪积极应对。",
      title: "中老年人网络生活“众生态”"
    },
    {
      img: "jpg5_zixunliebiao",
      content:
        "近年来，我国老年人口数量和比重持续增长，人口红利对经济增长驱动作用逐年下降，日渐加深的人口老龄化正深刻影响社会供求结构调整，关系到经济高质量发展路径和目标，需要未雨绸缪积极应对。",
      title: "2019江苏国际养老服务博览会亮点纷呈"
    }
  ];

  newsList = [];

  async created() {
    await this.getnewTags();
    if (this.$route.params.typeId) {
      this.currentChecked = this.$route.params.typeId;
    }
    const params = {
      pageSize: 10,
      pageIndex: 1,
      newsType: this.currentChecked
    };
    this.getNewsList(params);
  }
  checkedChange(index) {
    this.currentChecked = index;
    const params = {
      pageSize: 10,
      pageIndex: 1,
      newsType: this.currentChecked
    };
    this.newsList.length = 0;
    this.getNewsList(params);
  }

  getNewsList(params) {
    fetchPensionNewsHome(params).then(res => {
      if (res.code === 0) {
        res.data.list.forEach(item => this.newsList.push(item));
        this.pagination = {
          ...this.pagination,
          total: res.data.totalCount
        };
        const ba = [];
        for (let item of this.newsList) {
          let { next, pre, newsId, mainTitle } = item;
          ba.push({ next, pre, newsId, mainTitle });
        }
        localStorage.setItem("article", JSON.stringify(ba));
      }
    });
  }

  onchange(page, pageSize) {
    window.scrollTo({ top: 0 });

    this.pagination = {
      ...this.pagination,
      pageNum: page,
      pageSize: pageSize
    };
    const params = {
      pageSize: this.pagination.pageSize,
      pageIndex: this.pagination.pageNum,
      newsType: this.currentChecked
    };
    this.newsList.length = 0;
    this.getNewsList(params);
  }
}
</script>

<style lang="less">
.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.18rem 0 0.18rem;
  background-color: #fff;
  .container {
    width: 13.66rem;
    margin: 0 auto;
  }
}
.news-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 13.66rem;
  margin: 0 auto;
  background-color: #fff;
  .news-header {
    // height: 1.8rem;
    width: 100%;
    background-color: #edfefe;
    padding: 0.4rem 0 0.4rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 0.6rem;
    .tags {
      width: 2.5rem;
      height: 0.46rem;
      font-size: 0.2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.3rem;
      .text {
        height: 100%;
        width: 1.1rem;
        background-color: #edfefe;
        color: #515151;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .text:hover,
      .checked {
        height: 100%;
        width: 1.1rem;
        background-color: #60c6c7;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>


































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {confirmationOrder, dictionariesWithDesc, reasonOfCancellation} from "@/services/api";
import {message} from "ant-design-vue";

@Component({
    props: {
        status: Number
    }
})
export default class DetailInfo extends Vue {
    @Prop() dataList: any;
    serveComplete = false;
    serveCancel: Boolean = false;
    selectChangetsuahValue: any = [];
    cancelServiceList = {};
    shopAddressPlaceholder = "";

    province: any = '';
    city: any = '';
    area: any = '';
    street: any = '';



    //取消服务订购单列表
    getCancelServiceList() {
        dictionariesWithDesc(89).then((res) => {
            if (res && res.code == 0) {
                this.cancelServiceList = res.values;
            }
        })
    }

    //提示确认收货
    confirmReceipt() {
        this.serveComplete = true;
    }

    //确认收货
    serviceOrderList(orderId) {
        confirmationOrder({
            orderId: orderId,
        }).then((res) => {
            if (res.code === 0) {
                message.success("确认成功");
                this.$emit("lookDetail");
            } else {
                message.error("确认失败");
            }
        });
        this.serveComplete = false;
    }



    //取消订单提示
    cancellationOrder(key: boolean) {
        this.getCancelServiceList()
        this.serveCancel = key;
    }

    //取消订单
    selectChange(orderId) {
        if (this.selectChangetsuahValue.length === 0) {
            message.error("请选择取消订单理由");
            return
        } else {
            reasonOfCancellation({
                orderId: orderId,
                reason: this.selectChangetsuahValue
            }).then(res => {
                if (res.code === 0) {
                    message.success("取消订单成功");
                    this.$router.push("/personal/serviceOrderList");
                    this.$emit("lookDetail");
                    this.serveCancel = false
                } else {
                    message.error(res.msg);
                }
            });
            this.serveComplete = false;
        }


    }

    //进行评价
    evaluate(value) {
        this.$router.push({
            path: '/personal/goodsJudgeService',
            query: {
                orderId: value,
            }
        })
    }




    //付款
    payment(value) {


    }
}

import { render, staticRenderFns } from "./highConfigurationStep.vue?vue&type=template&id=97e365a4&scoped=true&"
import script from "./highConfigurationStep.vue?vue&type=script&lang=ts&"
export * from "./highConfigurationStep.vue?vue&type=script&lang=ts&"
import style0 from "./highConfigurationStep.vue?vue&type=style&index=0&id=97e365a4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e365a4",
  null
  
)

export default component.exports
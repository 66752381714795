












































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {getCancelServiceList, getReservationCancel} from "@/services/api";
import {message} from "ant-design-vue";

@Component({
    props: {
        infoData: Object,
        status: Number
    }
})
export default class ReservationDetailInfo extends Vue {
    @Prop() private content!: Object;
    cancelVisible = false;
    cancelReason = [];
    cancelId = "";

    created() {
        getCancelServiceList({typeId: "150"}).then((res: any) => {
            this.cancelReason = res["data"];
        });
    }

    cancelTheItem() {
        this.cancelVisible = true;
    }
    selectChange(value) {
        this.cancelId = value;
    }
    yuyuedanCommnet() {
        const content: any = this.content;
        const {href} = this.$router.resolve({
            path: "/personal/ReservationCommnet",
            query: {
                reservationOrderId: content.reservationOrderId,
                agencyName: content.agencyInfo.agencyName,
                seriesNum: content.seriesNum,
                orgId: content.agencyInfo.orgId
            }
        });
        open(href);
    }
    reservationCancel(reservationId) {
        if (!this.cancelId) {
            message.warning("请选择取消原因");
            return;
        }

        getReservationCancel({
            reservationId: reservationId,
            cancelReason: this.cancelId
        }).then(res => {
            if (res.code === 0) {
                message.success("提交成功");
                this.cancelId = "";
                this.$router.push("/personal/myReservation");
            } else {
                message.error("提交失败");
            }
            this.cancelVisible = false;
        });
    }
}

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import BusinessJoin from "../views/Join/BusinessJoin.vue";
import ServiceJoin from "../views/Join/ServiceJoin.vue";
import ServiceJoinProcess from "../views/Join/ServiceJoinProcess.vue";
import BusinessJoinRead from "../views/Join/BusinessJoinRead.vue";
import BusinessJoinStep1 from "../views/Join/BusinessJoinStep1.vue";
import BusinessJoinStep2 from "../views/Join/BusinessJoinStep2.vue";
import BusinessJoinStep3 from "../views/Join/BusinessJoinStep3.vue";
import BusinessJoinStep4 from "../views/Join/BusinessJoinStep4.vue";
import BusinessJoinStepProcess from "../views/Join/BusinessJoinStepProcess.vue";
import OpenAuthAlipay from "../views/Join/util/open-auth-alipay.vue";
import OpenAuthToken from "../views/Join/util/open-auth-token.vue";
import SearchProcess from "../views/Join/SearchProcess.vue";
import ServiceJoinStep1 from "../views/Join/ServiceJoinStep1.vue";
import ServiceJoinStep2 from "../views/Join/ServiceJoinStep2.vue";
import ServiceJoinStep3 from "../views/Join/ServiceJoinStep3.vue";
import ServiceJoinStep4 from "../views/Join/ServiceJoinStep4.vue";
import ServiceJoinRead from "../views/Join/ServiceJoinRead.vue";
import PensionService from "../views/PensionService.vue";
import ServiceOrder from "../views/ServiceOrder.vue";
import purchaseOrder from "../views/purchaseOrder.vue";
import ServiceOrderPay from "../views/ServiceOrderPay.vue";
import ServiceOrderPaySuccess from "../views/ServiceOrderPaySuccess.vue";
import purchaseOrderSuccess from "../views/purchaseOrderSuccess.vue";
import allJoin from "../views/Join/allJoin.vue";
import merchantBackground from "../views/Join/merchantBackground.vue";
import cooperationWithMerchants from "../views/Join/cooperationWithMerchants.vue";

import OrganizationJoinRead from "../views/Join/OrganizationJoinRead.vue";
import OrganizationJoinProcess from "../views/Join/OrganizationJoinProcess.vue";
import OrganizationJoinStep1 from "../views/Join/OrganizationJoinStep1.vue";
import OrganizationJoinStep2 from "../views/Join/OrganizationJoinStep2.vue";
import OrganizationJoin from "../views/Join/OrganizationJoin.vue";
import PensionMode from "../views/PensionMode.vue";
import PensionInstitution from "../views/PensionInstitution.vue";
import PensionFood from "../views/PensionFood.vue";
import PensionInfo from "../views/PensionInfo.vue";
import myHomePage from "../views/Personal/OtherPage/myHomePage.vue";
import MyGoods from "../views/Personal/OtherPage/MyGoods/MyGoods.vue";
import TrashCan from "../views/Personal/OtherPage/MyGoods/TrashCan.vue";
import OrderDetail from "../views/Personal/OtherPage/MyGoods/OrderDetail.vue";
import ReceiptApply from "../views/Personal/OtherPage/MyGoods/ReceiptApply.vue";
import ReceiptDetail from "../views/Personal/OtherPage/MyGoods/ReceiptDetail.vue";
import goodsJudgeService from "../views/Personal/OtherPage/MyGoods/goodsJudgeService.vue";
import AccountCoin from "../views/Personal/OtherPage/AccountCoin.vue";
import AccountCoinHistory from "../views/Personal/OtherPage/AccountCoinHistory.vue";
import InvestCoin from "../views/Personal/OtherPage/InvestCoin.vue";
import PayCoin from "../views/Personal/OtherPage/PayCoin.vue";
import AccountInfo from "../views/Personal/OtherPage/AccountInfo.vue";
import FamilyInfo from "../views/Personal/OtherPage/FamilyInfo.vue";
import userRatings from "../views/Personal/OtherPage/userRatings.vue";
import ChangePassword from "../views/Personal/OtherPage/ChangePassword.vue";
import ShouHuoLocation from "../views/Personal/OtherPage/ShouHuoLocation.vue";
import PayDetail from "../views/Personal/OtherPage/PayDetail.vue";
import MyCoupon from "../views/Personal/OtherPage/MyCoupon.vue";
import MyBankCard from "../views/Personal/OtherPage/MyBankCard.vue";
import AddBankCard from "../views/Personal/OtherPage/AddBankCard.vue";
import MyService from "../views/Personal/OtherPage/MyService/MyService.vue";
import ServiceTrashCan from "../views/Personal/OtherPage/MyService/ServiceTrashCan.vue";
import JudgeService from "../views/Personal/OtherPage/MyService/JudgeService.vue";
import serviceOrdersJudge from "../views/Personal/OtherPage/serviceOrderForm/serviceOrdersJudge.vue";
import OpenService from "../views/Personal/OtherPage/MyService/OpenService.vue";
import ServiceReceiptApply from "../views/Personal/OtherPage/MyService/ServiceReceiptApply.vue";
import ServiceReceiptDetail from "../views/Personal/OtherPage/MyService/ServiceReceiptDetail.vue";
import ServiceDetail from "../views/Personal/OtherPage/MyService/ServiceDetail.vue";
import thirdPartyOrder from "../views/Personal/OtherPage/tripartite/thirdPartyOrder.vue";

import serviceOrderList from "../views/Personal/OtherPage/serviceOrderForm/serviceOrderList.vue";
import serviceOrderDetail from "../views/Personal/OtherPage/serviceOrderForm/serviceOrderDetail.vue";

import FuWuLocation from "../views/Personal/OtherPage/FuWuLocation.vue";
import ReturnGoods from "../views/Personal/OtherPage/ReturnGoods.vue";
import ReturnService from "../views/Personal/OtherPage/ReturnService.vue";
import ReturnService1 from "../views/Personal/OtherPage/ReturnService1.vue";
import ReturnService2 from "../views/Personal/OtherPage/ReturnService2.vue";
import ReturnService3 from "../views/Personal/OtherPage/ReturnService3.vue";
import AskBuyAfter from "../views/Personal/OtherPage/AskBuyAfter.vue";
import AskBuyAfterService from "../views/Personal/OtherPage/AskBuyAfterService.vue";
import returnGoods1 from "../views/Personal/OtherPage/ReturnGoods1.vue";
import ReturnGoodsOne from "../views/Personal/OtherPage/ReturnGoodsOne.vue";
import ReturnGoodsTwo from "../views/Personal/OtherPage/ReturnGoodsTwo.vue";
import ReturnGoodsThree from "../views/Personal/OtherPage/ReturnGoodsThree.vue";
import ReturnGoods2 from "../views/Personal/OtherPage/ReturnGoods2.vue";
import ReturnGoods3 from "../views/Personal/OtherPage/ReturnGoods3.vue";
import ReturnGoods4 from "../views/Personal/OtherPage/ReturnGoods4.vue";
import ServiceProcessDetail from "../views/Personal/OtherPage/MyService/ServiceProcessDetail.vue";
//样板间服务
import classProductService1 from "../views/Personal/OtherPage/MyService/classProductService1.vue";
//可工配服务
import classConfigService1 from "../views/Personal/OtherPage/MyService/classConfigService1.vue";

import classServiceOne from "../views/Personal/OtherPage/MyService/classServiceOne.vue";
import classServiceTow from "../views/Personal/OtherPage/MyService/classServiceTow.vue";
import classOpenServiceOne1 from "../views/Personal/OtherPage/MyService/classOpenServiceOne1.vue";
import guahaowangSDK1 from "../views/Personal/OtherPage/MyService/guahaowangSDK1.vue";
import classOpenServiceOne2 from "../views/Personal/OtherPage/MyService/classOpenServiceOne2.vue";
import classOpenServiceOne from "../views/Personal/OtherPage/MyService/classOpenServiceOne.vue";
import classOpenServiceTwo1 from "../views/Personal/OtherPage/MyService/classOpenServiceTwo1.vue";
import classOpenServiceTwo2 from "../views/Personal/OtherPage/MyService/classOpenServiceTwo2.vue";
import classOpenServiceTwo3 from "../views/Personal/OtherPage/MyService/classOpenServiceTwo3.vue";
import MyCollection from "../views/Personal/OtherPage/MyCollection/MyCollection.vue";
import MyReservation from "../views/Personal/OtherPage/MyReservation/MyReservation.vue";
import ReservationTrashCan from "../views/Personal/OtherPage/MyReservation/ReservationTrashCan.vue";
import ReservationCommnet from "../views/Personal/OtherPage/MyReservation/ReservationCommnet.vue";
import ReservationDetail from "../views/Personal/OtherPage/MyReservation/ReservationDetail.vue";
import reservationList from "../views/Personal/OtherPage/reservationList/reservationList.vue";
import reservationListTrashCan from "../views/Personal/OtherPage/reservationList/reservationListTrashCan.vue";
import reservationListDetail from "../views/Personal/OtherPage/reservationList/reservationListDetail.vue";
import diagnosisEvaluationCommnet from "../views/Personal/OtherPage/reservationList/diagnosisEvaluationCommnet.vue";


import MyComment from "../views/Personal/OtherPage/MyComment/MyComment.vue";
import CancelServiceLog from "../views/Personal/OtherPage/CancelService/CancelServiceLog.vue";
import CancelGoodsLog from "../views/Personal/OtherPage/CancelGoods/CancelGoodsLog.vue";
import CancelyuyveLog from "../views/Personal/OtherPage/Cancelyuyve/CancelyuyveLog.vue";
import MySeek from "../views/Personal/OtherPage/MySeek/MySeek.vue";

import ServicePage from "../views/ServicePage/ServicePage.vue";
import servieItemDetail from "../views/ServiceDetail/servieItemDetail.vue";
import servieItemDetailAll from "../views/ServiceDetail/servieItemDetailAll.vue";

import organDetailAll from "../views/organDetail/organDetailAll.vue";


import CookInHome from "../views/CookInHome.vue";
import OrgDetail from "../views/OrgDetail.vue";
import organizationDetail from "../views/organizationDetail.vue";
import subordinateBody from "../views/subordinateBody.vue";


import Artical from "../views/Artical.vue";
import PensionNews from "../views/PensionNews.vue";
import PensionSolution from "../views/PensionSolution.vue";
import PensionDoctor from "../views/PensionDoctor.vue";
import PensionClean from "../views/PensionClean.vue";
import PensionSleep from "../views/PensionSleep.vue";
import PensionOrderSuccess from "../views/PensionOrderSuccess.vue";
import PensionOrderForm from "../views/PensionOrderForm.vue";
import PensionMap from "../views/PensionMap.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
// import ForgetPasswordNew from "../views/ForgetPasswordNew.vue";
import PickCity from "../views/PickCity.vue";
import Fuwuxiaofei from "../views/Personal/OtherPage/Fuwuxiaofei.vue";
import shopComment from "../views/Personal/OtherPage/MyComment/shopComment.vue";
import yuyveComment from "../views/Personal/OtherPage/MyComment/yuyveComment.vue";
import serveComment from "../views/Personal/OtherPage/MyComment/serveComment.vue";
import diagnosisTreatComment from "../views/Personal/OtherPage/MyComment/diagnosisTreatComment.vue";

import RenderJsonToService from "../views/RenderJsonToService.vue";
import paySuccessWaitting from "../views/paySuccessWaitting.vue";
import fuWuLocationAdd from "../components/Personal/fuWuLocationAdd.vue";
import addressLocationAdd from "../components/Personal/addressLocationAdd.vue";
//以下为征信平台界面
import creditReportingLogin from "../views/creditInvestigation/creditReportingLogin.vue";
import creditReportingHome from "../views/creditInvestigation/creditReportingHome.vue";
import creditReportingServicePage from "../views/creditInvestigation/creditReportingServicePage.vue";
import serviceProviderDetails from "../views/creditInvestigation/particulars/serviceProviderDetails.vue";
import agencyDetails from "../views/creditInvestigation/particulars/agencyDetails.vue";
import businessDetails from "../views/creditInvestigation/particulars/businessDetails.vue";
import serviceDetails from "../views/creditInvestigation/particulars/serviceDetails.vue";
import commodityDetails from "../views/creditInvestigation/particulars/commodityDetails.vue";
import memberInformation from "../views/creditInvestigation/particulars/memberInformation.vue";
import creditPersonalCenter from "../views/creditInvestigation/creditPersonalCenter.vue";
//即时通讯
import chat from "../views/chat/chat.vue"
// 第三方登录
import wxOAuth from "../views/oauth/wx-oauth.vue"
// 第三方登录
import wxBind from "../views/oauth/wx_bind.vue"
//养老商城
import mallHome from "../views/mall/mallApp.vue";
import mallHomeApp from "../views/mall/Home.vue";
import mallDetail from "../views/mall/Detail.vue";
import mallCart from "../views/mall/Cart.vue";
import mallSearch from "../views/mall/Search.vue";
import mallShop from "../views/mall/Shop.vue";
import mallOrderInfo from "../views/mall/OrderInfo.vue";
import mallPaySuccess from "../views/mall/PayOrderSuccess.vue";
import checkstand from "../views/mall/Checkstand.vue";
import selling from "../views/mall/Selling.vue";
import leaderboard from "../views/mall/Leaderboard.vue";
import spike from "../views/mall/Spike.vue";
import couponCenter from "../views/mall/CouponCenter.vue";

Vue.use(VueRouter);

// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location: any, onResolve: any, onReject: any): any {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch((err: any) => err)
// }

const routes = [

    //养老商城
    {
        path: "/mall/home",
        name: "mallHome",
        component: mallHome,
        meta: {
            requireAuth: false
        },
        children: [
            {
                path: "/mall/home",
                name: "商城首页",
                component: mallHomeApp,
                meta: {
                    requireAuth: false
                },
            },
            {
                path: "/mall/selling",
                name: "Selling",
                component: selling,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/leaderboard",
                name: "Leaderboard",
                component: leaderboard,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/spike",
                name: "Spike",
                component: spike,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/couponCenter",
                name: "couponCenter",
                component: couponCenter,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/detail/:id",
                name: "商品详情",
                component: mallDetail,
                meta:{
                    requireAuth:false
                }
            },
            {
                path: "/mall/cart",
                name: "购物车",
                component: mallCart,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/search",
                name: "商城搜索",
                component: mallSearch,
                meta:{
                    requireAuth:false
                }
            },
            {
                path: "/mall/shop/:id",
                name: "shop",
                component: mallShop,
                meta:{
                    requireAuth:false
                }
            },
            {
                path: "/mall/shop/:id/:typeId",
                name: "店铺详情",
                component: mallShop,
                meta:{
                    requireAuth:false
                }
            },
            {
                path: "/mall/OrderInfo",
                name: "立即购买",
                component: mallOrderInfo,
                meta:{
                    requireAuth:false
                }
            },
            {
                path: "/mall/mallPaySuccess",
                name: "支付成功",
                component: mallPaySuccess,
                meta:{
                    requireAuth:true
                }
            },
            {
                path: "/mall/checkStand",
                name: "支付返回",
                component: checkstand,
                meta:{
                    requireAuth:true
                }
            },
        ]
    },


    //养老平台
    {
        path: "/chat",
        name: "chat",
        component: chat,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/creditReportingLogin",
        name: "creditReportingLogin",
        component: creditReportingLogin,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/creditReportingHome",
        name: "creditReportingHome",
        component: creditReportingHome,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/creditReportingServi`cePage",
        name: "creditReportingServicePage",
        component: creditReportingServicePage,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/serviceProviderDetails",
        name: "serviceProviderDetails",
        component: serviceProviderDetails,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/agencyDetails",
        name: "agencyDetails",
        component: agencyDetails,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/businessDetails",
        name: "businessDetails",
        component: businessDetails,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/serviceDetails",
        name: "serviceDetails",
        component: serviceDetails,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/commodityDetails",
        name: "commodityDetails",
        component: commodityDetails,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/memberInformation",
        name: "memberInformation",
        component: memberInformation,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/creditInvestigation/creditPersonalCenter",
        name: "creditPersonalCenter",
        component: creditPersonalCenter,
        meta: {
            requireAuth: false
        }
    },


    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/paySuccessWaitting",
        name: "paySuccessWaitting",
        component: paySuccessWaitting,
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/home",
        name: "home",
        component: Home,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/old",
        component: Home,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            requireAuth: false
        }

    },
    {
        path: "/oauth/wx",
        name: "微信登录",
        component: wxOAuth,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/oauth/wx/bind",
        name: "wxBind",
        component: wxBind,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/forgetPassword",
        name: "forgetPassword",
        component: ForgetPassword,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/forgetPasswordNew",
        name: "忘记密码",
        component: () => import("../views/ForgetPasswordNew.vue"),
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/pensionService",
        name: "pensionService",
        component: PensionService,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/businessJoin",
        name: "businessJoin",
        component: BusinessJoin,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/businessJoinRead",
        name: "businessJoinRead",
        component: BusinessJoinRead,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/businessJoinStep1",
        name: "businessJoinStep1",
        component: BusinessJoinStep1,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/businessJoinStep2",
        name: "businessJoinStep2",
        component: BusinessJoinStep2,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/businessJoinStep3",
        name: "businessJoinStep3",
        component: BusinessJoinStep3,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/businessJoinStep4",
        name: "businessJoinStep4",
        component: BusinessJoinStep4,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/BusinessJoinStepProcess",
        name: "BusinessJoinStepProcess",
        component: BusinessJoinStepProcess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/openAuthAlipay",
        name: "openAuthAlipay",
        component: OpenAuthAlipay,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/openAuth`Token`",
        name: "openAuthToken",
        component: OpenAuthToken,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/searchProcess",
        name: "searchProcess",
        component: SearchProcess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceOrder",
        name: "serviceOrder",
        component: ServiceOrder,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/purchaseOrder",
        name: "purchaseOrder",
        component: purchaseOrder,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/goodsOrder",
        name: "goodsOrder",
        component: () => import("@/views/goodsOrder.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceOrderPay",
        name: "serviceOrderPay",
        component: ServiceOrderPay,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/ServiceOrderPaySuccess",
        name: "ServiceOrderPaySuccess",
        component: ServiceOrderPaySuccess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/purchaseOrderSuccess",
        name: "purchaseOrderSuccess",
        component: purchaseOrderSuccess,
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/serviceJoin",
        name: "serviceJoin",
        component: ServiceJoin,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/ServiceJoinProcess",
        name: "ServiceJoinProcess",
        component: ServiceJoinProcess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceJoinRead",
        name: "serviceJoinRead",
        component: ServiceJoinRead,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceJoinStep1",
        name: "serviceJoinStep1",
        component: ServiceJoinStep1,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceJoinStep2",
        name: "serviceJoinStep2",
        component: ServiceJoinStep2,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceJoinStep3",
        name: "serviceJoinStep3",
        component: ServiceJoinStep3,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/serviceJoinStep4",
        name: "serviceJoinStep4",
        component: ServiceJoinStep4,
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/allJoin",
        name: "allJoin",
        component: allJoin,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/cooperationWithMerchants",
        name: "cooperationWithMerchants",
        component: cooperationWithMerchants,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/merchantBackground",
        name: "merchantBackground",
        component: merchantBackground,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/OrganizationJoinRead",
        name: "OrganizationJoinRead",
        component: OrganizationJoinRead,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/OrganizationJoinProcess",
        name: "OrganizationJoinProcess",
        component: OrganizationJoinProcess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/OrganizationJoinStep1",
        name: "OrganizationJoinStep1",
        component: OrganizationJoinStep1,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/OrganizationJoinStep2",
        name: "OrganizationJoinStep2",
        component: OrganizationJoinStep2,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/OrganizationJoin",
        name: "OrganizationJoin",
        component: OrganizationJoin,
        meta: {
            requireAuth: false
        }
    },


    {
        path: "/pensionMode",
        name: "pensionMode",
        component: PensionMode,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionInstitution/:dicId",
        name: "pensionInstitution",
        component: PensionInstitution,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionFood",
        name: "pensionFood",
        component: PensionFood,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionInfo/:secondId",
        name: "pensionInfo",
        component: PensionInfo,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/servicePage",
        name: "servicePage",
        component: ServicePage,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/servieItemDetail",
        name: "servieItemDetail",
        component: servieItemDetail,
        meta: {
            requireAuth: false
        }
    },
    // servieItemDetailAll
    {
        path: "/servieItemDetailAll",
        name: "servieItemDetailAll",
        component: servieItemDetailAll,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/organDetailAll",
        name: "organDetailAll",
        component: organDetailAll,
        meta: {
            requireAuth: true
        }
    },

    // {
    //   path: "/serviceDetail1",
    //   name: "serviceDetail1",
    //   component: FoodDetail
    // },
    // {
    //   path: "/serviceDetail2",
    //   name: "serviceDetail2",
    //   component: AllServiceDetail
    // },
    {
        path: "/cookInHome/:serviceId",
        name: "cookInHome",
        component: CookInHome,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/orgDetail/:orgId",
        name: "orgDetail",
        component: OrgDetail,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/organizationDetail/:orgId",
        name: "organizationDetail",
        component: organizationDetail,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/subordinateBody/:orgId",
        name: "subordinateBody",
        component: subordinateBody,
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/artical/:newsId",
        name: "artical",
        component: Artical,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionNews",
        name: "pensionNews",
        component: PensionNews,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionNews/:typeId",
        name: "养老资讯",
        component: PensionNews,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionSolution",
        name: "pensionSolution",
        component: PensionSolution,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pensionDoctor",
        name: "pensionDoctor",
        component: PensionDoctor,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pensionClean",
        name: "pensionClean",
        component: PensionClean,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pensionSleep",
        name: "pensionSleep",
        component: PensionSleep,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pensionOrderSuccess",
        name: "pensionOrderSuccess",
        component: PensionOrderSuccess,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pensionOrderForm",
        name: "pensionOrderForm",
        component: PensionOrderForm,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/pensionMap",
        name: "pensionMap",
        component: PensionMap,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/pickCity",
        name: "pickCity",
        component: PickCity,
        meta: {
            requireAuth: false
        }
    },
    {
        path: "/renderjsontoservice",
        name: "renderjsontoservice",
        component: RenderJsonToService,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/personal",
        // component: Personal,
        component: () => import("../views/Personal/PersonalLayout.vue"),


        children: [
            {
                path: "fuWuLocationAdd",
                component: fuWuLocationAdd,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "addressLocationAdd",
                component: addressLocationAdd,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myHomePage",
                name: "我的首页",
                component: myHomePage,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "mygoods",
                name: "我的商品",
                component: MyGoods,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "trashcan",
                name: "商品订单回收站",
                component: TrashCan,
                meta: {
                    requireAuth: true
                }
            },
            // {
            //     path: "judgeOrder",
            //     name: "评价商品订单",
            //     component: JudgeOrder,
            //     meta: {
            //         requireAuth: true
            //     }
            // },
            {
                path: "orderDetail",
                name: "订单详情",
                component: OrderDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "receiptApply",
                name: "发票申请",
                component: ReceiptApply,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "receiptDetail",
                name: "发票详情",
                component: ReceiptDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "goodsJudgeService",
                name: "评价商品订单",
                component: goodsJudgeService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myService",
                name: "第三方服务订单",
                component: MyService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "thirdPartyOrder",
                name: "我的服务",
                component: thirdPartyOrder,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceTrashcan",
                name: "服务订单回收站",
                component: ServiceTrashCan,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "judgeService",
                name: "评价服务订单",
                component: JudgeService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceOrdersJudge",
                name: "订购单评价",
                component: serviceOrdersJudge,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "openService",
                name: "服务开启",
                component: OpenService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceReceiptApply",
                name: "服务发票申请",
                component: ServiceReceiptApply,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceReceiptDetail",
                name: "服务发票详情",
                component: ServiceReceiptDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceDetail",
                name: "服务详情",
                component: ServiceDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceProcessDetail",
                name: "服务流程详情",
                component: ServiceProcessDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classServiceOne",
                name: "等待服务商提供服务",
                component: classServiceOne,
                meta: {
                    requireAuth: true
                }
            },
            //服务订购单
            {
                path: "serviceOrderList",
                name: "服务订购单",
                component: serviceOrderList,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serviceOrderDetail",
                name: "服务订购单详情",
                component: serviceOrderDetail,
                meta: {
                    requireAuth: true
                }
            },



            //样板间
            {
                path: "classProductService1",
                name: "样板间服务详情",
                component: classProductService1,
                meta: {
                    requireAuth: true
                }
            },

            //可工配服务
            {
                path: "classConfigService1",
                name: "可工配服务",
                component: classConfigService1,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: "classServiceTow",
                name: "等待服务开启",
                component: classServiceTow,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classOpenServiceOne",
                name: "classOpenServiceOne",
                component: classOpenServiceOne,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classOpenServiceOne1",
                name: "提交资料/影像",
                component: classOpenServiceOne1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classOpenServiceOne2",
                name: "反馈结果",
                component: classOpenServiceOne2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "guahaowangSDK1",
                name: "挂号网影像类SDK",
                component: guahaowangSDK1,
                meta: {
                    requireAuth: true
                }
            },


            {
                path: "classOpenServiceTwo1",
                name: "预约体检日期",
                component: classOpenServiceTwo1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classOpenServiceTwo2",
                name: "到达体检中心体检",
                component: classOpenServiceTwo2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "classOpenServiceTwo3",
                name: "获取体检报告",
                component: classOpenServiceTwo3,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myCollection",
                name: "我的收藏",
                component: MyCollection,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myReservation",
                name: "机构预约单",
                component: MyReservation,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "reservationList",
                name: "诊疗预约单",
                component: reservationList,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "reservationListTrashCan",
                name: "诊疗预约单回收站",
                component: reservationListTrashCan,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "reservationListDetail",
                name: "诊疗预约单详情",
                component: reservationListDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "reservationTrashCan",
                name: "预约单回收站",
                component: ReservationTrashCan,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "ReservationCommnet",
                name: "预约单评价",
                component: ReservationCommnet,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "diagnosisEvaluationCommnet",
                name: "诊疗预约单评价",
                component: diagnosisEvaluationCommnet,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "reservationDetail",
                name: "预约单详情",
                component: ReservationDetail,
                meta: {
                    requireAuth: true
                }
            },

            {
                path: "myComment",
                name: "我的评价",
                component: MyComment,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "shopComment",
                name: "商品评价",
                component: shopComment,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "yuyveComment",
                name: "机构预约单评价",
                component: yuyveComment,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "serveComment",
                name: "服务评价",
                component: serveComment,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "diagnosisTreatComment",
                name: "诊疗预约单评价列表",
                component: diagnosisTreatComment,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "cancelService",
                name: "取消服务订单",
                component: CancelServiceLog,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "cancelGoods",
                name: "取消商品订单",
                component: CancelGoodsLog,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "Cancelyuyve",
                name: "取消预约订单",
                component: CancelyuyveLog,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "mySeek",
                name: "我的咨询",
                component: MySeek,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "accountCoin",
                name: "账户代币",
                component: AccountCoin,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "accountCoinHistory",
                name: "历史充值记录",
                component: AccountCoinHistory,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "investCoin",
                name: "充值代币",
                component: InvestCoin,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "payCoin",
                name: "支付代币",
                component: PayCoin,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "accountInfo",
                name: "账户信息",
                component: AccountInfo,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "familyInfo",
                name: "家庭圈信息",
                component: FamilyInfo,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "changePassword",
                name: "修改登录密码",
                component: ChangePassword,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "userRatings",
                name: "用户评分",
                component: userRatings,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "shouHuoLocation",
                name: "收货地址管理",
                component: ShouHuoLocation,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "fuWuLocation",
                name: "服务地址管理",
                component: FuWuLocation,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "payDetail",
                name: "商品消费明细",
                component: PayDetail,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "fuwuxiaofei",
                name: "服务消费明细",
                component: Fuwuxiaofei,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myCoupon",
                name: "我的优惠券",
                component: MyCoupon,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "myBankCard",
                name: "我的银行卡",
                component: MyBankCard,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "addBankCard",
                name: "添加银行卡",
                component: AddBankCard,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnservice",
                name: "申请/查询服务退款",
                component: ReturnService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnService1",
                name: "买家申请退款",
                component: ReturnService1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnService2",
                name: "服务商卖家处理退款申请",
                component: ReturnService2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnService3",
                name: "服务退款完毕",
                component: ReturnService3,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "askBuyAfterService",
                name: "服务申请售后",
                component: AskBuyAfterService,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returngoods",
                name: "申请/查询退换货",
                component: ReturnGoods,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "askBuyAfter",
                name: "申请售后",
                component: AskBuyAfter,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "ReturnGoodsOne",
                name: "买家申请仅退款",
                component: ReturnGoodsOne,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "ReturnGoodsTwo",
                name: "卖家处理退款申请",
                component: ReturnGoodsTwo,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "ReturnGoodsThree",
                name: "商家退款完毕",
                component: ReturnGoodsThree,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnGoods1",
                name: "买家申请退货退款",
                component: returnGoods1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnGoods2",
                name: "卖家处理退货申请",
                component: ReturnGoods2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnGoods3",
                name: "买家退货",
                component: ReturnGoods3,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "returnGoods4",
                name: "退款完毕",
                component: ReturnGoods4,
                meta: {
                    requireAuth: true
                }
            },
            // {
            //     path: "returnMoney1",
            //     name: "买家申请仅退款",
            //     component: ReturnMoney1,
            //     meta: {
            //         requireAuth: true
            //     }
            // },
            // {
            //     path: "returnMoney2",
            //     name: "卖家处理退款申请",
            //     component: ReturnMoney2,
            //     meta: {
            //         requireAuth: true
            //     }
            // },
            // {
            //     path: "ReturnMoney3",
            //     name: "退款完毕",
            //     component: ReturnMoney3,
            //     meta: {
            //         requireAuth: true
            //     }
            // },
        ],
    },

    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue")
    // }
];
(VueRouter as any).prototype.pushBlank = function (path) {
    const {href} = VueRouter.prototype.resolve({path});
    window.open(href, "_blank");
};
const router = new VueRouter({

    mode: "hash",
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
});
export default router;























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BankCard extends Vue {
  @Prop() private type!: string;
  @Prop() private num!: string;
}

<script lang="js">
    import Vue from "vue";
    import {LeaderboardBody, LeaderboardHeader} from "@/components/mallComponents";

    export default Vue.extend({
  components: { LeaderboardHeader, LeaderboardBody },
  methods: {
    setData(data) {
      this.$refs.LeaderboardBody.setData(data);
    },
  },
});
</script>
<template>
  <div id="Leaderboard">
    <div class="title" style="margin-bottom: 0.43rem">
      <img src="../../assets/images/mallHomeImg/paihangbang@2x.png" alt />
    </div>
    <LeaderboardHeader @setData="setData"></LeaderboardHeader>
    <LeaderboardBody ref="LeaderboardBody"></LeaderboardBody>
  </div>
</template>

<style lang="less" scoped>
#Leaderboard {
  width: 13rem;
  //   background-color: aqua;
  margin: auto;
  padding-top: 0.38rem;
  .title {
    text-align: center;
    img {
      width: 2rem;
    }
  }
}
</style>

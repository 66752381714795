<script>
    import {oAuthWxBindCode} from "@/services/api";

    export default {
  name: "wx-oauth",
  created() {
  },
  mounted() {
    // 打印微信登录结果
    let query = this.$route.query;
    if (query && query.code) {

        oAuthWxBindCode(query.code, query.state).then(res => {
        if (res && res.code == 0) {
          // 存储信息
          window.close()
        }
        // 关闭页面
      })
    }
  },
  methods: {

  }
}
</script>



























































import {Component, Vue} from "vue-property-decorator";
import {message, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import {diagnosisTreat, reservationCancelList} from "@/services/api";

Vue.use(Table);

@Component({
    components: {
        PersonalHeader,
    },
})
export default class extends Vue {
    columns = [
        {
            title: "预约单编号",
            dataIndex: "orderCode",
            width: "1.85rem",
            align: "center",
        },
        {
            title: "预约详情",
            dataIndex: "services",
            width: "2.25rem",
            align: "center",
        },
        {
            title: "提交时间",
            dataIndex: "cancelTime",
            width: "1.65rem",
            align: "center",
        },
        {
            title: "价格",
            dataIndex: "totalAmount",
            width: "1.4rem",
            align: "center",
            scopedSlots: {customRender: "totalAmount"},
        },
        {
            title: "取消原因",
            key: "cancelReason",
            dataIndex: "cancelReason",
            width: "1.55rem",
            align: "center",
        },
    ];
    columns1 = [
        {
            title: "预约单编号",
            dataIndex: "seriesNum",
            width: "1.7rem",
            align: "center",
        },
        {
            title: "预约详情",
            dataIndex: "serviceName",
            width: "2.25rem",
            align: "center",
        },
        {
            title: "服务商/机构名称",
            dataIndex: "orgName",
            width: "1.8rem",
            align: "center",
        },
        {
            title: "提交时间",
            dataIndex: "createTime",
            width: "1.5rem",
            align: "center",
        }, {
            title: "取消原因",
            key: "cancelResaon",
            dataIndex: "cancelResaon",
            width: "1.55rem",
            align: "center",
        },
    ];
    data: Array<any> = [];
    data1: Array<any> = [];

    async created() {
        await this.getServiceOrderRecord();
        await this.remoteReservationRecord();
    }

    // 获取服机构预约订单记录
    getServiceOrderRecord() {
        reservationCancelList({
            pageSize: 1000,
            pageIndex: 1,
        }).then((res: any) => {
            if (res && res.code === 0) {
                // 处理数据
                this.data = [];
                if (res.data.reservationList != []) {
                    res.data.reservationList.forEach((item: any, index: any) => {
                        let obj: any = {};
                        obj.orderCode = item.seriesCode;
                        obj.services = item.orgForm;
                        obj.cancelTime = item.createTime;
                        obj.totalAmount = item.price;
                        // obj.ifPay = item.ifPay;
                        obj.cancelReason = item.cancelReason;
                        this.data.push(obj);
                    });
                }
            } else {
                message.error(res.msg);
            }
        });
    }

    //获取诊疗预约订单记录
    remoteReservationRecord(){
        diagnosisTreat({
            limit: 5,
            page: 1,
            status:243,
        }).then((res: any) => {
            if (res && res.code === 0) {
                // 处理数据
                this.data = [];
                if (res.page.list != []) {
                    res.page.list.forEach((item: any, index: any) => {
                        let obj: any = {};
                        obj.seriesNum = item.seriesNum;
                        obj.serviceName = item.serviceName;
                        obj.orgName = item.orgName;
                        obj.createTime = item.createTime;
                        obj.cancelResaon = item.cancelResaon;
                        this.data1.push(obj);
                    });
                }
            } else {
                message.error(res.msg);
            }
        });
    }

}

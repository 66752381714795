



















































interface OrgLabels {
    labelDesc: string;
    labelId: string;
    labelName: string;
    orgId: string;
}

interface HotRecommond {
    addressArea: string;
    addressCity: string;
    addressProvince: string;
    addressStreet: string;
    areaSize: number;
    bedNum: number;
    coverPicture: string;
    createTime: string;
    detailAddress: string;
    fatherId: string;
    highestPrice: number;
    ifParentOrg: number;
    lowestPrice: number;
    orgAttitudeScore: number;
    orgCharacterName: string;
    orgEfficiencyScore: number;
    orgForm: string;
    orgId: string;
    orgIntro: string;
    orgLabels: OrgLabels[];
    orgOveralScore: number;
    orgRemark: string;
    orgSeries: string;
    orgServiceComplianceScore: number;
    orgTel: string;
    orgTypeName: string;
    serviceObject: string;
    specialService: string;
    storeName: string;
    updateTime: string;
}

import {Component, Vue} from "vue-property-decorator";
import {fetchHotInstitution} from "@/services/api";

@Component
export default class serviceProvider extends Vue {
    hotRecommend: HotRecommond[] = [];
    position: boolean = false;

    updated() {
        this.position = location.href.includes("servicePage");
    }

    getCityId() {
        return new Promise((resolve) => {
            const t1 = setInterval(() => {
                if (localStorage.getItem("provinceId")) {
                    resolve(localStorage.getItem("provinceId"));
                    clearInterval(t1);
                }
            }, 200);
            const t2 = setTimeout(() => {
                resolve("2");
                clearTimeout(t2);
                clearInterval(t1);
            }, 5000);
        });
    }

    async created() {
        const cityId: any = await this.getCityId();
        fetchHotInstitution({
            cityId,
            limit: 8,
        }).then((res) => {
            if (res.data && res.data.orgList && res.data.orgList.length) {
                res.data.orgList.forEach((item: HotRecommond) =>
                    this.hotRecommend.push(item)
                );
            }
        });
    }
}

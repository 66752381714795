<template>
  <div class="msg-box">
    <a-row>
      <a-icon v-if="success" type="check-circle" style="color: #67C23A;font-size: 150px"/>
      <a-icon v-else type="exclamation-circle" style="color: #ced05f;font-size: 150px"/>
    </a-row>
    <a-row style="margin-top: 0.5rem">
      <span>{{ message }}</span>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Mutation, State } from "vuex-class";
import Component from "vue-class-component";
import {
  message
} from "ant-design-vue";
import { Watch } from "vue-property-decorator";

@Component({})
export default class extends Vue {

  countDown = 5;
  success = false;
  msg = "";

  get message() {
    let res = this.success ? "授权成功！" : `授权失败，${this.msg}，请稍后再试。`
    return `${res}本页面将在 ${this.countDown} 秒后自动关闭`
  }

  created() {
    this.success = parseInt(this.$route.query.success)
    this.msg = parseInt(this.$route.query.msg)
    localStorage.setItem("ALIPAY_AUTHTOKEN_CALLBACK_SUCCESS", this.success) //这个跟另一个不一样
  }

  // 然后就是vue的生命周期
  mounted() {
    //倒计时
    let inter = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 1) {
        clearInterval(inter);
        window.location.href = "about:blank";
        window.close();
      }
    }, 1000);
  }
}
</script>

<style lang="less" scoped>
.msg-box {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.5rem;
}
</style>


















































































import {Component, Vue} from "vue-property-decorator";
import {Button, Table} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import DetailInfo from "@/components/Personal/serviceOrderForm/DetailInfo.vue";
import DetailPriceCard from "@/components/Personal/DetailPriceCard.vue";
import {serviceOrderDetailsDate} from "@/services/api";
import {baseUrl} from "../../../../services/http";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";

Vue.use(Table);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        MyStepBar,
        DetailInfo,
        DetailPriceCard,
        Breadcrumb,
    }
})
export default class extends Vue {
    baseUrl = baseUrl
    // 5是取消 6 交易关闭
    currentStatus: any = ''; // 根据状态动态调整currentStatus的值
    status: any = ''; //商品订单状态
    orderId = "";
    statusList: any = [];
    dataList = []
    tableData: Array<any> = [];
    logisticsList = [];
    statusBar: 1
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "服务订购单",
            path: "/personal/serviceOrderList",
            significance: 0
        },
        {
            name: "订单详情",
            path: "/personal/serviceOrderDetail",
            significance: 1
        },
    ]

    mounted() {
        const str1: any = localStorage.getItem("CURDETAILSTATUS");
        this.orderId = JSON.parse(str1)
        this.lookDetail()

    }

    lookDetail() {
        serviceOrderDetailsDate({orderId: this.orderId}).then(res => {
            if (res) {
                let data: any = (res as any).info || {};
                this.dataList = data
                this.statusBar = data.orderStatusList.length
                const dataList = {
                    servicePrice: data.servicePrice,
                    serviceCount: data.serviceCount,
                    purchaseQuantity: 0,
                    serviceFee: data.serviceFee,
                    service: {
                        serviceName: data.serviceName,
                        servicePic: data.servicePic,
                    }
                }
                this.tableData.push(dataList)

                this.changeStatus(data.orderStatusList);
            }
        });
    }

    //已发货和已完成状态下获取物流信息

    //判断顶部状态和显示时间
    changeStatus(data) {
        switch (data.length) {
            case 1: //已订购
                this.currentStatus = 1;
                this.stepData[0].time = data[0].orderStatusTime
                break;
            case 2: //等待服务
                this.currentStatus = 2;
                this.stepData[0].time = data[0].orderStatusTime
                this.stepData[1].time = data[1].orderStatusTime
                break;
            case 3: //服务进行中
                this.currentStatus = 3;
                this.stepData[0].time = data[0].orderStatusTime
                this.stepData[1].time = data[1].orderStatusTime
                this.stepData[2].time = data[2].orderStatusTime
                break;
            case 4:   //服务完成
                this.currentStatus = 4;
                this.stepData[0].time = data[0].orderStatusTime
                this.stepData[1].time = data[1].orderStatusTime
                this.stepData[2].time = data[2].orderStatusTime
                this.stepData[3].time = data[3].orderStatusTime
                break;
        }

    }


    stepData = [
        {
            name: "已订购",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "等待服务",
            time: "",
            img: require('@/assets/images/homeImg/flow-2.png'),
        },
        {
            name: "服务进行中",
            time: "",
            img: require('@/assets/images/homeImg/flow-3.png'),
        },
        {
            name: "服务完成",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        }
    ];

    columns = [
        {
            title: "服务名称",
            dataIndex: "service",
            key: "service",
            align: "center",
            width: "3.3rem",
            scopedSlots: {customRender: "service"}
        },
        {
            title: "单价",
            dataIndex: "servicePrice",
            key: "servicePrice",
            align: "center"
        },
        {
            title: "数量",
            dataIndex: "serviceCount",
            key: "serviceCount",
            align: "center"
        },
        {
            title: "优惠金额",
            dataIndex: "purchaseQuantity",
            key: "purchaseQuantity",
            align: "center",
            width: "1.9rem",
        },
        {
            title: "服务费",
            dataIndex: "serviceFee",
            key: "serviceFee",
            align: "center"
        },
    ];
}

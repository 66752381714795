



    import {Component, Vue} from "vue-property-decorator";
    import {http} from "@/services/http";

    @Component({})
export default class LeaderboardHeader extends Vue {
  dataArr = [
    {
      name: "日常生活用品",
      id: "4",
    },
    {
      name: "养生保健用品",
      id: "34",
    },
    {
      name: "护理用品",
      id: "139",
    },
    {
      name: "康复用品",
      id: "50",
    },
    {
      name: "娱乐用品",
      id: "68",
    },
    {
      name: "维修服务产品",
      id: "132",
    },
    {
      name: "文娱服务产品",
      id: "112",
    },
    {
      name: "代步用品",
      id: "78",
    },
    {
      name: "旅游装备用品",
      id: "82",
    },
    {
      name: "老年咨询服务产品",
      id: "121",
    },
    {
      name: "健康管理类可穿戴设备",
      id: "88",
    },
    {
      name: "便携式健康监测设备",
      id: "92",
    },
    {
      name: "自助式健康检测设备",
      id: "101",
    },
    {
      name: "智能养老监护设备",
      id: "104",
    },
    {
      name: "家务服务产品",
      id: "126",
    },
    {
      name: "全部分类",
    },
  ];

  hoverIndex = -1;
  selectIndex = 0;
  getIconType(index: number) {
    if (this.hoverIndex == index) {
      return "bai";
    } else if (this.selectIndex == index) {
      return "lv";
    } else {
      return "hei";
    }
  }
  ba: any = [];
  ifHideMenu = false;
  showHideMenu(i: number) {
    if (i == 15) this.ifHideMenu = true;
  }
  hideHideMenu(i: number) {
    if (i == 15) this.ifHideMenu = false;
  }
  menuLevel2 = [];
  menuLevel3Show = [];
  mounted() {
    let menuLevel:any=localStorage.getItem("menuLevel2")
    this.menuLevel2 = JSON.parse(menuLevel);
  }
  setLevel3(arr) {
    this.menuLevel3Show = arr;
  }
  created() {
    this.getData();
  }
  condition:any = {
    pageSize: 999,
    pageIndex: 1,
    typeId: "",
  };
  getData() {
    this.condition.typeId = this.dataArr[this.selectIndex].id;

    http
      .get(`/api/v1/commodities/hot`, { params: this.condition })
      .then((res: any) => {
        this.$emit("setData", res.data);
      });
  }

  getAll(id) {
    this.selectIndex = -1;
    this.condition.typeId = id;
    http
      .get(`/api/v1/commodities/hot`, { params: this.condition })
      .then((res: any) => {
        this.$emit("setData", res.data);
        this.ifHideMenu = false;
      });
  }
}

<script>
import EmojiSelector from './emoji-selector'

export default {
  components: {
    EmojiSelector
  },
  props: {
    emojiList: {
      type: Array,
      default: function (){return []}
    }
  },
  data () {
    return {
      svgSrc: {
        emoji: require('../svg/emoji.svg'),
        img: require('../svg/img.svg'),
        fav: require('../svg/fav.svg'),
      },
      emojiSelectorVisible: false,
      content: ''
    };
  },
  methods: {
    onKeyup (e) {
      if (e.ctrlKey && e.keyCode === 13 && this.content.length) {
        this.send();
      }
    },
    send() {
      if(this.content.trim() == '') return
      this.$emit('send-message', this.content)
      this.content = '';
    },
    insertEmoji(emoji) {
      this.content += emoji
    },
    imgSelectHandler() {
      this.$refs.hiddenInputFile.dispatchEvent(new MouseEvent('click'))
    },
    uploadImg(event) {
      //提交上传文件列表给上级组件处理(chat)
      this.$emit('upload',event.target.files);
    }
  }
};
</script>

<template>
  <div class="text">
    <p class="icon">
      <emoji-selector v-model="emojiSelectorVisible" :emoji-list="emojiList" @commit="insertEmoji"></emoji-selector>
      <img :src="svgSrc.emoji" @click="emojiSelectorVisible = !emojiSelectorVisible">
      <input type="file" accept="image/png,image/jpeg,image/bmp" @change="uploadImg"
             v-show="false" ref="hiddenInputFile"/>
      <img :src="svgSrc.img" @click="imgSelectHandler">
      <img :src="svgSrc.fav">
    </p>
    <textarea placeholder="按 Ctrl + Enter 发送" v-model="content" @keyup="onKeyup"></textarea>
    <footer>
      <button @click="send">发送</button>
    </footer>
  </div>
</template>

<style lang="less" scoped>
.text {
  background: #FFFFFF;
  border-top: 1px solid #E4E9F2;

  .icon {
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 21px;

    img {
      height: 21px;
      width: 21px;
      margin-left: 10px;
      transition: all .15s;
      border: 0px solid #ffffff;
      border-radius: 4px;
      &:hover {
        background-color: #EEF3FA;
      }
      &:active {
        border: 1px solid #D7F2F9;
        background-color: #D7F2F9;
      }
    }
  }
  textarea {
    padding: 0 10px;
    height: 50px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: "Micrsofot Yahei";
    resize: none;
  }
  footer {
    text-align: right;
    vertical-align: center;
    button {
      width: 86px;
      height: 30px;
      background-color: #ffffff;
      border: 1px solid #5FC6C5;
      border-radius: 15px;
      margin-right: 10px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 16px;
      color: #39BEB8;
      transition: all .15s;
      &:hover {
        background: #EEF3FA;
      }
      &:active {
        background: #D7F2F9;
      }
    }
  }
}
</style>

<template>
  <div>
    <Header />
    <Nav />
    <div class="businessJoinStep3">
      <div style="width: 10rem; margin:0 auto;marginTop: -0.4rem;">
        <ReturnStep :curStep="2" :stepList="stepList" />
      </div>
      <div class="form-box">
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item v-bind="formItemLayout" label="商家账号">
            <a-input
              style="width: 4rem;"
              placeholder="商家账号，用于登录商家管理平台"
              v-decorator="[
                'merAccount',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写商家账号！'
                    },
                    {
                      validator: this.checkExist.bind(this)
                    }
                  ],
                  initialValue: formData.shopInfo.merchantAccount
                }
              ]"
            />
            <p
              style="margin:0;marginBottom:-0.15rem;color: #ED6666;fontSize: 0.14rem;"
            >
              此帐号为日后登录并管理商家中心时使用，注册后不可修改，请牢记。默认密码123456
            </p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="店铺名称">
            <a-input
              style="width: 4rem;"
              placeholder="请填写店铺名称"
              v-decorator="[
                'ceocj',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写店铺名称！'
                    },
                    {
                      validator: this.checkShopNameExist.bind(this)
                    }
                  ],
                  initialValue: formData.shopInfo.storeName
                }
              ]"
            />
            <p
              style="margin:0;marginBottom:-0.15rem;color: #ED6666;fontSize: 0.14rem;"
            >
              店铺名称注册后不可修改，请认真填写
            </p>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="店铺封面">
            <a-upload
              name="file"
              :customRequest="uploadCoverAll"
              :remove="uploadCoverRemove"
              @preview="handlePicturePreview"
              @change="handleChange"
              :file-list="uploadCoverFileList"
              list-type="picture-card"
              v-decorator="[
                'coverImage',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.uploadCoverFileCheck.bind(this)
                    }
                  ]
                }
              ]"
            >
              <div v-if="uploadCoverFileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传封面</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="店铺简介">
            <a-textarea
              style="width: 4rem;"
              :rows="6"
              placeholder="请输入店铺简介"
              v-decorator="[
                'summary',
                {
                  rules: [
                    {
                      required: true,
                      message: '店铺简介不能为空'
                    }
                  ],
                  initialValue: formData.expansionInfo.merchantIntro
                }
              ]"
            />
          </a-form-item>

          <a-form-item v-bind="formItemLayout" label="店铺地址">
            <a-cascader
              :options="optionsaddr"
              style="width: 4rem;"
              placeholder="请选择店铺地址"
              :loadData="addrLoadData"
              v-decorator="[
                'shopaddress',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择公司地址！'
                    }
                  ]
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="详细地址">
            <a-input
              style="width: 4rem;"
              placeholder="请输入详细地址"
              v-decorator="[
                'shopDetailAddress',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写详细地址！'
                    }
                  ],
                  initialValue: formData.shopInfo.detailAddress
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="店铺电话">
            <a-input
              style="width: 4rem;"
              placeholder="请输入店铺电话"
              v-decorator="[
                'shopphone',
                {
                  rules: [
                    {
                      required: true,
                      message: '请填写店铺电话！'
                    },
                    {
                      validator: this.phoneCheck.bind(this)
                    }
                  ],
                  initialValue: formData.shopInfo.merchantTel
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="经营类目">
            <div v-for="item in list" :key="item">
              <a-cascader
                :options="options"
                :loadData="loadData"
                style="width: 4rem;"
                placeholder="请选择经营类目"
                @change="value => onChange(value, `refr${item}`)"
                v-decorator="[
                  `refr${item}`,
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择经营类目！'
                      }
                    ]
                  }
                ]"
              />
              <a-icon
                v-if="item > 1"
                class="dynamic-delete-button"
                style="marginLeft:5px;"
                type="minus-circle-o"
                @click="() => remove(item)"
              />
            </div>
            <span
              style="color: #60C7C6; fontSize: 0.14rem;cursor: pointer;"
              @click="add"
              ><a-icon
                type="plus-circle"
                style="marginRight: 5px;"
              />新增经营类目</span
            >
          </a-form-item>
          <a-form-item v-bind="formItemLayout" label="备注">
            <a-textarea
              style="width: 4rem;"
              :rows="2"
              v-decorator="[
                'shoreRemark',
                {
                  initialValue: formData.shopInfo.shoreRemark
                }
              ]"
            />
          </a-form-item>
          <a-form-item v-bind="tailFormItemLayout" style="margin: 1rem 0;">
            <a-button
              type="primary"
              style="width: 2rem;height:0.44rem;marginRight:1rem; background: #fff;color: #60c7c6"
              @click="$router.back()"
              >上一步
            </a-button>
            <a-button
              v-if="buttonSet.enableClick"
              :loading="buttonSet.loading"
              type="primary"
              html-type="submit"
              style="width: 2rem;height:0.44rem;"
              >提交审核
            </a-button>
            <a-button
              v-else
              :loading="buttonSet.loading"
              type="primary"
              disabled
              style="width: 2rem;height:0.44rem;"
              >提交审核
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <Footer />
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import {Button, Cascader, DatePicker, Form, Icon, Input, message, Select, Upload} from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import {
        checkShopNameExist,
        checkUserNameExist,
        fetchAddrCascader,
        fetchMerCategories,
        fetchMerchantAdd,
        uploadServiceFile
    } from "@/services/api";

    Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Upload);
Vue.use(Icon);
Vue.use(DatePicker);
Vue.use(Cascader);

export default {
  components: {
    Nav,
    Footer,
    Header,
    ReturnStep
  },
  data() {
    return {
      previewVisible: false,
      imageUrl: "",
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 9 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 }
        }
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 15,
            offset: 9
          }
        }
      },
      stepList: [
        {
          name: "入驻协议"
        },
        {
          name: "主体信息"
        },
        {
          name: "店铺信息"
        }
        // {
        //   name: "扩展信息"
        // }
      ],
      list: [1],
      uploadCoverFileList: [],
      selectCategory: [],
      options: [], // 经营类目
      optionsaddr: [], // 经营类目
      formData: {
        merchantInfo: {
          // 公司信息
          merchantName: "", // 公司名称
          merchantCode: "", // 公司组织机构代码
          addressDetail: "", // 公司详细地址
          merchantTel: "", // 公司电话
          addressProvince: "", // 公司地址（省、直辖市、自治区等）
          addressCity: "", // 公司地址（市）
          addressArea: "", // 公司地址（区、县）
          addressStreet: "" // 公司地址（街道、乡）
        },
        contactAccountInfo: {
          // 联系人及账户信息
          lpName: "", // 法人名称
          lpIdNum: "", // 法人身份证编号
          lpTel: "", // 法人联系方式
          accountName: "", // 银行开户名
          accountNumber: "", // 银行开户账号
          bankName: "", // 开户银行名称
          accountDetailAdd: "", // 开户详细地址
          businessLicensePicture: "", // 营业执照照片
          accountProvince: "",
          accountCity: "",
          accountArea: "",
          accountStreet: "",
          lpIdPicturePositive: "", // 法人身份证照片正面
          lpPictureNegative: "", // 法人身份证照片反面
          emergencyContactName: "", // 紧急联系人姓名
          emergencyContactPhone: "" // 紧急联系人-联系方式
        },
        administratorInfo: {
          // 管理员信息
          adminName: "", // 管理员姓名
          adminIDNum: "", // 管理员身份证编号
          adminTel: "" // 管理员手机号
        },
        expansionInfo: {
          // 扩展信息
          coverPicture: "", // 店铺封面
          createTime: "", // 店铺创建时间
          registeredCapital: "", //注册资本
          industryInvolved: 0, // 所属行业
          merchantIntro: "", // 公司简介
          merchantRemark: "" // 备注
        },
        shopInfo: {
          // 店铺信息
          merchantAccount: "", // 商家账号
          storeName: "", // 店铺名称
          addressProvince: "", // 店铺地址
          addressCity: "", // 店铺地址
          addressArea: "", // 店铺地址
          addressStreet: "", // 店铺地址
          detailAddress: "", // 店铺地址
          merchantTel: "", // 店铺电话
          categoryList: [], // 经营类目
          shoreRemark: "" // 店铺备注
        },
        paymentInfo: {
          // 后端存储，前端不存储
          alipayId: "",
          alipayNickname: "",
          alipayRealName: "",
          wechatId: ""
        },
        uploads: {
          // 上传图片由于某些数据的原因需要暂存
          businessLicensePictureUrl: "", // 营业执照暂存
          lpIdPicturePositiveUrl: "", // 身份证正面照片暂存
          lpIdPictureNegativeUrl: "", // 身份证反面照片暂存
          coverImage: "" // 封面照暂存
        }
      },
      buttonSet: {
        loading: false,
        enableClick: false
      },
      ifImageCanUpload: true
    };
  },
  beforeCreate() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.form = this.$form.createForm(this);
    } else {
      this.$router.push("/login");
    }
  },
  mounted() {
    let item = localStorage.getItem("merchantJoinForm");
    if (item) {
      let data = JSON.parse(item);
      this.formData = data;
    }
    this.init();
    this.$nextTick(() => {
      this.init();
      this.addrInit();
      this.buttonSet.enableClick = true;
    });
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    uploadCoverAll(options) {
      let t = setTimeout(() => {
        clearTimeout(t);
        this.uploadCover(options);
      }, 1000);
    },
    // 上传封面
    uploadCover(options) {
      if (!this.ifImageCanUpload) return;
      let formData = new FormData();
      formData.append("file", options.file);
      formData.append("typeId", 7);
      let p = {
        percent: 0
      };
      uploadServiceFile(formData, ({ total, loaded }) => {
        p.percent = +Math.round((loaded / total) * 100).toFixed(2);
        options.onProgress(p);
        if (p.percent >= 100) {
          options.onProgress({ percent: 99.3 });
        }
      })
        .then(data => {
          if (data && data.code == 0) {
            // message.success("上传成功");
            options.onSuccess();

            // this.uploadCoverFileList.push({
            //   uid: "coverImage",
            //   name: "coverImage.png",
            //   status: "done",
            //   url:
            //     data.filePath.indexOf("/fileupload") == 0
            //       ? `${baseUrl}${data.filePath}`
            //       : data.filePath
            // });
            this.formData.expansionInfo.coverPicture = data.filePath;
            // this.form.setFieldsValue({ coverImage: data.filePath });
            // file.onSuccess();
          } else {
            message.error(data.msg);
            this.uploadCoverFileList.pop();
            // file.onError(); //上传失败
          }
        })
        .catch(error => {
          message.error("上传失败");
          this.uploadCoverFileList.pop();
          this.form.validateFields(["coverImage"], errors => {
          });
        });
    },

    uploadCoverFileCheck(rule, value, callback) {
      if (this.formData.expansionInfo.coverPicture) {
        callback();
        return;
      }
      if (!value || value.fileList.length == 0) {
        callback("请上传封面图片");
        return;
      }
      if (value.file.size > 1000 * 1000 * 1) {
        this.ifImageCanUpload = false;
        value.fileList.length = 0;
        this.form.setFieldsValue({ coverImage: "" });
        message.error("上传失败，请控制图片在1M之内");
        callback();
        return;
      } else {
        this.ifImageCanUpload = true;
        callback();
      }

      // if (this.formData.expansionInfo.coverImage) {
      //   callback();
      // } else {
      //   callback(new Error("请上传店铺封面！"));
      // }
    },

    handleChange(info) {
      const t = setTimeout(() => {
        clearTimeout(t);
        if (this.ifImageCanUpload) this.uploadCoverFileList = info.fileList;
      }, 800);
    },

    async handlePicturePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    uploadCoverRemove(file) {
      this.uploadCoverFileList = [];
      this.formData.expansionInfo.coverPicture = "";
    },

    /**
     * 获取表单数据
     * @param name
     */
    getFormValue(name) {
      return this.form.getFieldValue(name);
    },
    /**
     * 检查商家账号是否已存在
     * @param rule
     * @param value
     * @param callback
     */
    checkExist(rule, value, callback) {
      if (value) {
        const reg2 = /^[0-9]+$/;
        if (reg2.test(value)) {
          callback("账号不能为纯数字，请重新输入");
          return;
        }
        // if (/^[0-9].*/.test(value)) {
        //     callback(new Error("账号不能以数字开头"))
        //     return
        // }

        checkUserNameExist(value)
          .then(res => {
            if (res && res.code == 0) {
              callback();
            } else {
              callback(res.msg);
            }
          })
          .catch(e => {
            callback(e);
          });
      }
      callback();
    },
    /**
     * 检查商家店铺名称是否已存在
     * @param rule
     * @param value
     * @param callback
     */
    checkShopNameExist(rule, value, callback) {
      if (value) {
        checkShopNameExist(value)
          .then(res => {
            if (res && res.code == 0) {
              callback();
            } else {
              callback(res.msg);
            }
          })
          .catch(e => {
            callback(e);
          });
      }
      callback();
    },
    /**
     * 提交
     * @param e
     */
    handleSubmit(e) {
      e.preventDefault();
      this.buttonSet.enableClick = false;
      this.buttonSet.loading = true;

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          // 获取地址
          let shopAddress = this.getFormValue("shopaddress");
          // 判断地址选择
          if (shopAddress.length < 3 || shopAddress.length > 4) {
            message.error("请选择正确的店铺地址");
            return;
          }
          //商家账号
          this.formData.shopInfo.merchantAccount = this.getFormValue(
            "merAccount"
          );
          // 店铺名称
          this.formData.shopInfo.storeName = this.getFormValue("ceocj");
          // 店铺地址
          this.formData.shopInfo.addressProvince = shopAddress[0];
          this.formData.shopInfo.addressCity = shopAddress[1];
          this.formData.shopInfo.addressArea = shopAddress[2];
          this.formData.shopInfo.addressStreet = shopAddress[3];
          this.formData.shopInfo.detailAddress = this.getFormValue(
            "shopDetailAddress"
          );

          this.formData.shopInfo.merchantTel = this.getFormValue("shopphone");
          this.formData.shopInfo.shoreRemark = this.getFormValue("shoreRemark");

          // 经营类目，去掉重复
          this.formData.shopInfo.categoryList = [];
          for (let key in this.selectCategory) {
            let category = this.selectCategory[key];
            if (this.formData.shopInfo.categoryList.indexOf(category) == -1) {
              this.formData.shopInfo.categoryList.push(category);
            }
          }

          localStorage.setItem(
            "merchantJoinForm",
            JSON.stringify(this.formData)
          );

          fetchMerchantAdd(this.formData).then(res => {
            this.buttonSet.loading = false;
            this.buttonSet.enableClick = true;
            if (res && res.code == 0) {
              message.success("提交成功，等待审核");
              localStorage.removeItem("merchantJoinForm");
              this.$router.push("/businessJoinStep4");
            } else {
              message.error(res.msg);
              this.buttonSet.enableClick = true;
              this.buttonSet.loading = false;
            }
          });
        } else {
          this.buttonSet.enableClick = true;
          this.buttonSet.loading = false;
        }
      });
    },
    /**
     * 经营类目修改事件
     * @param value
     * @param key
     */
    onChange(value, key) {
      // 判断重复问题
      this.list.forEach(i => {
        const name = `refr${i}`;
        if (name != key) {
          if (value[value.length - 1] == this.selectCategory[name]) {
            message.error("此类目已选，相同类目将不会被记录");
            return;
          }
        }
      });
      this.selectCategory[key] = value[value.length - 1];
    },
    /**
     * 校验电话号码
     * @param rule
     * @param value
     * @param callback
     */
    phoneCheck(rule, value, callback) {
      const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
      if (!reg.test(value)) {
        callback("请输入正确格式电话,如：021-87888822");
        return;
      }
      callback();
    },
    /**
     * 新增经营类目
     */
    add() {
      this.list.push(this.list[this.list.length - 1] + 1);
    },
    /**
     * 移除经营类目
     * @param k
     */
    remove(k) {
      // 移除list中的本数据
      const index = this.list.findIndex(item => `refr${item["index"]}` === k);
      this.list.splice(index, 1);
      delete this.selectCategory[`refr${k}`];
    },
    /**
     * 懒加载经营类目
     * @param place
     */
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      const { value } = targetOption;
      const { level } = targetOption;
      targetOption.loading = true;
      fetchMerCategories({
        level: level + 1,
        parentId: value
      }).then(data => {
        targetOption.loading = false;
        if (data && data.code == 0) {
          const list = data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.label,
                value: i.value,
                isLeaf: level >= 2,
                level: level + 1
              });
            });
          }
          targetOption.loading = false;
          targetOption.children = children;
          this.options = [...this.options];
        } else {
          message.error(data.msg);
        }
      });
    },
    /**
     * 初始化经营类目
     * @param place
     */
    init(place) {
      fetchMerCategories({
        level: 1,
        parentId: ""
      }).then(data => {
        if (data && data.code == 0) {
          const list = data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.label,
                value: i.value,
                level: 1,
                isLeaf: false
              });
            });
          }
          this.options = children;
        } else {
          message.error(data.msg);
        }
      });
    },
    /**
     * 获取地址信息
     * @param selectedOptions
     */
    addrLoadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      const { value } = targetOption;
      const { level } = targetOption;
      targetOption.loading = true;
      fetchAddrCascader({
        areaType: level,
        areaId: value
      }).then(data => {
        targetOption.loading = false;
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.name,
                value: i.id,
                isLeaf: i.childNum == 0,
                level: level + 1
              });
            });
          }
          targetOption.loading = false;
          targetOption.children = children;
          this.optionsaddr = [...this.optionsaddr];
        } else {
          message.error(data.msg);
        }
      });
    },
    /**
     * 初始化地址
     * @param place
     */
    addrInit(place) {
      fetchAddrCascader({
        areaType: 0,
        areaId: ""
      }).then(data => {
        if (data && data.code == 0) {
          const list = data.data.list;
          let children = [];
          if (list) {
            list.forEach(i => {
              children.push({
                label: i.name,
                value: i.id,
                level: 1,
                isLeaf: false
              });
            });
          }
          this.optionsaddr = children;
        } else {
          message.error(data.msg);
        }
      });
    }
  }
};
</script>

<style lang="less">
.businessJoinStep3 {
  .form-box {
    padding: 0.3rem 0;

    .form-title {
      border-top: 1px solid #f2f2f2;
      padding-top: 0.26rem;
      padding-bottom: 0.2rem;
      width: 10.66rem;
      margin: 0 auto;
    }

    .ant-form-item {
      margin-bottom: 0.2rem;
    }

    .ant-input:hover {
      border-color: #60c7c6;
    }

    .ant-select-selection:hover {
      border-color: #60c7c6;
    }

    .ant-form-item-label label {
      color: #737373;
      font-size: 0.16rem;
    }

    .ant-btn-primary {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      background-color: #60c7c6;
      border-color: #60c7c6;
    }
  }
}
</style>














































    import {Component, Vue} from "vue-property-decorator";
    import {Input, message, Modal, Rate, Select, Table, Tabs,} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import serverDiscuss from "@/components/Personal/MyComment/serverDiscuss.vue";
    import {deleteServiceComment, fetchServiceComment,} from "@/services/api";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(Table);
Vue.use(Select);
Vue.use(Rate);
Vue.use(Modal);

@Component({
  components: {
    PersonalHeader,
    serverDiscuss,
  },
})
export default class extends Vue {
  // 取消订单Modal显隐
  cancelVisible = false;
  curType = "全部";
  serveComplete=false;
  serveCompleteData=''

  topLis = [
    {
      title: "服务信息",
      width: "4rem"

    },
    {
      title: "评价得分",
      width: "3rem"
    },
    {
      title: "评价时间",
      width: "3rem"
    },
    {
      title: "操作",
      width: "2.5rem"
    }
  ];
  checkDetail(item) {
    // ifServiceOrder 2代表订购类
    if(item.ifServiceOrder===2){
      let { href } = this.$router.resolve({
        path: "/personal/serviceOrdersJudge",
        query: {
          commentId: item.commentId,
        },
      });
      open(href);
    }else{
      let { href } = this.$router.resolve({
        path: "/personal/judgeService",
        query: {
          commentId: item.commentId,
        },
      });
      open(href);
    }


  }
  data2: Array<any> = [];

  pagination = {
    total: 0,
    pageSize: 10,
    pageIndex: 1,
    queryStatus: 2
  };

  async created() {
    await this.getServiceComment();
  }
  eventbsakd(key) {
    if (key == 1) this.$router.push("/personal/serveComment");
    if (key == 2) this.$router.push("/personal/shopComment");
    if (key == 3) this.$router.push("/personal/yuyveComment");
    if (key == 4) this.$router.push("/personal/diagnosisTreatComment");
  }
  // 获取服务评价
  getServiceComment() {
    fetchServiceComment({
     ...this.pagination
    }).then((res: any) => {
      if (res && res.code === 0) {
        this.data2 = [];
        this.pagination.total = res.data.totalCount;
        this.pagination.pageSize = res.data.pageSize;
        // 处理数据
        res.data.comments.forEach((item: any, index: any) => {
          let obj: any = {};
          obj.information = {
            name: item.serviceName,
            img: item.pic,
          };
          obj.content = item.content;
          obj.rating = item.rating;
          obj.time = item.createTime;
          obj.orderCode = item.orderCode;

          obj.ids = {
            serviceId: item.serviceId,
            commentId: item.commentId,
            ifServiceOrder:item.ifServiceOrder,
            ...item,
          };

          this.data2.push(obj);
        });
      } else {
        message.error(res.msg);
      }
    });
  }
  // 点击删除服务评价
  handleDelServiceComment(value: any) {
    this.serveComplete=true
    this.serveCompleteData=value
  }
  //点击确认删除
  serviceOrderConfirm(){
        deleteServiceComment(this.serveCompleteData).then((res: any) => {
          if (res && res.code === 0) {
            // 处理数据
            message.success("删除成功！");
            this.getServiceComment();
          } else {
            message.error(res.msg);
          }
        }).then(_ => {
          this.serveComplete = false
        });
  }
}














































import {Component, Vue} from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import castAbout from "../../views/creditInvestigation/components/castAbout.vue";
import comprehensiveOutcome from "./search/comprehensiveOutcome.vue";
import nursingInstitutionForTheAged from "./search/nursingInstitutionForTheAged.vue";
import serviceProvider from "./search/serviceProvider.vue";
import commercialTenant from "./search/commercialTenant.vue";
import consumer from "./search/consumer.vue";
import {comprehensiveResult, comprehensiveSearch} from "@/services/api";
import {serviceItems,} from "@/components/Recommended";

@Component({
    components: {
        Nav,
        Footer,
        Header,
        comprehensiveOutcome,
        nursingInstitutionForTheAged,
        serviceProvider,
        commercialTenant,
        serviceItems,
        consumer,
        castAbout
    },
})
export default class extends Vue {
    currentTab: any = "1";
    searchContent = {};
    //查询分页
    pagination: any = {
        pageIndex: 1,
        pageSize: 10,
        total:0,
        queryType:''
    }
    //服务商
    // serviceProviderList = [];
    //服务
    // serviceList = [];
    //养老机构
    // organizationList = [];
    //商家
    // merchantList = [];
    orgList: any = {};
    // recordList: any = [];

    mounted() {
        const acKEY = sessionStorage.getItem("acKEY");
        if (acKEY != null) {
            this.currentTab = Number(acKEY);
        } else {
            // this.change(-1);
        }
        this.$nextTick().then(() => {
            let m: any = document.querySelectorAll(".ant-tabs-tab");
            const acKEY = sessionStorage.getItem("acKEY");
            if (acKEY != null) {
                // 创建一个鼠标事件
                var e = document.createEvent('MouseEvent');
                // 事件初始化绑定一个点击
                e.initEvent('click', false, false);
                // 获取到第二个待付款元素
                const el = m[Number(acKEY)];
                // 分发事件
                el.dispatchEvent(e)
                sessionStorage.removeItem("acKEY");
            }
        });
        this.currentTab = this.$route.query.moreType
            ? +this.$route.query.moreType + 1 + ""
            : "1";
    }

    //我们离开这个页面的时候清空input中内容
    destroyed() {
        // sessionStorage.setItem("creditSearch", "");
    }


    // serviceProjectList: any[] = [];
    // orgList: any[] = [];
    // getOrganizationList: any[] = [];
    serviceClassificationId: any = "";


    async created() {
        const value = sessionStorage.getItem("creditSearch") || "";
        const obj = JSON.parse(value);
        if (sessionStorage.getItem("serviceClassificationIdsasadd")) {
            this.handleSearch(obj)
        } else {
            this.getSearchInput(obj)
        }
    }
    //分页
    changePage(value) {
        switch (this.currentTab) {
            case "2": {
                this.pagination={
                    pageIndex: value,
                    pageSize: 10,
                    queryType: 1,
                }
                this.overallQuery(this.searchContent)
                break;
            }
            case "3": {
                this.pagination={
                    pageIndex: value,
                    pageSize: 10,
                    queryType: 0,
                }
                this.overallQuery(this.searchContent)
                break;
            }
            case "4": {
                this.pagination={
                    pageIndex: value,
                    pageSize: 10,
                    queryType: 2,
                }
                this.overallQuery(this.searchContent)
                break;
            }
        }

    }
    //切换数据
    callback(value: any) {
        switch (value) {
            case "1": {
                this.handleSearch(this.searchContent)
                break;
            }
            case "2": {
                this.pagination.queryType=1
                this.overallQuery(this.searchContent)
                break;
            }
            case "3": {
                this.pagination.queryType=0
                this.overallQuery(this.searchContent)
                break;
            }
            case "4": {
                this.pagination.queryType=2
                this.overallQuery(this.searchContent)
                break;
            }
        }
    }

    async getSearchInput(value: any) {
        this.searchContent = value;
        await this.faSongQuery(this.searchContent);
    }

    async faSongQuery(value: any) {
        switch (this.currentTab) {
            case "1": {
                this.handleSearch(value)
                break;
            }
            case "2": {
                this.pagination.queryType=1
                this.overallQuery(value)
                break;
            }
            case "3": {
                this.pagination.queryType=0
                this.overallQuery(value)
                break;
            }
            case "4": {
                this.pagination.queryType=2
                this.overallQuery(value)
                break;
            }
        }
    }

    //查询获取综合结果数据
    handleSearch(params: any) {
        this.orgList.length = 0;
        // {"choice":params.choice,"value":params.value}
        comprehensiveResult(
            {
                choice: params.choice,
                value: params.value,
            }).then((res) => {
            if (res.code === 0) {
                this.orgList = res.data
            }
        });
    }

    //养老机构、服务商、商户查询
    overallQuery(value) {
        comprehensiveSearch(
            {
                choice: value.choice,
                value: value.value,
                pageIndex:this.pagination.pageIndex,
                pageSize:this.pagination.pageSize,
                queryType:this.pagination.queryType,
            }).then((res) => {
            if (res.code === 0) {
                let data:any = res.data.recordCount
                this.orgList = res.data;
                this.pagination.pageIndex= res.data.currentPage;
                this.pagination.pageSize= res.data.pageSize;
                this.pagination.total= data;
                // this.pagination={
                //     pageIndex: res.data.currentPage,
                //     pageSize: res.data.pageSize,
                //     total: res.data.recordCount,
                // }
            }
        });
    }
}













    import {Component, Prop, Vue,} from "vue-property-decorator";
    import ProductsItem from "./ProductsItem.vue";

    @Component({
  components: {
    ProductsItem,
  },
})
export default class shopProduction extends Vue {
  @Prop() data: any;
  goTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

  }
}

<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view :key="$route.fullPath"/>
        </div>
    </a-config-provider>
</template>

<script>
    import {Vue} from "vue-property-decorator";
    import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {LocaleProvider} from "ant-design-vue";
    import VModal from "vue-js-modal";
    import {baseUrl, request} from "@/services/http";


    Vue.use(LocaleProvider);
    Vue.use(VModal);


    export default {
        name: "app",
        data() {
            return {
                locale: zhCN,
                path: "ws://localhost:8084/api/v1/socket",
                socket: {}
            };
        },

        mounted() {
            this.$router.afterEach((to, from) => {
                if (to.path == "/serviceOrder") {
                    localStorage.setItem("laterPath", from.fullPath);
                }

                if (to.path == "/login") {
                    localStorage.setItem("loginBeforePath", from.fullPath);
                }
            });
            this.$router.beforeEach((to, from, next) => {
                const newTabArr = [
                    "/serviceOrder",
                    "/purchaseOrder",
                    "/personal/AskBuyAfterService",
                    "/personal/askBuyAfterService",
                    "/personal/classOpenServiceOne1",
                    // "/personal/returnService2",
                    "personal/classOpenServiceOne",
                ];
                if (newTabArr.includes(to.path) || to.name == "cookInHome" || to.name == "orgDetail" || to.name == "servieItemDetailAll" || to.name == "organizationDetail") {
                    open("http://" + location.host + "/#" + to.fullPath, "_blank");
                } else {
                    let userInfo = localStorage.getItem("userInfo");
                    if (userInfo) {
                        // 有token，判断token是否有效
                        request(`${baseUrl}/api/v1/token`).then((res) => {
                            if (res.code === 401) {
                                localStorage.removeItem("userInfo");
                                localStorage.removeItem("laterPath");
                                localStorage.removeItem("loginBeforePath");
                                sessionStorage.removeItem("pay");
                                // token无效，判断页面是否需要token
                                if (to.meta.requireAuth) {
                                    localStorage.removeItem("userInfo");
                                    return next('/login')
                                } else {
                                    next()
                                }
                            } else {
                                next()
                            }
                        })
                    } else {
                        next();
                    }
                }
            });

            this.$websocket.init()


//获取字体大小
//     let width=$("body").width();
//     if(width>=1200){
//       let fontsize=width/1920*103.5;//fontsize为当前屏幕的基数字体，相对于设计稿计算得到的。
//       $("html").css("font-size",`${fontsize}px`)
//     }//当加载页面的时候设置生效
//     window.onresize = () => {//当页面尺寸改变的时候生效
//       return (() => {
//         let width=$("body").width();
//         if(width>=1200){
//           let fontsize=width/1920*103.5;
//           $("html").css("font-size",`${fontsize}px`)
//         }
//       })
//     }
        },
        destroyed() {
            this.$websocket.onDestroy()
        }
    };
</script>

<style lang="less">

    #app {
        height: 100%;
        width: 100%;
    }

    * {
        /*font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;*/
        font-family: "Microsoft YaHei", Arial, "PingFang SC", sans-serif;
    }

    body {
        margin: 0;
        scroll-behavior: smooth;
        font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
        /*overflow-x:auto;white-space:nowrap;*/
    }

    .ant-breadcrumb-link a.breadcrumbO:hover {
        color: #f6ab1a;
        text-decoration: underline;
    }


    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: #d7f3f3 !important;
    }

    .pagination {
        width: 13.66rem;
        text-align: center;
        padding: 0.31rem 0 0.8rem;
        margin-top: 0.21rem;
        /*border: 1px solid red;*/
        //共多少条
        li.ant-pagination-total-text {
            height: 0.38rem;
            line-height: 0.38rem;
        }

        //上一页
        li.ant-pagination-prev {
            width: 0.4rem;
            height: 0.38rem;
            /*border: 1px solid red;*/
            a {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        //下一页
        li.ant-pagination-next {
            width: 0.4rem;
            height: 0.38rem;
            /*border: 1px solid red;*/
            a {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        //整个分页
        li.ant-pagination-item {
            border-radius: 0;
            margin-right: 8px;
            width: 0.4rem;
            height: 0.38rem;
            /*border: 1px solid red;*/
            a {
                height: 0.38rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        //跳至多少页
        li.ant-pagination-options {
            height: 0.38rem;

            input {
                height: 0.38rem;
            }
        }

        a.ant-pagination-item-link {
            border-radius: 0;
            border: none;
        }

        a.ant-pagination-item-link:hover {
            /*border: 1px solid #39BEB8;*/
            color: #60c7c6;
        }


        li.ant-pagination-item:hover {
            border-radius: 0;
            border: 1px solid #39BEB8;

            a {
                color: #39BEB8;
            }
        }

        li.ant-pagination-item.ant-pagination-item-active {
            border: 1px solid #39BEB8;
            background-color: #39BEB8;

            a {
                color: #fff;
            }
        }

        .ant-pagination-next,
        .ant-pagination-prev {
            border: #ddd 1px solid;
            padding: 0rem 0.15rem;
            // line-height: 0.32rem;
            // height: 0.92rem;
            height: 32px;
            line-height: 32px;
            border-radius: 0;
            font-size: 0.16rem;
        }


        .a.ant-pagination-item-link:active {
            border: 1px solid #39BEB8;
        }

        .ant-pagination-options-quick-jumper input {
            border-radius: 0;
        }

        .ant-pagination-options-quick-jumper input:hover {
            border: 1px solid #39BEB8;
        }

        .ant-pagination-options-quick-jumper input:focus {
            border-radius: 0;
            border: 1px solid #39BEB8;
        }
    }

    .ant-table-pagination {
        width: 100%;
    }

    /*css滚动条样式*/
    /*::-webkit-scrollbar {*/
    /*    width: 0.07rem;*/
    /*    height: 0.05rem;*/
    /*    background: #00000000;*/
    /*}*/
    /*::-webkit-scrollbar {*/
    /*    width: 6px;*/
    /*    height: 6px;*/
    /*    background: transparent;*/
    /*}*/
    /*::-webkit-scrollbar-thumb {*/
    /*    background: transparent;*/
    /*    border-radius: 4px;*/
    /*}*/
    /*:hover::-webkit-scrollbar-thumb {*/
    /*    background: hsla(0, 0%, 53%, 0.4);*/
    /*}*/
    /*:hover::-webkit-scrollbar-track {*/
    /*    background: hsla(0, 0%, 53%, 0.1);*/
    /*}*/

</style>


























































































    import {Component, Vue} from "vue-property-decorator";
    import RightOrderDetailService from "@/components/RightOrderDetailService.vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import highConfigurationStep from "../../../components/highConfigurationStep.vue";
    import RightOrderDetail from "@/components/RightOrderDetail.vue";
    import {fetchAfterSaleStatus, fetchServiceHistory, fetchServiceOrderDetail} from "@/services/api";
    import {message} from "ant-design-vue";

    @Component({
  components: {
    RightOrderDetailService,
    ReturnStep,
    Breadcrumb,
    highConfigurationStep,
    RightOrderDetail
  }
})
export default class extends Vue {
  returnId: any = ""; // 退款Id
  returnStatusDetail: any = {}; // 退款状态详情
  serviceOrderDetail: any = {}; // 服务订单详情
  serviceHistory: any = []; // 售后服务协商历史
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "我的服务",
      path: "/personal/myService",
      significance: 0
    },
    {
      name: "申请退款",
      path: "/",
      significance: 1
    },
  ]
  stepList = [
    {
      name: "买家申请退款"
    },
    {
      name: "卖家处理退款申请"
    },
    {
      name: "退款完毕"
    }
  ];
  stepList1 = [
    {
      name: "买家申请退款"
    },
    {
      name: "卖家处理退款申请"
    },
    {
      name: "退款关闭"
    }
  ];

  async created() {
    this.returnId = this.$route.query.returnId;
    await this.getAfterSaleStatus();
    await this.getServiceOrderDetail();
    await this.getServiceHistory();
  }

  // 查询售后协商历史
  getServiceHistory() {
    fetchServiceHistory({
      serviceRefundInfo: this.returnId
    }).then(res => {
      if (res && res.code === 0) {
        // 处理数据
        console.log('------协商历史-----');
        console.log(res.data)
        this.serviceHistory = res.data;
      } else {
        message.error(res.msg);
      }
    });
  }
  //姓名加密
  getNickName(value) {
    if(value){
      const name: any[] = value.split("");
      return name[0] + "*****" + name[name.length - 1];
    }
  }
  // 查询售后申请状态
  getAfterSaleStatus() {
    fetchAfterSaleStatus({
      afterSaleId: this.returnId
    }).then(res => {
      if (res && res.code === 0) {
        // 处理数据
        this.returnStatusDetail = res.data;
      } else {
        message.error(res.msg);
      }
    });
  }

  // 获取商品订单详情
  getServiceOrderDetail() {
    fetchServiceOrderDetail({
      returnId: this.returnId
    }).then(res => {
      if (res && res.code === 0) {
        // 处理数据;
        res.data.orderCode = res.data.orderSeriesNum;
        res.data.serviceFee = res.data.tipPrice;
        res.data.totalPrice = res.data.serviceTotalPrice;
        this.serviceOrderDetail = res.data;
      } else {
        message.error(res.msg);
      }
    });
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"box"}},[_c('div',{staticClass:"form-box"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"contactInformation"},[_c('a-form-item',_vm._b({attrs:{"label":"收货人姓名"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiverName',
            {
              rules: [
                {
                  required: true,
                  message: '请输入收货人姓名'
                }
              ]
            }
          ]),expression:"[\n            'receiverName',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入收货人姓名'\n                }\n              ]\n            }\n          ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请输入收货人姓名"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"所在地区"}},'a-form-item',_vm.formItemLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'provinceValue',
            {
              trigger: 'change',
              rules: [
                {
                  required: true,
                  message: '请选择所在地址'
                }
              ],
              initialValue: _vm.data.provinceValue
            }
          ]),expression:"[\n            'provinceValue',\n            {\n              trigger: 'change',\n              rules: [\n                {\n                  required: true,\n                  message: '请选择所在地址'\n                }\n              ],\n              initialValue: data.provinceValue\n            }\n          ]"}],staticStyle:{"width":"4rem"},attrs:{"options":_vm.options,"loadData":_vm.loadMoreData,"placeholder":"请选择所在地址"},on:{"change":_vm.onProvinceChange}})],1),_c('a-form-item',_vm._b({attrs:{"label":"详细地址"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'receiverDetailAddress',

            {
              rules: [
                {
                  required: true,
                  message: '请输入详细地址'
                }
              ]
            }
          ]),expression:"[\n            'receiverDetailAddress',\n\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入详细地址'\n                }\n              ]\n            }\n          ]"}],staticStyle:{"width":"4rem","font-size":"0.14rem"},attrs:{"placeholder":"请输入详细地址"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"联系电话"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phoneNumber',
            {
              rules: [
                {
                  required: true,
                  message: '请输入联系电话'
                }
              ]
            }
          ]),expression:"[\n            'phoneNumber',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入联系电话'\n                }\n              ]\n            }\n          ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请输入联系电话"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"邮政编码"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'zipCode',
            {
              rules: [
                {
                  required: true,
                  message: '请输入邮政编码'
                }
              ]
            }
          ]),expression:"[\n            'zipCode',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入邮政编码'\n                }\n              ]\n            }\n          ]"}],staticStyle:{"width":"4rem"},attrs:{"placeholder":"请输入邮政编码"}})],1),_c('a-form-item',_vm._b({staticStyle:{"padding-left":"1.2rem"}},'a-form-item',_vm.formItemLayout,false),[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'isDefault',
            { valuePropName: 'checked', initialValue: false }
          ]),expression:"[\n            'isDefault',\n            { valuePropName: 'checked', initialValue: false }\n          ]"}]},[_vm._v("设为默认收货地址 ")])],1)],1),_c('div',{staticClass:"shouhuoFooter"},[_c('div',{staticClass:"ok-button",on:{"click":_vm.handleSubmit}},[_c('span',[_c('img',{attrs:{"src":require("../../assets/images/homeImg/yes.png")}}),_vm._v("保存")])]),_c('div',{staticClass:"cancel-button",on:{"click":_vm.cancelTheSave}},[_c('span',[_c('img',{attrs:{"src":require("../../assets/images/homeImg/no.png")}}),_vm._v("取消")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
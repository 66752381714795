

















































































































    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Button, Form, Input, message, Radio, Select, Steps, Table} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ServiceProcessSteps from "./classServicesteps.vue";
    import {Mutation, State} from "vuex-class";
    import {ifExtendStart, serviceConfirmExtend} from "@/services/api";

    Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);

@Component({
  components: {
    PersonalHeader,
    ServiceProcessSteps
  }
})
export default class extends Vue {
  @Mutation SET_CLASSSERVICETHREESTEP;
  @Mutation SET_CLASSSERVICEDETAIL;
  created() {
    const str1: any = localStorage.getItem("CLASSSERVICETHREESTEP");
    this.SET_CLASSSERVICETHREESTEP(JSON.parse(str1), true);
    const str2: any = localStorage.getItem("CLASSSERVICEDETAIL");
    this.SET_CLASSSERVICEDETAIL(JSON.parse(str2), true);
    // localStorage.removeItem("CLASSSERVICETHREESTEP");
    // localStorage.removeItem("CLASSSERVICEDETAIL");
  }

  @State classServieDetail;
  @State classServiceThreeStep;
  relationId = "";
  delayIsShow = false;
  refundId: any;
  curStatus = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false
  };
  curStatusStep = 0;
  @Watch("classServiceThreeStep", { immediate: true, deep: true })
  getCurStatus(newVal, oldVal) {
    if (newVal.step1) {
      this.curStatus.step1 = true;
      this.curStatusStep = 0;
    } else if (newVal.step2) {
      this.curStatus.step2 = true;
      this.curStatusStep = 1;
    } else if (newVal.step3) {
      this.curStatus.step3 = true;
      this.curStatusStep = 2;
    } else if (newVal.step4) {
      this.curStatus.step4 = true;
      this.curStatusStep = 3;
    } else if (newVal.step5) {
      this.curStatus.step5 = true;
      this.curStatusStep = 4;
    } else if (newVal.step6) {
      this.curStatus.step6 = true;
      this.curStatusStep = 5;
    }
  }
  @Watch("classServieDetail", { immediate: true, deep: true })
  getdata(newVal, oldVal) {
    this.refundId = newVal.refundId;
    this.relationId = newVal.relationId;
    this.stepList[0].time = newVal.waitForServiceTime;
    this.stepList[1].time = newVal.servingTime;
    this.stepList[2].time = newVal.serviceFinishTime;
    this.serviceIfExtendConfirm();

    if (newVal.serviceStatusDic != 302 && newVal.serviceStatusDic != 420) {
      let a: any = [];
      newVal.servingStatusList.map(item => {
        a.push({
          name: item.servingStatus,
          time: item.createTime,
          order: a.statusOrder
        });
      });
      a.sort((a, b) => a.statusOrder - b.statusOrder);
      this.stepList = [this.stepList[0], ...a, this.stepList[2]];
    }

    // this.data = [
    //   {
    //     key: 1,
    //     service: [newVal.serviceName, newVal.pic],
    //     institution: newVal.agencyName,
    //     serviceSeriesNum: newVal.serviceSeriesNum,
    //     price: newVal.price,
    //     tips: newVal.serviceFee
    //   }
    // ];
    this.data =
      newVal.serviceStyle == "到店服务"
        ? [
            {
              key: 1,
              service: [newVal.serviceName, newVal.pic],
              // institution: newVal.agencyName,
              serviceStyle: newVal.serviceStyle,
              price: newVal.price,
              tips: newVal.serviceFee
            }
          ]
        : [
            {
              key: 1,
              service: [newVal.serviceName, newVal.pic],
              servicePersonnelName: newVal.servicePersonnelName,
              contactTel: newVal.contactTel,
              serviceStyle: newVal.serviceStyle,
              price: newVal.price,
              tips: newVal.serviceFee
            }
          ];
  }
  data: any = [
    {
      key: 1,
      service: ["", ""],
      institution: "",
      serviceSeriesNum: "",
      price: "",
      tips: ""
    }
  ];
  stepList: any = [
    {
      name: "等待开启服务",
      time: ""
    },
    {
      name: "服务开启中",
      time: ""
    },
    // {
    //   name: "预约体检日期",
    //   time: ""
    // },
    // {
    //   name: "到达体检中心体检",
    //   time: ""
    // },
    // {
    //   name: "获取体检报告",
    //   time: ""
    // },
    {
      name: "服务开启完成",
      time: ""
    }
  ];
  columns = [
    {
      title: "服务名称",
      key: "service",
      dataIndex: "service",
      align: "center",
      width: "4.06rem",
      slots: { title: "service" },
      scopedSlots: { customRender: "service" }
    },
    {
      title: "服务方式",
      key: "serviceStyle",
      dataIndex: "serviceStyle",
      align: "center",
      width: "1.91rem"
    },
    {
      title: "服务价格",
      key: "price",
      dataIndex: "price",
      align: "center",
      width: "1.57rem"
    },
    {
      title: "服务费用",
      key: "tips",
      dataIndex: "tips",
      align: "center",
      width: "1.57rem"
    }
  ];
  // columns = [
  //   {
  //     title: "服务",
  //     key: "service",
  //     dataIndex: "service",
  //     align: "center",
  //     width: "4.06rem",
  //     slots: { title: "service" },
  //     scopedSlots: { customRender: "service" }
  //   },
  //   {
  //     title: "服务商名称",
  //     key: "institution",
  //     dataIndex: "institution",
  //     align: "center",
  //     width: "1.91rem"
  //   },
  //   {
  //     title: "服务编号",
  //     key: "serviceSeriesNum",
  //     dataIndex: "serviceSeriesNum",
  //     align: "center",
  //     width: "1.96rem"
  //   },
  //   {
  //     title: "服务价格",
  //     key: "price",
  //     dataIndex: "price",
  //     align: "center",
  //     width: "1.57rem"
  //   },
  //   {
  //     title: "服务费用",
  //     key: "tips",
  //     dataIndex: "tips",
  //     align: "center",
  //     width: "1.57rem"
  //   }
  // ];
  serviceIfExtendConfirm() {
    ifExtendStart({
      relationId: this.relationId
    }).then(res => {
      if (res["code"] == 0) {
        this.delayIsShow = res.data.ifShow;
        this.newEndTime = res.data.endTime;
      }
    });
  }
  applyReturnMoney() {
    this.$router.push({
      path: "/personal/returnService1",
      query: {
        relationId: this.relationId
      }
    });
  }
  newEndTime = "";
  startDelayTime() {
    var that = this;
    this.$confirm({
      title: `确认服务时间延长至${that.newEndTime},请及时完成服务，以免服务自动确认完成`,
      content:
        "您只能主动延长一次确认服务时间，延长后不能再次主动延长时间，且超出延长期后无法再次延长。",
      onOk() {
        // serviceIfExtend({
        //   relationId: that.relationId
        // }).then(res => {
        //   if (res.data) {
        serviceConfirmExtend({ relationId: that.relationId }).then(res => {
          if (res.code == 0) {
            message.success("申请延长服务时间成功");
            that.$router.push("/personal/myService");
          } else {
            message.error("申请延长服务时间失败");
          }
        });
        //   } else {
        //     message.error("申请延长服务时间");
        //   }
        // });
      },
      onCancel() {
      },
      class: "test"
    });
  }
  lookReturnMoney() {
    this.$router.push({
      path: "/personal/returnService2",
      query: {
        returnId: this.refundId,
        relationId: this.relationId
      }
    });
  }
}

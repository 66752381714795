var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"login-logo"},[_c('div',{staticClass:"logo",on:{"click":_vm.jumpToHome}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',{staticClass:"text"},[_vm._v("信用征信平台")])],1),_c('div',{staticClass:"background-img"},[_c('div',{staticClass:"login-form"},[_vm._m(0),_c('a-input',{staticClass:"my-input",style:({
        marginTop: '0.4rem',
        height: '0.5rem',
        fontSize: '0.16rem',
      }),attrs:{"placeholder":"请输入手机号/用户名"},on:{"change":function($event){_vm.usernameTip = ''}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}},[_c('template',{slot:"prefix"},[_c('div',{style:({
            height: '0.22rem',
            width: '0.2rem',
            backgroundImage: ("url(" + (require('../../assets/images/yonghuming_icon_dl.png')) + ")"),
            backgroundPosition: 'center' + ' ' + 'center',
            backgroundSize: 'cover',
          })})])],2),_c('a-input-password',{staticClass:"my-input",style:({
        marginTop: '0.4rem',
        height: '0.5rem',
        fontSize: '0.14rem',
      }),attrs:{"placeholder":"请输入密码","value":_vm.password},on:{"change":_vm.changePwd}},[_c('template',{slot:"prefix"},[_c('div',{style:({
            height: '0.22rem',
            width: '0.2rem',
            marginLeft: '-0.02rem',
            backgroundImage: ("url(" + (require('../../assets/images/mima_icon_dl.png')) + ")"),
            backgroundPosition: 'center' + ' ' + 'center',
            backgroundSize: '100% 100%',
          })})])],2),_c('div',{staticClass:"item_remember"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remember),expression:"remember"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.remember)?_vm._i(_vm.remember,null)>-1:(_vm.remember)},on:{"change":function($event){var $$a=_vm.remember,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.remember=$$a.concat([$$v]))}else{$$i>-1&&(_vm.remember=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.remember=$$c}}}}),_c('span',[_vm._v("记住密码")])]),_c('div',{staticClass:"login-button",on:{"click":_vm.handleLogin}},[_vm._v(" 登录 ")]),_c('div',{staticClass:"tipLogin",staticStyle:{"top":"1rem"}},[_vm._v(" "+_vm._s(_vm.usernameTip)+" ")]),_c('div',{staticClass:"tipLogin",staticStyle:{"top":"1rem"}},[_vm._v(" "+_vm._s(_vm.pswTip)+" ")])],1)]),_c('div',{staticClass:"register-footer-zc-login"},[_vm._m(1),_c('div',{staticClass:"bottom"},[_c('p',[_vm._v("专注养老事业")]),_c('p',[_vm._v(" CopyRight©2019~"+_vm._s(new Date().getFullYear())+" fytlun.com 版权所有 ")]),_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-type"},[_c('span',{staticClass:"checked"},[_vm._v("账户登录")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top"},[_c('span',[_vm._v("关于平台")]),_c('span',{staticStyle:{"width":"0.7rem","display":"inline-block","text-align":"center"}},[_vm._v("•")]),_c('span',[_vm._v("联系平台")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("粤ICP备20035226号")])])}]

export { render, staticRenderFns }














import { Component, Prop, Vue } from "vue-property-decorator";
import { Pagination } from "ant-design-vue";

Vue.use(Pagination);

@Component({
  props: ["total", "defaultCurrent"]
})
export default class MyPagination extends Vue {
  // @Prop() private msg!: string
  onChange(pageNumber: any, pageSize: any) {
    window.scrollTo({ top: 0 });
    this.$emit("change", pageNumber, pageSize);
  }
}

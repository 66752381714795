<template>
  <div>
    <Header />
    <Nav />
    <div class="doctor-banner-pensionService">
      <div
        class="banner-image"
        :style="{ backgroundImage: `url(${content.serviceDetailPhoto})` }"
      ></div>
      <div class="banner-description">
        <div class="title">
          <span class="content">{{ content.classificationName }}</span>
        </div>
        <div class="text">
          <div class="content">{{ content.detailDescription }}</div>
        </div>
      </div>
    </div>
    <div class="doctor-breadcrumb">
      <div class="container">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link to="/home" class="breadcrumbO">首页</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <router-link to="/pensionService" class="breadcrumbO"
              >养老服务</router-link
            >
          </a-breadcrumb-item>
          <a-breadcrumb-item :style="{ color: '#60c7c6' }">
            {{ content.classificationName }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="clean-body">
      <div class="title">服务特色</div>
      <CleanDesc
        :content="content.features"
        :serviceFeaturePhoto="content.serviceFeaturePhoto"
      />
      <div class="title">
        <span>服务预约</span>
        <div class="title-search">
          <a-input-search
            v-model="serviceName"
            placeholder="请输入服务名称"
            @search="onSearch"
            enterButton="搜索"
          >
            <a-icon slot="prefix" type="search" />
          </a-input-search>
        </div>
      </div>
      <div class="order" v-for="item in orderList" :key="item.id">
        <OrderItem
          :orderImage="item.serviceImage"
          :orderTitle="item.serviceName"
          :orderPrice="item.servicePrice"
          :orderLocation="item.detailAddress"
          :orderRate="item.serviceOveralScore"
          :orderArea="item.storeName"
          :saleCount="item.totalSale"
          :orderDesc="item.serviceDesc"
          :serviceId="item.serviceId"
          :all="item"
        />
      </div>
      <div class="pagination" style="padding: 0.31rem 0 0.61rem;">
        <a-pagination
          :showTotal="total => `共 ${pagination.total} 条`"
          :defaultCurrent="1"
          :total="pagination.total"
          :item-render="$store.state.itemRender"
          showQuickJumper
          @change="onchange"
        />
      </div>
      <div class="title">其他服务商</div>
      <PicSlider class="pic-slider" :orgList="orgList" />
      <div class="title">服务流程</div>
      <Process class="process-pic" :ProcessType="2" />
    </div>
    <Footer />
  </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import CleanDesc from "@/components/CleanDesc";
    import PicSlider from "@/components/PicSlider";
    import OrderItem from "@/components/OrderItem";
    import Process from "@/components/Process";
    import {Breadcrumb, Input} from "ant-design-vue";
    import {fetchDetailByThirdId, otherOrgs, serviceTypeInfo} from "@/services/api";

    Vue.use(Breadcrumb);
Vue.use(Input);

export default {
  name: "PensionClean",
  components: {
    Header,
    Nav,
    Footer,
    CleanDesc,
    PicSlider,
    OrderItem,
    Process
  },
  data() {
    return {
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        keywords: undefined
      },
      orderList: [],
      serviceName: "",
      content: "",
      orgList: []
    };
  },
  methods: {
    onSearch(value) {
      this.pagination = {
        ...this.pagination,
        keywords: value
      };

      const params = {
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.pageNum,
        searchInput: this.pagination.keywords,
        thirdServicesId: "12",
        cityId: parseInt(localStorage.getItem("provinceId") || "2", 10)
      };
      this.orderList.length = 0;
      this.getServiceList(params);
    },
    onchange(page, pageSize) {
      window.scrollTo({ top: 0 });

      const temp = {
        ...this.pagination,
        pageNum: page,
        pageSize
      };
      this.pagination = temp;

      const params = {
        pageSize: this.pagination.pageSize,
        pageIndex: this.pagination.pageNum,
        searchInput: this.pagination.keywords,
        thirdServicesId: "12",
        cityId: parseInt(localStorage.getItem("provinceId") || "2", 10)
      };
      this.getServiceList(params);
    },
    getServiceList(params) {
      fetchDetailByThirdId(params).then(res => {
        if (res.code === 0) {
          this.orderList = [];
          if (res.data.serviceList) {
            res.data.serviceList.forEach(item => this.orderList.push(item));
            this.pagination = {
              ...this.pagination,
              total: res.data.totalCount
            };
          }
        }
      });
    }
  },
  mounted: function() {
    const params = {
      pageSize: 10,
      pageIndex: 1,
      searchInput: this.pagination.keywords,
      thirdServicesId: "12",
      cityId: parseInt(localStorage.getItem("provinceId") || "2", 10)
    };
    this.getServiceList(params);
    let serviceClassificationId = this.$route.query.serviceClassificationId;
    // this.content = this.$route.query.data;
    serviceTypeInfo({ serviceClassificationId: serviceClassificationId }).then(
      res => {
        this.content = res.data;
      }
    );
    const otherParams = {
      pageSize: 9999,
      pageIndex: 1,
      cityId: parseInt(localStorage.getItem("provinceId") || "2", 10),
      secondCategoryId: "12",
      areaId: null
    };
    otherOrgs({ ...otherParams }).then(res => {
      this.orgList = res.data.orgList;
    });
  }
};
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.clean-banner-pensionService {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 4rem;
  .banner-image {
    height: 100%;
    width: 100%;
    background-image: url("./../assets/images/banner_zhujie.png");
    background-size: cover;
    background-position: center center;
  }
  .banner-description {
    position: absolute;
    left: 4.2rem;
    top: 0.4rem;
    color: #fff;
    .title {
      .content {
        font-size: 0.46rem;
        border-bottom: medium solid #f6ab1a;
      }
    }
    .text {
      width: 7.21rem;
      .content {
        font-size: 0.18rem;
      }
    }
  }
}
.clean-breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.18rem 0 0.18rem;
  background-color: #fff;
  .container {
    width: 13.66rem;
    margin: 0 auto;
  }
}
.clean-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #fff;
  .title {
    font-size: 0.4rem;
    color: #1f6bb1;
    position: relative;
    .ant-btn.ant-btn-primary.ant-input-search-button {
      background-color: #60c6c7;
      border-radius: 0;
      border: none;
    }
    input.ant-input {
      border-radius: 0;
    }
    .title-search {
      position: absolute;
      left: 3.5rem;
      bottom: 0.2rem;
      width: 3rem;
      height: 0.3rem;
      button.ant-btn.ant-btn-primary.ant-input-search-button {
        background-color: #60c7c6;
      }
    }
  }
  .order {
    width: 13.66rem;
    height: 2.4rem;
  }
  .pic-slider {
    margin: 0.46rem 0 0.63rem;
  }
  .process-pic {
    margin: 0.3rem 0 0.6rem;
  }
}
</style>


import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      second: 3
    };
  },

  methods: {
    setTime() {
      const t = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(t);
          window.close();
        }
      }, 1000);
    }
  },
  mounted() {
    this.setTime();
  }
});

<script>
import {baseUrl, baseUrl2, request} from "../../../../services/http";

export default {
  name: "commodity",
  props: {
    commodityId: {
      type: String,
      default: undefined
    },
  },
  watch: {
    commodityId: {
      immediate: true,
      handler() {
        this.reload();
      }
    },
  },
  data() {
    return {
      commodityInfo: {}
    }
  },
  methods: {
    reload() {
      if(this.commodityId == undefined) {
        console.error('请提供commodity-id')
      }
      this.loadCommodityInfo();
    },
    loadCommodityInfo() {
      request(`${baseUrl2}/api/v1/commodities/info`, {
        method: 'get',
        params: {
          commodityId: this.commodityId
        }
      }).then((res) => {
        this.commodityInfo = {
          commodityPic: res.data.detailPics[0].pictureAddress,
          commodityName: res.data.commodityInfoDto.commodityName,
          price: res.data.commodityInfoDto.suggestPrice,
        }
      })
    },
    buy() {
      this.$emit('jump',{
        messageType: 'FYTL:Commodity',
        content: {
          commodityId: this.commodityId
        }
      })
    },

    sendCommodity() {
      this.$emit('send-commodity', {
        commodityId: this.commodityId,
        commodityPic: this.commodityInfo.commodityPic,
        commodityName: this.commodityInfo.commodityName,
        price: this.commodityInfo.price,
      })
    }
  }
}
</script>

<template>
  <div class="commodity">
    <img :src="commodityInfo.commodityPic">
    <span class="text">{{ commodityInfo.commodityName }}</span>
    <span class="price">{{commodityInfo.price}}</span>
    <button class="buy" @click="buy">去购买</button>
    <button class="send" @click="sendCommodity">发送商品</button>
  </div>
</template>

<style lang="less" scoped>
.commodity {
  position: relative;
  &:hover {
    background: #F8F8F8;
  }
  background: #ffffff;
  height: 150px;
  border-bottom: 1px solid #E4E9F2;

  img {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    margin: 20px 10px;
  }
  .text {
    position: absolute;
    left: 100px;
    top: 25px;
    width: calc(~'100% - 110px');
    height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price {
    position: absolute;
    left: 100px;
    top: 80px;
    width: calc(~'100% - 110px');
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 19px;
    color: #FF1010;
    &:before {
      content: '￥';
    }
  }



  button {
    position: absolute;
    bottom: 10px;
  }
  .buy {
    left: 108px;
  }
  .send {
    right: 20px;
  }
}
button {
   width: 86px;
   height: 30px;
   background-color: #ffffff;
   border: 1px solid #78849E;
   border-radius: 15px;
   margin-right: 10px;
   font-size: 12px;
   font-family: Microsoft YaHei;
   font-weight: 400;
   line-height: 16px;
   color: #78849E;
   transition: all .15s;

   &:hover {
     background: #EEF3FA;
   }

   &:active {
     background: #D7F2F9;
   }
 }
</style>














































import Header from "./../../components/Header.vue";
import Footer from "./../../components/Footer.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
        Header,
        Footer
    }
})
export default class Home extends Vue {
    institution(value){
        let retuenList;
        switch(value){
            case '1':
                retuenList = this.$router.resolve({path: "/ServiceJoin"});
                break;
            case '2':
                retuenList = this.$router.resolve({path: "/OrganizationJoin"});
                break;
            default:
                retuenList = this.$router.resolve({path: "/BusinessJoin"});
        }
        let {href} = retuenList;
        window.open(href, "_blank");
    }
    jumpToHome() {
        this.$router.push("/");
    }
}




















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class extends Vue {
    @Prop() data: any;
    @Prop() type: any;

    jump() {
        this.$router.push(`/orgDetail/${this.data.orgId}`);
    }
}















































    import {Component, Vue} from "vue-property-decorator";
    import {Table} from "ant-design-vue";

    Vue.use(Table);

@Component({
  components: {}
})
export default class extends Vue {
  data: any = {}; // 页面数据
  mounted() {
    this.data = JSON.parse(this.$route.query.params);
  }
  // 点击我要退款
  handleApplyDrawBack() {
    this.$router.push({
      path: "/personal/returnService1",
      query: {
        relationId:
          this.data.other.servicRelationId || this.data.other.relationId
      }
    });
  }
}

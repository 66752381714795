<template>
    <div class="judge-body" v-if="shoasjoas">
        <div class="judge-detail-top">
            <Breadcrumb :breadcrumb="breadcrumb"/>
        </div>
        <div class="comment-top">
            <div class="top-bg">
                <div class="bg-left" @click="jumpService">
                    <img :src="orgInfo.agencyLogo"/>
                </div>
                <div class="bg-right">
                    <span :title="orgInfo.agencyName" class="bg-right-header-title" @click="jumpService">{{ orgInfo.agencyName }}</span>
                    <div class="bg-right-header-rate">
                        <div>综合</div>
                        <div>服务</div>
                        <div>态度</div>
                        <div>效率</div>
                    </div>
                    <div class="bg-right-header-item">
                        <div>{{ parseFloat(orgInfo.overallScore).toFixed(2) || 0 }}</div>
                        <div>{{ parseFloat(orgInfo.serviceScore).toFixed(2) || 0 }}</div>
                        <div>{{ parseFloat(orgInfo.attitudeScore).toFixed(2) || 0 }}</div>
                        <div>{{ parseFloat(orgInfo.efficiencyScore).toFixed(2) || 0 }}</div>
                    </div>
                </div>
            </div>
            <div class="comment-merchant">
                <span>服务符合度</span>
                <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem" v-model="ServiceInfo.serviceCompliance"/>
                <span>{{ ServiceInfo.serviceCompliance ? parseInt(ServiceInfo.serviceCompliance).toFixed(1) : 0 }}分</span>
            </div>
            <div class="comment-merchant">
                <span>服务商态度</span>
                <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem" v-model="ServiceInfo.organizationAttitude"/>
                <span>{{ ServiceInfo.organizationAttitude ? parseInt(ServiceInfo.organizationAttitude).toFixed(1) : 0 }}分</span>
            </div>
            <div class="comment-merchant">
                <span>服务商效率</span>
                <a-rate :disabled="commentId" style="font-size:0.18rem;margin-right: 0.1rem" v-model="ServiceInfo.organizationSpeed"/>
                <span>{{ ServiceInfo.organizationSpeed ? parseInt(ServiceInfo.organizationSpeed).toFixed(1) : 0 }}分</span>
            </div>
        </div>

        <div class="comment-center" v-for="(item, index) of ServiceInfo.serviceInfos" :key="item.relationId">
            <div class="comment-center-left">
                <div class="rate-body-comment">
                    <div class="good-img-comment">
                        <img :src="item.serviceLogo"/>
                    </div>
                    <span class="good-name-comment">{{ item.serviceName || "--" }}</span>
                    <span class="good-price-comment">￥{{ item.servicePrice || "--" | formatMoney }}</span>
                    <span v-if="item.serviceSku" class="good-specification">{{ item.serviceSku }}</span>
                </div>
            </div>
            <div class="comment-center-right">
                <div class="judge-form">
                    <div class="rate-item" style="margin-top: 0.5rem" v-if="commentId">
                        <span class="pin">评价时间</span>
                        <div class="exhibition">{{ ServiceInfo.createTime }}</div>
                    </div>
                </div>
                <div class="judge-form">
                    <div class="rate-item" :style="{ alignItems: 'center', marginBottom: '0.21rem', marginTop: commentId ? 'auto' : '0.5rem' }">
                        <span class="pin">服务评分</span>
                        <a-rate
                                style="font-size:0.18rem"
                                :defaultValue="0"
                                :disabled="commentId"
                                v-model="item.commentStar"
                        />
                        <span class="num">{{ item.commentStar ? parseInt(item.commentStar).toFixed(1) : 0 }}分</span>
                    </div>
                    <div class="Tiptip">{{ item.commentStarTip }}</div>

                    <div class="rate-item">
                        <span class="pin">评价内容</span>
                        <a-textarea
                                v-if="!commentId"
                                placeholder="服务质量如何，服务内容如何，快把您的感受分享给大家吧。"
                                v-model="item.commentContent"
                                @focus="item.commentContentTip = ''"
                                :style="{
                width: '6rem',
                fontSize: '0.14rem',
                resize: 'none',
                height: '1.4rem',
              }"
                        ></a-textarea>
                        <div class="exhibition" v-else>{{ item.commentContent }}</div>
                    </div>
                    <div class="Tiptip">{{ item.commentContentTip }}</div>

                    <div v-if="item.ifPersonnel" class="rate-item" :style="{ alignItems: 'center', marginBottom: '0.21rem' }">
                        <span class="pin">服务人员服务</span>
                        <a-rate
                                style="font-size:0.18rem"
                                :disabled="commentId"
                                :defaultValue="0"
                                v-model="item.personStar"
                        />
                        <span class="num">{{ item.personStar ? parseInt(item.personStar).toFixed(1) : 0 }}分</span>
                    </div>
                    <div class="Tiptip">{{ item.personnelScoreTip }}</div>

                    <div v-if="item.ifPersonnel" class="rate-item" :style="{ marginBottom: '0.1rem' }">
                        <span class="pin">评价内容</span>
                        <a-textarea
                                v-if="!commentId"
                                placeholder="服务人员如何，快把您的感受分享给大家吧。"
                                v-model="item.personContent"
                                @focus="item.personnelComContentTip = ''"
                                :style="{width: '6rem',fontSize: '0.14rem',resize: 'none',height: '1.4rem',}"
                        ></a-textarea>
                        <div class="exhibition" v-else>{{ item.personContent }}</div>
                    </div>
                    <div class="Tiptip">{{ item.personnelComContentTip }}</div>

                    <div class="button" v-if="!commentId && index == ServiceInfo.serviceInfos.length - 1">
                        <div class="btn" @click="handleSubmitJudge">
                            <img src="../../../../assets/images/homeImg/路径 2314@2x.png"/>
                            发表
                        </div>
                        <div>
                            <a-checkbox :style="{ fontSize: '0.14rem' }" v-model="ServiceInfo.isAnonymous">匿名</a-checkbox>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <a-modal width="6.3rem"  :visible="judgeModal" @cancel="() => (this.judgeModal = false)">
            <template slot="title">
                <div class="modal-header">
                    <span>评价成功</span>
                </div>
            </template>
            <div class="modal-center">
                <p class="confirm-single">评价已完成，以下服务未评价快去评价吧！</p>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="() => $router.push('/personal/serviceOrderList')">
                        <span><img src="../../../../assets/images/homeImg/yes.png"/>继续评价</span>
                    </div>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import {Component, Vue} from "vue-property-decorator";
    import {Button, Checkbox, Icon, Input, message, Modal, Rate,} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import {evaluationServiceOrder, purchaseOrderEvaluation} from "@/services/api";
    import {baseUrl, request} from "@/services/http";

    Vue.use(Icon);
    Vue.use(Rate);
    Vue.use(Input);
    Vue.use(Checkbox);
    Vue.use(Modal);
    Vue.use(message);
    Vue.use(Button);

    @Component({
        components: {
            PersonalHeader,
            Breadcrumb
        },
    })
    export default class serviceOrdersJudge extends Vue {
        //面包屑
        breadcrumb = [
            {
                name: "个人中心",
                path: "/personal/myHomePage",
                significance: 0

            },
            {
                name: "服务订购单",
                path: "/personal/serviceOrderList",
                significance: 0
            },
            {
                name: "服务订购单评价",
                path: "/personal/serviceOrdersJudge",
                significance: 1
            },
        ]
        judgeModal = false;
        orgInfo = [];
        ServiceInfo = {isAnonymous: false};
        orderId='';
        orgId='';

        commentId = false;

        jumpService() {
            this.$router.push({
                path: `/orgDetail/${this.orgId}`,
            });
        }


        created() {
            let orderId = this.$route.query.orderId;
            let commentId = this.$route.query.commentId;
            if (orderId) {
                this.evaluationServiceInfo(orderId,0);
                this.orderId = orderId
            }
            if (commentId) {
                this.commentId = true;
                this.getContentBycommentId(commentId);
            }
        }

        //查看服务评价详情
        getContentBycommentId(commentId) {
            request(`${baseUrl}/api/v1/services/comments/service/detail`, {
                params: {commentId},
            }).then(({data, code}) => {
                this.ServiceInfo = {
                    orderCode: data.orderCode,
                    createTime: data.commentTime,
                    serviceCompliance: data.serviceCompliance,
                    organizationAttitude: data.serviceAttitude || 0,
                    organizationSpeed: data.organizationSpeed || 0,
                    serviceInfos: [
                        {
                            serviceLogo: data.servicePic,
                            serviceName: data.serviceName,
                            servicePrice: data.price,
                            personStar: data.personnelScore,
                            commentStar: data.score,
                            commentContent: data.content,
                            personContent: data.personnelComContent,
                            ifPersonnel: true,
                            serviceSku: data.serviceSku,
                        },
                    ],
                };
                this.orderId = data.orderId;
                this.evaluationServiceInfo(data.serviceOrderId,1);
            });
        }

        evaluationServiceInfo(orderId,value) {
            evaluationServiceOrder({
                orderId: orderId,
            }).then((res) => {
                this.orgInfo = res.info;
                this.orgId = res.info.orgId;
                if(value===0){
                    this.ServiceInfo = {
                        serviceInfos: [
                            {
                                serviceLogo:  res.info.serviceLogo,
                                serviceName:  res.info.serviceName,
                                ifPersonnel: true,
                                servicePrice:  res.info.costPrice,
                                serviceSku:  res.info.skuInfo,
                            },
                        ],
                    };
                }


            });
        }

        shoasjoas = true;


        //提交评价
        handleSubmitJudge() {
            const required = {
                commentContent: "服务评价",
            };
            let err = false;
            this.ServiceInfo.serviceInfos.forEach((element) => {
                if (element.ifPersonnel) {
                    required.personContent = "服务人员评价";
                }
                Object.keys(required).forEach((item) => {
                    if (!element[item]) {
                        element[item + "Tip"] = required[item] + "不能为空";
                        err = true;
                        return;
                    } else {
                        element[item + "Tip"] = "";
                    }
                });
            });
            this.shoasjoas = false;
            this.$nextTick((e) => (this.shoasjoas = true));
            if (err) return;
            const data = {
                orderId:this.orderId,
                commentStar:this.ServiceInfo.serviceInfos[0].commentStar,
                commentContent:this.ServiceInfo.serviceInfos[0].commentContent,
                personStar:this.ServiceInfo.serviceInfos[0].personStar,
                personContent:this.ServiceInfo.serviceInfos[0].personContent,
                isAnonymous: this.ServiceInfo.isAnonymous ? 1 : 0,
                serviceCompliance:this.ServiceInfo.serviceCompliance,
                organizationAttitude:this.ServiceInfo.organizationAttitude,
                organizationSpeed:this.ServiceInfo.organizationSpeed,

            };
            purchaseOrderEvaluation(data).then((res) => {
                if (res.code === 0) {
                    message.success("服务评价成功");
                    this.judgeModal = true;
                } else {
                    message.success(data.msg);
                }
            });
        }
    }
</script>

<style lang="less">
    .Tiptip {
        padding-left: 14.7%;
        color: #f00;
        margin-top: -0.1rem;
        margin-bottom: 0.1rem;
        font-size: 0.14rem;
    }

    .judge-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        background-color: #f2f2f2;

        .judge-detail-top {
            margin: 0.2rem 0.3rem;
            display: flex;
            align-items: center;
        }

        .comment-top {
            height: 1.16rem;
            width: 100%;
            background: #FFFFFF;
            opacity: 1;
            border-radius: 0.12rem;
            display: flex;

            .top-bg {
                width: 3.11rem;
                height: 100%;
                background-image: url("./../../../../assets/images/homeImg/组 1188@2x.png");
                background-size: cover;
                background-position: center center;
                display: flex;

                .bg-left {
                    cursor: pointer;
                    width: 0.68rem;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    img {
                        width: 0.47rem;
                        height: 0.48rem;
                        border-radius: 0.05rem;
                    }
                }

                .bg-right {
                    width: 2.43rem;
                    height: 100%;
                    text-align: center;

                    .bg-right-header-title {
                        height: 0.25rem;
                        cursor: pointer;
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        opacity: 1;
                        display: block;
                        margin-top: 0.2rem;
                        margin-bottom: 0.1rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        padding-left: 0.1rem;
                        padding-right: 0.1rem;
                    }

                    .bg-right-header-rate {
                        width: 1.8rem;
                        height: 0.19rem;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        div {
                            font-size: 0.14rem;
                            color: #FFFFFF;
                            opacity: 1;
                        }
                    }

                    .bg-right-header-item {
                        width: 2.02rem;
                        height: 0.25rem;
                        margin: 0 auto;
                        margin-top: 0.06rem;
                        border-radius: 0.12rem;
                        background: #FFFFFF;
                        opacity: 1;
                        display: flex;
                        align-items: center;

                        div {
                            min-width: 0.3rem;
                            font-size: 0.12rem;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF7700;
                            opacity: 1;
                            margin: 0.14rem 0.1rem;
                        }
                    }
                }
            }

            .comment-merchant {
                padding-left: 0.2rem;
                display: flex;
                align-items: center;
                /*border: 1px solid red;*/

                span {
                    font-size: 0.14rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #363C51;
                    opacity: 1;
                    margin-right: 0.15rem;
                }
            }
        }

        .comment-center {
            width: 100%;
            height: 5.69rem;
            background: #FFFFFF;
            opacity: 1;
            border-radius: 0.12rem;
            margin-top: 0.3rem;
            display: flex;

            .comment-center-left {
                width: 2.75rem;
                height: 100%;

                .rate-body-comment {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .good-img-comment {
                        background-color: #dcdcdc;
                        margin-top: 0.6rem;
                        border-radius: 0.1rem;

                        img {
                            width: 205px;
                            height: 180px;
                            border-radius: 0.1rem;
                        }
                    }

                    .good-name-comment {
                        width: 1.88rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        font-size: 0.16rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin-top: 0.1rem;
                        text-align: center;

                    }

                    .good-price-comment {
                        font-size: 0.2rem;
                        color: #FF1010;
                        opacity: 1;
                        margin-top: 0.1rem;
                    }

                    .good-specification {
                        font-size: 0.12rem;
                        font-weight: 400;
                        color: #78849E;
                        opacity: 1;
                        margin-top: 0.1rem;
                    }
                }
            }

            .comment-center-right {
                width: 9.4rem;
                height: 100%;

                .evaluationTime {
                    width: 100%;
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #78849E;
                    opacity: 1;
                    /*text-align: center;*/
                    margin-top: 0.3rem;
                    margin-bottom: 0.33rem;
                    padding-left: 1rem;
                    border: 1px solid red;
                }

                .judge-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 0 0.93rem;

                    .rate-item {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-start;
                        margin-bottom: 0.21rem;
                        /*border: 1px solid red;*/

                        .exhibition {
                            font-size: 0.14rem;
                            width: 6rem;
                            resize: none;
                            display: inline-block;
                        }

                        .pin {
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #363C51;
                            opacity: 1;
                            display: inline;
                            width: 0.9rem;
                            margin-right: 0.2rem;
                            text-align: right;
                        }

                        .num {
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #363C51;
                            opacity: 1;
                            margin-left: 0.1rem;
                        }
                    }

                    .button {
                        width: 100%;
                        height: 0.45rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 0.4rem;

                        .btn {
                            width: 1.03rem;
                            height: 0.4rem;
                            background: #39BEB8;
                            opacity: 1;
                            border-radius: 0.2rem;
                            font-size: 0.14rem;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin-right: 0.3rem;

                            img {
                                width: 13.69px;
                                height: 13.69px;
                                margin-right: 0.05rem;
                            }
                        }

                        .btn:hover {
                            opacity: 0.8;
                        }


                    }
                }
            }
        }
    }
</style>

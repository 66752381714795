

































































































































































  import {Component, Vue} from "vue-property-decorator";
  import {Button, Form, Icon, Input, InputNumber, message, Modal, Select, Upload} from "ant-design-vue";
  import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
  import highConfigurationStep from "../../../components/highConfigurationStep.vue";
  import RightOrderDetail from "@/components/RightOrderDetail.vue";
  import ReturnStep from "@/components/ReturnStep.vue";
  import {Mutation, State} from "vuex-class";
  import {
    applicationInfo,
    getChangeCode,
    orderInfo,
    refundReasons,
    returnMoney,
    returnMoneyUpdate,
    returnServiceOrderCode,
    uploadPicture
  } from "@/services/api";

  Vue.use(Button);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Icon);
Vue.use(InputNumber);
// Vue.use(message);
@Component({
  components: {
    RightOrderDetail,
    ReturnStep,
    Breadcrumb,
    highConfigurationStep
  }
})
export default class extends Vue {
  refundMoneyGet: any = 0.00;
  refundMoneyDeduct: any = 0.00;

  refundMoneyChange(e) {

    this.refundMoneyDeduct = (e * 0.006).toFixed(2);
    this.refundMoneyGet = (e - +this.refundMoneyDeduct).toFixed(2);
  }

  uploadPicture: any = uploadPicture();
  @State refundReasons;
  @Mutation SET_RETURNID;
  previewVisible = false;
  isModify = false;
  userInfo: any = window.localStorage.getItem("userInfo");
  header = {
    token: JSON.parse(this.userInfo).token
  };
  allRefundReasons = {};
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "我的服务",
      path: "/personal/myService",
      significance: 0
    },
    {
      name: "申请退款",
      path: "/",
      significance: 1
    },
  ]

  getResions() {
    this.allRefundReasons={}
    if (this.orderStatusNum === 295) {
      refundReasons({type: '0'}).then(res => {
        let allRefundReasons = {};
        res.data.forEach(el => {
          allRefundReasons[el.dictionaryId] = el.dictionaryName;
        });
        this.allRefundReasons = allRefundReasons;
      });

    } else {
      refundReasons({type: '1'}).then(res => {
        let allRefundReasons = {};
        res.data.forEach(el => {
          allRefundReasons[el.dictionaryId] = el.dictionaryName;
        });
        this.allRefundReasons = allRefundReasons;
      });
    }


  }


  previewImage = "";
  value = 2;
  orderStatusNum: any = 0;
  serviceItem: any = {};
  fileList: any = [];
  Obj: Object = {};
  stepList = [
    {
      name: "买家申请退款"
    },
    {
      name: "卖家处理退款申请"
    },
    {
      name: "退款完毕"
    }
  ];
  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 3}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 21}
    }
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 15,
        offset: 9
      }
    }
  };
  form: any;

  submitLoading: boolean = false;
  allowSubmit: boolean = true;

  handleSubmit(e) {
    // 防止重复点击
    if (this.allowSubmit) {

      e.preventDefault();

      this.submitLoading = true
      this.allowSubmit = false

      this.form.validateFields((err, values) => {
        if (!err) {
          localStorage.setItem("serviceForm1", JSON.stringify(values));
          this.Obj["refundType"] = 215;
          this.Obj["refundNotice"] = values.refundNotice;
          this.Obj["refundMoney"] = values.refundMoney;
          this.Obj["pictures"] = [];
          this.Obj["changeCode"] = this.changeCode;
          for (let [key, value] of Object.entries(this.allRefundReasons)) {
            if (value === values.refundReason) {
              this.Obj["refundReason"] = key;
            }
          }
          this.Obj["serviceState"] = 399;
          if (this.isModify) {
            this.Obj["returnId"] = this.$route.query.returnId;
            this.fileList.forEach(el => {
              if (el.response != undefined) {
                this.Obj["pictures"].push(el.response.filePath);
              }
              if (el.url != undefined) {
                this.Obj["pictures"].push(el.url);
              }
            });

            this.returnMoneyUpdate();

          } else {
            this.Obj["serviceOrderRelation"] = this.$route.query.relationId;
            this.Obj["pictures"] = this.fileList.map(el => el.response.filePath);
            this.returnMoney();
          }
        } else {
          // 提交失败恢复可点击的状态
          this.allowSubmit = true
          this.submitLoading = false
        }
      });

    }

  }

  orderInfo(relationId) {
    orderInfo({relationId: relationId}).then(res => {
      console.log('0000000000');
      console.log(res.data)
      this.serviceItem = res.data;
      this.refundMoneyChange(this.serviceItem["totalPrice"].toFixed(2));
      //根据服务订单号查询该服务状态
      returnServiceOrderCode(res.data.orderCode, relationId).then(res => {
        this.orderStatusNum = res.data;
        this.getResions();
        if (this.$route.query.returnId) {
          this.isModify = true;
          this.applicationInfo(this.$route.query.returnId);
        }
      });
    });


  }

  // 防重复点击锁
  changeCode = "";

  mounted() {
    const that = this
    getChangeCode().then(res => {
      if (res && res.code == 0) {
        that.changeCode = res.data
      }
    }).then(_ => {
      let relationId = that.$route.query.relationId;
      // let returnId = that.$route.query.returnId;
      that.orderInfo(relationId);
      // if (returnId) {
      //   that.isModify = true;
      //   that.applicationInfo(returnId);
      // }
    })
  }

  returnMoney() {
    returnMoney({...this.Obj}).then(res => {
      if (res.code === 0) {
        this.$router.push({
          path: "/personal/returnService2",
          query: {
            relationId: this.$route.query.relationId,
            returnId: res.data.returnId
          }
        });
        this.SET_RETURNID(res.data.returnId);
      } else {
        message.error("提交失败，请稍后重试");
      }
    }).then(_ => {
      // 提交成功恢复可点击的状态
      this.allowSubmit = true
      this.submitLoading = false
    }).catch(e => {
      // 提交失败恢复可点击的状态
      this.allowSubmit = true
      this.submitLoading = false
    });
  }

  returnMoneyUpdate() {
    returnMoneyUpdate({...this.Obj}).then(res => {
      if (res.code === 0) {
        this.$router.push({
          path: "/personal/returnService2",
          query: {
            relationId: this.$route.query.relationId,
            returnId: this.$route.query.returnId
          }
        });
        // this.SET_RETURNID(res.data.returnId);
      } else {
        message.error("提交失败，请稍后重试");
      }
    }).then(_ => {
      // 提交成功恢复可点击的状态
      this.allowSubmit = true
      this.submitLoading = false
    }).catch(e => {
      // 提交失败恢复可点击的状态
      this.allowSubmit = true
      this.submitLoading = false
    });
  }
//获取修改数据并回填
  applicationInfo(returnId) {
    applicationInfo({
      returnId: returnId
    }).then(res => {
      this.Obj["serviceOrderRelation"] = this.$route.query.relationId;
      this.Obj["refundType"] = 215;
      this.Obj["refundNotice"] = res.data.refundNotice;
      this.Obj["refundMoney"] = res.data.refundMoney;
      this.Obj["refundReason"] = this.allRefundReasons[res.data.refundReason];
      res.data.pictures.forEach((el, index) => {
        let fileobj: any = {
          uid: index,
          name: "image.png",
          status: "done",
          url: el
        };
        (this as any).fileList.push(fileobj);
      });
      this.Obj["serviceState"] = res.data.serviceState;
      this.form.setFieldsValue(this.Obj);
    });
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  handleCancel() {
    this.previewVisible = false;
  }

  handlePreview(file: any) {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  }

  handleChange({fileList}: any) {
    this.fileList = fileList;
  }
}

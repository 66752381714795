


















































































    import {Component, Vue} from "vue-property-decorator";
    import {Button, Form, Input, Radio} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";

    Vue.use(Form);
Vue.use(Input);
Vue.use(Button);
Vue.use(Radio);

@Component({
  components: {
    PersonalHeader
  }
})
export default class extends Vue {
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 }
    }
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 8
      }
    }
  };

  typeOption = [{ label: "个人", value: "1" }, { label: "企业", value: "2" }];

  contentOption = [
    { label: "服务明细", value: "1" },
    { label: "服务类别", value: "2" }
  ];

  handleTypeChange(e: any) {
    this.form.setFieldsValue({
      receiptType: e.target.value
    });
  }

  handleSubmit() {}
  form: any;
  beforeMounted() {
    this.form = this.$form.createForm(this);
  }
}











































































    import {Component, Vue} from "vue-property-decorator";
    import {Table} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import {deleteConsultationId, getConsultationsList} from "@/services/api";

    Vue.use(Table);

@Component({
  components: {
    PersonalHeader
  }
})
export default class extends Vue {
  pagination = {
    total: 0,
    pageSize: 5,
    pageIndex: 1
  };
  columns = [
    {
      title: "序号",
      dataIndex: "index",
      width: "1rem",
      align: "center",
      scopedSlots: { customRender: "index" }
    },
    {
      title: "咨询问题",
      dataIndex: "question",
      width: "2.4rem",
      scopedSlots: { customRender: "question" },
      align: "center"
    },
    {
      title: "咨询价格",
      dataIndex: "advisoryFare",
      width: "1.2rem",
      align: "center"
    },
    {
      title: "咨询时间",
      dataIndex: "advisoryTime",
      width: "1.2rem",
      align: "center"
    },
    {
      title: "咨询回复",
      dataIndex: "advisoryReply",
      width: "2.4rem",
      align: "center"
    },
    {
      title: "回复时间",
      dataIndex: "replyTime",
      width: "1.2rem",
      align: "center"
    },
    {
      title: "操作",
      key: "other",
      scopedSlots: { customRender: "other" },
      align: "center",
      width: "1.5rem"
    }
  ];
  consultations = [];
  created() {
    this.getConsultationsList();
  }
  onChangePageSize(page, pageSize) {
    window.scrollTo({ top: 0 });
    this.pagination.pageIndex = page;
    this.getConsultationsList();
  }
  deleteConsultationId(index) {
    let advisoryId: any = this.consultations[index];
    deleteConsultationId({
      consultationId: advisoryId.advisoryId
    }).then(res => {
      this.consultations = [];
      this.getConsultationsList();
    });
  }
  getConsultationsList() {
    getConsultationsList({
      ...this.pagination
    }).then(res => {
      let data = res.data;
      this.pagination.total = data.totalCount;
      data.consultations.forEach((item: any, index: any) => {
        let obj: any = {};
        obj.advisoryFare = item.advisoryFare;
        obj.question = {
          pic: item.pic,
          question: item.question
        };
        obj.advisoryTime = item.advisoryTime;
        obj.advisoryReply = item.advisoryReply;
        obj.replyTime = item.replyTime;
        obj.advisoryId = item.advisoryId;
        (this as any).consultations.push(obj);
      });
      this.pagination.pageSize = data.pageSize;
    });
  }
}

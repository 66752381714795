

    import {Component, Vue} from "vue-property-decorator";

    @Component({})
export default class detailLike extends Vue {
  items = [
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
    {
      margin: 10,
      price: 50,
      linePrice: 100,
      commodityId: "demo001",
      brand: "小明同学",
      commodityName: "洗衣粉",
    },
  ];
  mounted() {
    this.totalPage = Math.ceil(this.items.length / 6);
    if (this.totalPage == 1) this.leftCan = this.rightCan = false;
  }
  ulLeft = 0;

  leftCan = true;
  rightCan = false;
  totalPage = 1;
  page = 1;
  leftTo() {
    if (this.page < this.totalPage) {
      this.ulLeft -= 11.67;
      this.page++;
      this.leftCan = this.page != this.totalPage;
      this.rightCan = this.page != 1;
    }
  }

  rightTo() {
    if (this.page > 1) {
      this.ulLeft += 11.67;
      this.page--;
      this.rightCan = this.page != 1;
      this.leftCan = this.page != this.totalPage;
    }
  }
}

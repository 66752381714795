





















































































































import {Component, Vue} from "vue-property-decorator";
import {Checkbox, Icon, Input, List, message, Tabs, Tooltip,} from "ant-design-vue";

import ServiceTrashItem from "@/components/Personal/MyService/ServiceTrashItem.vue";
import {State} from "vuex-class";
import {deleteByQuery, getServiceOrdersList, huanyuanByQuery,} from "@/services/api";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
    components: {
        ServiceTrashItem,
    },
})
export default class extends Vue {
    @State allOrderStatus;
    @State allServiceStaus;
    serviceStatus = {};
    isAllCheck: Boolean = false;
    deleteModel: Boolean = false;
    serviceOrderInfoList: Array<any> = [];

    data() {
        return {
            OrdersStatus: {},
            data: [],
            // isAllCheck: false
        };
    }

    chnabftab(e) {
        e.preventDefault();
        this.$emit("chnabftab");
    }

    pagination = {
        total: 0,
        pageSize: 1000,
        pageIndex: 1,
    };

    onChange(e) {
        this.isAllCheck = !this.isAllCheck;
        if (this.isAllCheck) {
            this.serviceOrderInfoList = [];
            (this as any).data.forEach((el) => {
                el.ischeck = true;
                this.serviceOrderInfoList.push({
                    serviceOrderId: el.serviceOrderInfoId,
                });
            });
        } else {
            let data = (this as any).data.filter((el) => el.ischeck === true);
            if (data.length === this.data.length) {
                this.serviceOrderInfoList = [];
                (this as any).data.forEach((el) => {
                    el.ischeck = false;
                });
            }
        }
    }

    getOrderInfoList(obj) {
        this.data[obj.id].ischeck = true;
        this.serviceOrderInfoList.push({serviceOrderId: obj.serviceOrderInfoId});
        if (this.serviceOrderInfoList.length == this.data.length) {
            this.isAllCheck = true;
        } else {
            this.isAllCheck = false;
        }
    }

    deleteOrderInfoList(obj) {
        this.data[obj.id].ischeck = false;
        let newArr: any = [];
        for (let i = 0; i < this.serviceOrderInfoList.length; i++) {
            let newObj: any = JSON.parse(
                JSON.stringify(this.serviceOrderInfoList[i])
            );
            newArr.push(newObj);
        }
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].serviceOrderId === obj.serviceOrderInfoId) {
                newArr.splice(i, 1);
            }
        }
        this.serviceOrderInfoList = newArr;
        this.isAllCheck = false;
    }

    created() {
        this.getList();
    }

    onChangePageSize(page, pageSize) {
        this.pagination.pageIndex = page;
        this.getList();
    }

    getList() {
        (this as any).data = [];
        getServiceOrdersList({
            ...this.pagination,
            status: 6,
        }).then((res) => {
            let data = (res as any).data;
            this.pagination.total = data.totalCount;
            data.serviceOrderInfos.forEach((el, i) => {
                (this as any).data.push({
                    ischeck: false,
                    id: i,
                    ...el,
                });
            });
            //拆分规格
            for (let item of (this.data as any)) {
                (item["services"] as any).forEach((item) => {
                    if (item.skuInfo != null) {
                        //服务规格列表拆开
                        item.skuInfoList.forEach(el => {
                            const mapItem = el.skuValue
                            let cc = typeof (mapItem);
                            if (cc === 'string') {
                                let str = mapItem.replaceAll('[', '').replaceAll(']', '');
                                let itemArr = str.split(', ');
                                el.skuValue = itemArr;
                            }
                        })
                        //当前服务规格拆分
                        let str = item.skuInfo.replaceAll('', '');
                        let itemArrOne = str.split(' ');
                        item.skuInfo = itemArrOne;
                    }
                });
            }
            this.pagination.pageSize = data.pageSize;
        });
    }

    comfrimDelete() {
        this.deleteByQueryEvent();
        this.deleteModel = false;
    }

    singleDelete(id) {
        this.serviceOrderInfoList = [];
        this.serviceOrderInfoList.push({serviceOrderId: id});
        this.deleteByQueryEvent();
    }

    singleHuanYuan(id) {
        this.serviceOrderInfoList = [];
        this.serviceOrderInfoList.push({serviceOrderId: id});
        this.huanyuanByQueryEvent();
    }

    deleteByQuery() {
        this.deleteModel = true;
    }

    //
    huanyuanByQuery() {
        this.huanyuanByQueryEvent();
    }

    deleteByQueryEvent() {
        deleteByQuery({
            serviceOrderInfoIdList: this.serviceOrderInfoList,
        }).then((res) => {
            if (res.code === 0) {
                message.success("删除成功");
                this.serviceOrderInfoList = [];
                this.getList();
            } else {
                message.error("删除失败");
            }
        });
    }

    huanyuanByQueryEvent() {
        huanyuanByQuery({
            serviceOrderInfoList: this.serviceOrderInfoList,
        }).then((res) => {
            if (res.code === 0) {
                message.success("还原成功");
                this.serviceOrderInfoList = [];
                this.getList();
            } else {
                message.error("还原失败");
            }
        });
    }


}

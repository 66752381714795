import { render, staticRenderFns } from "./GoodsRightOrderDetail.vue?vue&type=template&id=294e0f96&scoped=true&"
import script from "./GoodsRightOrderDetail.vue?vue&type=script&lang=ts&"
export * from "./GoodsRightOrderDetail.vue?vue&type=script&lang=ts&"
import style0 from "./GoodsRightOrderDetail.vue?vue&type=style&index=0&id=294e0f96&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294e0f96",
  null
  
)

export default component.exports
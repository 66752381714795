






































































































































    import {Component, Vue} from "vue-property-decorator";
    import {Button, Form, Icon, Input, message, Modal, Select, Upload,} from "ant-design-vue";
    import RightOrderDetail from "@/components/RightOrderDetail.vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import highConfigurationStep from "../../../components/highConfigurationStep.vue";
    import {
        afterSaleServiceHistory,
        afterSaleStatus,
        getInvolvedService,
        returnMoneyCancel,
        returnServiceinfo,
        whetherRevokeRefundService
    } from "@/services/api";

    Vue.use(Button);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Icon);
// Vue.use(message);
@Component({
  components: {
    RightOrderDetail,
    ReturnStep,
    Breadcrumb,
    highConfigurationStep
  },
})
export default class extends Vue {
  refund = true;
  stepList = [
    {
      name: "买家申请退款",
    },
    {
      name: "卖家处理退款申请",
    },
    {
      name: "退款完毕",
    },
  ];
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "我的服务",
      path: "/personal/myService",
      significance: 0
    },
    {
      name: "申请退款",
      path: "/",
      significance: 1
    },
  ]

  getOperator(id) {
    if (!id) return "";
    switch (id) {
      case 334:
        return "买家";
      case 335:
        return "卖家";
      case 336:
        return "管理员";
    }
  }

  serviceItem = [];
  serveCancel =false;
  status =false;
  serveCancelTwo =false;
  remainTime: any = {
    seconds: 0,
    days: 0,
    hours: 0,
    minutes: 0,
  };
  HistoryList = [];
  returnId = "";
  created() {
    let returnId = this.$route.query.returnId;
    this.returnId = returnId;
    this.returnServiceinfo(returnId);
    this.afterSaleStatus(returnId);
    this.afterSaleServiceHistory(returnId);
    this.whetherRefund(returnId)
  }
  //查看是否能撤销申请
  whetherRefund(value) {
    whetherRevokeRefundService({
      returnId: value
    }).then(res => {
      if (res.data === true) {
        this.refund = true;
      } else {
        this.refund = false;
      }
    });
  }
  returnServiceinfo(returnId) {
    returnServiceinfo({ returnId: returnId }).then((res) => {
      this.serviceItem = res.data;
    });
  }
  returnMoneyCancelEvent() {
    this.serveCancelTwo=true;
  }
  //申请平台介入
  intervene() {
    this.serveCancel = true
  };
  enshrine(){
    getInvolvedService({refundId: this.$route.query.returnId}).then(res => {
      if (res && res.code == 0) {
        message.success('平台介入成功');
      } else {
        message.error("申请平台介入失败，请稍后重试");
      }
      this.serveCancel = false
    });
  }
  statusDic = 0;
  afterSaleStatus(returnId) {
    afterSaleStatus({
      afterSaleId: returnId,
    }).then((res) => {
      if (res.data.afterSaleStatus == "退款成功") {
        this.$router.push({
          path: "/personal/returnService3",
          query: {
            relationId: this.$route.query.relationId,
            returnId: this.$route.query.returnId,
          },
        });
      }
      this.status = true;
      this.statusDic = res.data.statusDic;
      // this.remainTime = res.data.remainTime;
      this.remainTime.days = res.data.remainTime.days;
      this.remainTime.hours = res.data.remainTime.hours;
      this.remainTime.minutes = res.data.remainTime.minutes;
      this.remainTime.seconds = res.data.remainTime.seconds;
      this.payTime();
    });
  }
  afterSaleServiceHistory(returnId) {
    afterSaleServiceHistory({
      serviceRefundInfo: returnId,
    }).then((res) => {
      this.HistoryList = res.data;
    });
  }
  payTime() {
    let that = this;
    function time() {
      if (that.remainTime.seconds != 0) {
        that.remainTime.seconds--;
      } else if (that.remainTime.minutes != 0) {
        that.remainTime.minutes = that.remainTime.minutes - 1;
        that.remainTime.seconds = 59;
      } else if (that.remainTime.hours != 0) {
        --that.remainTime.hours;
        that.remainTime.minutes = 60;
        // this.time.seconds = 60;
      } else if (that.remainTime.days != 0) {
        --that.remainTime.days;
        that.remainTime.hours = 24;
        that.remainTime.seconds = 60;
      }
    }
    window.setInterval(time, 1000);
  }

  //姓名加密
  getNickName(value) {
    if(value){
      const name: any[] = value.split("");
      return name[0] + "*****" + name[name.length - 1];
    }
  }

  returnMoneyCancel() {
    returnMoneyCancel({
      returnId: this.$route.query.returnId,
    }).then((res) => {
      if (res.code === 0) {
        message.success("申请已撤消");
        this.$router.push(`/personal/returnService3?returnId=${this.returnId}`);
      }
      this.serveCancelTwo=false;
    });
  }


  modifyApply() {
    this.$router.push({
      path: "/personal/returnService1",
      query: {
        returnId: this.$route.query.returnId,
        relationId: this.serviceItem["serviceRelationId"],
      },
    });
  }
}

<template>
    <div>
        <div class="fixation" :style=" isFixed
          ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: '999',
              backgroundColor: '#fff',
              paddingTop: '0.1rem',
            }
          : {}
      ">
            <div class="menu">
                <div class="logo" @click="jumpToHome"></div>
                <div class="search">
                    <div class="screeningCondition">
                        <div class="condition">
                            <div class="condition_list" :class="this.choice===395?'condition_list_one':''"
                                 @click="choose(395)">全部信用记录
                            </div>
                            <div class="condition_list" :class="this.choice===397?'condition_list_one':''"
                                 @click="choose(397)">正常信用记录
                            </div>
                            <div class="condition_list" :class="this.choice===396?'condition_list_one':''"
                                 @click="choose(396)">异常信用记录
                            </div>
                        </div>
                    </div>
                    <a-input-search
                            :value="searchVal"
                            @change="onSearchChange"
                            @search="onSearch"
                            placeholder="请输入服务商、养老机构、商家"
                            enterButton="搜索"
                    />
                </div>
                <div class="appImg">
                    <!--                <img style="width: 0.9rem;height: 0.85rem" src="../../../assets/images/app.png"/>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import {Component} from "vue-property-decorator";
    //
    // @Component({
    //
    // })

    export default {
        props: {
            handleNavSearchData: Function,
        },
        data() {
            return {
                choice: 395,
                searchVal: "",
                isFixed: false,
            };
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
            const value = sessionStorage.getItem("creditSearch") || "";
            if (value) {
              const obj = JSON.parse(value);
              this.choice = obj.choice;
              this.searchVal = obj.value;
            } else {
              this.choice = 395;
              this.searchVal = "";
            }
        },
        destroyed() {
            this.choice=395;
        },
        methods: {
            jumpToHome() {
                this.$router.push(`/creditInvestigation/creditReportingHome`);
            },
            //input框搜索
            onSearchChange(e) {
                this.searchVal = e.target.value;

            },
            //选择条件
            choose(val) {
                this.choice = val;

            },
            onSearch(value) {
                let data = {
                    "value": this.searchVal,
                    "choice": this.choice
                }
                sessionStorage.setItem("creditSearch", JSON.stringify(data));
                const path = window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1,
                    window.location.href.length
                );
                if (path !== "creditReportingServicePage") {
                    this.$router.push("/creditInvestigation/creditReportingServicePage");
                }
                if (this.$props.handleNavSearchData) {
                    this.$props.handleNavSearchData(data);
                }
            },
            //查看推荐
            seeRecommendations(value) {

            },
            //下拉获取
            handleScroll(e) {
                const scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;

                if (scrollTop > 120) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
                this.$store.commit("show_zxptDiv", this.isFixed);
            },
        }
    }
</script>

<style type="text/less" lang="less" scoped>


    .fixation {
        width: 100%;
        z-index: 999;
        background-color: #fff;
        padding-top: 0.1rem;
        border-bottom: 1px solid #d9d9d9;
        height: 1rem;
        min-width: 15.2rem;

        .menu {
            white-space: nowrap;
            height: 0.87rem;
            position: relative;
            width: 17.2rem;

            .logo {
                float: left;
                height: 0.69rem;
                width: 1.33rem;
                /*border: 1px solid red;*/
                background-image: url("../../../assets/images/homeImg/组 1472@2x.png");
                background-position: center center;
                background-size: 100%;
                background-repeat: no-repeat;
                cursor: pointer;
                margin-left: 3rem;
                margin-top: 0.05rem;
            }

            .search {
                height: 0.77rem;
                margin-top: 0.3rem;
                position: absolute;
                top: -35%;
                left: 35%;
                /*border: 1px solid red;*/

                .screeningCondition {
                    height: 0.3rem;
                    width: 70%;
                    /*border: 1px solid red;*/
                    margin-bottom: 0.03rem;
                    display: flex;
                    align-items: center;

                    .condition {
                        width: 80%;
                        height: 0.5rem;
                        display: flex;
                        align-items: center;

                        .condition_list {
                            height: 0.3rem;
                            font-size: 0.14rem;
                            /*padding-left: 0.2rem;*/
                            padding-right: 0.3rem;
                            font-weight: bold;
                            color: #454F63;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            /*border-right: 1px solid red;*/
                        }

                        .condition_list_one {
                            color: #f6ab1a;
                        }


                    }
                }

                /deep/ .ant-input {
                    width: 5rem;
                    font-size: 0.16rem;
                    line-height: 0.84rem;
                    height: 0.4rem;
                }
            }

            .appImg {
                position: absolute;
                top: -0.44rem;
                left: 15.1rem;
            }

            /deep/ button.ant-btn.ant-btn-primary.ant-input-search-button {
                width: 0.9rem;
                height: 0.4rem;
                background-color: #60c7c6;
                border-radius: 0;
                border-color: #60c7c6;
                font-size: 0.2rem;
            }

            /deep/ input.ant-input:focus {
                border-color: #d9d9d9;
                border-radius: 0;
                -webkit-box-shadow: 0 0 0 0;
                box-shadow: 0 0 0 0;
            }

            /deep/ input.ant-input:hover {
                border-color: #d9d9d9;
            }

            /deep/ input.ant-input {
                border-radius: 0;
            }


        }

    }

</style>

<template>
  <div class="underneath">
    <div class="model">
      <img src="../../assets/images/homeImg/色相饱和度1拷贝2@2x.png"/>
      <div class="matter">
        <div v-if="this.$props.searchContent" class="title">很抱歉，在"{{this.cityName}}"没有找到与"{{this.$props.searchContent}}"相关条件的结果哦！</div>
        <div v-else class="title">很抱歉，在"{{this.cityName}}"没有找到相关条件的结果哦！</div>
        <p>温馨提示:</p>
        <p>1.建议您重新选择筛选条件,或者<a  @click.prevent="$router.push('/pickCity')">切换城市进行搜索</a>。</p>
        <p>2.要不您换个搜索词，我再帮您找找。</p>
        <p>3.没有对应条件的综合结果。<a @click="allDAta">查看全部综合结果</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cityName: "",
    };
  },
  props: { searchContent: String },
  created() {
    this.getCityName();
  },
  methods: {
    allDAta(evt) {
      evt.preventDefault();
      let index = 0;
      this.$emit("search");
    },
    getCityName() {
      const allList = JSON.parse(sessionStorage.getItem("allAreaData"));
      const cityId = localStorage.getItem("provinceId");
      let obj = {};
      allList.map((item) => {
        const m = item.cityList.find((item) => item.cityId == cityId);
        m ? (this.cityName = m.shortName) : "";
      });
      if (cityId == "0") {
        this.cityName = "全国";
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .underneath {
    height: 4rem;
    /*width: 14rem;*/
    margin: 0 auto;
    margin-top: 0.1rem;
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    justify-content: center;

    .model {
      display: flex;
      align-items: center;

      img {
        width: 1.79rem;
        height: 1.13rem;
        /*border: 1px solid red;*/
      }

      .matter {
        margin-left: 0.2rem;

        .title {
          font-size: 0.16rem;
          font-weight: bold;
          color: #2A2E43;
          opacity: 1;
          margin-bottom: 0.1rem;
        }

        p {
          font-size: 0.14rem;
          font-weight: 400;
          color: #454F63;
          opacity: 1;
          margin: 0;
          line-height: 0.25rem;
          /*display: inline-block;*/
          a{
            color: #38B4B2;
            margin-left: 0.02rem;
            cursor: pointer;
          }
        }

      }
    }

  }
</style>

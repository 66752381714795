










































































    import {Component, Vue} from "vue-property-decorator";
    import RightOrderDetailService from "@/components/RightOrderDetailService.vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import GoodsRightOrderDetail from "../../../components/GoodsRightOrderDetail.vue";
    import highConfigurationStep from "../../../components/highConfigurationStep.vue";
    import {aftersalesApplicationStatus, applicationDetails, DetailsRefund, NegotiationHistory} from "@/services/api";

    @Component({
        components: {
            RightOrderDetailService,
            ReturnStep,
            GoodsRightOrderDetail,
            highConfigurationStep
        }
    })
    export default class extends Vue {
        returnId: any = ""; // 退款Id
        commodityRelationId: any = ""; // 商品订单Id
        serviceItem: any = {};
        HistoryList: any = {};
        status = false;
        statusDic:any="";
        detailsRefund: any = {};
        returnStatusDetail: any = {};
        serviceHistory: any = []; // 协商历史
        stepList = [
            {
                name: "买家申请退款"
            },
            {
                name: "商家处理退款申请"
            },
            {
                name: "买家退货"
            },
            {
                name: "退款完毕"
            }
        ];
        stepList1 = [
            {
                name: "买家申请退款"
            },
            {
                name: "商家处理退款申请"
            },
            {
                name: "买家退货"
            },
            {
                name: "退款关闭"
            }
        ];

        async created() {
            this.returnId = this.$route.query.returnId;
            this.commodityRelationId = this.$route.query.commodityRelationId;

            this.orderInfo(this.commodityRelationId)
            this.afterSale(this.returnId)
            this.applicationStatus(this.returnId)
            this.negotiation(this.returnId);
        }

        //获取详情页面数据
        orderInfo(commodityRelationId) {
            DetailsRefund({commodityRelationId: commodityRelationId}).then(res => {
                this.serviceItem = res.data;
            });
        }

        //查看售后申请详情
        afterSale(value) {
            applicationDetails({
                returnId: value
            }).then(res => {
                if (res.data != '') {
                    this.detailsRefund = res.data;
                    this.status = true;
                }
            });

        }
        //姓名加密
        getNickName(value) {
            if(value.length>3){
                const name: any[] = value.split("");
                return name[0] + "*****" + name[name.length - 1];
            }else{
                return  value
            }
        }

//查询售后申请状态
        applicationStatus(value) {
            aftersalesApplicationStatus({returnId: value}).then(res => {
                this.returnStatusDetail = res.data;
                this.statusDic = res.data.statusDic
            });
        }

        //获取协商历史
        negotiation(returnId) {
            NegotiationHistory({returnId: returnId}).then(res => {
                this.serviceHistory = res.data;
            });
        }

    }

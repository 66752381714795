






















































import {Component, Prop, Vue} from "vue-property-decorator";
import {http} from "@/services/http";

@Component
export default class shopNav extends Vue {
    @Prop() selectTypeId: string;
    @Prop() shopId: string;

    list = [];
    showChild = false;

    selectType(typeId: string, secondTypeId: string) {
        this.$emit("selectType", typeId, secondTypeId);
    }

    created() {
        this.getShopNav();
    }

    toHome() {
        const {href} = this.$router.resolve("/mall/shop/" + this.shopId);
        open(href, "_self");
    }

    getShopNav() {
        http.get(`/api/v1/merchant/detail/categories`, {
            params: {
                merchantId: this.shopId,
                showType: 1,
            },
        })
            .then((res: any) => {
                if (res.code == 0) {
                    res.data.unshift({firstTypeName: '全部商品', firstTypeId: '', secondTypes: []});
                    this.list = res.data;
                }
            });
    }
}













import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Footer extends Vue {
    thisYear = 2019;


    created() {
        this.thisYear = new Date().getFullYear();
    }

    scheme(item) {
        if (item == '/mall') {
            (location.href.includes("test") || location.href.includes("develop") || location.href.includes("adv")) ? open("/mall") : alert("暂未开放");
        } else {
            this.$router.push(item)
        }

    }
}

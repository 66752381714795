<template>
  <div id="mallHomeApp">
    <div id="banner" >
      <banner :bannerList="bannerList" />
    </div>
    <div id="home_body">
      <MallSpick :data="panicBuying" hidden v-if="panicBuying"></MallSpick>
      <Abbreviation
        hidden
        :couponList="couponList"
        :brandRecommend="brandRecommend"
        :hotLeaderboard="hotLeaderboard"
      ></Abbreviation>
      <sliderBar ></sliderBar>
    </div>
    <div id="Selling">
      <Selling v-if="hotRecommend" :data="hotRecommend"></Selling>
    </div>
    <div id="home_body2" >
      <Products :dataAll="floors"></Products>
    </div>
  </div>
</template>
<script>
    import {Abbreviation, banner, MallSpick, Products, Selling, sliderBar,} from "@/components/mallComponents";
    import {http} from "@/services/http";

    export default {
    name: "Home",
    components: { MallSpick, Abbreviation, Selling, Products, sliderBar, banner },

    data() {
      return {
        panicBuying: "",
        hotRecommend: "",
        bannerList: "",
        couponList: [],
        floors: [],
        brandRecommend: [],
        hotLeaderboard: [],
      };
    },
    created() {
      http.get("/api/v1/shop-mall/home").then((res) => {
        if (res.code == 0) {
          this.panicBuying = res.data.panicBuying || "";
          this.hotRecommend = res.data.hotRecommend;
          this.bannerList = res.data.bannerList;
          this.floors = res.data.floors;
          this.brandRecommend = res.data.brandRecommend;
          this.hotLeaderboard = res.data.hotLeaderboard;
          // this.couponList =  ;
          res.data.couponList.map((item, index) => {
            if (index % 4 == 0) {
              this.couponList.push(res.data.couponList.slice(index, index + 4));
            }
          });
        }
      });
    },
  };
</script>
<style lang="less" scoped>
  #mallHomeApp{
    /*border: 1px solid red;*/
  }
#Selling {
  background-color: #F5F7FA;
}
#home_body,
#home_body2 {
  width: 13.8rem;
  margin: auto;
  position: relative;
}
#banner {
 /*height: 5rem;*/
 /* width: 100%;*/
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
  /*border: 1px  solid red;*/
}
</style>


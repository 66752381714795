





















































































































































  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {Mutation, State} from "vuex-class";
  import {message} from "ant-design-vue";
  import {cancelOrderSubmit, getCancelServiceList, jointOrder, serviceOrderConfirm} from "@/services/api";

  @Component({
  props: {
    // infoData: Object,
    // status: Number
  }
})
export default class ServiceDetailInfo extends Vue {
  @Prop() ifRefund: any;
  @Prop() firstRelationId: any;
  @Prop() firstRefundId: any;
  @Prop() serviceId: any;

  tuikuanDetail() {
    this.$router.push(
            `/personal/returnService2?returnId=${this.firstRefundId}&relationId=${this.firstRelationId}`
    );
  }

  serveCancel = false;
  serveCancel1=false;
  serviceData={}
  curStatus: Object = {
    paixiafuwu: false,
    maijiafukuan: false,
    fuwutigongshang: false,

    querenfuwu: false,
    pingjia: false
  };
  time = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
  @State detailItem;
  @State curOrderStatus;
  @State payTime;
  @Mutation SET_CURDETAILSTATUS;
  @Watch("detailItem", { immediate: true, deep: true })
  // @Watch("payTime", { immediate: true, deep: true })
  getdetailItem(newVal, oldVal) {
    if(newVal.contactPersonDetail.hasOwnProperty('serviceStore')){
      this.serviceData=newVal.contactPersonDetail.serviceStore[this.serviceId];
    }
    if (this.isHaveAttr(newVal, "orderRemainingPayTime")) {
      this.time.days = newVal.orderRemainingPayTime.days;
      this.time.hours = newVal.orderRemainingPayTime.hours;
      this.time.minutes = newVal.orderRemainingPayTime.minutes;
      this.time.seconds = newVal.orderRemainingPayTime.seconds;
      this.payTimeEvent();
    }


  }
  servingTimeLeft = "";
  servingTimeLeftInterval: any;
  servingTimeCaculate() {
    let {
      days,
      hours,
      minutes,
      seconds
    } = this.detailItem.orderRemainingConfirmTime;
    this.servingTimeLeft = `${days}天${hours}小时${minutes}分${seconds}秒`;
    var lefttime = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
    this.servingTimeLeftInterval = setInterval(() => {
      lefttime--;
      var d = Math.floor(lefttime / (24 * 60 * 60));
      var h = Math.floor((lefttime / (60 * 60)) % 24);
      var m = Math.floor((lefttime / 60) % 60);
      var s = Math.floor(lefttime % 60);
      this.servingTimeLeft = `${d}天${h}小时${m}分${s}秒`;
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.servingTimeLeftInterval);
  }
  getPayTime(newVal, oldVal) {
    this.time = JSON.parse(JSON.stringify(newVal));
    this.payTimeEvent();
  }
  created() {
    if (this.detailItem.statusDic == 294) {
      this.servingTimeCaculate();
    }
  }
  mounted() {
    this.getCancelServiceList();
  }
  cancelServiceList = [];
  getCancelServiceList() {
    getCancelServiceList({ typeId: 89 }).then(res => {
      if (res) {
        this.cancelServiceList = (res as any)["data"];
      }
    });
    // }
  }
  selectChangetsuahValue: any='';
  selectChange() {
    cancelOrderSubmit({
      serviceOrderInfoId: this.detailItem.serviceOrderInfoId,
      cancelReason: this.selectChangetsuahValue
    }).then(res => {
      if ((res as any).code === 0) {
        message.success("提交成功");
        this.$router.push("/personal/myService");
      } else {
        message.error("提交失败");
      }
      this.serveCancel = false;
    });
  }

  payTimeEvent() {
    window.setInterval(() => {
      if (this.time.seconds != 0) {
        this.time.seconds--;
        return;
      } else if (this.time.minutes != 0) {
        this.time.minutes--;
        this.time.seconds = 60;
        return;
      } else if (this.time.hours != 0) {
        this.time.hours--;
        this.time.minutes = 60;
        return;
        // this.time.seconds = 60;
      } else if (this.time.days != 0) {
        this.time.days--;
        this.time.hours = 24;
        this.time.seconds = 60;
        return;
      }
    }, 1000);
  }
  payEvent(val) {
    jointOrder({
      orderId: val.serviceOrderInfoId,
    }).then((res:any) => {
      if(res.code==0){
        this.$router.push({
          path: "/serviceOrderPay",
          query: {
            mainServiceOrderCode: res.mainServiceCode,
          },
        });
      }
    })

  }
  cuiServie() {
    message.success("催服务成功");
    this.curStatus["fuwutigongshang"] = true;
    this.curStatus["querenfuwu"] = false;
    // this.SET_CURDETAILSTATUS(this.curStatus);
    // this.$emit("curStatusChange", this.curStatus);
  }
  comfirmService() {
    serviceOrderConfirm({
      serviceOrderId: this.detailItem.serviceOrderInfoId
    }).then(res => {
      if (res.code === 0) {

        message.success("确认成功");
        this.curStatus["querenfuwu"] = true;
        this.curStatus["fuwutigongshang"] = false;
        this.SET_CURDETAILSTATUS(this.curStatus);
        this.$emit("curStatusChange", this.curStatus);
        this.serveCancel1=false;
      } else {
        message.error("确认失败");
      }
    });
  }
  isHaveAttr(Obj: Object, attr: any) {
    if (Obj.hasOwnProperty(`${attr}`)) {
      return true;
    } else {
      return false;
    }
  }
  invoiceApply() {
    this.$router.push("/personal/serviceReceiptDetail");
  }
}








































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Input, message, Select} from "ant-design-vue";
import {
    fetchAccountInfo,
    fetchArea,
    fetchCity,
    fetchCredentials,
    fetchNation,
    fetchProvince,
    fetchStreet,
    getVerificationCode,
    pictureUpload,
    updateAccount,
    uploadServiceFile
} from "@/services/api";
import {baseUrl, request} from "@/services/http";

Vue.use(Button);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);

@Component({
    components: {}
})
export default class extends Vue {
    get mobileNum() {
        return this.accountInfo.mobile;
    }

    get userName() {
        return this.accountInfo.username;
    }

    step1Tptip = "";
    form: any;
    uploadCoverFileList: any = [];
    ifImageCanUpload: any = true;
    previewImage: any = ""
    previewVisible: any = false;
    coverPicture: any = "";
    provinceOptions: any = []; // 省市区街道列表
    credentialsOptions: any = []; // 获取证件类型
    insuranceOptions: any = []; // 获取社保类型
    nationOptions: any = []; // 获取民族
    formItemLayout: any = {
        labelCol: {
            // sm: {span: 2, offset: 0}
            sm: {span: 7, offset: 0}
        },
        wrapperCol: {
            // xs: {span: 4},
            sm: {span: 15}
        }
    }
    formItemLayout2: any = {
        labelCol: {
            // sm: {span: 2, offset: 0}
            sm: {span: 2, offset: 0}
        },
        wrapperCol: {
            // xs: {span: 4},
            sm: {span: 12}
        }
    }
    accountInfo: any = {
        provinceDefalutValue: [], // 级联选项默认值

        username: "", // 账户名称
        user_id: "", // 用户id
        real_name: "", // 姓名
        mobile: "", // 手机号码
        user_age: "", // 年龄
        province: "", // 省份
        city: "", // 市
        area: "", // 区
        street: "", // 所属街道
        // provinceName: "", // 省份名称
        // cityName: "", // 市名称
        // areaName: "", // 区名称
        // streetName: "", // 所属街道名称
        address_detail: "", // 详细地址
        certificate_type: undefined, // 证件类型
        certificate_num: "", // 证件编号
        ss_type: undefined, // 社保类型
        nation: undefined, // 民族
        height: "", // 身高
        weight: "", // 体重
    };

    beforeCreate() {
        this.form = this.$form.createForm(this);
    }

    async created() {
        await this.getAccountInfo();
        await this.getProvince();
        await this.initArea();
        await this.getCredentials();
        await this.getInsurance();
        await this.getNation();
    }

    async initArea() {
        if (this.accountInfo.province != '') {
            let obj = await this.mfun(
                this.provinceOptions,
                this.accountInfo.province,
                1
            );
            let obj2 = await this.mfun(obj, this.accountInfo.city, 2);
            await this.mfun(obj2, this.accountInfo.area, 3);
        }
    }

    mfun(farr, id, type) {
        return new Promise(resolve => {
            fetchCity({
                areaType: type,
                areaId: id
            }).then(res => {
                if (res && res.code === 0) {
                    res.data.list.forEach((item: any, index: any) => {
                        item.value = item.id;
                        item.label = item.name;
                        item.isLeaf = item.childNum == 0;
                        item.level = type + 1;
                    });
                    farr.forEach(item => {
                        if (item.id == id) {
                            item.children = res.data.list;
                            resolve(item.children);
                        }
                    });
                } else {
                    message.error(res.msg);
                    resolve();
                }
            });
        });
    }

    async userNameCheck(rule: any, value: any, callback: any) {
        if (!value) {
            callback("用户名不能为空");
            return;
        }
        // if (/^[0-9].*/.test(value)) {
        //   callback("用户名不能以数字开头");
        //   return;
        // }
        const reg2 = /^[0-9]+$/;
        if (reg2.test(value)) {
            callback("用户名不能为纯数字，请重新输入");
            return;
        }


        const reg = /^[a-zA-Z0-9 ]{6,20}$/;
        if (!reg.test(value)) {
            callback("用户名可输入字母或数字，6~20个字符");
            return;
        }

        await request(`${baseUrl}/api/v1/exist/${value}`).then(({exist}: any) => {
            if (exist && value != this.accountInfo.username) {
                callback("用户名已被占用，请重新输入");
                return;
            }
        });
    }


    handleChange(info) {
        let fileList = [...info.fileList];
        //限制上传数量，新增的会被替掉
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        this.uploadCoverFileList = fileList;
    }


    //自定义上传
    uploadCoverAll(options) {
        let t = setTimeout(() => {
            clearTimeout(t);
            this.uploadCover(options);
        }, 1000);
    }

    // 上传封面
    uploadCover(options) {
        if (!this.ifImageCanUpload) return;
        let formData = new FormData();
        var three: any = 3
        formData.append("file", options.file);
        formData.append("typeId", three);
        let p = {
            percent: 0
        };
        uploadServiceFile(formData, ({total, loaded}) => {
            p.percent = +Math.round((loaded / total) * 100).toFixed(2);
            options.onProgress(p);
            if (p.percent >= 100) {
                options.onProgress({percent: 99.3});
            }
        }).then(data => {
            if (data && data.code == 0) {
                options.onSuccess();
                this.coverPicture = data.filePath;
                this.previewVisible = true;
                this.previewImage = baseUrl + data.filePath;

            } else {
                message.error(data.msg);
                this.uploadCoverFileList.pop();
            }
        })
            .catch(error => {
                message.error("上传失败");
                this.uploadCoverFileList.pop();
                this.form.validateFields(["coverPicture"], errors => {
                });
            });
    }

    //提交上传图片
    conserve() {

        pictureUpload({headIcon: this.coverPicture}).then(res => {
            if (res && res.code === 0) {
                // message.success("头像更新成功");
                // this.getAccountInfo();
            } else {
                message.error(res.msg);
            }
        });
    }

    // 校验手机号
    checkMobile(rule: any, value: any, callback: any) {
        const form = this.form;
        const telRegxp = /^1[3456789]\d{9}$/;
        if (value && !telRegxp.test(value)) {
            callback("手机号格式不正确");
        } else {
            callback();
        }
    }

    // 省份改变
    onProvinceChange(value: any) {
        [
            this.accountInfo.province,
            this.accountInfo.city,
            this.accountInfo.area,
            this.accountInfo.street
        ] = value;
    }

    // 所属地区点击加载更多
    async loadMoreData(selectedOptions: any) {
        switch (selectedOptions.length) {
            case 1:
                await this.getCity(selectedOptions);
                break;
            case 2:
                await this.getArea(selectedOptions);
                break;
            case 3:
                await this.getStreet(selectedOptions);
                break;
            default:
                break;
        }
    }

    // 获取省
    getProvince() {
        return new Promise(resolve => {
            fetchProvince().then(res => {
                if (res && res.code === 0) {
                    // 处理数据
                    res.data.list.forEach((item: any, index: any) => {
                        item.value = item.id;
                        item.label = item.name;
                        item.isLeaf = false;
                    });
                    this.provinceOptions = res.data.list;
                } else {
                    message.error(res.msg);
                }
                resolve();
            });
        });
    }

    // 获取市
    getCity(selectedOptions: any) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        fetchCity({
            areaType: 1,
            areaId: targetOption.id
        }).then(res => {
            if (res && res.code === 0) {
                targetOption.loading = false;
                // 处理数据
                res.data.list.forEach((item: any, index: any) => {
                    item.value = item.id;
                    item.label = item.name;
                    item.isLeaf = false;
                });
                targetOption.children = res.data.list;
                this.provinceOptions = [...this.provinceOptions];
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取区
    getArea(selectedOptions: any) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        fetchArea({
            areaType: 2,
            areaId: targetOption.id
        }).then(res => {
            if (res && res.code === 0) {
                targetOption.loading = false;
                // 处理数据
                res.data.list.forEach((item: any, index: any) => {
                    item.value = item.id;
                    item.label = item.name;
                    item.isLeaf = false;
                });
                targetOption.children = res.data.list;
                this.provinceOptions = [...this.provinceOptions];
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取街道
    getStreet(selectedOptions: any) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        fetchStreet({
            areaType: 3,
            areaId: targetOption.id
        }).then(res => {
            if (res && res.code === 0) {
                targetOption.loading = false;
                // 处理数据
                res.data.list.forEach((item: any, index: any) => {
                    item.value = item.id;
                    item.label = item.name;
                    item.isLeaf = true;
                });
                targetOption.children = res.data.list;
                this.provinceOptions = [...this.provinceOptions];
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取账户信息
    getAccountInfo() {
        return new Promise(resolve => {
            fetchAccountInfo().then(async res => {
                if (res.code === 0) {
                    // 处理数据
                    this.accountInfo = Object.assign(this.accountInfo, res.data);
                    this.previewImage = res.data.head_icon;
                    this.coverPicture = res.data.head_icon;
                    if (this.accountInfo.province != '') {
                        this.accountInfo.provinceDefalutValue = [
                            this.accountInfo.province,
                            this.accountInfo.city,
                            this.accountInfo.area,
                            this.accountInfo.street
                        ];
                    }

                    let obj = JSON.parse(localStorage.getItem("userInfo") as string);
                    obj.nickname = this.accountInfo.user_info_nickname;
                    localStorage.setItem("userInfo", JSON.stringify(obj));

                    this.form.setFieldsValue(
                        this.accountInfo
                    );

                    // await this.getProvinceName();
                } else {
                    message.error(`获取账户信息错误，错误原因：${res.msg}`);
                }
                resolve();
            });
        });
    }

    // 根据id获取省市区街道名称
    // async getProvinceName() {
    //     if (!this.accountInfo.province) return;
    //     fetchProvinceName({
    //         province: this.accountInfo.province,
    //         city: this.accountInfo.city,
    //         area: this.accountInfo.area,
    //         street: this.accountInfo.street
    //     }).then(res => {
    //         if (res && res.code === 0) {
    //             // 处理数据
    //             this.accountInfo.provinceName = res.info.addr.province;
    //             this.accountInfo.cityName = res.info.addr.city;
    //             this.accountInfo.areaName = res.info.addr.area;
    //             this.accountInfo.streetName = res.info.addr.street;
    //         } else {
    //             message.error(res.msg);
    //         }
    //     });
    // }

    // 获取证件类型
    getCredentials() {
        fetchCredentials({
            type: 20
        }).then(res => {
            if (res && res.code === 0) {
                this.credentialsOptions = res.list;
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取社保类型
    getInsurance() {
        fetchCredentials({
            type: 22
        }).then(res => {
            if (res && res.code === 0) {
                this.insuranceOptions = res.list;
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取民族
    getNation() {
        fetchNation().then(res => {
            if (res && res.code === 0) {
                this.nationOptions = res.list;
            } else {
                message.error(res.msg);
            }
        });
    }

    // 点击更新
    handleSubmit() {
        this.form.validateFields((err: any, values: any) => {
            if (!err) {
                const params = {
                    user_id: this.accountInfo.user_id,
                    name: this.form.getFieldValue("real_name"),
                    username: this.form.getFieldValue("username"),
                    phoneNum: this.form.getFieldValue("mobile"),
                    age: this.form.getFieldValue("user_age"),
                    province: this.accountInfo.province,
                    userInfoNickname: this.form.getFieldValue("user_info_nickname"),
                    city: this.accountInfo.city,
                    area: this.accountInfo.area,
                    street: this.accountInfo.street,
                    detailAddress: this.form.getFieldValue("address_detail"),
                    certificateType: this.form.getFieldValue("certificate_type"),
                    certificateNum: this.form.getFieldValue("certificate_num"),
                    social_insurance_type: this.form.getFieldValue("ss_type"),
                    nation: this.form.getFieldValue("nation"),
                    height: this.form.getFieldValue("height"),
                    weight: this.form.getFieldValue("weight"),
                    user_gender: this.form.getFieldValue("user_gender")
                };
                this.conserve()
                updateAccount(params).then(res => {
                    if (res && res.code === 0) {
                        message.success("账户信息更新成功");
                        this.getAccountInfo();
                        location.reload();
                    } else {
                        message.error(res.msg);
                    }
                });
            }
        });
    }

    ifshowChangeMobile = false;

    resetCDialog() {
        this.vcode1 = "";
        this.vcode2 = "";
        this.buttonText = "获取短信验证码";
        this.VerificationCodeSecond = 0;
    }

    ifshowUsername = false;

    showChangeMobile(evt) {
        evt.preventDefault();
        this.ifshowChangeMobile = !this.ifshowChangeMobile;
    }

    showUsername(evt) {
        evt.preventDefault();
        this.ifshowUsername = !this.ifshowUsername;
    }

    VerificationCodeSecond = 0;
    buttonText = "获取短信验证码";

    // 获取验证码
    async handleSendCode(e: number) {
        let phone = "";
        if (e == 1) {
            phone = this.form.getFieldValue("mobile");
        } else {
            phone = this.newPhone;
            let {exist}: any = await request(`${baseUrl}/api/v1/exist/${phone}`);
            if (exist && phone != this.form.getFieldValue("mobile")) {
                this.step1Tptip = "该手机号已被注册，请重新输入";
                return;
            }
        }
        this.VerificationCodeSecond = 60;
        getVerificationCode(phone).then(res => {
            if (res && res.code === 0) {
                this.step1Tptip = "验证码已发送，5分钟内输入有效";
                const t = setInterval(() => {
                    if (this.VerificationCodeSecond == 0) {
                        this.buttonText = "重新获取验证码";
                        clearInterval(t);
                    } else {
                        this.VerificationCodeSecond--;
                        this.buttonText = this.VerificationCodeSecond + "s后重新获取";
                    }
                }, 1000);
            } else {
                message.error(res.msg);
            }
        });
    }

    vcode1 = "";
    vcode2 = "";
    step = 1;

    handleClickNext() {
        if (!this.vcode1) {
            message.error("请输入短信验证码");
            return;
        }
        request(
            `${baseUrl}/api/v1/checkcode/${this.form.getFieldValue("mobile")}/${
                this.vcode1
            }`
        ).then((res: any) => {
            if (res.check) {
                this.step = 2;
                this.VerificationCodeSecond = 0;
                this.buttonText = "获取短信验证码";
            } else {
                this.step1Tptip = "短信验证码错误或已失效，请重新获取";
            }
        });
    }

    newPhone = "";

    handleClickNextNewPhone() {
        if (!this.vcode2) {
            this.step1Tptip = "请输入短信验证码";
            return;
        }
        request(`${baseUrl}/api/v1/checkcode/${this.newPhone}/${this.vcode2}`).then(
            (res: any) => {
                if (res.check) {
                    this.ifshowChangeMobile = false;
                    this.form.setFields({mobile: {value: this.newPhone}});
                    this.accountInfo.mobile = this.newPhone;
                } else {
                    this.step1Tptip = "短信验证码错误或已失效，请重新获取";
                }
            }
        );
    }
}












































































































import {Component, Vue} from "vue-property-decorator";
import {message, Pagination, Tag} from "ant-design-vue";
import ServiceProjectCard from "@/components/ServiceProjectCard.vue";
import MyPagination from "@/components/MyPagonation.vue";
import {fetchYlmsNextMenuList, getSecondaryMenu, threeLevelList,} from "@/services/api";
import noResult from "./noResult.vue";
import {serviceItems, serviceProvider} from "@/components/Recommended";

Vue.use(Tag);
Vue.use(Pagination);

@Component({
    props: {
        onSearch: Function,
        list: Array,
        pagination: Object,
        searchContent: String,
        serviceClassificationIdttttt: String,
    },
    components: {
        ServiceProjectCard,
        MyPagination,
        noResult,
        serviceItems,
        serviceProvider,
    },
})
export default class extends Vue {
    tags: any[] = [];
    projectSelect: any[] = [];
    projectSelectTwo: any[] = [];
    pullDown: any = 0;
    pullDownTwo: any = 0;
    YlfwNavMenu: any[] = [];
    secondaryMenu: any = [];
    classStatus: any = false;
    pitchUp:any = -1;
    stairId:any='';
    priceSelect = [
        {
            label: "不限",
            value: 0,
        },
        {
            label: "50以内",
            value: 370,
        },
        {
            label: "50-100",
            value: 371,
        },
        {
            label: "100-200",
            value: 372,
        },
        {
            label: "200-300",
            value: 373,
        },
        {
            label: "300-500",
            value: 374,
        },
        {
            label: "500以上",
            value: 375,
        },
    ];
    currentChecked = 0;
    cityName = "";
    currentTypeChecked = -1;

    allDAta(evt) {
        evt.preventDefault();
        let index = 0;
        this.currentChecked = index;
        this.currentTypeChecked = -1;
        const keys = ["serviceItemType", "serviceItemPrice"];
        this.$props.onSearch(keys);
    }

    junpCity() {
        this.$router.push("/pickCity");
    }

    async getCityName() {
        let allList: any[] = JSON.parse(
            sessionStorage.getItem("allAreaData") as any
        );
        const cityId = localStorage.getItem("provinceId") as any;
        let obj = {};
        if (!allList) {
            let res = await threeLevelList({});
            window.sessionStorage.setItem(
                "allAreaData",
                JSON.stringify(res.data.provinceList)
            );
            allList = res.data.provinceList;
        }

        allList.map((item) => {
            const m = item.cityList.find((item) => item.cityId == cityId);
            m ? (this.cityName = m.shortName) : "";
        });
        if (cityId == "0") {
            this.cityName = "全国";
        }
    }

    created() {
        this.fetchYlmsNextMenuList();
        this.getCityName();
        this.currentTypeChecked = this.$props.serviceClassificationIdttttt || -1;
    }

    fetchYlmsNextMenuList() {
        fetchYlmsNextMenuList().then((res) => {
            this.YlfwNavMenu = [{label: "不限", value: -1}];
            if (res.data && res.data.secondTypeList) {
                res.data.secondTypeList.forEach((item: any) => {
                    this.YlfwNavMenu.push({
                        label: item.classificationName,
                        value: item.serviceClassificationId,
                        childCountData: item.childCount,
                    })
                });
                this.projectSelect = this.YlfwNavMenu.slice(0, 10);

                this.projectSelectTwo = this.YlfwNavMenu;
                this.pullDown = 9
                this.pullDownTwo = 11

            }
        });
    }

    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});
        this.$emit("changePage", page);
    }

    handlePriceCheckedChange(index: number) {
        this.currentChecked = index;
        this.$props.onSearch("serviceItemPrice", index,1);
    }

    handleTypeCheckedChange(val) {
        this.currentTypeChecked = val.value;
        if (val.value == -1) val.value = "";
        this.$props.onSearch("serviceItemType", val.value,1);
        this.stairId = val.value;
        if (val.label != '不限') {
            //判断一级服务下有没有二级服务
            if (val.value != 0) {
                //获取二级菜单数据
                this.classStatus = true;
                getSecondaryMenu(val.value).then(res => {
                    this.secondaryMenu = [{classificationName: "全部", serviceClassificationId: -1}];
                    if (res && res.code === 0) {
                        res.data.forEach(data => {
                            this.secondaryMenu.push({
                                classificationName: data.classificationName,
                                serviceClassificationId: data.serviceClassificationId,
                            })
                        })
                    } else {
                        message.error(res.msg);
                    }
                });
            }

        } else {
            this.classStatus = false;
        }


    }

    //服务类别选中二类
    theSecond(val) {
        this.pitchUp = val.serviceClassificationId;
        if(val.serviceClassificationId===-1){
            this.$props.onSearch("serviceItemType", this.stairId,1);
        }else{
            this.$props.onSearch("serviceItemType", val.serviceClassificationId,2);
        }
    }
}

<template>
    <div id="serviceOrderPaySuccess">
        <payHeader/>
        <div id="serviceOrderPaySuccess-top">
            <div id="serviceOrderPaySuccess-header">
                <div class="img-span">
                    <img src="../assets/images/homeImg/dlLogo.png" alt @click="jumpToHome"/>
                    <span></span>
                </div>
                <div class="serviceOrderPaySuccess-input">
                    <div class="home_input">
                        <a-input-search
                                :value="searchVal"
                                @change="onSearchChange"
                                @search="onSearch"
                                placeholder="请输入您想找的服务商或服务项目"
                                enter-button
                        />
                    </div>
                </div>

            </div>
        </div>
        <div class="ServiceOrderPay-center">
            <div class="ServiceOrderPay-details">
                <div class="submit-order">
                    <img src="../assets/images/homeImg/路径 2364@2x.png"/>
                    <div class="accountFor">
                        <p class="p1">您已成功订购服务</p>
                    </div>
                </div>
                <div class="submit-order-sum">
          <span class="money">实付总金额 <span class="money-red">￥ </span><span
                  class="money-yuan">{{ totalAmount | formatMoney }}</span> 元</span>
                </div>
                <div class="line-item">
                    <div v-if="serviceStyle===16">
                        <div class="line-item" >
                            <div class="information">
                                <p>门店名称： {{ providerInfo.shopName || "--" }}</p>
                                <p>门店电话： {{ providerInfo.contactPhone || "--" }}</p>
                            </div>
                            <div class="information">
                                <p>门店地址： {{ providerInfo.detailAddress }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="line-item" v-if="serviceStyle===15">
                        <div class="information">
                            <p>联系人： {{ providerInfo.receiverName || "--" }}</p>
                            <p>联系人电话： {{ providerInfo.phoneNumber || "--" }}</p>
                        </div>
                        <div class="information">
                            <p>服务地址： {{ providerInfo.addressDetail }}</p>
                        </div>
                    </div>

                </div>
                <div class="payment-btn">
                    <a-button class="payment-button1" @click="lookDetail"><img
                            src="../assets/images/homeImg/组 2016@2x.png"/>服务订购单列表
                    </a-button>
                    <a-button class="payment-button2" @click="$router.push('/')">再去逛逛</a-button>
                </div>
                <div class="tis">
                    <div>重要提示:</div>
                    <div>平台及销售商不会以订单异常、系统升级为由，要求您点击任何链接进行退款。谨防假冒客服电话诈骗!</div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Vue from "vue";
    import Footer from "@/components/Footer";
    import payHeader from "@/components/payHeader";
    import {Button, Cascader, Checkbox, Form, Input, Modal, Select, Table, Upload,} from "ant-design-vue";

    Vue.use(Checkbox);
    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Cascader);
    Vue.use(Upload);
    Vue.use(Modal);
    Vue.use(Table);

    export default {
        components: {
            Footer,
            payHeader
        },
        data() {
            return {
                value: 1,
                searchVal: "",
                serviceStyle:0,
                providerInfo:{},
                totalAmount:'',

            };
        },
        mounted() {
            this.serviceStyle = this.$route.query.serviceStyle;
            this.totalAmount = this.$route.query.totalAmount;
            this.providerInfo =  JSON.parse(this.$route.query.providerInfo);

        },
        methods: {
            jumpToHome() {
                this.$router.push("/");
            },
            //搜索
            onSearchChange(e) {
                this.searchVal = e.target.value;
                sessionStorage.setItem("search", e.target.value);
            },
            //搜索
            onSearch(value) {
                const path = window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1,
                    window.location.href.length
                );
                if (path !== "servicePage") {
                    this.$router.push("/servicePage");
                }
                if (this.$props.handleNavSearch) {
                    this.$props.handleNavSearch(value);
                }
            },
            lookDetail() {
                this.$router.push("/personal/serviceOrderList");
            },

        },
    };
</script>

<style lang="less">
    #serviceOrderPaySuccess {
        background: #F5F7FA;
        opacity: 1;
        min-width: 11.72rem;
        user-select: none;

        #serviceOrderPaySuccess-top {
            height: 81px;
            width: 100%;
            min-width: 10rem;
            background: #ffffff;

            #serviceOrderPaySuccess-header {
                user-select: none;
                height: 100%;
                width: 11.72rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                opacity: 1;
                margin: 0 auto;

                .img-span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    min-width: 2.3rem;

                    img {
                        width: 1.24rem;
                        height: 0.59rem;
                        cursor: pointer;

                    }

                    span {
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #39BEB8;
                        opacity: 1;
                        display: inline-block;
                        padding-left: 0.47rem;
                        margin-top: 0.12rem;
                    }
                }

                .serviceOrderPaySuccess-input {
                    min-width: 3.74rem;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .home_input {
                        /*width: 4rem;*/
                        display: flex;
                        align-items: center;

                        .ant-input {
                            min-width: 2.95rem;
                            font-size: 0.14rem;
                            height: 0.48rem;

                        }

                        .ant-input {
                            &::placeholder {
                                color: #78849E;
                                font-size: 12px;
                                opacity: 1;
                            }
                        }

                        button.ant-btn.ant-btn-primary.ant-input-search-button {
                            width: 0.77rem;
                            height: 0.48rem;
                            background-color: #60c7c6;
                            border-radius: 0 0.5rem 0.5rem 0;
                            border-color: #60c7c6;
                            font-size: 0.2rem;
                        }

                        input.ant-input:focus {
                            border-color: #d9d9d9;
                            border-radius: 0.5rem 0 0 0.5rem;
                            -webkit-box-shadow: 0 0 0 0;
                            box-shadow: 0 0 0 0;
                        }

                        input.ant-input:hover {
                            border-color: #d9d9d9;
                        }

                        input.ant-input {
                            border-radius: 0.5rem 0 0 0.5rem;
                        }
                    }
                }


            }
        }

        .ServiceOrderPay-center {
            width: 100%;

            .ServiceOrderPay-details {
                margin: 0 auto;
                margin-top: 0.25rem;
                margin-bottom: 0.3rem;
                width: 11.72rem;
                min-height: 4.63rem;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 0.12rem;
                padding-top: 0.3rem;
                padding-bottom: 0.2rem;

                .submit-order {
                    width: 523px;
                    height: 1.07rem;
                    background: #F9F9F9;
                    opacity: 1;
                    border-radius: 0.12rem;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    user-select: none;

                    img {
                        width: 0.41rem;
                        height: 0.41rem;
                        margin-left: 0.86rem;
                    }

                    .accountFor {
                        width: 296px;
                        margin-left: 0.14rem;

                        .p1 {
                            font-size: 0.16rem;
                            font-weight: bold;
                            color: #454F63;
                            opacity: 1;
                            padding: 0;
                            margin: 0;
                        }

                        .p2 {
                            font-size: 0.12rem;
                            font-weight: 400;
                            color: #454F63;
                            opacity: 1;
                            padding: 0;
                            margin: 0;
                            margin-top: 0.03rem;
                        }
                    }
                }

                .submit-order-sum {
                    width: 5.23rem;
                    height: 0.4rem;
                    border-bottom: 1px solid #E4E9F2;
                    margin: 0 auto;
                    margin-top: 0.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /*vertical-align: bottom;*/
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                    user-select: none;

                    .money {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin-top: -0.09rem;

                        .money-red {
                            font-family: Roboto;
                            font-weight: bold;
                            color: #FF5242;
                            opacity: 1;
                        }

                        .money-yuan {
                            font-size: 0.24rem;
                            font-family: Roboto;
                            font-weight: 900;
                            color: #FF5242;
                            opacity: 1;
                        }
                    }


                }

                .line-item {
                    text-align: center;
                    width: 5.23rem;
                    margin: 0 auto;
                    margin-top: 0.19rem;
                    /*height: 0.9rem;*/

                    p {
                        font-size: 0.13rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                        margin: 0;
                        margin-right: 0.3rem;
                        text-align: left;
                    }

                    .information {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.05rem;
                    }

                }

                .payment-btn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 0.3rem;

                    .payment-button1 {
                        width: 1.72rem;
                        height: 0.4rem;
                        background: #39BEB8;
                        opacity: 1;
                        border-radius: 0.2rem;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 0.8rem;

                        img {
                            width: 14.93px;
                            height: 14.93px;
                            margin-right: 0.04rem;
                            margin-top: 0.03rem;
                        }

                    }

                    .payment-button1:hover {
                        border: 1px solid #39BEB8;
                        background: #39BEB8;
                    }

                    .payment-button2 {
                        width: 1.46rem;
                        height: 0.4rem;
                        border: 1px solid #78849E;
                        opacity: 1;
                        border-radius: 0.2rem;
                        font-size: 0.14rem;
                        color: #78849E;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .tis {
                    width: 491px;
                    height: 42px;
                    font-size: 13px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 24px;
                    color: #454F63;
                    opacity: 1;
                    margin: 0 auto;
                    margin-top: 0.4rem;
                    margin-bottom: 0.2rem;
                    text-align: center;
                    display: flex;

                    div:first-child {
                        width: 100px;
                        /*border: 1px solid red;*/
                    }

                    div:last-child {
                        text-align: left;
                    }
                }


            }


        }
    }
</style>

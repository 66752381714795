

















































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Icon, Input, message, Modal, Select, Upload} from "ant-design-vue";
import ReturnStep from "@/components/ReturnStep.vue";
import GoodsRightOrderDetail from "../../../components/GoodsRightOrderDetail.vue";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import highConfigurationStep from "../../../components/highConfigurationStep.vue";
import {
    aftersalesApplicationStatus,
    applicationDetails,
    DetailsRefund,
    getInvolved,
    NegotiationHistory,
    whetherRevokeRefund,
    withdrawApplication
} from "@/services/api";
import {baseUrl} from "../../../services/http";

Vue.use(Button);
Vue.use(Form);
Vue.use(Select);
Vue.use(Input);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Icon);
@Component({
    components: {
        ReturnStep,
        GoodsRightOrderDetail,
        Breadcrumb,
        highConfigurationStep
    }
})
export default class extends Vue {
    baseUrl = baseUrl
    serviceItem = {};
    detailsRefund = {};
    HistoryList = [];
    refundStatus = {};
    status = false;
    serveCancel = false;
    serveCancelTwo = false;
    refund = true;
    statusDic: any = '';
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的商品",
            path: "/personal/MyGoods",
            significance: 0
        },
        {
            name: "申请退款",
            path: "/",
            significance: 1
        },
    ]

    mounted() {

        let commodityRelationId = this.$route.query.commodityRelationId;
        let returnId = this.$route.query.returnId;
        this.negotiation(returnId);
        this.orderInfo(commodityRelationId);
        this.afterSale(returnId)
        this.whetherRefund(returnId)
        this.seeRefund(returnId)
    }

    stepList = [
        {
            name: "买家申请退款"
        },
        {
            name: "商家处理退款申请"
        },
        {
            name: "退款完毕"
        }
    ];
    //姓名加密
    getNickName(value) {
        if(value.length>3){
            const name: any[] = value.split("");
            return name[0] + "*****" + name[name.length - 1];
        }else{
            return  value
        }

    }
    //获取协商历史
    negotiation(returnId) {
        NegotiationHistory({returnId: returnId}).then(res => {
            this.HistoryList = res.data;
        });
    }

    //获取详情页面数据
    orderInfo(commodityRelationId) {
        DetailsRefund({commodityRelationId: commodityRelationId}).then(res => {
            this.serviceItem = res.data;
        });
    }

    //修改申请
    modifyApply() {
        this.$router.push({
            path: "/personal/ReturnGoodsOne",
            query: {
                returnId: this.$route.query.returnId,
                commodityRelationId: this.$route.query.commodityRelationId,
            }
        });
    }

    //查看是否能撤销申请
    whetherRefund(value) {
        whetherRevokeRefund({
            returnId: value
        }).then(res => {
            if (res.data === true) {
                this.refund = true;
            } else {
                this.refund = false;
            }
        });
    }

    //撤销申请提示
    revocation() {
        this.serveCancelTwo = true;

    }


    //撤销申请
    returnMoneyCancel() {
        withdrawApplication({
            returnId: this.$route.query.returnId
        }).then(res => {
            if (res.code === 0) {
                message.success("申请已撤消");
                this.$router.push({
                    path: "/personal/ReturnGoodsThree",
                    query: {
                        returnId: this.$route.query.returnId,
                        commodityRelationId: this.$route.query.commodityRelationId,

                    }
                });
            }
            this.serveCancelTwo = false
        });
    };

    //查看售后申请详情
    afterSale(value) {
        applicationDetails({
            returnId: value
        }).then(res => {
            if (res.data != '') {
                this.detailsRefund = res.data;
                this.status = true;
            }
        });

    };

    //申请平台介入
    intervene() {
        this.serveCancel = true
    };

    enshrine() {
        getInvolved({returnId: this.$route.query.returnId}).then(res => {
            if (res && res.code == 0) {
                message.success('平台介入成功');
            } else {
                message.error("申请平台介入失败，请稍后重试");
            }
            this.serveCancel = false
        });
    }

    //查询售后申请状态
    seeRefund(value) {
        aftersalesApplicationStatus({returnId: value}).then(res => {
            this.refundStatus = res.data
            this.statusDic = res.data.statusDic;
            this.countDown(res.data.remainTime)
            if(res.data.statusDic===270){
                this.$router.push({
                    path: "/personal/ReturnGoodsThree",
                    query: {
                        returnId: this.$route.query.returnId,
                        commodityRelationId: this.$route.query.commodityRelationId,

                    }
                });
            }
        });

    }

    //根据最终返回时间进行倒计时
    countDown(value) {
        var _ordertimer: any = null;
        var data: any = new Date();
        var txt = $('.js_time_txt');
        var buyTime = value; //倒计时最终时间
        var nowTime = new Date(getnow()); //获取当前时间
        var ce1: any = new Date(nowTime); //请求时间戳
        var ce2: any = new Date(getnow()) //本地时间戳
        var dateDiff = ce1 - ce2; //请求时间戳与(-)本地时间戳
        if (dateDiff < 0) {
            dateDiff = Math.abs(dateDiff);
        }

        if (new Date(nowTime) > new Date(buyTime)) {
            this.$router.push({
                path: "/personal/ReturnGoodsThree",
                query: {
                    commodityRelationId: this.$route.query.commodityRelationId,
                    returnId: this.$route.query.returnId
                }
            });
            return;
        } else {
            leftTimer(buyTime);
            _ordertimer = setInterval(function () {
                leftTimer(buyTime)
            }, 1000);
        }

        // 获取当前时间 xxxx/xx/xx 00:00:00
        function getnow() {
            var year = data.getFullYear();
            var month = parseInt(data.getMonth() + 1) >= 10 ? parseInt(data.getMonth() + 1) : '0' + parseInt(data.getMonth() + 1);
            var day = data.getDate();
            var hours = data.getHours();
            var minutes = data.getMinutes();
            var seconds = data.getSeconds();
            var now = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return now;
        }

        function leftTimer(enddate) {
            var ce1: any = (new Date(enddate));
            var ce2: any = new Date
            var leftTime = ce1 - ce2 + dateDiff;

            var days = parseInt((leftTime / 1000 / 60 / 60 / 24).toString(), 10); //计算剩余的天数
            var hours = parseInt((leftTime / 1000 / 60 / 60 % 24).toString(), 10); //计算剩余的小时
            var minutes = parseInt((leftTime / 1000 / 60 % 60).toString(), 10);//计算剩余的分钟
            var seconds = parseInt((leftTime / 1000 % 60).toString(), 10);//计算剩余的秒数
            days = checkTime(days);
            hours = checkTime(hours);
            minutes = checkTime(minutes);
            seconds = checkTime(seconds);

            if (days >= 0 || hours >= 0 || minutes >= 0 || seconds >= 0)
                txt.html(days + "天" + hours + "小时" + minutes + "分" + seconds + "秒");
            if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
                window.clearInterval(_ordertimer);
                _ordertimer = null;
            }
        }

        function checkTime(i) { //将0-9的数字前面加上0，例1变为01
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }
    }
}















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
import {baseUrl, mallUrl} from "../../services/http";
import aSpin from "@/components/aSpin.vue";

@Component({
        components: {
            PersonalHeader,
            OpenServiceItem,
            aSpin
        },
    })
    export default class extends Vue {
        @Prop() topLis: any;
        @Prop() data: any;
        @Prop() pagination: any;
        @Prop() emptyTextBySearch: any;
        currentRelationId = "";
        currentServiceType = 0;
        currentTab = 1;
        serviceStatus = 0;
        baseUrl = baseUrl;
        loading = true;

        // 点击查看退款记录
        handleApplyCusService(id) {
            this.$emit("handleApplyCusService", id);
        }
        @Watch("data", {immediate: true, deep: true})
        getData(newVal) {
            this.loading=false
        }
        onChangePageSize(page, pageSize) {
            window.scrollTo({top: 0});
            this.pagination.pageIndex = page;
            this.$emit("getApplyReturn");
        }

        handleGoCommodity(data) {
            open(mallUrl + '/#/mall/detail/' + data);
        }
    }




















  import {Component, Prop, Vue} from "vue-property-decorator";

  @Component
export default class shopSort extends Vue {
  @Prop() value: any;
  @Prop() totalCount: Number;
  shopSortContentArr = [
    { name: "综合", value: 0 },
    { name: "销量", value: 1 },
    { name: "价格", value: 2 },
    // { name: "上架时间", value: 3 },
  ];
  select(value) {
    this.$emit("input", value);
  }
}






































































































































































import {Component, Vue} from "vue-property-decorator";
import {InputNumber, message} from "ant-design-vue";
import GoodsRightOrderDetail from "../../../components/GoodsRightOrderDetail.vue";
import ReturnStep from "../../../components/ReturnStep.vue";
import highConfigurationStep from "../../../components/highConfigurationStep.vue";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import {Mutation, State} from "vuex-class";
import {baseUrl} from "../../../services/http";
import {
    applicationDetails,
    commodityRefund,
    DetailsRefund,
    dictionariesWithDesc,
    returnCommodityUpdate,
    uploadPicture
} from "../../../services/api";

Vue.use(InputNumber);
// Vue.use(message);
@Component({
    components: {
        GoodsRightOrderDetail,
        ReturnStep,
        highConfigurationStep,
        Breadcrumb
    }
})
export default class extends Vue {
    refundMoneyGet = "0.00";
    refundMoneyDeduct: any = "0.00";

    //获取到账金额
    refundMoneyChange(e) {
        this.refundMoneyDeduct = (e * 0.006).toFixed(2);
        this.refundMoneyGet = (e - +this.refundMoneyDeduct).toFixed(2);
    }
    baseUrl = baseUrl
    uploadPicture = uploadPicture;
    @State refundReasons;
    @Mutation SET_RETURNID;
    previewVisible = false;
    isModify = false;
    data: any = {}; // 页面数据
    status = false;
    commodityRelationId = '';

    userInfo: any = window.localStorage.getItem("userInfo");
    header = {
        token: JSON.parse(this.userInfo).token
    };
    allRefundReasons = [];
    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的商品",
            path: "/personal/MyGoods",
            significance: 0
        },
        {
            name: "申请退款",
            path: "/",
            significance: 1
        },
    ]


    previewImage = "";
    value = 2;
    serviceItem = {};
    fileList: any = [];
    Obj: Object = {};
    stepList = [
        {
            name: "买家申请退款"
        },
        {
            name: "商家处理退款申请"
        },
        {
            name: "买家退货"
        },
        {
            name: "退款完毕"
        }
    ];
    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 3}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 21}
        }
    };
    tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 15,
                offset: 9
            }
        }
    };
    form: any;

    mounted() {
      this.getRefund();
        this.Obj["RefundType"] = 1; //货物状态
        this.form.setFieldsValue(this.Obj);
        let returnId = this.$route.query.returnId;
        if (returnId) {
          this.commodityRelationId = this.$route.query.commodityRelationId
            this.applicationInfoList(returnId);
            this.isModify = true;
        }else{
            this.commodityRelationId = this.$route.query.commodityRelationId
        }
        this.orderInfo(this.commodityRelationId);

    }

    //选择退款类型
    histTypeUpdate(e) {
        if (e.target.value === 0) {
            this.$router.replace({
                path: "/personal/ReturnGoodsOne",
                query: {
                    commodityRelationId: this.$route.query.commodityRelationId,
                    returnId:this.$route.query.returnId
                }
            });
        }
    }
    //获取退款退货类型
    getRefund() {
        dictionariesWithDesc(184).then(res => {
            this.allRefundReasons = res.values
        });

    }
    //表单提交
    handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
            if (!err) {
                localStorage.setItem("serviceForm1", JSON.stringify(values));
                this.Obj["afterSaleType"] = 1;  //退款类型
                this.Obj["afterSaleDescription"] = values.afterSaleDescription; //退款说明
                this.Obj["refundMoney"] = values.refundMoney; //退款金额
                this.Obj["picture"] = []; //图片
                for (let [key, value] of Object.entries(this.allRefundReasons)) {
                    if (Number((value as any).key) === values.reason) {
                        this.Obj["reason"] =Number((value as any).key);
                    }
                }  //退款原因
                this.Obj["commodityStatus"] = 1; //货物状态
                this.Obj["commodityRelationId"] = this.$route.query.commodityRelationId; //商品订单Id
                if (this.isModify) {
                    this.Obj["returnId"] = this.$route.query.returnId;
                    this.fileList.forEach(el => {
                        if (el.response != undefined) {
                            this.Obj["picture"].push(el.response.filePath);
                        }
                        if (el.url != undefined) {
                            this.Obj["picture"].push(el.url);
                        }
                    });
                    this.returnMoneyUpdate();
                } else {
                    this.Obj["picture"] = this.fileList.map(el => el.response.filePath); //图片
                    this.returnMoney();
                }
            }
        });
    }

    //获取退款订单页面数据
    orderInfo(commodityRelationId) {
        DetailsRefund({commodityRelationId: commodityRelationId}).then(res => {
            this.serviceItem = res.data;
            this.refundMoneyDeduct = (res.data.commodityTotalPrice * 0.006).toFixed(2);
            this.refundMoneyGet = (res.data.commodityTotalPrice - +this.refundMoneyDeduct).toFixed(2);
        });
    }

    //提交退款服务申请
    returnMoney() {
        commodityRefund({...this.Obj}).then(res => {
            if (res.code === 0) {
                this.$router.push({
                    path: "/personal/ReturnGoods2",
                    query: {
                        relationId: this.$route.query.relationId,
                        returnId: res.data.returnId,
                        // commodityRelationId: JSON.parse(this.$route.query.commodityRelationId)
                        commodityRelationId:this.$route.query.commodityRelationId
                    }
                });
                // localStorage.setItem("examine", "1");
            } else {
                message.error("提交失败，请稍后重试");
            }
        });
    }


    //获取修改数据并回填
    applicationInfoList(returnId) {
        applicationDetails({
            returnId: returnId
        }).then(res => {
            this.Obj["afterSaleType"] = 0;  //退款类型
            // this.Obj["commodityStatus"] = Number(res.data.goodsState); //货物状态
            this.Obj["commodityStatus"] = 1 //货物状态
            dictionariesWithDesc(184).then(data => {
                for (let value of data.values) {
                    if (value.key == res.data.refundReasonNumber) {
                        this.Obj["reason"] = res.data.refundReasonNumber;
                    }
                }
                this.form.setFieldsValue(this.Obj);
            });
            this.Obj["refundMoney"] = res.data.refundMoney; //退款金额
            this.Obj["afterSaleDescription"] = res.data.introduction; //退款说明
            //上传图片回传
            res.data.refundPicList.forEach((el, index) => {
                let fileobj: any = {
                    uid: index,
                    name: "image.png",
                    status: "done",
                    url: el
                };
                (this as any).fileList.push(fileobj);
            });
            this.form.setFieldsValue(this.Obj);
        });
    }


    //修改退款申请
    returnMoneyUpdate() {
        returnCommodityUpdate({...this.Obj}).then(res => {
            if (res.code === 0) {
                this.$router.push({
                    path: "/personal/ReturnGoods2",
                    query: {
                        relationId: this.$route.query.relationId,
                        returnId: res.data.returnId,
                        commodityRelationId: this.$route.query.commodityRelationId
                    }
                });
            } else {
                message.error("提交失败，请稍后重试");
            }
        });
    }


    beforeCreate() {
        this.form = this.$form.createForm(this);
    }

    //图片上传
    handleCancel() {
        this.previewVisible = false;
    }

    //图片上传
    handlePreview(file: any) {
        this.previewImage = file.url || file.thumbUrl;
        this.previewVisible = true;
    }

    //图片上传
    handleChange({fileList}: any) {
        this.fileList = fileList;
    }

}











import {Component, Vue} from "vue-property-decorator";
import {baseUrl, mallUrl} from "../../../services/http";

@Component({
  props: {
    price: Number,
    picture: String,
    serviceId: String
  }
})
export default class CollectionScrollItem extends Vue {
  baseUrl = baseUrl;
  handleGoService(data){
    open(mallUrl + '/#/cookInHome/' + data);
  }
}

<template>
  <div>
    <Header />
    <Nav />
    <div class="searchProcess" style="height:5.2rem">
      <div v-if="status" class="top">
        <div class="image"></div>
        <div class="txt" v-if="agencyStatus">
          <span>{{ agencyStatus }}</span>
        </div>
      </div>
      <p
        v-if="status && storeName"
        style="marginBottom: 0.12rem; fontSize: 0.22rem;color: #737373;marginTop: 0.3rem;width:8rem"
      >
        <span v-if="checkStatus == 40"
          >感谢你在平台申请服务商入驻，审核会在1-2个工作日完成。</span
        >
        <span v-if="checkStatus == 41"
          >恭喜您服务商入驻成功，马上去服务商中心完善服务商信息吧！</span
        >
        <span v-if="checkStatus == 42"
          >非常抱歉您的服务商入驻未成功，马上去修改服务商入驻信息再次申请吧。
        </span>

        <span v-if="checkInfo" style="padding-left:0.1rem">
          <br />
          <br />
          驳回理由为:{{ checkInfo }}
          驳回理由为：{{ checkInfo }}
        </span>
        <br />
      </p>
      <p
        v-if="status && storeName"
        style="fontSize: 0.22rem;color: #737373; margin:0;text-align:left;width:8rem"
      >
        服务商名称：{{ storeName }}
      </p>

      <div>
        <a-button
          type="primary"
          html-type="submit"
          style="width: 2rem;height:0.44rem;marginRight:1rem;font-size:0.18rem"
          @click="$router.push('/')"
          >返回首页</a-button
        >
        <a-button
          v-if="checkStatus == 40"
          type="primary"
          html-type="submit"
          style="width: 2rem;height:0.44rem;background: #F6AB1A; borderColor: #F6AB1A;font-size:0.18rem"
          @click="$router.push('/personal/myService')"
          >进入个人中心</a-button
        >
        <a-button
          v-if="checkStatus == 41"
          type="primary"
          html-type="submit"
          style="width: 2rem;height:0.44rem;background: #F6AB1A; borderColor: #F6AB1A;font-size:0.18rem"
          @click="goserverCenter"
          >进入商户中心</a-button
        >

        <a-button
          v-if="checkStatus == 42"
          type="primary"
          html-type="submit"
          style="width: 2rem;height:0.44rem;background: #F6AB1A; borderColor: #F6AB1A;font-size:0.18rem"
          @click="jumpChage"
          >修改入驻信息</a-button
        >
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import {message} from "ant-design-vue";
    import {fetchMerChantStatus, getJoinData} from "@/services/api";


    import {serviceProviderUrl} from "@/services/http";

    export default {
  components: {
    Nav,
    Footer,
    Header
  },
  methods: {
    goserverCenter() {
      window.open(serviceProviderUrl, "_blank");
    },
    jumpChage() {
      getJoinData().then(res => {
        if (res.code == 0) {
          localStorage.setItem("merchantJoinForm", JSON.stringify(res.data));
          this.$router.push("/BusinessJoinStep1");
        } else {
          message.error(res.msg);
        }
      });
    }
  },
  data() {
    return {
      status: 0,
      agencyStatus: "",
      storeName: "",
      checkStatus: -1,
      checkInfo: ""
    };
  },
  mounted: function() {
    fetchMerChantStatus().then(res => {
      this.status = 0;
      if (res.code === 0) {
        if (res.data.storeName && res.data.checkStatus) {
          this.storeName = res.data.storeName;
          this.checkStatus = res.data.checkStatus;
          this.checkInfo = res.data.checkInfo;
          switch (res.data.checkStatus) {
            case 40:
              this.agencyStatus = "审核中";
              break;
            case 41:
              this.agencyStatus = "审核通过";
              break;
            case 42:
              this.agencyStatus = "审核驳回";
              break;
          }
          this.agencyStatus = res.data.agencyStatus;
          this.status = 1;
        }
      } else {
        message.error(res.msg);
      }
    });
  }
};
</script>

<style lang="less">
.searchProcess {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 5rem;
  .top {
    display: flex;
    align-items: center;

    .image {
      width: 110px;
      height: 110px;
      margin-right: 0.4rem;
      background-image: url("./../../assets/images/zhengxaishenhezhong_fwsrz.png");
      background-position: center center;
      background-repeat: no-repeat;
    }

    .icon-box {
      margin-right: 0.4rem;
    }

    .txt {
      font-size: 0.48rem;
      color: #60c7c6;
    }
  }
  .ant-btn-primary {
    background-color: #60c7c6;
    border-color: #60c7c6;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #60c7c6;
    border-color: #60c7c6;
  }
}
</style>

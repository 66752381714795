import { render, staticRenderFns } from "./MyGoodsItem.vue?vue&type=template&id=17192452&scoped=true&"
import script from "./MyGoodsItem.vue?vue&type=script&lang=ts&"
export * from "./MyGoodsItem.vue?vue&type=script&lang=ts&"
import style0 from "./MyGoodsItem.vue?vue&type=style&index=0&id=17192452&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17192452",
  null
  
)

export default component.exports
<template>
    <div>
        <Header/>
        <div class="serviceJoinStep2">
            <div class="stepTop">
                <img src="../../assets/images/homeImg/组 11@2x.png" height="240" width="3840"/>
                <div class="rz-type">服务商入驻</div>
            </div>
            <div class="progressBar">
                <MyStepBar :stepData="stepData" :status="3"/>
            </div>
            <div class="form-box">
                <a-form :form="form">
                    <div class="form-item">
                        <a-form-item v-bind="formItemLayout" label="服务商账号">
                            <a-input
                                    placeholder="服务商账号不能为空"
                                    v-decorator="[
                'orgAccount',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkExist.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgAccount,
                },
              ]"
                            />
                            <div class="fileTipBox2020050666">此帐号为日后登录并管理服务商管理中心时使用，注册后不可修改，请牢记。默认密码123456</div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务商名称" style="margin-top: 0.33rem">
                            <a-input
                                    placeholder="请输入服务商名称"
                                    v-decorator="[
                'orgName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.checkExistName.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgName,
                },
              ]"
                            />
                            <div class="fileTipBox2020050666">服务商名称注册后不可修改，请认真填写</div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="创建时间" style="margin-top: 0.33rem">
                            <a-date-picker
                                    placeholder="请选择服务商创建时间"
                                    v-decorator="[
                'createTime',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商创建时间不能为空',
                    },
                  ],
                  initialValue: formData.expansionInfo.createTime ? moment(formData.expansionInfo.createTime, 'YYYY-MM-DD') : null,
                },
              ]"
                            />
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务商性质" class="property">
                            <a-radio-group
                                    v-decorator="[
                'fnre',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择服务商性质',
                    },
                  ],
                   initialValue: initialValue.fnre,
                },
              ]"
                            >
                                <a-radio v-for="item in fnreType" :value="item.key" :key="item.key">{{ item.value }}
                                  </a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="养老模式">
                            <a-select
                                    style="width: 3.53rem"
                                    mode="multiple"
                                    placeholder="请选择服务商养老模式"
                                    v-decorator="[
                'orgForm',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商养老模式不能为空',
                    },
                  ],
                  initialValue: initialValue.orgForm,
                },
              ]"
                            >
                                <a-select-option
                                        style="font-size: 0.16rem"
                                        v-for="item in serviceType"
                                        :value="item.key"
                                        :key="item.key"
                                >{{ item.value }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <div class="imageUpload">
                            <a-form-item v-bind="formItemLayout" label="封面图片" style="height: 1.05rem;">
                                <a-upload
                                        name="file"
                                        :customRequest="uploadCoverAll"
                                        :remove="uploadCoverRemove"
                                        @preview="handlePicturePreview"
                                        list-type="picture-card"
                                        :file-list="uploadCoverFileList"
                                        v-decorator="[
                'coverImage',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.uploadCoverFileCheck.bind(this),
                    },
                  ],
                },
              ]"
                                >
                                    <div v-if="uploadCoverFileList.length < 1">
                                        <a-icon type="plus"/>
                                        <div class="ant-upload-text">上传封面</div>
                                    </div>
                                </a-upload>
                            </a-form-item>
                        </div>
                        <a-form-item v-bind="formItemLayout" label="服务商图集">
                            <a-upload
                                    name="file"
                                    :customRequest="uploadAtlasAll"
                                    :remove="uploadAtlasRemove"
                                    :multiple="true"
                                    @preview="handlePicturePreview"
                                    list-type="picture-card"
                                    :file-list="uploadAtlasList"
                                    v-decorator="[
                'imageUrl',
                {
                  rules: [
                    {
                      required: false,
                      validator: this.uploadCoverFileCheckOne.bind(this),
                    },
                  ],
                },
              ]"
                            >
                                <div>
                                    <a-icon type="plus"/>
                                    <div class="ant-upload-text">上传图集</div>
                                </div>

                            </a-upload>
                            <div class="fileTipBox2020050666" style="top: 0.13rem" v-if="uploadAtlasList.length<3">
                                服务商图集也可以在服务商审批通过后再完善
                            </div>

                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务商所在地">
                            <a-cascader
                                    :options="options"
                                    style="width: 3.53rem"
                                    :placeholder="addrPlaceHolder"
                                    :loadData="loadData"
                                    @change="onChange1"
                                    v-decorator="[
                'companyAddress',
                {
                  trigger: 'change',
                  rules: [
                    {
                      required: true,
                      validator: this.companyAddrcheck.bind(this),
                    },
                  ],
                    initialValue: formData.shopInfo.shopAddress,
                },
              ]"
                            >
                            </a-cascader>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务商详细地址">
                            <a-input
                                    placeholder="请输入服务商详细地址"
                                    v-decorator="[
                'addressDetail',
                {
                  rules: [
                    {
                      required: true,
                      message: '服务商详细地址不能为空',
                    },
                  ],
                  initialValue: formData.shopInfo.addressDetail,
                },
              ]"
                            />
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务商电话">
                            <a-input
                                    placeholder="请输入服务商电话,如010-12345678,13812345678"
                                    v-decorator="[
                'orgTel',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.phoneCheck.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.orgTel,
                },
              ]"
                            />
                        </a-form-item>
                    </div>
                    <div class="detailsTheData">
                        <a-form-item v-bind="formItemLayout" label="最低价格（元）">
                            <a-input
                                    placeholder="请输入服务商最低价格"
                                    addon-before="￥"
                                    v-decorator="[
                'lowestPrice',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.formater.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.lowestPrice,
                },
              ]"
                            />
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="最高价格（元）">
                            <a-input
                                    addon-before="￥" placeholder="请输入服务商最高价格" v-decorator="[
                'highestPrice',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.maxThanMin.bind(this),
                    },
                  ],
                  initialValue: formData.shopInfo.highestPrice,
                },
              ]"
                            />
                        </a-form-item>
                    </div>
                    <div class="form-item">
                        <a-form-item v-bind="formItemLayout" label="营业时间">
                            <a-radio-group
                                    @change="onBusinessHoursChange"
                                    v-decorator="[
                'businessHours',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择服务商营业时间！',
                    },
                    {
                      validator: this.checkBusinessTime.bind(this),
                    },
                  ],
                  initialValue: needSetBusinessTime,
                },
              ]"
                            >
                                <a-radio :value="false">全天</a-radio>
                                <a-radio :value="true">自定义</a-radio>
                                <div v-show="needSetBusinessTime" style="margin-top: 0.1rem">
                                    <a-time-picker
                                            :default-open-value="moment('00:00', 'HH:mm')"
                                            placeholder="开始时间"
                                            format="HH:mm"
                                            v-decorator="[
                    'opentime',
                    {
                      initialValue: formData.shopInfo.openTime
                        ? moment(formData.shopInfo.openTime, 'HH:mm:ss')
                        : moment('00:00:00', 'HH:mm:ss'),
                    },
                  ]"
                                    />
                                    -
                                    <a-time-picker
                                            :default-open-value="moment('00:00', 'HH:mm')"
                                            placeholder="结束时间"
                                            format="HH:mm"
                                            v-decorator="[
                    'closetime',
                    {
                      initialValue: formData.shopInfo.closeTime
                        ? moment(formData.shopInfo.closeTime, 'HH:mm:ss')
                        : moment('00:00:00', 'HH:mm:ss'),
                    },
                  ]"
                                    />
                                </div>
                            </a-radio-group>
                        </a-form-item>
                        <div class="intro">
                            <a-form-item v-bind="formItemLayout" label="服务商简介" style="height: 1.5rem;">
                                <a-textarea
                                        style="width:6.21rem;height: 1.5rem;"
                                        :rows="4"
                                        placeholder="请输入服务商简介，200字以内"
                                        v-decorator="[
                'briefIntro',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.briefIntroCheck.bind(this),
                    },
                  ],
                  initialValue: formData.expansionInfo.briefIntro,
                },
              ]"
                                />
                            </a-form-item>
                        </div>

                        <a-form-item v-bind="formItemLayout" label="详细介绍" style="min-height: 4.2rem" class="abstract">
                            <a-input
                                    v-decorator="['detailDescription', { rules:[{ required: true, validator: this.checkDetailInfo.bind(this)}] }]"
                                    style="display: none"></a-input>
                            <div :style="{'height': `${ editorHeightFun }rem` }" style="width: 6.21rem">
                                <content-editor ref="contentEditor" init-placeholder="请输入服务商的详细介绍"
                                                @content-callback="HandleGetContentValue"/>
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="备注">
                            <a-textarea
                                    style="width:6.21rem;height: 1.5rem"
                                    :rows="2"
                                    v-decorator="[
                'orgRemark',
                {
                  initialValue: formData.expansionInfo.orgRemark,
                },
              ]"
                            />
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="服务信息" class="tip"></a-form-item>
                        <a-form-item v-bind="formItemLayoutTwo" label="服务类目">
                            <a-input
                                    v-decorator="['serviceCategory', { rules:[{ required: true, validator: this.newDatecs.bind(this)}] }]"
                                    style="display: none"></a-input>
                            <span style="color: #60c7c6; fontsize: 0.14rem; cursor: pointer" @click="add"><a-icon
                                    type="plus-circle" style="marginright: 5px"/>新增服务类目</span>
                            <el-table v-if="serviceCategoryList.length!=0" :data="serviceCategoryList"
                                      :span-method="objectSpanMethod"
                                      border>
                                <el-table-column prop="categoryOneName" label="一级服务类目" align="center"></el-table-column>
                                <el-table-column prop="categoryTwoName" label="二级服务类目" align="center"></el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template slot-scope="scope">
                                        <div class="remove" @click="delServiceInfo(scope.row)">删除</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="管理员信息" class="tip"></a-form-item>
                        <a-form-item v-bind="formItemLayout" label="管理员姓名">
                            <a-input
                                    placeholder="请输入管理员姓名"
                                    v-decorator="[
                'adminName',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.farenName.bind(this),
                    },
                  ],
                  initialValue: formData.administratorInfo.adminName,
                },
              ]"
                            />
                            <div class="fileTipBox2020050666">
                                请确保管理员信息准确，后续结算账户修改及支付密码等重要信息设置均需管理员验证
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="管理员身份证编号" style="margin-top: 0.33rem">
                            <a-input
                                    placeholder="请输入管理员身份证编号"
                                    v-decorator="[
                'adminIdNum',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.infoCardCheck.bind(this),
                    },
                  ],
                  initialValue: formData.administratorInfo.adminIdNum,
                },
              ]"
                            />
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="管理员手机号">
                            <a-input
                                    placeholder="请输入管理员手机号"
                                    v-decorator="[
                'adminTel',
                {
                  trigger: 'blur',
                  rules: [
                    {
                      required: true,
                      validator: this.telCheck.bind(this),
                    },
                  ],
                  initialValue: formData.administratorInfo.adminTel,
                },
              ]"
                            />

                            <div class="authCode">
                                <a-button
                                        v-if="!isSending"
                                        @click="handleSendCode"
                                        style="font-size: 0.16rem"
                                >{{ isSendingFisrt ? "点击获取验证码" : "重新获取" }}
                                </a-button
                                >
                                <a-button v-else style="font-size: 0.15rem">
                                    {{ `${remainTime}s后重新获取` }}
                                </a-button
                                >
                            </div>
                        </a-form-item>
                        <a-form-item v-bind="formItemLayout" label="验证码">
                            <a-input
                                    placeholder="请输入验证码"
                                    v-decorator="[
                'yzm',
                {
                  rules: [
                    {
                      required: true,
                      validator: this.yzmCheck.bind(this),
                    },
                  ],
                },
              ]"
                            />

                        </a-form-item>



                        <a-form-item v-bind="tailFormItemLayout" class="present">
                            <a-button class="button1" html-type="submit" @click="back()"><img
                                    src="../../assets/images/homeImg/路径 2330@2x.png"/>返回上一步
                            </a-button>
                            <a-button class="button2" v-if="buttonSet.enableClick" :loading="buttonSet.loading"
                                      type="primary" @click="handleSubmit"><img
                                    src="../../assets/images/homeImg/yes.png"/>提交审核
                            </a-button>
                            <a-button class="button2" v-else :loading="buttonSet.loading" type="primary" disabled><img
                                    src="../../assets/images/homeImg/yes.png"/>提交审核
                            </a-button>
                        </a-form-item>
                    </div>
                </a-form>
            </div>
        </div>
        <Footer/>

        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" :width="'30vw'">
            <div style="margin-top: 0.2rem;">
                <img alt="example" style="width: 100%;max-height: 5rem;margin-bottom: 0.3rem" :src="previewImage"/>
            </div>
        </a-modal>
        <a-modal width="6.6rem" v-model="visibleConfirm" @ok="handleClickConfirm" :afterClose="handleClickConfirm">
            <template slot="title">
                <div class="modal-header">
                    <span>入驻信息</span>
                </div>
            </template>
            <Confirm/>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="handleClickConfirm">
                        <span><img src="../../assets/images/homeImg/yes.png"/>确认</span>
                    </div>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="serviceCategoryModel" @cancel="handleCancel" width="6.3rem">
            <template slot="title">
                <div class="modal-header">
                    <span>新增服务类目</span>
                </div>
            </template>
            <div class="erviceCategoryStyle">
                <el-cascader-panel v-model="serviceData" :options="serviceTypes" :props="{ multiple: true}" clearable
                                   @change="value => cascaderChange(value)">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader-panel>
            </div>
            <template slot="footer">
                <div class="modal-footer">
                    <div class="ok-button" @click="erviceCategoryDate">
                        <span><img src="../../assets/images/homeImg/yes.png"/>确认</span>
                    </div>
                    <div class="cancel-button" @click="handleCancel">
                        <span><img src="../../assets/images/homeImg/no.png"/>取消</span>
                    </div>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import Confirm from "./ServiceJoinStep4";
    import ContentEditor from './util/content-editor';
    import {Button, DatePicker, Form, Icon, Input, message, Select, Upload,} from "ant-design-vue";
    import ReturnStep from "@/components/ReturnStep.vue";
    import MyStepBar from "@/components/enterMyStepBar";
    import moment from "moment";
    import "moment/locale/zh-cn";

    import {
        checkAccountNameExist,
        checkMobileValidateCode,
        checkUserNameExist,
        dictionaries,
        fetchAddrCascader,
        fetchOrganizationsAdd,
        fetchProvinceName,
        fetchServiceTypes,
        getVerificationCode,
        uploadServiceFile,
    } from "@/services/api";
    import {baseUrl} from "@/services/http";
    import ElementUI from 'element-ui';

    Vue.use(Button);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Upload);
    Vue.use(Icon);
    Vue.use(DatePicker);

    Vue.use(ElementUI);
    export default {
        components: {
            Nav,
            Footer,
            Header,
            ReturnStep,
            Confirm,
            ContentEditor,
            MyStepBar
        },
        data() {
            let heightCoefficient = window.innerHeight / 1080,
                // F(x) = -0.24200379107195477*x*x+4.46424066884904*x-0.20860334347523352
                editorHeightFun = heightCoefficient * heightCoefficient * (-0.24200379107195477) + 4.46424066884904 * heightCoefficient - 0.20860334347523352
            return {
                moment,
                editorHeightFun: editorHeightFun,
                issubmit: false,
                cheYZMBynet: false,
                visibleConfirm: false,
                imageUrl: "",
                isSending: false, // 是否发送
                isSendingFisrt: true,
                ifImageCanUploadTwo: true,
                options: [], // 地址选择器
                fileList: [],
                uploadAtlasList: [],
                remainTime: 60,
                serviceType: [], // 服务形式
                fnreType: [], // 服务商性质
                serviceTypes: [], // 服务商类目
                addrPlaceHolder: "请选择服务商所在地",
                needSetBusinessTime: false,
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 10},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 14},
                    },
                },
                formItemLayoutTwo: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 9},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 7},
                    },
                },
                tailFormItemLayout: {
                    wrapperCol: {
                        xs: {
                            span: 24,
                            offset: 0,
                        },
                        sm: {
                            span: 14,
                            offset: 10,
                        },
                    },
                },
                ifImageCanUpload: true,
                stepData: [
                    {
                        name: "入驻协议",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-1.png'),
                    },
                    {
                        name: "主体信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-2.png'),
                    },
                    {
                        name: "服务商信息",
                        time: "",
                        img: require('@/assets/images/homeImg/enter-3.png'),
                    },
                ],
                formData: {
                    organizationInfo: {
                        companyName: "",
                        orgCode: "",
                        companyProvince: "",
                        companyCity: "",
                        companyArea: "",
                        companyStreet: "",
                        companyAddressDetail: "",
                        companyContactNumber: "",
                        detailDescription: "",
                        companyAddress: [],
                    },
                    accountInfo: {
                        lpName: "",
                        lpIdNum: "",
                        lpTel: "",
                        accountName: "",
                        accountNumber: "",
                        bankName: "",
                        accountProvince: "",
                        accountCity: "",
                        accountArea: "",
                        accountStreet: "",
                        accountAddress: [],
                        accountDetailAdd: "",
                        lpIdPicturePositive: "",
                        lpIdPictureNegative: "",
                        businessLicensePicture: "",
                        emergencyContactName: "",
                        emergencyContactPhone: "",
                        accountOpeningPermit:"",
                        operatingLicense:"",
                        productQualificationCertificate:"",
                    },
                    administratorInfo: {
                        adminName: "",
                        adminIdNum: "",
                        adminTel: "",
                    },
                    expansionInfo: {
                        coverImage: "",
                        createTime: "",
                        briefIntro: "",
                        orgRemark: "",
                        areaSize: 0,
                        orgIntro: "",
                    },
                    shopInfo: {
                        orgAccount: JSON.parse(localStorage.getItem("userInfo"))["userName"],
                        orgName: "",
                        orgForm: [],
                        orgImagesList: [],
                        servicesCategoryList: [],
                        orgCharacter: "",
                        addressProvince: "",
                        addressCity: "",
                        addressArea: "",
                        addressStreet: "",
                        addressDetail: "",
                        lowestPrice: null,
                        highestPrice: null,
                        orgTel: "",
                        openTime: "",
                        closeTime: "",
                        shopAddress: [],
                    },
                    label: {
                        specialService: "",
                    },
                },
                atlasInstitutions:[],//服务商图集存储当前图片的路径
                previewVisible: false,
                serviceCategoryModel: false,
                previewImage: "",
                buttonSet: {
                    loading: false,
                    enableClick: false,
                },
                initialValue: {
                    // 需要进行初始化处理的数据
                    refcev: [], // 收住对象
                    serviceOrNot: '',
                    orgForm: [], // 养老形式
                    ndewi: [], // 服务类别
                    fnre: "", // 服务商性质
                    serviceTypes: [], // 服务类目
                    fwts: [], // 服务特色
                    orgImagesList: [], //上传图集
                },
                uploadCoverFileList: [],
                selectedData: [],
                serviceCategoryList: [],
                serviceData: []
            };
        },
        beforeCreate() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.form = this.$form.createForm(this);
            } else {
                this.$router.push("/login");
            }
        },
        mounted() {
            //获取服务类目
            let initRefr = {};
            this.init();
            this.visibleConfirm = false;
            dictionaries([67, 74]).then((res) => {
                if (res && res.code == 0) {
                    this.serviceType = res.values[74];
                    this.fnreType = res.values[67];
                } else {
                    message.error("获取服务类别失败");
                }
            }).then(() => {
                // 获取服务类目
                fetchServiceTypes()
                    .then((res) => {
                        if (res && res.code == 0) {
                            this.serviceTypes = res.data.list;
                        } else {
                            message.error(res.msg);
                        }
                    }).then((_) => {
                    // 返回上一步的时候数据给他填回去
                    let item = localStorage.getItem("serviceJoinForm");
                    if (item) {
                        let data = JSON.parse(item);

                        if (!data.organizationInfo.companyName) {
                            message.error("前置信息获取失败，请重新填写");
                            this.$router.back();
                            return;
                        }
                        this.formData = data;
                        //姓名回填
                        if (data.shopInfo.orgAccount == "") {
                            this.formData.shopInfo.orgAccount = JSON.parse(localStorage.getItem("userInfo"))["userName"]
                        }
                        //地址回填
                        if (this.formData.shopInfo.addressProvince != '' && this.formData.shopInfo.addressCity != '' && this.formData.shopInfo.addressArea != '') {
                            this.initAllArea([
                                this.formData.shopInfo.addressProvince,
                                this.formData.shopInfo.addressCity,
                                this.formData.shopInfo.addressArea
                            ]);
                        }

                        // 养老形式
                        if (this.formData.shopInfo.orgForm) {
                            if (typeof data.shopInfo.orgForm == "string") {
                                this.formData.shopInfo.orgForm = data.shopInfo.orgForm.split(",");
                                this.formData.shopInfo.orgForm.forEach((i) => this.initialValue.orgForm.push(parseInt(i)));
                            } else {
                                this.formData.shopInfo.orgForm = data.shopInfo.orgForm;
                                this.formData.shopInfo.orgForm.forEach((i) => this.initialValue.orgForm.push(parseInt(i)));
                            }
                        }
                        //封面图片
                        if (this.formData.expansionInfo.coverImage) {
                            this.uploadCoverFileList.push({
                                uid: "coverImage",
                                name: "coverImage.png",
                                status: "done",
                                url: this.formData.expansionInfo.coverImage.indexOf("/fileupload") == 0 ? `${baseUrl}${this.formData.expansionInfo.coverImage}` : this.formData.expansionInfo.coverImage,
                            });
                        }
                        //图集
                        this.formData.shopInfo.orgImagesList.forEach((item, index) => {
                            if (this.formData.shopInfo.orgImagesList) {
                                //图片数据赋值出来
                                this.atlasInstitutions.push({
                                    "uid": "imageUrl" + index,
                                    "path": item
                                })
                                this.uploadAtlasList.push({
                                    uid: "imageUrl" + index,
                                    name: "imageUrl" + index + ".png",
                                    status: "done",
                                    url: item.indexOf("/fileupload") == 0 ? `${baseUrl}${item}` : item,
                                });
                            }
                        })

                        // 服务商性质
                        if (this.formData.shopInfo.orgCharacter) {
                            this.initialValue.fnre = parseInt(this.formData.shopInfo.orgCharacter);
                        }

                        // 服务商类目
                        if (data.shopInfo.servicesCategoryList.length != 0) {
                            var dataNumber = [];
                            var dataNumber2 = [];
                            const list = data.shopInfo.servicesCategoryList
                            list.forEach(item => {
                                this.serviceTypes.forEach(item2 => {
                                    item2.children.forEach(item3 => {
                                        if (item === item3.value) {
                                            dataNumber.push({
                                                categoryId: item3.value,
                                                categoryOneName: item2.label,
                                                categoryTwoName: item3.label
                                            })
                                            dataNumber2.push([item2.value, item3.value])
                                        }
                                    })
                                })
                            })
                            this.serviceData = dataNumber2
                            this.sort_pro(dataNumber)

                        }

                        // 时间
                        if (this.formData.shopInfo.openTime && this.formData.shopInfo.closeTime
                        ) {
                            if (
                                this.formData.shopInfo.openTime == "00:00:00" &&
                                this.formData.shopInfo.closeTime == "00:00:00"
                            ) {
                                this.needSetBusinessTime = false;
                            } else {
                                this.needSetBusinessTime = true;
                            }
                            initRefr["businessHours"] = this.needSetBusinessTime;
                        }
                        if (data.shopInfo.addressProvince != '') {
                            var companyProvince = Number(data.shopInfo.addressProvince);
                            var companyCity = Number(data.shopInfo.addressCity);
                            var companyArea = Number(data.shopInfo.addressArea);
                            var companyStreet = Number(data.shopInfo.addressStreet);
                            this.formData.shopInfo.shopAddress = [companyProvince, companyCity, companyArea, companyStreet];
                            let m = JSON.parse(JSON.stringify(this.formData.shopInfo.shopAddress));
                            m.pop();
                            this.initAllArea(m);
                        }
                        if (data.shopInfo.lowestPrice != null && data.shopInfo.highestPrice != null) {
                            var reg = /.*\..*/;
                            var lowestPrice = reg.test(data.shopInfo.lowestPrice);
                            var highestPrice = reg.test(data.shopInfo.highestPrice);
                            if (lowestPrice != true && highestPrice != true) {
                                this.formData.shopInfo.lowestPrice = data.shopInfo.lowestPrice.toFixed(2)
                                this.formData.shopInfo.highestPrice = data.shopInfo.highestPrice.toFixed(2)
                            }
                        }

                        // 详细介绍
                        this.$refs['contentEditor'].init(data.organizationInfo.detailDescription)

                    } else {
                        message.error("前置信息获取失败，请重新填写");
                        this.$router.back();
                    }


                    this.buttonSet.loading = false;
                    this.buttonSet.enableClick = true;
                })
                    .then((_) => {
                        this.form.setFieldsValue(initRefr);
                    });
            });
        },
        methods: {
            numberkeyup(e) {
                const a = +e.target.value;
                //
                if (a) this.form.setFieldsValue({areaSize: a < 0 ? null : a});
            },
            //返回上一级
            back() {
                this.saveJoinForm();
                this.$router.push("/serviceJoinStep1");
            },
            getFormValue(name) {
                return this.form.getFieldValue(name);
            },
            /*  选择公司所在地  */
            onChange1(value, selectedOptions) {
                if (value.length >= 3 && value.length <= 4) {
                    this.formData.shopInfo.addressProvince = value[0];
                    this.formData.shopInfo.addressCity = value[1];
                    this.formData.shopInfo.addressArea = value[2];
                    this.formData.shopInfo.addressStreet = value[3] || "";
                    this.formData.shopInfo.shopAddress = value;
                    fetchProvinceName({
                        province: this.formData.shopInfo.addressProvince,
                        city: this.formData.shopInfo.addressCity,
                        area: this.formData.shopInfo.addressArea,
                        street: this.formData.shopInfo.addressStreet
                    }).then(res => {
                        if (res.code == 0) {
                            const add = res.info.addr;
                            this.formData.shopInfo.shopAddress = `${add.province}/${add.city}/${add.area}${add.street ? `/${add.street}` : ""}`;
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            },


            getImageShow(imageUrl) {
                return `${baseUrl}${imageUrl}`;
            },
            uploadCoverAll(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadCover(options);
                }, 1000);
            },
            // 上传封面
            uploadCover(options) {
                if (!this.ifImageCanUpload) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0,
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                })
                    .then((data) => {
                        if (data && data.code == 0) {
                            options.onSuccess();
                            this.formData.expansionInfo.coverImage = data.filePath;
                            this.uploadCoverFileList.push({
                                uid: "coverImage",
                                name: "coverImage.png",
                                status: "done",
                                url: this.formData.expansionInfo.coverImage.indexOf("/fileupload") == 0 ? `${baseUrl}${ data.filePath}` : data.filePath,
                            });
                        } else {
                            message.error(data.msg);
                            this.uploadCoverFileList.pop();
                            // file.onError(); //上传失败
                        }
                    })
                    .catch((error) => {
                        message.error("上传失败");
                        this.uploadCoverFileList.pop();
                        this.form.validateFields(["coverImage"], (errors) => {
                        });
                    });
            },
            // 移除封面
            uploadCoverRemove(file) {
                this.uploadCoverFileList = [];
                this.formData.expansionInfo.coverImage = "";
            },
            //校验图片上传
            uploadCoverFileCheck(rule, value, callback) {
                if (this.formData.expansionInfo.coverImage) {
                    callback();
                    return;
                }
                if (!value || value.fileList.length == 0) {
                    callback("请上传封面图片");
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUpload = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({coverImage: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();
                    return;
                } else {
                    this.ifImageCanUpload = true;
                    callback();
                }
            },
            // 图片预览
            async handlePicturePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await this.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            //自定义上传图集
            uploadAtlasAll(options) {
                let t = setTimeout(() => {
                    clearTimeout(t);
                    this.uploadAtlas(options);
                }, 1000);
            },
            //上传图集
            uploadAtlas(options) {
                if (!this.ifImageCanUploadTwo) return;
                let formData = new FormData();
                formData.append("file", options.file);
                formData.append("typeId", 3);
                let p = {
                    percent: 0
                };
                uploadServiceFile(formData, ({total, loaded}) => {
                    p.percent = +Math.round((loaded / total) * 100).toFixed(2);
                    options.onProgress(p);
                    if (p.percent >= 100) {
                        options.onProgress({percent: 99.3});
                    }
                }).then(data => {

                    if (data && data.code == 0) {
                        options.onSuccess();
                        //获取上传数据
                        const file = options.file;
                        this.atlasInstitutions.push({
                            "uid": file.uid,
                            "path": data.filePath,
                        })
                        this.uploadAtlasList.push({
                            uid: "imageUrl" + file.uid,
                            name: "imageUrl" + file.uid + ".png",
                            status: "done",
                            url:`${baseUrl}${data.filePath}` ,
                        });
                    } else {
                        message.error(data.msg);
                        this.uploadAtlasList.pop();
                    }
                }).catch(error => {
                    message.error("上传失败");
                    this.uploadAtlasList.pop();
                });
            },
            //移除图集
            uploadAtlasRemove(file) {
                this.uploadAtlasList = [];
                this.formData.shopInfo.orgImagesList = [];
            },
            //校验图集上传
            uploadCoverFileCheckOne(rule, value, callback) {
                if (this.formData.shopInfo.orgImagesList) {
                    callback();
                    return;
                }
                if (value.file.size > 3000 * 1000 * 1) {
                    this.ifImageCanUploadTwo = false;
                    value.fileList.length = 0;
                    this.form.setFieldsValue({coverImage: ""});
                    message.error("上传失败，请控制图片在3M之内");
                    callback();
                    return;
                } else {
                    this.ifImageCanUploadTwo = true;
                    callback();
                }
            },
            //获取地址信息并回填
            async initAllArea([a, b, c]) {
                let opton = this.options;
                for (let level of [a, b, c]) {
                    let item = opton.find((item) => item.value == level);
                    await this.loadData([item]);
                    opton = item.children;

                }
            },

            // 初始化地址
            init(place) {
                return new Promise((resolve) => {
                    fetchAddrCascader({
                        areaType: 0,
                        areaId: "",
                    }).then((data) => {
                        resolve();
                        if (data && data.code == 0) {
                            const list = data.data.list;
                            let children = [];
                            if (list) {
                                list.forEach((i) => {
                                    children.push({
                                        label: i.name,
                                        value: i.id,
                                        level: 1,
                                        isLeaf: false,
                                    });
                                });
                            }
                            this.options = children;
                        } else {
                            message.error(data.msg);
                        }
                    });
                });
            },
            // 获取地址信息
            loadData(selectedOptions) {
                if (selectedOptions[0] != undefined) {
                    var targetOption = selectedOptions[selectedOptions.length - 1];
                    const {value} = targetOption;
                    const {level} = targetOption;
                    targetOption.loading = true;

                    return new Promise((resolve) => {
                        fetchAddrCascader({
                            areaType: level,
                            areaId: value,
                        }).then((data) => {
                            resolve();
                            targetOption.loading = false;
                            if (data && data.code == 0) {
                                const list = data.data.list;
                                let children = [];
                                if (list) {
                                    list.forEach((i) => {
                                        children.push({
                                            label: i.name,
                                            value: i.id,
                                            isLeaf: i.childNum == 0,
                                            level: level + 1,
                                        });
                                    });
                                }
                                targetOption.loading = false;
                                targetOption.children = children;
                                this.options = [...this.options];
                            } else {
                                message.error(data.msg);
                            }
                        });
                    });
                }

            },


            // 将文件转成Base64
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            // 预览框关闭
            handleCancel() {
                this.previewVisible = false;
                this.serviceCategoryModel = false;
            },
            // 校验最高价格比最低价格要多
            maxThanMin(rule, value, callback) {
                if (!value) {
                    callback("服务商最高价格不能为空");
                    return;
                }
                if (+value <= 0) {
                    callback("金额格式不正确,价格不能为0和负数");
                    return;
                }
                let lowestPrice = parseInt(this.getFormValue("lowestPrice"));
                if (lowestPrice >= 0) {
                    if (parseInt(value) < lowestPrice) {
                        callback("最高价格不能比最低价格低");
                        return;
                    }
                }

                this.form.setFieldsValue({highestPrice: (+value).toFixed(2)});
                callback();
            },
            formater(rule, value, callback) {
                if (!value) {
                    callback("服务商最低价格不能为空");
                    return;
                }
                if (+value <= 0) {
                    callback("金额格式不正确,价格不能为0和负数");
                    return;
                }
                this.form.setFieldsValue({lowestPrice: (+value).toFixed(2)});

                callback();
            },


            // 选择经营时间
            onBusinessHoursChange(e) {
                this.needSetBusinessTime = e.target.value;
            },
            // 校验营业时间
            checkBusinessTime(rule, value, callback) {
                const that = this;
                if (value) {
                    // 是否是自定义
                    let openTime = that.getFormValue("opentime");
                    let closeTime = that.getFormValue("closetime");
                    if (!openTime) {
                        callback(new Error("请设置开始营业时间"));
                    }
                    if (!closeTime) {
                        callback(new Error("请设置营业结束时间"));
                    }
                }
                callback();
            },
            // 检查商家账号是否已存在
            checkExist(rule, value, callback) {
                // 校验服务商的名称是否被占用
                if (value) {
                    // 不能全是数字或者以数字开头
                    if (/^[0-9]*$/.test(value)) {
                        callback(new Error("用户名不能为纯数字，请重新输入"));
                        return;
                    }

                    checkUserNameExist(value)
                        .then((res) => {
                            if (res && res.code == 0) {
                                callback();
                            } else {
                                callback(res.msg);
                            }
                        })
                        .catch((e) => {
                            callback(e);
                        });
                } else {
                    callback(new Error("请填写服务商账号！"));
                }
            },
            // 检查商家名称是否已存在
            checkExistName(rule, value, callback) {
                // 校验服务商的名称是否被占用
                if (value) {
                    checkAccountNameExist(value)
                        .then((res) => {
                            if (res && res.code == 0) {
                                callback();
                            } else {
                                callback(res.msg);
                            }
                        })
                        .catch((e) => {
                            callback(e);
                        });
                } else {
                    callback(new Error("服务商名称不能为空"));
                }
            },
            //电话验证
            phoneCheck(rule, value, callback) {
                if (!value) {
                    callback("服务商电话不能为空");
                }
                const reg = /^\d{3}-\d{8}|\d{4}-\d{7}$/;
                const reg2 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value) && !reg2.test(value)) {
                    callback(
                        "格式不正确，请输入正确格式的公司电话,如：021-87888822,13812345678"
                    );
                } else {
                    callback();
                }
            },
            /* 法人名字校验 */
            farenName(rule, value, callback) {
                const tanslation = {
                    lpName: "法定代表人姓名",
                    accountName: "银行开户名",
                    bankName: "开户银行名称",
                    emergencyContactName: "公司紧急联系人",
                    adminName: "管理员姓名",
                };
                if (!value) {
                    callback(tanslation[rule.field] + "不能为空");
                    return;
                }
                const reg = /^[\u4e00-\u9fa5a-zA-Z ]{0,20}$/;

                if (!reg.test(value)) {
                    callback("只能输入汉字或字母");
                    return;
                } else if (value && value.trim().length < 2) {
                    callback("请填写正确格式的姓名");
                    return;
                }
                callback();
            },
            /*校验身份证号*/
            infoCardCheck(rule, value, callback) {
                const trans = {
                    lpIdNum: "法定代表人身份证号",
                    adminIdNum: "管理员身份证编号",
                };
                if (!value) {
                    callback(trans[rule.field] + "不能为空");
                }
                const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的身份证号");
                } else {
                    callback();
                }
            },
            /* 校验手机号 */
            telCheck(rule, value, callback) {
                const trans = {
                    lpTel: "法定代表人联系方式",
                    emergencyContactPhone: "公司紧急联系人手机",
                    adminTel: "管理员手机号",
                };
                if (!value) {
                    callback(trans[rule.field] + "不能为空");
                    return;
                }
                // ^\d{15}|\d{18}$
                const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                if (!reg.test(value)) {
                    callback("格式不正确，请输入正确格式的手机号码");
                } else {
                    callback();
                }
            },
            //验证码
            yzmCheck(rule, value, callback) {
                if (!value) {
                    callback("请填写验证码");
                    return;
                }
                if (!this.cheYZMBynet) {
                    return;
                }

                let getFormValue = (name) => this.form.getFieldValue(name);

                if (this.t) {
                    clearTimeout(this.t);
                }
                this.t = setTimeout(() => {
                    checkMobileValidateCode({
                        mobile: getFormValue("adminTel"),
                        code: getFormValue("yzm"),
                    }).then((res) => {
                        if (res && res.code == 0) {
                            callback();
                        } else {
                            callback("短信验证码错误或已失效，请重新获取");
                        }
                    });
                }, 100);
            },
            // 发送验证码
            handleSendCode() {
                let mobile = this.form.getFieldValue("adminTel");
                this.form.validateFields(["adminTel"], (errors, values) => {
                    if (!errors) {
                        var startTime = () => {
                            this.remainTime = 60;
                            this.isSending = true;
                            this.isSendingFisrt = false;
                            const t = setInterval(() => {
                                if (this.remainTime > 1) {
                                    this.remainTime--;
                                } else {
                                    this.isSending = false;
                                    clearInterval(t);
                                }
                            }, 1000);
                        };
                        getVerificationCode(mobile).then((res) => {
                            if (res && res.code === 0) {
                                startTime();
                                message.success("验证码已发送，5分钟内输入有效");
                            } else {
                                message.error(res.msg);
                            }
                        });
                        // }
                    }
                });
            },


            //获取数据排序
            sort_pro(data) {

                let group = {}
                let groupId = []
                let endList = []
                data.sort((a, b)=> a.categoryTwoName.localeCompare(b.categoryTwoName, 'zh'));
                data.forEach(i => {
                    // group[i.name] = group[i.name] || []
                    if (group[i.categoryOneName]) {
                        group[i.categoryOneName] = group[i.categoryOneName]
                    } else {
                        group[i.categoryOneName] = []
                        groupId.push(i.categoryOneName)
                    }
                    group[i.categoryOneName].push(i)
                })

                groupId.forEach(i => {
                    endList = endList.concat(group[i])
                })
                this.DataPush(endList)
            },

            DataPush(value) {
                this.serviceCategoryList = value
                this.getSpanArr(value);

            },
            // 合并表格数据
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1)
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].categoryOneName === data[i - 1].categoryOneName) {
                            this.spanArr[this.pos] += 1
                            this.spanArr.push(0)
                        } else {
                            this.spanArr.push(1)
                            this.pos = i
                        }
                    }
                }
            },
            // 表格合并
            objectSpanMethod({rowIndex, columnIndex}) {
                if (columnIndex === 0) {
                    const _row = this.spanArr[rowIndex]
                    const _col = _row > 0 ? 1 : 0
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                } else {
                    return false
                }
            },

            // 获取富文本框回调
            HandleGetContentValue(content) {
                this.formData.organizationInfo.detailDescription = Base64.encode(content.toString());
            },
            // 校验详细介绍
            checkDetailInfo(rule, value, callback) {
                if (this.formData.organizationInfo.detailDescription) {
                    callback()
                } else {
                    callback('请输入服务商的详细介绍')
                }
            },

            // 新增类目
            add() {
                this.serviceCategoryModel = true;
            },
            //类目选择框变化
            cascaderChange(value) {
                if (value.length === 0) {
                    this.selectedData = this.serviceCategoryList
                } else {
                    this.selectedData = value
                }
            },
            //服务类目为空判断
            newDatecs(rule, value, callback) {
                if (this.serviceCategoryList.length === 0) {
                    callback("服务类目不能为空，请选择。");
                    return
                }
                callback();
            },
            //确认新增类目
            erviceCategoryDate() {
                var dataNumber = [];
                var dataNumber2 = [];
                if (this.selectedData.length === 0) {
                    this.serviceCategoryList.forEach(item => {
                        this.serviceTypes.forEach(item2 => {
                            item2.children.forEach(item3 => {
                                if (item.categoryId === item3.value) {
                                    dataNumber.push({
                                        categoryId: item3.value,
                                        categoryOneName: item2.label,
                                        categoryTwoName: item3.label
                                    })
                                    dataNumber2.push([item2.value, item3.value])
                                }
                            })
                        })
                    })
                } else {
                    this.selectedData.forEach(item => {
                        this.serviceTypes.forEach(item2 => {
                            item2.children.forEach(item3 => {
                                if (item[1] === item3.value) {
                                    dataNumber.push({
                                        categoryId: item3.value,
                                        categoryOneName: item2.label,
                                        categoryTwoName: item3.label
                                    })
                                    dataNumber2.push([item2.value, item3.value])
                                }
                            })
                        })
                    })
                }
                this.sort_pro(dataNumber);
                this.serviceData = dataNumber2
                this.serviceCategoryModel = false

                this.form.setFieldsValue({
                    serviceCategory: 0
                })
                this.form.validateFields(['serviceCategory'], (err) => {
                })
            },
            //删除类目
            delServiceInfo(row) {
                const dataNumber22 = [];
                const dataNumber33 = [];
                this.serviceCategoryList.splice(this.serviceCategoryList.indexOf(row), 1)
                this.serviceCategoryList.forEach(item => {
                    this.serviceTypes.forEach(item2 => {
                        item2.children.forEach(item3 => {
                            if (item.categoryId === item3.value) {
                                dataNumber33.push({
                                    categoryId: item3.value,
                                    categoryOneName: item2.label,
                                    categoryTwoName: item3.label
                                })
                                dataNumber22.push([item2.value, item3.value])
                            }
                        })
                    })
                })
                this.sort_pro(dataNumber33);
                this.serviceData = dataNumber22
                if (this.serviceData.length === 0) {
                    this.form.setFieldsValue({
                        serviceCategory: 0
                    })
                    this.form.validateFields(['serviceCategory'], (err) => {
                    })
                }
            },


            // yPeng 服务商所在地
            companyAddrcheck(rule, value, callback) {
                if (this.formData.shopInfo.shopAddress.length == 0) {
                    callback("服务商所在地不能为空");
                } else {
                    callback();
                }
            },
            //服务商简介
            briefIntroCheck(rule, value, callback) {
                if (!value) {
                    callback("服务商简介不能为空");
                    return;
                }
                if (value.length > 200) {
                    callback("服务商简介不能超过200字");
                    return;
                }
                callback();
            },

            //确定
            handleClickConfirm(e) {
                this.$router.push("/ServiceJoinProcess");
            },

            handleSubmit(e) {
                // e.preventDefault();
                if (this.buttonSet.enableClick) {
                    this.buttonSet.loading = true;
                    this.buttonSet.enableClick = true;
                    this.cheYZMBynet = true;
                    this.form.validateFieldsAndScroll(
                        null,
                        {
                            first: false,
                            scroll: {
                                offsetTop: 120,
                            },
                        },
                        (err, values) => {
                            this.cheYZMBynet = false
                            if (!err) {
                                this.saveJoinForm();
                                fetchOrganizationsAdd(this.formData).then((res) => {
                                    this.buttonSet.loading = false;
                                    this.buttonSet.enableClick = true;
                                    if (res && res.code == 0) {
                                        // message.success("提交成功，等待审核");
                                        this.buttonSet.loading = false;
                                        this.buttonSet.enableClick = true;
                                        this.visibleConfirm = true;
                                    } else {
                                        this.buttonSet.loading = false;
                                        this.buttonSet.enableClick = true;
                                        message.error(res.msg);
                                    }
                                });
                            } else {
                                this.buttonSet.loading = false;
                                this.buttonSet.enableClick = true;
                            }
                        }
                    );
                }
            },
            // 存储数据
            saveJoinForm() {
                // 填充数据
                this.formData.shopInfo.orgAccount = this.getFormValue("orgAccount");
                this.formData.shopInfo.orgName = this.getFormValue("orgName");
                this.formData.shopInfo.orgForm = this.getFormValue("orgForm");
                this.formData.shopInfo.addressDetail = this.getFormValue("addressDetail");
                this.formData.shopInfo.lowestPrice = this.getFormValue("lowestPrice");
                this.formData.shopInfo.highestPrice = this.getFormValue("highestPrice");
                this.formData.shopInfo.orgTel = this.getFormValue("orgTel");
                this.formData.shopInfo.orgCharacter = this.getFormValue("fnre");

                // 服务类目填充
                this.formData.shopInfo.servicesCategoryList = [];
                var serviceCategoryType = []
                this.serviceCategoryList.forEach((i) => {
                    if (i != '') {
                        this.formData.shopInfo.servicesCategoryList.push(i.categoryId);
                    }
                });
                let businessTimeSet = this.getFormValue("businessHours");
                if (businessTimeSet) {
                    this.formData.shopInfo.openTime = this.getFormValue("opentime").format("HH:mm:ss");
                    this.formData.shopInfo.closeTime = this.getFormValue("closetime").format("HH:mm:ss");
                } else {
                    this.formData.shopInfo.openTime = "00:00:00";
                    this.formData.shopInfo.closeTime = "00:00:00";
                }
                if (this.formData.shopInfo.orgForm) {
                    if (
                        Array.isArray(this.formData.shopInfo.orgForm) &&
                        this.formData.shopInfo.orgForm.length > 0
                    ) {
                        this.formData.shopInfo.orgForm = this.formData.shopInfo.orgForm.join();
                    }
                }

                this.formData.expansionInfo.createTime = this.getFormValue("createTime");
                this.formData.expansionInfo.areaSize = this.getFormValue("areaSize");
                this.formData.expansionInfo.briefIntro = this.getFormValue("briefIntro");
                this.formData.expansionInfo.orgIntro = this.getFormValue("orgIntro");
                this.formData.expansionInfo.orgRemark = this.getFormValue("orgRemark");

                //管理员信息
                this.formData.administratorInfo.adminName = this.getFormValue("adminName");
                this.formData.administratorInfo.adminIdNum = this.getFormValue("adminIdNum");
                this.formData.administratorInfo.adminTel = this.getFormValue("adminTel");
                this.formData.shopInfo.orgImagesList= this.atlasInstitutions.map(i => i.path);
                localStorage.setItem("serviceJoinForm", JSON.stringify(this.formData));
            },
        },
    };
</script>
<style lang="less">
    .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
        color: #60c7c6 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #60c7c6 !important;
        border-color: #60c7c6 !important;
    }
    //隐藏弹出框最外边框
    .el-cascader-panel.is-bordered{
        border: none !important;
    }
    //设置类目弹出框框中样式（下三个）
    .el-cascader-menu{
        height: 1.8rem;
        border:1px solid #E4E7ED !important;
        border-radius: 4px;

    }
    .el-cascader-menu:first-child{
        margin-left: 0.3rem;
    }
    .el-cascader-menu:last-child{
        margin-left: 0.4rem;
        .el-cascader-node__label{
            min-width: 1.73rem;
            overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
        }
    }
    //设置类目表格样式（下三个）
    .el-table th>.cell {
        font-weight: 600;
        font-size: 14px;
        color: #606979;
        opacity: 1;
    }
    .cell{
        font-size: 0.14rem;
        color: #606979;
        opacity: 1;
    }
    //操作删除样式
    .remove{
        font-size:0.14rem;
        color: #39BEB8;
        opacity: 1;
        cursor: pointer;
    }
    //创建时间组件样式
    .ant-calendar-panel {

        .ant-calendar-input {
            font-size: 0.14rem;

            &::placeholder {
                font-size: 0.14rem;
            }
        }

        .ant-calendar-body, .ant-calendar-today-btn, .ant-calendar-month-select, .ant-calendar-year-select {
            font-size: 0.14rem;
        }
    }

    .ql-editor.ql-blank:before {
        font-size: 0.14rem;
    }
    .serviceJoinStep2 {
        margin-top: -0.01rem;

        .stepTop {
            width: 100%;
            display: flex;
            margin-bottom: 0.6rem;
            position: relative;

            img {
                width: 100%;
                height: 1.2rem;
            }

            .rz-type {
                position: absolute;
                left: 4.4rem;
                top: 0.39rem;
                font-size: 0.24rem;
                color: #fff;
                font-weight: bold;
                opacity: 1;
            }
        }

        .progressBar {
            width: 4.5rem;
            height: 1rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form-box {
            /*border: 1px solid red;*/
            padding: 0.3rem 0;
            margin-bottom: 0.7rem;
            .ant-select-selection__placeholder, .ant-select-selection__choice__content {
                font-size: 0.14rem;
            }

            .ant-checkbox-group-item {
                span {
                    font-size: 0.14rem;
                }

            }
            .tip {
                /*height: 0.5rem;*/
                /*border: 1px solid red;*/
                margin-top: 0.2rem;
                .ant-form-item-label label {
                    font-size: 0.14rem;
                    font-weight: bold;
                    color: #454F63;
                    opacity: 1;
                }

                .ant-form-item-label > label::after {
                    content: '';
                }
            }

            .ant-input {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
                width: 3.53rem;
                height: 0.4rem;

                &::placeholder {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #8F9BA8;
                    opacity: 1;
                }
            }

            .form-item {
                .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                    width: 100%;
                    /*object-fit: contain;*/
                    object-fit: fill;
                    border-radius: 4px;
                }
                .property{
                    /*border: 1px solid red;*/
                    .ant-form-item-control-wrapper {
                        .ant-form-explain {
                            font-size: 0.14rem;
                            color: #FF4021;
                            opacity: 1;
                            margin-left: 5.7rem;
                            margin-top: -0.3rem;
                        }
                    }
                }
                .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item{
                    padding: 0;
                }
                .imageUpload {
                    .ant-form-item-control-wrapper {
                        .ant-form-explain {
                            font-size: 0.14rem;
                            color: #FF4021;
                            opacity: 1;
                            margin-left: 3.7rem;
                            margin-top: -0.8rem;
                        }
                    }
                }
                .abstract {
                    margin-top: 1rem;

                    .ant-form-item-control-wrapper {
                        .ant-form-explain {
                            width: 3rem;
                            font-size: 0.14rem;
                            color: #FF4021;
                            opacity: 1;
                            margin-left: 6.3rem;
                        }
                    }
                }

                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        width: 3rem;
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                        margin-left: 3.7rem;
                        margin-top: -0.33rem;
                    }
                }

                .authCode {
                    position: absolute;
                    top: -0.115rem;
                    left: 2.15rem;
                    height: 0.41rem;

                    button {
                        width: 1.39rem;
                        height: 100%;
                        background: #F5AA21;
                        font-size: 0.14rem;
                        color: #FFFFFF;
                        opacity: 1;
                    }

                    button:hover {
                        border: 1px solid #F5AA21;
                        background: #F5AA21;
                    }

                }
            }


            .detailsTheData {
                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                    }
                }
                .ant-input {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;
                    width: 3.15rem;
                    height: 0.4rem;

                    &::placeholder {
                        font-size: 0.14rem;
                        font-weight: 400;
                        color: #8F9BA8;
                        opacity: 1;
                    }


                }

            }

            .intro {
                .ant-form-item-control-wrapper {
                    .ant-form-explain {
                        font-size: 0.14rem;
                        color: #FF4021;
                        opacity: 1;
                        margin-left: 6.4rem;
                        margin-top: -0.8rem;
                    }
                }
            }


            .present {
                margin-top: 0.8rem;

                button {
                    width: 1.46rem;
                    height: 0.4rem;
                    opacity: 1;
                    border-radius: 0.2rem;
                    font-size: 0.14rem;

                    img {
                        width: 7.75px;
                        height: 13.38px;
                    }
                }

                .button1 {
                    border: 1px solid #78849E;
                    margin-right: 0.5rem;
                    color: #454F63;

                    img {
                        margin-right: 0.07rem;
                    }
                }

                .button2 {
                    color: #FFFFFF;

                    img {
                        width: 14.3px;
                        height: 14px;
                        margin-right: 0.07rem;
                    }
                }
            }

            .ant-cascader-picker-label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }


            .ant-form-item-label label {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-form-item {
                margin-bottom: 0.2rem;
            }

            .ant-input:hover {
                border-color: #60c7c6;
            }

            .ant-select-selection:hover {
                border-color: #60c7c6;
            }
            .ant-radio-wrapper {
                font-size: 0.14rem;
                font-weight: 400;
                color: #454F63;
                opacity: 1;
            }

            .ant-radio-checked .ant-radio-inner {
                border-color: #60c7c6;
                span{
                    font-size: 0.14rem;
                }
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }

            .ant-radio-inner::after {
                background-color: #60c7c6;
            }

            .ant-btn-primary {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }

            .ant-btn-primary:hover,
            .ant-btn-primary:focus {
                background-color: #60c7c6;
                border-color: #60c7c6;
            }
        }

        .fileTipBox2020050666 {
            position: absolute;
            font-size: 0.14rem;
            font-weight: 400;
            color: #a9a9a9;
            opacity: 1;
            top: 0.33rem;
            display: flex;
            align-items: center;
            height: 0.2rem;
            width: 9rem;
        }

    }

    .erviceCategoryStyle {
        margin-bottom: 0.1rem;

        .erviceCategoryButton {
            margin-bottom: -0.3rem;
            margin-top: 0.1rem;
            text-align: right;

            button:first-child {
                margin-right: 0.2rem
            }

            button:last-child {
                background-color: #1890ff;
                color: #fff;
                border-color: #1890ff;
                text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
                box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
            }
        }
    }
</style>

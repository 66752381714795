<template>
    <div>
<!--        v-if="!isFixed"-->
        <div class="creditReportingNav">
            <div class="creditReporting_menu">
                <div class="creditReporting_top">
                    <div class="logo" @click="jumpToHome">
                        <span class="menu_top">信用征信平台</span>
                    </div>

                    <div class="creditReporting_address">
                        <a-icon type="environment" :style="{ color: '#60c7c6', marginRight: '5px' }"/>
                        <span class="addressSpan">{{ currentCity.includes("全国") ? "全国" : currentCity }}</span>
                        <span class="userSpan"
                              @click="$router.push('/personal/userRatings')">你好，尊敬的{{userInfo.nickname}}</span>
                    </div>
                </div>
                <div class="searchBox">
                    <span class="centerSpan">信用 · 让你我更好互联</span>
                    <div class="screeningCondition">
                        <div class="condition">
                            <div class="condition_list" :class="this.choice===395?'condition_list_one':''" @click="choose(395)">全部信用记录</div>
                            <div class="condition_list" :class="this.choice===397?'condition_list_one':''" @click="choose(397)">正常信用记录</div>
                            <div class="condition_list" :class="this.choice===396?'condition_list_one':''" @click="choose(396)">异常信用记录</div>
                        </div>

                    </div>
                    <div class="search">
                        <a-input-search :value="searchVal" placeholder="请输入服务商、养老机构、商家" @change="onSearchChange" @search="onSearch"/>
                        <!--                        <div class="recommen">-->
                        <!--                            <span class="recommendSpan">推荐：<span v-for="(item,index) in type" @click="seeRecommendations(item.name)" :key="index">{{item.name}}<span style="color: #fff" v-if="index-(type.length-1)">，</span></span></span>-->
                        <!--                        </div>-->
                    </div>


                </div>0
            </div>
        </div>
<!--        <div class="fixation" v-else>-->
<!--            <div class="menu">-->
<!--                <div class="logo" @click="jumpToHome"></div>-->
<!--                <div class="search">-->
<!--                    <a-input-search-->
<!--                            style="display: flex"-->
<!--                            :value="searchVal"-->
<!--                            @change="onSearchChange"-->
<!--                            @search="onSearch"-->
<!--                            placeholder="请输入服务商、养老机构、商家、用户"-->
<!--                            enterButton="搜索"-->
<!--                    />-->
<!--                </div>-->
<!--                <div class="appImg">-->
<!--                    <img style="width: 0.9rem;height: 0.85rem" src="../../assets/images/app.png"/>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    // import castAbout from "../../views/creditInvestigation/components/castAbout";

    export default {
        components: {},

        data() {
            return {
                choice:395,
                currentCity: "正在定位...",
                userInfo: {},
                searchVal: "",
                type: [{name: '服务商'}, {name: '养老机构'}, {name: '商家'}, {name: '用户'}],
                isFixed: false,
            };
        },
        mounted() {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
                this.userInfo = JSON.parse(userInfo);
            }
            window.addEventListener("scroll", this.handleScroll);
            //获取定位信息
            if (localStorage.getItem("setCity")) {
                const str = localStorage.getItem("setCity");
                const obj = JSON.parse(str);
                this.currentCity = obj.cityName;
                localStorage.setItem("provinceId", obj.cityId);
            }
        },
        methods: {
            jumpToHome() {
                this.$router.push(`/creditInvestigation/creditReportingHome`);
            },
            //选择条件
            choose(val){
                this.choice=val;
            },
            //监听input中数据
            onSearchChange(e) {
                this.searchVal = e.target.value;
            },
            //input点击跳转
            onSearch(value) {

                let data ={
                    "value": this.searchVal,
                    "choice":this.choice
                }
                sessionStorage.setItem("creditSearch", JSON.stringify(data));
                const path = window.location.href.substring(
                    window.location.href.lastIndexOf("/") + 1,
                    window.location.href.length
                );

                if (path !== "creditReportingServicePage") {
                    this.$router.push("/creditInvestigation/creditReportingServicePage");
                }
                if (this.$props.handleNavSearch) {
                    this.$props.handleNavSearch(value);
                }
            },
            //查看推荐
            seeRecommendations(value) {

            },
            //下拉获取
            handleScroll(e) {
                const scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (scrollTop > 120) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
                this.$store.commit("show_zxptDiv", this.isFixed);
            },
        }
    }
</script>

<style type="text/less" lang="less" scoped>


    .creditReportingNav {
        height: 5rem;
        font-size: 0.14rem;
        z-index: 999;
        width: 100%;
        background-image: url("../../assets/zxImages/crp_mainpage_banner.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 5rem;
        text-align: center;
        min-width: 15.2rem;

        .creditReporting_menu {
            white-space: nowrap;
            height: 0.87rem;
            position: relative;
            width: 100%;
            margin: 0 auto;

            .creditReporting_top {
                height: 0.87rem;

                .logo {
                    float: left;
                    height: 0.6rem;
                    width: 1.25rem;
                    background-image: url("../../assets/zxImages/logo.png");
                    background-position: left;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    margin-left: 2.7rem;
                    margin-top: 0.15rem;

                    .menu_top {
                        font-size: 0.25rem;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 0.68rem;
                        margin-left: 1.8rem;

                    }
                }

                .creditReporting_address {
                    float: right;
                    margin-top: 0.4rem;
                    /*border: 1px solid red;*/
                    /*line-height: 0.87rem;*/
                    margin-right: 1.7rem;

                    .addressSpan {
                        cursor: pointer;
                        display: inline-block;
                        color: #fff;
                        margin-right: 0.4rem;
                    }

                    .userSpan {
                        cursor: pointer;
                        display: inline-block;
                        color: #fff
                    }
                }
            }

            .searchBox {
                width: 100%;
                height: 4.13rem;
                .centerSpan {
                    font-size: 0.5rem;
                    display: inline-block;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-top: 0.6rem;
                }

                .screeningCondition {
                    height: 0.5rem;
                    width: 35%;
                    margin: 0 auto;
                    margin-top: 0.9rem;
                    display: flex;
                    align-items: center;
                    .condition{
                        width: 80%;
                        height: 0.5rem;
                        display: flex;
                        align-items: center;
                        .condition_list{
                            height: 0.5rem;
                            /*width: 35%;*/
                            font-size: 0.19rem;
                            padding-left: 0.2rem;
                            padding-right: 0.2rem;
                            font-weight: bold;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            border-bottom: 3px solid rgba(255,255,255,0.001);
                        }
                        .condition_list_one{
                            border-bottom: 3px solid #f6ab1a;
                            color: #f6ab1a;
                        }


                    }
                }



            }

            .search {
                width: 35%;
                /*height: 1rem;*/
                margin: 0 auto;
                margin-top: 0.1rem;

                .ant-input-search {
                    height: 0.45rem;
                    font-size: 0.17rem;
                }

                .recommen {
                    text-align: left;
                    margin-top: 0.07rem;
                    /*border: 1px solid red;*/

                    .recommendSpan {
                        font-size: 0.15rem;
                        font-family: Microsoft YaHei;
                        /*display: inline-block;*/
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 0.6rem;
                        /*text-align: left;*/

                        span:hover {
                            cursor: pointer;
                            color: #f6ab1a;
                        }
                    }
                }

            }


        }
    }

    .fixation {
        border: 1px solid #fff;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background-color: #fff;
        padding-top: 0.1rem;
        border-bottom: 1px solid #d9d9d9;
        height: 1rem;
        min-width: 15.2rem;

        .logo {
            float: left;
            height: 0.84rem;
            width: 2.43rem;
            background-image: url("../../assets/images/logo.png");
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
            cursor: pointer;
            margin-left: 2.3rem;
        }

        .search {
            height: 0.5rem;
            margin-top: 0.3rem;
            position: absolute;
            top: -5%;
            left: 35%;

            /deep/ .ant-input {
                width: 5rem;
                font-size: 0.16rem;
                line-height: 0.84rem;
                height: 0.4rem;
            }
        }

        /deep/ button.ant-btn.ant-btn-primary.ant-input-search-button {
            width: 0.9rem;
            height: 0.4rem;
            background-color: #60c7c6;
            border-radius: 0;
            border-color: #60c7c6;
            font-size: 0.2rem;
        }

        /deep/ input.ant-input:focus {
            border-color: #d9d9d9;
            border-radius: 0;
            -webkit-box-shadow: 0 0 0 0;
            box-shadow: 0 0 0 0;
        }

        /deep/ input.ant-input:hover {
            border-color: #d9d9d9;
        }

        /deep/ input.ant-input {
            border-radius: 0;
        }

        .appImg {
            position: absolute;
            top: -0.34rem;
            left: 15.1rem;
        }

    }

</style>


















































































import {Component, Vue} from "vue-property-decorator";
import {Rate} from "ant-design-vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";

Vue.use(Rate);

@Component({
    props: {
        children: Array,
        orgItem: Object
    },
    components: {
        InstitutionInfo
    }
})
export default class YlmsItem extends Vue {
    // @Prop() private msg!: string
    tagBgColor = ["#8285ec", "#52b2ef", "#f87180"];
    allshow = false;
    showArr = [];

    mounted() {
        if (this.$props.children)
            this.showArr = this.$props.children;
    }

    handleJumpToOrgDetail() {
        if (this.$props.orgItem.orgId)
            this.$router.push(`/organizationDetail/${this.$props.orgItem.orgId}`);
    }
}

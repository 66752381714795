var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process-item"},[_c('div',{staticClass:"img",style:({
      width:  _vm.itemType==2?'104%':'100%',
      backgroundImage:
        'url(' +
        require('../assets/images/' + _vm.itemImage + '.png') +
        ')'
    })},[_c('div',{staticClass:"content",style:({
    marginTop:_vm.itemType==2||_vm.itemType==3?'-0.2rem':'0'})},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.itemTitle))]),_c('div',[_vm._v(_vm._s(_vm.itemContent))])])]),(_vm.showArrow&&_vm.itemType==1)?_c('img',{staticClass:"imgArray imgArray2",attrs:{"src":require("../assets/images/lvsejiantou_icon_jgyl.png")}}):_vm._e(),(_vm.showArrow&&_vm.itemType==2||_vm.showArrow&&_vm.itemType==3)?_c('img',{staticClass:"imgArray",style:({right:_vm.itemType==3?0:''}),attrs:{"src":require("../assets/images/huangjiantou_icon_zhucan.png")}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
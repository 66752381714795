


















import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class Banner extends Vue {
    @Prop() private bannerList!: Array<[]>;

    @Watch("bannerList", {immediate: true, deep: true})
    getBannerList(newVal, oldVal) {
        this.currentBanner = newVal;
        this.startInterval();
    }

    togo(src) {
        window.open(src, "_blank");
    }

    currentKey = 1;
    timer = 0;
    time = 7000;
    cc = 1000;
    currentBanner: any = [];

    updateCurrentKey() {
        if (this.currentKey > this.currentBanner.length - 1) {
            this.currentKey = 1;
            return;
        }
        this.currentKey = this.currentKey + 1;
    }

    dotClick(key: number) {
        this.stopInterval();
        this.currentKey = key;
        this.startInterval();
    }

    startInterval() {
        this.timer = setInterval(this.updateCurrentKey, 7000);
    }

    stopInterval() {
        clearInterval(this.timer);
        this.timer = 0;
    }
}



























    import {Component, Vue, Watch} from "vue-property-decorator";
    import {Button, Table} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ServiceDetailInfo from "@/components/Personal/MyService/ServiceDetailInfo.vue";
    import ServiceDetailPriceCard from "@/components/Personal/MyService/ServiceDetailPriceCard.vue";
    import ServiceProcessSteps from "./classServicesteps.vue";
    import ServiceDetailTable from "./ServiceDetailTable.vue";
    import {Mutation, State} from "vuex-class";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import MyStepBar from "@/components/Personal/MyStepBar.vue";

    Vue.use(Table);
Vue.use(Button);

@Component({
  components: {
    PersonalHeader,
    MyStepBar,
    ServiceDetailInfo,
    ServiceDetailPriceCard,
    ServiceProcessSteps,
    ServiceDetailTable,
    Breadcrumb
  }
})
export default class extends Vue {
  @Mutation SET_CURDETAILSTATUS;
  @Mutation SET_DETAILITEM;

  created() {
    const str1: any = localStorage.getItem("CURDETAILSTATUS");
    this.SET_CURDETAILSTATUS(JSON.parse(str1), true);
    const str2: any = localStorage.getItem("DETAILITEM");
    this.detailItemList =JSON.parse(str2)
    this.SET_DETAILITEM(JSON.parse(str2), true);
    localStorage.setItem("laterPath", "/personal/serviceDetail");

  }


  // 5是取消 6 交易关闭
  currentStatus = 1; // 根据状态动态调整currentStatus的值
  detailItemList={};
  @State curOrderStatus;
  @State detailItem;
  stepData = [
    {
      name: "拍下服务",
      time: "",
      img:require('@/assets/images/homeImg/flow-1.png'),
    },
    {
      name: "买家付款",
      time: "",
      img:require('@/assets/images/homeImg/flow-2.png'),
    },
    {
      name: "服务订单进行中",
      time: "",
      img:require('@/assets/images/homeImg/flow-3.png'),
    },
    {
      name: "确认服务订单完成",
      time: "",
      img:require('@/assets/images/homeImg/flow-4.png'),
    },
    {
      name: "评价",
      title: "",
      img:require('@/assets/images/homeImg/flow-5.png'),
    }
  ];
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "我的服务",
      path: "/personal/myService",
      significance: 0
    },
    {
      name: "订单详情",
      path: "/personal/serviceDetail",
      significance: 1
    },
  ]
  @Watch("curOrderStatus", { immediate: true, deep: true })
  getCurStatus(newVal, oldVal) {
    this.changeStatus(newVal);
  }

  curStatusChange(newVal) {
    this.changeStatus(newVal);
  }
  changeStatus(newVal: any) {
    if (newVal.paixiafuwu) {
      this.currentStatus = 1;
    } else if (newVal.maijiafukuan) {
      this.currentStatus = 2;
    } else if (newVal.fuwutigongshang) {
      this.currentStatus = 3;
    } else if (newVal.querenfuwu) {
      this.currentStatus = 4;
    } else if (newVal.pingjia) {
      this.currentStatus = 5;
    } else if (newVal.guanbi) {
      this.currentStatus = 6;
    }
  }
  columns = [
    {
      title: "服务",
      dataIndex: "service",
      key: "service",
      align: "center",
      scopedSlots: { customRender: "service" }
    },
    {
      title: "单价",
      dataIndex: "price",
      key: "price",
      align: "center"
    },
    {
      title: "数量",
      dataIndex: "count",
      key: "count",
      align: "center"
    },
    {
      title: "优惠",
      dataIndex: "discount",
      key: "discount",
      align: "center"
    },
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      align: "center"
    }
  ];
  tableData: [];
  data() {
    return { tableData: [] };
  }
  ifRedund: any = 1;
  firstRefundId = 0;
  firstRelationId = 0;
  serviceId=''
  mounted() {
    let that: any = this;
    this.ifRedund = this.detailItem.services[0].ifRedund;
    this.firstRefundId = this.detailItem.services[0].refundId;
    this.firstRelationId = this.detailItem.services[0].relationId;
    this.serviceId = this.detailItem.services[0].serviceId;
    this.stepData[0].time = this.detailItem.createdTime;
    this.stepData[1].time = this.detailItem.payTime;
    this.stepData[2].time = this.detailItem.servingTime;
    this.stepData[3].time = this.detailItem.endTime;
    this.stepData[4].time = this.detailItem.commentTime;
    this.detailItem.services.forEach((el, i) => {
      let newObj = {
        key: i,
        service: {
          serviceName: el.serviceName,
          serviceImg: el.servicePic
        },
        price: el.price,
        count: el.quantity,
        discount: 0,
        state: el.serviceStatus
      };
      that.tableData.push(newObj);
    });
  }
}

<template>
    <div>
        <Header/>
        <Nav/>
        <div class="businessJoin-main">
            <div class="bg">
                <p class="top">马上入驻<span style="marginLeft: 0.4rem;">开向未来</span></p>
                <p class="bottom">加入芳园天伦，让千万老人发现你的独特</p>
            </div>

            <p class="title">
                入驻类型
            </p>
            <div class="rzlx">
                <!-- 服务商-->
                <div class="item">
                    <p class="top">服务商</p>
                    <p class="center">适用于能提供居家养老服务、社区养老服务或其他服务提供商。</p>
                    <div v-if="!facilitatorLoading" class="button">
                        <a-button
                                v-if="!status.facilitatorStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem;width: 1.6rem"
                                @click="$router.push('/serviceJoinRead')"
                        >我要入驻
                        </a-button>
                        <a-button
                                v-if="status.facilitatorStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem;width: 1.6rem"
                                @click="$router.push('/ServiceJoinProcess')"
                        >入驻进度查询
                        </a-button>
                    </div>
                </div>

                <!-- 机构-->
                <div class="item">
                    <p class="top">养老机构</p>
                    <p class="center">适用于提供老人入驻服务的养老机构为主，提供相应的居家养老服务、社区养老服务或其他服务的为辅的养老机构。</p>
                    <div v-if="!organizationLoading" class="button">
                        <a-button
                                v-if="!status.organizationStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem;width: 1.6rem"
                                @click="$router.push('/OrganizationJoinRead')"
                        >我要入驻
                        </a-button>
                        <a-button
                                v-if="status.organizationStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem; width: 1.6rem"
                                @click="$router.push('/OrganizationJoinProcess')"
                        >入驻进度查询
                        </a-button>
                    </div>
                </div>
                <!-- 商家-->
                <div class="item">
                    <p class="top">商家</p>
                    <p class="center">适用于销售—般健康养老产品、智慧健康养老产品等养老商品分类的商户。</p>
                    <div v-if="!merchantLoading" class="button">
                        <a-button
                                v-if="!status.merchantStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem;width: 1.6rem"
                                @click="$router.push('/businessJoinRead')"
                        >我要入驻
                        </a-button>
                        <a-button
                                v-if="status.merchantStatus"
                                type="primary"
                                style="background: #60c7c6; borderColor: #60c7c6;height: 0.46rem;fontSize: 0.2rem;width: 1.6rem"
                                @click="$router.push('/BusinessJoinStepProcess')"
                        >入驻进度查询
                        </a-button>
                    </div>
                </div>
            </div>


            <p class="title">
                入驻流程
            </p>
            <div class="rzlc">
                <div class="step">
                    <div class="image step1"></div>
                    <p class="first">提交入驻资料</p>
                    <p>注册芳园天伦个人用户账号。</p>
                    <p>进入入驻中心页面,选择入驻类型点击"立即入驻"。</p>
                </div>
                <div class="next">
                    <img style="margin-top: 0.6rem;" src="../../assets/images/xuxian.png" alt/>
                </div>
                <!--                style="width: 2.2rem"-->
                <div class="step">
                    <div class="image step2"></div>
                    <p class="first">填写/提交信息及资料</p>
                    <p>填写公司、入驻信息，提交资质。</p>
                    <p>填写入驻名称。</p>
                    <p>确认在线入驻协议。</p>
                </div>
                <div class="next">
                    <img style="margin-top: 0.6rem;" src="../../assets/images/xuxian.png" alt/>
                </div>
                <div class="step">
                    <div class="image step3"></div>
                    <p class="first">等待平台审核</p>
                    <p>平台1-3个工作日内反馈审核结果。</p>
                    <p>可查询入驻审核进度，及接收入驻进度短信通知。</p>
                </div>
                <div class="next">
                    <img style="margin-top: 0.6rem;" src="../../assets/images/xuxian.png" alt/>
                </div>
                <div class="step">
                    <div class="image step4"></div>
                    <p class="first">正式上线</p>
                    <p>登陆后台管理中心正常经营。</p>
                    <p>发布服务以及商品。</p>
                </div>
            </div>

            <p class="title" style="margin-bottom: 0">热招服务类目</p>
            <div class="container">
                <div class="item">
                    <div class="image pic1"></div>
                    <p>助餐服务</p>
                </div>
                <div class="item">
                    <div class="image pic2"></div>
                    <p>助洁服务</p>
                </div>
                <div class="item">
                    <div class="image pic3"></div>
                    <p>小时工</p>
                </div>
                <div class="item">
                    <div class="image pic4"></div>
                    <p>身体测评</p>
                </div>
                <div class="item">
                    <div class="image pic5"></div>
                    <p>维修服务</p>
                </div>
                <div class="item">
                    <div class="image pic6"></div>
                    <p>适老化改造</p>
                </div>
            </div>
            <p class="title" style="margin-bottom: 0">热招类目</p>
            <div class="container">
                <div class="item">
                    <div class="image pic1"></div>
                    <p>老年人日常生活用品</p>
                </div>
                <div class="item">
                    <div class="image pic2"></div>
                    <p>老年人护理用品</p>
                </div>
                <div class="item">
                    <div class="image pic3"></div>
                    <p>老年人养生保健用品</p>
                </div>
                <div class="item">
                    <div class="image pic4"></div>
                    <p>老年人娱乐用品</p>
                </div>
                <div class="item">
                    <div class="image pic5"></div>
                    <p>老年人旅游装备用品</p>
                </div>
                <div class="item">
                    <div class="image pic6"></div>
                    <p>老年人出行代步用品</p>
                </div>
                <div class="item">
                    <div class="image pic7"></div>
                    <p>老年人康复用品</p>
                </div>
                <div class="item">
                    <div class="image pic8"></div>
                    <p>老年人视听辅助用品</p>
                </div>
            </div>

        </div>
        <Footer/>
    </div>
</template>

<script>
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import {fetchMerchantStatus, fetchOrganizationsStatus, fetchServiceProviderStatus} from "@/services/api";

    export default {
        components: {
            Nav,
            Footer,
            Header
        },
        data() {
            return {
                facilitatorLoading: true,
                merchantLoading: true,
                organizationLoading: true,


                status: {
                    //服务商
                    facilitatorStatus: false, //服务商 false-没有进度；true-有进度
                    agencyStatus: "",
                    facilitatorCheckStatus: -1, //服务商CheckStatus
                    //机构
                    organizationStatus: false,//机构 false-没有进度；true-有进度
                    organizationName: "", //机构名称
                    merchantStatusCheckStatus:-1, // 机构审核状态，值表示有无，查询到了进度则表示已经入驻过，否则没有
                    //商家
                    merchantStatus: false, //商家 false-没有进度；true-有进度
                    storeName: "", // 店铺名称，这里可有可无
                    merchantCheckStatus: "" // 商家审核状态，值表示有无，查询到了进度则表示已经入驻过，否则没有


                }
            };
        },
        mounted() {
            this.facilitator();
            // localStorage.setItem("serviceJoinForm", "");
            this.getStatus();
            this.organization();
        },
        methods: {

            //服务商入驻查询，根据入驻状态显示按钮
            facilitator(){
                this.facilitatorLoading = true;
                fetchServiceProviderStatus().then((res) => {
                    if (res && res.code == 0) {

                        // 尚未入驻、入驻驳回 ： false ； 入驻审核中、入驻成功： true
                        this.status.facilitatorStatus = res.data.checkStatus == 41 || res.data.checkStatus == 40|| res.data.checkStatus == 42;
                        if (res.data.agencyStatus) {
                            this.status.facilitatorCheckStatus = res.data.checkStatus;
                            this.status.agencyStatus = res.data.agencyStatus;
                        }
                        this.facilitatorLoading = false;
                    }
                });
            },


           //机构入驻查询，根据入驻状态显示按钮
            organization(){
                    this.organizationLoading = true;
                fetchOrganizationsStatus().then(res => {
                      if (res && res.code == 0) {
                          this.status.organizationStatus = res.data.checkStatus == 41 || res.data.checkStatus == 40|| res.data.checkStatus == 42;
                        if (res.data.agencyStatus) {
                          this.status.merchantStatusCheckStatus = res.data.checkStatus;
                          this.status.organizationName = res.data.agencyStatus;
                        } else {
                          this.status.status = false;
                        }
                        this.organizationLoading = false;
                        if (res.data.agencyName) this.status.organizationName = res.data.agencyName;
                      }
                    });
            },






            // 商户入驻查询，根据入驻状态显示按钮
            getStatus() {
                this.merchantLoading = true;
                fetchMerchantStatus().then(res => {
                    if (res && res.code == 0) {
                        if (res.data.checkStatus) {
                            this.status.merchantStatus = true;
                            this.status.merchantCheckStatus = res.data.checkStatus;
                        } else {
                            this.status.merchantStatus = false;
                        }
                        this.merchantLoading = false;
                        if (res.data.storeName) this.status.storeName = res.data.storeName;
                    }
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .businessJoin-main {
        font-size: 0.14rem;
        text-align: center;

        .bg {
            height: 4rem;
            width: 100%;
            background-image: url("./../../assets/images/banner_shrz.png");
            background-position: center center;
            background-repeat: no-repeat;
            text-align: center;

            .top {
                color: #fff;
                font-size: 0.46rem;
                padding-top: 1.1rem;
                font-weight: bold;
            }

            .bottom {
                color: #fff;
                font-size: 0.36rem;
            }
        }

        .title {
            margin-top: 0.7rem;
            font-size: 0.4rem;
            color: #1f6bb1;
        }

        .rzlx {
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            margin: 0 auto;
            margin-bottom: 1.22rem;

            .item {
                width: 3rem;
                height: 3.5rem;
                border: 4px solid #60c7c6;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                margin-top: 0.3rem;
                border-radius: 0.07rem;

                .top {
                    margin: 0.34rem 0 0 0.1rem;
                    font-size: 0.26rem;
                    font-weight: bold;
                    color: #171717;
                    text-align: left;
                }

                .center {
                    margin: 0.1rem 0.05rem 0 0.1rem;
                    font-size: 0.16rem;
                    color: #171717;
                    text-align: left;
                    height: 1.93rem;
                }
            }
        }

        .rzlc {
            display: flex;
            justify-content: center;

            .step {
                /*border: 1px solid red;*/
                width: 3rem;
                height: 3rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                /*margin: 0 1rem;*/


                .image {
                    width: 1rem;
                    height: 1.1rem;
                    background-repeat: no-repeat;
                    background-size: 100%;
                }

                .next {
                    position: absolute;
                    padding-top: 0.3rem;

                    img {
                        position: absolute;
                        top: 0.2rem;
                        margin-top: 1rem;
                    }
                }

                .step1 {
                    background-image: url("./../../assets/images/tijiaoruzhuziliao_icon_fwsrz.png");
                }

                .step2 {
                    background-image: url("./../../assets/images/fuwushangdedaishenhe_icon_fwsrz.png");
                }

                .step3 {
                    background-image: url("./../../assets/images/fuwushangwanshanxinxi_icon_fwsrz.png");
                }

                .step4 {
                    background-image: url("./../../assets/images/fabufuwu_icon_fwsrz.png");
                }

                /*.require {*/
                /* border: 1px solid red;*/
                /*    width: 100%;*/
                /*    text-align: left;*/
                /*    p{*/
                /*        margin-left: 0.5rem;*/
                /*        font-size: 0.16rem;*/
                /*        color: #737373;*/
                /*        margin-bottom: 0*/
                /*    }*/
                /*}*/

                p {
                    font-size: 0.16rem;
                    color: #737373;
                    margin-bottom: 0;
                }

                p.first {
                    font-size: 0.2rem;
                    color: #60c7c6;
                    margin-bottom: 0.05rem;
                }
            }
        }


        .container {
            display: flex;
            flex-wrap: wrap;
            width: 1504px;
            margin: 0 auto;
            margin-bottom: 1.22rem;

            .item {
                display: flex;
                flex-direction: column;
                margin: 0 68px;
                margin-top: 0.6rem;

                p {
                    margin-top: 0.34rem;
                    margin-bottom: 0;
                    font-size: 0.26rem;
                    color: #171717;
                }

                .image {
                    width: 240px;
                    height: 180px;
                    background-repeat: no-repeat;
                }

                .pic1 {
                    background-image: url("./../../assets/images/richangshenghuoyongpin_shrz.png");
                }

                .pic2 {
                    background-image: url("./../../assets/images/huliyongpin_shrz.png");
                }

                .pic3 {
                    background-image: url("./../../assets/images/yangshengbaojianpin_shrz.png");
                }

                .pic4 {
                    background-image: url("./../../assets/images/yuleyongpin_shrz.png");
                }

                .pic5 {
                    background-image: url("./../../assets/images/lvyouzhuanbeiyongpin_shrz.png");
                }

                .pic6 {
                    background-image: url("./../../assets/images/chuxingdaibuyongpin_shrz.png");
                }

                .pic7 {
                    background-image: url("./../../assets/images/kangfuyongpin_shrz.png");
                }

                .pic8 {
                    background-image: url("./../../assets/images/shitingfuzhuyongpin_shrz.png");
                }
            }
        }
    }
</style>
























































































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Button, Form, Input, message, Radio, Select, Steps, Table,} from "ant-design-vue";
import {
    comfrimService,
    ifExtendStart,
    openService2,
    serviceConfirmExtend,
    serviceIfExtendConfirm,
    serviceStartExtend,
} from "@/services/api";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import ServiceProcessSteps from "./classServicesteps.vue";
import {Mutation, State} from "vuex-class";
import {baseUrl, request} from "@/services/http";
import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
import MyStepBar from "@/components/Personal/MyStepBar.vue";

Vue.use(Steps);
Vue.use(Table);
Vue.use(Form);
Vue.use(Radio);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);

@Component({
    components: {
        PersonalHeader,
        ServiceProcessSteps,
        Breadcrumb,
        MyStepBar
    },
})
export default class extends Vue {
    @Mutation SET_CLASSSERVICETWOSTEP;
    @Mutation SET_CLASSSERVICEDETAIL;

    created() {
        const str1: any = localStorage.getItem("CLASSSERVICETWOSTEP");
        this.SET_CLASSSERVICETWOSTEP(JSON.parse(str1), true);
        const str2: any = localStorage.getItem("CLASSSERVICEDETAIL");
        this.SET_CLASSSERVICEDETAIL(JSON.parse(str2), true);
        this.Type7relationId = JSON.parse(str2).relationId;
        this.Type7result = JSON.parse(str2).ServiceResult;
        this.getserviceTypeForNextStep();

        // localStorage.removeItem("CLASSSERVICETWOSTEP");
        // localStorage.removeItem("CLASSSERVICEDETAIL");
    }

    //面包屑
    breadcrumb = [
        {
            name: "个人中心",
            path: "/personal/myHomePage",
            significance: 0

        },
        {
            name: "我的服务",
            path: "/personal/myService",
            significance: 0
        },
        {
            name: "服务详情",
            path: "/personal/classServiceOne",
            significance: 1
        },
    ]
    stepList = [
        {
            name: "等待开启服务",
            time: "",
            img: require('@/assets/images/homeImg/flow-1.png'),
        },
        {
            name: "服务开启中",
            time: "",
            img: require('@/assets/images/homeImg/flow-3.png'),
        },
        {
            name: "服务开启完成",
            time: "",
            img: require('@/assets/images/homeImg/flow-4.png'),
        }
    ];

    curStatusStep = 0;
    relationId = "";
    refundId: any;
    delayIsShow1 = false;
    delayIsShow2 = false;
    extendOpenService = false;
    affirmOpenService = false;
    url_result = "";


    Type1ForNextStep = false;//上传文件字符串结果
    Type2ForNextStep = false;//上传文件json结果
    Type3ForNextStep = false;//跳转网页
    Type4ForNextStep = false;//跳转SDK组件
    Type5ForNextStep = false;//枣庄学院短信诈骗判断
    Type6ForNextStep = false;//跳转网页得到结果
    Type7ForNextStep = false;//跳转网页得到结果
    showPage = false;//跳转网页得到结果 平安
    Type7relationId = "";
    Type7result = "";

    openService = false;
    serveOver = false;

    openServiceEvent(openService: boolean, index: number) {
        openService2({
            relationId: this.relationId,
        }).then((res) => {
            if (res.code === 0) {
                this.$router.push({
                    path: "/personal/classOpenServiceOne1",
                    query: {
                        relationId: this.relationId,
                    },
                });
            } else {
                message.error("开启服务失败，请稍后重试");
            }
        });
    }

    getserviceTypeForNextStep() {
        request(`${baseUrl}/api/v1/api-services/zaixian/serviceType_forNextStep`, {
            method: "get",
            params: {
                relationId: this.Type7relationId,
            },
        }).then((res: any) => {
            if (res.data.resultInfo != 12) {
                this.showPage = true;
            } else {
                request(`${baseUrl}/api/v1/api-services/zaixian/getPolicyNobyRelationID`, {
                    method: "get",
                    params: {
                        relationId: this.Type7relationId,
                    },
                }).then((res: any) => {
                    let policyNo = encodeURIComponent(res.data.resultInfo);
                    window.location.href = "http://mcp-health.pingan.com.cn/gateway/mcp/outChannel/downloadPolicy.do?c=FYTL&policyNo=" + policyNo;
                })
            }


            switch (parseInt(res.data.resultInfo)) {
                case 1:
                    this.Type1ForNextStep = true;
                    break;
                case 2:
                    this.Type2ForNextStep = true;
                    break;
                case 3:
                    this.Type3ForNextStep = true;
                    break;
                case 4:
                    this.Type4ForNextStep = true;
                    break;
                case 5:
                    this.Type5ForNextStep = true;
                    break;
                case 6:
                    this.Type6ForNextStep = true;
                    break;
                case 7:
                    this.Type7ForNextStep = true;
                    break;

            }
        });
    }

    getResultType7() {
        let relationId = this.relationId;
        let url3 = this.Type7result;

        let url1 = url3.split("@")[0];//输入网页
        let url2 = url3.split("@")[1];//结果网页
        this.url_result = url2 + relationId
        let temp_window: any = window.open('_blank');
        temp_window.location = this.url_result;
    }

    serviceConfirm() {
        comfrimService({
            data: {
                relationId: this.relationId,
                confirmType: 1,
            },
        }).then(({data, code, msg}: any) => {
            if (code == 0) {
                message.success(msg);
                this.$router.push("/personal/myService");
            } else {
                message.error(msg);
            }
        });
    }

    @State classServiceTwoStep;

    @Watch("classServiceTwoStep", {immediate: true, deep: true})
    getCurStatus(newVal, oldVal) {
        if (newVal.step1) {
            this.curStatus.step1 = true;
            this.curStatusStep = 0;
        } else if (newVal.step2) {
            this.curStatus.step2 = true;
            this.curStatusStep = 1;
        } else if (newVal.step3) {
            this.curStatus.step3 = true;
            this.curStatusStep = 2;
        } else if (newVal.step4) {
            this.curStatus.step4 = true;
            this.curStatusStep = 3;
        }
    }

    ServiceResult = "";
    @State classServieDetail;

    @Watch("classServieDetail", {immediate: true, deep: true})
    getdata(newVal, oldVal) {
        this.refundId = newVal.refundId;
        this.relationId = newVal.relationId;
        this.ServiceResult = newVal.ServiceResult;
        this.stepList[0].time = newVal.waitForServiceTime;
        this.stepList[1].time = newVal.servingTime;
        this.stepList[2].time = newVal.serviceFinishTime;
        this.data = [
            {
                key: 1,
                service: [newVal.serviceName, newVal.pic],
                serviceStyle: newVal.serviceStyle,
                // serviceNum: newVal.serviceOrderId,
                price: newVal.price,
                tips: newVal.serviceFee,
            },
        ];
        if (this.curStatus.step1) {
            ifExtendStart({
                relationId: this.relationId,
            }).then((res) => {
                if (res["code"] == 0) {
                    this.delayIsShow1 = res.data.ifShow;
                    this.newEndTime = res.data.endTime;
                }
            });
        }
        if (this.curStatus.step2) {
            serviceIfExtendConfirm({
                relationId: this.relationId,
            }).then((res) => {
                if (res["code"] == 0) {
                    this.delayIsShow2 = res.data.ifShow;
                    this.newEndTime = res.data.endTime;
                }
            });
        }
    }


    curStatus = {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
    };
    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 9},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 15},
        },
    };

    columns = [
        {
            title: "服务",
            key: "service",
            dataIndex: "service",
            align: "center",
            width: "4.06rem",
            slots: {title: "service"},
            scopedSlots: {customRender: "service"},
        },
        {
            title: "服务方式",
            key: "serviceStyle",
            dataIndex: "serviceStyle",
            align: "center",
            width: "1.91rem",
        },
        // {
        //   title: "服务编号",
        //   key: "serviceNum",
        //   dataIndex: "serviceNum",
        //   align: "center",
        //   width: "1.96rem"
        // },
        {
            title: "服务价格",
            key: "price",
            dataIndex: "price",
            align: "center",
            width: "1.57rem",
        },
        {
            title: "服务费用",
            key: "tips",
            dataIndex: "tips",
            align: "center",
            width: "1.57rem",
        },
    ];
    data = [
        {
            key: 1,
            service: ["", ""],
            serviceStyle: "",
            // serviceNum: "",
            price: "",
            tips: "",
        },
    ];
    // ifExtendStart() {
    //   ifExtendStart({
    //     relationId: this.relationId
    //   }).then(res => {
    //     if (res["code"] == 0) {
    //       this.delayIsShow = res.data.ifShow;
    //       this.newEndTime = res.data.endTime;
    //     }
    //   });
    // }
    applyReturnMoney() {
        this.$router.push({
            path: "/personal/returnService1",
            query: {
                relationId: this.relationId,
            },
        });
    }

    newEndTime = "";

    startDelayTime1() {
        serviceStartExtend({relationId: this.relationId}).then((res) => {
            if (res.code == 0) {
                message.success("申请延长开启服务时间成功");
                this.$router.push("/personal/myService");
            } else {
                message.error("申请延长开启服务时间失败");
            }
        });
    }

    startDelayTime() {
        serviceConfirmExtend({relationId: this.relationId}).then((res) => {
            if (res.code == 0) {
                message.success("申请延长确认服务时间成功");
                this.$router.push("/personal/myService");
            } else {
                message.error("申请延长服务时间失败");
            }
        });

    }

    lookReturnMoney() {
        this.$router.push({
            path: "/personal/returnService2",
            query: {
                returnId: this.refundId,
                relationId: this.relationId,
            },
        });
    }
}

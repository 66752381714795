<template>
  <div>
    <Header style="margin: 0"/>
    <div class="schedule-main">
      <div class="schedule-top">
        <div class="rz-type">入驻进度查询</div>
        <div class="rz-hint">
          <div class="p1">Welcome</div>
          <div class="p2">欢迎加入服务商大家庭</div>
          <div class="p3">芳园天伦欢迎一切正直的、拥有优质品质的服务商成为合作伙伴</div>
        </div>
      </div>
      <div class="schedule-center">
        <div class="schedule-state">
          <div class="schedule-state-details" v-if="checkStatus == 40">
            <img src="../../assets/images/homeImg/sh-1.png"/>
            <span style="color: #4BB0FF">正在审核中...</span>
          </div>
          <div class="schedule-state-details" v-if="checkStatus == 41">
            <img src="../../assets/images/homeImg/sh-2.png"/>
            <span style="color:#39BEB8">审核通过</span>
          </div>
          <div class="schedule-state-details" v-if="checkStatus == 42">
            <img src="../../assets/images/homeImg/sh-3.png"/>
            <span>审核未通过！</span>
          </div>
        </div>

        <div class="auditStatus" v-if="status && agencyName">
          <span v-if="checkStatus == 40">感谢您在平台申请服务商入驻，审核会在1-2个工作日完成。</span>
          <span v-if="checkStatus == 41">恭喜您服务商入驻成功，马上去服务商中心完善服务商信息吧！</span>
          <span v-if="checkStatus == 42">非常抱歉您的服务商入驻未成功，马上去修改服务商入驻信息再次申请吧。</span>
        </div>
        <div class="reasonForRejection">
          <div class="reason">
            <div class="p1" v-if="status && agencyName">
              服务商名称：{{ agencyName }}
            </div>
          </div>
          <div class="reject" v-if="checkStatus ==42">
            <div class="p2" style="min-width: 0.83rem;">驳回原因：</div>
            <div class="p2" style="max-height:1.6rem;overflow: auto;">
              {{checkInfo}}
            </div>
          </div>
        </div>
        <div class="rejectButton">
          <div class="reject-amend" v-if="checkStatus == 40" @click="$router.push('/personal/myHomePage')">进入个人中心</div>
          <div class="reject-amend" v-if="checkStatus == 41" @click="goserverCenter">进入服务商中心</div>
          <div class="reject-amend" v-if="checkStatus == 42" @click="jumpChage">修改入驻信息</div>
          <div class="reject-home"  @click="$router.push('/')">回到首页</div>
        </div>
      </div>


    </div>
    <Footer/>
  </div>
</template>
<script>
import Vue from "vue";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { message } from "ant-design-vue";
import {
  fetchServiceProviderStatus,
  fetchGetOrganizationsJoinInfo,
} from "@/services/api";

import { serviceProviderUrl } from "@/services/http";
export default {
  components: {
    Footer,
    Header,
  },
  methods: {
    goserverCenter() {
      window.open(serviceProviderUrl, "_blank");
    },
    jumpChage() {
      // localStorage.removeItem("merchantJoinForm");
      fetchGetOrganizationsJoinInfo().then((res) => {

        if (res.code == 0) {
          localStorage.setItem("serviceJoinForm", JSON.stringify(res.serviceJoinForm)
          );
          this.$router.push("/serviceJoinStep1");
        } else {
          message.error(res.msg);
        }
      });
    },
  },
  data() {
    return {
      status: 0,
      agencyStatus: "",
      agencyName: "",
      checkStatus: -1,
      checkInfo: "",
    };
  },
  mounted: function () {
    fetchServiceProviderStatus().then((res) => {
      this.status = 0;
      if (res.code === 0) {
        if (res.data.agencyName && res.data.agencyStatus) {
          this.agencyName = res.data.agencyName;
          this.checkStatus = res.data.checkStatus;
          this.checkInfo = res.data.checkInfo;
          switch (this.checkStatus) {
            case "40": {
              this.agencyStatus = "审核中";
              break;
            }
            case "41": {
              this.agencyStatus = "审核通过";
              break;
            }
            case "42": {
              this.agencyStatus = "审核驳回";
              break;
            }
          }
          this.agencyStatus = res.data.agencyStatus;
          this.status = 1;
        }
      } else {
        message.error(res.msg);
      }
    });
  },
};
</script>

<style lang="less" scoped>
  .title-top {
    width: 100%;
    margin: 0 auto;
    margin-top: 0.8rem;

    .title-span {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      /*border: 1px solid red;*/

      .p1 {
        font-size: 0.18rem;
        font-weight: 400;
        color: #454F63;
        opacity: 1;
        padding-right: 0.1rem;
      }

      .p2 {
        font-size: 0.18rem;
        font-weight: 400;
        color: #454F63;
        opacity: 1;
      }
    }

    .title-img {
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .title-img-bj {
        margin-top: 0.1rem;
        width: 18%;
        height: 0.15rem;
        background-image: url("../../assets/images/homeImg/90sheji_linggan_13332287@2x.png");
        background-size: 100% 100%;
        background-position: center center;
      }
    }
  }

  .schedule-main {
    width: 100%;
    height: 100%;

    .schedule-top {
      height: 6rem;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-image: url("../../assets/images/homeImg/fwsrz.png");
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .rz-type {
        position: absolute;
        left: 4.4rem;
        top: 0.5rem;
        font-size: 0.24rem;
        color: #fff;
        font-weight: bold;
        opacity: 1;
      }
      .rz-hint {
        width: 7.76rem;
        height: 2.88rem;
        background: rgba(0, 0, 0, 0.3);
        text-align: center;

        .p1 {
          margin-top: 0.4rem;
        }

        .p2 {
          margin-top: 0.1rem;
        }

        .p1, .p2 {
          font-size: 0.36rem;
          font-family: Arial;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 1;
          /*border: 1px solid red;*/
        }

        .p3 {
          margin-top: 0.1rem;
          font-size: 0.22rem;
          font-family: DFPYuanW3-GB;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 1;
          /*border: 1px solid red;*/
        }


      }
    }

    .schedule-center {
      width: 100%;
      height: 5.13rem;
      background: #FFFFFF;
      opacity: 1;
      border: 1px solid #fff;

      .schedule-state {
        height: 1.13rem;
        width: 100%;
        margin-top: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .schedule-state-details {
          width: 4.68rem;
          height: 1.13rem;
          background: #FFFFFF;
          box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.06);
          opacity: 1;
          border-radius: 0.24rem;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 0.32rem;
            height: 0.3218rem;
            margin-right: 0.2rem;
          }

          span {
            font-size: 0.24rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FF874D;
            opacity: 1;
          }

        }
      }

      .auditStatus {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        span {
          font-size: 0.16rem;
          font-weight: 400;
          color: #454F63;
          opacity: 1;
        }
      }

      .reasonForRejection {
        width: 9rem;
        /*border: 1px solid red;*/
        padding-top: 0.2rem;
        margin: 0 auto;

        .reason {
          margin: 0 auto;
          /*border: 1px solid red;*/
          text-align: center;
          margin-bottom: 0.1rem;

          .p1 {
            font-size: 0.16rem;
            font-weight: 400;
            color: #454F63;
            opacity: 1;
          }
        }

        .reject {
          margin: 0 auto;
          /*border: 1px solid red;*/
          /*text-align: center;*/
          display: flex;
          justify-content: center;

          .p2 {
            font-size: 0.16rem;
            font-weight: 400;
            color: #FF874D;
            opacity: 1;
          }
        }
      }

      .rejectButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.6rem;

        .reject-amend {
          width: 1.36rem;
          height: 0.4rem;
          border: 1px solid #78849E;
          opacity: 1;
          border-radius: 0.2rem;
          font-size: 0.14rem;
          color: #454F63;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 0.38rem;
        }

        .reject-amend:hover {
          border: 1px solid #39BEB8;
          color: #39BEB8;
        }

        .reject-home {
          width: 1.36rem;
          height: 0.4rem;
          background: #39BEB8;
          opacity: 1;
          border-radius: 0.2rem;
          font-size: 0.14rem;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .reject-home:hover{
          opacity: 0.8;
        }
      }

    }
  }
</style>

import axios from "axios";
import router from "@/router/index";
import {message} from "ant-design-vue";

const SUCCESS_CODE = 0;
export {SUCCESS_CODE};

// 环境参数
const env = process.env.NODE_ENV

//平台访问地址
// export const baseUrl = "http://admin.test.fytlun.com"; //跨域代理api，正式环境请更改成 http://admin.fytlun.com/
let getBaseUrl = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://admin.test.fytlun.com";
        case "production":
            return "https://admin.fytlun.com"
    }
}
export const baseUrl = getBaseUrl()

//商城访问地址
// export const baseUrl2 = "http://api.test.mall.fytlun.com";
let getBaseUrl2 = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://api.test.mall.fytlun.com";
        case "production":
            return "https://apimall.fytlun.com"
    }
}
export const baseUrl2 = getBaseUrl2();

// 跳转地址
// export const serviceProviderUrl = "http://fwsadmin.test.fytlun.com/"; // 服务商后台
let getServiceProviderUrl = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://fwsadmin.test.fytlun.com/";
        case "production":
            return "https://fwsadmin.fytlun.com/"
    }
}
export const serviceProviderUrl = getServiceProviderUrl()

// export const MerchantUrl = "http://merchant.test.fytlun.com/"; // 商户后台
let getMerchantUrl = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://merchant.test.fytlun.com/";
        case "production":
            return "https://merchant.fytlun.com/"
    }
}
export const MerchantUrl = getMerchantUrl()

// export const facilitatorUrl = "http://orgadmin.test.fytlun.com/"; // 机构后台
let getFacilitatorUrl = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://orgadmin.test.fytlun.com/";
        case "production":
            return "https://orgadmin.fytlun.com/"
    }
}
export const facilitatorUrl = getFacilitatorUrl()

// export const  mallUrl  = "http://www.test.fytlun.com"; //商城支付
let getMallUrl = () => {
    switch (env) {
        case "development":
        case "test":
            return "http://www.test.fytlun.com";
        case "production":
            return "https://www.fytlun.com"
    }
}
export const mallUrl = getMallUrl()


const instance = axios.create({
    timeout: 3000000,
});

// 现在这里配置一个随动的token，等登陆做好了就能在调用时获得到token
instance.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

//这里我简单列出一些常见的http状态码信息，可以自己去调整配置
let httpCode: { [key: number]: string } = {
    400: "请求参数错误",
    401: "权限不足, 请重新登录",
    403: "服务器拒绝本次访问",
    404: "请求资源未找到",
    500: "内部服务器错误",
    501: "服务器不支持该请求中使用的方法",
    502: "网关错误",
    504: "网关超时",
};

// 添加请求拦截器
instance.interceptors.request.use(
    (config) => {
        //每次访问后跟一个时间戳
        if (config.method == 'post') {
            // config.data = config.data
        } else if (config.method == 'get') {
            config.params = {
                t: `${new Date().getTime()}`,
                ...config.params
            }
        }
        //每个方法的header中添加token
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            const token = JSON.parse(userInfo).token;
            config.headers.token = token;
            return config;
        } else {
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 添加相应拦截器
instance.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            if (response.config.url != `${baseUrl}/api/v1/token`) {
                if (response.data.code === 401) {
                    // message.error("登录信息已过期，请重新登录");
                    router.push("/login");
                    // localStorage.removeItem("userInfo");
                }
            }
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.statusText);
        }
    },
    (error) => {
        if (error.response) {
            // 根据请求失败的http状态码去给用户相应的提示
            let tips = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message;
            message.error(tips);
            if (error.response.status === 401) {
                // 如果是401，直接跳到登陆页
                router.push("/login");
            }
            return Promise.reject(error);
        } else {
            // message.error("请求超时， 请刷新重试。");
            // router.push("/home");

            return Promise.reject(new Error("请求超时, 请刷新重试。"));
        }
    }
);

export interface optionParams {
    method?: any;
    data?: any;
    params?: any;
    headers?: any;
    onUploadProgress?: any;
}

export const request = (url: string, option: optionParams = {method: "get"}) => {
    const {method = "get", data, params} = option;
    const conf = {method, url, ...option}
    return new Promise((resolve, reject) => {
        instance(conf).then((response) => {
            resolve(response);
        })
            .catch((error) => {
                reject(error);
            });
    });
};

//商城访问路径
export interface responseC {
    data: any;
    code: number;
    msg: string;
}

export const http = {
    post: (url: string, data: object, params?: { params?: object }, header?: String) => {
        let connUrl = baseUrl2
        if (header && header === "baseUrl2") {
            connUrl = baseUrl;
        }
        return new Promise((resolve, reject) => {
            instance.post(url.includes("http://") ? url : connUrl + url + `?t=${new Date().getTime()}`, data, params)
                .then((res) => {
                    const resData: any = res
                    const a: responseC = resData;
                    resolve(a);
                })
                .catch((error) => {
                    reject(error.response.data);
                    //doSomeThing
                });
        });
    },
    get: (url: string, params?: { params?: object }, header?: String) => {
        let connUrl = baseUrl2
        if (header && header === "baseUrl2") {
            connUrl = baseUrl;
        }
        return new Promise((resolve, reject) => {
            instance.get(url.includes("http://") ? url : connUrl + url + `?t=${new Date().getTime()}`, params)
                .then((res) => {
                    const resData: any = res
                    const a: responseC = resData;
                    resolve(a);
                })
                .catch((error) => {
                    reject(error);
                    //doSomeThing
                });
        });
    },
};


















import {Component, Vue} from "vue-property-decorator";

@Component({
    props: {
        beNullList: Object,
    },
})
export default class beNull extends Vue {


}






















































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Icon, message} from "ant-design-vue";
import serviceOrderDetail from "@/components/Personal/serviceOrderForm/serviceOrderDetail.vue";
import {baseUrl} from "../../../services/http";
import {confirmationOrder, deletingServiceOrder, dictionariesWithDesc, reasonOfCancellation} from "@/services/api";

import {Mutation, State} from "vuex-class";

Vue.use(Icon);
@Component({
    components: {
        serviceOrderDetail
    },
    props: {
        location: String,
        // allcontent: Object
    }
})
export default class MyGoodsItem extends Vue {
    baseUrl = baseUrl;
    serveComplete = false;
    @Prop() private status!: String;
    @Prop() private statusDic!: Number;
    @Prop() private allcontent!: Object;
    @Prop() private itemIndex: Number;
    @Prop() public services!: any;
    @Mutation SET_CURDETAILSTATUS;
    @State cancelReason;

    selectChangetsuahValue: any = null;
    cancelServiceList = {};
    serveCancel = false;
    recycleBin = false;
    shopAddressPlaceholder = "";
    province: any = '';
    city: any = '';
    area: any = '';
    street: any = '';
    allowSubmit: any = true;

    //订单详情
    lookDetail(orderId: any) {
        window.open("#/personal/serviceOrderDetail",);
        this.SET_CURDETAILSTATUS(orderId);
    }


    mounted() {
        this.selectChangetsuahValue = undefined;
        this.getCancelServiceList()
    }

    //取消订单
    getCancelServiceList() {
        dictionariesWithDesc(89).then((res) => {
            if (res && res.code == 0) {
                this.cancelServiceList = res.values;
            }
        })
    }

    //删除订单
    deleteOrder(value) {
        if (this.allcontent["orderStatus"] == 766 || this.allcontent["orderStatus"] == 765) {
            deletingServiceOrder([value]).then((res) => {
                if ((res as any).code === 0) {
                    message.success("删除成功,已移到商品订单回收站");
                    this.$emit("againGetList");
                } else {
                    message.error((res as any).msg);
                }
                this.recycleBin = false
            });
        } else {
            message.error("只有已完成和已取消的订单能够放入回收站");
        }

    }


    //取消订单
    selectChange(value) {
        if (this.allowSubmit) {
            this.allowSubmit = false;
            if (this.selectChangetsuahValue === undefined) {
                message.error("请选择取消订单理由");
                return
            } else {
                reasonOfCancellation({
                    orderId: value,
                    reason: this.selectChangetsuahValue
                }).then(res => {
                    if (res.code === 0) {
                        this.allowSubmit = false
                        message.success("取消订单成功");
                        this.$emit("againGetList");
                        this.serveCancel = false
                    } else {
                        message.error(res.msg);
                        this.allowSubmit = true
                    }
                });
                this.serveComplete = false;
                this.selectChangetsuahValue = ''
            }
        }

    }

    toChat() {
        alert('该功能暂未开通')
    }


    //确认服务订购单
    serviceOrderList(orderId) {
        confirmationOrder({
            orderId: orderId,
        }).then((res) => {
            if (res.code === 0) {
                message.success("确认成功");
                this.$emit("againGetList");
            } else {
                message.error("确认失败");
            }
        });
        this.serveComplete = false;
    }


    //进行评价
    evaluate(value) {
        this.$router.push({
            path: "/personal/serviceOrdersJudge",
            query: {
                orderId: value
            }
        });
    }

}



































    import {Component, Vue} from "vue-property-decorator";
    import {Button, Table} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import Breadcrumb from "@/components/Personal/Breadcrumb.vue";
    import reservationListDetailInfo from "@/components/Personal/reservationList/reservationListDetailInfo.vue";
    import MyStepBar from "@/components/Personal/MyStepBar.vue";
    // getReservationDetail
    import {diagnosisTreatmentDetails} from "@/services/api";

    Vue.use(Table);
Vue.use(Button);

@Component({
  components: {
    PersonalHeader,
    Breadcrumb,
    reservationListDetailInfo,
    MyStepBar
  }
})
export default class extends Vue {
  // 4是取消
  currentStatus = 0; // 根据状态动态调整currentStatus的值
  content = {};
  //面包屑
  breadcrumb = [
    {
      name: "个人中心",
      path: "/personal/myHomePage",
      significance: 0

    },
    {
      name: "诊疗预约单",
      path: "/personal/reservationList",
      significance: 0
    },
    {
      name: "诊疗预约单详情",
      path: "/personal/reservationListDetail",
      significance: 1
    },
  ]
  stepData = [
    {
      name: "提交预约单",
      time: "",
      img:require('@/assets/images/homeImg/flow-1.png'),
    },
    {
      name: "服务商接受预约单",
      time: "",
      img:require('@/assets/images/homeImg/flow-6.png'),
    },
    {
      name: "预约单完成",
      time: "",
      img:require('@/assets/images/homeImg/flow-4.png'),
    },
    {
      name: "评价",
      time: "",
      img:require('@/assets/images/homeImg/flow-5.png'),
    }
  ];
  columns = [
    {
      title: "预约详情",
      dataIndex: "serviceName",
      key: "serviceName",
      align: "center",
      scopedSlots: { customRender: "serviceName" }
    },
    {
      title: "服务商",
      dataIndex: "orgName",
      key: "orgName",
      align: "center"
    },
    {
      title: "服务商地址",
      dataIndex: "orgAddressProvince",
      key: "orgAddressProvince",
      align: "center",
      scopedSlots: { customRender: "orgAddressProvince" }
    },
    {
      title: "联系电话",
      dataIndex: "orgTelephone",
      key: "orgTelephone",
      align: "center"
    },
  ];

  tableData = [];
  created() {
    let reservationId = this.$route.query.reservationId;
    this.getReservationDetail(reservationId);
  }
  getReservationDetail(reservationId) {
    diagnosisTreatmentDetails({
      reservationId: reservationId
    }).then(res => {
      let data = res.info;
      this.content = data;
      (this as any).tableData.push(data);
      switch (data.orderStatus) {
        case 239:
          this.currentStatus = 1;
          break;
        case 240:
          this.currentStatus = 2;
          break;
        case 241:
          this.currentStatus = 3;
          if (data.ifEvaluation) this.currentStatus = 4;
          break;
        case 243:
          this.currentStatus = 5;
          break;
        //  239-提交预约单 240-服务商与用户线下已沟通 241-服务结束 243-已取消
      }
      this.stepData[0].time = data.statuses[0].createTime || "";
      this.stepData[1].time = data.statuses[1].createTime || "";
      this.stepData[2].time = data.statuses[2].createTime || "";
      this.stepData[3].time = data.statuses[3].createTime || "";
    });
  }
}

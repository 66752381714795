



















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import OpenServiceItem from "@/components/Personal/MyService/OpenServiceItem.vue";
import {baseUrl, mallUrl} from "@/services/http";
import aSpin from "@/components/aSpin.vue";

@Component({
        components: {
            PersonalHeader,
            OpenServiceItem,
            aSpin
        },
    })
    export default class extends Vue {
        @Prop() topLis: any;
        @Prop() data: any;
        @Prop() pagination: any;
        currentRelationId = "";
        currentServiceType = 0;
        currentTab = 1;
        serviceStatus = 0;
        baseUrl = baseUrl;
        mallUrl = mallUrl;
        loading = true;

        @Watch("data", {immediate: true, deep: true})
        getData(newVal) {
            this.loading = false
        }

        mounted() {
            // this.getServiceList();
        }

        // 点击查看退款记录
        handleRecordCheck(id) {
            this.$emit("handleRecordCheck", id);
        }

        defaultValue = "";

        // 退款状态字典
        formatStatusDic(value: any) {
            // 269,退款待处理
            // 270,退款成功
            // 271,退款关闭
            // 725,拒绝退款
            // 726,待买家发货
            // 727,待商家收货
            // 728,拒绝收货

            let descrption = "";
            switch (value) {
                case 269:
                    return '退款待处理'
                case 270:
                    return '退款成功'
                case 271:
                    return '退款关闭'
                case 725:
                    return '已拒绝退款'
                case 726:
                    return '待买家发货'
                case 727:
                    return '待商家收货'
                case 728:
                    return '商家拒绝收货'
            }
            return descrption;
        }

        emptyTextBySearch = false;

        onChangePageSize(page, pageSize) {
            window.scrollTo({top: 0});
            this.pagination.pageIndex = page;
            this.$emit("getReturnRecord");
        }

        cc(value) {
            open(mallUrl + '/#/mall/detail/' + value)
        }

        aa(value) {
            open(mallUrl + '/#/mall/shop/' + value)
        }


    }

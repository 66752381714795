

    import {Component, Prop, Vue} from "vue-property-decorator";
    import spikePart1 from "./spikePart1.vue";

    @Component({
  components: { spikePart1 },
})
export default class spikeItem extends Vue {
  @Prop() selectItem: any;
  @Prop() list: any;
  @Prop() panicBuyingSessions: any;
  @Prop() panicBuyingName: any;
  @Prop() endTime: any;
  mounted() {
    this.getTime();
    setInterval(() => {
      this.getTime();
    }, 1000);
  }

  bufferTime = 0;
  created() {}

  getTime() {
    let [he, me, se] = this.endTime.split(":");
    let now = new Date();
    const a = +he * 3600 + +me * 60 + +se;
    const b = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    this.bufferTime = Math.abs(a - b);

    let m:any = Math.floor(this.bufferTime / 60);
    this.time = [
      Math.floor(m / 60) < 10 ? "0" + Math.floor(m / 60) : Math.floor(m / 60),
      m % 60 < 10 ? "0" + (m % 60) : m % 60,
      this.bufferTime % 60 < 10
        ? "0" + (this.bufferTime % 60)
        : this.bufferTime % 60,
    ];
  }

  time:any = [];
}

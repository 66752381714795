




















































































































import {Component, Vue} from "vue-property-decorator";
import {Button, Form, Input, Modal, Select} from "ant-design-vue";
import {citySearchInputList,} from "@/services/api";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Modal);

@Component({
    components: {
        Nav,
        Footer,
        Header,
    },
})
export default class extends Vue {
    batchInto: boolean = false;
    proviceList: any = [];
    cityList: any = [];
    defaultCity: String = "";
    searchList: any = [];
    allAreaData: any = window.sessionStorage.getItem("allAreaData");
    testSelect = [
        {label: "select1", value: 1},
        {label: "select2", value: 2},
        {label: "select3", value: 3},
    ];
    lettersOnShow: any = [];

    adjustTop(letter) {
        this.lettersOnShow = letter ? [letter] : this.letters;

        // const el: any = document.getElementById(letter);
        // const top = el.offsetTop - document.documentElement.scrollTop;
        // let fontSize: any = document.getElementsByTagName("html");
        // fontSize = fontSize[0].style.fontSize.split("px")[0];
        // window.scrollTo({ top: top - +fontSize * 2.2 });
    }


    hotCity = [
        {
            cityId: "0",
            cityName: "全国",
        },
        {
            cityId: "1",
            cityName: "北京",
        },
        {
            cityId: "73",
            cityName: "上海",
        },
        {
            cityId: "200",
            cityName: "广州",
        },
        {
            cityId: "202",
            cityName: "深圳",
        },
        {
            cityId: "2",
            cityName: "天津",
        },
        {
            cityId: "309",
            cityName: "西安",
        },
        {
            cityId: "255",
            cityName: "重庆",
        },
        {
            cityId: "87",
            cityName: "杭州",
        },
        {
            cityId: "74",
            cityName: "南京",
        },
        {
            cityId: "169",
            cityName: "武汉",
        },
        {
            cityId: "256",
            cityName: "成都",
        },
    ];

    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "",
    //     "武汉",
    //     "成都"
    //   ];

    letters = [];

    cityName = {
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: [],
    };

    onSearch() {
    }

    buffer: string[] = [];

    selectCity(item) {
        this.buffer = item.split("$");
        this.handleOk();
    }

    selectCity2(item) {
        this.buffer = item.split("$");
    }



    mounted() {
        let res: any = localStorage.getItem("citypicK");
        res = JSON.parse(res);
        Object.entries(this.cityName).forEach((el, key) => {
            res.data.forEach((ele) => {
                if (ele.firstLetter === el[0]) {
                    (this as any).letters.push(ele.firstLetter);
                    this.lettersOnShow = this.letters;
                    this.cityName[ele.firstLetter] = ele.cityList;
                }
            });
        });
        this.proviceList = JSON.parse(this.allAreaData);
    }

    handleSearch(value) {
        citySearchInputList({searchInput: value}).then((res) => {
            this.searchList = res.data.cityList;


        });
    }

    handleOk() {
        if (this.searchList.length != 0|| this.buffer.length!=0) {
            const obj = {
                cityId: this.buffer[0],
                cityName: this.buffer[1],
            };
            localStorage.setItem("setCity", JSON.stringify(obj));
            if (sessionStorage.getItem("frontPage") == "MAP") {
                this.$router.push("/pensionMap");
            } else {
                // this.$router.push("/");
                // this.$router.back();
                this.$router.push("/Home");
                window.location.reload();

                const t = setTimeout(() => {
                    window.location.reload();
                    clearTimeout(t);
                }, 200);
            }
        } else {
            this.batchInto = true;
        }

    }

    handleChange(value) {
        this.cityList = this.proviceList[value].cityList;
        this.defaultCity = "";
    }
}
















    import {Component, Vue} from "vue-property-decorator";
    import {Mutation, State} from "vuex-class";

    @Component({})
// hello world
  export default class sliderBar extends Vue {
    @Mutation setScrollTop: any;
    ifTimeOut: any = null;
    ifShowSlider = false;
    stepLength = 0;
    @State sliderBarItems!: Array<any>;
    @State fixedCurTop!: any;
    mounted() {
      window.addEventListener("scroll", this.scrollEvent);
      this.ifShowSlider = this.fixedCurTop < 0;
    }
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrollEvent);
    }
    scrollTop = 0;
    scrollEvent(): void {
      // 节流
      if (!this.ifTimeOut) {
        this.ifTimeOut = setTimeout(() => {
          this.scrollTop = document.documentElement.scrollTop;
          this.setScrollTop(this.scrollTop);
          this.ifShowSlider = this.scrollTop > this.fixedCurTop;
          clearTimeout(this.ifTimeOut);
          this.ifTimeOut = null;
        }, 10);
      }
    }

    toTop() {
      const a = setInterval(() => {
        if (document.documentElement.scrollTop > this.stepLength) {
          document.documentElement.scrollTop -= this.stepLength;
          this.stepLength += 5;
        } else {
          document.documentElement.scrollTop = 0;
          this.stepLength = 0;
          clearInterval(a);
        }
      }, 5);
    }
    toCurHeight(top: number) {
      document.documentElement.scrollTop = top + 20;
    }
    getBool(indxe: number) {
      if (indxe == this.sliderBarItems.length - 1) {
        return this.scrollTop >= this.sliderBarItems[indxe].top;
      } else {
        return (
                this.scrollTop >= this.sliderBarItems[indxe].top &&
                this.scrollTop < this.sliderBarItems[indxe + 1].top
        );
      }
    }
  }

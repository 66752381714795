<template>
  <div>
    <Header style="margin: 0"/>
    <div class="organization-main">
      <div class="organization-top">
        <div class="rz-type">机构入驻</div>
        <div class="rz-hint">
          <div class="p1">Welcome</div>
          <div class="p2">欢迎加入养老机构大家庭</div>
          <div class="p3">芳园天伦欢迎一切正直的、拥有优质品质的养老机构成为合作伙伴</div>
          <div v-if="!loading" class="button">
            <div class="ServiceJoin-button" v-if="status.status"
                 @click="$router.push('/OrganizationJoinProcess')">
              <img src="../../assets/images/homeImg/query.png"/>入驻进度查询
            </div>
            <div class="ServiceJoin-button" v-if="!status.status" @click="WantMoveInto">
              <img src="../../assets/images/homeImg/enter.png"/>我要入驻
            </div>
          </div>
        </div>
      </div>
      <div class="organization-principle">
        <div class="title-top">
          <div class="title-span">
            <div class="p1">入驻原则<span style="margin-left: 0.1rem">*</span></div>
            <div class="p2">Entry principle</div>
          </div>
          <div class="title-img">
            <div class="title-img-bj"></div>
          </div>
        </div>
        <div class="principle-bjt"></div>
        <div class="principle">
          <div class="principle-content">
            <div class="p1">廉洁正直、公平 合理的合作关系</div>
            <div class="p2">互助互补、竞合共赢 的双重受益合作模式</div>
            <div class="p3">致力于长期、 战略性合作</div>
          </div>
        </div>
        <div class="principle-bjt2"></div>
      </div>
      <div class="organization-flow">
        <div class="title-top">
          <div class="title-span">
            <div class="p1">入驻流程<span style="margin-left: 0.1rem">*</span></div>
            <div class="p2">Settlement process</div>
          </div>
          <div class="title-img">
            <div class="title-img-bj"></div>
          </div>
        </div>
        <div class="flowPath">
          <div class="step-1">
            <p>1.注册芳园天伦个人用户账号；</p>
            <p>2.进入合作招商页面,选择为养老机构的入驻类型，点击“立即入驻”。</p>
            <p>3.进入养老机构入驻页面,点击“立即入驻”。</p>
          </div>
          <div class="step-2">
            <p>提交入驻资料</p>
            <p>1.确认在线入驻协议。</p>
            <p>2.填写公司信息及上传资质信息；养老机构命名。</p>
            <p>3.填写养老机构信息并选择养老机构类目；</p>
          </div>
          <div class="step-3">
            <p>等待平台审核</p>
            <p>1.平台1-3个工作日内反馈审核结果；</p>
            <p> 2.可查询入驻审核进度，及接收入驻进度短信通知。</p>
          </div>
          <div class="step-4">
            <p>等待平台审核</p>
            <p>1.登录养老机构后台，完善养老机构信息存在延伸服务，可发布服务信息。</p>
          </div>
        </div>
      </div>
      <div class="organization-classHeading">
        <div>
          <div class="title-top" >
            <div class="title-span">
              <div class="p1">热招养老机构<span style="margin-left: 0.1rem">*</span></div>
              <div class="p2">Hot call service category</div>
            </div>
            <div class="title-img">
              <div class="title-img-bj"></div>
            </div>
          </div>
          <div class="rzlm-list">
            <div class="serve" v-for="(item,index) in this.status.serviceCategory" :key="index">
              <img :src="item.img">
              <div class="pp">{{item.title}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="title-top">
            <div class="title-span">
              <div class="p1">热招服务类目<span style="margin-left: 0.1rem">*</span></div>
              <div class="p2">Hot call service category</div>
            </div>
            <div class="title-img">
              <div class="title-img-bj"></div>
            </div>
          </div>
          <div class="rzlm-list" >
            <div class="serve" v-for="(item,index) in this.status.serviceCategoryTwo" :key="index">
              <img :src="item.img">
              <div class="pp">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Footer from "@/components/Footer";
  import Header from "@/components/Header";
  import {message} from "ant-design-vue";
  import {fetchOrganizationsStatus} from "@/services/api";

  export default {
  components: {
    Footer,
    Header,
  },
  // beforeCreate() {
  //
  // },
  data() {
    return {
      loading: true,
      status: {
        status: false, // false-没有进度；true-有进度
        agencyName: "",
        agencyStatus: "",
        checkStatus: -1,
        serviceCategory:[],
        serviceCategoryTwo:[],
      },
    };
  },

  mounted() {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      this.loading = false;
      this.status.status = false;
      // this.$router.push("/login");
    }else{
      this.getStatus();
      const OrganizationSettlementResult = localStorage.getItem("OrganizationSettlementResult");
      if(!status.status&&OrganizationSettlementResult==='1'){
        localStorage.setItem("OrganizationSettlementResult",'2');
        this.$router.push('/OrganizationJoinRead')
      }
    }
    this.status.serviceCategory=[
      {img: require('@/assets/images/homeImg/reZhaoCategory/养老中心.png'), title: '养老中心'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/养老院.png'), title: '养老院'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/康复中心.png'), title: '康复中心'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/旅居基地.png'), title: '旅居基地'},
    ]
    this.status.serviceCategoryTwo=[
      {img: require('@/assets/images/homeImg/reZhaoCategory/家政服务.png'), title: '家政服务'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/生活照料.png'), title: '生活照料'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/适老化改造.png'), title: '适老化改造'},
      {img: require('@/assets/images/homeImg/reZhaoCategory/文化娱乐.png'), title: '文化娱乐'},
    ]
  },
  methods: {
    // 查询入驻状态，根据入驻状态显示按钮
    getStatus() {
      this.loading = true;
      fetchOrganizationsStatus().then((res) => {
        if (res && res.code == 0) {
          // 尚未入驻： false ； 入驻审核中、入驻成功、入驻驳回： true，表示有入驻信息
          this.status.status = res.data.checkStatus == 41 || res.data.checkStatus == 40;

          if (res.data.agencyStatus) {
            this.status.checkStatus = res.data.checkStatus;
            this.status.agencyStatus = res.data.agencyStatus;
            this.status.status = true
          }
          this.loading = false;
          if (res.data.agencyName) this.status.agencyName = res.data.agencyName;
        } else {
          message.error(res.msg);
        }
      });
    },
    //我要入驻
    WantMoveInto(){
      localStorage.setItem("OrganizationSettlementResult",'2');
      const userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        this.$store.commit("setRegisterSuccessTargetPage","/OrganizationJoinRead");
        this.$router.push("/login");
        localStorage.setItem("OrganizationSettlementResult",'1');
      } else {
        this.$router.push('/OrganizationJoinRead')
      }
    }

  },
};
</script>

<style lang="less" scoped>
  .title-top {
    width: 100%;
    margin: 0 auto;
    margin-top: 0.8rem;

    .title-span {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      /*border: 1px solid red;*/

      .p1 {
        font-size: 0.18rem;
        font-weight: 400;
        color: #454F63;
        opacity: 1;
        padding-right: 0.1rem;
      }

      .p2 {
        font-size: 0.18rem;
        font-weight: 400;
        color: #454F63;
        opacity: 1;
      }
    }

    .title-img {
      width: 100%;
      height: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .title-img-bj {
        margin-top: 0.1rem;
        width: 18%;
        height: 0.15rem;
        background-image: url("../../assets/images/homeImg/90sheji_linggan_13332287@2x.png");
        background-size: 100% 100%;
        background-position: center center;
      }
    }
  }

  .organization-main {
    width: 100%;
    height: 100%;
    min-width: 18.8rem;

    .organization-top {
      height: 6rem;
      width: 100%;
      background-position: center center;
      background-size: cover;
      background-image: url("../../assets/images/homeImg/fwsrz.png");
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .rz-type {
        position: absolute;
        left: 4.4rem;
        top: 0.5rem;
        font-size: 0.24rem;
        color: #fff;
        font-weight: bold;
        opacity: 1;
      }
      .rz-hint {
        width: 7.76rem;
        height: 2.88rem;
        background: rgba(0, 0, 0, 0.3);
        text-align: center;

        .p1 {
          margin-top: 0.4rem;
        }

        .p2 {
          margin-top: 0.1rem;
        }

        .p1, .p2 {
          font-size: 0.36rem;
          font-family: Arial;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 1;
          /*border: 1px solid red;*/
        }

        .p3 {
          margin-top: 0.1rem;
          font-size: 0.22rem;
          font-family: DFPYuanW3-GB;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 1;
          /*border: 1px solid red;*/
        }
        .button{
          height:0.5rem;
          display: flex;
          margin-top: 0.25rem;
          .ServiceJoin-button {
            margin: 0 auto;
            text-align: center;
            width: 1.35rem;
            height: 0.4rem;
            border: 1px solid #FFFFFF;
            opacity: 1;
            border-radius: 0.2rem;
            font-size: 0.14rem;
            font-family: MicrosoftYaHei;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              width: 12.54px;
              height: 12.1px;
              margin-right: 0.04rem;
            }
          }

          .ServiceJoin-button:hover {
            opacity: 0.8;
          }
        }


      }

    }

    .organization-principle {
      width: 100%;
      height: 5.08rem;
      background: #F8F8F8;
      opacity: 1;
      border: 1px solid #F8F8F8;
      position: relative;

      .principle-bjt {
        position: absolute;
        width: 1.78rem;
        height: 0.82rem;
        left: 3.2rem;
        top: 1.35rem;
        background-image: url("../../assets/images/homeImg/90sheji_linggan_2213648589@2x.png");
        background-size: cover;
        background-position: center center;
      }

      .principle-bjt2 {
        position: absolute;
        width: 1.78rem;
        height: 0.82rem;
        right: 3.2rem;
        top: 1.35rem;
        background-image: url("../../assets/images/homeImg/90sheji_linggan_13648585@2x.png");
        background-size: cover;
        background-position: center center;

      }

      .principle {
        width: 10.18rem;
        height: 2.34rem;
        margin: 0 auto;
        margin-top: 0.64rem;
        background-position: center center;
        background-size: cover;
        background-image: url("../../assets/images/homeImg/组 1657@2x.png");
        align-items: center;
        display: flex;
        justify-content: center;

        .principle-content {
          width: 8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .p1, .p2, .p3 {
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #5FC6C5;
          opacity: 1;
        }

        .p1 {
          width: 1.22rem;
          height: 0.5rem;
        }

        .p2 {
          width: 1.55rem;
          height: 0.5rem;
        }

        .p3 {
          width: 1.06rem;
          height: 0.5rem;
        }
      }

    }

    .organization-flow {
      height: 6rem;
      width: 100%;
      background: #ffffff;
      opacity: 1;
      .flowPath {
        margin: 0 auto;
        width: 12.49rem;
        height: 2.48rem;
        margin-top: 1.57rem;
        /*border: 1px solid red;*/
        background-position: center center;
        background-size: cover;
        background-image: url("../../assets/images/homeImg/组 1765@2x.png");
        position: relative;
        .step-1,.step-2,.step-3,.step-4{
          p {
            font-size: 0.14rem;
            font-weight: 400;
            /*line-height: 0.15rem;*/
            color: #454F63;
            opacity: 1;

          }
        }
        .step-1 {
          position: absolute;
          top: 2rem;
          left: 0.5rem;
          width: 2.75rem;
          /*height: 1.06rem;*/
          /*border: 1px solid red;*/
        }
        .step-2 {
          position: absolute;
          bottom: 1.7rem;
          left: 3.2rem;
          width: 2.9rem;
          height: 1.9rem;
          /*border: 1px solid red;*/
          p:first-child{
            font-size: 0.16rem;
            font-weight: bold;
            color: #4BB0FF;
            opacity: 1;
            text-align: center;
            margin-bottom: 0.2rem;
          }
        }
        .step-3 {
          position: absolute;
          top: 1.5rem;
          left: 6.4rem;
          width: 2.5rem;
          height: 1.8rem;
          /*border: 1px solid red;*/
          p:first-child{
            font-size: 0.16rem;
            font-weight: bold;
            color: #FF7B3B;
            opacity: 1;
            text-align: center;
            margin-bottom: 0.2rem;
          }
        }
        .step-4 {
          position: absolute;
          bottom: 1.7rem;
          left: 9.3rem;
          width: 2.7rem;
          height: 1.1rem;
          /*border: 1px solid red;*/
          p:first-child{
            font-size: 0.16rem;
            font-weight: bold;
            color: #4BB0FF;
            opacity: 1;
            text-align: center;
            margin-bottom: 0.2rem;
          }
        }
      }
    }

    .organization-classHeading {
      width: 100%;
      height: 9.29rem;
      background: #F8F8F8;
      opacity: 1;
      /*border: 1px solid #F8F8F8;*/
      margin-bottom: 0.2rem;
      padding-top: 0.3rem;
      /*border: 1px solid red;*/

      .rzlm-list {
        width: 12.49rem;
        height:2.18rem;
        margin: 0 auto;
        margin-top: 0.42rem;
        display: flex;
        flex-wrap: wrap; //拆行
        align-items: center;
        justify-content: space-between;
        /*border: 1px solid red;*/
        .serve{
          width: 2.94rem;
          height: 2.18rem;
          background: #FFFFFF;
          border: 1px solid #5FC6C5;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
          opacity: 1;
          border-radius: 0.12rem;
          /*cursor: pointer;*/
          img{
            width: 2.68rem;
            height: 1.64rem;
            border-radius: 0.12rem;
            margin: 0.1rem 0.1rem 0 0.1rem;
          }
          .pp {
            /*border: 1px solid red;*/
            width: 2rem;
            font-size: 0.18rem;
            font-family: MicrosoftYaHei;
            color: #454F63;
            opacity: 1;
            text-align: center;
            margin: 0 auto;
            margin-top: 0.1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
</style>

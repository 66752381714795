var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"LeaderboardHeader"}},[_c('ul',{staticClass:"showMenu"},_vm._l((_vm.dataArr.slice(0, 10)),function(item,index){return _c('li',{key:item + index,class:{ select: _vm.selectIndex == index, hover: _vm.hoverIndex == index },on:{"mouseenter":function($event){_vm.hoverIndex = index},"mouseleave":function($event){_vm.hoverIndex = -1},"click":function($event){_vm.selectIndex = index,
        _vm.getData()}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"imageBox"},[_c('img',{attrs:{"src":("./img/icons/tubiao" + (index + 1) + "_" + (_vm.getIconType(
              index
            )) + "_icon_scph.png")}})]),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(item.name))])])])}),0),_c('ul',{staticClass:"showMenu"},_vm._l((_vm.dataArr.slice(10, 20)),function(item,index){return _c('li',{key:item + index,class:{
        select: _vm.selectIndex == index + 10,
        hover: _vm.hoverIndex == index + 10,
      },on:{"mouseenter":function($event){_vm.hoverIndex = index + 10,
        _vm.showHideMenu(index + 10)},"mouseleave":function($event){_vm.hoverIndex = -1,
        _vm.hideHideMenu(index + 10)},"click":function($event){_vm.selectIndex = index + 10,
        _vm.getData()}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"imageBox"},[_c('img',{attrs:{"src":("./img/icons/tubiao" + (index + 11) + "_" + (_vm.getIconType(
              index + 10
            )) + "_icon_scph.png"),"alt":""}})]),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(item.name))])])])}),0),(_vm.ifHideMenu)?_c('div',{staticClass:"hideMwnu",on:{"mouseenter":function($event){_vm.hoverIndex = 15,
      _vm.ifHideMenu = true},"mouseleave":function($event){_vm.ifHideMenu = false}}},[_vm._m(0),_c('ul',{attrs:{"id":"level1"}},_vm._l((_vm.menuLevel2),function(item,index){return _c('li',{key:index,on:{"mouseenter":function($event){return _vm.setLevel3(item.child)},"click":function($event){return _vm.getAll(item.typeId)}}},[_c('span',[_vm._v(_vm._s(item.typeName))])])}),0),_c('ul',{attrs:{"id":"level2"}},_vm._l((_vm.menuLevel3Show),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.getAll(item.typeId)}}},[_c('span',[_vm._v(_vm._s(item.typeName))])])}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrImg"},[_c('img',{attrs:{"src":require("../../../assets/images/mallHomeImg/lvTopArr.png"),"alt":""}})])}]

export { render, staticRenderFns }















































































  import {Component, Prop, Vue} from "vue-property-decorator";
  import {http} from "@/services/http";

  @Component({})
export default class evaluation extends Vue {
  @Prop() id: any;
  @Prop() overallScore: any;
  condition = {pageSize: 10, pageIndex: 1, type: 0};
  total = 0;
  list = [];

  created() {
    this.getdata();
  }

  getdata() {
    http.get(`/api/v1/comments/list`, {params: {...this.condition, commodityId: this.id},})
        .then((res) => {
          this.total = (res as any)["data"].totalCount;
          this.list = (res as any)["data"].list;
          this.$store.commit(
              "setProductCommontTotalCount",
                  (res as any)["data"].totalCount
          );
        });
  }

  onChange(e) {
    this.condition.pageIndex = e;
    this.getdata();
  }

  itemRender(current, type, originalElement) {
    const a = document.createElement("a");

    if (type === "prev") {
      a.innerHTML = "上一页";
      return a;
    } else if (type === "next") {
      a.innerHTML = "下一页";
      return a;
    }
    return originalElement;
  }
}

<template>
    <div class="pension-map-body">
        <div class="map" id="mapContainer">
        </div>
        <div class="menu">
            <div class="map-left">
                <div class="castAbout-top">
                    <div class="castAbout-top-title" @click="jumpToHome"></div>
                    <div class="castAbout-top-address">
                        <img src="../assets/images/homeImg/路径 4@2x2.png" height="25" width="21"/>
                        <span class="city">{{ myCurrentLocation.city}}</span>
                        <div class="cut" @click="pickCity">切换城市
                            <a-icon style="font-size: 0.1rem;margin-left: 5px" type="caret-down"/>
                        </div>
                        <div class="orientation" v-if="showdetialLocation&&myCurrentLocation.name"
                             :title="myCurrentLocation.name">当前位置: <span>{{myCurrentLocation.name}}</span></div>
                    </div>

                </div>
                <div class="castAbout-input">
                    <a-input-search
                            placeholder="服务商名称/服务名称/地址"
                            @search="searchOrgByInput"
                            enter-button
                            v-model="searchInput"
                    >
                    </a-input-search>
                </div>
            </div>
            <div class="map-right">
                <div class="pattern" :style="{height:this.heightened}">
                    <div class="pattern-map">
                        <div class="pattern-title">养老模式</div>
                        <div class="classify-map">
                            <div class="classify-style" @click="searchOrgByYlform(null)">
                                <img class="classify-img" src="../assets/images/homeImg/map-classify/组 1114@2x.png">
                                <span class="classify-span">全部模式</span>
                            </div>
                            <div class="classify-style" v-for="item in mapService" :key="item.ylformId"
                                 @click="searchOrgByYlform(item.ylformId,item)">
                                <img class="classify-img" :src="item.imgPoistion">
                                <span class="classify-span">{{item.ylformName}}</span>
                            </div>

                        </div>
                    </div>
                    <div class="service">
                        <div class="pattern-title">养老服务</div>
                        <div class="classify-map">
                            <div class="classify-style" @click="searchOrgByCategory(null)">
                                <img class="classify-img" src="../assets/images/homeImg/map-classify/组 1114@2x.png">
                                <span class="classify-span">全部服务</span>
                            </div>
                            <div class="classify-style" v-for="(item,index) in levelOneTypeList"
                                 :key="item.serviceClassificationId+index"
                                 @click="searchOrgByCategory(item.serviceClassificationId,item)">
                                <img class="classify-img" :src="item.imgPoistion">
                                <span class="classify-span">{{item.classificationName.substring(0, 5)}}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="viewMore" @click="goUp">
                    <img v-if="imageLocation" src="../assets/images/homeImg/Up向上 (20).png"/>
                    <img v-if="!imageLocation" style="transform: rotate(180deg)"
                         src="../assets/images/homeImg/Up向上 (20).png"/>
                </div>
            </div>
        </div>

        <div class="serviceLookup" v-show="lookForInformation">
            <div class="top">
                <span v-if="ylformName!='全部模式'">{{ylformName}}</span>
                <span v-else>全部服务中心</span>
                <img @click="occlude" src="../assets/images/homeImg/组 2081@2x.png"/>
            </div>
            <div class="locating">
                <p>搜寻结果</p>
                <div>共 <span>{{pagination.total}}条</span> 数据</div>
            </div>
            <div class="locating-details" v-for="(item,index) in orgList" :key="index"
                 @mouseenter="detailedAddress(item)" @mouseleave="leave()">
                <div class="imgDiv"><img src="../assets/images/homeImg/组 2076@2x.png"/> <span
                        @click="jump( item)">{{item.orgName}}</span>
                </div>
                <div class="informationDiv"><span>电话: </span><span style="margin-left: 0.03rem">{{item.orgTel}}</span>
                </div>
                <div class="informationDiv"><span>地址: </span><span
                        style="margin-left: 0.03rem">{{item.orgFullAddress}}</span></div>
            </div>
            <div
                    class="paginationMap"
                    v-if="pagination.total>6"
            >
                <a-pagination
                        @change="onChangePageSize"
                        :total="pagination.total"
                        :showTotal="total => ` 共${total} 条`"
                        :pageSize="pagination.pageSize"
                        :defaultCurrent="pagination.pageIndex"
                        :item-render="$store.state.itemRender"
                        show-less-items
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import {Icon, Input} from "ant-design-vue";
    import AMapLoader from "@amap/amap-jsapi-loader";
    import {
        endowmentPattern,
        fetchPensionMapService,
        fetchSearchPensionMapInfo,
        firstLetterList,
        getOrgByInput,
    } from "@/services/api";

    Vue.use(Icon);
    Vue.use(Input);

    export default {
        name: "PensionMap",
        components: {},
        data() {
            return {
                mapService: [],
                lookForInformation: false,
                levelOneTypeList: [],
                plugins: ["AMap.Geolocation"],
                AMap: undefined,
                map: null,
                myCurrentLocation: {city: "", name: ""},
                fold: {bool1: false, bool2: false, bool3: false},
                orgList: {orgList: []},
                orgListTwo: {orgListTwo: []},
                searchInput: null,
                showdetialLocation: true,
                page: 1,
                heightened: '122px',
                imageLocation: false,
                totalPage: 1,
                orgForm: null,
                orgCategory: null,
                ylformName: '',
                orgListOnPage: [],
                iconArr: [
                    "shzl",
                    "jzfw",
                    "bmwx",
                    "hlfw",
                    "ylfw",
                    "jkjcz",
                    "jswj",
                    "whyl",
                    "slhgz",
                ],
                pagination: {
                    total: 0,
                    pageSize: 20,
                    pageIndex: 1
                }
            };
        },
        methods: {
            fetchYlmsNavMenuList() {
                endowmentPattern().then((res) => {
                    this.mapService = res.data.slice(0, 5);

                    const imgPoistion = {
                        机构养老: require('@/assets/images/homeImg/map-classify/组 1102@2x.png'),
                        居家养老: require('@/assets/images/homeImg/map-classify/组 1113@2x.png'),
                        社区养老: require('@/assets/images/homeImg/map-classify/组 1116@2x.png'),
                        医养结合: require('@/assets/images/homeImg/map-classify/组 1113@2x(1).png'),
                        旅居养老: require('@/assets/images/homeImg/map-classify/组 1117@2x(1).png'),

                    };
                    this.mapService.map((item) => {
                        item.imgPoistion = imgPoistion[item.ylformName];
                    });
                });
            },
            goUp() {
                if (this.heightened === '122px') {
                    this.heightened = '100%';
                    this.imageLocation = true;
                } else {
                    this.heightened = '122px';
                    this.imageLocation = false;
                }

            },
            fetchPensionMapService() {
                fetchPensionMapService({classificationLevel: 1}).then((res) => {
                    let levelOneTypeList = res.data.levelOneTypeList;
                    const imgPoistion = {
                        生活照料: require('@/assets/images/homeImg/map-classify/组 1116@2x(2).png'),
                        家政服务: require('@/assets/images/homeImg/map-classify/组 1112@2x.png'),
                        便民维修: require('@/assets/images/homeImg/map-classify/组 1102@2x(2).png'),

                        护理服务: require('@/assets/images/homeImg/map-classify/组 1102@2x(1).png'),
                        医疗服务: require('@/assets/images/homeImg/map-classify/组 1117@2x(2).png'),
                        健康检测: require('@/assets/images/homeImg/map-classify/组 1113@2x(2).png'),

                        精神慰藉: require('@/assets/images/homeImg/map-classify/组 1113@2x(3).png'),
                        文化娱乐: require('@/assets/images/homeImg/map-classify/组 1116@2x(1).png'),
                        适老化改造: require('@/assets/images/homeImg/map-classify/组 1115@2x.png'),
                        保险服务: require('@/assets/images/homeImg/map-classify/组 11141@2x.png'),
                        休闲旅游: require('@/assets/images/homeImg/map-classify/组 111e7@2x.png'),
                    };
                    levelOneTypeList.map((item) => {
                        item.imgPoistion = imgPoistion[item.classificationName];
                    });
                    this.levelOneTypeList = levelOneTypeList.filter(data => data.imgPoistion != undefined);
                });
            },
            onSearch(value) {
                fetchSearchPensionMapInfo({
                    cityId: parseInt(localStorage.getItem("provinceId") || "0", 10),
                    areaId: "1",
                    searchInput: value,
                }).then((res) => {
                });
            },
            pickCity() {
                sessionStorage.setItem("frontPage", "MAP");
                this.$router.push("/pickCity");
            },
            serviceClick(list) {
                this.curLabel.length = 0;
                list.forEach((item2) => {
                    this.curLabel.push(item2);
                });
            },
            async initAMap() {
                this.AMap = await AMapLoader.load({
                    key: "c4ac5dbaa55b2994a57c6b369f28af53",
                    plugins: ["AMap.Geolocation", "AMap.Geocoder", "AMap.CitySearch"],
                    version: "1.4.15",
                });
            },
            getLocation() {
                const cityObj = JSON.parse(localStorage.getItem("setCity"));
                if (cityObj) {
                    this.myCurrentLocation.city = cityObj.cityName;
                    this.cityId = cityObj.cityId;
                    this.showdetialLocation = true;
                    return;
                }
                this.showdetialLocation = false;
                let location = new this.AMap.Geolocation({
                    enableHighAccuracy: true,
                    extensions: "all",
                });

                location.getCurrentPosition((status, result) => {
                    if (result.type == "complete" && result.info == "SUCCESS") {
                        this.myCurrentLocation = result.pois[0];
                        const address = result.addressComponent;
                        this.myCurrentLocation.city = address.city || address.province;
                        this.getCityId();

                    } else {
                        const citySearch = new this.AMap.CitySearch();
                        citySearch.getLocalCity((status, result) => {
                            if (result.type == "complete" && result.info == "OK") {
                                this.myCurrentLocation.city = result.city || result.province;
                                this.getCityId();
                            }
                        });
                    }
                    this.map = new this.AMap.Map("mapContainer", {
                        zoom: 13,//级别
                        center: [this.myCurrentLocation.location.lng, this.myCurrentLocation.location.lat],//中心点坐标
                    });
                });
            },
            initMap() {                                   
                this.map = new this.AMap.Map("mapContainer",);
            },
            //点击input框查询获取下拉数据参数
            searchOrgByInput() {
                this.lookForInformation = true;
                // $(".serviceLookup").fadeToggle(500);
                this.ylformName = '全部服务'
                this.gettingData()
            },
            //获取搜索数据接口
            gettingData() {
                getOrgByInput({
                    cityId: this.cityId,
                    queryKey: this.searchInput,
                    orgForm: this.orgForm,
                    orgCategory: this.orgCategory,
                    pageSize: this.pagination.pageSize,
                    pageIndex: this.pagination.pageIndex,
                }).then((res) => {
                    if (res.code === 0) {
                        this.orgList = res.page.list;
                        this.pagination.total = res.page.totalCount;
                        this.pagination.pageSize = res.page.pageSize;
                        // this.getMarkerOnMap();
                    }
                });
            },
            //获取全部数据接口(主要用来获取全部地图选点)
            gettingDataTwo() {
                getOrgByInput({
                    cityId: this.cityId,
                    queryKey: this.searchInput,
                    orgForm: this.orgForm,
                    orgCategory: this.orgCategory,
                    pageSize: 999999,
                    pageIndex: 1,
                }).then((res) => {
                    if (res.code === 0) {
                        // this.orgList = res.page.list;
                        this.orgListTwo = res.page.list;
                        this.getMarkerOnMap();
                    }
                });
            },
            //移入服务商地图位置
            detailedAddress(value) {
                this.getMarkerOnMapTwo(value);
            },
            //移出
            leave() {
                this.gettingDataTwo();
            },
            //点击分页查找
            onChangePageSize(page) {
                this.lookForInformation = true
                this.pagination.pageIndex = page;
                this.gettingData()
            },
            //关闭搜索框
            occlude() {
                $(".serviceLookup").fadeToggle(500);
                this.lookForInformation = false;
            },
            //回到首页
            jumpToHome() {
                this.$router.push("/");
            },

            //养老模式分类查询
            searchOrgByYlform(val, data) {
                if (val === null) {
                    this.ylformName = '全部模式'
                } else {
                    this.ylformName = data.ylformName
                }

                this.orgForm = val;
                this.lookForInformation = true
                this.gettingData();
                this.gettingDataTwo();

            },
            //养老服务分类查询
            searchOrgByCategory(categoryId, val) {
                if (categoryId === null) {
                    this.ylformName = '全部模式'
                } else {
                    this.ylformName = val.classificationName
                }
                this.orgCategory = categoryId;
                this.lookForInformation = true
                this.gettingData();
                this.gettingDataTwo();
            },
            getCityId() {
                firstLetterList().then((res) => {
                    if (res.msg == "success") {
                        res.data.map((item) => {
                            const m = item.cityList.find(
                                (item) => item.cityName == this.myCurrentLocation.city
                            );
                            if (m) {
                                this.cityId = m.cityId;
                            }
                        });
                    }
                });
            },
            jump(val) {
                if(val.serOrOrg==='0'){
                    const {href} = this.$router.resolve(`/orgDetail/${val.orgId}`);
                    window.open(href);
                }else{
                    const {href} = this.$router.resolve(`/organizationDetail/${val.orgId}`);
                    window.open(href);
                }
            },

            getMarkerOnMap() {
                var _this = this;
                this.map.remove(this.map.getAllOverlays("marker"));
                const list = this.orgListTwo;
                const arr = [];
                for (let item of list) {
                    arr.push(item.orgFullAddress);
                }

                const geocoder = new this.AMap.Geocoder({
                    city: this.myCurrentLocation.city,
                    batch: true,
                });
                geocoder.getLocation(arr, (state, result) => {
                    if (state === "complete" && result.geocodes.length) {
                        const markers = [];
                        var infoWindow = new this.AMap.InfoWindow({
                            offset: new this.AMap.Pixel(25, -45),
                        });
                        for (let i = 0; i < result.geocodes.length; i++) {
                            if (!result.geocodes[i]) return;
                            var markerContent = `<div class="marker" ><img src="http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png" alt=""><span>${
                                i + 1
                            }</span></div>`;
                            var marker = new this.AMap.Marker({
                                position: result.geocodes[i].location,
                                content: markerContent,
                            });
                            marker.on("click", (e) => {
                                infoWindow.setContent(
                                    `<div class="title" title="${list[i].orgName}" > <span> ${list[i].orgName}</span>   <img src='https://webapi.amap.com/images/close2.gif' id='close'> </div><ul><li title="地址：${list[i].orgFullAddress}" >地址：${list[i].orgFullAddress}</li><li>电话：${list[i].orgTel}</li><li><button id="btn" >去看看</button></li></ul><img src="https://webapi.amap.com/images/sharp.png" class='cdfrr' alt="">`
                                );
                                infoWindow.open(this.map, e.target.getPosition());
                                const t = setTimeout(() => {
                                    document.getElementById("btn").onclick = function () {
                                        const {href} = _this.$router.resolve({
                                            path: `/orgDetail/${
                                                list[i].orgId
                                            }`,
                                        });
                                        window.open(href);
                                    };
                                    document.getElementById("close").onclick = function () {
                                        infoWindow.close();
                                    };
                                    clearTimeout(t);
                                }, 1000);
                            });
                            markers.push(marker);
                        }
                        this.map.add(markers);
                        this.map.setFitView(markers);
                    }
                });
            },
            getMarkerOnMapTwo(val) {
                var _this = this;
                const list = [val];
                const arr = [];
                for (let item of list) {
                    arr.push(item.orgFullAddress);
                }

                const geocoder = new this.AMap.Geocoder({
                    city: this.myCurrentLocation.city,
                    batch: true,
                });
                geocoder.getLocation(arr, (state, result) => {
                    if (state === "complete" && result.geocodes.length) {
                        const markers = [];
                        var infoWindow = new this.AMap.InfoWindow({
                            offset: new this.AMap.Pixel(25, -45),
                        });
                        for (let i = 0; i < result.geocodes.length; i++) {
                            if (!result.geocodes[i]) return;
                            var markerContent = `<div class="marker" ></div>`;
                            var marker = new this.AMap.Marker({
                                position: result.geocodes[i].location,
                                content: markerContent,
                            });
                            marker.on("click", (e) => {
                                infoWindow.setContent(
                                    `<div class="title" title="${list[i].orgName}" > <span> ${list[i].orgName}</span>   <img src='https://webapi.amap.com/images/close2.gif' id='close'> </div><ul><li title="地址：${list[i].orgFullAddress}" >地址：${list[i].orgFullAddress}</li><li>电话：${list[i].orgTel}</li><li><button id="btn" >去看看</button></li></ul><img src="https://webapi.amap.com/images/sharp.png" class='cdfrr' alt="">`
                                );
                                infoWindow.open(this.map, e.target.getPosition());
                                const t = setTimeout(() => {
                                    document.getElementById("btn").onclick = function () {
                                        const {href} = _this.$router.resolve({
                                            path: `/orgDetail/${
                                                list[i].orgId
                                            }`,
                                        });
                                        window.open(href);
                                    };
                                    document.getElementById("close").onclick = function () {
                                        infoWindow.close();
                                    };
                                    clearTimeout(t);
                                }, 1000);
                            });
                            markers.push(marker);
                        }
                        this.map.add(markers);
                        this.map.setFitView(markers);
                    }
                });
            },
        },
        mounted: async function () {
            this.fetchYlmsNavMenuList();
            this.fetchPensionMapService();
            await this.initAMap();
            this.getLocation();
            this.initMap();
            this.gettingDataTwo()
        },
        destroyed() {
            // sessionStorage.removeItem("oneMap");
        },
    };
</script>

<style lang="less">
    .pension-map-body {
        width: 100%;
        height: 100%;

        .map {
            right: 0;
            background-color: #dddddd;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        .marker {
            position: relative;
            width: 0.35rem;
            height: 0.35rem;
            text-align: center;

            img {
                position: absolute;
                width: 0.35rem;
                left: 0;
                top: 0;
            }

            span {
                display: inline-block;
                width: 0.35rem;
                position: absolute;
                left: 0;
                top: 0;
                color: #fff;
                font-size: 0.2rem;
            }
        }

        .amap-info-close {
            display: none;
        }

        .amap-info-sharp {
            display: none;
        }

        .amap-info-content {
            width: 350px;
            height: 130px;
            overflow: inherit;
            position: relative;
            padding: 0;
            // background: none;
            // border: none;
            font-size: 17px;

            ul {
                padding: 0;
                margin: 0;
            }

            ul {
                list-style-type: none;
            }

            li {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            li:nth-child(1),
            li:nth-child(2) {
                height: 25px;
                line-height: 0.35rem;
                padding: 0 15px;
                font-size: 0.16rem;
                font-weight: 400;
                color: #2A2E43;
                opacity: 1;
            }

            li:nth-child(3) {
                margin-top: 5px;
                text-align: right;

                button {
                    background: none;
                    border: 0;
                    outline: none;
                    border-radius: 5px;
                    font-size: 0.16rem;
                    color: #222B45;
                    opacity: 1;
                    padding: 3px 15px;
                    cursor: pointer;

                    // box-shadow: 2px 2px 6px 2px #aaa;
                }
            }

            img.cdfrr {
                position: absolute;
                top: 128px;
                left: 160px;
            }

            .title {
                background-color: #f5f5f5;
                padding: 5px 10px 0;

                span {
                    width: 90%;
                    font-size: 0.14rem;
                    font-weight: bold;
                    color: #222B45;
                    opacity: 1;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                img {
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    width: 12px;
                    cursor: pointer;
                }
            }
        }

        .menu {
            width: 100%;
            position: absolute;
            left: 0;
            padding: 0.09rem 0.1rem 0.1rem 0.14rem;
            display: flex;
            /*border: 1px solid black;*/

            .map-left {
                height: 122px;
                /*min-width: 5.72rem;*/
                min-width: 572px;
                background: #FFFFFF;
                /*border: 1px solid #E4E9F2;*/
                box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
                opacity: 1;
                border-radius: 0.12rem;
                margin-right: 0.1rem;
                /*border: 1px solid red;*/

                .castAbout-top {
                    /*border: 1px solid red;*/
                    padding-left: 16px;
                    display: flex;

                    .castAbout-top-title {
                        width: 174px;
                        height: 49px;
                        background-image: url("../assets/images/homeImg/组 2075@2x.png");
                        background-position: center center;
                        background-size: 100% 100%;
                        cursor: pointer;
                        /*border: 1px solid red;*/


                    }

                    .castAbout-top-address {
                        display: flex;
                        align-items: center;
                        /*border: 1px solid red;*/
                        min-width: 320px;
                        user-select: none;

                        img {
                            width: 10.5px;
                            height: 12.3px;
                            margin-left: 0.17rem;
                            margin-right: 5px;
                        }

                        .city {
                            font-size: 0.14rem;
                            color: #38B4B2;
                            opacity: 1;
                            margin-right: 9px;
                            /*border: 1px solid red;*/
                            white-space: nowrap;


                        }

                        .cut {
                            width: 94px;
                            height: 26px;
                            background: #FFFFFF;
                            border: 1px solid #5FC6C5;
                            opacity: 1;
                            border-radius: 8px;
                            font-size: 0.14rem;
                            color: #38B4B2;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 20px;
                            cursor: pointer;
                        }

                        .orientation {
                            width: 175px;
                            height: 20px;
                            overflow: hidden;
                            font-size: 0.14rem;
                            font-weight: 400;
                            color: #454F63;
                            opacity: 1;
                            /*border: 1px solid red;*/

                            span {
                                font-size: 0.14rem;
                                font-weight: 400;
                                color: #38B4B2;
                                opacity: 1;
                            }
                        }

                    }

                }

                .castAbout-input {
                    width: 450px;
                    height: 48px;
                    margin-left: 10px;
                    margin-top: 10px;
                    /*border: 1px solid red;*/

                    .ant-input {
                        width: 412px;
                        font-size: 12px;
                        color: #78849E;
                        height: 44px;
                        opacity: 1;
                        border: 1px solid #ECECEC;
                    }

                    .ant-input {
                        &::placeholder {
                            color: #78849E;
                            font-size: 12px;
                            opacity: 1;
                        }
                    }

                    button.ant-btn.ant-btn-primary.ant-input-search-button {
                        width: 77px;
                        height: 44px;
                        background-color: #60c7c6;
                        border-color: #60c7c6;
                        font-size: 0.2rem;
                        border-radius: 0 0.5rem 0.5rem 0;
                    }

                    input.ant-input:focus {
                        border-color: #d9d9d9;
                        -webkit-box-shadow: 0 0 0 0;
                        box-shadow: 0 0 0 0;
                        border-radius: 0.5rem 0 0 0.5rem;
                    }

                    input.ant-input:hover {
                        border-color: #d9d9d9;
                    }

                    input.ant-input {
                        border-radius: 0.5rem 0 0 0.5rem;
                    }

                }
            }


            .map-right {
                width: 100%;
                /*border: 1px solid red;*/

                .pattern {
                    width: 100%;
                    /*border: 1px solid yellow;*/
                    overflow: hidden;
                    background: #FFFFFF;
                    /*border: 1px solid #E4E9F2;*/
                    border-bottom: none;
                    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
                    opacity: 1;
                    border-radius: 0.12rem;
                    display: flex;
                    user-select: none;

                    .pattern-map {
                        width: 45%;
                        display: flex;
                        /*border: 1px solid blue;*/
                        margin-top: 0.3rem;
                        margin-bottom: 0.1rem;

                        .pattern-title {
                            max-width: 14px;
                            font-size: 0.14rem;
                            font-weight: bold;
                            color: #2A2E43;
                            opacity: 1;
                            /*border: 1px solid black;*/
                            line-height: 16px;
                            margin-left: 20px;
                        }

                        .classify-map {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;

                            .classify-style {
                                /*border: 1px solid red;*/
                                width: 0.65rem;
                                height: 73px;
                                margin-left: 0.18rem;
                                margin-bottom: 0.2rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;
                                white-space: nowrap;

                                .classify-img {
                                    width: 48px;
                                    height: 48px;
                                    /*border: 1px solid red;*/
                                }

                                .classify-span {
                                    font-size: 0.14rem;
                                    font-weight: 400;
                                    color: #777A8E;
                                    opacity: 1;
                                    display: inline-block;
                                    /*border: 1px solid red;*/
                                }
                            }

                            .classify-style:hover {
                                border-bottom: 2px solid #5FC6C5;

                                .classify-span {
                                    color: #38B4B2;
                                }
                            }
                        }
                    }

                    .service {
                        width: 58%;
                        display: flex;
                        /*border: 1px solid blue;*/
                        margin-top: 0.3rem;
                        margin-bottom: 0.1rem;


                        .pattern-title {
                            max-width: 14px;
                            font-size: 0.14rem;
                            font-weight: bold;
                            color: #2A2E43;
                            opacity: 1;
                            /*border: 1px solid red;*/
                            line-height: 16px;
                            margin-left: 20px;
                        }

                        .classify-map {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            /*align-items: center;*/
                            flex-wrap: wrap;


                            .classify-style {
                                /*border: 1px solid red;*/
                                width: 0.64rem;
                                height: 73px;
                                margin-left: 0.18rem;
                                margin-bottom: 0.21rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                cursor: pointer;
                                white-space: nowrap;

                                .classify-img {
                                    width: 48px;
                                    height: 48px;
                                    /*border: 1px solid red;*/
                                }

                                .classify-span {
                                    font-size: 0.14rem;
                                    font-weight: 400;
                                    color: #777A8E;
                                    opacity: 1;
                                    display: inline-block;
                                    /*border: 1px solid red;*/
                                }
                            }

                            .classify-style:hover {
                                border-bottom: 2px solid #5FC6C5;

                                .classify-span {
                                    color: #38B4B2;
                                }
                            }
                        }
                    }
                }

                .viewMore {
                    width: 50.24px;
                    height: 25.16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 0 auto;
                    cursor: pointer;
                    background-color: #fff;
                    border-radius: 0 0 0.1rem 0.1rem;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

                    img {
                        width: 35.24px;
                        height: 30.16px;
                    }

                }

            }


        }


        .serviceLookup {
            width: 572px;
            height: 6.65rem;
            /*border: 1px solid red;*/
            overflow-y: auto;
            overflow-x: hidden;
            background: #FFFFFF;
            border: 1px solid #E4E9F2;
            box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
            opacity: 1;
            border-radius: 12px;
            position: absolute;
            left: 0.15rem;
            top: 143px;
            box-sizing: border-box;

            .paginationMap {
                text-align: center;
                margin-top: 0.21rem;
                margin-bottom: 0.3rem;
                bottom: 0.2rem;
                width: 100%;

                a.ant-pagination-item-link {
                    border-radius: 0;
                }

                a.ant-pagination-item-link:hover {
                    border: 1px solid #60c7c6;
                    color: #60c7c6;
                }

                li.ant-pagination-item {
                    border-radius: 0;
                }

                li.ant-pagination-item:hover {
                    border-radius: 0;
                    border: 1px solid #60c7c6;

                    a {
                        color: #60c7c6;
                    }
                }

                li.ant-pagination-item.ant-pagination-item-active {
                    border: 1px solid #60c7c6;
                    background-color: #60c7c6;

                    a {
                        color: #fff;
                    }
                }

                .ant-pagination-next,
                .ant-pagination-prev {
                    border: #ddd 1px solid;
                    padding: 0rem 0.15rem;
                    // line-height: 0.32rem;
                    // height: 0.92rem;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 0;
                    font-size: 0.16rem;
                }

                .a.ant-pagination-item-link:active {
                    border: 1px solid #60c7c6;
                }

                .ant-pagination-options-quick-jumper input {
                    border-radius: 0;
                }

                .ant-pagination-options-quick-jumper input:hover {
                    border: 1px solid #60c7c6;
                }

                .ant-pagination-options-quick-jumper input:focus {
                    border-radius: 0;
                    border: 1px solid #60c7c6;
                }
            }

            .top {
                height: 0.3rem;
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.21rem 0 0.21rem;
                user-select: none;
                /*border: 1px solid red;*/

                span {
                    font-size: 0.16rem;
                    font-weight: bold;
                    color: #2A2E43;
                    opacity: 1;
                }

                img {
                    width: 17.47px;
                    height: 17.47px;
                    cursor: pointer;
                }
            }

            .locating {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.21rem 0 0.21rem;
                user-select: none;
                margin-top: 0.13rem;
                /*border: 1px solid red;*/

                p {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;
                }

                div {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: #454F63;
                    opacity: 1;

                    span {
                        color: #5FC6C5;
                    }
                }
            }

            .locating-details {
                margin: 0.2rem;
                width: 532px;
                height: 90px;
                background: #F8F8F8;
                opacity: 1;
                border-radius: 4px;
                padding: 0.11rem;
                cursor: pointer;
                /*border: 1px solid red;*/

                .imgDiv {
                    display: flex;
                    align-items: center;

                    img {
                        height: 15.33px;
                        width: 12.21px;
                        margin-right: 0.07rem;
                    }

                    span {
                        font-size: 0.14rem;
                        font-weight: bold;
                        color: #454F63;
                        opacity: 1;
                        cursor: pointer;
                    }

                    span:hover {
                        color: #5FC6C5;
                    }
                }


                .informationDiv {
                    margin-top: 0.07rem;
                    display: flex;

                    span {
                        font-size: 0.13rem;
                        font-weight: 400;
                        color: #454F63;
                        opacity: 1;
                    }
                }
            }
        }
    }


</style>

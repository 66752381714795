























  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import ProductsItem from "./ProductsItem.vue";
  import {Mutation, State} from "vuex-class";

  @Component({ components: { ProductsItem } })
export default class Products extends Vue {
  //  = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  @Prop() dataAll: any;
  colorArray = [
    "#60c7c6",
    "#ff9902",
    "#5186f1",
    "#3eb8e9",
    "#7179ea",
    "#719ef7",
  ];
  @Watch("dataAll")
  fn() {
    this.data = this.dataAll;

    this.setSliderItems();
  }
  data: Array<any> = [];
  @State scrollTopHeight!: number;

  @Watch("scrollTopHeight") getTop(val: number) {
    let [a, b, c] = [
      val,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
    ];
    if (a + b + 700 >= c && this.data.length < 10) {
      // this.data = this.dataAll.slice(0, this.data.length + 1);
    }
  }
  @Mutation setSliderBarItems: any;
  @Mutation setfixedCurTop: any;
  setSliderItems() {
    this.$nextTick().then((res) => {
      const el: any = document.querySelector(`.ProductsItem0`);
      const ar: any[] = [
        // {
        //   name: "日常生活",
        //   top: 700,
        //   border: true,
        //   line: 2,
        // },
      ];
      this.dataAll.slice(0, 15).map((item, index) => {
        ar.push({
          name: `${index + 1}F`,
          allcontent: item.typeName,
          // top: 1600 + 0 + 557 * index,
          content: item.typeName.slice(
                  item.typeName.length - 4,
                  item.typeName.length
          ),
          top: 0,
          border: true,
        });
      });

      this.setSliderBarItems(ar);
    });
  }
  created() {
    // this.setfixedCurTop(780);
  }
  mounted() {
    // for (let i = 0; i < 5; i++) {
    //   this.data.push(this.dataAll[0]);
    // }
    // this.data = this.dataAll.slice(0, 3);
  }
}






































import {Component, Vue} from "vue-property-decorator";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {Breadcrumb, message} from "ant-design-vue";
import Ylms from "../components/Ylms.vue";
import {fetchYlmsList} from "@/services/api";

declare var $: any;

Vue.use(Breadcrumb);

interface YlmsPageContent {
    formDicId: Number;
    formName: string;
    // orgList: OrgList[];
}

@Component({
    components: {
        Header,
        Nav,
        Footer,
        Ylms
    },
    data() {
        return {};
    }
})
export default class PensionMode extends Vue {

    contentList: YlmsPageContent[] = [];

    mounted() {
        const city = parseInt(localStorage.getItem("provinceId") || "2", 10);
        fetchYlmsList({
            cityId: city
        }).then((res: any) => {
            if (res.code === 0) {
                if (res.data && res.data.formList) {
                    res.data.formList.map((item: YlmsPageContent) => {
                        this.contentList.push(item);
                        let levelOneTypeList =  this.contentList;
                        const imgPoistion:any = {
                            机构养老: require('@/assets/images/homeImg/map-classify/组 1102@2x.png'),
                            居家养老: require('@/assets/images/homeImg/map-classify/组 1113@2x.png'),
                            社区养老: require('@/assets/images/homeImg/map-classify/组 1116@2x.png'),
                            医养结合: require('@/assets/images/homeImg/map-classify/组 1113@2x(1).png'),
                            旅居养老: require('@/assets/images/homeImg/map-classify/组 1117@2x(1).png'),
                        };
                        levelOneTypeList.map((item:any) => {
                            item.imgPoistion = imgPoistion[item.formName];
                        });
                    });
                }
            } else {
                message.error("获取界面内容失败！");
            }
        });
    }
}






























































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Icon, Tag} from "ant-design-vue";
import YlmsItem from "@/components/YlmsItem.vue";
import InstitutionInfo from "@/components/InstitutionInfo.vue";
import noResult from "./noResult.vue";
import {serviceItems, serviceProvider} from "@/components/Recommended";
import {fetchArea} from "@/services/api";
import aSpin from "@/components/aSpin.vue";

Vue.use(Icon);
Vue.use(Tag);

@Component({
    props: {
        onSearch: Function,
        list: Array,
        pagination: Object,
        searchContent: String,
    },
    components: {
        YlmsItem,
        noResult,
        InstitutionInfo,
        serviceItems,
        serviceProvider,
        aSpin
    },
})
export default class extends Vue {
    tags: any[] = [];
    loading:any=true;
    // 养老模式
    /**
     * null代表不限，206-机构养老；207-居家养老；208-社区养老；209-旅居养老；210-医养结合；216-其它
     */

    projectSelect = [
        {
            label: "不限",
            value: 0,
        },
        {
            label: "机构养老",
            value: 206,
        },
        {
            label: "居家养老",
            value: 207,
        },
        {
            label: "社区养老",
            value: 208,
        },
        {
            label: "旅居养老",
            value: 209,
        },
        {
            label: "医养结合",
            value: 210,
        },
    ];
    // 机构性质
    /**
     * null代表不限，172-国营机构；173-民营机构
     * 174-社会团体；175-公办民营；176-公助民办；177-其它
     */

    jgxz = [
        {
            label: "不限",
            value: 0,
        },
        {
            label: "国营机构",
            value: 172,
        },
        {
            label: "公办民营",
            value: 175,
        },
        {
            label: "民营机构",
            value: 173,
        },
        {
            label: "社会团体",
            value: 174,
        },
        {
            label: "公助民办",
            value: 176,
        },
        {
            label: "其他",
            value: 177,
        },
    ];
    // 选择区域
    sfbz = [];
    sfbz2 = [];

    pullDown = 0;
    pullDownTwo = 0;
    screenWidth = 0;
    ylmsChecked = 0;
    orgTypeChecked = 0;
    bedAmountChecked = 0;
    jgxzChecked = 0;
    sfbzChecked = 0;
    szdxChecked = 0;
    tsfwChecked = 0;

    cityName: any = "";
    @Watch("list", {immediate: true, deep: true})
    getList(newVal) {
        this.loading=false
    }
    // 判断当前选择的是否是全国？
    selectNationwide: boolean = false;

    /**
     * 根据筛选的地区显示没有结果的时候用
     */
    get _cityName() {
        const that = this
        const allList = JSON.parse(sessionStorage.getItem("allAreaData") as any);
        let provinceId: string | null = localStorage.getItem("provinceId");


        let selectId, cityName = ''
        // 如果当前选择了其他地址，则替换掉这个默认所在的地址
        if (that.sfbzChecked) {
            selectId = that.sfbzChecked.toString()
        }

        if (that.selectNationwide) {
            // 当前所在地是全国，判断是否有筛选 “选择区域”
            if (selectId) {
                // 筛选了地址，则找到是哪个省，显示省地址的简短名称
                for (const allListKey in allList) {
                    const item = allList[allListKey]
                    if (item.provinceId == parseInt(selectId || '0')) {
                        cityName = item.shortName;
                        break;
                    }
                }
            } else {
                // 选择的是不限，则显示  全国
                cityName = "全国";
            }
        } else {
            // 当前所在地是某个市
            if (selectId) {
                // 选择了具体的某个区县，则显示区县的名称
                let find = that.sfbz2.find((item) => item["id"] == selectId);
                if (find) {
                    cityName = find["name"];
                }
            } else {
                // 选择的是不限，则显示  当前市的名称
                let setCity: any = JSON.parse(localStorage.getItem("setCity") as any);
                if (setCity) cityName = setCity["cityName"];
            }
        }

        return cityName
    }

    allDAta(evt) {
        evt.preventDefault();
        let index = 0;
        this.ylmsChecked = 0;
        this.orgTypeChecked = index;
        this.bedAmountChecked = index;
        this.jgxzChecked = index;
        this.sfbzChecked = index;
        this.szdxChecked = index;
        this.tsfwChecked = index;
        const keys = [
            "specialService",
            "serviceObject",
            "areaId",
            "orgCharacter",
            "orgBedNum",
            "orgType",
            "orgForm",
        ];
        this.$props.onSearch(keys);
    }

    resetCHecks() {
        this.ylmsChecked = 0;
        this.orgTypeChecked = 0;
        this.bedAmountChecked = 0;
        this.jgxzChecked = 0;
        this.sfbzChecked = 0;
        this.szdxChecked = 0;
        this.tsfwChecked = 0;
    }

    getCityName() {
        const allList = JSON.parse(sessionStorage.getItem("allAreaData") as any);
        const cityId = localStorage.getItem("provinceId");

        let obj = {};
        allList.map((item) => {
            const m = item.cityList.find((item) => item.cityId == cityId);

            m ? (this.cityName = m.shortName) : "";
        });
        if (cityId == "0") {
            this.cityName = "全国";
        }
        // fetchCity
    }

    /**
     * 获取到 选择区域 的区域列表
     * @param val
     */
    district(val) {
        const cityId: any = localStorage.getItem("provinceId");
        this.selectNationwide = false

        //全国
        if (cityId === '0') {
            this.selectNationwide = true
            fetchArea({
                areaId: cityId,
                areaType: 0,
            }).then(res => {
                let data = (res as any).data;
                data.list.unshift({name: '不限', id: 0});
                this.sfbz = data.list.slice(0, 10);
                this.pullDown = 9
                this.pullDownTwo = 10
                // if (val < 1000 && val > 800) {
                //     this.sfbz = data.list.slice(0, 10);
                //     this.pullDown = 9
                //     this.pullDownTwo = 10
                // }  else {
                //     this.sfbz = data.list.slice(0, 10);
                //     this.pullDown = 9
                //     this.pullDownTwo = 10
                // }


                this.sfbz2 = data.list;

            });
        } else {
            fetchArea({
                areaId: cityId,
                areaType: 2,
            }).then(res => {
                let data = (res as any).data;
                data.list.unshift({name: '不限', id: 0});
                this.sfbz = data.list.slice(0, 10);
                this.pullDown = 9
                this.pullDownTwo = 10
                // if (val < 1000 && val > 800) {
                //     this.sfbz = data.list.slice(0, 10);
                //     this.pullDown = 9
                //     this.pullDownTwo = 10
                // } else {
                //     this.sfbz = data.list.slice(0, 10);
                //     this.pullDown = 9
                //     this.pullDownTwo = 10
                // }

                // this.sfbz = data.list.slice(0, 15);
                this.sfbz2 = data.list;

            });
        }


    }

    mounted() {
        this.getCityName();

        //获取区域宽度
        this.screenWidth = (this.$refs.barparent as any).clientWidth;
        const that = this
        window.onresize = () => {
            return (() => {
                (window as any).screenWidth = (this.$refs.barparent as any).clientWidth
                that.screenWidth = (window as any).screenWidth
            })()
        }
    }

    //监听区域宽度变化
    @Watch("screenWidth")
    getScreenWidth(val) {
        this.district(val)
    }

    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});
        this.$emit("changePage", page);
    }

    addFilter(tag: any) {
        this.tags.push(tag);
        const temp = this.projectSelect.filter((item) => item !== tag);
        this.projectSelect = temp;
    }

    cancelOrgType(num: number) {
        this.orgTypeChecked = num;
    }

    handleYlmsCheckedChange(checked: boolean, index: number) {
        this.ylmsChecked = index;
        this.$props.onSearch("orgForm", index);
    }

    handleOrgTypeCheckedChange(checked: boolean, index: number) {
        this.orgTypeChecked = index;
        this.$props.onSearch("orgType", index);
    }

    handleBedAmountCheckedChange(checked: boolean, index: number) {
        this.bedAmountChecked = index;
        this.$props.onSearch("orgBedNum", index);
    }

    handleJgxzCheckedChange(checked: boolean, index: number) {
        this.jgxzChecked = index;
        this.$props.onSearch("orgCharacter", index);
    }

    /**
     * 选择区域
     * @param checked
     * @param index
     */
    handleSfbzCheckedChange(checked: boolean, index: number) {
        this.sfbzChecked = index;
        this.$props.onSearch(this.selectNationwide ? "provinceId" : "areaId", index);
    }

    handleSzdxCheckedChange(checked: boolean, index: number) {
        this.szdxChecked = index;
        this.$props.onSearch("serviceObject", index);
    }

    handleTsfwCheckedChange(checked: boolean, index: number) {
        this.tsfwChecked = index;
        this.$props.onSearch("specialService", index);
    }
}















































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Button, Icon, Tabs, Table } from "ant-design-vue";
import { VNode } from "vue";

Vue.use(Button);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(Table);

@Component({
  components: {}
})
export default class extends Vue {
  pagination = {
    total: 1,
    showTotal: (total: number) => `共${total}条记录`,
    itemRender: (current: any, type: string, originalElement: any): any => {
      if (type === "prev") {
        return "< 上一页";
      } else if (type === "next") {
        return "下一页 >";
      }
      return originalElement;
    }
  };
  columns = [
    {
      title: "时间",
      dataIndex: "time",
      align: "center"
    },
    {
      title: "充值单号",
      dataIndex: "num",
      align: "center"
    },
    {
      title: "充值金额",
      dataIndex: "money",
      align: "center"
    },
    {
      title: "状态",
      dataIndex: "status",
      align: "center"
    },
    {
      title: "备注",
      dataIndex: "other",
      align: "center"
    }
  ];
  data = [
    {
      key: "1",
      time: "2019-11-9 11:53:59",
      num: "211568455",
      money: "1.00",
      status: "未支付",
      other: "这是备注信息"
    }
  ];
}

<script>
import Service from './business/service'
import ServiceOrder from './business/service-order'
import Commodity from './business/commodity'
import CommodityOrder from './business/commodity-order'
export default {
  components: { ServiceOrder, Service, CommodityOrder, Commodity },
  name: "right-sidebar",
  props: {
    tabs: {
      type: Array,
      default: () => {
        return ['正在咨询', '我的订单']
      }
    },
    entryParams: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      currentTab: this.tabs[0]
    }
  },
  methods: {
    selectTab(item) {
      this.currentTab = item
    },

    sendServiceOrder(serviceOrderId) {
      this.$emit('send-service-order',serviceOrderId)
    },
    sendService(serviceInfo) {
      this.$emit('send-service',serviceInfo)
    },

    sendCommodityOrder(commodityOrderId) {
      this.$emit('send-commodity-order',commodityOrderId)
    },
    sendCommodity(commodityInfo) {
      this.$emit('send-commodity',commodityInfo)
    },
    jumpPage(message) {
      this.$emit('jump',message)
    }
  }
}
</script>

<template>
  <div class="right-sidebar">
    <ul class="control">
      <li v-for="item in tabs" :class="{ active: item === currentTab }" @click="selectTab(item)">
        <span>{{item}}</span>
      </li>
    </ul>
    <div class="main">
      <service :service-id="entryParams.serviceId" @send-service="sendService" @jump="jumpPage"
               v-show="currentTab === '正在咨询'" v-if="entryParams.serviceId"></service>
      <service-order :service-order-id="entryParams.serviceOrderId"
                     @send-service="sendService" @send-service-order="sendServiceOrder"
                     v-show="currentTab === '正在咨询'" v-if="entryParams.serviceOrderId"></service-order>
      <service-order :org-id="entryParams.orgId" v-show="currentTab === '我的订单'" v-if="entryParams.orgId"
                     @send-service="sendService" @send-service-order="sendServiceOrder"></service-order>


      <commodity :commodity-id="entryParams.commodityId" @send-commodity="sendCommodity" @jump="jumpPage"
               v-show="currentTab === '正在咨询'" v-if="entryParams.commodityId"></commodity>
      <commodity-order :commodity-order-id="entryParams.commodityOrderId"
                     @send-commodity="sendCommodity" @send-commodity-order="sendCommodityOrder"
                     v-show="currentTab === '正在咨询'" v-if="entryParams.commodityOrderId"></commodity-order>
      <commodity-order :merchant-id="entryParams.merchantId" v-show="currentTab === '我的订单'" v-if="entryParams.merchantId"
                       @send-commodity="sendCommodity" @send-commodity-order="sendCommodityOrder"></commodity-order>
    </div>
  </div>
</template>

<style scoped lang="less">
.right-sidebar {
  width: 100%;
  height: 100%;
  .control {
    padding-left: 0px;
    margin: 0px;
    height: 60px;
    border-bottom: 1px solid #E4E9F2;
    li {
      display: inline-block;
      list-style-type: none;
      width: 50%;
      line-height: 60px;
      text-align: center;
      vertical-align: center;
      cursor: pointer;
      transition: all .25s;
      font-size: 14px;
      height: 60px;
      color: #2A2E43;
      &.active {
        color: #39BEB8;
        border-bottom: 3px solid #39BEB8;
      }
    }
  }
  .main {
    height: calc(100% - 60px);
    background: #ffffff !important;
  }
}
</style>
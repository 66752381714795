






































































import {Component, Vue, Watch} from "vue-property-decorator";
import shopHeader from "@/components/mallComponents/shop/header.vue";
import shopNav from "@/components/mallComponents/shop/nav.vue";
import shopProduction from "@/components/mallComponents/shop/production.vue";
import shopSort from "@/components/mallComponents/shop/sort.vue";

import {http} from "@/services/http";

@Component({
    components: {
        shopHeader,
        shopNav,
        shopProduction,
        shopSort,
    },
})
export default class shop extends Vue {
    shopId = "";
    productionType = "";
    keyWords: any = "";
    showPages=false

    created() {
        this.shopId = this.$route.params.id || "";
        this.productionType = this.$route.params.typeId || "";
        this.keyWords = this.$route.query.keywords || "";
        this.getProductionList();


    }

    mounted() {
        this.startInterval();

    }

    bannerList = [];
    imageList: any = [];
    dataList: any = {};
    beNullList: any = {
        title: '很抱歉，您查看的店铺，可能已下架或者被转移。',
        list: [{data: "您可以:"}, {data: "很抱歉，您查看的店铺不存在，可能已下架或者被转移。"}, {data: "2.在顶部搜索框重新输入关键词搜索"}, {data: "3.去其他的地方逛逛"}],
        path: '/#/personal/myCollection?type=4',
        pathName: '收藏的店铺'
    }

    //去平台
    goPlatform(val) {
        window.open(val, "_blank");
    }

    selectType(typeId, secondTypeId) {
        this.pageInfo = {
            pageSize: 30,
            pageIndex: 1,
            SortMethod: 0,
            total:0
        };
        this.showPages=true
        http.get(`/api/v1/merchant/detail/category-commodities`, {
            params: {
                firstTypeId: typeId,
                secondTypeId: secondTypeId,
                merchantId: this.shopId,
                ...this.pageInfo,
            },
        }).then((res:any) => {
                if (res.code == 0) {
                    this.result = res.data;
                    this.ifHome = false;
                    this.pageInfo.total = res.data.totalCount;
                    this.pageInfo.pageSize = res.data.pageSize;
                }
            });
    }


    currentKey = 1;
    merchantStatus: any=1;
    timer = 0;

    updateCurrentKey() {
        if (this.currentKey > this.imageList.length - 1) {
            this.currentKey = 1;
            return;
        }
        this.currentKey = this.currentKey + 1;
    }

    startInterval() {
        this.timer = setInterval(this.updateCurrentKey, 3300);
    }

    stopInterval() {
        clearInterval(this.timer);
        this.timer = 0;
    }


    pageInfo = {
        pageSize: 30,
        pageIndex: 1,
        SortMethod: 0,
        total:0
    };

    // SortMethod = 0;


    //监听传过来的数据
    @Watch('bannerList')
    getBannerList(value) {
        this.dataList = value;
        this.merchantStatus = value.merchantStatus
        this.imageList = value.imageList;

    }

    @Watch("pageInfo.SortMethod")
    SortMethodChange() {
        this.keyWords ? this.getProductionByKeyWords() : this.getProductionByType();
    }


    onChange(pageindex) {
        this.pageInfo.pageIndex = pageindex;
        this.getProductionByType();
    }

    ifHome = true;
    result = {totalCount: 100,list:{}};

    getProductionList() {
        this.ifHome = !this.productionType && !this.keyWords;
        this.ifHome ? this.getIndexProductionList() : this.keyWords ? this.getProductionByKeyWords() : this.getProductionByType();
    }

    //商品详情页-猜你喜欢、商铺详情页推荐商品
    getIndexProductionList() {
        http.get(`/api/v1/commodities/surmise`, {
                params: {
                    merchantId: this.shopId,
                    ...this.pageInfo,
                },
            })
            .then((res:any) => {
                if (res.code == 0) {
                    this.pageInfo.total = res.data.totalCount;
                    this.pageInfo.pageSize = res.data.pageSize;
                    this.result.list=res.data.list
                }
            });
    }

    //搜索商品（类目搜索或者具体商品搜索或者热门推荐）
    getProductionByKeyWords() {
        http
            .get("/api/v1/commodities/search", {
                params: {
                    merchantId: this.shopId,
                    searchInput: this.keyWords,
                    ...this.pageInfo,
                },
            })
            .then((res:any) => {
                if (res.code == 0) {
                    this.result = res.data;
                }
            });
    }

    //店铺品类详情页
    getProductionByType() {
        http.get(`/api/v1/merchant/detail/category-commodities`, {
            params: {
                merchantId: this.shopId,
                typeId: this.productionType,
                ...this.pageInfo,
            },
        }).then((res:any) => {
                if (res.code == 0) {
                    this.result = res.data;
                    this.pageInfo.total = res.data.totalCount;
                    this.pageInfo.pageSize = res.data.pageSize;
                }
            });
    }

}

<template>
  <div id="page">
    <div id="chat">
      <div class="box">
        <div class="sidebar-left">
          <card :me="user" @search="sessionSearchHandler"></card>
          <list v-model="targetId" @destory="destoryConversation" ref="list"
                :sessions="sessions" :filter-key="filterKey">
          </list>
        </div>
        <div class="main">
          <message v-model="session" ref="message" :me="user" @click="openWindowByMessage"></message>
          <text-input :emoji-list="emojiList" @send-message="sendMessage" @upload="imgSendingHandler"></text-input>
        </div>
        <div class="sidebar-right">
          <right-sidebar :entry-params="entryParams" @jump="openWindowByMessage"
                         @send-service="sendServiceHandler" @send-service-order="sendServiceOrderHandler"
                         @send-commodity="sendCommodityHandler" @send-commodity-order="sendCommodityOrderHandler"></right-sidebar>
        </div>
      </div>
    </div>
    <div id="copyright">
      <span>CopyRight©2020~2021 fytlun.com 版权所有</span>
      <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备20035226号-1</a>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">电信业务经营许可证：粤B2-20210484</a>
    </div>
  </div>
</template>

<script>
  import imTool from '@/im/imTool.js'
  import Card from './components/card';
  import List from './components/list';
  import TextInput from './components/text-input';
  import Message from './components/message';
  import RightSidebar from './components/right-sidebar';
  import {baseUrl, request} from "../../services/http";
  import ServiceItem from "../../components/Personal/MyService/ServiceItem"
  import {serviceOrderDetal} from "@/services/api";

  export default {
    components: { Card, List, TextInput, Message, RightSidebar, ServiceItem },
    async created () {
      await this.initIM();
      await this.initData();
      await this.entryParamsHandler();
    },
    data() {
      return {
        user: {},
        sessions: [],
        targetId: undefined,
        session: {}, //当前选中的session
        filterKey: '',
        emojiList: imTool.getEmojiList(),
        entryParams: {
          serviceId: this.$route.query.serviceId,
          serviceOrderId: this.$route.query.serviceOrderId,
          orgId: this.$route.query.orgId,
          commodityId: this.$route.query.commodityId,
          commodityOrderId: this.$route.query.commodityOrderId,
          merchantId: this.$route.query.merchantId
        }
      }
    },
    watch: {
      targetId() {
        this.sessions.forEach((item) => {
          if(item.targetId == this.targetId) {
            this.session = item;
            this.loadSessionHistory(item);
            this.updateEntryParams();
            return;
          }
        })
      },
    },
    methods: {
      /**
       * 初始化IM连接
       **/
      async initIM() {
        const that = this
        this.user = await imTool.imToolInit({
          localAjaxMethod: this.ajaxMethod,
          callbackSetting: {
            // // 监听会话列表变更事件
            async conversation(event) {
              console.log('bbbbbbbbbb')
              console.log(event)
              console.log(that.sessions)
              const conversationList = that.sessions
              // 发生变更的会话列表
              let updatedConversationList = event.updatedConversationList;
              await imTool.fillUserInfoToConversationList(updatedConversationList);
              // 通过 im.Conversation.merge 计算最新的会话列表
              that.sessions = imTool.mergeConversation({conversationList, updatedConversationList})
              that.$refs.list.refresh();
            },
            // 监听消息通知
            message(event) {
              // 新接收到的消息内容
              const message = event.message;
              const targetId = message.targetId;
              that.sessions.forEach((item) => {
                if(item.targetId == targetId) {
                  if(item.messages == undefined) {
                    that.loadSessionHistory(item)
                  } else {
                    item.messages.push(message)
                  }
                }
              })
              that.$nextTick(()=>{
                that.$refs.message.$forceUpdate()
              })
            },
          }
        });
        this.user.name = this.user.nickname ? this.user.nickname : this.user.username;
        console.log('-----user-----');
        console.log(this.user)
      },
      /**
       * 初始化数据
       */
      async initData() {
        let that = this
        //加载会话列表
        await imTool.loadConversationList({
          resultList: this.sessions
        })
        this.$refs.list.refresh();
        //更新会话列表后要检查targetId是否还在列表中(删除时)，若不在列表中，默认选择第一个session
        let targetIdInSessions = false;
        this.sessions.forEach(item => {
          if(item.targetId == this.targetId) {
            targetIdInSessions = true;
          }
        })
        console.log('看看执行了个啥')
        console.log(targetIdInSessions)
        if(!targetIdInSessions) {
          this.targetId = this.sessions[0] ? this.sessions[0].targetId : undefined;
        }
      },
      /**
       * 初始化入口参数（配置目标session）
       * 注：此方法要在加载会话列表后调用
       */
      async entryParamsHandler() {
        let tempSession = await imTool.getTempConversation({
          serviceId: this.$route.query.serviceId,
          serviceOrderId: this.$route.query.serviceOrderId,
          orgId: this.$route.query.orgId,
          commodityId: this.$route.query.commodityId,
          commodityOrderId: this.$route.query.commodityOrderId,
          merchantId: this.$route.query.merchantId
        })
        if (tempSession == undefined) return;
        let isInSessions = false;
        for(let session of this.sessions) {
          if(session.targetId == tempSession.targetId) {
            isInSessions = true;
            break;
          }
        }
        if(!isInSessions) {
          this.sessions.unshift(tempSession)
        }
        this.targetId = tempSession.targetId;
      },
      /**
       * 设置当前session并获取聊天记录
       */
      loadSessionHistory(session) {
        session.messages = []
        imTool.loadHistory({
          targetId: session.targetId,
          resultList: session.messages,
          callback: ()=> {
            this.$nextTick(() => {
              console.log('哈哈哈哈')
              console.log(this.$refs.message)
              this.$refs.message.$forceUpdate()
            })
          }
        })
      },
      /**
       * 删除指定会话并重新加载会话列表
       */
      destoryConversation(targetId) {
        if(targetId == this.targetId) {
          this.targetId = undefined;
        }
        let that = this
        imTool.destoryConversation({
          targetId: targetId,
          callback: ()=>{
            that.initData()
          }
        });
      },

      /**
       * 发送聊天消息
       */
      sendMessage(content) {
        console.log(content);
        console.log(this.targetId)
        console.log(this.pushMessage)
        // if(!this.targetCheck()) return;
        imTool.sendMessage({
          targetId: this.targetId,
          content: content,
          onSuccess: this.pushMessage
        })
      },
      /**
       * 更新对话过滤文本
       * @param text
       */
      sessionSearchHandler(text) {
        this.filterKey = text
      },
      /**
       * 处理图片上传请求
       * @param files
       */
      imgSendingHandler(files) {
        if(!this.targetCheck()) return;
        imTool.uploadImg({
          file: files[0],
          autoSend: true,
          targetId: this.targetId,
          onSuccess: this.pushMessage
        })
      },

      /**
       * 处理发送服务请求
       */
      sendServiceHandler(serviceInfo) {
        if(!this.targetCheck()) return;
        imTool.sendService({
          targetId: this.targetId,
          content: serviceInfo,
          onSuccess: this.pushMessage
        })
      },

      /**
       * 处理发送服务订单请求
       */
      sendServiceOrderHandler(serviceOrderInfo) {
        if(!this.targetCheck()) return;
        imTool.sendServiceOrder({
          targetId: this.targetId,
          content: serviceOrderInfo,
          onSuccess: this.pushMessage
        })
      },

      /**
       * 处理发送商品请求
       */
      sendCommodityHandler(commodityInfo) {
        if(!this.targetCheck()) return;
        imTool.sendCommodity({
          targetId: this.targetId,
          content: commodityInfo,
          onSuccess: this.pushMessage
        })
      },

      /**
       * 处理发送商品订单请求
       */
      sendCommodityOrderHandler(commodityOrderInfo) {
        if(!this.targetCheck()) return;
        imTool.sendCommodityOrder({
          targetId: this.targetId,
          content: commodityOrderInfo,
          onSuccess: this.pushMessage
        })
      },

      /**
       * 检查是否存在目标id
       */
      targetCheck() {
        if(this.targetId == undefined || this.targetId == '') {
          return false;
        } else {
          return true;
        }
      },
      /**
       * 推送发从成功的消息到session
       */
      pushMessage(message) {
        this.sessions.forEach((session) => {
          if(session.targetId == message.targetId) {
            session.messages.push(message)
          }
        })
        if(this.targetId == message.targetId) {
          this.$nextTick(() => {
            this.$refs.message.$forceUpdate()
          })
        }
      },

      /**
       * 根据当前选中的session配置EntryParams
       */
      updateEntryParams() {
        if(this.session.orgId) {
          if(this.session.orgId == this.$route.query.orgId) {
            this.entryParams = {
              orgId: this.session.orgId,
              serviceId: this.$route.query.serviceId,
              serviceOrderId: this.$route.query.serviceOrderId,
            }
          } else {
            this.entryParams = {
              orgId: this.session.orgId,
            }
          }
        } else if(this.session.merchantId) {
          if (this.session.merchantId == this.$route.query.merchantId) {
            this.entryParams = {
              merchantId: this.session.merchantId,
              commodityId: this.$route.query.commodityId,
              commodityOrderId: this.$route.query.commodityOrderId
            }
          } else {
            this.entryParams = {
              merchantId: this.session.merchantId
            }
          }
        }
      },


      /**
       * 以下方法需要在不同端调整
       */
      async ajaxMethod({url, method, params, data, onSuccess, onError}) {
        await request(`${baseUrl}${url}`, {
          method: method,
          params: params ? params : undefined,
          data: data ? data : undefined,
        }).then(async (data) => {
          await onSuccess(data)
        })
      },

      openWindowByMessage(message) {
        switch (message.messageType) {
          case 'FYTL:Service':
            this.$router.push('/cookInHome/' + message.content.serviceId)
            break;
          case 'FYTL:ServiceOrder':
            const serviceOrderInfoId = message.content.serviceOrderId
            let curOrderStatus = {
              paixiafuwu: false,
              maijiafukuan: false,
              fuwutigongshang: false,
              querenfuwu: false,
              pingjia: false,
              guanbi: false,
            }
            this.$store.commit("SET_DETAILITEM");
            serviceOrderDetal({serviceOrderInfoId: serviceOrderInfoId}).then(
                    (res) => {
                      if (res) {
                        let data = res.data || {};
                        data.serviceOrderInfoId = serviceOrderInfoId;
                        this.$store.commit("SET_DETAILITEM", data);
                        switch (data.statusDic) {
                          case 292: //未付款
                            curOrderStatus["paixiafuwu"] = true;
                            this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            break;
                          case 293: //已付款
                            curOrderStatus["maijiafukuan"] = true;
                            this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            break;
                          case 294: //进行中
                            curOrderStatus["fuwutigongshang"] = true;
                            this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            break;
                          case 295: {
                            //已完成
                            if (data.ifEvaluation) {
                              curOrderStatus["pingjia"] = true;
                              this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            } else {
                              curOrderStatus["querenfuwu"] = true;
                              this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            }
                            break;
                          }
                          case 296: //已取消
                            curOrderStatus["guanbi"] = true;
                            this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            break;
                          default:
                            //已关闭
                            curOrderStatus["guanbi"] = true;
                            this.$store.commit("SET_CURDETAILSTATUS", curOrderStatus);
                            break;
                        }
                        let {href} = this.$router.resolve("/personal/serviceDetail");
                        window.open(href, "_blank");
                      }
                    }
            );
            break;
          case 'FYTL:Commodity':
            window.open('#/mall/detail/' + message.content.commodityId);
            break;
          case 'FYTL:CommodityOrder':
            window.open("#/personal/OrderDetail");
            this.$store.commit("SET_CURDETAILSTATUS", message.content.commodityOrderId);
            break;
        }
      }
    },
  }
</script>



<style lang="less" scoped>
  #page {
    position: fixed;
    top: 0;
    padding-top: 70px;
    height: 100%;
    width: 100%;
    background: #2D2E3C;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  #chat {

    margin: 20px auto;
    width: 1240px;
    height: 770px;

    background: #FFFFFF;
    border: 1px solid #E4E9F2;
    box-shadow: 0px 3px 46px rgba(77, 113, 136, 0.16);
    padding: 20px;

    overflow: hidden;
    border-radius: 12px;

    font-size: 14px;
    font-family: MicrosoftYaHei;
    .box {
      position: relative;
      border: 1px solid #E4E9F2;
      height: 100%;
    }

    .sidebar-left, .main, .sidebar-right {
      height: 100%;
    }
    .sidebar-left {
      float: left;
      width: 318px;
      font-size: 14px;
      color: #2A2E43;
      background: #FFFFFF;
      border-right: 1px solid #E4E9F2;
    }
    .main {
      position: absolute;
      overflow: hidden;
      left: 318px;
      width: 555px;
      background-color: #ffffff !important;
      border-right: 1px solid #E4E9F2;
      .text {
        height: 130px;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
      .message {
        height: calc(~"100% - 130px");
        /*border: 1px solid yellow;*/
      }
    }
    .sidebar-right {
      position: absolute;
      left: 873px;
      top: 0px;
      width: 323px;
      height: 100%;
    }

  }
  #copyright {
    position: relative;
    margin-top: 62px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 14px;
    color: #FFFFFF;
    a {
      color: #FFFFFF;
    }
  }
</style>





























    import {Component, Vue} from "vue-property-decorator";
    import {Checkbox, Icon, Input, List, Tabs, Tooltip} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import ServiceTrashItem from "@/components/Personal/MyService/ServiceTrashItem.vue";
    import ServiceTrashCanItem from "./ServiceTrashCanItem.vue";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Tooltip);

@Component({
  components: {
    PersonalHeader,
    ServiceTrashItem,
    ServiceTrashCanItem
  }
})
export default class extends Vue {
  tabTitle = ["全部服务", "待付款", "已付款", "已完成", "已取消", "待评价"];
  data = [
    {
      id: 1,
      status: 1,
      orderTime: "2019-11-20 175308",
      orderNum: "101643891418",
      storeName: "老年旗舰店",
      customer: "白桂金",
      totalPrice: 1065.01,
      payMode: "在线支付",
      remainingHour: 22,
      remainingMin: 8,
      content: "欧姆龙(OMRON)电子血压计 家用HEM-712(上臂式)",
      count: 3,
      price: 100,
      number: 3,
      servePerson: "白桂金"
    }
  ];
  // 点击筛选商品
  handleScreenGoods(key: Boolean) {
    // 1 全部订单  2  待服务  3 进行中  4  待评价
  }
  onSearch() {}
}



































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Form, Input, message} from "ant-design-vue";
import {
    checkMobileValidateCode,
    fetchAccountInfo,
    getVerificationCode,
    modifyThePhoneNumber,
    userRepassword,
    verificationCode,
    WxRemoveBinding
} from "@/services/api";
import {baseUrl} from "@/services/http";

Vue.use(Form);
Vue.use(Input);

@Component({
    components: {}
})
export default class extends Vue {
    userInfo: any = {};
    weChat: any = '绑定'
    recycleBin=false;
    visibleWechat=false;
    wechatQRCode='';
    mobile = ''
    serveComplete = false;
    userMobile = '';
    adminTel = ''
    isSending: any = false
    isSending2: any = false
    isSendingFisrt: any = false
    isSendingFisrt2: any = false
    verificationMobilePhone: any = false
    remainTime: any = 60
    remainTime2: any = 60
    code: any = 0;
    reminder: any = '';
    changeStatusType: any;
    cheYZMBynet: any = false;
    serveCompleteTwo: any = false;
    deleteModel: any = false;
    time: any;
    baseUrl = baseUrl
    formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 15}
        }
    }
    form: any;

    beforeCreate() {
        this.form = this.$form.createForm(this);
    }

    mounted() {
        //获取用户信息
        this.fetchAccountInfoUser()
    }

    fetchAccountInfoUser() {
        fetchAccountInfo().then(async res => {
            this.userInfo = res.data
        });
    }

    /* 校验手机号*/
    telCheck(rule, value, callback) {
        if (!value) {
            callback(`不能为空`);
            return;
        }

        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (!reg.test(value)) {
            callback("格式不正确，请输入正确格式的手机号");
            return;
        }
        callback();
    }

    //检验验证码
    yzmCheck(rule, value, callback) {
        if (!value) {
            callback("请填写验证码");
            return;
        }
        if (!this.cheYZMBynet) {
            return;
        }

        let getFormValue = name => this.form.getFieldValue(name);

        if (this.time) {
            clearTimeout(this.time);
        }
        this.time = setTimeout(() => {
            checkMobileValidateCode({
                mobile: getFormValue("adminTel"),
                code: getFormValue("yzm")
            }).then(res => {
                if (res && res.code == 0) {
                    callback();
                } else {
                    callback("短信验证码错误或已失效，请重新获取");
                }
            });
        }, 100);
    }

    /* 发送验证码*/
    handleSendCode() {
        let mobile;
        if (this.serveComplete) {
            mobile = this.userInfo.mobile
        } else {
            mobile = this.form.getFieldValue("adminTel");
        }
        this.form.validateFields(["adminTel"], (errors, values) => {
            if (!errors) {
                if (!this.isSending) {
                    this.isSending = true;
                    const t = setInterval(() => {
                        if (this.remainTime >= 1) {
                            this.remainTime -= 1;
                        } else {
                            this.isSending = false;
                            window.clearInterval(t);
                            this.remainTime = 60;
                        }
                    }, 1000);
                    getVerificationCode(mobile).then(res => {
                        this.isSendingFisrt = false;
                        if (res && res.code === 0) {
                            message.success("验证码已发送！");
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            } else {
                return;
            }
        });
    }

    //发送验证码2
    handleSendCode2() {
        let mobile;
        if (this.serveComplete) {
            mobile = this.userInfo.mobile
        } else {
            mobile = this.form.getFieldValue("newPhoneNumber");
        }
        this.form.validateFields(["newPhoneNumber"], (errors, values) => {
            if (!errors) {
                if (!this.isSending2) {
                    this.isSending2 = true;
                    const t = setInterval(() => {
                        if (this.remainTime2 >= 1) {
                            this.remainTime2 -= 1;
                        } else {
                            this.isSending2 = false;
                            window.clearInterval(t);
                            this.remainTime2 = 60;
                        }
                    }, 1000);
                    getVerificationCode(mobile).then(res => {
                        this.isSendingFisrt2 = false;
                        if (res && res.code === 0) {
                            message.success("验证码已发送！");
                        } else {
                            message.error(res.msg);
                        }
                    });
                }
            } else {
                return;
            }
        });
    }

    //修改密码、修改手机号第一步验证
    serviceOrderConfirm() {
        verificationCode({
            mobile: this.userInfo.mobile,
            code: this.form.getFieldValue("yzm"),
        }).then(res => {
            if (res.code == 0) {
                this.code = this.form.getFieldValue("yzm");
                if (this.changeStatusType === 0) {
                    this.serveComplete = false;
                    this.serveCompleteTwo = true;
                } else {
                    this.serveComplete = false;
                    this.verificationMobilePhone = true;
                }
            } else {
                message.error(res.msg)
            }

        })
    }

    //修改密码第二步（设置新密码）
    handleSubmit() {
        if (this.form.getFieldValue("confirmPassword") == '' || this.form.getFieldValue("newPassword") == '') {
            message.error('设置密码不能为空');
        } else {
            if (this.form.getFieldValue("confirmPassword") === this.form.getFieldValue("newPassword")) {
                userRepassword({
                    phone_number: this.userInfo.mobile,
                    verification_code: this.code,
                    new_password: this.form.getFieldValue("newPassword"),

                }).then((res: any) => {
                    if (res && res.code === 200) {
                        this.reminder = '恭喜您，登录密码修改成功。'
                        this.serveCompleteTwo = false;
                        this.deleteModel = true;

                    } else {
                        message.error(res.msg);
                    }
                });
            }
        }

    }


    //修改手机号第二步
    handleSubmit2() {
        if (this.form.getFieldValue("newPhoneNumber") == '') {
            message.error('新手机号不能为空');
        } else {
            verificationCode({
                mobile: this.form.getFieldValue("newPhoneNumber"),
                code: this.form.getFieldValue("yzmTwo"),
            }).then(res => {
                if (res.code == 0) {
                    modifyThePhoneNumber({
                        phone_number: this.form.getFieldValue("newPhoneNumber"),
                        verification_code: this.form.getFieldValue("yzmTwo"),

                    }).then((res: any) => {
                        if (res && res.code === 200) {
                            this.verificationMobilePhone = false;
                            this.deleteModel = true;
                            this.reminder = '恭喜您，手机号码修改成功。'

                        } else {
                            message.error(res.msg);
                        }
                    });
                } else {
                    message.error(res.msg)
                }

            })

        }
    }

    // 校验新密码
    checkNewPassword(rule: any, value: any, callback: any) {
        const form = this.form;
        const regexp = /^[A-Za-z0-9]{8,20}$/;
        if (value && (value < 8 || value > 20)) {
            callback("密码长度8-20个字符（密码必须包括数字和英文字母）");
        } else if (value && value == form.getFieldValue("oldPassword")) {
            callback("新密码不能与原密码相同");
        } else {
            callback();
        }
    }

    // 校验确认密码
    checkConfirmPassword(rule: any, value: any, callback: any) {
        const form = this.form;
        const regexp = /^[A-Za-z0-9]{8,20}$/;
        if (value && (value < 8 || value > 20)) {
            callback("密码长度8-20个字符（密码必须包括数字和英文字母）");
        } else if (value && value != form.getFieldValue("newPassword")) {
            callback("两次密码输入不同");
        } else {
            callback();
        }
    }

    //修改密码、修改手机
    modify(val) {
        this.serveComplete = true;
        if (val === '0') {
            this.changeStatusType = 0;
        } else {
            this.changeStatusType = 1
        }
    }

    //微信绑定
    weChatBinding() {
        let websocket = (this as any).$websocket;
        // let websocket = this.$websocket;
        // websocket.onDestroy("01_wx_login_url", msg=>{
        // })
        websocket.onSend("01_wx_bind_url", msg => {
            if (msg && msg.code == 0) {
                switch (msg.socketCode) {
                    //(wx_login_url)返回这个调用微信二维码
                    case "wx_bind_url": {
                        if (msg.message.indexOf("https://") >= 0) {
                            // this.visibleWechat=true;
                            // this.wechatQRCode=msg.message;
                            window.open(msg.message)
                        }
                    }
                        break
                    //（返回wx_bind_result）说明已经注册判断扫码登录结果是成功还是失败
                    case "wx_bind_result": {
                        this.fetchAccountInfoUser()
                    }
                }
            }
        })
    }

    //微信解除绑定
    unbundle() {
        WxRemoveBinding('').then(async res => {
            if (res.code === 0) {
                this.recycleBin = false
                this.fetchAccountInfoUser()
            }
        });
    }
}

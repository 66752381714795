<template>
  <div class="register">
    <div class="login-logo-resister">
      <div class="login-logo-left">
        <div class="log-logo" @click="jumpToHome"></div>
        <a-divider type="vertical"/>
        <span class="login-text">免费注册</span>
      </div>
      <div class="promptTone">
        <span>你好，欢迎来到老年服务综合平台 !</span>
      </div>
    </div>

    <div class="register-form-box">
        <div class="register-center">
          <div class="register-center-top">
            <div class="top-left">正在注册</div>
            <div class="top-right" @click="$router.push('/login')">已有账号？去登录<a-icon style="margin-left: 0.05rem" type="arrow-right" /></div>
          </div>
          <div class="register-center-matter">
            <div class="item">
              <span style="color:#f00">*</span> 用户名
              <div class="inputBox">
                <a-input
                        placeholder="可用于登录和名称显示"
                        v-model="info.username"
                        @change="
                          tip.usernameTip = '',
                          info.username = info.username.slice(0, 20)
                        "
                        @focus="tip.usernameTip = '支持数字、字母大小写，6~20个字符'"
                        @blur="testusername()"
                        maxlength="20"
                        id="username"
                />
              </div>
              <span class="tip" :class="{ tipOk: tip.usernameTip == 'OK' }">
                      <span v-if="tip.usernameTip != 'OK'">{{ tip.usernameTip }}</span>
                      <a-icon type="check-circle" v-else/>
                    </span>
            </div>
            <div class="item">
              <span style="color:#f00">*</span> 密码
              <div class="inputBox">
                <a-input-password
                        placeholder="请输入密码"
                        v-model="info.psw"
                        @change="
                          tip.pswTip = '',
                          info.psw = info.psw.slice(0, 20)
                        "
                        @focus="
                          tip.pswTip = '密码长度8-20个字符（密码必须包括数字和英文字母）'
                        "
                        id="psw"
                        maxlength="20"
                        @blur="testPsw()"
                />
              </div>
              <span class="tip" :class="{ tipOk: tip.pswTip == 'OK' }">
                      <span v-if="tip.pswTip != 'OK'">{{ tip.pswTip }}</span>
                      <a-icon type="check-circle" v-else/>
                    </span>
            </div>
            <div class="item">
              <span style="color:#f00">*</span> 确认密码
              <div class="inputBox">
                <a-input-password
                        placeholder="请再次输入密码"
                        id="pswAgain"
                        v-model="info.pswAgain"
                        @focus="tip.pswAgainTip = '请再次输入密码'"
                        @change="
                          tip.pswAgainTip = '',
                          info.pswAgain = info.pswAgain.slice(0, 20)
                        "
                        maxlength="20"
                        @blur="
                          tip.pswAgainTip =
                            info.pswAgain == info.psw ? 'OK' : '两次输入的密码不一致'
                        "
                />
              </div>
              <span class="tip" :class="{ tipOk: tip.pswAgainTip == 'OK' }">
                      <span v-if="tip.pswAgainTip != 'OK'">{{ tip.pswAgainTip }}</span>
                      <a-icon type="check-circle" v-else/>
                    </span>
            </div>
            <div class="item">
              <span style="color:#f00">*</span> 手机号
              <div class="inputBox">
                <a-input
                        placeholder="可用于登录和密码找回"
                        id="phone"
                        v-model="info.phone"
                        @change="
                          tip.phoneTip = '',
                          info.phone = info.phone.slice(0, 11)
                        "
                        @focus="tip.phoneTip = '注册成功后，可使用该手机号登录或找回密码'"
                        @blur="phoneTest()"
                />
              </div>
              <span class="tip" :class="{ tipOk: tip.phoneTip == 'OK' }">
                      <span v-if="tip.phoneTip != 'OK'">{{ tip.phoneTip }}</span>
                      <a-icon type="check-circle" v-else/>
                    </span>
            </div>
            <div class="item">
              <span style="color:#f00">*</span> 验证码
              <div class="inputBox" style="text-align:left">
                <a-input
                        placeholder="请输入验证码"
                        style="width:1.8rem"
                        id="code"
                        v-model="info.code"
                        @focus="tip.codeTip = '请输入验证码'"
                        @change="tip.codeTip = ''"
                />
                <a-button
                        type="primary"
                        :disabled="isSending"
                        :style="{
                          backgroundColor: isSending ? '#c5c5c5' : '',
                          fontSize: '0.14rem'
                        }"
                        @click="handleSendCode"
                >{{ buttonContent }}
                </a-button>
              </div>
              <span class="tip" :class="{ tipOk: tip.codeTip == 'OK' }">
                      <span v-if="tip.codeTip != 'OK'">{{ tip.codeTip }}</span>
                      <a-icon type="check-circle" v-else/>
                    </span>
            </div>

            <div class="item" style="padding-left:0.9rem">
                    <span id="checkXieYi36652525">
                      <a-checkbox v-model="checked">我已阅读并同意以下协议</a-checkbox>
                      <a href="/html/养老平台注册协议.html" target="_blank">《注册协议》</a>
                      <a href="/html/隐私政策.html" target="_blank">《隐私政策》</a>
                    </span>
            </div>
            <div class="item">
              <div class="registerButon">
                <a-button
                        @click="handleSubmit"
                        :disabled="!checked"
                        :style="{ backgroundColor: !checked ? '#c5c5c5' : '' }"
                >注册
                </a-button>
              </div>
            </div>
          </div>


        </div>

    </div>


    <loginFooter style="height: 7%" />

    <a-modal
      v-model="visible"
      title=" "
      style="text-align: center;padding-top: 150px"
      :footer="null"
      @cancel="handleOk"
    >
      <div class="modal-image"></div>
      <p style="color: #000;font-size: 20px;margin-bottom:43px">恭喜您！注册成功</p>
      <a-button
        style="width: 135px;height:39.6px;margin:0 auto;margin-bottom: 44px;background-color: #60c7c6; color: white"
        @click="registerSuccessConfirm"
      >确定</a-button>
    </a-modal>
  </div>
</template>

<script>
    import {Vue} from "vue-property-decorator";
    import {Button, Checkbox, Form, Input, message} from "ant-design-vue";

    import {getVerificationCode, userRegister,} from "@/services/api";
    import {baseUrl, request} from "@/services/http";
    import loginFooter from "@/components/loginFooter";

    Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Form);

export default {
  components: {
    loginFooter,
  },
  data() {
    return {
      remainTime: 60,
      isSending: false,
      checked: false,
      visible: false,
      tip: {
        usernameTip: "",
        pswTip: "",
        pswAgainTip: "",
        phoneTip: "",
        codeTip: "",
      },
      info: { username: "", psw: "", pswAgain: "", phone: "", code: "" },
      translate: {
        username: "请输入用户名",
        psw: "请输入密码",
        pswAgain: "请再次输入密码",
        phone: "请输入手机号",
        code: "请输入验证码",
      },
      buttonContent: "获取短信验证码",
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
    console.log("正在注册")
  },
  mounted() {},
  methods: {
    handleSendCode() {
      // if (!this.form.getFieldValue("usermobile")) {
      //   return;
      // }

      this.remainTime = 60;
      this.telCheck(this.info.phone, (tip) => {
        this.isSending = true;
        if (tip == "OK") {
          getVerificationCode(this.info.phone).then((res) => {
            if (res && res.code === 0) {
              this.tip.codeTip = "验证码已发送，5分钟内输入有效";
              const t = setInterval(() => {
                this.remainTime -= 1;
                this.buttonContent = this.remainTime + "s后重新获取";
                if (this.remainTime == 0) {
                  this.isSending = false;
                  this.buttonContent = "重新发送";
                  clearInterval(t);
                }
              }, 1000);
              message.success("验证码已发送！");
            } else {
              this.tip.codeTip = "验证码获取失败，请重新获取";
              this.buttonContent = "重新发送";
              this.isSending = false;
              message.error(res.msg);
            }
          });
        } else {
          this.isSending = false;

          this.tip.phoneTip = tip;
        }
      });
    },
    jumpToHome() {
      this.$router.push("/");
    },
    onChange(checked) {
      this.checked = checked;
    },
    handleSubmit() {
      const that = this;
      if (!this.checked) {
        message.error("请阅读并同意《养老平台注册协议》《隐私政策》");
        return;
      }
      let sig = true;
      for (let item of Object.keys(this.info)) {
        if (!this.info[item]) {
          let str = item + "Tip";
          // document.getElementById(item).focus();
          this.tip[item + "Tip"] = this.translate[item];
          sig = false;
          break;
        }
      }
      const values = {
        username: this.info.username,
        userpwd: this.info.psw,
        usermobile: this.info.phone,
        vcodes: this.info.code,
      };
      if (sig) {
        userRegister(values).then((res) => {
          if (res && res.code === 0) {
            let userInfo = res["data"];
            userInfo.userName = userInfo.username;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            // that.visible = true;
            // message.success(res.msg);
            // this.$router.push("/home");
            that.registerSuccessConfirm()
          } else {
            message.error(res.msg);
          }
        });
      }
    },
    handleOk() {
      this.visible = false;
    },
    checkExist(value, callback) {
      if (value) {
        // 不能全是数字或者以数字开头
        // if (/^[0-9].*/.test(value)) {
        //   callback("用户名不能以数字开头");
        //   return;
        // }

        // const reg = /^[\u4e00-\u9fa5a-zA-Z0-9 ]{6,20}$/;
        // if (!reg.test(value)) {
        //   callback("用户名可输入汉字、字母或数字，且不少于六位");
        //   return;
        // }
        request(`${baseUrl}/api/v1/exist//${value}`)
          .then((res) => {
            if (res["code"] == 0) {
              if (res["exist"]) callback("该用户名已经被注册");
              else callback("OK");
            } else {
              callback("服务器错误！");
            }
          })
          .catch((error) => callback("服务器错误！"));
        // checkUserNameExistForRegister(value)
        //   .then(res => {
        //     if (res && res.code == 0) {
        //       callback("OK");
        //     } else {
        //       callback(res.msg);
        //     }
        //   })
        //   .catch(e => {
        //     callback(e);
        //   });
      }
    },
    /**
     * 校验密码强度
     * @param rule
     * @param value
     * @param callback
     */
    checkPassword(value, callback) {
      if (value) {
        if (value.length > 20 || value.length < 8) {
          callback(" 密码长度8-20个字符（密码必须包括数字和英文字母）");
          return;
        }
        let regex = /^(?=.*[a-z])(?=.*\d)[\s\S]{8,20}$/;
        if (!regex.test(value)) {
          callback("密码必须包括数字和英文字母");
        } else callback("OK");
      }
    },
    /**
     * 校验手机号
     * @param rule
     * @param value
     * @param callback
     */
    telCheck(value, callback) {
      // ^\d{15}|\d{18}$
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!reg.test(value)) {
        callback("请输入正确格式的手机号码");
        return;
      }
      request(`${baseUrl}/api/v1/exist//${this.info.phone}`).then((res) => {
        if (res["code"] == 0 && res["exist"]) {
          callback("该手机号已经被注册");
        } else {
          callback("OK");
        }
      });
      // else callback("OK");
    },
    testusername() {
      const username = this.info.username;

      var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (reg.test(username)) {
        this.tip.usernameTip = "用户名不能为中文，请重新输入";
        return;
      }
      const reg2 = /^[0-9]+$/;
      if (reg2.test(username)) {
        this.tip.usernameTip = "用户名不能为纯数字，请重新输入";
        return;
      }
      if (username.length > 20 || username.length < 6) {
        this.tip.usernameTip = "用户名长度在6~20个字符之间";
        return;
      }

      // const reg3 = /^[0-9]/;
      // if (reg3.test(username)) {
      //   this.tip.usernameTip = "用户名不能以数字开头，请重新输入";
      //   return;
      // }
      this.checkExist(username, (tip) => {
        this.tip.usernameTip = tip;
      });
    },
    testPsw() {
      this.checkPassword(this.info.psw, (tip) => {
        this.tip.pswTip = tip;
      });
    },
    phoneTest() {
      this.telCheck(this.info.phone, (tip) => {
        this.tip.phoneTip = tip;
      });
    },
    /**
     * 注册成功点击确认
     * 这里有些判断操作，如果是从我要入驻进来的，要求注册成功之后直接跳转到入驻第一步
     *
     * 在 localstorage 中存储字段 "REGISTER_SUCCESS_TARGET"
     *
     * 否则跳转到登陆并自动登陆？
     */
    registerSuccessConfirm() {
      const that = this;
      that.$router.push(that.$store.state.registerSuccessTargetPage)
    }
  },
};
</script>

<style lang="less">
.modal-image {
  width: 78px;
  height: 78px;
  margin: 0 auto;
  margin-bottom: 18px;
  background-image: url("../assets/images/zhucechenggong_icon_zc.png");
  background-position: center center;
  background-size: cover;
}
.register {
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  min-width: 18.93rem;
  .login-logo-resister {
    width: 100%;
    height: 12%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .login-logo-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .log-logo {
        height: 0.84rem;
        width: 1.25rem;
        margin-left: 1.63rem;
        background-image: url("../assets/images/homeImg/dlLogo.png");
        background-position: center center;
        background-size: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .ant-divider {
        background-color: #39BEB8 !important;
      }

      .ant-divider,
      .ant-divider-vertical {
        height: 0.26rem !important;
        width: 2px;
        margin-left: 0.2rem !important;
        margin-right: 0.2rem !important;;
        margin-top: 0.15rem;
      }

      .login-text {
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #39BEB8;
        opacity: 1;
        margin-top: 0.1rem;
      }
    }
    .promptTone{
      /*border: 1px solid yellow;*/
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      padding-top: 0.13rem;
      margin-right: 2.03rem;
      span{
        font-size: 0.2rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2A2E43;
        opacity: 1;
      }
    }
  }

  .register-form-box {
    /*border: 1px solid red;*/
    height: 81%;
    width: 100%;
    background: #F5F7FA;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
     .register-center{
         width: 10.34rem;
         height: 5.01rem;
         background: #FFFFFF;
         opacity: 1;
       .register-center-top{
         /*border: 1px solid red;*/
         width: 100%;
         margin-top: 0.1rem;
         display: flex;
         justify-content: space-between;
         align-items: center;
         .top-left,.top-right{
           font-size: 0.16rem;
           font-weight: 400;
           color: #2A2E43;
           opacity: 1;
         }
         .top-left{
           margin-left: 0.2rem;
         }
         .top-right{
           margin-right: 0.2rem;
           cursor: pointer;
         }
         .top-right:hover{
           color: #39BEB8;
         }
       }
       .register-center-matter{
         width: 100%;
         margin-top: 0.2rem;
         /*border: 1px solid red;*/
         .item {
           width: 5rem;
           font-size: 0.14rem;
           margin: 0 auto;
           transform: translateX(-0.28rem);
           text-align: right;
           line-height: 0.4rem;
           margin-bottom: 0.22rem;
           color: #454F63;
           /*border: 1px solid red;*/
           position: relative;
           #checkXieYi36652525 {
             text-align: center;
             padding-left: 0.16rem;
             width: 110%;
             display: inline-block;
             * {
               font-size: 0.14rem;
             }
             .ant-checkbox {
               font-size: 0.14rem;
             }
             font-size: 0.14rem;
             a {
               font-size: 0.14rem;
               color: #3CA7A2;
               opacity: 1;
             }
           }
           .inputBox {
             width: 3.53rem;
             float: right;
             margin-left: 0.1rem;
             height: 0.4rem;
             input {
               height: 0.4rem;
               font-size: 0.14rem;
             }
             button {
               border: none;
               float: right;
               height: 0.4rem;
               background: #f6ab1a;
               width: 1.6rem;
               text-align: center;
               color: #fff;
             }
           }
           .tip {
             position: absolute;
             top: 0.3rem;
             left: 1.47rem;
             width: 5rem;
             color: #f00;
             text-align: left;
             i {
               color: #60c7c6;
               margin-top: 0.11rem;
               font-size: 0.26rem;
             }
           }
           .tipOk {
             top: 0rem;
             left: 5.1rem;
           }
           .registerButon {
             text-align: center;
             /*border: 1px solid red;*/
             margin-left: 1.3rem;
           }
           .registerButon > button {
             background-color: #60c7c6;
             color: #fff;
             border: none;
             width: 3.46rem;
             height: 0.44rem;
             font-size: 0.16rem;
             opacity: 1;
             border-radius: 0.22rem;

           }
         }
         .sending {
           height: 0.5rem;
           width: 1rem;
           display: flex;
           justify-content: center;
           align-items: center;
           color: #fff;
           background-color: #b1b1b1;
           border-radius: 3px;
           font-size: 0.16rem;
         }
         .ant-form-item {
           margin-bottom: 0rem;
         }
         .ant-input:hover {
           border-color: #60c7c6;
         }
         .ant-select-selection:hover {
           border-color: #60c7c6;
         }
         .ant-form-item-label label {
           color: #737373;
           font-size: 0.16rem;
         }
         .ant-btn-primary {
           background-color: #60c7c6;
           border-color: #60c7c6;
         }
         .ant-btn-primary:hover,
         .ant-btn-primary:focus {
           background-color: #60c7c6;
           border-color: #60c7c6;
         }
       }
     }































  }
  .register-footer-zc {
    font-size: 0.16rem;
    text-align: center;
    display: block;
    float: none;
    position: relative;
    transform: translateX(-0.2rem);
    .top {
      width: 100%;
      margin-bottom: 0.1rem;
    }
    .bottom {
      line-height: 0.18rem;
    }
  }
  input {
    font-size: 0.16rem;
  }
  .ant-form-item {
    margin-bottom: 0rem;
  }
}
</style>















































import Vue from "vue";
import {searchHeader, searchItem} from "@/components/mallComponents";
import {http} from "@/services/http";

export default Vue.extend({
    components: {searchItem, searchHeader},
    name: "Search",
    mounted() {
    },
    created() {
        if (location.href.includes("keywords"))
            this.keywords = decodeURI(location.href.split("?keywords=")[1]);

        else {
            let str: any = location.href.split("?")[1];
            str = str.replace("&", "=").split("=");
            this.classId = str[1];
            this.menuId = str[3].split('&')[0];
            this.genre = str[4]

            this.path = localStorage.getItem("menupath") as string;
        }
        this.getBrands();
        this.getGoods();
    },
    data() {
        return {
            keywords: "",
            classId: "",
            menuId: "",
            genre: "",
            totalCount: 0,
            path: "",
            condition: {pageIndex: 1, pageSize: 15},
            goodList: [],
            beNullList: {
                list: [{data: "您可以:"}, {data: "1.建议您重新选择筛选条件。"}, {data: "2.要不您换个搜索词，我再帮您找找"}, {data: "3.没有对应养老商品分类或筛选组合下的养老商品。"}],
            },
            brandList:[],
            brandListTwo:[],
            brandListDown:0,
            brandListDownTwo:0
        };

    },
    methods: {
        openNull() {
            open("/#/mall/search?keywords=", "_self");
        },
        goTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;

        },
        getBrands() {
            //根据首页输入关键字信息查询相关信息
            if (!this.menuId) {
                http.get(`/api/v1/shop-mall/input-relation/brands`, {
                    params: {searchInput: this.keywords},
                }).then((res: any) => {
                    this.brandList = res["data"].brandList.slice(0, 12);
                    this.brandListTwo = res["data"].brandList;
                    this.brandListDown = 8
                    this.brandListDownTwo = 12
                });
            } else {
                //根据首页左侧商品分类查询信息
                http.get(`/api/v1/shop-mall/type-relation/brands`, {
                    params: {commodityType: this.menuId, typeClass: this.classId},
                }).then((res:any) => {
                    this.brandList = (res as any)["data"].brandList.slice(0, 12);
                    this.brandListTwo = res["data"].brandList;
                    this.brandListDown = 8;
                    this.brandListDownTwo = 12;
                    (this.$refs.searchHeader as any).getClassList(
                        (res as any)["data"].commodityNextTypes
                    );
                });
            }
        },
        conditionChange(selectConditions: any) {
            this.condition.pageIndex =1
            const vueEl: any = this.$refs.searchHeader;
            const obj: any = {
                commodityType: [],
                brand: [],
                priceRange: [],
                typeClass: (this.$refs.searchHeader as any).classLevelType,
                order: "",
            };
            for (let item of selectConditions) {
                if (item.type == "价格") obj["priceRange"].push(item.id);
                if (item.type == "品牌") obj["brand"].push(item.id);
                if (item.type == "商品类目") obj["commodityType"].push(item.id);
            }
            obj["priceRange"] = obj["priceRange"].join(",");
            obj["brand"] = obj["brand"].join(",");
            obj["commodityType"] = obj["commodityType"].join(",");

            (obj.order as any) = vueEl.selectSort == 0 ? "" : vueEl.selectSort;

            (this.condition as any) = {...this.condition, ...obj};
            for (let item of Object.keys(this.condition))
                if (!(this.condition as any)[item]) {
                    delete (this.condition as any)[item];
                }
            if (!obj["commodityType"]) {
                delete (this.condition as any)["typeClass"];
            }
            if (!obj["brand"]) {
                delete (this.condition as any)["brand"];
            }
            if (!obj["priceRange"]) {
                delete (this.condition as any)["priceRange"];
            }
            this.getGoods();
        },
        getGoods() {
            const params = {
                searchInput: this.keywords,
                commodityType: this.menuId,
                typeClass: this.classId,
                ...this.condition,
            };
            //（改方法目前存的bug是我传给后端的pageSize是15，他返回的数据一般都是8条或者是更少，需要后端改这个接口）
            http.get(`/api/v1/commodities/search`, {params: params}).then((res: any) => {
                this.goodList = res.data.list;
                this.totalCount = res.data.totalCount;
            });

        },
        onChange(e) {
            this.condition.pageIndex = e;
            this.getGoods();
        }
    },
});

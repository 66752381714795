

    import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({})
export default class MallSpick extends Vue {
  @Prop() public data!: any;
  items = [];

  startTime = "";
  endTime = "";
  panicBuyingName = "";

  mounted() {
    this.startTime = this.data.startTime || "00:00:00";
    this.endTime = this.data.endTime || "00:00:00";
    this.items = this.data.commodities || [];

    this.getTime();
  }
  time: any[] = [];
  stateSingl = true;
  stateSing2 = true;
  bufferTime: number = 0;
  getTime() {
    const now = new Date();
    this.stateSingl =
      now.getHours() * 60 + now.getMinutes() <
      +this.startTime.split(":")[0] * 60 + +this.startTime.split(":")[1];
    this.stateSing2 =
      now.getHours() * 60 + now.getMinutes() >=
      +this.endTime.split(":")[0] * 60 + +this.endTime.split(":")[1];
    if (this.stateSing2) {
      this.time = ["00", "00", "00"];
      return;
    }
    const bufferTime: any[] = this.stateSingl
      ? this.startTime.split(":")
      : this.endTime.split(":");
    const a = +bufferTime[0] * 3600 + +bufferTime[1] * 60 + +bufferTime[2];
    const b = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    this.bufferTime = Math.abs(a - b);
    let m = Math.floor(this.bufferTime / 60);
    this.time = [
      Math.floor(m / 60) < 10 ? "0" + Math.floor(m / 60) : Math.floor(m / 60),
      m % 60 < 10 ? "0" + (m % 60) : m % 60,
      this.bufferTime % 60 < 10
        ? "0" + (this.bufferTime % 60)
        : this.bufferTime % 60,
    ];
    let timer = setInterval(() => {
      this.bufferTime--;
      let m = Math.floor(this.bufferTime / 60);
      this.time = [
        Math.floor(m / 60) < 10 ? "0" + Math.floor(m / 60) : Math.floor(m / 60),
        m % 60 < 10 ? "0" + (m % 60) : m % 60,
        this.bufferTime % 60 < 10
          ? "0" + (this.bufferTime % 60)
          : this.bufferTime % 60,
      ];
      if (this.bufferTime == 0) {
        this.getTime();
        clearInterval(timer);
      }
    }, 1000);
  }

  // created() {
  //   http
  //     .get("/api/v1/commodities/panic-buying", {
  //       params: { pageSize: 5, pageIndex: 1 }
  //     })
  //     .then(res => {
  //     });
  // }
}

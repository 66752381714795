













































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Select, Table} from "ant-design-vue";

import {browsingHistory, orderQuantity} from "@/services/api";
import {mallUrl} from "@/services/http";

Vue.use(Table);
    Vue.use(Select);

    @Component({
        components: {},
    })
    export default class extends Vue {
        // allOrderStatus = 5;
        presentDay: any = ''
        systemDate: any = ''
        week: any = ''
        orderCount: any = {}
        collectionNumber: any = {}
        commodityOrder: any = {}
        reservationOrder: any = {}
        serviceOrder1: any = {}
        userInfo: any = {}
        browsingHistoryList: any = []

        mounted() {
            this.addDate();
            this.orderSize();
            this.browsingHistoryNum();
        }

        //服务订单
        serviceOrder(data) {
            this.$router.push({path: '/personal/myService'});
            sessionStorage.setItem("acKEY", JSON.stringify(data));
        }

        //服务退款
        ServiceRefund() {
            this.$router.push({path: '/personal/returnservice'});
        }

        //商品订单
        obligation(data) {
            this.$router.push({path: '/personal/MyGoods'});
            sessionStorage.setItem("acKEY", JSON.stringify(data));
        }

        //预约单
        reservationList(data) {
            this.$router.push({path: '/personal/myReservation'});
            sessionStorage.setItem("acKEY", JSON.stringify(data));
        }
        //医疗预约单
        medicalTreatmentList(data){
            this.$router.push({path: '/personal/reservationList'});
            sessionStorage.setItem("acKEY", JSON.stringify(data));
        }

        //商品退款
        refund() {
            this.$router.push({path: '/personal/returngoods'});
        }

        //获取当前日期
        addDate() {
            var nowDate = new Date();
            let wk = nowDate.getDay();
            var date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                day: nowDate.getDate(),
            }
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            this.week = weeks[wk]
            this.systemDate = date.year + "." + (date.month >= 10 ? date.month : "0" + date.month)
            this.presentDay = (date.day >= 10 ? date.day : "0" + date.day)
        }

        //获取订单数量
        orderSize() {
            orderQuantity({}).then(res => {
                if ((res as any).code === 0) {
                    this.collectionNumber = (res as any).data.collectionNumber;
                    this.commodityOrder = (res as any).data.commodityOrder;
                    this.reservationOrder = (res as any).data.reservationOrder;
                    this.serviceOrder1 = (res as any).data.serviceOrder;
                    this.userInfo = (res as any).data.userInfo;
                }
            });
        }

        //获取浏览记录
        browsingHistoryNum() {
            browsingHistory({}).then(res => {
                if ((res as any).code === 0) {
                    this.browsingHistoryList = (res as any).data.slice(0, 4);
                }
            });
        }

        //跳转页面
        history(value) {
            if (value.ifCommodity === 0) {
                this.$router.push(`/cookInHome/${value.contentId}`);
            } else {
                open(mallUrl + '/#/mall/detail/' + value.contentId);
            }
        }
    }

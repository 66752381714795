








































































































































































































































































































































































































// @ is an alias to /src
import {Component, Vue, Watch} from "vue-property-decorator";
import Header from "./../components/Header.vue";
import Footer from "@/components/Footer.vue";
import serviceProvider from "@/components/Recommended/serviceProvider.vue";
import nursingInstitution from "@/components/Recommended/nursingInstitution.vue";
import serviceItems from "@/components/Recommended/Items.vue";
import swiper from './Swiper.vue'
import Ylfw from "@/components/Ylfw.vue";
import Nav from "@/components/Nav.vue";
import Banner from "@/components/Banner.vue";
import {Button, Card, Carousel, Divider, Icon, Input, Menu, Tabs, Tooltip,} from "ant-design-vue";
import {
  fetchAdditionList,
  fetchBannerList,
  fetchPensionNewsList,
  fetchYlmsDetail,
  fetchYlmsList,
  recommendedRetirement,
  threeLevelList
} from "@/services/api";

declare var $: any;

    Vue.use(Button);
    Vue.use(Menu);
    Vue.use(Input);
    Vue.use(Divider);
    Vue.use(Tabs);
    Vue.use(Card);
    Vue.use(Icon);
    Vue.use(Carousel);
    Vue.use(Tooltip);

    interface BannerRes {
        bannerHref: string;
        bannerId: string;
        bannerImageUrl: string;
        isShow: boolean;
        orderNum: number;
        timeSpan: number;
    }

    interface NewOrg {
        createTime: string;
        orgId: string;
        storeName: string;
    }

    interface OrgLabels {
        labelDesc: string;
        labelId: string;
        labelName: string;
        orgId: string;
    }

    interface HotRecommond {
        addressArea: string;
        addressCity: string;
        addressProvince: string;
        addressStreet: string;
        areaSize: number;
        bedNum: number;
        coverPicture: string;
        createTime: string;
        detailAddress: string;
        fatherId: string;
        highestPrice: number;
        ifParentOrg: number;
        lowestPrice: number;
        orgAttitudeScore: number;
        orgCharacterName: string;
        orgEfficiencyScore: number;
        orgForm: string;
        orgId: string;
        orgIntro: string;
        orgLabels: OrgLabels[];
        orgOveralScore: number;
        orgRemark: string;
        orgSeries: string;
        orgServiceComplianceScore: number;
        orgTel: string;
        orgTypeName: string;
        serviceObject: string;
        specialService: string;
        storeName: string;
        updateTime: string;
    }

    interface HotService {
        addressProvince: string;
        serviceDesc: string;
        serviceOveralScore: number;
        serviceSeries: string;
        serviceName: string;
        orgIntro: string;
        categoryName: string;
        orgId: string;
        serviceStyle: string;
        shippingFee: number;
        servicePrice: number;
        addressStreet: string;
        ifRefund: number;
        markingPrice: number;
        detailAddress: string;
        storeName: string;
        serviceImage: string;
        serviceScope: string;
        serviceId: string;
        totalSale: number;
        serviceCategoryId: number;
        addressArea: string;
        addressCity: string;
    }

    interface News {
        createTime: string;
        messageCategoryName: string;
        messageId: string;
        messageRemark: string;
        messageTitle: string;
        year: string;
        month: string;
        day: string;
    }

    @Component({
        components: {
            Header,
            Footer,
            Ylfw,
            Nav,
            swiper,
            Banner,
            serviceProvider,
            serviceItems,
            nursingInstitution
        },
    })
    export default class Home extends Vue {
        scrollVisible: boolean = false;
        sizeCoefficient: number = window.innerHeight / 1080; // 屏幕高度系数
        tzcc: boolean = false;
        scrollItem: String = "ylmsScroll";
        scrollMenuTop: number = (window.outerHeight - (450 / this.sizeCoefficient)) / 2 * this.sizeCoefficient;

        banner: BannerRes[] = [];
        newOrg: NewOrg[] = [];
        hotRecommend: HotRecommond[] = [];
        hotServices: HotService[] = [];

        newsImages: string[] = [];
        tempNewsList: string[] = [];
        newsList: News[] = [];
        pattern: any = [];
        friendlyLink: any = [];
        lastTime: any = "";
        currentTime: any = "";
        timeOut: any = 10000;

        innerHeight: number = window.innerHeight;

        computeScrollMenuTop: number = 0;

        @Watch("innerHeight", {immediate: true, deep: true})
        listenHeight(newVal, oldVal) {
            // 监听页面的高度
            const that = this;
            // 计算函数
            // F(x) = (11538) * Math.pow(x, 0) + (-57771.1) * Math.pow(x, 1) + (106620) * Math.pow(x, 2) + (-84412) * Math.pow(x, 3) + (24401.4) * Math.pow(x, 4)
            let x = newVal / 1080;
            let Fx = (11538) * Math.pow(x, 0) + (-57771.1) * Math.pow(x, 1) + (106620) * Math.pow(x, 2) + (-84412) * Math.pow(x, 3) + (24401.4) * Math.pow(x, 4);
            if (x <= 0.55) Fx = 100; // 设置最小值
            that.computeScrollMenuTop = Fx;
        }

        innerWidth: number = 0;
        computeScrollMenuLeft: number = 0;

        @Watch("innerWidth", {immediate: true, deep: true})
        listenWidth(newVal, oldVal) {
            // 监听页面的宽度
            const that = this;
            const x = newVal || window.innerWidth;
            let Fx = (-577.097) * Math.pow(x, 0) + (0.951613) * Math.pow(x, 1) + (0) * Math.pow(x, 2)
            if (x < 1340) Fx = 680
            that.computeScrollMenuLeft = Fx;
        }

        deleteOrg(str) {
            return str.replace("机构", "");
        }

        getNewsContent(Content) {
            let str: string = Base64.decode(Content);
            // .replace(/<br>/g, "")
            // .replace(/<p>/g, "")
            // .replace(/<\/p>/g, "")
            // .trim();
            let regex = new RegExp("<.+?>", "g");
            let strOutput = str.replace("&nbsp;", "").replace(regex, "");
            return strOutput;
        }

        // 养老模式查看更多
        seeMore(val) {
            this.$router.push("/pensionInstitution/" + val.ylformId);
        }

        jumpToArticle(id) {
            this.$router.push("/artical/" + id);
        }

        created() {
            const that = this
            that.getPensionNews();
            window.onresize = () => {
                return (() => {
                    that.innerHeight = window.innerHeight
                    that.innerWidth = window.innerWidth
                })()
            }
        }

        mounted() {
            this.friendlyLink = [
                {img: require('@/assets/images/home/tjdx_logo@2x.png'), url: 'http://www.tju.edu.cn'},
                {img: require('@/assets/images/home/kjd_logo@2x.png'), url: 'http://www.feellike21.com/'},
                {img: require('@/assets/images/home/zjdx_logo@2x.png'), url: 'http://www.zju.edu.cn'},
                {img: require('@/assets/images/home/IT_institute_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/xw_hospital_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/pt_logo@2x.png'), url: 'http://www.cpit.com.cn'},
                {img: require('@/assets/images/home/anychenk_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/brilliange_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/xk_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/wfy_logo@2x.png'), url: 'http://www.rcwanfuyuan.com/index.html'},
                {img: require('@/assets/images/home/zdwx_logo@2x.png'), url: ''},
                {img: require('@/assets/images/home/pakj_logo@2x.png'), url: ''},
            ];
            window.addEventListener("scroll", this.handleScroll);
            let params = {
                pageSize: 10,
                pageIndex: 1,
            };

            const cityId = parseInt(localStorage.getItem("provinceId") || "0", 10);

            fetchYlmsList({cityId}).then((res) => {
            });

            // 热门推荐
            // fetchHotInstitution({
            //   cityId,
            //   limit: 6
            // }).then(res => {
            //
            //   if (res.data && res.data.orgList && res.data.orgList.length) {
            //     res.data.orgList.forEach((item: HotRecommond) =>
            //       this.hotRecommend.push(item)
            //     );
            //   }
            // });

            // fetchHotService({
            //   cityId,
            //   limit: 6
            // }).then(res => {
            //
            //   if (res.data && res.data.serviceList && res.data.serviceList.length) {
            //     res.data.serviceList.forEach((item: HotService) =>
            //       this.hotServices.push(item)
            //     );
            //   }
            // });

            // 获取最新入住机构
            fetchAdditionList().then((res) => {
                if (res.data && res.data.latestOrgList && res.data.latestOrgList.length) {
                    res.data.latestOrgList.forEach((item: NewOrg) =>
                        this.newOrg.push(item)
                    );
                }
            });

            // 获取首页banner
            fetchBannerList({
                bannerLocation: 55,
                platformType: 244,
            }).then((res) => {
                if (res.data && res.data.bannerList && res.data.bannerList.length) {
                    res.data.bannerList.forEach((item: BannerRes) =>
                        this.banner.push(item)
                    );
                }
            });

            // 指定养老模式详情
            fetchYlmsDetail({
                pageIndex: 1,
                pageSize: 10,
                cityId: cityId,
                formDicId: 206,
            }).then((res) => {
            });

            //获取首页养老模式
            recommendedRetirement().then((res) => {
                if (res.data) {
                    this.pattern = res.data;
                }
            });
            // 获取用户代币
            // fetchUserCoin().then(res => {
            //
            // });

            // 获取省市县地区(如果有用到该方法打开api.ts查fetchProvinceName这个方法，不在用下面方法)
            threeLevelList({}).then((res) => {
                window.sessionStorage.setItem(
                    "allAreaData",
                    JSON.stringify(res.data.provinceList)
                );
            });
        }

        cahngNews(event) {
            this.newsTypeKey = event;
        }

        updated() {

        }

        toPage(item) {
            if (item.serOrOrg) {
                this.$router.push(`/organizationDetail/${item.orgId}`)
            } else {
                this.$router.push(`/orgDetail/${item.orgId}`);
            }

        }

        // 获取养老资讯推荐
        newsTypeKey: any;

        getPensionNews() {
            // 养老资讯推荐
            this.newsList.length = 0;
            fetchPensionNewsList().then(({data, code}): any => {
                if (code == 0) {
                    this.newsList = data.recommendedMessages;
                    this.newsTypeKey = this.newsList[0]["messageCategoryId"];
                }
            });
        }

        getTimeByCreate(creatTime) {
            if (creatTime) return creatTime.split(" ")[0].split("-");
            else return ["1975", "01", "01"];
        }

        scrollClick(id: string) {

            let nav: any = document.getElementsByClassName('nav')[0]
            let yqlj: any = document.getElementsByClassName('main-yqlj')[0];

            let ylmsScroll: any = document.getElementById('ylms-center-box')
            let rmtjScroll: any = document.getElementById('rmtj')
            let ylyztScroll: any = document.getElementById('ylyzt')
            let ylfwScroll: any = document.getElementById('ylfw')
            let ylzxScroll: any = document.getElementById('ylzx')
            let zxrzScroll: any = document.getElementById('zxrz')

            let offsetTop = 150,  // 滚动值
                scrollTop = 0,
                sizeCoefficient = (window.innerHeight / 1080) // 尺寸系数，1080 = 1
            switch (id) {
                case 'ylms':
                    scrollTop = ylmsScroll.offsetTop - nav.offsetHeight;
                    break;
                case 'rmtj':
                    scrollTop = rmtjScroll.offsetTop - nav.offsetHeight;
                    break;
                case 'ylyzt':
                    scrollTop = ylyztScroll.offsetTop - nav.offsetHeight;
                    break;
                case 'ylfw':
                    scrollTop = ylfwScroll.offsetTop - nav.offsetHeight;
                    break;
                case 'ylzx':
                    scrollTop = ylzxScroll.offsetTop - nav.offsetHeight;
                    break;
                case 'zxrz':
                    scrollTop = zxrzScroll.offsetTop - nav.offsetHeight;
                    break;
            }
            // let scrollTop = $(`#${id}`).offset().top - (offsetTop * sizeCoefficient);
            $("html,body").animate(
                {
                    scrollTop: scrollTop,
                },
                600
            );
        }

        handleScroll(e: any) {
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            // 1080下 滚动超过 360 即显示
            const scrollHeightCoefficient = scrollTop / this.sizeCoefficient

            let nav: any = document.getElementsByClassName('nav')[0]
            let yqlj: any = document.getElementsByClassName('main-yqlj')[0];

            let ylmsScroll: any = document.getElementById('ylms-center-box')
            let rmtjScroll: any = document.getElementById('rmtj')
            let ylyztScroll: any = document.getElementById('ylyzt')
            let ylfwScroll: any = document.getElementById('ylfw')
            let ylzxScroll: any = document.getElementById('ylzx')
            let zxrzScroll: any = document.getElementById('zxrz')

            let scrollTop2 = scrollTop + nav.offsetHeight + 14
            if (scrollTop2 > ylmsScroll.offsetTop) {
                this.scrollVisible = true;

                if (scrollTop2 >= ylmsScroll.offsetTop) {
                    this.scrollItem = "ylmsScroll";
                }
                if (scrollTop2 >= rmtjScroll.offsetTop) {
                    this.scrollItem = "rmtjScroll";
                }
                if (scrollTop2 >= ylyztScroll.offsetTop) {
                    this.scrollItem = "ylyztScroll";
                }
                if (scrollTop2 >= ylfwScroll.offsetTop) {
                    this.scrollItem = "ylfwScroll";
                }
                if (scrollTop2 >= ylzxScroll.offsetTop) {
                    this.scrollItem = "ylzxScroll";
                }
                if (scrollTop2 >= zxrzScroll.offsetTop) {
                    this.scrollItem = "zxrzScroll";
                }
                if (scrollTop2 > yqlj.offsetTop) {
                    this.scrollItem = "dbScroll";
                }

            } else {
                this.scrollVisible = false;
            }
        }

        tuijianChange() {
        }

        jump(path: string) {
            this.$router.push(path);
        }

        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        }

        jumpByType() {
            this.$router.push(`/pensionNews/${this.newsTypeKey}`);
        }

        handleToMap() {
            let {href} = this.$router.resolve({path: "/pensionMap"});
            window.open(href, "_blank");
        }

        fhsjafknbfasf = "1";

        viewMoreRecommonds() {
            this.$router.push("/servicePage?moreType=" + this.fhsjafknbfasf);
        }

    }

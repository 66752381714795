







































































































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Divider, Input, message} from "ant-design-vue";
import {
    confirmTheAccount,
    fetchCollection,
    userDetermine,
    userLogin,
    userLoginByPhone,
    userLoginPassword,
    wxUnregistered
} from "@/services/api";
import loginFooter from "@/components/loginFooter.vue";

import {baseUrl, request} from "@/services/http";

Vue.use(Input);
Vue.use(Divider);

@Component({
    components: {
        loginFooter,
    },
})
export default class extends Vue {
    visible=false;
    wechatQRCode='';
    baseUrl = baseUrl;
    currentChecked = 0;
    weChatState = 0;
    nextStepNum = 0;
    remainTime = 60;
    username = "";
    password = "";
    userMobile = "";
    userSetName = "";
    userSetNameTip = "";
    userPassword = "";
    vcodes = "";
    isSending = false;
    cartoon = false;
    cartoonOne = true;
    checked = false;
    phoneTip = "";
    phoneTipTwo = "";
    passwordTip = "";
    passwordSetTip = "";
    vcodesTip = "";
    vcodesTipTwo = "";
    buttonContent = "获取短信验证码";
    ifRegister = false;
    register: any;
    logImg = require("../assets/images/image_dl.png");
    coverImgUrl = "";

    handleSendCode() {
        if (!this.userMobile) {
            this.phoneTip = "请输入手机号";
            return;
        }
        const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (!reg.test(this.userMobile)) {
            this.phoneTip = "请输入正确的手机号！";
            return;
        }
        if (this.isSending) {
            return;
        }
        request(`${baseUrl}/userapp/logincodequick/${this.userMobile}`)
            // getVerificationCode(this.userMobile)
            .then((res: any) => {
                if (res && res.code === 0) {
                    this.register = res.data.register
                    this.vcodesTip = "验证码已发送,5分钟内输入有效";
                    this.remainTime = 60;
                    this.isSending = true;
                    this.ifRegister = res.data.register;
                    const t = setInterval(() => {
                        this.remainTime -= 1;
                        this.buttonContent = `${this.remainTime}s后重新获取`;
                        if (this.remainTime == 0) {
                            clearInterval(t);
                            this.buttonContent = `重新获取`;
                            this.isSending = false;
                        }
                    }, 1000);
                } else {
                    // message.error(res.msg);
                    this.vcodesTip = "验证码获取失败";
                }
            });
    }

    orgId = "";
    fromLocation = "";
    keypressEvent: any;

    mounted() {
        this.keypressEvent = (e) => {
            if (e.key == "Enter") {
                this.handleLogin();
            }
        };

        document.body.addEventListener("keypress", this.keypressEvent, false);
        //获取登录页面图片
        this.loginImg();
    }

    created() {
        this.orgId = location.href.split("login?collectionOrgid=")[1];
        //enter键快捷登录方法
        // var t = this;
        // document.onkeydown = function(e) {
        //   var key;
        //   if (window.event == undefined) {
        //     key = e.keyCode;
        //   } else {
        //     key = (window.event as any).keyCode;
        //   }
        //   //enter的ASCII码是13
        //   if (key == 13) {
        //     t.submiLogin();
        //   }
        // };
    }

    loginImg() {
        request(`${baseUrl}/api/v1/loginbg`).then((res: any) => {
            if (res && res.code === 0) {
                this.coverImgUrl = baseUrl + res.image;
            } else {
                message.error(res.msg);
            }
        });
    }

    //同意并注册
    handleLoginRegister() {

        let reg: any = /^(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,20}$/;
        if (!this.userPassword) {
            this.passwordTip = "请输入密码";
            return;
        }
        if (!reg.test(this.userPassword)) {
            this.passwordTip = "密码长度8-20个位(密码必须包括数字和英文字母)";
            return;
        }

        userLoginPassword({
            phoneNumber: this.userMobile,
            userpwd: this.userPassword,
            vcodes: this.vcodes,
        }).then((res) => {
            if (res && res.code === 0) {
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                if (this.fetchCollection()) return;
                // if (this.loginBeforePath == "/register" || this.loginBeforePath == "/forgetPasswordNew")
                this.$router.push(this.$store.state.registerSuccessTargetPage)
            } else {
                message.error(res.msg);
            }
        })
    }

    //微信绑定（下一步）
    nextStepWechat() {
        // this.nextStepNum = 1;
        const loginBeforePath = localStorage.getItem("loginBeforePath");
        if (this.weChatState != 0) {
            if (!this.userMobile) {
                this.phoneTipTwo = "请输入手机号";
                return false;
            }
            if (!this.vcodes) {
                this.vcodesTipTwo = "请输入验证码";
                return false;
            }
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.userMobile)) {
                this.phoneTipTwo = "请输入正确的手机号";
                return false;
            }
            if (this.ifRegister) {
                userDetermine({
                    mobile: this.userMobile,
                    validateCode: this.vcodes,
                }).then((res) => {
                    this.nextStepNum = 2;
                    this.userSetName = res.userName;
                })
            } else {
                this.userSetName = 'fytl'+this.userMobile;
                this.nextStepNum = 1;
            }

            return;

        }

    }

    //微信绑定（第二步）
    lastEnter() {
        if (!this.userSetName) {
            this.userSetNameTip = "用户名不能为空";
            return false;
        }
        let reg: any = /^(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{8,20}$/;
        if (!this.password) {
            this.passwordSetTip = "请设置密码";
            return;
        }
        if (!reg.test(this.password)) {
            this.passwordSetTip = "密码长度8-20个位(密码必须包括数字和英文字母)";
            return;
        }
        wxUnregistered({
            mobile: this.userMobile,
            validateCode: this.vcodes,
            userName: this.userSetName,
            password: this.password,
            oAuthId: this.dataOAuthId,
        }).then((res) => {
            if(res.code==0){
                localStorage.setItem("userInfo", JSON.stringify(res.info));
                this.$router.push(this.$store.state.registerSuccessTargetPage)
            }

        })
    }

    //微信绑定（第二步 确认）
    countersign() {
        confirmTheAccount({
            mobile: this.userMobile,
            validateCode: this.vcodes,
            oAuthId: this.dataOAuthId,
        }).then((res) => {
            if(res.code==0){
                localStorage.setItem("userInfo", JSON.stringify(res.info));
                this.$router.push(this.$store.state.registerSuccessTargetPage)
            }
        })
    }

    fetchCollection(operation = 0) {
        const href = sessionStorage.getItem("pay");
        if (href) {
            open(href, "_self");
            window.close();
            return "pay";
        }

        const params = {
            itemId: this.orgId,
            itemType: 238,
        };
        fetchCollection({
            ...params,
            operation: operation,
        }).then((res) => {
            // resolve(res);
        });
    }

    loginBeforePath: any = "";

    //登录提交方法
    handleLogin() {
        const loginBeforePath = localStorage.getItem("loginBeforePath");
        if (!this.ifRegister && this.currentChecked == 0) {
            if (!this.userMobile) {
                this.phoneTip = "请输入手机号";
                return false;
            }
            if (!this.vcodes) {
                this.vcodesTip = "请输入验证码";
                return false;
            }

            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.userMobile)) {
                this.phoneTip = "请输入正确的手机号";
                return false;
            }
            request(
                `${baseUrl}/userapp/checkmobile/${this.userMobile}/${this.vcodes}`
            ).then((res: any) => {
                if (res["code"] == 0) {
                    this.currentChecked = 2;
                    this.loginBeforePath = loginBeforePath;
                } else {
                    this.vcodesTip = res.msg;
                    //    "短信验证码错误或已失效，请重新获取";
                }
            });

            return;
        }
        if (this.currentChecked === 0) {
            if (!this.userMobile) {
                this.phoneTip = "请输入手机号";
                return false;
            }
            if (!this.vcodes) {
                this.vcodesTip = "请输入验证码";
                return false;
            }

            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.userMobile)) {
                this.phoneTip = "请输入正确的手机号";
                return false;
            }
            //获取手机验证码接口登录
            userLoginByPhone({
                userMobile: this.userMobile,
                vcodes: this.vcodes,
            }).then((res) => {
                if (res && res.code === 0) {
                    //清空enter键赋值，登录成功后禁止按enter键再次重新登录
                    document.body.removeEventListener(
                        "keypress",
                        this.keypressEvent,
                        false
                    );
                    res.data.userName = res.data.username;
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    // this.$router.push("/");
                    if (this.fetchCollection()) return;
                    if (
                        loginBeforePath == "/register" ||
                        loginBeforePath == "/forgetPasswordNew"
                    )
                      this.$router.push(this.$store.state.registerSuccessTargetPage)
                    else this.$router.back();
                } else {
                    // message.error(res.msg);
                    this.vcodesTip = res.msg;
                    // "短信验证码错误或已失效，请重新获取";
                }
            });
        } else {
            if (!this.username) {
                this.usernameTip = "请输入用户名";
                return false;
            }
            if (!this.password) {
                this.pswTip = "请输入密码";
                return false;
            }
            //登录接口（账号密码登录）
            userLogin({
                username: this.username,
                password: this.password,
            }).then((res) => {
                if (res && res.code === 200) {
                    //清空enter键赋值，登录成功后禁止按enter键再次重新登录
                    (document as any).onkeydown = undefined;
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    if (this.fetchCollection()) return;
                    document.body.removeEventListener(
                        "keypress",
                        this.keypressEvent,
                        false
                    );
                    if (loginBeforePath == "/register" || loginBeforePath == "/forgetPasswordNew"){
                        this.$router.push(this.$store.state.registerSuccessTargetPage)
                    }else{
                        this.$router.back();
                    }
                } else {
                    this.usernameTip2 = res.msg;
                    //   "用户名和密码不匹配，请重新输入";
                    // message.error(res.msg);
                }
            });
        }
    }

    changePwd(e: any) {
        this.password = e.target.value;
        this.pswTip = "";
    }

    changeUsername(e: any) {
        this.username = e.target.value;
    }

    changeVcodes(e: any) {
        this.vcodesTip = "";
        this.vcodes = e.target.value;
    }

    usernameTip = "";
    usernameTip2 = "";
    pswTip = "";
    dataOAuthId = ''

    testUswename() {
        if (!this.username) {
            this.usernameTip = "请输入用户名";
        }
    }

    changeMobile(e) {
        this.telCheck(this.userMobile, (msg) => {
            this.phoneTip = msg;
        });
    }

    telCheck(value, callback) {
        // ^\d{15}|\d{18}$
        if (!value) {
            callback("请输入手机号码");
            return;
        }
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (!reg.test(value)) {
            callback("请输入正确格式的手机号码");
            return;
        }
    }

    jumpToHome() {
        this.$router.push("/");
    }

    /**
     * 获取微信登录扫码链接
     */
    getWxLoginUrl() {
        let websocket = (this as any).$websocket;
        websocket.onSend("01_wx_login_url", msg => {
            if (msg && msg.code == 0) {
                switch (msg.socketCode) {
                    //(wx_login_url)返回这个调用微信二维码
                    case "wx_login_url": {
                        if (msg.message.indexOf("https://") >= 0) {
                            // this.visible=true;
                            // this.wechatQRCode=msg.message;
                            window.open(msg.message)
                        }
                    }
                        break
                    //（返回wx_login_result）说明已经注册判断扫码登录结果是成功还是失败
                    case "wx_login_result": {
                        if (msg && msg.data) {
                            if (msg.data.registered) {
                                if (msg.data && msg.data.token) {
                                    localStorage.setItem("userInfo", JSON.stringify(msg.data));
                                }

                                const loginBeforePath = localStorage.getItem("loginBeforePath");
                                //清空enter键赋值，登录成功后禁止按enter键再次重新登录
                                document.body.removeEventListener(
                                    "keypress",
                                    this.keypressEvent,
                                    false
                                );
                                if (this.fetchCollection()) return;
                                if (
                                    loginBeforePath == "/register" ||
                                    loginBeforePath == "/forgetPasswordNew"
                                )
                                    this.$router.push(this.$store.state.registerSuccessTargetPage)
                                else this.$router.back();
                            } else {
                                this.weChatState = 1;
                                this.dataOAuthId = msg.data.oAuthId;

                            }

                        }

                    }
                }
            }
        })
    }

}

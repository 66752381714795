






























































































































































































































import {Component, Vue} from "vue-property-decorator";
import beNull from "@/components/beNull.vue";
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import personalHeaderList from "@/components/personalHeader.vue";
import OrgDetailHeader from "@/components/OrgDetailHeader.vue";
import facilitatorItem from "@/components/facilitatorItem.vue";
import SubServiceItem from "@/components/SubServiceItem.vue";
import ServiceComment from "@/components/ServiceComment.vue";

import AMapLoader from "@amap/amap-jsapi-loader";
import {
    organizationDetail,
    OrganizationOrServiceProvider,
    OrganizationOrServiceProviderCityIds,
    orgCommentsList,
    sameTypeList
} from "@/services/api";
import {Mutation} from "vuex-class";
import nearbyItem from "./nearbyItem.vue";

declare var $: any;

@Component({
    components: {
        Nav,
        Footer,
        personalHeaderList,
        OrgDetailHeader,
        facilitatorItem,
        SubServiceItem,
        ServiceComment,
        beNull,
        nearbyItem,
    },
})
export default class extends Vue {
    @Mutation SET_ORGFORM;
    isHide = false;
    pagination: any = {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    };
    AMap: any;
    showContent: any = [];
    beNullList: any = {};
    hiddenContent: any = [];
    orgId: any = "";
    commentsList = [];
    cityList: any = [];
    categoryIds = []; // 第一次加载之后存储的服务类型id
    servicesAvailable = [];
    serviceDetailsList = [];
    orgOveralScore = 0;
    orgDetail = {};
    comments = [];
    nearbyOrgList = [];
    priceList: any = [];
    nearStore = [];
    serviceCompliance = 0;
    organizationAttitude = 0;
    organizationSpeed = 0;
    cityNumber: any = '0';
    genreNumber: any = '0';
    priceNumber: any = '0';
    lowestPrice: any = '0';
    highestPrice: any = '0';
    categoryId: any = '';
    loading: any = true;


    onchange(page: any, pageSize: any) {
        window.scrollTo({top: 0});

        this.pagination.pageIndex = page;
        this.orgCommentsList(this.orgId);
    }

    orgChildNodesShowPage = [];

    get _location() {
        const addressProvince = this.orgDetail["addressProvince"] || "";
        const addressCity = this.orgDetail["addressCity"] || "";
        const addressArea = this.orgDetail["addressArea"] || "";
        const addressStreet = this.orgDetail["addressStreet"] || "";

        const detailAddress = this.orgDetail["detailAddress"] || "";

        return (
            addressProvince +
            addressCity +
            addressArea +
            addressStreet +
            detailAddress
        );
    }

    thirdCategoryShowPage = [];

    orgCommentsList(orgId) {
        orgCommentsList({
            ...this.pagination,
            orgId: orgId,
        }).then((res) => {
            this.orgOveralScore = res.data.orgOveralScore;
            this.serviceCompliance = res.data.serviceCompliance;
            this.organizationAttitude = res.data.organizationAttitude;
            this.organizationSpeed = res.data.organizationSpeed;
            this.comments = res.data.comments;
            this.pagination.total = res.data.totalCount;

        });
    }

    sameTypeList(params) {
        sameTypeList({...params}).then((res) => {
        });
    }

    content = "";

    get hiddenContentBoolen() {
        if (this.content != null) {
            let a: any = this.content.match(/<p>/g) || [];
            return a.length > 10 || this.content.length > 750 || this.shouheight >= 228;
        } else {
            return false
        }


    }

    checkStatus = "";
    //40 - 审核中
    // 41 - 审核成功
    // 42 - 审核不通过

    shouheight = 0;

    mounted() {
        const that = this
        that.orgId = window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1,
            window.location.href.length
        );

        if (localStorage.getItem("provinceId") === null) {
            const val: any = 0;
            localStorage.setItem("provinceId", val);
        }

        organizationDetail({orgId: that.orgId}).then((res) => {
            if (res && res.code == 0) {
                if (res.data.onStatus === false) {
                    this.checkStatus = '88'
                } else {
                    var a = this.orgDetail["checkStatus"] || "41";
                    this.checkStatus = a.toString()
                }
                that.orgDetail = res.data;
                that.orgChildNodesShowPage = that.orgDetail["orgChildNodes"];
                that.thirdCategoryShowPage = that.orgDetail["thirdCategory"];
                that.orgChildNodesShowPage = that.orgChildNodesShowPage.slice(0, 6);
                // that.thirdCategoryShowPage = that.thirdCategoryShowPage.slice(0, 10);
                that.nearbyOrgList = that.orgDetail["nearbyOrgList"].slice(0, 6);
                that.content = that.orgDetail["orgIntro"];

                that.SET_ORGFORM((that as any).orgDetail.orgFormJson);
                that.initMap();
                setTimeout(() => {
                    const el: any = document.querySelector(".detailInfo-snas-one");
                    that.shouheight = el.offsetHeight;
                });
            }
            this.loading = false
        }).then(_ => {
            // 初始化服务项目筛选条件

            //区间价格
            that.priceList = [
                {id: "0", value: '不限'},
                {id: "1", value: '0-200元'},
                {id: "2", value: '200-500元'},
                {id: "3", value: '500-1000元'},
                {id: "4", value: '1000-3000元'},
                {id: "5", value: '3000-6000元'},
                {id: "6", value: '6000以上'}
            ];

            // 筛选列表的服务项目
            let servicesAvailableList = (that.orgDetail as any)['thirdCategory'].map(i => {
                return {
                    orgCategoryId: i.categoryId,
                    categoryName: i.categoryName
                }
            })
            // 第一次请求不需要设置类型，但是在里面需要设置地区
            that.categoryIds = servicesAvailableList.map(i => i.orgCategoryId);
            servicesAvailableList.unshift({orgCategoryId: '0', categoryName: '不限'})
            that.servicesAvailable = servicesAvailableList;

            // 获取评论数据
            that.orgCommentsList(that.orgId);

        }).then(_ => {
            // 获取地区
            that.cityList = []
            that.cityNumber = parseInt((localStorage as any).getItem("provinceId"))
            OrganizationOrServiceProviderCityIds({
                orgId: that.orgId,
                cityId: that.cityNumber,
                categoryIds: that.categoryIds.join(','),
            }).then(res => {
                if (res && res.code == 0) {
                    // 开通城市，第一次初始化
                    that.cityList = res.cityIds;
                    that.cityList.unshift({key: '0', value: '不限'});
                }
            }).then(_ => {
                //获取服务项目或者机构项目
                that.reload()
            });
        })
        this.beNullList = {
            title: '很抱歉，没有找到相应服务商信息',
            list: [{data: "您可以:"}, {data: "1.该服务商可能已经下架或重新审核中，您可以联系平台咨询。"}, {data: "2.检查刚才的输入"}, {data: "3.去其他的地方逛逛"}],
            path: '/personal/myCollection?type=3',
            pathName: '收藏的服务商'
        }
    }

    async initMap() {
        const AMap = await AMapLoader.load({
            key: "c4ac5dbaa55b2994a57c6b369f28af53",
            plugins: ["AMap.Geocoder"],
            version: "1.4.15",
        });
        const map = new AMap.Map("Map");
        const geocoder = new AMap.Geocoder({
            city: this.orgDetail["addressCity"],
            batch: true,
        });
        geocoder.getLocation(this.orgDetail["detailAddress"], (state, result) => {
            if (state === "complete" && result.geocodes.length) {
                const markers: any[] = [];
                for (let i = 0; i < result.geocodes.length; i += 1) {
                    var markerContent = `<div class="marker" ><img src="http://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png" alt=""></div>`;
                    var marker = new AMap.Marker({
                        position: result.geocodes[i].location,
                        content: markerContent,
                    });
                    markers.push(marker);
                }

                map.add(markers);
                map.setFitView(markers);
            }
        });
    }

    //获取服务项目或者机构项目方法
    organizationOrService(categoryId) {
        //获取服务项目或者机构项目方法
        OrganizationOrServiceProvider({
            orgId: this.orgId,
            categoryIds: categoryId.join(','),
            cityId: this.cityNumber | 0,
            lowestPrice: this.lowestPrice | 0,
            highestPrice: this.highestPrice | 0,
        }).then((res) => {
            if (res && res.code == 0) {
            }
            this.serviceDetailsList = res.list;
        })
    }

    //获取全部数据
    entire() {
        this.$router.go(0)
    }


    toDetail(item) {
        if (item.ifParentOrg === 1) {
            this.$router.push(`/orgDetail/${item.orgId}`);
        } else {
            this.$router.push({
                path: "/servieItemDetail",
                query: {
                    orgId: item.orgId,
                    title: item.storeName,
                },
            });
        }
    }



    scrollClick(index: string) {
        $("html,body").animate({scrollTop: $(`#${index}`).offset().top - 170,}, 600);
        // $("html,body").animate({ scrollTop:$(`#${id}`).height()*4.8}, 600);
    }

    //获取city事件
    cityEvent(val) {
        this.cityNumber = val.key
        this.reload()
    }

    //获取服务类型事件
    genreEvent(val) {
        this.genreNumber = val.orgCategoryId;
        this.reload()
    }

    /**
     * 重新加载数据
     */
    reload() {
        // 如果类型为0 就默认加载所有的类型的数据
        this.organizationOrService(this.genreNumber != '0' ? [this.genreNumber] : this.categoryIds)
    }

    //服务价格
    priceEvent(val: any) {
        this.priceNumber = val.id
        switch (this.priceNumber) {
            case '0':
                this.lowestPrice = 0;
                this.highestPrice = 0;
                break;
            case '1':
                this.lowestPrice = 0;
                this.highestPrice = 200;
                break;
            case '2':
                this.lowestPrice = 200;
                this.highestPrice = 500;
                break;
            case '3':
                this.lowestPrice = 500;
                this.highestPrice = 1000;
                break;
            case '4':
                this.lowestPrice = 1000;
                this.highestPrice = 3000;
                break;
            case '5':
                this.lowestPrice = 3000;
                this.highestPrice = 6000;
                break;
            case '6':
                this.lowestPrice = 6000;
                this.highestPrice = 0;
                break;
        }
        this.reload()
    }
}

<template>
    <div class="pensionService">
        <Header/>
        <Nav/>
        <div class="service-banner-pensionService">
            <div class="banner-image">
                <div class="banner-setting"></div>
                <div class="bj-img">
                    <div>关注养老模式</div>
                    <span>养老服务是为老年人提供必要的生活服务，满足其物质生活和精神生活的基本需求，有效满足老年人多样化、多层次养老服务需求。让所有老人老有所养、老有所依、老有所乐、老有所安。</span>
                </div>
            </div>
        </div>
        <div class="pensionService-center">
            <div class="pensionService-single" v-for="(item, index) in ylfwContent" :key="index">
                <div class="single-left" @click="() => $router.push(`/pensionInfo/${item.serviceClassificationId}`) ">
                    <img :class="item.thirdTypeList.length>7?'img2':'img1'" :src="item.classificationPhoto">
                    <div :class="item.thirdTypeList.length>7?'left-bj2':'left-bj'">
                        <span> {{item.classificationName}}</span>
                    </div>
                </div>
                <div class="single-right">
                    <!--                    item.thirdTypeList-->
                    <div class="intro" v-for="(val, index) in item.thirdTypeList" :key="index"
                         @click="goPath('/pensionFood', val.serviceClassificationId, $event)">
                        <div class="name" :title="val.classificationName">{{val.classificationName}}</div>
                        <div class="title" :title="val.description">{{val.description}}</div>
                    </div>
                </div>


            </div>

        </div>
        <Footer/>
    </div>
</template>

<script>
    import Vue from "vue";
    import Ylfw from "@/components/Ylfw";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Header from "@/components/Header";
    import {Breadcrumb, Pagination} from "ant-design-vue";
    import {fetchMenuYlfwList, pensionServiceRecommendList} from "@/services/api";

    Vue.use(Breadcrumb);
    Vue.use(Pagination);

    export default {
        name: "pensionService",
        components: {
            Ylfw,
            Header,
            Nav,
            Footer
        },
        data() {
            return {
                ylfwContent: {}
            };
        },
        mounted() {
            // this.pensionServiceRecommendList()
            this.getYlmsList({
                pageSize: 999999,
                pageIndex: 1
            });
        },
        methods: {
            getYlmsList(params) {
                fetchMenuYlfwList(params).then(res => {
                    if (res.data && res.data.secondTypeList) {
                        this.ylfwContent = res.data.secondTypeList;
                    }
                });
            },
            goPath(path, serviceClassificationId, e) {
                e.stopPropagation();
                this.$router.push({
                    path: path,
                    query: {serviceClassificationId: serviceClassificationId}
                });
            }
        }
    };
</script>

<style lang="less">
    .pensionService {
        width: 100%;
        height: 100%;

        .service-banner-pensionService {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5rem;

            .banner-image {
                height: 100%;
                width: 100%;
                background-image: url("./../assets/images/homeImg/组 2150@2x.png");
                background-size: cover;
                background-position: center center;
                display: flex;
                align-items: center;
                justify-content: center;

                .banner-setting {
                    width: 9.85rem;
                    height: 2.67rem;
                    background: #000000;
                    opacity: 0.3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    position: relative;
                }

                .bj-img {
                    width: 8.45rem;
                    height: 1.63rem;
                    /*background-image: url("./../assets/images/homeImg/组 2141@2x.png");*/
                    /*background-size: cover;*/
                    /*background-position: center center;*/
                    z-index: 10;
                    position: absolute;
                    opacity: 0.9;
                    user-select: none;

                    div {
                        font-size: 0.36rem;
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                        margin-bottom: 0.02rem;

                    }

                    span {
                        display: flex;
                        text-align: left;
                        font-size: 0.22rem;
                        color: #fff;
                        font-weight: 400;
                        line-height: 0.35rem;
                        padding-left: 0.05rem;
                    }
                }
            }
        }

        .pensionService-center {
            width: 13.85rem;
            margin: 0 auto;
            /*border: 1px solid red;*/
            margin-bottom: 0.4rem;

            .pensionService-single {
                width: 13.85rem;
                min-height: 0.87rem;
                background: #FFFFFF;
                border: 1px solid #E4E9F2;
                opacity: 1;
                margin-top: 0.3rem;
                border-radius: 0.12rem;
                display: flex;

                .single-left {
                    width: 1.18rem;
                    min-height: 0.85rem;
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    background-color: #7CB7B7;
                    border-radius: 0.11rem;
                    /*border: 1px solid red;*/

                    .img1 {
                        width: 1.18rem;
                        height: 0.91rem;
                        border-radius: 0.11rem;

                    }

                    .img2 {
                        width: 1.18rem;
                        height: 0.91rem;
                    }

                    .left-bj {
                        width: 1.18rem;
                        height: 0.27rem;
                        background: #FFFFFF;
                        opacity: 0.72;
                        border-radius: 0 0 0.11rem 0.11rem;
                        position: absolute;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-size: 0.14rem;
                            font-weight: bold;
                            color: #2A2E43;
                            opacity: 1;
                        }
                    }

                    .left-bj2 {
                        width: 1.18rem;
                        height: 0.27rem;
                        opacity: 0.72;
                        border-radius: 0 0 0.11rem 0.11rem;
                        position: absolute;
                        top: 67%;
                        /*bottom: 0;*/
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-size: 0.14rem;
                            font-weight: bold;
                            color: #2A2E43;
                            opacity: 1;
                        }
                    }
                }

                .single-right {
                    width: 12.66rem;

                    min-height: 0.85rem;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding-top: 0.13rem;


                    .intro {
                        width: 1.5rem;
                        height: 0.65rem;
                        margin-left: 0.3rem;
                        margin-right: 0.3rem;
                        text-align: center;
                        margin-bottom: 0.13rem;
                        cursor: pointer;

                        .name {
                            font-size: 0.14rem;
                            font-weight: bold;
                            color: #454F63;
                            opacity: 1;
                            margin-bottom: 0.05rem;
                            overflow: hidden;
                            letter-spacing: 0;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 1; /*要显示的行数*/
                            -webkit-box-orient: vertical;
                        }

                        .title {
                            width: 100%;
                            font-size: 0.12rem;
                            font-weight: 400;
                            color: #78849E;
                            opacity: 1;
                            overflow: hidden;
                            letter-spacing: 0;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2; /*要显示的行数*/
                            -webkit-box-orient: vertical;
                            /*border: 1px solid red;*/
                            text-align: left;
                        }

                    }

                    .intro:hover {
                        .name {
                            color: #38B4B2;
                            font-weight: bold;
                        }

                        .title {
                            color: #38B4B2;
                        }
                    }

                }
            }

            .pensionService-single:hover {
                border: 1px solid #5FC6C5;
                box-shadow: 0px 3px 26px rgba(45, 93, 92, 0.16);
                opacity: 1;
            }

        }
    }

</style>









































































































































































































import {Component, Vue} from "vue-property-decorator";
import {Icon, Input, List, message, Modal, Select, Tabs} from "ant-design-vue";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
import Collection from "@/components/Personal/MyCollection/Collection.vue";
import serviceProviderItem from "@/components/Personal/MyCollection/serviceProviderItem.vue";
import institutionOfCollection from "@/components/Personal/MyCollection/InstitutionOfCollection.vue";
import commodityCollection from "@/components/Personal/MyCollection/commodityCollection.vue";
import shopCollectionList from "@/components/Personal/MyCollection/shopCollection.vue";
import aSpin from "@/components/aSpin.vue";
import {
    fetchServiceCollectCategory,
    fetchServiceCollectList,
    fetchServiceMerchandise,
    fetchServiceStoreCollectList,
    getCollectionList,
    getShopCollectList,
    organizationList
} from "@/services/api";

Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
    components: {
        PersonalHeader,
        // CollectionScrollItem,
        Collection,
        serviceProviderItem,
        institutionOfCollection,
        commodityCollection,
        shopCollectionList,
        aSpin
    }
})
export default class extends Vue {
    curType = "全部服务";
    curType2 = "全部商品";
    activeKey = "1";
    flag = true;
    flag2 = true;
    BigWidtg: any = '';
    BigWidtgTwo: any = '';
    displayStatus: any = false;
    merchandiseListStatus: any = false;
    serviceStoreCollectListStatus: any = false;
    shopCollectListStatus: any = false;
    organizationCollectListStatus: any = false;
    loading: any = true;
    pagination = {
        total: 0,
        pageSize: 40,
        pageIndex: 1
    };
    serviceCategory: Array<any> = [
        {
            calssificationName: "全部服务",
            categoryId: "",
            serviceNum: 0
        }
    ]; // 商品收藏  分类
    merchandiseClassify: Array<any> = [
        {
            calssificationName: "全部商品",
            categoryId: "",
            commodityNum: 0
        }
    ]; // 商品收藏  分类

    searchValue: String = ""; // 查询输入框的值
    // 服务收藏列表
    serviceCollectList: Array<any> = []; // 服务收藏列表
    organizationCollectList: Array<any> = []; // 机构收藏列表
    merchandiseList: Array<any> = []; // 商品收藏列表
    serviceStoreCollectList: Array<any> = []; // 服务商收藏列表
    shopCollectList: Array<any> = []; //店铺收藏列表
    screenWidth: any
    placeHolderList: Array<any> = [
        {
            tab: "1",
            name: "请输入服务名称"
        },
        {
            tab: "2",
            name: "请输入商品名称"
        },
        {
            tab: "3",
            name: "请输入服务商名称"
        },
        {
            tab: "4",
            name: "请输入店铺名称"
        },
        {
            tab: "5",
            name: "请输机构名称"
        }
    ];

    created() {
        this.activeKey = location.href.split("myCollection?type=")[1];
        this.switchGetData();
    }

    showTag() {
        this.flag = !this.flag
    }

    showTagTwo() {
        this.flag2 = !this.flag2
    }

    switchGetData() {
        this.loading = true;
        switch (this.activeKey) {
            case "1": {
                this.getServiceCollect();
                this.getServiceCollectList();

                break;
            }
            case "3": {
                this.getServiceStoreCollectList();
                break;
            }
            case "2": {
                this.AddWishlist();
                this.getMerchandiseList();

                break;
            }
            case "4": {
                this.obtainShopList();
                break;
            }
            case "5": {
                this.getorganizationCollectList();
                // this.loading = false
                break;
            }
        }
    }

    //查询分页
    onChangePageSize(page, pageSize) {
        window.scrollTo({top: 0});
        this.pagination.pageIndex = page;
        switch ((this.activeKey as any)) {
            case '1':
                this.getServiceCollectList()
                break;
            case '2':
                this.AddWishlist()
                break;
            case '3':
                this.getServiceStoreCollectList()
                break;
            case '4':
                this.obtainShopList()
                break;
            default:
                this.getorganizationCollectList()
        }
    }

    // 筛选placeholder


    filterPlaceHolder() {
        let name = "";
        this.placeHolderList.forEach((item: any) => {
            if (item.tab == this.activeKey) {
                name = item.name;
            }
        });
        return name;
    }

    // 获取服务收藏列表
    getServiceCollectList() {
        const params = {
            ...this.pagination,
            typeId: this.curTypeId,
            serviceName: this.searchValue
        };
        fetchServiceCollectList(params).then(res => {
            if (res && res.code === 0) {
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                this.serviceCollectList = res.data.service;
                this.loading = false
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取服务收藏分类
    getServiceCollect() {
        fetchServiceCollectCategory().then(res => {
            if (res && res.code === 0) {
                // 处理数据
                // const result: Array<any> = [];
                res.data.forEach((item: any) => {
                    if (item.serviceNum != 0) {
                        this.serviceCategory.push(item);
                        this.serviceCategory[0].serviceNum += item.serviceNum;
                    }
                });
                this.huxingW();
            } else {
                message.error(res.msg);
            }
        });
    }

    //获取服务ul的宽度
    huxingW() {
        this.$nextTick(() => {
            const children = (this.$refs.ul as any).children
            let liw = children[0].offsetWidth;
            let len = children.length;
            this.BigWidtg = liw * len
        });
    }


    // 获取商品收藏分类
    getMerchandiseList() {
        fetchServiceMerchandise().then(res => {
            if (res && res.code === 0) {
                // 处理数据
                res.data.forEach((item: any) => {
                    if (item != "") {
                        this.merchandiseClassify.push(item);
                        this.merchandiseClassify[0].commodityNum += item.commodityNum;

                    }
                });
                this.huxingW2()
                this.loading = false
            } else {
                message.error(res.msg);
            }
        });
    }

    //获取商品ul长度
    huxingW2() {
        this.$nextTick(() => {
            const children = (this.$refs.ul2 as any).children
            let liw = children[0].offsetWidth;
            let len = children.length;
            this.BigWidtgTwo = liw * len
        });
    }

    //获取商品收藏列表
    AddWishlist() {
        const params = {
            ...this.pagination,
            typeId: this.curTypeId,
            commodityName: this.searchValue
        };
        getCollectionList(params).then(res => {
            if (res && res.code === 0) {
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                this.merchandiseList = res.data.commodity;
            } else {
                message.error(res.msg);
            }
        });
    }

    // 获取服务商收藏列表
    getServiceStoreCollectList() {
        this.serviceStoreCollectList = [];
        const params = {
            ...this.pagination,
            orgName: this.searchValue
        };
        fetchServiceStoreCollectList(params).then(res => {
            if (res && res.code === 0) {
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                this.serviceStoreCollectList = res.data.service;
                this.loading = false
            } else {
                message.error(res.msg);
            }
        });
    }

    //获取机构收藏列表
    getorganizationCollectList() {
        this.organizationCollectList = [];
        const params = {
            ...this.pagination,
            orgName: this.searchValue
        };
        organizationList(params).then(res => {
            if (res && res.code === 0) {
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                // 处理数据
                this.organizationCollectList = res.data.service;
                this.loading = false
            } else {
                message.error(res.msg);
            }
        });
    }

    //获取店铺列表
    obtainShopList() {
        const params = {
            ...this.pagination,
            merchantName: this.searchValue
        };
        getShopCollectList(params).then(res => {
            if (res && res.code === 0) {
                this.pagination.total = res.data.totalCount;
                this.pagination.pageSize = res.data.pageSize;
                this.shopCollectList = res.data.list;
                this.loading = false
            } else {
                message.error(res.msg);
            }
        });
    }

    // 点击切换tab
    handleTabChange() {
        this.searchValue = "";
        this.$router.push(`/personal/myCollection?type=${this.activeKey}`);
        // this.switchGetData();
    }

    // 点击查询搜索
    async onSearch() {
        if (this.activeKey == "1") {
            // 搜索服务收藏
            await this.getServiceCollectList();
            if (this.serviceCollectList.length === 0) {
                this.displayStatus = true
            }
        } else if (this.activeKey == "2") {
            await this.AddWishlist();
            if (this.merchandiseList.length === 0) {
                this.merchandiseListStatus = true
            }
        } else if (this.activeKey == "3") {
            this.getServiceStoreCollectList();
            if (this.serviceStoreCollectList.length === 0) {
                this.serviceStoreCollectListStatus = true
            }
        } else if (this.activeKey == "4") {
            this.obtainShopList();
            if (this.shopCollectList.length === 0) {
                this.shopCollectListStatus = true
            }
        } else if (this.activeKey == "5") {
            this.getorganizationCollectList();
            if (this.organizationCollectList.length === 0) {
                this.organizationCollectListStatus = true
            }
        }
    }

    curTypeId = "";

    typeClick(type: any) {
        this.curTypeId = type.categoryId;
        this.curType = type.calssificationName;
        this.getServiceCollectList();
    }

    typeClickTwo(type: any) {
        this.curTypeId = type.categoryId;
        this.curType2 = type.calssificationName;
        this.AddWishlist();
    }
}


import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class productDesciption extends Vue {
    commodityIntroductionDtoArr:any = [];
    commodityIntroductionDtoObj = {};
    pictureList = [];
    content = "";

    setCommodityIntroductionDto(commodityIntroductionDto) {
        this.pictureList = commodityIntroductionDto.pictureList;

        this.commodityIntroductionDtoObj = JSON.parse(
            commodityIntroductionDto.commodityParameter || "{}"
        );
        this.commodityIntroductionDtoArr = Object.keys(
            this.commodityIntroductionDtoObj
        );

        this.content = Base64.decode(
            commodityIntroductionDto.commodityDetail || ""
        );
    }
}

<template>
    <div class="swiperBox">
        <div id="swiper">
            <div class="imgBox" @mouseenter="enter" @mouseleave="leave">
                <div class="imgDiv" v-for="(item, index) of imgList" :key="index">
                    <a :href="item.url" target="_blank">
                        <img :src="item.img" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            speed: Number,
            direction: String,
        },
        data() {
            return {
                imgList: [
                    {img: require('@/assets/images/home/tjdx_logo@2x.png'), url: 'http://www.tju.edu.cn'},
                    {img: require('@/assets/images/home/kjd_logo@2x.png'), url: 'http://www.feellike21.com/'},
                    {img: require('@/assets/images/home/zjdx_logo@2x.png'), url: 'http://www.zju.edu.cn'},
                    {img: require('@/assets/images/home/IT_institute_logo@2x.png'), url: 'https://www.tjzhilot.com/'},
                    {img: require('@/assets/images/home/xw_hospital_logo@2x.png'), url: 'https://www.xwhosp.com.cn/'},
                    {img: require('@/assets/images/home/pt_logo@2x.png'), url: 'http://www.cpit.com.cn'},
                    {img: require('@/assets/images/home/anychenk_logo@2x.png'), url: 'https://www.11467.com/qiye/96022600.htm'},
                    {img: require('@/assets/images/home/brilliange_logo@2x.png'), url: 'http://47.102.158.125/most_frontend/#/'},
                    {img: require('@/assets/images/home/xk_logo@2x.png'), url: 'https://www.xikang.com/'},
                    {img: require('@/assets/images/home/wfy_logo@2x.png'), url: 'http://www.rcwanfuyuan.com/index.html'},
                    {img: require('@/assets/images/home/zdwx_logo@2x.png'), url: 'http://www.insigmagroup.com.cn/'},
                    {img: require('@/assets/images/home/pakj_logo@2x.png'), url: 'https://tech.pingan.com/'},
                    {img: require('@/assets/images/home/bkd.png'), url: 'http://47.102.158.125/most_frontend'},
                ],
                timer: null,
                theSpeed: 2,  //移动速度
                imgWidth: 260,
                theDirection: 'left'  //移动方向
            };
        },
        created() {},
        mounted() {
            this.move();
        },
        methods: {
            // 移入
            enter() {
                this.timer = clearInterval(this.timer);
            },
            leave() {
                this.move()
            },
            move() {
                var imgBox = document.getElementsByClassName("imgBox")[0];
                imgBox.innerHTML += imgBox.innerHTML;
                var imgDiv = document.getElementsByClassName("imgDiv");
                imgBox.style.width = imgDiv.length * this.imgWidth + "px"; //设置ul的宽度使图片可以放下
                let that = this;
                function autoScroll() {
                    if (imgBox.offsetLeft < -(imgBox.offsetWidth / 2)) {
                        //向左滚动
                        imgBox.style.left = 0;
                    }
                    if (imgBox.offsetLeft > 0) {
                        //向右滚动
                        imgBox.style.left = -(imgBox.offsetWidth / 2) + "px";
                    }
                    if (that.theDirection == "left") {
                        that.theSpeed = -Math.abs(that.theSpeed);
                    } else {
                        that.theSpeed = Math.abs(that.theSpeed);
                    }
                    imgBox.style.left = imgBox.offsetLeft + that.theSpeed + "px";
                }
                clearInterval(this.timer)
                this.timer = setInterval(autoScroll, 30); //全局变量 ，保存返回的定时器

            }
        },

        beforeDestroy() {
            clearInterval(this.timer);
            this.timer = null;
        }
    };
</script>
<style scoped lang="less">
    .swiperBox {
        height: 1.4rem;
        width: 100%;
        margin:0 auto;
        position: relative;
    }
    .swiperBox #swiper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .swiperBox #swiper .imgBox {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .swiperBox #swiper .imgBox .imgDiv {
        width: 1.82rem;
        height: 1.14rem;
        margin: 0.05rem;
        cursor: pointer;
        background-color: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .swiperBox #swiper .imgBox .imgDiv img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin-top: -0.6rem;

    }
</style>

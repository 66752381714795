


























































































































    import {Component, Prop, Vue} from "vue-property-decorator";
    import {message} from "ant-design-vue";
    import {
        addShippingAddress,
        fetchArea,
        fetchCity,
        fetchProvince,
        fetchProvinceName,
        fetchStreet,
        updateShippingAddress
    } from "@/services/api";

    @Component({})
export default class addressLocationAdd extends Vue {
  form: any;
  @Prop() editDataTwo: any;
  @Prop() editTypeTwo: string;
  data: any = {
    receiverName: "",
    provinceValue: [],
    receiverDetailAddress: "",
    zipCode: "",
    isDefaut: "",
    phoneNumber: "",
    receiverStreet: null,
    receiverArea: null,
    receiverCity: null,
    receiverProvince: null
  };
  options: any = [];
  formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 5}
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 15}
    }
  };
  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  async created() {
    // this.getOptionsList();

    const data = this.editDataTwo;
    this.data = { ...data };
    this.data.provinceValue = this.data.receiverProvince
      ? [
          this.data.receiverProvince,
          this.data.receiverCity,
          this.data.receiverArea,
          this.data.receiverStreet
        ]
      : [];
    await this.getProvince();
    await this.initArea();
    this.form.setFieldsValue(this.data);
  }

  async initArea() {
    let obj = await this.mfun(this.options, this.data.receiverProvince, 1);
    let obj2 = await this.mfun(obj, this.data.receiverCity, 2);
    await this.mfun(obj2, this.data.receiverArea, 3);
  }

  mfun(farr, id, type) {
    return new Promise(resolve => {
      fetchCity({
        areaType: type,
        areaId: id
      }).then(res => {
        if (res && res.code === 0) {
          res.data.list.forEach((item: any, index: any) => {
            item.value = item.id;
            item.label = item.name;
            item.isLeaf = item.childNum == 0;
            item.level = type + 1;
          });
          farr.forEach(item => {
            if (item.id == id) {
              item.children = res.data.list;
              resolve(item.children);
            }
          });
        } else {
          message.error(res.msg);
          resolve();
        }
      });
    });
  }

  //保存数据
  handleSubmit(evt) {
    evt.preventDefault();
    this.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data = this.form.getFieldsValue();
        if (this.editTypeTwo) {
          this.editAddress(data);
        } else {
          this.addAddress(data);
        }
      }
    });
  }
  //取消保存
  cancelTheSave(){
    this.$emit("cancelSave");
  }

  //更新地址
  editAddress(data) {
    const a = {
      ...this.data,
      ...data,
      isDefault: data.isDefault ? 1 : 0,
      receiverProvince: data.provinceValue[0],
      receiverCity: data.provinceValue[1],
      receiverArea: data.provinceValue[2],
      receiverStreet: data.provinceValue[3]
    };
    updateShippingAddress(a).then(res => {
      if (res && res.code === 0) {
        message.success("更新收货地址成功");
        this.$emit("editAddressSuccess");
      } else {
        message.error(res.msg);
      }
    });
  }

  //新增地址
  addAddress(data) {
    const a = {
      ...this.data,
      ...data,
      isDefault: data.isDefault ? 1 : 0,
      receiverProvince: data.provinceValue[0],
      receiverCity: data.provinceValue[1],
      receiverArea: data.provinceValue[2],
      receiverStreet: data.provinceValue[3]
    };
    addShippingAddress(a).then(res => {
      if (res && res.code === 0) {
        message.success("新增收货地址成功");
        this.$emit("addressSuccess");
      } else {
        message.error(res.msg);
      }
    });
  }

  //获取信息
  async loadMoreData(selectedOptions: any) {
    switch (selectedOptions.length) {
      case 1:
        await this.getCity(selectedOptions);
        break;
      case 2:
        await this.getArea(selectedOptions);
        break;
      case 3:
        await this.getStreet(selectedOptions);
        break;
      default:
        break;
    }
  }

  // 获取省
  getProvince() {
    return new Promise(resolve => {
      fetchProvince().then(res => {
        if (res && res.code === 0) {
          // 处理数据
          res.data.list.forEach((item: any, index: any) => {
            item.value = item.id;
            item.label = item.name;
            item.isLeaf = false;
          });
          this.options = res.data.list;
        } else {
          message.error(res.msg);
        }
        resolve();
      });
    });
  }

  // 获取市
  getCity(selectedOptions: any) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    fetchCity({
      areaType: 1,
      areaId: targetOption.id
    }).then(res => {
      if (res && res.code === 0) {
        targetOption.loading = false;
        // 处理数据
        res.data.list.forEach((item: any, index: any) => {
          item.value = item.id;
          item.label = item.name;
          item.isLeaf = false;
        });
        targetOption.children = res.data.list;
        this.options = [...this.options];
      } else {
        message.error(res.msg);
      }
    });
  }

  // 获取区
  getArea(selectedOptions: any) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    fetchArea({
      areaType: 2,
      areaId: targetOption.id
    }).then(res => {
      if (res && res.code === 0) {
        targetOption.loading = false;
        // 处理数据
        res.data.list.forEach((item: any, index: any) => {
          item.value = item.id;
          item.label = item.name;
          item.isLeaf = false;
        });
        targetOption.children = res.data.list;
        this.options = [...this.options];
      } else {
        message.error(res.msg);
      }
    });
  }

  // 获取街道
  getStreet(selectedOptions: any) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    fetchStreet({
      areaType: 3,
      areaId: targetOption.id
    }).then(res => {
      if (res && res.code === 0) {
        targetOption.loading = false;
        // 处理数据
        res.data.list.forEach((item: any, index: any) => {
          item.value = item.id;
          item.label = item.name;
          item.isLeaf = true;
        });
        targetOption.children = res.data.list;
        this.options = [...this.options];
      } else {
        message.error(res.msg);
      }
    });
  }

  preName = [];

  onProvinceChange(value: any) {
    [
      this.data.receiverProvince,
      this.data.receiverCity,
      this.data.receiverArea,
      this.data.receiverStreet
    ] = value;
    fetchProvinceName({
      province: value[0],
      city: value[1],
      area: value[2],
      street: value[3]
    }).then(({ info, code }) => {
      if (code == 0) {
        this.preName = Object.values(info.addr);
      }
    });
  }

  // //获取收货人详细地址
  // getOptionsList() {
  //   let str: any = sessionStorage.getItem("allAreaData");
  //   str = str.replace(/provinceName/g, "label");
  //   str = str.replace(/cityList/g, "children");
  //   str = str.replace(/provinceId/g, "value");
  //   str = str.replace(/cityName/g, "label");
  //   str = str.replace(/areaList/g, "children");
  //   str = str.replace(/cityId/g, "value");
  //   str = str.replace(/areaName/g, "label");
  //   str = str.replace(/areaId/g, "value");
  //   this.options = json.parse(str);
  //   this.options.map((item: any) => {
  //     item.children.map(val => {
  //       val.children.map(i => {
  //         i.isLeaf = false;
  //       });
  //     });
  //   });
  // }
  // //获取地址信息
  // onLocationChange(selectedOptions) {
  //   const targetOption = selectedOptions[selectedOptions.length - 1];
  //   request(`${baseUrl}/api/v1/citys/list`, {
  //     params: { areaType: 3, areaCode: targetOption.areaCode }
  //   }).then((res: any) => {
  //     const b: any[] = [];
  //     res.data.streetList.map(item => {
  //       b.push({
  //         label: item.streetName,
  //         value: item.streetId
  //       });
  //     });
  //     targetOption.children = b;
  //     this.options = [...this.options];
  //   });
  // }
}

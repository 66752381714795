
















































import {Component, Vue} from "vue-property-decorator";
import {addShopping, callOffTop, cancelCollection} from "../../../services/api";
import {baseUrl, mallUrl} from "../../../services/http";
import {message} from "ant-design-vue";

@Component({
        props: {
            data: Object
        }
    })
    export default class Collection extends Vue {
        newContent = "";
        mouseIsEnter = false;
        serveCancel = false;
        baseUrl = baseUrl;

        //加入购物车
        addShoppingCart(data) {
            addShopping({
                siId: '123',
                quantity: '123'
            }).then(res => {
                if (res.code == 0) {
                    // this.$message.success("您已成功取消收藏");
                    // this.$emit("updateServiceCollectList");
                } else {
                    this.$message.error(res.msg);
                }
            });
        }

        //商品详情
        handleGoService(data) {
            open(mallUrl + '/#/mall/detail/' + data.id);
        }

        //进入店铺
        handleGoServiceSotre(data) {
            open(mallUrl + '/#/mall/shop/' + data.merchantId);
        }

        //置顶、取消置顶
        cancelTheTop(value) {
            callOffTop({
                collectionId: value,
            }).then(res => {
                if (res && res.code === 0) {
                    // 处理数据
                    this.$emit("updateServiceCollectList");
                } else {
                    message.error(res.msg);
                }
            });
        }

        // 点击取消收藏
        handleCancelCollect() {
            this.serveCancel = true
        }

        enshrine() {
            cancelCollection({
                collectionId: this.$props.data.collectId
            }).then(res => {
                if (res.code == 0) {
                    this.$emit("updateServiceCollectList");
                    this.serveCancel = false
                } else {
                    this.$message.error(res.msg);
                }
            });

        }

        //移入
        handleMouseEnter() {
            this.mouseIsEnter = true;
        }

        //移出
        handleMouseLeave() {
            this.mouseIsEnter = false;
        }
    }





























import {Component, Vue, Watch, Prop} from "vue-property-decorator";
import {Table, Select, message} from "ant-design-vue";
import {getServiceDetail} from "@/services/api";
import PersonalHeader from "@/components/Personal/PersonalHeader.vue";

Vue.use(Table);
Vue.use(Select);

@Component({
    components: {PersonalHeader}
})
export default class extends Vue {
    columns = [
        {
            title: "商品订单号",
            dataIndex: "seriesNum",
            align: "center"
        },
        {
            title: "消费金额",
            dataIndex: "actualAmount",
            align: "center"
        },
        {
            title: "支付方式",
            dataIndex: "purchaseMethod",
            align: "center"
        },
        {
            title: "消费时间",
            dataIndex: "createTime",
            align: "center"
        }
    ];
    consumptionDetailData: any = []; // 商品消费明细列表
    async created() {
        this.consumptionDetail()
    }
    //商品消费明细
    consumptionDetail() {
        getServiceDetail({
            pageIndex: 1,
            pageSize: 99999999,
        }).then((res: any) => {
            if (res && res.code === 0) {
                this.consumptionDetailData = res.data;
            } else {
                message.error(res.msg);
            }
        });
    }
    pagination = {
        defaultPageSize: 10,
        showTotal: total => `共 ${total} 条数据`,
        showSizeChanger: false,
    }
}


























    import {Component, Vue} from "vue-property-decorator";
    import {Rate} from "ant-design-vue";

    Vue.use(Rate);

@Component({
  props: {
    title: String,
    location: String,
    area: String,
    saleCount: Number,
    rate: Number,
    price: Number,
    serviceId: String,
    image: String,
    allContent: Object,
    telemedicineReservation:Boolean,
  },
})
export default class ServiceProjectCard extends Vue {
  // @Prop() private msg!: string
  toDetail(serviceId, e) {
    this.$router.push(`/cookInHome/${serviceId}`);

  }
  handleBuy(event) {
    event.stopImmediatePropagation();
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.$router.push({
        path: "/serviceOrder",
        query: {
          serviceId: this.$props.serviceId,
          amount: "1",
        },
      });
    } else {
      const { href } = this.$router.resolve({
        path: "/serviceOrder",
        query: {
          serviceId: this.$props.serviceId,
          amount: "1",
        },
      });
      sessionStorage.setItem("pay", href);
      this.$router.push("/login");
    }
  }
  //立即预约
  appointmentImmediately(event){
  }
}

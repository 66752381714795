




























    import {Component, Vue} from "vue-property-decorator";
    import {Icon, Input, List, Modal, Select, Tabs} from "ant-design-vue";
    import PersonalHeader from "@/components/Personal/PersonalHeader.vue";
    import reservationListItem from "./reservationListItem.vue";
    import reservationListTrashCanItem from "./reservationListTrashCanItem.vue";

    import {reservationOrderStatus} from "@/services/api";
    import {Mutation} from "vuex-class";

    Vue.use(Tabs);
Vue.use(Input);
Vue.use(List);
Vue.use(Icon);
Vue.use(Modal);
Vue.use(Select);

@Component({
  components: {
    PersonalHeader,
    // ReservationItem,
    reservationListItem,
    reservationListTrashCanItem
  }
})
export default class extends Vue {
  @Mutation SET_ALLRSERVATIONSTATUS;
  list = ["全部预约", "已预约","进行中", "已完成", "已取消", "待评价"];
  reservationStatus = {};
  searchItem = "";
  created() {
    this.reservationOrderStatus();
    const acKEY = sessionStorage.getItem("acKEY");
    if(acKEY!=null){
      this.TANiNDEX = Number(acKEY);
      sessionStorage.removeItem("acKEY");
    }

  }
  emptyTextBySearch = false;
  TANiNDEX = 1;
  onSearch(value) {
    this.emptyTextBySearch = true;
    (this.$refs["reservationListItem" + (this.TANiNDEX - 1)] as any)[0].getSearchItem(value);

    this.searchItem = value;
  }
  reservationOrderStatus() {
    reservationOrderStatus({}).then(res => {
      let that: any = this;
      res.data.forEach(el => {
        that.reservationStatus[el.dictionaryId] = el.dictionaryName;
      });
      this.SET_ALLRSERVATIONSTATUS(this.reservationStatus);
    });
  }
  mounted() {
    this.$nextTick().then(() => {
      let m: any = document.querySelectorAll(".ant-tabs-tab");
      // m[6].style.display = "none";
      m[6].style.borderLeft="1px solid #E4E9F2";
      m[6].style.height="0.2rem";
      m[6].style.paddingTop="0";
      m[5].style.paddingRight="0.2rem";
      m[6].style.paddingLeft="0.3rem";
      m[6].style.marginLeft="0.1rem";
      m.forEach((element, indxe) => {
        element.onclick = () => {
          if(this.$refs["reservationListItem" + indxe]!=undefined){
            (this.$refs["reservationListItem" + indxe] as any)[0].reservationOrderList();
          }
        };
      });
    });
  }
  //切换获取状态下对应的信息
  change(activeKey) {
    if(activeKey===1){
      this.emptyTextBySearch=false;
    }else{
      this.emptyTextBySearch=true;
    }
    let a: any = document.querySelectorAll(".ant-tabs-ink-bar");
    if(activeKey===7){
      a[0].style.background='none';
    } else if(a.length!=0){
      a[0].style.backgroundColor='#39BEB8';
    }
  }

}

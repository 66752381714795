









































































































































































































































import {http} from "@/services/http";
import {message} from "ant-design-vue";
import {Component, Vue, Watch} from "vue-property-decorator";
import {State} from "vuex-class";
import ReturnStep from "../../components/mallComponents/orderInfo/ReturnStep.vue";

@Component({
    components: {ReturnStep},
})
export default class Cart extends Vue {
    @State scrollTopHeight!: any;

    @Watch("scrollTopHeight") changeScrollTopHeight(val: number) {
    }

    stepList = [
        {
            name: "我的购物车",
        },
        {
            name: "填写核对商品订单信息",
        },
        {
            name: "成功提交订单",
        },
    ];
    addressId = "";
    searchKeywords = "";
    visibleConfirm = false;
    reminder = false
    favorite = [];
    productList:any = [];
    commoditiesInCartsList = '';
    expurgate = false;
    del1 = '';
    del2 = '';
    stateArrow = false;
    removeAll = false;
    batchInto = false;
     useful = false;
    currentImageIndex = 0;

    color = ''

    mounted() {
    }

    //头部搜索
    jumpRuter(keywords?: string) {
        keywords = keywords || this.searchKeywords;
        open(`/#/mall/search?keywords=${encodeURI(keywords)}`, "_self");
    }

    //查看类型
    checkTheType() {
        if (this.quantityCheck != 0) {
            this.stateArrow = !this.stateArrow
        }
        // if (this.stateArrow === false) {
        // }
    }

    beforeCreate() {
        if (!localStorage.getItem("userInfo")) {
            message.warning("请先登录");
            open("/#/login", "_self");
            return;
        }
    }
    //左
    handlePreImg() {
        if (this.currentImageIndex > 0) {
            if (this.currentImageIndex % 10 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft =
                    -(this.currentImageIndex - 10) * 0.985 + "rem";
            }
            this.currentImageIndex -= 1;
        }
    }
    //右
    handleNextImg() {
        if (this.currentImageIndex < this.productList.length - 1) {
            this.currentImageIndex += 1;
            if (this.currentImageIndex % 10 == 0) {
                const el = document.getElementById("imgsInnerBox");
                (el as any).style.marginLeft = -this.currentImageIndex *0.985 + "rem";
            }
        }
    }


    created() {
        this.getCartList();
        this.getAddressList();
    }

    testLogin() {
        const userInfo = localStorage.getItem("userInfo");

        if (!userInfo) {
            open("http://www.baidu.com", "_self");
        }
    }

    /*确认*/
    handleClickConfirm(e) {
        this.$router.push("/allJoin");
    };

    favorites(item) {
        var cartIds:any = [];
        cartIds.push(item.cartId)
        http.post(`/api/v1/cart/collect`, {
            cartIds,
        })
            .then((code:any) => {
                if (code == 0) {
                    message.success("移入收藏夹成功");
                    this.getCartList();
                } else {
                    message.error("移入收藏夹失败");
                }
            });
    }

    eeee(val) {
        if (val.quantity <= 1) return;
        val.quantity--;
        val.totalPrice = val.quantity * val.price;
        this.getPriceCheck();
    }

    cccc(e, val) {
        const n = +e.target.value;
        val.quantity = n;
        const purchaseLimit = val.purchaseLimit;
        if (val.isLimit && n >= val.purchaseLimit) {
            message.warn("超过最大购买数量");
            val.quantity = 1;
            // return;
        }
        if (n >= val.stockNum) {
            message.warn("超过最大购买数量");
            val.quantity = 1;
            // return;
        }

        val.totalPrice = val.quantity * val.price;
        this.getPriceCheck();
    }

    pppp(val) {
        if (val.isLimit && val.quantity >= val.purchaseLimit) {
            message.warn("已达最大购买数量");
            return;
        }
        if (val.quantity >= val.stockNum) {
            message.warn("已达最大购买数量");
            return;
        }

        val.quantity++;
        val.totalPrice = val.quantity * val.price;
        this.getPriceCheck();
    }

    addressList:any = [];

    getAddressList() {
        http.get("/api/v1/addresses/list").then((res:any) => {
            if (res["code"] == 0) {
                this.addressList = res["data"];
                this.addressId = this.addressList[0].addressId;
            }
        });
    }

    toPay() {
        const data:any = [];
        for (let item of this.result.merchantList) {
            if (item.check) {
                const obj = JSON.parse(JSON.stringify(item));
                let a = item.commoditiesInCarts.filter((i) => i.check);
                if (a.length > 0) {
                    obj.commoditiesInCarts = a;
                    data.push(obj);
                    continue;
                }
            } else {
                const obj = JSON.parse(JSON.stringify(item));
                let a = item.commoditiesInCarts.filter((i) => i.check);
                if (a.length > 0) {
                    obj.commoditiesInCarts = a;
                    data.push(obj);
                }
            }
        }

        if (data.length <= 0) {
            message.warning("请勾选您要结算的商品");
            return;
        }
        // localStorage.setItem("seCartPayData", )
        // );
        // this.$router.push("/mall/OrderInfo");
        const dataList =  JSON.stringify({data, totalAmount:Number(this.priceCheck).toFixed(2), addressId: this.addressId, cart: true})
        this.$router.push({
            path: "/mall/OrderInfo",
            query: {
                dataList
            },
        });

    }

    condition = {
        pageSize: 10,
        pageIndex: 1,
    };
    result:any = {
        totalCount: 0,
        commodityNum: 0,
        merchantList: [],
        selectAll: false,
    };

    //总体全选
    selectAllChange() {
        this.result.merchantList.forEach((item) => {
            item.check = this.result.selectAll;
            item.commoditiesInCarts.forEach((element) => {
                if (element.commodityStatus === 359 || element.commodityStatus === 345 ||element.stockNum===0) {
                    element.check = false;
                } else {
                    element.check = this.result.selectAll;
                    // this.color='#fff8e1'
                }


            });
        });
        this.getPriceCheck();
    }

    showData = true;

    //店铺全选
    shopCheckChange(item) {
        this.showData = false;
        item.commoditiesInCarts.forEach((element) => {
            if (element.commodityStatus === 359 || element.commodityStatus === 345||element.stockNum===0) {
                element.check = false;
            } else {
                element.check = item.check;
            }
        });
        this.result.selectAll = this.result.merchantList.every((i) => i.check);
        this.showData = true;
        this.getPriceCheck();
    }

    //单个选中
    goodCheckChange(item) {
        if (item.commodityStatus === 359 || item.commodityStatus === 345) {
            item.check = false;
        }
        // item.check = item.commoditiesInCarts.every((i) => i.check);
        this.result.selectAll = this.result.merchantList.every((i) => i.check);
        this.getPriceCheck();
    }

    countPrice() {
        const res = {}
        this.result.merchantList.forEach((item) => {
            item.commoditiesInCarts.forEach((element) => {
                if (res[element.commodityStatus]) {
                    res[element.commodityStatus]++
                } else {
                    res[element.commodityStatus] = 1
                }
                // element.check = true;
                element.totalPrice = element.price * element.quantity;
            });

        });
        this.commoditiesInCartsList = res[Object.keys(res)[1]];
        if (this.commoditiesInCartsList == '' || this.commoditiesInCartsList == undefined) {
            this.reminder = false
        } else {
            this.reminder = true
        }
    }

    del(item, cartId) {
        const userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            http
                .post("/api/v1/cart/delete", {cartIds: [{cartId}]})
                .then((code:any) => {
                    if (code == 0) {
                        message.success("删除成功！");
                        this.getCartList();
                    } else {
                        message.error("删除失败！");
                    }
                });
        }
    }

    priceCheck: any = 0;
    quantityCheck: any = "0";

    getPriceCheck() {
        let price = 0;
        let quantity = 0;
        this.productList = [];
        this.result.merchantList.forEach((item) => {
            item.commoditiesInCarts.forEach((element:any) => {
                if (element.check) price += element.totalPrice;
                if (element.check) quantity += element.quantity;
                if (element.check) this.productList.push(element);
            });
        });
        this.priceCheck = price + "";
        this.quantityCheck = quantity + "";

        this.stateArrow = false;
        if (this.quantityCheck === "0") {

        }
    }

    //批量删除判断
    batchDel() {
        const cartIds:any = [];
        this.result.merchantList.forEach((item) => {
            item.commoditiesInCarts.forEach((element:any) => {
                if (element.check) cartIds.push({cartId: element.cartId});
            });
        });
        if (cartIds.length == 0) {
            message.warning("请选择商品进行删除");
            return;
        }
        this.removeAll = true

    }

    //批量移入判断
    batchTransfe() {
        const cartIds:any = [];
        this.result.merchantList.forEach((item) => {
            item.commoditiesInCarts.forEach((element) => {
                if (element.check) cartIds.push({cartId: element.cartId});
            });
        });
        if (cartIds.length == 0) {
            message.warning("请选择商品进行批量移入商品");
            return;
        }
        this.batchInto = true
    }
    //清理失效商品
    loseEfficacy(){
        http
            .post("/api/v1/cart/clear/offStatus", {})
            .then((res:any) => {
              if (res.code == 0) {
                message.success("清理失效商品成功！");
                this.getCartList();
              } else {
                message.error("清理失效商品失败！");
              }
            });
    }

    //批量移入提交
    batchUpload() {
        const cartIds:any = [];
        this.result.merchantList.forEach((item:any) => {
            item.commoditiesInCarts.forEach((element:any) => {
                if (element.check) cartIds.push(element.cartId);
            });
        });
        http.post(`/api/v1/cart/collect`, {
            cartIds,
        })
            .then((res:any) => {
                if (res.code == 0) {
                    message.success("移入收藏夹成功");
                    this.getCartList();
                } else {
                    message.error("移入收藏夹失败");
                }
            });
    }

    //批量删除提交
    delCheck() {
        const cartIds:any = [];
        this.result.merchantList.forEach((item) => {
            item.commoditiesInCarts.forEach((element) => {
                if (element.check) cartIds.push({cartId: element.cartId});
            });
        });
        http.post("/api/v1/cart/delete", {cartIds}).then((res:any) => {
            if (res.code == 0) {
                message.success("删除成功！");
                this.getCartList();
            }
        });
    }


    getCartList() {
        this.result = {
            totalCount: 0,
            commodityNum: 0,
            merchantList: [],
            selectAll: false,
        };

        http.get("/api/v1/cart/list", {params: {...this.condition, type: "0"}})
            .then(({code, data}: any) => {
                if (code == 0) {
                    this.result = {...this.result, ...data};
                  data.merchantList.forEach(item=>{
                      item.commoditiesInCarts.forEach(item2=>{
                          if(!item.useful){
                              this.useful=true
                          }else if(!item2.useful){
                              this.useful=true
                          }
                      })

                    })

                    this.countPrice();
                    this.getPriceCheck();
                }
            });
    }
}
